<template>
  <BModal :active="isOpen" hasModalCard :width="600" @close="$emit('close')">
    <form v-if="!isComponentLoading" @submit.prevent="handleSubmitCreateCost">
      <div class="modal-card">
        <CreateOrEditVendorModal
          :isOpen="isAddVendorModalOpen"
          :initialVendorName="createCostForm.fields.vendor.value"
          @close="handleToggleAddVendorModal"
          @submitSuccess="handleCreateVendorSuccess"
        />
        <div class="modal-card-head">
          <p class="modal-card-title">
            {{ $t('common.ctas.createCost') }}
          </p>
        </div>
        <div class="modal-card-body">
          <BField v-if="submissionError">
            <BMessage type="is-danger">
              {{ submissionError.message }}
            </BMessage>
          </BField>
          <div class="columns is-mobile is-multiline">
            <div
              :class="[
                'field column is-12-mobile is-6-tablet',
                { 'required': createCostForm.fields.costName.required },
              ]"
            >
              <label class="label">{{ $t(createCostForm.fields.costName.label) }}</label>
              <div :class="['control', { 'has-icons-left': createCostForm.fields.costName.icon }]">
                <input
                  :class="['input', 'input--icon-dark', { 'is-danger':  createCostForm.formErrors[$t(createCostForm.fields.costName.name)] }]"
                  v-model="createCostForm.fields.costName.value"
                  :disabled="createCostForm.isFormSubmissionInProgress"
                  @input="handleTouchForm"
                  :ref="createCostForm.fields.costName.ref"
                />
                <span v-if="createCostForm.fields.costName.icon" class="icon is-left">
                  {{ createCostForm.fields.costName.icon }}
                </span>
              </div>
              <p v-if="createCostForm.formErrors[$t(createCostForm.fields.costName.name)]" class="help is-danger">
                {{ createCostForm.formErrors[$t(createCostForm.fields.costName.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-12-mobile is-6-tablet',
                { 'required': createCostForm.fields.costType.required },
              ]"
            >
              <label class="label">{{ $t(createCostForm.fields.costType.label) }}</label>
              <BSelect
                v-model="createCostForm.fields.costType.value"
                expanded
              >
                <option v-for="costType in costTypes" :key="costType.id" :value="costType">
                  {{ $t(`common.costTypes.${costType.name}`) }}
                </option>
              </BSelect>
              <p v-if="createCostForm.formErrors[$t(createCostForm.fields.costType.name)]" class="help is-danger">
                {{ createCostForm.formErrors[$t(createCostForm.fields.costType.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-6-mobile is-6-tablet',
                { 'required': createCostForm.fields.startDate.required },
              ]"
            >
              <label class="label">{{ $t(createCostForm.fields.startDate.label) }}</label>
              <BDatepicker
                v-model="createCostForm.fields.startDate.value"
                :locale="$i18n.locale"
                icon="calendar-today"
                trapFocus
                appendToBody
              />
              <p v-if="createCostForm.formErrors[$t(createCostForm.fields.startDate.name)]" class="help is-danger">
                {{ createCostForm.formErrors[$t(createCostForm.fields.startDate.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-6-mobile is-6-tablet',
                { 'required': createCostForm.fields.endDate.required },
              ]"
            >
              <label class="label">{{ $t(createCostForm.fields.endDate.label) }}</label>
              <BDatepicker
                v-model="createCostForm.fields.endDate.value"
                :locale="$i18n.locale"
                icon="calendar-today"
                trapFocus
                appendToBody
              />
              <p v-if="createCostForm.formErrors[$t(createCostForm.fields.endDate.name)]" class="help is-danger">
                {{ createCostForm.formErrors[$t(createCostForm.fields.endDate.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-12-mobile is-6-tablet',
                { 'required': createCostForm.fields.vendor.required },
              ]"
            >
              <label class="label">{{ $t(createCostForm.fields.vendor.label) }}</label>
              <BAutocomplete
                class="disable-on-select"
                :data="vendorsList"
                v-model="createCostForm.fields.vendor.value"
                :placeholder="$t(createCostForm.fields.vendor.placeholder)"
                field="name"
                ref="create-cost-modal__vendor-autocomplete"
                :disabled="createCostForm.isFormSubmissionInProgress || selectedVendorId"
                openOnFocus
                appendToBody
                :maxHeight="200"
                clearable
                :selectOnClickOutside="false"
                :loading="isFetchingVendors"
                @select="handleSelectVendor"
                @blur="handleVendorFieldBlur"
                @input="handleTouchForm"
                @typing="fetchVendors"
              >
                <template slot="footer">
                  <div @click="handleToggleAddVendorModal" type="is-info" outlined size="is-small" style="width: 100%;">
                    <span class="autocomplete__footer__cta">
                      <BIcon icon="plus-circle-outline" customSize="mdi-18px" />
                      {{
                        (createCostForm.fields.vendor.value && !selectedVendorId)
                          ? $t('createCostView.ctas.createVendor', { vendorName: createCostForm.fields.vendor.value })
                          : $t('common.ctas.createVendor')
                      }}
                    </span>
                  </div>
                </template>
                <template v-if="vendorsList.length === 0" slot="empty">
                  {{ $t('common.emptyStates.noResults') }}
                </template>
              </BAutocomplete>
              <p v-if="createCostForm.formErrors[$t(createCostForm.fields.vendor.name)]" class="help is-danger">
                {{ createCostForm.formErrors[$t(createCostForm.fields.vendor.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-6-mobile is-6-tablet',
                { 'required': createCostForm.fields.billingType.required },
              ]"
            >
              <label class="label">{{ $t(createCostForm.fields.billingType.label) }}</label>
              <div class="is-block">
                <BRadio
                  v-for="billingType in billingTypes"
                  :key="billingType.label"
                  :native-value="billingType.value"
                  v-model="createCostForm.fields.billingType.value"
                  :disabled="createCostForm.isFormSubmissionInProgress"
                  @input="handleTouchForm"
                >
                  {{ billingType.label }}
                  <BTooltip :label="billingType.info" type="is-dark" multilined>
                    <BIcon icon="information-outline" size="is-small" type="is-dark" />
                  </BTooltip>
                </BRadio>
              </div>
              <p v-if="createCostForm.formErrors[$t(createCostForm.fields.billingType.name)]" class="help is-danger">
                {{ createCostForm.formErrors[$t(createCostForm.fields.billingType.name)] }}
              </p>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <BButton
            type="is-primary"
            icon-left="plus-thick"
            nativeType="submit"
            :loading="createCostForm.isFormSubmissionInProgress"
            :disabled="createCostForm.isFormSubmissionInProgress"
            expanded
          >
            {{ $t('common.ctas.createCost') }}
          </BButton>
        </footer>
      </div>
    </form>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isComponentLoading" :can-cancel="false"></BLoading>
    </div>
  </BModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import * as CostActions from '@/store/actions/Cost.actions';
import * as VendorActions from '@/store/actions/Vendor.actions';
import * as ValidationService from '@/services/Validation.service';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import createCostForm from '@/forms/createCost.form';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import CreateOrEditVendorModal from '@/components/CreateOrEditVendorModal.vue';
import * as Types from '@/constants/Types';

export default {
  name: 'CreateCostModal',

  components: {
    CreateOrEditVendorModal,
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'submitSuccess',
    'close',
  ],

  data() {
    return {
      isComponentLoading: true,
      isAddVendorModalOpen: false,
      idFetchingVendors: false,
      createCostForm: cloneDeep(createCostForm),
      selectedVendorId: null,
      submissionError: null,
      vendorsList: [],
    };
  },

  computed: {
    ...mapState({
      subcontracts: state => state.subcontract.subcontracts,
      projects: state => state.project.projects,
      vendors: state => state.vendor.vendors,
      costTypes: state => state.costType.costTypes,
    }),
    ...mapGetters([
      'activeOrganization',
      'getBillingTypeByName',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    sortedVendors() {
      return !isObjectEmpty(this.vendors) ? Object.values(this.vendors).sort((a, b) => (a.name > b.name ? 1 : -1)) : [];
    },
    billingTypes() {
      return [
        {
          label: this.$t('common.terms.billingTypes.advance'),
          info: this.$t('billingTypeInfo.advance'),
          value: this.getBillingTypeByName(Types.billingTypes.advance).id,
        },
        {
          label: this.$t('common.terms.billingTypes.lumpSum'),
          info: this.$t('billingTypeInfo.lumpSum'),
          value: this.getBillingTypeByName(Types.billingTypes.lumpSum).id,
        },
      ];
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    // @NOTE: this method is loosely coupled to the blur handler (unfortunately), due to potential race conditions.
    handleSelectVendor(vendor) {
      this.selectedVendorId = vendor ? vendor.id : null;
      if (this.selectedVendorId) {
        this.createCostForm.fields.vendor.value = this.vendors[this.selectedVendorId].name;
      }
    },
    handleVendorFieldBlur() {
      // Wrapped in a setTimeout to avoid race condition of events between @select and @blur.
      setTimeout(() => {
        if (!this.selectedVendorId) {
          this.createCostForm.fields.vendor.value = '';
        }
      }, 200);
    },
    handleClickAddVendor() {
      this.isAddVendorModalOpen = true;
    },
    handleToggleAddVendorModal() {
      this.isAddVendorModalOpen = !this.isAddVendorModalOpen;
    },
    handleCreateVendorSuccess(vendorId) {
      this.selectedVendorId = vendorId;
      this.createCostForm.fields.vendor.value = this.vendors[this.selectedVendorId].name;
      this.handleToggleAddVendorModal();
    },
    handleTouchForm() {
      console.log('form touched');
    },
    async handleSubmitCreateCost() {
      this.submissionError = null;
      this.createCostForm.formErrors = {};
      this.createCostForm.formErrors = ValidationService.runFormValidation({ ...this.createCostForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.createCostForm.formErrors)) return;

      this.createCostForm.isFormSubmissionInProgress = true;

      try {
        const costId = await this.$store.dispatch(
          CostActions.CREATE_COST,
          {
            newCost: {
              projectId: this.projectId,
              name: this.createCostForm.fields.costName.value,
              costTypeId: this.createCostForm.fields.costType.value.id,
              startDate: this.createCostForm.fields.startDate.value,
              endDate: this.createCostForm.fields.endDate.value,
              billingTypeId: this.createCostForm.fields.billingType.value,
              ...this.selectedVendorId && { vendorId: this.selectedVendorId },
            },
          },
        );
        this.createCostForm = cloneDeep(createCostForm);
        this.setInitialFormState();
        NotificationService.showNotification(NotificationTypes.CREATE_COST.SUCCESS);
        this.$emit('submitSuccess', costId);
      } catch (err) {
        this.submissionError = err;
      }

      this.createCostForm.isFormSubmissionInProgress = false;
    },
    async fetchVendors() {
      this.isFetchingVendors = true;

      const [{ vendorIds }] = await Promise.all([
        this.$store.dispatch(VendorActions.FETCH_ORGANIZATION_VENDORS, {
          organizationId: this.activeOrganization.id,
          params: {
            pageNumber: 1,
            pageSize: 50,
            sortDirection: 'asc',
            sortBy: 'name',
            ...this.createCostForm.fields.vendor.value && { search: this.createCostForm.fields.vendor.value },
          },
        }),
      ]);

      this.vendorsList = vendorIds.map(vendorId => this.vendors[vendorId]);

      this.isFetchingVendors = false;
    },
    async fetchInitialData() {
      this.isComponentLoading = true;

      await this.fetchVendors();

      this.setInitialFormState();
      this.isComponentLoading = false;
    },
    setInitialFormState() {
      this.createCostForm.fields.costType.value = Object.values(this.costTypes)[0]; // eslint-disable-line
      this.createCostForm.fields.startDate.value = new Date();
      this.createCostForm.fields.endDate.value = new Date();
      this.createCostForm.fields.vendor.value = '';
      this.selectedVendorId = null;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
