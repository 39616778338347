import i18n from '@/i18n';

export const CREATE_BILL = {
  SUCCESS: {
    message: i18n.t('notifications.createBill.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.createBill.error'),
    type: 'is-danger',
  },
};

export const UPDATE_BILL = {
  SUCCESS: {
    message: i18n.t('notifications.updateBill.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateBill.error'),
    type: 'is-danger',
  },
};

export const LOAD_PAGE = {
  ERROR: {
    message: i18n.t('notifications.loadPage.error'),
    type: 'is-danger',
  },
};

export const LOAD_BILL_HISTORY = {
  ERROR: {
    message: i18n.t('notifications.loadBillHistory.error'),
    type: 'is-danger',
  },
};

export const CREATE_CLIENT = {
  SUCCESS: {
    message: i18n.t('notifications.createClient.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.createClient.error'),
    type: 'is-danger',
  },
};

export const UPDATE_CLIENT = {
  SUCCESS: {
    message: i18n.t('notifications.updateClient.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateClient.error'),
    type: 'is-danger',
  },
};

export const UPDATE_PROJECT = {
  SUCCESS: {
    message: i18n.t('notifications.updateProject.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateProject.error'),
    type: 'is-danger',
  },
};

export const UPDATE_ACTIVITY = {
  SUCCESS: {
    message: i18n.t('notifications.updateActivity.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateActivity.error'),
    type: 'is-danger',
  },
};

export const DELETE_ACTIVITY = {
  SUCCESS: {
    message: i18n.t('notifications.deleteActivity.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.deleteActivity.error'),
    type: 'is-danger',
  },
};

export const UPDATE_ACTIVITY_CATEGORY = {
  SUCCESS: {
    message: i18n.t('notifications.updateActivityCategory.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateActivityCategory.error'),
    type: 'is-danger',
  },
};

export const DELETE_ACTIVITY_CATEGORY = {
  SUCCESS: {
    message: i18n.t('notifications.deleteActivityCategory.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.deleteActivityCategory.error'),
    type: 'is-danger',
  },
};

export const CREATE_COST_CODE = {
  SUCCESS: {
    message: i18n.t('notifications.createCostCode.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.createCostCode.error'),
    type: 'is-danger',
  },
};

export const DELETE_COST_CODE = {
  SUCCESS: {
    message: i18n.t('notifications.deleteCostCode.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.deleteCostCode.error'),
    type: 'is-danger',
  },
};

export const UPDATE_COST_CODE = {
  SUCCESS: {
    message: i18n.t('notifications.updateCostCode.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateCostCode.error'),
    type: 'is-danger',
  },
};

export const CREATE_COST = {
  SUCCESS: {
    message: i18n.t('notifications.createCost.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.createCost.error'),
    type: 'is-danger',
  },
};

export const UPDATE_COST = {
  SUCCESS: {
    message: i18n.t('notifications.updateCost.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateCost.error'),
    type: 'is-danger',
  },
};

export const DELETE_COST = {
  SUCCESS: {
    message: i18n.t('notifications.deleteCost.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.deleteCost.error'),
    type: 'is-danger',
  },
};

export const FETCH_COST_CODES = {
  ERROR: {
    message: i18n.t('notifications.fetchCostCodes.error'),
    type: 'is-danger',
  },
};

export const BULK_IMPORT_COST_CODES = costCodeCount => ({
  SUCCESS: {
    message: i18n.t('notifications.bulkImportCostCodes.success', { costCodeCount }),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.bulkImportCostCodes.error', { costCodeCount }),
    type: 'is-danger',
  },
});

export const BULK_IMPORT_ACTIVITIES = activityCount => ({
  SUCCESS: {
    message: i18n.t('notifications.bulkImportActivities.success', { activityCount }),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.bulkImportActivities.error', { activityCount }),
    type: 'is-danger',
  },
});

export const UPDATE_USER_PROFILE = {
  SUCCESS: {
    message: i18n.t('notifications.updateUserProfile.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateUserProfile.error'),
    type: 'is-danger',
  },
};

export const UPDATE_USER_ROLES = {
  SUCCESS: {
    message: i18n.t('notifications.updateUserRoles.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateUserRoles.error'),
    type: 'is-danger',
  },
};

export const UPGRADE_SUBSCRIPTION = {
  SUCCESS: {
    message: i18n.t('notifications.upgradeSubscription.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.upgradeSubscription.error'),
    type: 'is-danger',
  },
};

export const RESEND_INVITATION = userDisplayName => ({
  SUCCESS: {
    message: i18n.t('notifications.resendInvitation.success', { userDisplayName }),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.resendInvitation.error', { userDisplayName }),
    type: 'is-danger',
  },
});

export const CREATE_VENDOR = {
  SUCCESS: {
    message: i18n.t('notifications.createVendor.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.createVendor.error'),
    type: 'is-danger',
  },
};

export const UPDATE_VENDOR = {
  SUCCESS: {
    message: i18n.t('notifications.updateVendor.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateVendor.error'),
    type: 'is-danger',
  },
};

export const DELETE_VENDOR = {
  SUCCESS: {
    message: i18n.t('notifications.deleteVendor.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.deleteVendor.error'),
    type: 'is-danger',
  },
};

export const CREATE_CHANGE_ORDER = {
  SUCCESS: {
    message: i18n.t('notifications.createChangeOrder.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.createChangeOrder.error'),
    type: 'is-danger',
  },
};

export const UPDATE_CHANGE_ORDER = {
  SUCCESS: {
    message: i18n.t('notifications.updateChangeOrder.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.updateChangeOrder.error'),
    type: 'is-danger',
  },
};

export const DELETE_CHANGE_ORDER = {
  SUCCESS: {
    message: i18n.t('notifications.deleteChangeOrder.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.deleteChangeOrder.error'),
    type: 'is-danger',
  },
};

export const DELETE_BUDGET = {
  SUCCESS: {
    message: i18n.t('notifications.deleteBudget.success'),
    type: 'is-success',
  },
  ERROR: {
    message: i18n.t('notifications.deleteBudget.error'),
    type: 'is-danger',
  },
};
