import { baseForm, baseField } from '@/forms/base.form';
import { validateEmail } from '@/services/Validation.service';

const createClientForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'createClientForm.name.label',
      name: 'createClientForm.name.name',
      placeholder: 'createClientForm.name.placeholder',
      value: '',
      required: true,
      width: 'is-6',
    },
    description: {
      ...baseField,
      label: 'createClientForm.description.label',
      name: 'createClientForm.description.name',
      placeholder: 'createClientForm.description.placeholder',
      value: '',
      required: false,
      width: 'is-6',
      type: 'textarea',
    },
    contactName: {
      ...baseField,
      label: 'createClientForm.contactName.label',
      name: 'createClientForm.contactName.name',
      placeholder: 'createClientForm.contactName.placeholder',
      value: '',
      required: false,
      width: 'is-4',
    },
    contactEmail: {
      ...baseField,
      label: 'createClientForm.contactEmail.label',
      name: 'createClientForm.contactEmail.name',
      value: '',
      required: false,
      validator: validateEmail,
      width: 'is-4',
    },
    contactPhone: {
      ...baseField,
      label: 'createClientForm.contactPhone.label',
      name: 'createClientForm.contactPhone.name',
      value: '',
      required: false,
      masked: true,
      rawValue: '',
      width: 'is-4',
    },
    id1: {
      ...baseField,
      label: 'createClientForm.id1.label',
      name: 'createClientForm.id1.name',
      value: '',
      required: false,
      width: 'is-4',
    },
    id2: {
      ...baseField,
      label: 'createClientForm.id2.label',
      name: 'createClientForm.id2.name',
      value: '',
      required: false,
      width: 'is-4',
    },
  },
};

export default createClientForm;
