<template>
  <div>
    <BModal
      :active="isOpen"
      hasModalCard
      trapFocus
      ariaModal
      :canCancel="false"
      fullScreen
    >
      <ConfirmActionModal ref="bill-details-modal__confirm-bill-status-update">
        <p>
          <BIcon icon="alert" size="is-large" type="is-danger" />
        </p>
        <p v-if="isLoggedInUserAtLeastAdmin" class="is-size-5 u-m-t-small">{{ $t('confirmReopenBillModal.bodyTextForAdmin') }}</p>
        <p v-else class="is-size-5 u-m-t-small">{{ $t('confirmReopenBillModal.bodyTextForNonAdmin') }}</p>
        <p v-if="isLoggedInUserAtLeastAdmin" class="is-size-5 has-text-weight-bold u-m-t-small">{{ $t('confirmReopenBillModal.bodyCta') }}</p>
      </ConfirmActionModal>
      <ConfirmActionModal ref="bill-details-modal__disallow-bill-status-update__modal">
        <p>
          <BIcon icon="lock" size="is-large" type="is-danger" />
        </p>
        <p class="is-size-5 u-m-t-small">{{ $t('disallowBillStatusUpdateModal.bodyText') }}</p>
      </ConfirmActionModal>
      <div v-if="billId" class="modal-card" id="print-area">
        <div v-if="activeBill" class="modal-card-head" style="align-items: start">
          <div style="width: 100%;">
            <p class="modal-card-title">
              {{ $t('common.terms.bill') }} {{ activeBill.number }}
              <span v-if="activeBill.name">
                &nbsp;-&nbsp;
                {{ activeBill.name }}
              </span>
            </p>
          </div>
          <BButton type="is-text" size="is-small" @click="handleCloseModal">
            <BIcon icon="close" />
          </BButton>
        </div>
        <div class="modal-card-body u-p-t-0 has-background-white">

          <!-- BILL DATA LIKE PROJECT, COST, VENDOR -->
          <div class="u-p-y-small">
            <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
              {{ $t('common.notifications.archivedProject') }}
            </BMessage>
            <div class="is-flex is-justify-content-space-between">
              <div>
                <div class="is-flex">
                  <p class="u-m-r-xsmall is-italic has-text-grey has-text-weight-bold">
                    <BIcon icon="table" size="is-small" />
                    &nbsp;&nbsp;
                    {{ $t('common.terms.project') }}:
                  </p>
                  <p class="u-m-r-xsmall is-italic">
                    {{ activeBill && activeProject.name }}
                  </p>
                </div>
                <div class="is-flex">
                    <p class="u-m-r-xsmall is-italic has-text-grey has-text-weight-bold">
                    <BIcon icon="file-document-edit-outline" size="is-small" />
                    &nbsp;&nbsp;
                    {{ $t('common.terms.cost') }}:
                  </p>
                  <p class="u-m-r-xsmall is-italic">
                    {{ activeBill && costs[activeBill.costId].name }}
                  </p>
                </div>
                <div class="is-flex">
                  <p v-if="activeBill && vendors[costs[activeBill.costId].vendorId]" class="u-m-r-xsmall is-italic has-text-grey has-text-weight-bold">
                    <BIcon icon="account-hard-hat" size="is-small" />
                    &nbsp;&nbsp;
                    {{ $t('common.terms.vendor') }}:
                  </p>
                  <p v-if="activeBill && vendors[costs[activeBill.costId].vendorId]" class="u-m-r-xsmall is-italic">
                    {{ vendors[costs[activeBill.costId].vendorId].name }}
                  </p>
                </div>
              </div>
              <div class="is-flex">
                <BButton
                  @click="handleClickPrint"
                  type="is-primary"
                  size="is-small"
                  outlined
                  iconLeft="printer"
                  class="u-m-r-xsmall"
                >
                  {{ $t('common.ctas.print') }}
                </BButton>
                <BButton
                  @click="handleDownloadAsCsv"
                  type="is-primary"
                  size="is-small"
                  outlined
                  iconLeft="file"
                >
                  {{ $t('common.ctas.download') }}
                </BButton>
              </div>
            </div>
          </div>

          <BTabs
            v-if="!isBillLoading"
            v-model="activeTab"
            class="bill-details-modal__tabs-wrapper" :animated="false"
          >
            <BTabItem :label="$t('billDetailsModal.tabs.summary.label')" class="bill-details-modal__tab-item">
              <div v-if="!isBillLoading && activeCost && activeBill">
                <div class="columns is-mobile u-m-t-small bill-details-modal__bill-summary__row">
                  <div class="column is-6">
                    {{ $t('common.terms.contracted') }}
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(getCostTotalActivityBudget(activeCost.id), {prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
                <div class="columns is-mobile bill-details-modal__bill-summary__row">
                  <div class="column is-6">
                    {{ $t('common.terms.completed') }}
                  </div>
                  <div class="column">
                    {{ convertFractionToPercent(calculateTotalAdvanceSnapshot(activeBill.number) / getCostTotalActivityBudget(activeCost.id)) }}%
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(calculateTotalAdvanceSnapshot(activeBill.number), { prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
                <div class="columns is-mobile bill-details-modal__bill-summary__row">
                  <div class="column is-6">
                    {{ $t('common.terms.previouslyCompleted') }}
                  </div>
                  <div class="column">
                    {{ convertFractionToPercent((activeBill.number > 1 ? calculateTotalAdvanceSnapshot(activeBill.number - 1) : 0) / getCostTotalActivityBudget(activeCost.id)) }}%
                  </div>
                  <div class="column has-text-right">
                    -
                    ({{ displayCurrency(
                        activeBill.number > 1 ? calculateTotalAdvanceSnapshot(activeBill.number - 1) : 0,
                        { prefix: '$', multiplier: 100 },
                      )
                    }})
                  </div>
                </div>
                <div class="u-m-t-large columns u-p-a-0 bill-details-modal__bill-summary__row" />
                <div class="columns is-mobile bill-details-modal__bill-summary__row has-text-weight-bold">
                  <div class="column is-6">
                    {{ $t('common.terms.advanceThisBill') }}
                  </div>
                  <div class="column">
                    {{ convertFractionToPercent(billAdvanceTotal / getCostTotalActivityBudget(activeCost.id)) }}%
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(billAdvanceTotal, { prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
                <div class="columns is-mobile bill-details-modal__bill-summary__row">
                  <div class="column is-6">
                    {{ $t('common.terms.deductions') }}
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(deductionTotal, { prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
                <div class="columns is-mobile bill-details-modal__bill-summary__row">
                  <div class="column is-6">
                    {{ $t('common.terms.downPayment') }}
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(downPaymentTotal, { prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
                <div class="columns is-mobile bill-details-modal__bill-summary__row">
                  <div class="column is-6">
                    {{ $t('common.terms.retainage') }}
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(retainageTotal, { prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
                <div class="columns is-mobile bill-details-modal__bill-summary__row has-text-weight-bold">
                  <div class="column is-6">
                  </div>
                  <div class="column has-text-right">
                    {{ $t('common.terms.subtotal') }}
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(billSubtotalNoTaxes, { prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
                <div class="columns is-mobile bill-details-modal__bill-summary__row">
                  <div class="column is-6">
                  </div>
                  <div class="column has-text-right">
                    {{ $t('common.terms.taxes') }}
                    <span v-if="billTax" class="is-italic">({{ billTax.name}} {{ convertFractionToPercent(billTax.percent) }}%)</span>
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(taxesTotal, { prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
                <div class="columns is-mobile bill-details-modal__bill-summary__row has-text-weight-bold is-size-5">
                  <div class="column is-6">
                  </div>
                  <div class="column has-text-right">
                    {{ $t('common.terms.total') }}
                  </div>
                  <div class="column has-text-right">
                    {{ displayCurrency(billTotal, { prefix: '$', multiplier: 100 }) }}
                  </div>
                </div>
              </div>
              <div v-else style="position: relative; min-height: 150px;">
                <BLoading :is-full-page="false" :active.sync="isBillLoading" :can-cancel="false"></BLoading>
              </div>
            </BTabItem>
            <BTabItem :label="$t('billDetailsModal.tabs.breakdown.label')" class="bill-details-modal__tab-item u-p-t">
              <BillDetailsActivitiesTable :costId="activeCost.id" :activities="activitiesInBreakdown" :billId="billId" />
            </BTabItem>
            <BTabItem :label="$t('billDetailsModal.tabs.comments.label')" class="bill-details-modal__tab-item">
              <div class="bill-details-modal__comments__comment-input">
                <CommentTextInput size="small" @submit="handleSubmitBillComment" />
              </div>
              <div v-if="!areCommentsLoading">
                <div  v-for="comment in billComments" :key="comment.id" class="bill-details-modal__comments__wrapper u-m-t-small">
                  <Comment
                    :commentId="comment.id"
                    :isHighlighted="highlightedComments.includes(comment.id)"
                    customClass="u-p-a-small u-m-b-small"
                  />
                </div>
              </div>
              <div v-else style="position: relative; min-height: 150px;">
                <BLoading :is-full-page="false" :active.sync="isAuditLogLoading" :can-cancel="false"></BLoading>
              </div>
            </BTabItem>
            <BTabItem :label="$t('billDetailsModal.tabs.documents.label')">
              <div v-if="billId" class="u-m-b">
                <div v-if="!areDocumentsLoading">
                  <div v-if="getDocumentsByResourceId(billId).length > 0">
                    <h5 class="title is-5 has-text-grey u-m-y-small">
                      {{ $t('billBreakdownModal.tabs.documents.sections.billDocuments') }}

                    </h5>
                    <div v-for="document in getDocumentsByResourceId(billId)" :key="document.id" class="u-m-b-xsmall">
                      <DocumentLink :documentId="document.id" :resourceId="billId" resourceType="bill" />
                    </div>
                  </div>
                </div>
                <div v-else style="position: relative; min-height: 150px;">
                  <BLoading :is-full-page="false" :active.sync="areDocumentsLoading" :can-cancel="false"></BLoading>
                </div>
              </div>
              <div>
                <h5 class="title is-5 has-text-grey u-m-b-small">{{ $t('billBreakdownModal.tabs.documents.sections.uploadDocuments') }}</h5>
                <div class="columns">
                  <div class="column is-10">
                    <BUpload v-model="documents" multiple dragDrop expanded>
                      <section class="section">
                        <div class="content has-text-centered">
                          <div>
                            <BIcon icon="upload" size="is-large" />
                          </div>
                          <p>{{ $t('common.ctas.fileUpload') }}</p>
                        </div>
                      </section>
                    </BUpload>
                  </div>
                  <div class="column">
                    <BButton
                      style="height: 100%"
                      type="is-primary is-light"
                      :disabled="!documents"
                      iconLeft="upload"
                      @click="handleUploadDocuments"
                    >
                      {{ $t('common.ctas.upload') }}
                    </BButton>
                  </div>
                </div>
                <div class="tags u-m-t-small">
                  <span v-for="(document, index) in documents" :key="index" class="tag is-primary is-light">
                    {{ document.name }}
                    <button class="delete is-small" type="button" @click="handleDeleteDocument(index)"></button>
                  </span>
                </div>
              </div>
            </BTabItem>
            <BTabItem :label="$t('billDetailsModal.tabs.history.label')" class="bill-details-modal__tab-item">
              <div v-if="!isAuditLogLoading" class="u-m-t">
                <BTable
                  v-if="billUpdateLogs.length > 0"
                  :data="billUpdateLogs"
                  hoverable
                  :mobile-cards="false"
                  ref="billUpdateLogsTable"
                  class="card"
                  :defaultSortDirection="'desc'"
                  :defaultSort="['createdAt']"
                  narrowed
                >
                  <BTableColumn
                    field="creator"
                    :label="$t('common.terms.user')"
                    cellClass="vertical-align-middle"
                    v-slot="props"
                  >
                    <div class="is-flex is-align-items-center">
                      <UserAvatar :user="users[props.row.actorUserId]" class="u-m-r-small" size="xsmall" isInline />
                      <UserDisplayName v-if="props.row.actorUserId" :activeUserId="props.row.actorUserId" />
                    </div>
                  </BTableColumn>
                  <BTableColumn
                    field="createdAt"
                    :label="$t('common.terms.createdAt')"
                    cellClass="vertical-align-middle"
                    sortable
                    v-slot="props"
                  >
                    {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale, {}) }}
                    {{ new Date(props.row.createdAt).toLocaleTimeString($i18n.locale, {}) }}
                  </BTableColumn>
                  <BTableColumn
                    field="actionType"
                    :label="$t('common.terms.action')"
                    cellClass="vertical-align-middle"
                    v-slot="props"
                  >
                    {{ $t(`auditLogActionTypes.${props.row.actionType}`) }}
                  </BTableColumn>
                  <BTableColumn
                    field="changes"
                    :label="$t('common.terms.changes')"
                    cellClass="vertical-align-middle"
                    v-slot="props"
                  >
                    <div v-if="isStatusUpdate(props.row)">
                      <span class="is-italic u-m-r-small">{{ $t('common.terms.billStatus') }}:</span>
                      <BillStatusTag
                        v-if="getAuditLogStateDiff(props.row).previousState.statusId"
                        :billStatusId="getAuditLogStateDiff(props.row).previousState.statusId"
                        size="small"
                        :isEditable="false"
                      />
                      &#x2192;
                      <BillStatusTag
                        v-if="getAuditLogStateDiff(props.row).currentState.statusId"
                        :billStatusId="getAuditLogStateDiff(props.row).currentState.statusId"
                        size="small"
                        :isEditable="false"
                      />
                    </div>
                    <div
                      v-else-if="isObjectEmpty(getAuditLogStateDiff(props.row).previousState) && isObjectEmpty(getAuditLogStateDiff(props.row).currentState)"
                      class="is-italic"
                    >
                      {{ $t('common.terms.noChanges') }}
                    </div>
                    <div v-else-if="props.row.actionType === 'update'" class="is-italic">
                      {{ $t('billDetailsModal.tabs.history.changesNotVisible') }}
                    </div>
                  </BTableColumn>
                </BTable>
              </div>
              <div v-else style="position: relative; min-height: 150px;">
                <BLoading :is-full-page="false" :active.sync="isAuditLogLoading" :can-cancel="false"></BLoading>
              </div>
            </BTabItem>
          </BTabs>
        </div>
        <footer class="modal-card-foot bill-details-modal__card__footer">
          <div style="width: 100%">
            <div class="columns is-mobile is-vcentered">
              <div class="column">
                <small class="has-text-weight-bold">{{ $t('billDetailsModal.footer.billTotal') }}:</small>
                <h3 class="title is-3 is-size-5-mobile has-text-weight-black">
                  <AnimatedNumber
                    :value="billTotal"
                    :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                    :duration="400"
                  />
                </h3>
              </div>
              <div class="column has-text-right">
                <BillStatusTag
                  v-if="!isBillLoading"
                  :billStatusId="activeBill.statusId"
                  :isEditable="!isProjectArchived(activeProject.id)"
                  size="large"
                  :isLoading="isBillUpdateInProgress"
                  @update="handleUpdateBillStatus"
                />
              </div>
            </div>
            <div v-if="getCurrentlyUploadingDocumentsByResourceId(billId).length > 0" class="columns">
              <div class="column has-text-centered">
                <div class="is-flex is-size-7 is-align-items-center">
                  <progress class="progress is-small is-primary is-inline u-m-r-small" max="100" style="width: 40px;" />
                  {{ $t('common.terms.uploading') }} {{ getCurrentlyUploadingDocumentsByResourceId(billId).length }}
                  {{ getCurrentlyUploadingDocumentsByResourceId(billId).length > 1 ? $t('common.terms.documents') : $t('common.terms.document') }}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </BModal>
  </div>
</template>

<script>
import Papaparse from 'papaparse';
import { saveAs } from 'file-saver';
import { mapState, mapGetters } from 'vuex';
import AnimatedNumber from 'animated-number-vue';
import { diff } from 'deep-object-diff';
import { deepDictionary, deepRemoveKeys, isObjectEmpty } from '@/helpers/dataHelpers';
import * as Types from '@/constants/Types';
import * as BillStatusConstants from '@/constants/BillStatus.constants';
import * as AuditLogActions from '@/store/actions/AuditLog.actions';
import * as CommentActions from '@/store/actions/Comment.actions';
import * as DocumentActions from '@/store/actions/Document.actions';
import * as BillActions from '@/store/actions/Bill.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as Flags from '@/constants/Flags';
import { displayCurrency } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import { shapeDocumentDisplayName } from '@/helpers/documentHelpers';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import BillStatusTag from '@/components/BillStatusTag.vue';
import UserDisplayName from '@/components/UserDisplayName.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import BillDetailsActivitiesTable from '@/components/BillDetailsActivitiesTable.vue';
import CommentTextInput from '@/components/CommentTextInput.vue';
import Comment from '@/components/Comment.vue';
import ConfirmActionModal from '@/components/ConfirmActionModal.vue';
import DocumentLink from '@/components/DocumentLink.vue';

export default {
  name: 'BillDetailsModal',

  components: {
    AnimatedNumber,
    BillStatusTag,
    UserDisplayName,
    UserAvatar,
    BillDetailsActivitiesTable,
    CommentTextInput,
    Comment,
    ConfirmActionModal,
    DocumentLink,
  },

  props: {
    billId: {
      type: [String, null],
      required: false,
      default: null,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      activeTab: 0,
      isBillUpdateInProgress: false,
      isAuditLogLoading: false,
      areCommentsLoading: false,
      isBillLoading: false,
      highlightedComments: [],
      documents: null,
      areDocumentsLoading: false,
      BillStatusConstants,
    };
  },

  computed: {
    ...mapState({
      auditLogs: state => state.auditLog.auditLogs,
      bills: state => state.bill.bills,
      billItems: state => state.billItem.billItems,
      costs: state => state.cost.costs,
      activities: state => state.activity.activities,
      projects: state => state.project.projects,
      taxes: state => state.tax.taxes,
      vendors: state => state.vendor.vendors,
      users: state => state.user.users,
    }),
    ...mapGetters([
      'getBillItemTypeByName',
      'getBillingTypeByName',
      'getBillsByCostId',
      'getTaxByBillId',
      'getBillItemsByBillId',
      'getBillItemsByBillIdAndTypeName',
      'getBillItemsByActivityId',
      'getCostTotalActivityBudget',
      'getCostTotalAdvanceAmount',
      'getCostTotalAdvancePercent',
      'getCostTotalClosedAmount',
      'getCostTotalClosedTaxAmount',
      'getCostTotalDownPaymentAmount',
      'getCostTotalRetainageAmount',
      'getCostTotalDeductionAmount',
      'getClosedBillItemsByCostId',
      'getAuditLogsByResourceId',
      'getCostActivities',
      'getBillActivities',
      'getCommentsByResourceId',
      'getDocumentsByResourceId',
      'getCurrentlyUploadingDocumentsByResourceId',
      'isProjectArchived',
      'getBillStatusByName',
      'isLoggedInUserAtLeastAdmin',
      'loggedInUser',
      'activeOrganization',
    ]),
    activeBill() {
      return this.bills[this.billId];
    },
    activeCost() {
      return this.costs[this.activeBill?.costId];
    },
    activeProject() {
      return this.projects[this.activeCost.projectId];
    },
    billTax() {
      return this.getTaxByBillId(this.activeBill.id);
    },
    activeBillItems() {
      return this.getBillItemsByBillId(this.activeBill?.id);
    },
    advanceBillItems() {
      return this.getBillItemsByBillIdAndTypeName(this.activeBill.id, Types.billItemTypes.advance);
    },
    activitiesInBreakdown() {
      return this.isAdvanceBillingType ? this.getCostActivities(this.activeCost.id) : this.getBillActivities(this.billId);
    },
    isAdvanceBillingType() {
      return this.activeCost?.billingTypeId === this.getBillingTypeByName(Types.billingTypes.advance).id;
    },
    isLumpSumBillingType() {
      return this.activeCost?.billingTypeId === this.getBillingTypeByName(Types.billingTypes.lumpSum).id;
    },
    billAdvanceTotal() {
      return this.advanceBillItems.reduce((total, billItem) => (billItem.amount + total), 0);
    },
    billAdvanceTaxes() {
      return this.advanceBillItems
        .filter(billItem => billItem.isTaxed)
        .reduce((acc, curr) => (acc + (curr.amount * (this.taxes[this.activeBill.taxId]?.percent || 0))), 0);
    },
    deductionBillItems() {
      return this.activeBillItems.filter(billItem => billItem.billItemTypeId === this.getBillItemTypeByName(Types.billItemTypes.deduction).id);
    },
    deductionTotal() {
      return this.deductionBillItems.reduce((total, billItem) => (billItem.amount + total), 0);
    },
    retainageBillItems() {
      return this.activeBillItems.filter(billItem => billItem.billItemTypeId === this.getBillItemTypeByName(Types.billItemTypes.retainage).id);
    },
    retainageTotal() {
      return this.retainageBillItems.reduce((total, billItem) => (billItem.amount + total), 0);
    },
    downPaymentBillItems() {
      return this.activeBillItems.filter(billItem => billItem.billItemTypeId === this.getBillItemTypeByName(Types.billItemTypes.downPayment).id);
    },
    downPaymentTotal() {
      return this.downPaymentBillItems.reduce((total, billItem) => (billItem.amount + total), 0);
    },
    taxesTotal() {
      if (!this.activeBill || !this.activeBill.taxId) return 0;

      return this.activeBillItems
        .filter(billItem => billItem.isTaxed)
        .reduce((acc, curr) => (acc + (curr.amount * (this.taxes[this.activeBill.taxId]?.percent || 0))), 0);
    },
    billSubtotalNoTaxes() {
      return this.activeBillItems.reduce((acc, curr) => (acc + curr.amount), 0);
    },
    billTotal() {
      return this.billSubtotalNoTaxes + this.taxesTotal;
    },
    billUpdateLogs() {
      return this.getAuditLogsByResourceId(this.billId)
        .filter(auditLog => auditLog.actionType === 'create' || auditLog.actionType === 'update');
    },
    billComments() {
      return this.getCommentsByResourceId(this.billId)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    isBillClosed() {
      const closedBillStatus = this.getBillStatusByName('closed');

      return this.activeBill.statusId === closedBillStatus.id;
    },
    isBillApproved() {
      const approvedBillStatus = this.getBillStatusByName('approved');

      return this.activeBill.statusId === approvedBillStatus.id;
    },
  },

  watch: {
    isOpen(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.fetchBillAuditLog();
        this.fetchBillComments();
        this.fetchBill();
        this.fetchBillDocuments();
      }
    },
  },

  methods: {
    displayCurrency,
    convertFractionToPercent,
    isObjectEmpty,
    shapeDocumentDisplayName,
    handleCloseModal() {
      this.$emit('close');
    },
    async handleClickPrint() {
      await this.$htmlToPaper('print-area');
    },
    handleDownloadAsCsv() {
      const calculateActivityAdvanceSnapshot = (billNumber, activity) => (
        [...this.getBillItemsByActivityId(activity.id)]
          .map(billItem => ({ ...billItem, bill: this.bills[billItem.billId] }))
          .sort((a, b) => b.bill.number - a.bill.number)
          .reverse()
          .slice(0, billNumber)
          .reduce((total, curr) => (total + curr?.amount ?? 0), 0)
      );

      const getAdvanceBillItems = billId => this.getBillItemsByBillIdAndTypeName(billId, Types.billItemTypes.advance);

      const sortedActivities = [...this.activitiesInBreakdown.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })];
      const template = Papaparse.unparse({
        fields: [
          this.$t('common.terms.activity').toUpperCase(),
          this.$t('common.terms.unit').toUpperCase(),
          this.$t('common.terms.unitValue').toUpperCase(),
          this.$t('common.terms.quantity').toUpperCase(),
          `${this.$t('common.terms.contracted')} ${this.$t('common.terms.value')}`.toUpperCase(),
          `${this.$t('common.terms.advanceThisBill')} ${this.$t('common.terms.unit')}`.toUpperCase(),
          `${this.$t('common.terms.advanceThisBill')} ${this.$t('common.terms.value')}`.toUpperCase(),
          // `${this.$t('common.terms.advanceThisBill')} ${this.$t('common.terms.percent')}`.toUpperCase(),
          `${this.$t('common.terms.totalAdvance')} ${this.$t('common.terms.unit')}`.toUpperCase(),
          `${this.$t('common.terms.totalAdvance')} ${this.$t('common.terms.value')}`.toUpperCase(),
          // `${this.$t('common.terms.totalAdvance')} ${this.$t('common.terms.percent')}`.toUpperCase(),
        ],
        data: [
          ...sortedActivities.map(activity => [
            activity.name,
            activity.unit,
            displayCurrency(activity.unitValue, { multiplier: 100 }),
            activity.quantity,
            displayCurrency(calculateActivityBudget(activity.unitValue, activity.quantity), { prefix: '$', multiplier: 100 }),
            `${((getAdvanceBillItems(this.activeBill.id).find(billItem => billItem.activityId === activity.id)?.amount || 0) / activity.unitValue).toFixed(1)}`,
            displayCurrency(getAdvanceBillItems(this.activeBill.id).find(billItem => billItem.activityId === activity.id)?.amount || 0, { prefix: '$', multiplier: 100 }),
            // `${convertFractionToPercent((getAdvanceBillItems(this.activeBill.id).find(billItem => billItem.activityId === activity.id)?.amount || 0) / this.getCostTotalActivityBudget(this.activeCost.id))}%`,
            `${(calculateActivityAdvanceSnapshot(this.activeBill.number, activity) / activity.unitValue).toFixed(1)}`,
            displayCurrency(calculateActivityAdvanceSnapshot(this.activeBill.number, activity), { multiplier: 100, prefix: '$' }),
            // `${convertFractionToPercent(calculateActivityAdvanceSnapshot(this.activeBill.number, activity) / this.getCostTotalActivityBudget(this.activeCost.id))}%`,
          ]),
        ],
      });
      const fileName = `${this.activeProject.name}_${this.activeCost.name}_${this.$t('common.terms.bill')}_${this.activeBill.number}_${this.activeBill.name}`;
      const file = new File([template], fileName, {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(file);
    },
    handleDeleteDocument(index) {
      this.documents.splice(index, 1);
    },
    async handleUpdateBillStatus({ billStatus }) {
      // If org can open bills after previous is APPROVED
      // Then show modal when bill moves from APPROVED or CLOSED
      // to any other status.
      if (this.$GrowthBook.isOn(Flags.OPEN_BILL_WHEN_PREVIOUS_APPROVED)) {
        if (
          (this.isBillClosed || this.isBillApproved)
          && (
            billStatus.id !== this.getBillStatusByName('closed').id
            && billStatus.id !== this.getBillStatusByName('approved').id
          )
        ) {
          const isConfirmed = await this.showReopenConfirmationModal();
          if (!isConfirmed) {
            return; // return early to cancel action.
          }
        }
      } else if (this.isBillClosed) { // Default behavior where modal is shown when moving bills from CLOSED status.
        const isConfirmed = await this.showReopenConfirmationModal();
        if (!isConfirmed) {
          return; // return early to cancel action.
        }
      }

      if (
        this.activeOrganization.id === Flags.FLAGGED_ORG_ID
        && this.loggedInUser.id !== Flags.ALLOWED_USER_ID
      ) {
        if (
          (this.isBillApproved && billStatus.id !== this.getBillStatusByName('closed').id)
          || (!this.isBillClosed && billStatus.id === this.getBillStatusByName('approved').id)
          || (!this.isBillApproved && billStatus.id === this.getBillStatusByName('closed').id)
        ) {
          const allowed = await this.$refs['bill-details-modal__disallow-bill-status-update__modal'].show({
            title: this.$t('disallowBillStatusUpdateModal.title'),
            // ...this.isLoggedInUserAtLeastAdmin && { confirmButton: this.$t('disallowBillStatusUpdateModal.confirmCta') },
            cancelButton: this.$t('disallowBillStatusUpdateModal.cancelCta'),
          });
          if (!allowed) {
            this.isBillInProgress = null;
            // Wrapped in timeout so that it executes after other containers
            // have finished their `applyDrag` and so this one will be the final arbiter of truth.
            setTimeout(() => {
              this.createBillStatusLists();
            }, 100);
            return;
          }
        }
      }
      this.isBillUpdateInProgress = true;
      try {
        await this.$store.dispatch(
          BillActions.UPDATE_BILL_STATUS_BY_BILL_ID,
          { billId: this.billId, updatedBill: { statusId: billStatus.id } },
        );
        this.$emit('update');
        this.fetchBillAuditLog();
      } catch (err) {
        NotificationService.showNotification(NotificationTypes.UPDATE_BILL.ERROR);
      }
      this.isBillUpdateInProgress = false;
    },
    async handleSubmitBillComment(content) {
      try {
        const newCommentId = await this.$store.dispatch(
          CommentActions.CREATE_COMMENT,
          {
            resourceType: 'bill',
            resourceId: this.activeBill.id,
            content,
          },
        );

        this.highlightedComments = [...this.highlightedComments, newCommentId];
        setTimeout(() => {
          this.highlightedComments = this.highlightedComments.filter(id => id !== newCommentId);
        }, 3200);
      } catch (err) {
        this.commentApiError = err;
      }
    },
    async fetchBillAuditLog() {
      this.isAuditLogLoading = true;

      try {
        await this.$store.dispatch(
          AuditLogActions.FETCH_AUDIT_LOGS_BY_RESOURCE_ID,
          { resourceId: this.billId, resourceType: 'bill' },
        );
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_BILL_HISTORY.ERROR);
      }

      this.isAuditLogLoading = false;
    },
    async fetchBillComments() {
      this.areCommentsLoading = true;

      try {
        await this.$store.dispatch(
          CommentActions.FETCH_COMMENTS_BY_RESOURCE_ID,
          { resourceId: this.billId, resourceType: 'bill' },
        );
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_BILL_HISTORY.ERROR);
      }

      this.areCommentsLoading = false;
    },
    async fetchBillDocuments() {
      this.areDocumentsLoading = true;

      try {
        await this.$store.dispatch(
          DocumentActions.FETCH_DOCUMENTS_BY_RESOURCE_ID,
          { resourceId: this.billId, resourceType: 'bill' },
        );
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_BILL_HISTORY.ERROR);
      }

      this.areDocumentsLoading = false;
    },
    async fetchBill() {
      this.isBillLoading = true;

      try {
        await this.$store.dispatch(BillActions.FETCH_BILL_BY_ID, { billId: this.billId });
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_BILL_HISTORY.ERROR);
      }

      this.isBillLoading = false;
    },
    /**
     * Calculates the total advance up until a specific bill number.
     */
    calculateTotalAdvanceSnapshot(billNumber) {
      return this.getBillsByCostId(this.activeCost.id)
        .sort((billA, billB) => billA.number - billB.number)
        .slice(0, billNumber)
        .reduce((total, currBill) => {
          const advanceBillItems = this.getBillItemsByBillIdAndTypeName(currBill.id, Types.billItemTypes.advance);
          const advanceBillItemsTotal = advanceBillItems.reduce((advanceTotal, currBillItem) => (advanceTotal + currBillItem.amount), 0);

          return total + advanceBillItemsTotal;
        }, 0);
    },
    /**
     * Calculates the total advance up until a specific bill number.
     */
    getBillIdsUpToThisBill(billNumber) {
      return this.getBillsByCostId(this.activeCost.id)
        .sort((billA, billB) => billA.number - billB.number)
        .slice(0, billNumber)
        .map(bill => bill.id);
    },
    /**
     * Return bills that are sequentially AFTER the active bill. Used to get snapshots up to a specific bill number.
     */
    getExcludedBillIds(billNumber) {
      return this.getBillsByCostId(this.activeCost.id)
        .filter(bill => !this.getBillIdsUpToThisBill(billNumber).includes(bill.id))
        .map(bill => bill.id);
    },
    getAuditLogStateDiff(auditLog) {
      const { previousState, currentState } = auditLog;
      const previousStateDictionaries = deepRemoveKeys(deepDictionary(previousState), ['updatedAt']);
      const currentStateDictionaries = deepRemoveKeys(deepDictionary(currentState), ['updatedAt']);

      const shapedNewState = diff(previousStateDictionaries, currentStateDictionaries);
      const shapedPrevState = diff(currentStateDictionaries, previousStateDictionaries);

      return {
        ...auditLog,
        previousState: shapedPrevState,
        currentState: shapedNewState,
      };
    },
    isStatusUpdate(auditLog) {
      const auditLogDiff = this.getAuditLogStateDiff(auditLog);
      return (
        auditLog.actionType === 'update'
        && auditLogDiff.previousState?.statusId !== auditLogDiff.currentState?.statusId
      );
    },
    handleUploadDocuments() {
      this.documents.forEach(file => {
        this.$store.dispatch(DocumentActions.CREATE_DOCUMENT, {
          resourceType: 'bill',
          resourceId: this.billId,
          file,
        });
      });

      this.documents = null;
    },
    async showReopenConfirmationModal() {
      const confirmed = await this.$refs['bill-details-modal__confirm-bill-status-update'].show({
        title: this.$t('confirmReopenBillModal.title'),
        ...this.isLoggedInUserAtLeastAdmin && { confirmButton: this.$t('confirmReopenBillModal.confirmCta') },
        cancelButton: this.$t('confirmReopenBillModal.cancelCta'),
      });

      return confirmed;
    },
  },
};
</script>

<style lang="scss">
$bill-details-modal-card-footer-height: 104px;

.bill-details-modal__bill-item__row {
  padding: ($global-whitespace-xsmall / 2) $global-whitespace-base;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-lightest;
  }

  &:hover {
    background: $white-ter;
  }
}

.bill-details-modal__bill-summary__row {
  padding: ($global-whitespace-xsmall / 2) $global-whitespace-base;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-lightest;
  }

  &:hover {
    background: $white-ter;
  }
}

.bill-details-modal__tabs-wrapper {
  margin: 0 -20px;
  background: $white;

  > nav {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $white;
  }

  .tab-content {
    padding-top: 0;
  }
}

.bill-details-modal__tab-item {
  min-height: 300px;
  background: $white;
}

.bill-details-modal__card__footer {
  min-height: $bill-details-modal-card-footer-height;
}

.bill-details-modal__comments__comment-input {
  position: sticky;
  top: 36px;
  z-index: 1;
  bottom: $bill-details-modal-card-footer-height;
  display: flex;
  width: 100%;
  background: white;
  padding-top: $global-whitespace-base;
  padding-bottom: $global-whitespace-base;
}

.bill-details-modal__comments__wrapper {
  margin: 0 -$global-whitespace-small;
}
</style>
