import * as firebase from 'firebase/app';
import 'firebase/auth';
import cloneDeep from 'lodash.clonedeep';
import i18n from '@/i18n';
import * as AuthMutations from '@/store/mutations/Auth.mutations';
import * as AuthActions from '@/store/actions/Auth.actions';

const getInitialState = () => ({
  logInError: null,
  isLogInFormLoading: false,
  isSignUpFormLoading: false,
  isSessionAuthLoading: false,
  isUserLoggingOut: false,
  authUser: {},
});

const state = getInitialState();

const getters = {};

const mutations = {
  [AuthMutations.SET_AUTH_USER](state, payload) {
    state.isLogInFormLoading = false;
    state.isSessionAuthLoading = false;
    state.isSignUpFormLoading = false;
    state.logInError = null;
    state.authUser = cloneDeep(payload.authUser?.toJSON());
  },

  [AuthMutations.SET_LOG_IN_ERROR](state, payload) {
    state.isLogInFormLoading = false;
    state.isSessionAuthLoading = false;
    state.isSignUpFormLoading = false;
    state.logInError = payload;
  },

  [AuthMutations.LOG_IN_FORM_REQUEST_DATA](state) {
    state.isLogInFormLoading = true;
  },

  [AuthMutations.SIGN_UP_FORM_REQUEST_DATA](state) {
    state.isSignUpFormLoading = true;
  },

  [AuthMutations.SET_LOG_OUT_IN_PROGRESS](state) {
    state.isUserLoggingOut = true;
  },

  [AuthMutations.LOG_OUT](state) {
    Object.assign(state, getInitialState());
  },

  [AuthMutations.SESSION_AUTH_REQUEST_DATA](state) {
    state.isSessionAuthLoading = true;
  },
};

const actions = {
  async [AuthActions.CREATE_AUTH_USER_WITH_EMAIL_AND_PASSWORD](context, payload) {
    const { email, password } = payload;
    context.commit(AuthMutations.SIGN_UP_FORM_REQUEST_DATA);

    const { user: authUser } = await firebase.auth().createUserWithEmailAndPassword(email, password);
    // await firebase.auth().currentUser.reload();
    context.commit(
      AuthMutations.SET_AUTH_USER,
      { authUser },
    );
  },

  async [AuthActions.SEND_EMAIL_VERIFICATION]() {
    firebase.auth().languageCode = i18n.locale;
    await firebase.auth().currentUser.sendEmailVerification();
  },

  async [AuthActions.APPLY_EMAIL_VERIFICATION_CODE](context, payload) {
    firebase.auth().languageCode = i18n.locale;
    await firebase.auth().applyActionCode(payload.actionCode);
    await firebase.auth().currentUser.reload();

    context.commit(
      AuthMutations.SET_AUTH_USER,
      { authUser: firebase.auth().currentUser },
    );
  },

  async [AuthActions.VERIFY_PASSWORD_RESET_CODE](context, payload) {
    firebase.auth().languageCode = i18n.locale;
    const email = await firebase.auth().verifyPasswordResetCode(payload.actionCode);

    return email;
  },

  async [AuthActions.CONFIRM_PASSWORD_RESET](context, payload) {
    const { actionCode, newPassword } = payload;
    await firebase.auth().confirmPasswordReset(actionCode, newPassword);
  },

  async [AuthActions.SEND_PASSWORD_RESET_EMAIL](context, payload) {
    await firebase.auth().sendPasswordResetEmail(payload.userEmail);
  },

  async [AuthActions.LOG_IN_WITH_EMAIL_AND_PASSWORD](context, payload) {
    const { userEmail, userPassword } = payload;
    context.commit(AuthMutations.LOG_IN_FORM_REQUEST_DATA);
    try {
      const { user: authUser } = await firebase.auth().signInWithEmailAndPassword(userEmail, userPassword);

      context.commit(
        AuthMutations.SET_AUTH_USER,
        { authUser },
      );
      return;
    } catch (err) {
      context.commit(AuthMutations.SET_LOG_IN_ERROR, err);
      console.error(err); // eslint-disable-line no-console
      throw Error(err);
    }
  },

  // async [AuthActions.LOG_IN_WITH_GOOGLE_AUTH](context) {
  //   context.commit(AuthMutations.GOOGLE_AUTH_REQUEST_DATA);
  //   const provider = new firebase.auth.GoogleAuthProvider();

  //   try {
  //     firebase.auth().signInWithRedirect(provider);
  //   } catch (error) {
  //     context.commit(AuthMutations.SET_LOG_IN_ERROR, error);
  //     console.error(error); // eslint-disable-line no-console
  //   }
  // },

  async [AuthActions.LOG_IN_WITH_EXISTING_SESSION_TOKEN](context) {
    context.commit(AuthMutations.SESSION_AUTH_REQUEST_DATA);
    const authUser = firebase.auth().currentUser;
    if (!authUser) { // No active firebase session found in browser.
      context.commit(AuthMutations.LOG_OUT);
    // Session exists but data is not in Vuex store.
    }
    context.commit(
      AuthMutations.SET_AUTH_USER,
      { authUser },
    );
  },

  async [AuthActions.LOG_OUT_USER](context) {
    context.commit(AuthMutations.SESSION_AUTH_REQUEST_DATA);
    context.commit(AuthMutations.SET_LOG_OUT_IN_PROGRESS);

    await firebase.auth().signOut();
    context.commit(AuthMutations.LOG_OUT);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
