/**
 * Load our base configurations.
 * This will be merged with envioronment specific settings.
 */
const LOCAL_CONFIG = require('./env/local.json');
const DEV_CONFIG = require('./env/dev.json');
// const STAGE_CONFIG = require('./env/stage.json');
const PROD_CONFIG = require('./env/prod.json');

/**
 * Class to detect and load the envioronment settings.
 */
const AppConfig = {
  /**
   * Detect the current envioronment base on the host url.
   *
   * @returns {string} - The environment code.
   */
  detectEnv() {
    const currentHost = window.location.hostname;
    const devHosts = [
      'contract-flow-dev.web.app',
      'contract-flow-dev.firebaseapp.com',
      'dev.contractflow.io',
    ];

    const prodHosts = [
      'contract-flow-production.firebaseapp.com',
      'contract-flow-production.web.app',
      'app.contractflow.io',
    ];

    let env;
    if (devHosts.includes(currentHost)) {
      env = 'dev';
    } else if (prodHosts.includes(currentHost)) {
      env = 'prod';
    } else {
      env = 'local';
    }

    return env;
  },

  /**
   * Get the configuration of the specific envioronment.
   *
   * @param {string} env - The detected environment.
   * @returns {Object} - Key-value pair of settings.
   * @see `AppConfig.detectEnv()`
   */
  getConfig(env) {
    let config = {};

    if (env === 'local') {
      config = LOCAL_CONFIG;
    } else if (env === 'dev') {
      config = DEV_CONFIG;
    } else if (env === 'prod') {
      config = PROD_CONFIG;
    }

    return { ...config };
  },

  /**
   * Load the envioronment configuration.
   *
   * @returns {Object} - The envioronment configuration.
   */
  load() { // eslint-disable-line class-methods-use-this
    const env = this.detectEnv();
    const config = this.getConfig(env);

    return { env, config };
  },

  install(Vue) {
    Vue.prototype.$env = this.load().env; // eslint-disable-line no-param-reassign
    Vue.prototype.$env = this.load().config; // eslint-disable-line no-param-reassign
  },
};

export default AppConfig;
