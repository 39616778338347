<template>
  <div
    :class="[
      'card-row__container',
      { 'card-row__container--clickable': isClickable },
      { 'card-row__container--compact': isCompact },
    ]"
    @click="handleClick"
    @keyup="handleKeyUp"
  >
    <div v-if="$slots['content-left']" class="card-row__left">
      <slot name="content-left">
      </slot>
    </div>
    <div v-if="$slots.default" class="card-row__center">
      <slot></slot>
    </div>
    <div v-if="$slots['content-right']" class="card-row__right">
      <slot name="content-right">
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardRow',

  props: {
    isClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCompact: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
    handleKeyUp(e) {
      this.$emit('keyup', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-row__container {
  display: flex;
  flex-wrap: wrap;
  padding: $global-whitespace-medium $global-whitespace-small;
  justify-content: space-between;

  &:hover {
    background-color: $white-bis;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $grey-light;
  }

  &--compact {
    padding: $global-whitespace-small;
  }
}

.card-row__left,
.card-row__right,
.card-row__center {
  flex: 1;
  display: flex;
  align-items: center;
}

.card-row__left {
  justify-content: flex-start;
}

.card-row__right {
  justify-content: flex-end
}

.card-row__center {
  justify-content: center;
}

.card-row__container--clickable {
  cursor: pointer;

  &:hover {
    border-left: 2px solid $primary;
    margin-left: -2px;
  }
}
</style>
