<template>
  <BTable
    :data="tableData"
    :loading="isLoading"
    :total="total"
    paginated
    backendPagination
    backendSorting
    :perPage="pageSize"
    ariaNextLabel="Next page"
    ariaPreviousLabel="Previous page"
    ariaPageLabel="Page"
    ariaCurrentLabel="Current page"
    @page-change="handlePageChange"
    @sort="handleSort"
    hoverable
    :mobileCards="false"
    ref="vendorsTable"
    class="card u-p-b"
    :defaultSortDirection="'asc'"
    :defaultSort="['name']"
    narrowed
    scrollable
  >
    <CreateOrEditVendorModal
      :isOpen="!!vendorIdEditing"
      @close="handleCloseEditModal"
      @submitSuccess="handleUpdateVendorSuccess"
      :activeVendorId="vendorIdEditing"
    />
    <BModal
      :active="!!vendorIdConfirmingDelete"
      @close="handleCloseDeleteModal"
      hasModalCard
    >
      <div v-if="!isFetchingVendorDetails" class="modal-card">
        <header class="modal-card-head">
          <p v-if="vendorIdConfirmingDelete" class="modal-card-title">
            {{ $t('common.ctas.delete') }}
            {{ $t('common.terms.vendor') }}
          </p>
          <button
            type="button"
            class="delete"
            @click="handleCloseDeleteModal"
          />
        </header>
        <section v-if="vendors[vendorIdConfirmingDelete]" class="modal-card-body has-background-white u-p-a has-text-centered">
          <div v-if="canDeleteVendor">
            <p>
              <BIcon icon="alert" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('vendorsTable.deleteVendorModal.warning') }}</h5>
            <p>
              {{ vendors[vendorIdConfirmingDelete].name }}
            </p>
          </div>
          <div v-else>
            <p>
              <BIcon icon="alert-octagon" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('vendorsTable.deleteVendorModal.notAllowed') }}</h5>
            <ul>
              <li
                v-for="costId in vendors[vendorIdConfirmingDelete].costs"
                :key="costId.key"
                class="has-text-weight-bold u-p-b-small"
              >
                {{ costs[costId].name }}
              </li>
            </ul>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleCloseDeleteModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-danger"
              @click="handleConfirmDeleteVendor(vendorIdConfirmingDelete)"
              :loading="vendorsDeleting.includes(vendorIdConfirmingDelete)"
              :disabled="!canDeleteVendor"
            >
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.vendor') }}
            </BButton>
          </div>
        </footer>
      </div>
    </BModal>
    <template slot="empty">
      <div class="has-text-grey u-p-a has-text-centered">
        {{ $t('vendorsTable.emptyState') }}
      </div>
    </template>
    <BTableColumn
      field="name"
      :label="$t('vendorsTable.columns.name')"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      <p class="has-text-weight-bold">{{ props.row.name }}</p>
      <p v-if="props.row.legalId1" class="is-size-7"><BIcon icon="card-account-details-outline" size="is-small" />&nbsp;{{ props.row.legalId1 }}</p>
      <p v-if="props.row.legalId2" class="is-size-7"><BIcon icon="card-account-details-outline" size="is-small" />&nbsp;{{ props.row.legalId2 }}</p>
    </BTableColumn>
    <BTableColumn
      field="contactName"
      :label="$t('vendorsTable.columns.contactName')"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      <p class="has-text-weight-bold">{{ props.row.contactName }}</p>
      <p v-if="props.row.contactEmail" class="is-size-7"><BIcon icon="email-outline" size="is-small" />&nbsp;{{ props.row.contactEmail }}</p>
      <p v-if="props.row.contactPhone" class="is-size-7"><BIcon icon="phone-outline" size="is-small" />&nbsp;{{ props.row.contactPhone }}</p>
    </BTableColumn>
    <BTableColumn
      field="createdAt"
      :label="$t('vendorsTable.columns.createdAt')"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale, {}) }}
    </BTableColumn>
    <BTableColumn
      cell-class="vertical-align-middle"
      width="140"
      v-slot="props"
    >
      <div class="buttons" style="justify-content: center;">
        <BButton
          v-if="isLoggedInUserAtLeastProjectManager"
          type="is-primary is-light"
          @click="() => handleClickEditVendor(props.row.id)"
        >
          <BIcon icon="pencil" size="mdi-18px" />
        </BButton>
        <BButton
          v-if="isLoggedInUserAtLeastAdmin"
          type="is-primary is-light"
          @click="() => handleClickDeleteVendor(props.row.id)"
          :loading="isFetchingVendorDetails && vendorIdConfirmingDelete === props.row.id"
        >
          <BIcon icon="delete" size="mdi-18px" />
        </BButton>
      </div>
    </BTableColumn>
  </BTable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as VendorActions from '@/store/actions/Vendor.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import CreateOrEditVendorModal from '@/components/CreateOrEditVendorModal.vue';

export default {
  name: 'VendorsTable',

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 20,
    },
    tableData: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },

  emits: [
    'pageChange',
    'sort',
    'updateVendor',
    'deleteVendor',
  ],

  components: {
    CreateOrEditVendorModal,
  },

  data() {
    return {
      vendorsDeleting: [],
      vendorIdEditing: null,
      vendorIdConfirmingDelete: null,
      isFetchingVendorDetails: false,
    };
  },

  computed: {
    ...mapState({
      costs: state => state.cost.costs,
      vendors: state => state.vendor.vendors,
    }),
    ...mapGetters([
      'isLoggedInUserAtLeastProjectManager',
      'isLoggedInUserAtLeastAdmin',
    ]),
    canDeleteVendor() {
      return this.vendorIdConfirmingDelete && this.vendors[this.vendorIdConfirmingDelete]?.costs.length === 0;
    },
  },

  methods: {
    handleClickEditVendor(vendorId) {
      this.vendorIdEditing = vendorId;
    },
    handleCloseEditModal() {
      this.vendorIdEditing = null;
    },
    handleCloseDeleteModal() {
      this.vendorIdConfirmingDelete = null;
    },
    handlePageChange(page) {
      this.$emit('pageChange', page);
    },
    handleSort(field, order) {
      this.$emit('sort', { field, order });
    },
    async handleClickDeleteVendor(vendorId) {
      this.isFetchingVendorDetails = true;
      try {
        await this.$store.dispatch(VendorActions.FETCH_VENDOR_BY_ID, { vendorId });
        this.vendorIdConfirmingDelete = vendorId;
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isFetchingVendorDetails = false;
    },
    async handleConfirmDeleteVendor(vendorId) {
      this.vendorsDeleting = [...this.vendorsDeleting, vendorId];
      try {
        await this.$store.dispatch(VendorActions.DELETE_VENDOR_BY_ID, {
          vendorId,
        });

        NotificationService.showNotification(NotificationTypes.DELETE_VENDOR.SUCCESS);
        this.$emit('deleteVendor', vendorId);
        this.handleCloseDeleteModal();
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_VENDOR.ERROR);
      }
      this.vendorsDeleting = this.vendorsDeleting.filter(a => a !== vendorId);
    },
    handleUpdateVendorSuccess(vendorId) {
      this.handleCloseEditModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_VENDOR.SUCCESS);
      this.$emit('updateVendor', vendorId);
    },
  },
};
</script>
