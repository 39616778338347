import { normalize } from 'normalizr';
import * as Api from '@/services/Api.service';
import * as CostCodeMutations from '@/store/mutations/CostCode.mutations';
import * as CostCodeCategoryMutations from '@/store/mutations/CostCodeCategory.mutations';
import * as CostCodeCategoryActions from '@/store/actions/CostCodeCategory.actions';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as schemas from '@/store/schemas';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  costCodeCategories: {},
});

const receiveCostCodeCategories = (state, payload) => {
  state.costCodeCategories = {
    ...state.costCodeCategories,
    ...payload.costCodeCategories,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  // Placeholder
};

const mutations = {
  [CostCodeCategoryMutations.COST_CODE_CATEGORY_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [CostCodeCategoryMutations.COST_CODE_CATEGORY_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [CostCodeCategoryMutations.COST_CODE_CATEGORY_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [UserMutations.RECEIVE_USERS]: receiveCostCodeCategories,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveCostCodeCategories,
  [CostCodeMutations.RECEIVE_COST_CODES]: receiveCostCodeCategories,
  [CostCodeCategoryMutations.RECEIVE_COST_CODE_CATEGORIES]: receiveCostCodeCategories,
  [BillMutations.RECEIVE_BILLS]: receiveCostCodeCategories,
};

const actions = {
  async [CostCodeCategoryActions.FETCH_ORGANIZATION_COST_CODE_CATEGORIES](context, payload) {
    context.commit(CostCodeCategoryMutations.COST_CODE_CATEGORY_REQUEST_DATA);
    try {
      const { data: { costCodeCategories } } = await Api.organization.costCodeCategory.findMany(payload.organizationId);
      const normalizedCostCodeCategories = normalize(costCodeCategories, [schemas.costCodeCategory]);
      context.commit(CostCodeCategoryMutations.RECEIVE_COST_CODE_CATEGORIES, normalizedCostCodeCategories.entities);
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [CostCodeCategoryActions.CREATE_COST_CODE_CATEGORY](context, payload) {
    context.commit(CostCodeCategoryMutations.COST_CODE_CATEGORY_BEGIN_WRITE_DATA);

    try {
      const { data: { costCodeCategory } } = await Api.costCodeCategory.create(payload);
      const normalizedCostCodeCategory = normalize(costCodeCategory, schemas.costCodeCategory);
      context.commit(CostCodeCategoryMutations.RECEIVE_COST_CODE_CATEGORIES, {
        ...normalizedCostCodeCategory.entities,
      });

      return normalizedCostCodeCategory.result; // Created cost code category.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
