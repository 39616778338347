import { normalize } from 'normalizr';
import * as CostCodeActions from '@/store/actions/CostCode.actions';
import * as CostCodeMutations from '@/store/mutations/CostCode.mutations';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as CostCodeCategoryMutations from '@/store/mutations/CostCodeCategory.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  costCodes: {},
});

const receiveCostCodes = (state, payload) => {
  state.costCodes = {
    ...state.costCodes,
    ...payload.costCodes,
  };

  state.isFetchingData = false;
  state.isWritingData = false;
};

function deleteCostCode(state, payload) {
  const updatedCostCodes = { ...state.costCodes };
  delete updatedCostCodes[payload.costCodeId];
  state.costCodes = { ...updatedCostCodes };
  state.isFetchingData = false;
  state.isWritingData = false;
}

const state = getInitialState();

const getters = {
  fuzzyFindCostCodeByCode(state) {
    return code => Object.values(state.costCodes)
      .filter(costCode => costCode.code.toLowerCase().includes(code.toLowerCase()))[0];
  },
};

const mutations = {
  [CostCodeMutations.COST_CODE_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [CostCodeMutations.COST_CODE_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [CostCodeMutations.COST_CODE_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [CostCodeMutations.RECEIVE_COST_CODES]: receiveCostCodes,
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveCostCodes,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveCostCodes,
  [CostCodeCategoryMutations.RECEIVE_COST_CODE_CATEGORIES]: receiveCostCodes,
  [UserMutations.RECEIVE_USERS]: receiveCostCodes,
  [BillMutations.RECEIVE_BILLS]: receiveCostCodes,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveCostCodes,
  [CostCodeMutations.DELETE_COST_CODE]: deleteCostCode,
};

const actions = {
  async [CostCodeActions.CREATE_COST_CODE](context, payload) {
    context.commit(CostCodeMutations.COST_CODE_BEGIN_WRITE_DATA);

    try {
      const { data: { costCode } } = await Api.costCode.create(payload.newCostCode);
      const { entities, result } = normalize(costCode, schemas.costCode);
      context.commit(CostCodeMutations.RECEIVE_COST_CODES, {
        ...entities,
      });

      return result; // Created bill id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [CostCodeActions.CREATE_COST_CODES](context, payload) {
    context.commit(CostCodeMutations.COST_CODE_BEGIN_WRITE_DATA);

    try {
      await Api.costCode.createMany(payload.newCostCodes);
      context.commit(CostCodeMutations.COST_CODE_COMPLETE_WRITE_DATA);
      return;
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [CostCodeActions.FETCH_ORGANIZATION_COST_CODES](context, payload) {
    context.commit(CostCodeMutations.COST_CODE_REQUEST_DATA);
    try {
      const { organizationId, params = {} } = payload;
      const { data: { costCodes, _count: costCodeCount } } = await Api.organization.costCode.findMany(organizationId, params);

      const normalizedCostCodes = normalize(costCodes, [schemas.costCode]);

      context.commit(CostCodeMutations.RECEIVE_COST_CODES, { ...normalizedCostCodes.entities });

      return {
        costCodeIds: Object.keys(normalizedCostCodes?.entities?.costCodes ?? {}),
        costCodeCount,
      };
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [CostCodeActions.FETCH_COST_CODE_BY_ID](context, payload) {
    context.commit(CostCodeMutations.COST_CODE_REQUEST_DATA);
    try {
      const { data: { costCode } } = await Api.costCode.findOne(payload.costCodeId);
      const { entities } = normalize(costCode, schemas.costCode);
      context.commit(CostCodeMutations.RECEIVE_COST_CODES, entities);

      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [CostCodeActions.UPDATE_COST_CODE_BY_ID](context, payload) {
    context.commit(CostCodeMutations.COST_CODE_BEGIN_WRITE_DATA);
    try {
      const { costCodeId, updatedCostCode } = payload;
      const { data: { costCode } } = await Api.costCode.updateOne(costCodeId, updatedCostCode);
      const normalizedCostCode = normalize(costCode, schemas.costCode);

      context.commit(CostCodeMutations.RECEIVE_COST_CODES, normalizedCostCode.entities);

      return normalizedCostCode.result;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [CostCodeActions.DELETE_COST_CODE_BY_ID](context, payload) {
    context.commit(CostCodeMutations.COST_CODE_BEGIN_WRITE_DATA);
    try {
      await Api.costCode.deleteOne(payload.costCodeId);

      context.commit(CostCodeMutations.DELETE_COST_CODE, { costCodeId: payload.costCodeId });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
