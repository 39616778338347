<template>
  <div style="position: relative;">
    <BIcon
      v-if="isTouched && value"
      icon="checkbox-marked-circle"
      type="is-success"
      custumSize="mdi-24px"
      class="progress-input-card__marked-activity-icon"
    />
    <div class="card card--dark card--inset">
      <div class="card-content u-p-a-small">
        <div class="columns u-m-a-0 u-m-b-xsmall">
          <small class="column u-p-y-0 has-text-weight-bold">{{ $t('progressInputCard.columnLabels.increment') }}</small>
          <div class="column u-p-x-0" style="max-width: 100px;"></div>
          <small class="column u-p-y-0 has-text-weight-bold">{{ $t('progressInputCard.columnLabels.total') }}</small>
        </div>
        <div
          v-for="(valueType, index) in Object.entries(progressInput.values)"
          :key="valueType[0]"
          :class="[
            'columns',
            'is-mobile',
            'u-m-a-0',
            'is-vcentered',
            { 'u-m-b-xsmall': index !== Object.keys(progressInput.values).length - 1 }
          ]"
        >
          <div class="column u-p-a-0 progress-input-card__input-column">
            <div class="field">
              <div
                :class="[
                  'control',
                  'has-icons-left'
                ]"
              >
                <input
                  :class="[
                    'input',
                    getFieldType(valueType[0], 'increment'),
                    'has-text-right',
                    'progress-input-card__input',
                    'is-small'
                  ]"
                  :disabled="isLoading"
                  :type="progressInput.type"
                  v-currency="currencyOptions"
                  v-model="valueType[1].increment.value"
                  :ref="valueType[1].increment.ref"
                />
                <span v-if="valueType[0] === 'currency'" class="icon is-left">$</span>
                <span v-if="valueType[0] === 'units'" class="icon is-left">{{ activeActivity.unit }}</span>
                <span v-if="valueType[0] === 'percent'" class="icon is-left">%</span>
              </div>
              <p v-if="valueType[1].increment.error" class="help is-danger">
                {{ $t(valueType[1].increment.error) }}
              </p>
            </div>
          </div>
          <small class="column has-text-weight-bold u-p-a-0" style="max-width: 100px;">{{ valueType[1].increment.label }}</small>
          <div class="column has-text-center u-p-a-0">
            <div class="field">
              <div
                :class="[
                  'control',
                  'has-icons-left'
                ]"
              >
                <input
                  :class="[
                    'input',
                    getFieldType(valueType[0], 'total'),
                    'has-text-right',
                    'progress-input-card__input',
                    'is-small'
                  ]"
                  :disabled="isLoading"
                  :type="progressInput.type"
                  v-currency="currencyOptions"
                  v-model="valueType[1].total.value"
                  :ref="valueType[1].total.ref"
                  @blur="handleBlur"
                />
                <span v-if="valueType[0] === 'currency'" class="icon is-left">$</span>
                <span v-if="valueType[0] === 'units'" class="icon is-left">{{ activeActivity.unit }}</span>
                <span v-if="valueType[0] === 'percent'" class="icon is-left">%</span>
              </div>
              <p v-if="valueType[1].total.error" class="help is-danger">
                {{ $t(valueType[1].total.error) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as Types from '@/constants/Types';
import * as Currency from '@/constants/Currency';

export default {
  name: 'ProgressInputCard',

  props: {
    value: {
      type: Number,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      currencyOptions: { ...Currency.defaultCurrency },
      progressInput: {
        type: 'text',
        isTouched: false,
        required: true,
        values: {
          currency: {
            increment: {
              value: '',
              label: this.$t('progressInputCard.fieldLabels.currency'),
              // isFieldTouched: this.isFieldTouched.currency.increment,
              error: null,
              ref: `${this.activityId}_currencyIncrement`,
              shaper: value => value,
            },
            total: {
              value: '',
              label: this.$t('progressInputCard.fieldLabels.currency'),
              // isFieldTouched: this.isFieldTouched.currency.total,
              error: null,
              ref: `${this.activityId}_currencyTotal`,
              shaper: value => ((this.currentActivityAdvanceAmount || value)
                ? (this.currentActivityAdvanceAmount + value)
                : ''),
            },
          },
          units: {
            increment: {
              value: '',
              label: this.$t('progressInputCard.fieldLabels.units'),
              // isFieldTouched: this.isFieldTouched.units.increment,
              error: null,
              ref: `${this.activityId}_unitsIncrement`,
              shaper: value => (value
                ? (((value) / this.getActivityBudget(this.activityId)) * (this.activeActivity.quantity * 100))
                : ''),
            },
            total: {
              value: '',
              label: this.$t('progressInputCard.fieldLabels.units'),
              // isFieldTouched: this.isFieldTouched.units.total,
              error: null,
              ref: `${this.activityId}_unitsTotal`,
              shaper: value => ((this.currentActivityAdvanceAmount || value)
                ? (((this.currentActivityAdvanceAmount + value) / this.getActivityBudget(this.activityId)) * (this.activeActivity.quantity * 100))
                : ''),
            },
          },
          percent: {
            increment: {
              value: '',
              label: this.$t('progressInputCard.fieldLabels.percent'),
              // isFieldTouched: this.isFieldTouched.percent.increment,
              error: null,
              ref: `${this.activityId}_percentIncrement`,
              shaper: value => (value
                ? (value / this.getActivityBudget(this.activityId)) * 10000
                : ''),
            },
            total: {
              value: '',
              label: this.$t('progressInputCard.fieldLabels.percent'),
              // isFieldTouched: this.isFieldTouched.percent.total,
              error: null,
              ref: `${this.activityId}_percentTotal`,
              shaper: value => ((this.currentActivityAdvanceAmount || value)
                ? ((this.currentActivityAdvanceAmount + value) / this.getActivityBudget(this.activityId)) * 10000
                : ''),
            },
          },
        },
        name: `newAdvance${this.activityId}`,
      },
      isTouched: false,
      isFieldTouched: {
        percent: {
          increment: false,
          total: false,
        },
        currency: {
          increment: false,
          total: false,
        },
        units: {
          increment: false,
          total: false,
        },
      },
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
    }),
    ...mapGetters([
      'getActivityTotalAdvanceAmount',
      'getActivityBudget',
    ]),
    activeActivity() {
      return this.activities[this.activityId];
    },
    currentActivityAdvanceAmount() {
      return this.getActivityTotalAdvanceAmount(this.activityId, { excludedBillStatuses: [Types.billStatusTypes.draft] });
    },
  },

  watch: {
    value() {
      this.handleValueChange();
      this.setInputValues();
    },
    'progressInput.values.currency.increment.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);
      if (document.activeElement === this.$refs[this.progressInput.values.currency.increment.ref][0]) {
        this.handleInput(value, 'currency', 'increment');
      }
    },
    'progressInput.values.currency.total.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);
      if (document.activeElement === this.$refs[this.progressInput.values.currency.total.ref][0]) {
        this.handleInput(value, 'currency', 'total');
      }
    },
    'progressInput.values.units.increment.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);
      if (document.activeElement === this.$refs[this.progressInput.values.units.increment.ref][0]) {
        this.handleInput(value, 'units', 'increment');
      }
    },
    'progressInput.values.units.total.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);
      if (document.activeElement === this.$refs[this.progressInput.values.units.total.ref][0]) {
        this.handleInput(value, 'units', 'total');
      }
    },
    'progressInput.values.percent.increment.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);
      if (document.activeElement === this.$refs[this.progressInput.values.percent.increment.ref][0]) {
        this.handleInput(value, 'percent', 'increment');
      }
    },
    'progressInput.values.percent.total.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);
      if (document.activeElement === this.$refs[this.progressInput.values.percent.total.ref][0]) {
        this.handleInput(value, 'percent', 'total');
      }
    },
  },

  mounted() {
    this.setInputValues();
  },

  methods: {
    handleValueChange() {
      this.isTouched = true;
    },
    setInputValues() {
      let incrementValue = this.value;

      // Cap value to max
      if (incrementValue + this.currentActivityAdvanceAmount > this.getActivityBudget(this.activityId)) {
        incrementValue = this.getActivityBudget(this.activityId) - this.currentActivityAdvanceAmount;
      }
      Object.keys(this.progressInput.values).forEach(fieldType => {
        Object.keys(this.progressInput.values[fieldType]).forEach(incrementType => {
          this.setValue(
            this.$refs[this.progressInput.values[fieldType][incrementType].ref][0],
            incrementValue,
            this.progressInput.values[fieldType][incrementType].shaper,
          );
        });
      });
    },
    setValue(ref, newValue, shaper) {
      if (document.activeElement !== ref) {
        this.$ci.setValue(ref, shaper(newValue));
      }
    },
    getValueInCurrency(value, valueType) {
      let newValue = value;
      const activityBudget = this.getActivityBudget(this.activityId);

      if (valueType === 'percent') {
        newValue = Math.round((((value / 10000) * ((activityBudget)))));
      }
      if (valueType === 'units') {
        newValue = Math.round((((newValue / 100) / this.activeActivity.quantity) * activityBudget));
      }

      return newValue;
    },
    touchFieldType(touchedValueType, touchedIncrementType) {
      Object.keys(this.isFieldTouched).forEach(valueTypeKey => {
        Object.keys(this.isFieldTouched[valueTypeKey]).forEach(incrementTypeKey => {
          this.isFieldTouched[valueTypeKey][incrementTypeKey] = false;
        });
      });
      this.isFieldTouched[touchedValueType][touchedIncrementType] = true;
    },
    getIncrement(value, incrementType) {
      if (incrementType === 'increment') return value;

      return value - this.currentActivityAdvanceAmount;
    },
    removePeriodsFromString(string) {
      return string.replace(/\./g, '');
    },
    handleInput(val, valueType, incrementType) {
      let value = val;
      const ref = this.$refs[this.progressInput.values[valueType][incrementType].ref][0];
      const maxIncrementValue = this.getActivityBudget(this.activityId) - this.currentActivityAdvanceAmount;

      this.touchFieldType(valueType, incrementType);

      value = this.getValueInCurrency(value, valueType);
      value = this.getIncrement(value, incrementType);

      // validate max value
      if (value > maxIncrementValue) {
        value = maxIncrementValue;
        this.$ci.setValue(ref, this.progressInput.values[valueType][incrementType].shaper(value));
      }

      this.$emit('change', value);
    },
    getFieldType(valueType, incrementType) {
      const field = this.progressInput.values[valueType][incrementType];
      let fieldType;

      if (field.isFieldTouched && !field.error) {
        fieldType = 'is-success';
      }

      if (field.error) {
        fieldType = 'is-danger';
      }

      return fieldType;
    },
    handleBlur() {
      const incrementField = this.progressInput.values.percent.increment;
      const incrementPercentValue = this.$ci.parse(incrementField.value, this.currencyOptions);

      if (incrementPercentValue < 0) {
        this.$emit('change', 0);
      }
    },
  },
};
</script>

<style lang="scss">
.progress-input-card__marked-activity-icon {
  position: absolute;
  right: -4px;
  top: -4px;
  z-index: 1;
  background-color: $white;
  border-radius: 100px;
  width: 18px;
  height: 18px;
  box-shadow: $dark-shadow;
}

.progress-input-card__input {
  margin-bottom: $global-whitespace-2xsmall;

  + .icon {
    color: $dark !important;
  }
}

.progress-input-card__input-column {
  text-align: center;

  & .field {
    text-align: left;
  }
}
</style>
