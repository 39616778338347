<template>
  <div>
  <div v-if="!isComponentLoading" class="card card--dark card--inset">
    <div class="card-content">
      <form @submit.prevent="handleSubmitCreateChangeOrder">
        <BField v-if="formSubmissionError">
          <BMessage type="is-danger">
            {{  formSubmissionError.message }}
          </BMessage>
        </BField>
        <div class="columns">
          <BField
            v-for="formField in Object.values(createChangeOrderForm.fields)"
            :key="formField.key"
            :class="['column', { 'required': formField.required, [`${formField.width}`]: formField.width }]"
            :label="$t(formField.label)"
            :type="createChangeOrderForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
            :message="createChangeOrderForm.formErrors[$t(formField.name)] || ''"
          >
            <BInput
              :type="formField.type || 'text'"
              v-model="formField.value"
              :disabled="createChangeOrderForm.isFormSubmissionInProgress"
              :placeholder="$t(formField.placeholder)"
              @input="handleTouchForm"
              :ref="$t(formField.name)"
            />
          </BField>
          <BField class="column" style="margin-top: 26px;">
            <BButton
              type="is-primary"
              native-type="submit"
              :disabled="createChangeOrderForm.isFormSubmissionInProgress"
              :loading="createChangeOrderForm.isFormSubmissionInProgress"
            >
              {{ $t('common.ctas.createChangeOrder') }}
            </BButton>
          </BField>
        </div>
      </form>
    </div>
  </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isComponentLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import * as ChangeOrderActions from '@/store/actions/ChangeOrder.actions';
import * as ValidationService from '@/services/Validation.service';
import createChangeOrderForm from '@/forms/createChangeOrder.form';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'CreateChangeOrderCard',

  props: {
    projectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isComponentLoading: false,
      createChangeOrderForm: cloneDeep(createChangeOrderForm),
      formSubmissionError: null,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
    }),
    ...mapGetters([
      'activeOrganization',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
  },

  methods: {
    handleTouchForm() {
      // console.log('form touched');
    },
    async handleSubmitCreateChangeOrder() {
      this.formSubmissionError = null;
      this.createChangeOrderForm.formErrors = {};
      this.createChangeOrderForm.formErrors = ValidationService.runFormValidation({ ...this.createChangeOrderForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.createChangeOrderForm.formErrors)) return;

      this.createChangeOrderForm.isFormSubmissionInProgress = true;

      try {
        await this.$store.dispatch(
          ChangeOrderActions.CREATE_CHANGE_ORDER,
          {
            newChangeOrder: {
              projectId: this.projectId,
              name: this.createChangeOrderForm.fields.name.value,
            },
          },
        );
        this.createChangeOrderForm = cloneDeep(createChangeOrderForm);
      } catch (err) {
        this.formSubmissionError = err;
      }

      this.createChangeOrderForm.isFormSubmissionInProgress = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
