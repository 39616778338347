import { GrowthBook } from '@growthbook/growthbook';
import Vue from 'vue';

// Create a GrowthBook instance
const growthbook = new GrowthBook();

const FEATURES_ENDPOINT = `https://cdn.growthbook.io/api/features/${process.env.VUE_APP_GROWTHBOOK_API_KEY}`;

// Load feature definitions from API
export const fetchFeatures = async growthBookInstance => {
  try {
    const response = await fetch(FEATURES_ENDPOINT);
    const json = await response.json();

    growthBookInstance.setFeatures(json.features);
  } catch {
    console.log('Failed to fetch feature definitions from GrowthBook');
  }
};

export const GrowthBookVue = {
  install() {
    Vue.prototype.$GrowthBook = growthbook;
  },
};
