<template>
  <div>
    <BModal
      :active="isOpen"
      hasModalCard
      @close="handleCloseModal"
      trapFocus
      ariaModal
      :canCancel="true"
    >
      <form>
        <div class="modal-card" >
          <div class="modal-card-head">
            <p class="modal-card-title">{{ $t('common.terms.billBreakdown') }}</p>
            <BButton type="is-text" size="is-small" @click="handleCloseModal">
              <BIcon icon="close" />
            </BButton>
          </div>
          <div class="modal-card-body u-p-x-0">
            <!-- ERROR NOTIFICATION -->
            <BField v-if="apiError">
              <BMessage type="is-danger">
                {{ apiError.message }}
              </BMessage>
            </BField>
            <BTabs v-model="activeTab">
              <BTabItem :label="$t('billBreakdownModal.tabs.breakdown.label')" class="bill-breakdown-modal__tabs__tab-item__section">
                <!-- BILL NAME AND TAX -->
                <BField
                  :label="$t(billBreakdownForm.fields.name.name)"
                  :name="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.name.name)] ? 'is-danger' : ''"
                  :message="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.name.name)] || ''"
                  horizontal
                  custom-class="payment-settings-modal__field-label"
                >
                  <BInput
                    v-model="billBreakdownForm.fields.name.value"
                  />
                </BField>
                <BField
                  :label="$t(billBreakdownForm.fields.tax.name)"
                  :type="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.tax.name)] ? 'is-danger' : ''"
                  :message="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.tax.name)] || ''"
                  horizontal
                  custom-class="payment-settings-modal__field-label"
                >
                  <div class="control u-m-a-0">
                    <p :class="['has-text-weight-bold', 'is-italic', 'u-p-y-xsmall', { 'has-text-grey': !selectedTaxId }]">
                      {{ addSuffix(convertFractionToPercent(taxes[selectedTaxId] ? taxes[selectedTaxId].percent : 0), '%')}}
                      <BIcon v-if="selectedTaxId" icon="check" type="is-success" custom-size="mdi-18px" />
                    </p>
                  </div>
                  <BAutocomplete
                    class="disable-on-select"
                    :data="filteredTaxes"
                    v-model="billBreakdownForm.fields.tax.value"
                    :placeholder="$t(billBreakdownForm.fields.tax.placeholder)"
                    field="name"
                    :disabled="selectedTaxId"
                    openOnFocus
                    :maxHeight="200"
                    :maxWidth="300"
                    clearable
                    :selectOnClickOutside="false"
                    @select="handleSelectTax"
                    @blur="handleTaxFieldBlur"
                  >
                    <template slot-scope="props">
                      {{ props.option.name }} — <span class="has-text-weight-bold is-italic">{{ addSuffix(convertFractionToPercent(props.option.percent), '%') }}</span>
                    </template>
                    <template slot="footer">
                      <div @click="handleToggleCreateTaxModal" type="is-info" outlined size="is-small">
                        <span class="autocomplete__footer__cta">
                          <BIcon icon="plus-circle-outline" customSize="mdi-18px" />
                          {{
                            (billBreakdownForm.fields.tax.value && !selectedTaxId)
                              ? $t('createBillView.ctas.createTax', { taxName: billBreakdownForm.fields.tax.value })
                              : $t('common.ctas.createTax')
                          }}
                        </span>
                      </div>
                    </template>
                    <template v-if="filteredTaxes.length === 0" slot="empty">
                      {{ $t('common.emptyStates.noResults') }}
                    </template>
                  </BAutocomplete>
                </BField>
                <div v-if="isAdvanceBillingType">
                  <!-- ADVANCE DETAILS/NUMBERS -->
                  <div class="content-divider content-divider--white" data-content="Avance"></div>
                  <BCollapse v-model="isSectionOpen.advance" animation="slide" class="bill-breakdown-modal__collapse-container">
                    <template slot="trigger">
                      <BIcon
                        :icon="isSectionOpen.advance ? 'chevron-up' : 'chevron-down'"
                        size="is-medium"
                        type="is-primary"
                      />
                    </template>
                    <BField
                      :label="$t('billBreakdownModal.summaryLabels.totalCostAmount')"
                      horizontal
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-right">{{ displayCurrency(costTotalActivityBudget, { prefix: '$', multiplier: 100 }) }}</p>
                    </BField>
                    <!-- <BField
                      label="Porcentage de Avance Total"
                      horizontal
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-right">{{
                        addSuffix(convertFractionToPercent((this.costTotalAdvancePreview + (this.getCostTotalAdvanceAmount(this.activeCostId) / 100)) / (this.costTotalActivityBudget / 100)), '%')
                      }}</p>
                    </BField> -->
                    <BField
                      :label="$t('billBreakdownModal.summaryLabels.totalAdvanceAmount')"
                      horizontal
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-centered is-italic">
                        {{ convertFractionToPercent(costTotalAdvancePreviewPercent / 100) }}%
                      </p>
                      <p class="has-text-right">
                        {{  displayCurrency(costTotalAdvancePreview, { prefix: '+ $', multiplier: 100 }) }}
                      </p>
                    </BField>
                    <BField
                      :label="$t('billBreakdownModal.summaryLabels.minusPreviousAdvance')"
                      horizontal
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-right">
                        {{
                          displayCurrency(
                            getCostTotalAdvanceAmount(activeCostId, { excludedBillStatusNames: [...excludedBillStatusTypes]}),
                            { prefix: '- $', multiplier: 100 },
                          )
                        }}
                      </p>
                    </BField>
                    <BField
                      :label="$t('billBreakdownModal.summaryLabels.incrementAdvanceAmount')"
                      horizontal
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-centered is-italic">
                        {{ convertFractionToPercent(advanceIncrementPercent / 100) }}%
                      </p>
                      <p class="has-text-right has-text-weight-bold">
                        {{ displayCurrency(advanceIncrementAmount, { prefix: '= $', multiplier: 100 })  }}
                      </p>
                    </BField>
                    <BField
                      horizontal
                      :label="$t('billBreakdownModal.summaryLabels.tax')"
                      custom-class="payment-settings-modal__field-label"
                    >
                      <BSwitch
                        rounded
                        :disabled="!selectedTaxId"
                        v-model="billBreakdownForm.fields.isAdvanceTaxed.value"
                      />
                          <p v-if="selectedTaxId && billBreakdownForm.fields.isAdvanceTaxed.value">
                        {{ convertFractionToPercent(taxes[selectedTaxId].percent) }}%
                      </p>
                      <p v-if="selectedTaxId && billBreakdownForm.fields.isAdvanceTaxed.value" class="has-text-right">
                        {{ displayCurrency(taxAmountForAdvance, { prefix: '+ $', multiplier: 100 }) }}
                      </p>
                    </BField>
                  </BCollapse>

                  <!-- DEDUCTIONS -->
                  <div class="content-divider content-divider--white" :data-content="$t('billBreakdownModal.sections.deductions')"></div>
                  <BCollapse v-model="isSectionOpen.deduction" animation="slide" class="bill-breakdown-modal__collapse-container">
                    <template slot="trigger">
                      <BIcon
                        :icon="isSectionOpen.deduction ? 'chevron-up' : 'chevron-down'"
                        size="is-medium"
                        type="is-primary"
                      />
                    </template>
                    <div class="field is-horizontal u-m-t">
                      <div class="field-label payment-settings-modal__field-label">
                        <label :class="['label', { 'has-text-grey': isInputDisabled('deduction') }]">
                          {{ $t(billBreakdownForm.fields.deduction.name) }}
                        </label>
                      </div>
                      <div class="field-body">
                        <div class="field" style="max-width: 120px">
                          <p class="control has-icons-left">
                            <input
                              class="input input--icon-dark has-text-right"
                              :name="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.deduction.name)] || ''"
                              v-model="billBreakdownForm.fields.deduction.value"
                              v-currency="currencyOptions"
                              ref="deductionAmountInput"
                              :disabled="isInputDisabled('deduction')"
                            />
                            <span class="icon is-left">
                              $
                            </span>
                          </p>
                        </div>
                        <div class="field" style="max-width: 100px">
                          <p class="control has-icons-right">
                            <input
                              class="input input--icon-dark has-text-right"
                              v-model="billBreakdownForm.fields.deductionPercent.value"
                              v-currency="currencyOptions"
                              ref="deductionPercentInput"
                              :disabled="isInputDisabled('deduction')"
                            />
                            <span class="icon is-right">
                              %
                            </span>
                          </p>
                        </div>
                        <div class="field">
                          <p class="has-text-right" style="white-space: nowrap;">
                            {{ displayCurrency(($ci.parse(billBreakdownForm.fields.deduction.value || 0, currencyOptions)), { prefix: '- $', multiplier: 100 }) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label payment-settings-modal__field-label">
                        <label :class="['label', { 'has-text-grey': isInputDisabled('deductionRepayment') }]">
                          {{ $t(billBreakdownForm.fields.deductionRepayment.name) }}
                        </label>
                      </div>
                      <div class="field-body">
                        <div class="field-body" style="flex-direction: column;">
                          <div class="field-body">
                            <div class="field" style="max-width: 120px">
                              <p class="control has-icons-left">
                                <input
                                  class="input input--icon-dark has-text-right"
                                  :name="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.deductionRepayment.name)] || ''"
                                  v-model="billBreakdownForm.fields.deductionRepayment.value"
                                  v-currency="currencyOptions"
                                  ref="deductionRepaymentAmountInput"
                                  :disabled="isInputDisabled('deductionRepayment')"
                                />
                                <span class="icon is-left">
                                  $
                                </span>
                              </p>
                            </div>
                            <div class="field" style="max-width: 100px">
                              <p class="control has-icons-right">
                                <input
                                  class="input input--icon-dark has-text-right"
                                  v-model="billBreakdownForm.fields.deductionRepaymentPercent.value"
                                  v-currency="currencyOptions"
                                  ref="deductionRepaymentPercentInput"
                                  :disabled="isInputDisabled('deductionRepayment')"
                                />
                                <span class="icon is-right">
                                  %
                                </span>
                              </p>
                            </div>
                          </div>
                          <p class="help u-m-t-xsmall">
                            {{ $t('billBreakdownModal.summaryLabels.accumulatedDeductionAmount') }}:
                            {{ displayCurrency(accumulatedBillDeductions ? accumulatedBillDeductions : 0, { multiplier: 100, prefix: '$' }) }}
                          </p>
                        </div>
                        <div class="field">
                          <p class="has-text-right" style="white-space: nowrap;">
                            {{ displayCurrency(($ci.parse(billBreakdownForm.fields.deductionRepayment.value || 0, currencyOptions)), { prefix: '+ $', multiplier: 100 }) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <BField
                      horizontal
                      :label="$t('billBreakdownModal.summaryLabels.advanceWithDeductions')"
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-right has-text-weight-bold">
                        {{ displayCurrency(advanceIncrementAmountWithDeductions, { prefix: '= $', multiplier: 100 }) }}
                      </p>
                    </BField>
                    <BField
                      horizontal
                      :label="$t('billBreakdownModal.summaryLabels.tax')"
                      custom-class="payment-settings-modal__field-label"
                    >
                      <BSwitch
                        rounded
                        :disabled="!selectedTaxId"
                        v-model="billBreakdownForm.fields.isDeductionTaxed.value"
                      />
                          <p v-if="selectedTaxId && billBreakdownForm.fields.isDeductionTaxed.value">
                        {{ convertFractionToPercent(taxes[selectedTaxId].percent) }}%
                      </p>
                      <p v-if="selectedTaxId && billBreakdownForm.fields.isDeductionTaxed.value" class="has-text-right">
                        {{ displayCurrency(taxAmountForDeduction, { prefix: '+ $', multiplier: 100 }) }}
                      </p>
                    </BField>
                    <!-- <BField
                      horizontal
                      label="Subtotal de Avance"
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-right has-text-weight-bold">
                        {{ displayCurrency(advanceIncrementAmountAfterTax, { prefix: '= $'}) }}
                      </p>
                    </BField> -->
                  </BCollapse>

                  <!-- DOWN PAYMENTS -->
                  <div class="content-divider content-divider--white" :data-content="$t('billBreakdownModal.sections.downPayment')"></div>
                  <BCollapse v-model="isSectionOpen.downPayment" animation="slide" class="bill-breakdown-modal__collapse-container">
                    <template slot="trigger">
                      <BIcon
                        :icon="isSectionOpen.downPayment ? 'chevron-up' : 'chevron-down'"
                        size="is-medium"
                        type="is-primary"
                      />
                    </template>
                    <div class="field is-horizontal u-m-t">
                      <div class="field-label payment-settings-modal__field-label">
                        <label :class="['label', { 'has-text-grey': isInputDisabled('downPayment') }]">
                          {{ $t(billBreakdownForm.fields.downPayment.name) }}
                        </label>
                      </div>
                      <div class="field-body">
                        <div class="field" style="max-width: 120px">
                          <p class="control has-icons-left">
                            <input
                              class="input input--icon-dark has-text-right"
                              :name="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.downPayment.name)] || ''"
                              v-model="billBreakdownForm.fields.downPayment.value"
                              v-currency="currencyOptions"
                              :disabled="isInputDisabled('downPayment')"
                              ref="downPaymentAmountInput"
                            />
                            <span class="icon is-left">
                              $
                            </span>
                          </p>
                        </div>
                        <div class="field" style="max-width: 100px">
                          <p class="control has-icons-right">
                            <input
                              class="input input--icon-dark has-text-right"
                              v-model="billBreakdownForm.fields.downPaymentPercent.value"
                              v-currency="currencyOptions"
                              :disabled="isInputDisabled('downPayment')"
                              ref="downPaymentPercentInput"
                            />
                            <span class="icon is-right">
                              %
                            </span>
                          </p>
                        </div>
                        <div class="field">
                          <BTooltip
                              :label="$t('billBreakdownModal.downPayment.percentTooltipInfo')"
                              type="is-dark"
                              multilined
                              position="is-top"
                            >
                              <BIcon
                                icon="information-outline"
                                class="u-m-l-xsmall"
                                style="margin-top: 8px;"
                              />
                            </BTooltip>
                        </div>
                        <div class="field">
                          <p class="has-text-right" style="white-space: nowrap;">
                            {{ displayCurrency(($ci.parse(billBreakdownForm.fields.downPayment.value || 0, currencyOptions)), { prefix: '+ $', multiplier: 100 }) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label payment-settings-modal__field-label">
                        <label :class="['label', { 'has-text-grey': isInputDisabled('downPaymentDeduction') }]">
                          {{ $t(billBreakdownForm.fields.downPaymentDeduction.name) }}
                        </label>
                      </div>
                      <div class="field-body">
                        <div class="field-body" style="flex-direction: column;">
                          <div class="field-body">
                            <div class="field" style="max-width: 120px">
                              <p class="control has-icons-left">
                                <input
                                  class="input input--icon-dark has-text-right"
                                  :name="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.downPaymentDeduction.name)] || ''"
                                  v-model="billBreakdownForm.fields.downPaymentDeduction.value"
                                  v-currency="currencyOptions"
                                  :disabled="isInputDisabled('downPaymentDeduction')"
                                  ref="downPaymentDeductionAmountInput"
                                />
                                <span class="icon is-left">
                                  $
                                </span>
                              </p>
                            </div>
                            <div class="field" style="max-width: 100px">
                              <p class="control has-icons-right">
                                <input
                                  class="input input--icon-dark has-text-right"
                                  v-model="billBreakdownForm.fields.downPaymentDeductionPercent.value"
                                  v-currency="currencyOptions"
                                  :disabled="isInputDisabled('downPaymentDeduction')"
                                  ref="downPaymentDeductionPercentInput"
                                />
                                <span class="icon is-right">
                                  %
                                </span>
                              </p>
                            </div>
                          </div>
                          <p class="help u-m-t-xsmall">
                            {{ $t('billBreakdownModal.summaryLabels.accumulatedDownPaymentAmount') }}:
                            {{ displayCurrency(accumulatedBillDownPayment ? accumulatedBillDownPayment : 0, { multiplier: 100, prefix: '$' }) }}
                          </p>
                        </div>
                        <div class="field">
                          <p class="has-text-right" style="white-space: nowrap;">
                            {{ displayCurrency(($ci.parse(billBreakdownForm.fields.downPaymentDeduction.value || 0, currencyOptions)), { prefix: '- $', multiplier: 100 }) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <BField
                      horizontal
                      :label="$t('billBreakdownModal.summaryLabels.tax')"
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p>
                        <BSwitch
                          rounded
                          :disabled="!selectedTaxId"
                          v-model="billBreakdownForm.fields.isDownPaymentTaxed.value"
                        />
                      </p>
                      <p v-if="selectedTaxId && billBreakdownForm.fields.isDownPaymentTaxed.value">
                        {{ convertFractionToPercent(taxes[selectedTaxId].percent) }}%
                      </p>
                      <p v-if="selectedTaxId && billBreakdownForm.fields.isDownPaymentTaxed.value" class="has-text-right">
                        {{ displayCurrency(taxAmountForDownPayment, { prefix: '+ $', multiplier: 100 }) }}
                      </p>
                    </BField>
                    <!-- <BField
                      horizontal
                      label="Subtotal de Avance"
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-right has-text-weight-bold">
                        {{ displayCurrency(advanceIncrementAmountAfterDownPayment, { prefix: '= $'}) }}
                      </p>
                    </BField> -->
                  </BCollapse>

                  <!-- RETAINAGE -->
                  <div class="content-divider content-divider--white" :data-content="$t('billBreakdownModal.sections.retainage')"></div>
                  <BCollapse v-model="isSectionOpen.retainage" animation="slide" class="bill-breakdown-modal__collapse-container">
                    <template slot="trigger">
                      <BIcon
                        :icon="isSectionOpen.retainage ? 'chevron-up' : 'chevron-down'"
                        size="is-medium"
                        type="is-primary"
                      />
                    </template>
                    <div class="field is-horizontal u-m-t">
                      <div class="field-label payment-settings-modal__field-label">
                        <label :class="['label', { 'has-text-grey': isInputDisabled('retainage') }]">
                          {{ $t(billBreakdownForm.fields.retainage.name) }}
                        </label>
                      </div>
                      <div class="field-body">
                        <div class="field" style="max-width: 120px">
                          <p class="control has-icons-left">
                            <input
                              class="input input--icon-dark has-text-right"
                              :name="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.retainage.name)] || ''"
                              v-model="billBreakdownForm.fields.retainage.value"
                              v-currency="currencyOptions"
                              :disabled="isInputDisabled('retainage')"
                              ref="retainageAmountInput"
                            />
                            <span class="icon is-left">
                              $
                            </span>
                          </p>
                        </div>
                        <div class="field" style="max-width: 100px">
                          <p class="control has-icons-right">
                            <input
                              class="input input--icon-dark has-text-right"
                              v-model="billBreakdownForm.fields.retainagePercent.value"
                              v-currency="currencyOptions"
                              :disabled="isInputDisabled('retainage')"
                              ref="retainagePercentInput"
                            />
                            <span class="icon is-right">
                              %
                            </span>
                          </p>
                        </div>
                        <div class="field">
                          <p class="has-text-right" style="white-space: nowrap;">
                            {{ displayCurrency(($ci.parse(billBreakdownForm.fields.retainage.value || 0, currencyOptions)), { prefix: '- $', multiplier: 100 }) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label payment-settings-modal__field-label">
                        <label :class="['label', { 'has-text-grey': isInputDisabled('retainageRepayment') }]">
                          {{ $t(billBreakdownForm.fields.retainageRepayment.name) }}
                        </label>
                      </div>
                      <div class="field-body">
                        <div class="field-body" style="flex-direction: column;">
                          <div class="field-body">
                            <div class="field" style="max-width: 120px">
                              <p class="control has-icons-left">
                                <input
                                  class="input input--icon-dark has-text-right"
                                  :name="billBreakdownForm.formErrors[$t(billBreakdownForm.fields.retainageRepayment.name)] || ''"
                                  v-model="billBreakdownForm.fields.retainageRepayment.value"
                                  v-currency="currencyOptions"
                                  :disabled="isInputDisabled('retainageRepayment')"
                                  ref="retainageRepaymentAmountInput"
                                />
                                <span class="icon is-left">
                                  $
                                </span>
                              </p>
                            </div>
                            <div class="field" style="max-width: 100px">
                              <p class="control has-icons-right">
                                <input
                                  class="input input--icon-dark has-text-right"
                                  v-model="billBreakdownForm.fields.retainageRepaymentPercent.value"
                                  v-currency="currencyOptions"
                                  :disabled="isInputDisabled('retainageRepayment')"
                                  ref="retainageRepaymentPercentInput"
                                />
                                <span class="icon is-right">
                                  %
                                </span>
                              </p>
                            </div>
                          </div>
                          <p class="help u-m-t-xsmall">
                            {{ $t('billBreakdownModal.summaryLabels.accumulatedRetainageAmount') }}:
                            {{ displayCurrency(accumulatedBillRetainage ? accumulatedBillRetainage : 0, { multiplier: 100, prefix: '$' }) }}
                          </p>
                        </div>
                        <div class="field">
                          <p class="has-text-right" style="white-space: nowrap;">
                            {{ displayCurrency(($ci.parse(billBreakdownForm.fields.retainageRepayment.value || 0, currencyOptions)), { prefix: '+ $', multiplier: 100 }) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- <BField
                      horizontal
                      label="Avance con Deducciones"
                      custom-class="payment-settings-modal__field-label"
                    >
                      <p class="has-text-right has-text-weight-bold">
                        {{ displayCurrency(advanceIncrementAmountWithDeductions, { prefix: '= $'}) }}
                      </p>
                    </BField> -->
                    <BField
                      horizontal
                      :label="$t('billBreakdownModal.summaryLabels.tax')"
                      custom-class="payment-settings-modal__field-label"
                    >
                      <BSwitch
                        rounded
                        :disabled="!selectedTaxId"
                        v-model="billBreakdownForm.fields.isRetainageTaxed.value"
                      />
                          <p v-if="selectedTaxId && billBreakdownForm.fields.isRetainageTaxed.value">
                        {{ convertFractionToPercent(taxes[selectedTaxId].percent) }}%
                      </p>
                      <p v-if="selectedTaxId && billBreakdownForm.fields.isRetainageTaxed.value" class="has-text-right">
                        {{ displayCurrency(taxAmountForRetainage, { prefix: '+ $', multiplier: 100 }) }}
                      </p>
                    </BField>
                  </BCollapse>
                </div>

                <!-- SUBTOTALS -->
                <div class="content-divider content-divider--white" :data-content="$t('billBreakdownModal.sections.summary')"></div>
                <BField
                  horizontal
                  :label="$t('billBreakdownModal.summaryLabels.subtotal')"
                  custom-class="payment-settings-modal__field-label"
                >
                  <p class="has-text-right">
                    {{ displayCurrency(billSubtotalNoTaxes, { prefix: '= $', multiplier: 100 }) }}
                  </p>
                </BField>
                <BField
                  horizontal
                  :label="$t('common.titles.taxes')"
                  custom-class="payment-settings-modal__field-label"
                >
                  <p class="has-text-right">
                    {{ displayCurrency(taxesTotal, { prefix: '+ $', multiplier: 100 }) }}
                  </p>
                </BField>
                <BField
                  horizontal
                  :label="$t('billBreakdownModal.summaryLabels.subtotal')"
                  custom-class="payment-settings-modal__field-label"
                >
                  <p class="has-text-right has-text-weight-bold">
                    {{ displayCurrency(billTotal, { prefix: '+ $', multiplier: 100 }) }}
                  </p>
                </BField>
              </BTabItem>
              <BTabItem :label="$t('billBreakdownModal.tabs.documents.label')" class="bill-breakdown-modal__tabs__tab-item__section">
                <div v-if="activeBillId" class="u-m-b">
                  <div v-if="!areDocumentsLoading">
                    <h5 class="title is-5 has-text-grey u-m-b-small">{{ $t('billBreakdownModal.tabs.documents.sections.billDocuments') }}</h5>
                    <div v-for="document in getDocumentsByResourceId(activeBillId)" :key="document.id" class="u-m-b-xsmall">
                      <DocumentLink :documentId="document.id" :resourceId="activeBillId" resourceType="bill" />
                    </div>
                  </div>
                  <div v-else style="position: relative; min-height: 150px;">
                    <BLoading :is-full-page="false" :active.sync="areDocumentsLoading" :can-cancel="false"></BLoading>
                  </div>
                </div>
                <div>
                  <h5 class="title is-5 has-text-grey u-m-b-small">{{ $t('billBreakdownModal.tabs.documents.sections.uploadDocuments') }}</h5>
                  <BUpload v-model="documents" multiple dragDrop expanded>
                    <section class="section">
                      <div class="content has-text-centered">
                        <div>
                          <BIcon v-if="!isSubmissionInProgress" icon="upload" size="is-large" />
                          <span v-else style="min-height: 44px; display: block;">
                            <BLoading :isFullPage="false" :canCancel="false" :active="isSubmissionInProgress" />
                          </span>
                        </div>
                        <p>{{ $t('common.ctas.fileUpload') }}</p>
                      </div>
                    </section>
                  </BUpload>
                  <div class="tags u-m-t">
                    <span v-for="(document, index) in documents" :key="index" class="tag is-primary is-light">
                      {{ document.name }}
                      <button class="delete is-small" type="button" @click="handleDeleteDocument(index)"></button>
                    </span>
                  </div>
                </div>
              </BTabItem>
              <!-- <BTabItem label="Comments" class="bill-breakdown-modal__tabs__tab-item__section">
              </BTabItem> -->
            </BTabs>
          </div>

          <!-- FOOTER -->
          <footer class="modal-card-foot">
            <div class="columns is-vcentered" style="width: 100%">
              <div class="column">
                <small class="has-text-weight-bold">{{ $t('billBreakdownModal.summaryLabels.billTotal') }}</small>
                <h3 class="title is-3">
                  <AnimatedNumber
                    :value="billTotal"
                    :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                    :duration="400"
                  />
                </h3>
              </div>
              <div class="column">
                <BButton
                  type="is-light"
                  size="is-small"
                  :loading="isSubmissionInProgress"
                  :name="Types.billStatusTypes.draft"
                  @click.prevent="handleSubmit(Types.billStatusTypes.draft)"
                  @keyup.enter.prevent="handleSubmit(Types.billStatusTypes.draft)"
                >
                  {{ $t('common.ctas.saveBillDraft') }}
                </BButton>
              </div>
              <div class="column">
                <BButton
                  type="is-primary"
                  expanded
                  :loading="isSubmissionInProgress"
                  size="is-large"
                  :name="Types.billStatusTypes.pendingApproval"
                  @click.prevent="handleSubmit(Types.billStatusTypes.pendingApproval)"
                  @keyup.enter.prevent="handleSubmit(Types.billStatusTypes.pendingApproval)"
                >
                  {{ $t('common.ctas.finalizeBill') }}
                </BButton>
              </div>
            </div>
          </footer>
        </div>
      </form>
    </BModal>
    <CreateTaxModal
      :isOpen="isCreateTaxModalOpen"
      :initialTaxName="billBreakdownForm.fields.tax.value"
      @close="handleToggleCreateTaxModal"
      @submitSuccess="handleCreateTaxSuccess"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AnimatedNumber from 'animated-number-vue';
import * as Types from '@/constants/Types';
import * as Currency from '@/constants/Currency';
import * as DocumentActions from '@/store/actions/Document.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import { addSuffix, displayCurrency } from '@/helpers/stringHelpers';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import CreateTaxModal from '@/components/CreateTaxModal.vue';
import DocumentLink from '@/components/DocumentLink.vue';

export default {
  name: 'BillBreakdownModal',

  components: {
    CreateTaxModal,
    AnimatedNumber,
    DocumentLink,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    advanceInputs: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    activities: {
      type: Array,
      required: false,
      default: () => [],
    },
    isSubmissionInProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultBillItemType: {
      type: String,
      required: false,
      validator(propValue) {
        return [...Object.values(Types.billItemTypes)].indexOf(propValue) !== -1;
      },
      default: 'advance',
    },
  },

  data() {
    return {
      Types,
      apiError: null,
      isCreateTaxModalOpen: false,
      selectedTaxId: null,
      activeTab: 0,
      isSectionOpen: {
        advance: true,
        downPayment: true,
        deduction: true,
        retainage: true,
      },
      excludedBillStatusTypes: [
        Types.billStatusTypes.draft,
        Types.billStatusTypes.rejected,
        Types.billStatusTypes.pendingApproval,
      ],
      documents: null,
      areDocumentsLoading: false,
      billBreakdownForm: {
        formErrors: {},
        fields: {
          name: {
            name: 'billBreakdownForm.name.name',
            value: '',
            required: true,
            label: 'billBreakdownForm.name.label',
          },
          tax: {
            name: 'billBreakdownForm.tax.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.tax.label',
            placeholder: 'billBreakdownForm.tax.placeholder',
          },
          isAdvanceTaxed: {
            name: 'billBreakdownForm.isAdvanceTaxed.name',
            value: false,
            required: false,
            label: 'billBreakdownForm.isAdvanceTaxed.label',
            placeholder: 'billBreakdownForm.isAdvanceTaxed.placeholder',
          },
          deduction: {
            name: 'billBreakdownForm.deduction.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.deduction.label',
            placeholder: 'billBreakdownForm.deduction.placeholder',
            ref: 'deductionAmountInput',
            linkedTo: 'deductionPercent',
            disabledBy: 'deductionRepayment',
            shaper: val => (this.advanceIncrementAmount) * (val / 10000),
          },
          deductionPercent: {
            name: 'billBreakdownForm.deduction.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.deduction.label',
            placeholder: 'billBreakdownForm.deduction.placeholder',
            ref: 'deductionPercentInput',
            linkedTo: 'deduction',
            disabledBy: 'deductionRepayment',
            shaper: val => ((val / this.advanceIncrementAmount) * 10000),
          },
          deductionRepayment: {
            name: 'billBreakdownForm.deductionRepayment.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.deductionRepayment.label',
            placeholder: 'billBreakdownForm.deductionRepayment.placeholder',
            ref: 'deductionRepaymentAmountInput',
            linkedTo: 'deductionRepaymentPercent',
            disabledBy: 'deduction',
            shaper: val => ((this.accumulatedBillDeductions) * (val / 10000)),
          },
          deductionRepaymentPercent: {
            name: 'billBreakdownForm.deductionRepayment.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.deductionRepayment.label',
            placeholder: 'billBreakdownForm.deductionRepayment.placeholder',
            ref: 'deductionRepaymentPercentInput',
            linkedTo: 'deductionRepayment',
            disabledBy: 'deduction',
            shaper: val => ((val / this.accumulatedBillDeductions) * 10000),
          },
          isDeductionTaxed: {
            name: 'billBreakdownForm.isDeductionTaxed.name',
            value: false,
            required: false,
            label: 'billBreakdownForm.isDeductionTaxed.label',
            placeholder: 'billBreakdownForm.isDeductionTaxed.placeholder',
          },
          downPayment: {
            name: 'billBreakdownForm.downPayment.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.downPayment.label',
            placeholder: 'billBreakdownForm.downPayment.placeholder',
            ref: 'downPaymentAmountInput',
            linkedTo: 'downPaymentPercent',
            disabledBy: 'downPaymentDeduction',
            shaper: val => (this.costTotalActivityBudget) * (val / 10000),
          },
          downPaymentPercent: {
            name: 'billBreakdownForm.downPayment.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.downPayment.label',
            placeholder: 'billBreakdownForm.downPayment.placeholder',
            ref: 'downPaymentPercentInput',
            linkedTo: 'downPayment',
            disabledBy: 'downPaymentDeduction',
            shaper: val => ((val / this.costTotalActivityBudget) * 10000),
          },
          downPaymentDeduction: {
            name: 'billBreakdownForm.downPaymentDeduction.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.downPaymentDeduction.label',
            placeholder: 'billBreakdownForm.downPaymentDeduction.placeholder',
            ref: 'downPaymentDeductionAmountInput',
            linkedTo: 'downPaymentDeductionPercent',
            disabledBy: 'downPayment',
            shaper: val => (this.accumulatedBillDownPayment) * (val / 10000),
          },
          downPaymentDeductionPercent: {
            name: 'billBreakdownForm.downPaymentDeduction.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.downPaymentDeduction.label',
            placeholder: 'billBreakdownForm.downPaymentDeduction.placeholder',
            ref: 'downPaymentDeductionPercentInput',
            linkedTo: 'downPaymentDeduction',
            disabledBy: 'downPayment',
            shaper: val => ((val / this.accumulatedBillDownPayment) * 10000),
          },
          isDownPaymentTaxed: {
            name: 'billBreakdownForm.isDownPaymentTaxed.name',
            value: false,
            required: false,
            label: 'billBreakdownForm.isDownPaymentTaxed.label',
            placeholder: 'billBreakdownForm.isDownPaymentTaxed.placeholder',
          },
          retainage: {
            name: 'billBreakdownForm.retainage.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.retainage.label',
            placeholder: 'billBreakdownForm.retainage.placeholder',
            ref: 'retainageAmountInput',
            linkedTo: 'retainagePercent',
            disabledBy: 'retainageRepayment',
            shaper: val => (this.advanceIncrementAmount) * (val / 10000),
          },
          retainagePercent: {
            name: 'billBreakdownForm.retainage.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.retainage.label',
            placeholder: 'billBreakdownForm.retainage.placeholder',
            ref: 'retainagePercentInput',
            linkedTo: 'retainage',
            disabledBy: 'retainageRepayment',
            shaper: val => ((val / this.advanceIncrementAmount) * 10000),
          },
          retainageRepayment: {
            name: 'billBreakdownForm.retainageRepayment.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.retainageRepayment.label',
            placeholder: 'billBreakdownForm.retainageRepayment.placeholder',
            ref: 'retainageRepaymentAmountInput',
            linkedTo: 'retainageRepaymentPercent',
            disabledBy: 'retainage',
            shaper: val => (this.accumulatedBillRetainage) * (val / 10000),
          },
          retainageRepaymentPercent: {
            name: 'billBreakdownForm.retainageRepayment.name',
            value: '',
            required: false,
            label: 'billBreakdownForm.retainageRepayment.label',
            placeholder: 'billBreakdownForm.retainageRepayment.placeholder',
            ref: 'retainageRepaymentPercentInput',
            linkedTo: 'retainageRepayment',
            disabledBy: 'retainage',
            shaper: val => ((val / this.accumulatedBillRetainage) * 10000),
          },
          isRetainageTaxed: {
            name: 'billBreakdownForm.isRetainageTaxed.name',
            value: false,
            required: false,
            label: 'billBreakdownForm.isRetainageTaxed.label',
            placeholder: 'billBreakdownForm.isRetainageTaxed.placeholder',
          },
        },
      },
    };
  },

  computed: {
    ...mapState({
      taxes: state => state.tax.taxes,
      costs: state => state.cost.costs,
      bills: state => state.bill.bills,
    }),
    ...mapGetters([
      'getCostTotalAdvanceAmount',
      'getBillItemTypeByName',
      'getBillingTypeByName',
      'getBillTotalAdvance',
      'getBillItemsByBillIdAndTypeName',
      'getBillItemsByCostId',
      'getBillStatusByName',
      'getDocumentsByResourceId',
    ]),
    activeCostId() {
      return this.$route.params.costId;
    },
    activeCost() {
      return this.costs[this.activeCostId];
    },
    activeBillId() {
      return this.$route.params.billId;
    },
    activeBill() {
      return this.bills[this.activeBillId];
    },
    currencyOptions() {
      return { ...Currency.defaultCurrency };
    },
    costTotalAdvancePreview() {
      if (isObjectEmpty(this.advanceInputs)) return (this.getCostTotalAdvanceAmount(this.activeCostId));

      return Object.values({ ...this.advanceInputs }).reduce((acc, val) => acc + val, 0)
        + (this.getCostTotalAdvanceAmount(this.activeCostId))
        - (this.getBillTotalAdvance(this.activeBillId));
    },
    costTotalAdvancePreviewPercent() {
      return this.costTotalAdvancePreview / (this.costTotalActivityBudget / 100);
    },
    isAdvanceBillingType() {
      return this.activeCost?.billingTypeId === this.getBillingTypeByName(Types.billingTypes.advance).id;
    },
    isLumpSumBillingType() {
      return this.activeCost?.billingTypeId === this.getBillingTypeByName(Types.billingTypes.lumpSum).id;
    },
    costTotalActivityBudget() {
      return (this.activities)
        .reduce((acc, activity) => { // eslint-disable-line
          return acc + this.calculateActivityBudget(activity.unitValue, activity.quantity);
        }, 0);
    },
    costRemainingBalance() {
      return this.costTotalActivityBudget - (this.getCostTotalAdvanceAmount(this.activeCostId, { excludedBillStatusNames: [...this.excludedBillStatusTypes] }) / 100);
    },
    costRemainingBalancePreview() {
      return this.costTotalActivityBudget - this.costTotalAdvancePreview;
    },
    advanceIncrementAmount() {
      return this.costTotalAdvancePreview - (this.getCostTotalAdvanceAmount(this.activeCostId, { excludedBillStatusNames: [...this.excludedBillStatusTypes] }));
    },
    advanceIncrementPercent() {
      return this.advanceIncrementAmount / (this.costTotalActivityBudget / 100);
    },
    taxAmountForAdvance() {
      return this.billBreakdownForm.fields.isAdvanceTaxed.value
        ? (this.taxes[this.selectedTaxId]?.percent || 0) * this.advanceIncrementAmount
        : 0;
    },
    advanceIncrementAmountAfterTax() {
      return this.billBreakdownForm.fields.isAdvanceTaxed.value
        ? this.advanceIncrementAmount + this.taxAmountForAdvance
        : this.advanceIncrementAmount;
    },
    netDeduction() {
      return this.$ci.parse(this.billBreakdownForm.fields.deductionRepayment.value || 0, this.currencyOptions)
        - this.$ci.parse(this.billBreakdownForm.fields.deduction.value || 0, this.currencyOptions);
    },
    taxAmountForDeduction() {
      return this.billBreakdownForm.fields.isDeductionTaxed.value
        ? (this.taxes[this.selectedTaxId]?.percent || 0) * this.netDeduction
        : 0;
    },
    advanceIncrementAmountWithDeductions() {
      return this.advanceIncrementAmount + this.netDeduction;
    },
    taxAmountForDownPayment() {
      return this.billBreakdownForm.fields.isDownPaymentTaxed.value
        ? (this.taxes[this.selectedTaxId]?.percent || 0) * this.downPaymentNet
        : 0;
    },
    downPaymentNet() {
      return this.$ci.parse(this.billBreakdownForm.fields.downPayment.value || 0, this.currencyOptions)
        - this.$ci.parse(this.billBreakdownForm.fields.downPaymentDeduction.value || 0, this.currencyOptions);
    },
    retainageNet() {
      return this.$ci.parse(this.billBreakdownForm.fields.retainageRepayment.value || 0, this.currencyOptions)
        - this.$ci.parse(this.billBreakdownForm.fields.retainage.value || 0, this.currencyOptions);
    },
    taxAmountForRetainage() {
      return this.billBreakdownForm.fields.isRetainageTaxed.value
        ? (this.taxes[this.selectedTaxId]?.percent || 0) * this.retainageNet
        : 0;
    },
    taxesTotal() {
      return this.isAdvanceBillingType
        ? this.taxAmountForAdvance + this.taxAmountForDeduction + this.taxAmountForDownPayment + this.taxAmountForRetainage
        : this.billSubtotalNoTaxes * (this.taxes[this.selectedTaxId]?.percent || 0);
    },
    billSubtotalNoTaxes() {
      return this.isAdvanceBillingType
        ? this.advanceIncrementAmount + this.netDeduction + this.downPaymentNet + this.retainageNet
        : this.costTotalActivityBudget;
    },
    billTotal() {
      return this.billSubtotalNoTaxes + this.taxesTotal;
    },
    filteredTaxes() {
      return this.billBreakdownForm.fields.tax.value
        ? Object.values(this.taxes)
          .filter(tax => tax?.name?.toLowerCase().includes(this.billBreakdownForm.fields.tax.value.toLowerCase()))
        : Object.values(this.taxes);
    },
    costBillItems() {
      return this.getBillItemsByCostId(this.activeCost.id);
    },
    accumulatedBillDeductions() {
      return Math.abs(this.costBillItems
        .filter(billItem => (
          billItem.billItemTypeId === this.getBillItemTypeByName(Types.billItemTypes.deduction).id
          && this.bills[billItem.billId].statusId === this.getBillStatusByName(Types.billStatusTypes.closed).id
        ))
        .reduce((total, current) => (total + current.amount), 0));
    },
    accumulatedBillDownPayment() {
      return Math.abs(this.costBillItems
        .filter(billItem => (
          billItem.billItemTypeId === this.getBillItemTypeByName(Types.billItemTypes.downPayment).id
          && this.bills[billItem.billId].statusId === this.getBillStatusByName(Types.billStatusTypes.closed).id
        ))
        .reduce((total, current) => (total + current.amount), 0));
    },
    accumulatedBillRetainage() {
      return Math.abs(this.costBillItems
        .filter(billItem => (
          billItem.billItemTypeId === this.getBillItemTypeByName(Types.billItemTypes.retainage).id
          && this.bills[billItem.billId].statusId === this.getBillStatusByName(Types.billStatusTypes.closed).id
        ))
        .reduce((total, current) => (total + current.amount), 0));
    },
  },

  watch: {
    async isOpen(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.setInitialUiState();
        this.fetchBillDocuments();

        await this.$nextTick(() => {
          this.setInitialFormState();
        });
      }
    },
    'billBreakdownForm.fields.deduction.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('deduction', value);
      this.validateMaxValue(value, this.advanceIncrementAmount, this.billBreakdownForm.fields.deduction.ref);
    },
    'billBreakdownForm.fields.deductionPercent.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('deductionPercent', value);
      this.validateMaxValue(value, 10000, this.billBreakdownForm.fields.deductionPercent.ref);
    },
    'billBreakdownForm.fields.deductionRepayment.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('deductionRepayment', value);
      this.validateMaxValue(value, this.accumulatedBillDeductions, this.billBreakdownForm.fields.deductionRepayment.ref);
    },
    'billBreakdownForm.fields.deductionRepaymentPercent.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('deductionRepaymentPercent', value);
      this.validateMaxValue(value, 10000, this.billBreakdownForm.fields.deductionRepaymentPercent.ref);
    },
    'billBreakdownForm.fields.downPayment.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('downPayment', value);
      this.validateMaxValue(value, null, this.billBreakdownForm.fields.downPayment.ref);
    },
    'billBreakdownForm.fields.downPaymentPercent.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('downPaymentPercent', value);
      this.validateMaxValue(value, null, this.billBreakdownForm.fields.downPaymentPercent.ref);
    },
    'billBreakdownForm.fields.downPaymentDeduction.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('downPaymentDeduction', value);
      this.validateMaxValue(value, this.accumulatedBillDownPayment, this.billBreakdownForm.fields.downPaymentDeduction.ref);
    },
    'billBreakdownForm.fields.downPaymentDeductionPercent.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('downPaymentDeductionPercent', value);
      this.validateMaxValue(value, 10000, this.billBreakdownForm.fields.downPaymentDeductionPercent.ref);
    },
    'billBreakdownForm.fields.retainage.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('retainage', value);
      this.validateMaxValue(value, this.advanceIncrementAmount, this.billBreakdownForm.fields.retainage.ref);
    },
    'billBreakdownForm.fields.retainagePercent.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('retainagePercent', value);
      this.validateMaxValue(value, 10000, this.billBreakdownForm.fields.retainagePercent.ref);
    },
    'billBreakdownForm.fields.retainageRepayment.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('retainageRepayment', value);
      this.validateMaxValue(value, this.accumulatedBillRetainage, this.billBreakdownForm.fields.retainageRepayment.ref);
    },
    'billBreakdownForm.fields.retainageRepaymentPercent.value'(newValue) { // eslint-disable-line
      const value = this.$ci.parse(newValue, this.currencyOptions);

      this.updateLinkedInput('retainageRepaymentPercent', value);
      this.validateMaxValue(value, 10000, this.billBreakdownForm.fields.retainageRepaymentPercent.ref);
    },
  },

  created() {
    this.setInitialUiState();
  },

  methods: {
    addSuffix,
    convertFractionToPercent,
    displayCurrency,
    calculateActivityBudget,
    handleDeleteDocument(index) {
      this.documents.splice(index, 1);
    },
    handleSubmit(billStatusType) {
      // Originally we used a native submit event on the form
      // But because we had a need to differentiate the submitter
      // This had to be changed as the submitter property was not supported in Safari 15.3 and below.
      // const billStatusType = event.submitter.name;

      const [deductionBillItem] = this.getBillItemsByBillIdAndTypeName(this.activeBill?.id, Types.billItemTypes.deduction);
      const [downPaymentBillItem] = this.getBillItemsByBillIdAndTypeName(this.activeBill?.id, Types.billItemTypes.downPayment);
      const [retainageBillItem] = this.getBillItemsByBillIdAndTypeName(this.activeBill?.id, Types.billItemTypes.retainage);

      const billBreakdownItems = [];
      if (this.netDeduction !== 0 || deductionBillItem) {
        billBreakdownItems.push({
          billItemTypeId: this.getBillItemTypeByName(Types.billItemTypes.deduction).id,
          amount: this.netDeduction,
          isTaxed: this.billBreakdownForm.fields.isDeductionTaxed.value,
          ...deductionBillItem && {
            id: deductionBillItem.id,
          },
        });
      }

      if (this.downPaymentNet !== 0 || downPaymentBillItem) {
        billBreakdownItems.push({
          billItemTypeId: this.getBillItemTypeByName(Types.billItemTypes.downPayment).id,
          amount: this.downPaymentNet,
          isTaxed: this.billBreakdownForm.fields.isDownPaymentTaxed.value,
          ...downPaymentBillItem && {
            id: downPaymentBillItem.id,
          },
        });
      }

      if (this.retainageNet !== 0 || retainageBillItem) {
        billBreakdownItems.push({
          billItemTypeId: this.getBillItemTypeByName(Types.billItemTypes.retainage).id,
          amount: this.retainageNet,
          isTaxed: this.billBreakdownForm.fields.isRetainageTaxed.value,
          ...retainageBillItem && {
            id: retainageBillItem.id,
          },
        });
      }

      this.$emit('submit', {
        billBreakdownItems,
        isAdvanceTaxed: this.billBreakdownForm.fields.isAdvanceTaxed.value,
        billName: this.billBreakdownForm.fields.name.value,
        taxId: this.selectedTaxId,
        billStatusType,
        documents: this.documents,
      });
    },
    updateLinkedInput(inputKey, value, options = { forceUpdate: false }) {
      const input = this.billBreakdownForm.fields[inputKey];
      const linkedInput = this.billBreakdownForm.fields[input.linkedTo];
      const { shaper, ref: linkedInputRef } = linkedInput;

      if (document.activeElement === this.$refs[input.ref] || options.forceUpdate) {
        this.$ci.setValue(this.$refs[linkedInputRef], shaper(value));
      }
    },
    validateMaxValue(value, max, inputRef) {
      if (max !== null && value > max) {
        this.$ci.setValue(this.$refs[inputRef], max);
      }
    },
    handleToggleCreateTaxModal() {
      this.isCreateTaxModalOpen = !this.isCreateTaxModalOpen;
    },
    isInputDisabled(inputKey) {
      const field = this.billBreakdownForm.fields[inputKey];
      const value = this.$ci.parse(this.billBreakdownForm.fields[field.disabledBy].value, this.currencyOptions);
      return value !== null && value !== 0;
    },
    handleToggleData(key) {
      this[key] = !this[key];
    },
    handleCreateTaxSuccess(taxId) {
      this.selectedTaxId = taxId;
      this.billBreakdownForm.fields.tax.value = this.taxes[this.selectedTaxId].name;
      this.handleToggleCreateTaxModal();
    },
    handleCloseModal() {
      this.$emit('close');
    },
    handleSelectTax(tax) {
      this.selectedTaxId = tax ? tax.id : null;
      if (this.selectedTaxId) {
        this.billBreakdownForm.fields.tax.value = this.taxes[this.selectedTaxId].name;
      }
    },
    handleTaxFieldBlur() {
      // Wrapped in a setTimeout to avoid race condition of events between @select and @blur.
      setTimeout(() => {
        if (!this.selectedTaxId) {
          this.billBreakdownForm.fields.tax.value = '';
        }
      }, 200);
    },
    setInitialUiState() {
      if (this.defaultBillItemType === Types.billItemTypes.advance) return;

      Object.keys(this.isSectionOpen).forEach(key => {
        this.isSectionOpen[key] = false;
      });

      this.isSectionOpen[Types.billItemTypes[this.defaultBillItemType]] = true;
    },
    setInitialFormState() {
      if (this.activeBill) {
        const { name, taxId } = this.activeBill;
        const [deductionBillItem] = this.getBillItemsByBillIdAndTypeName(this.activeBill?.id, Types.billItemTypes.deduction);
        const [downPaymentBillItem] = this.getBillItemsByBillIdAndTypeName(this.activeBill?.id, Types.billItemTypes.downPayment);
        const [retainageBillItem] = this.getBillItemsByBillIdAndTypeName(this.activeBill?.id, Types.billItemTypes.retainage);
        const [firstAdvanceBillItem] = this.getBillItemsByBillIdAndTypeName(this.activeBill?.id, Types.billItemTypes.advance);

        if (name) this.billBreakdownForm.fields.name.value = name;

        if (taxId) {
          this.selectedTaxId = taxId;
          this.billBreakdownForm.fields.tax.value = this.taxes[this.selectedTaxId].name;
        }

        if (deductionBillItem) {
          this.setBillItemFieldInitialState('deductionRepayment', 'deduction', deductionBillItem.amount);
          this.billBreakdownForm.fields.isDeductionTaxed.value = deductionBillItem.isTaxed;
        }

        if (downPaymentBillItem) {
          this.setBillItemFieldInitialState('downPayment', 'downPaymentDeduction', downPaymentBillItem.amount);
          this.billBreakdownForm.fields.isDownPaymentTaxed.value = downPaymentBillItem.isTaxed;
        }

        if (retainageBillItem) {
          this.setBillItemFieldInitialState('retainageRepayment', 'retainage', retainageBillItem.amount);
          this.billBreakdownForm.fields.isRetainageTaxed.value = retainageBillItem.isTaxed;
        }

        if (
          (this.isAdvanceBillingType && firstAdvanceBillItem && firstAdvanceBillItem.isTaxed)
          || (this.isLumpSumBillingType)
        ) {
          this.billBreakdownForm.fields.isAdvanceTaxed.value = true;
        }
      }
    },
    setBillItemFieldInitialState(addField, subtractField, amount) {
      const value = Math.abs(amount);

      const deductionField = amount > 0 ? addField : subtractField;
      const input = this.billBreakdownForm.fields[deductionField];
      const { ref } = input;

      this.$ci.setValue(this.$refs[ref], value);
      this.updateLinkedInput(deductionField, value, { forceUpdate: true });
    },
    async fetchBillDocuments() {
      if (!this.billId) return;

      this.areDocumentsLoading = true;

      try {
        await this.$store.dispatch(
          DocumentActions.FETCH_DOCUMENTS_BY_RESOURCE_ID,
          { resourceId: this.billId, resourceType: 'bill' },
        );
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_BILL_HISTORY.ERROR);
      }

      this.areDocumentsLoading = false;
    },
  },
};
</script>

<style lang="scss">
.payment-settings-modal__field-label {
  flex-basis: 20% !important;
}

.bill-breakdown-modal__collapse-container {
  margin-top: -3rem;

  & > .collapse-trigger {
    position: relative;
    z-index: 1;
    display: block;
    text-align: right;

    & > .icon {
      background-color: $white;
      width: 50px;
      justify-content: flex-end;
    }
  }
}

.bill-breakdown-modal__tabs__tab-item__section {
  min-height: 100vh;
  padding: $global-whitespace-small;
}
</style>
