import { normalize } from 'normalizr';
import * as CostMutations from '@/store/mutations/Cost.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as VendorActions from '@/store/actions/Vendor.actions';
import * as VendorMutations from '@/store/mutations/Vendor.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  vendors: {},
});

const receiveVendors = (state, payload) => {
  state.vendors = {
    ...state.vendors,
    ...payload.vendors,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const deleteVendor = (state, payload) => {
  const updatedVendors = { ...state.vendors };
  delete updatedVendors[payload.vendorId];
  state.vendors = { ...updatedVendors };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  fuzzyFindVendorByName(state) {
    return name => Object.values(state.vendors)
      .filter(vendor => vendor.name.toLowerCase().includes(name.toLowerCase()))[0];
  },
};

const mutations = {
  [VendorMutations.VENDOR_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [VendorMutations.VENDOR_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [VendorMutations.VENDOR_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [VendorMutations.RECEIVE_VENDORS]: receiveVendors,
  [CostMutations.RECEIVE_COSTS]: receiveVendors,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveVendors,
  [BillMutations.RECEIVE_BILLS]: receiveVendors,
  [VendorMutations.DELETE_VENDOR]: deleteVendor,
};

const actions = {
  [VendorActions.FETCH_ORGANIZATION_VENDORS]: async (context, payload) => {
    context.commit(VendorMutations.VENDOR_REQUEST_DATA);
    const { organizationId, params = {} } = payload;
    try {
      const {
        data: {
          vendors,
          _count: vendorCount,
        },
      } = await Api.organization.vendor.findMany(organizationId, params);

      const normalizedVendors = normalize(vendors, [schemas.vendor]);
      context.commit(VendorMutations.RECEIVE_VENDORS, { ...normalizedVendors.entities });

      return {
        vendorIds: Object.keys(normalizedVendors?.entities?.vendors ?? {}),
        vendorCount,
      };
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data);
    }
  },

  [VendorActions.CREATE_VENDOR]: async (context, payload) => {
    context.commit(VendorMutations.VENDOR_BEGIN_WRITE_DATA);

    try {
      const { data: { vendor } } = await Api.vendor.create(payload);
      const normalizedVendor = normalize(vendor, schemas.vendor);
      context.commit(VendorMutations.RECEIVE_VENDORS, {
        ...normalizedVendor.entities,
      });

      return normalizedVendor.result; // Created vendor id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  [VendorActions.UPDATE_VENDOR_BY_ID]: async (context, payload) => {
    context.commit(VendorMutations.VENDOR_BEGIN_WRITE_DATA);

    try {
      const { id, updatedVendor } = payload;

      const { data: { vendor } } = await Api.vendor.updateOne(id, updatedVendor);

      const normalizedVendor = normalize(vendor, schemas.vendor);
      context.commit(VendorMutations.RECEIVE_VENDORS, {
        ...normalizedVendor.entities,
      });

      return normalizedVendor.result; // Created vendor id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  [VendorActions.FETCH_VENDOR_BY_ID]: async (context, payload) => {
    context.commit(VendorMutations.VENDOR_BEGIN_WRITE_DATA);

    try {
      const { vendorId } = payload;

      const { data: { vendor } } = await Api.vendor.findOne(vendorId);

      const normalizedVendor = normalize(vendor, schemas.vendor);
      context.commit(VendorMutations.RECEIVE_VENDORS, {
        ...normalizedVendor.entities,
      });

      return normalizedVendor.result; // Created vendor id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [VendorActions.DELETE_VENDOR_BY_ID](context, payload) {
    context.commit(VendorMutations.VENDOR_BEGIN_WRITE_DATA);
    try {
      await Api.vendor.deleteOne(payload.vendorId);

      context.commit(VendorMutations.DELETE_VENDOR, { vendorId: payload.vendorId });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
