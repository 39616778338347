<template>
  <div class="card card--inset card--dark">
    <div class="card-content">
      <form @submit.prevent="handleCreateNewActivityCategory" class="columns is-vcentered">
        <div
          :class="[
            'field column is-12-mobile is-7-tablet is-6-widescreen',
            'required',
          ]"
        >
          <label :class="['label']">{{ $t('createActivityCategoryForm.name.label') }}</label>
          <div :class="['control']">
            <input
              :class="['input', 'input--icon-dark', { 'is-danger':  createActivityCategoryForm.formErrors[$t(createActivityCategoryForm.fields.name.name)] }]"
              v-model="createActivityCategoryForm.fields.name.value"
              :ref="createActivityCategoryForm.fields.name.ref"
              :disabled="createActivityCategoryForm.isFormSubmissionInProgress"
              @input="handleTouchForm"
            />
          </div>
          <p v-if="createActivityCategoryForm.formErrors[$t(createActivityCategoryForm.fields.name.name)]" class="help is-danger">
            {{ createActivityCategoryForm.formErrors[$t(createActivityCategoryForm.fields.name.name)] }}
          </p>
        </div>
        <div
          :class="[
            'field column is-12-mobile is-7-tablet is-6-widescreen',
            'required',
          ]"
        >
          <BButton type="is-primary" nativeType="submit" style="margin-top: 23px;">
            {{ activityCategoryId ? $t('common.ctas.updateActivityCategory') : $t('common.ctas.createActivityCategory') }}
          </BButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import * as ValidationService from '@/services/Validation.service';
import * as ActivityCategoryActions from '@/store/actions/ActivityCategory.actions';
import createActivityCategoryForm from '@/forms/createActivityCategory.form';

export default {
  name: 'CreateOrEditActivityCategoryCard',

  props: {
    // Pass an exiting activityCategoryId if you wish to edit an existing activity category.
    activityCategoryId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isComponentLoading: true,
      createActivityCategoryForm: cloneDeep(createActivityCategoryForm),
      isCreatingActivityCategory: false,
    };
  },

  computed: {
    ...mapState({
      activityCategories: state => state.activityCategory.activityCategories,
    }),
    ...mapGetters([
      'activeOrganization',
    ]),
    activeActivityCategory() {
      return this.activityCategories[this.activityCategoryId];
    },
  },

  mounted() {
    this.setInitialFormState();
  },

  methods: {
    setInitialFormState() {
      this.createActivityCategoryForm = cloneDeep(createActivityCategoryForm);
      if (this.activityCategoryId) {
        const {
          name,
        } = this.activeActivityCategory;

        this.createActivityCategoryForm.fields.name.value = name;
      }

      this.$refs[this.createActivityCategoryForm.fields.name.ref].focus();
    },
    async handleCreateNewActivityCategory() {
      this.createActivityCategoryForm.formErrors = ValidationService.runFormValidation({ ...this.createActivityCategoryForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.createActivityCategoryForm.formErrors)) return;
      this.isCreatingActivityCategory = true;

      try {
        if (this.activityCategoryId) {
          await this.$store.dispatch(ActivityCategoryActions.UPDATE_ACTIVITY_CATEGORY_BY_ID, {
            activityCategoryId: this.activityCategoryId,
            updatedActivityCategory: { name: this.createActivityCategoryForm.fields.name.value },
          });
        } else {
          await this.$store.dispatch(ActivityCategoryActions.CREATE_ACTIVITY_CATEGORY, {
            name: this.createActivityCategoryForm.fields.name.value,
          });
        }
        this.$emit('submitSuccess');
      } catch (err) {
        console.error(err);
      }

      this.setInitialFormState();
      this.isCreatingActivityCategory = false;
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>
