<template>
  <div class="card" v-if="activity" v-bind="$attrs">
    <div class="card-content u-m-y-xlarge has-text-left">
      <h5 class="title is-5">{{ activity.name }}</h5>
      <p>{{ displayCurrency(calculateActivityBudget(activity.unitValue, activity.quantity), { prefix: '$', multiplier: 100 }) }}</p>
      <small>
        {{ activity.quantity }} {{ activity.unit }} @ {{ displayCurrency(activity.unitValue, { multiplier: 100, prefix: '$' }) }}
      </small>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { displayCurrency } from '@/helpers/stringHelpers';
import { calculateActivityBudget } from '@/helpers/activityHelpers';

export default {
  name: 'ActivityCard',

  props: {
    activityId: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
    }),
    activity() {
      return this.activities[this.activityId];
    },
  },

  methods: {
    displayCurrency,
    calculateActivityBudget,
  },
};
</script>
