<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
        {{ $t('common.notifications.archivedProject') }}
      </BMessage>
      <section class="app-page__header app-page__header--no-wrap">
        <div class="app-page__header__title app-page__header__title--no-wrap">
          <h4 class="title is-4">
            {{ activeProject.name }}
          </h4>
          <p class="subtitle u-m-b-xsmall">{{ activeProject.description }}</p>
          <router-link v-if="clients[activeProject.clientId]" :to="`/clients/${activeProject.clientId}/edit`">
            <p class="is-size-6 is-italic has-text-primary">
              <BIcon icon="briefcase-account" size="is-small" />
              &nbsp;
              {{ clients[activeProject.clientId].name }}
            </p>
          </router-link>
        </div>
        <div class="app-page__header__action app-page__header__action--no-wrap">
          <div class="items-top">
            <BDropdown
              class="custom-dropdown"
              position="is-bottom-left"
              :mobile-modal="false"
              @active-change="handleToggleProjectDropdown"
            >
              <span slot="trigger">
                <!-- <BTooltip :label="$t('common.terms.details')" position="is-top" :triggers="['hover']" type="is-dark" class="u-m-r-small"> -->
                  <BButton
                    type="is-primary"
                  >
                    <BIcon icon="dots-vertical" />
                  </BButton>
                <!-- </BTooltip> -->
              </span>
              <BDropdownItem @click="handleProjectDropdownClick(`/projects/${activeProjectId}/edit`)">
                <BIcon icon="pencil" />&nbsp;{{ $t('common.ctas.editDetails') }}
              </BDropdownItem>
              <BDropdownItem @click="handleProjectDropdownClick(`/projects/${activeProjectId}/budgets`)">
                <BIcon icon="cash-multiple" />&nbsp;{{ $t('common.terms.budgets') }}
              </BDropdownItem>
              <BDropdownItem @click="handleProjectDropdownClick(`/projects/${activeProjectId}/change-orders`)">
                <BIcon icon="format-list-group-plus" />&nbsp;{{ $t('common.terms.changeOrders') }}
              </BDropdownItem>
            </BDropdown>
          </div>
          <!-- <div class="buttons is-small is-align-items-flex-start">
            <BButton
              v-if="isLoggedInUserAtLeastProjectAssistant"
              tag="router-link"
              :to="`/projects/${activeProjectId}/edit`"
              type="is-primary"
              size="is-small"
              outlined
              icon-left="pencil"
            >
              {{ $t('common.ctas.editDetails') }}
            </BButton>
            <BButton
              tag="router-link"
              :to="`/projects/${activeProjectId}/budgets`"
              type="is-primary"
              size="is-small"
              outlined
              icon-left="cash-multiple"
            >
              {{ $t('common.terms.budgets') }}
            </BButton>
            <BButton
              tag="router-link"
              type="is-primary"
              size="is-small"
              outlined
              icon-left="format-list-group-plus"
              :to="`/projects/${activeProjectId}/change-orders`"
            >
              {{ $t('common.terms.changeOrders') }}
            </BButton>
          </div> -->
        </div>
      </section>

      <section class="app-page__section">
        <!-- <BProgress :value="Math.round(advancePercent * 100)" type="is-primary" class="u-m-t-xsmall" showValue format="percent" /> -->
        <div class="columns">
          <div
            v-for="total in projectTotals"
            :key="total.key"
            class="column"
          >
            <TotalsSummaryCard
              :title="total.title"
              :tooltipInfo="total.tooltipInfo"
            >
              <div class="card-content u-p-t-0 u-p-b-small u-p-x">
                <div class="title is-size-4 is-size-3-fullhd has-text-weight-black u-m-b-0 has-text-right">
                  <BTooltip
                    multilined
                    appendToBody
                    :active="total.hasTooltip"
                    :triggers="['click']"
                    :autoClose="['outside', 'escape']"
                    position="is-left"
                    type="is-white"
                    class="project-details-view__total-card__breakdown-tooltip"
                  >
                    <AnimatedNumber
                      :value="total.total"
                      :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                      :duration="400"
                      :class="[{ 'is-clickable is-underlined': total.hasTooltip }]"
                    />
                    <template v-slot:content>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.billItemTypes.advance') }}
                        </div>
                        <div class="column has-text-right">
                          {{ displayCurrency(total.advance, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row ">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.billItemTypes.downPayment') }}
                        </div>
                        <div class="column has-text-right">
                          + {{ displayCurrency(total.downPayment, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row ">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.billItemTypes.retainage') }}
                        </div>
                        <div class="column has-text-right">
                          - {{ displayCurrency(total.retainage, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row ">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.billItemTypes.deduction') }}
                        </div>
                        <div class="column has-text-right">
                          - {{ displayCurrency(total.deduction, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row ">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.terms.taxes') }}
                        </div>
                        <div class="column has-text-right">
                          + {{ displayCurrency(total.tax, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                    </template>
                  </BTooltip>
                </div>
              </div>
            </TotalsSummaryCard>
          </div>
        </div>
      </section>

      <section class="app-page__section">
        <div class="columns">
          <div class="column">
            <ProjectCostsSummary :activeProjectId="activeProjectId" />
          </div>
          <div class="column">
            <ProjectBillsSummary :activeProjectId="activeProjectId" />
          </div>
        </div>
      </section>

      <!-- <section class="app-page__section u-m-t-large">
        <div class="app-page__header u-m-b-0">
          <div class="app-page__header__title">
            <div style="display: flex; align-items: baseline">
              <h5 class="title is-5 u-m-b-0">{{ $t('common.titles.costs') }}</h5>
            </div>
          </div>
          <div class="app-page__header__action">
            <BButton
              type="is-primary"
              size="is-small"
              iconLeft="plus-thick"
              @click.prevent="handleOpenCreateCostModal"
              @keyup.enter.prevent="handleOpenCreateCostModal"
            >
              {{ $t('common.ctas.createCost') }}
            </BButton>
          </div>
        </div>
        <CreateCostModal
          v-if="isLoggedInUserAtLeastProjectManager && !isProjectArchived(activeProject.id)"
          class="u-m-b"
          :projectId="activeProjectId"
          :isOpen="isCreateCostModalOpen"
          @close="handleCloseCreateCostModal"
          @submitSuccess="handleCreateCostSuccess"
        />
        <ProjectCostsTable :projectId="this.activeProjectId" />
      </section> -->
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';
import { mapState, mapGetters } from 'vuex';
// import AnimatedNumber from 'animated-number-vue';
import * as Types from '@/constants/Types';
import * as BillStatusConstants from '@/constants/BillStatus.constants';
import * as ProjectActions from '@/store/actions/Project.actions';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
// import CreateCostModal from '@/components/CreateCostModal.vue';
// import ProjectCostsTable from '@/components/ProjectCostsTable.vue';
import TotalsSummaryCard from '@/components/TotalsSummaryCard.vue';
import ProjectCostsSummary from '../components/ProjectCostsSummary.vue';
import ProjectBillsSummary from '../components/ProjectBillsSummary.vue';

export default {
  name: 'ProjectDetails',

  components: {
    // CreateCostModal,
    // ProjectCostsTable,
    TotalsSummaryCard,
    AnimatedNumber,
    ProjectCostsSummary,
    ProjectBillsSummary,
  },

  data() {
    return {
      isPageLoading: true,
      isCreateCostModalOpen: false,
      selectedCostTypes: [],
      isProjectDropdownActive: false,
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      projects: state => state.project.projects,
      costTypes: state => state.costType.costTypes,
      clients: state => state.client.clients,
    }),
    ...mapGetters([
      'activeOrganization',
      'getTotalBudgetValue',
      'getProjectTotalActivityBudget',
      'getProjectTotalAdvanceAmount',
      'getProjectTotalDownPaymentAmount',
      'getProjectTotalRetainageAmount',
      'getProjectTotalDeductionAmount',
      'getProjectTotalClosedAmount',
      'getProjectTotalClosedTaxAmount',
      'getProjectTotalOpenTaxAmount',
      'isLoggedInUserAtLeastProjectManager',
      'isLoggedInUserAtLeastProjectAssistant',
      'getOpenBillItemsByProjectId',
      'getClosedBillItemsByProjectId',
      'isProjectArchived',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    budgetedVsContracted() {
      return {
        title: `${this.$t('common.terms.budgeted')} vs. ${this.$t('common.terms.contracted')}`,
        budgeted: this.getTotalBudgetValue(this.activeProject.activeBudgetId) ?? 0,
        contracted: this.getProjectTotalActivityBudget(this.activeProjectId),
        budgetedLabel: this.$t('common.terms.budgeted'),
        contractedLabel: this.$t('common.terms.contracted'),
        percentOfBudgetedContracted: (
          ((this.getProjectTotalActivityBudget(this.activeProjectId) - this.getTotalBudgetValue(this.activeProject.activeBudgetId))
          / this.getTotalBudgetValue(this.activeProject.activeBudgetId)) + 1
        ),
        percentLabel: 'Percent of Budget Contracted',
        // tooltipInfo: this.$t('costDetailsView.summaryInfo.contracted'),
      };
    },
    advancePercent() {
      return this.getProjectTotalAdvanceAmount(this.activeProjectId) / this.getProjectTotalActivityBudget(this.activeProjectId);
    },
    projectTotals() {
      return [
        {
          title: this.$t('common.terms.budgeted'),
          total: this.getTotalBudgetValue(this.activeProject.activeBudgetId) ?? 0,
          tooltipInfo: this.$t('costDetailsView.summaryInfo.budgeted'),
          hasTooltip: false,
        },
        {
          title: this.$t('common.terms.contracted'),
          total: this.getProjectTotalActivityBudget(this.activeProjectId),
          // titleFigure: this.getCostTotalAdvanceAmount(this.activeCostId) / this.getCostTotalActivityBudget(this.activeCostId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.contracted'),
          hasTooltip: false,
        },
        {
          title: this.$t('common.terms.inOpenBills'),
          // total: this.getProjectTotalAdvanceAmount(this.activeProjectId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.inOpenBills'),
          advance: this.getProjectTotalAdvanceAmount(this.activeProjectId, { excludedBillStatusNames: [Types.billStatusTypes.closed] }),
          downPayment: this.getProjectTotalDownPaymentAmount(this.activeProjectId, { excludedBillStatusNames: [Types.billStatusTypes.closed] }),
          retainage: Math.abs(this.getProjectTotalRetainageAmount(this.activeProjectId, { excludedBillStatusNames: [Types.billStatusTypes.closed] })),
          deduction: Math.abs(this.getProjectTotalDeductionAmount(this.activeProjectId, { excludedBillStatusNames: [Types.billStatusTypes.closed] })),
          total: (
            this.getOpenBillItemsByProjectId(this.activeProjectId).reduce((acc, curr) => (acc + curr.amount), 0)
            + this.getProjectTotalOpenTaxAmount(this.activeProjectId)
          ),
          tax: this.getProjectTotalOpenTaxAmount(this.activeProjectId),
          hasTooltip: true,
        },
        {
          title: this.$t('common.terms.totalClosed'),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.totalClosed'),
          advance: this.getProjectTotalAdvanceAmount(this.activeProjectId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] }),
          downPayment: this.getProjectTotalDownPaymentAmount(this.activeProjectId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] }),
          retainage: Math.abs(this.getProjectTotalRetainageAmount(this.activeProjectId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] })),
          deduction: Math.abs(this.getProjectTotalDeductionAmount(this.activeProjectId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] })),
          tax: this.getProjectTotalClosedTaxAmount(this.activeProjectId),
          total: (
            this.getProjectTotalClosedAmount(this.activeProjectId)
            + this.getProjectTotalClosedTaxAmount(this.activeProjectId)
          ),
          hasTooltip: true,
        },
      ];
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    displayCurrency,
    addSuffix,
    convertFractionToPercent,
    handleCloseCreateCostModal() {
      this.isCreateCostModalOpen = false;
    },
    handleOpenCreateCostModal() {
      this.isCreateCostModalOpen = true;
    },
    handleCreateCostSuccess() {
      this.isCreateCostModalOpen = false;
    },
    handleToggleProjectDropdown(isDropdownActive) {
      this.isProjectDropdownActive = isDropdownActive;
    },
    handleProjectDropdownClick(route) {
      this.$router.push(route);
    },
    async fetchInitialData() {
      this.isPageLoading = true;
      const projectId = this.activeProjectId;
      await Promise.all([
        this.$store.dispatch(ProjectActions.FETCH_PROJECT_BY_ID, { projectId }),
      ]);

      this.isPageLoading = false;
    },
  },
};
</script>

<style lang="scss">
.project-details-view__total-card__breakdown-tooltip {
  > .tooltip-content {
    padding: 0;
  }
}
</style>
