<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('createClientView.title') }}</h4>
        </div>
      </section>

      <section class="app-page__section">
        <div>
          <form @submit.prevent="handleSubmitCreateClient">
            <h5 class="title is-5 has-text-grey">
              {{ $t('createClientView.subtitle') }}
            </h5>
            <BField v-if="formSubmissionError">
              <BMessage type="is-danger">
                {{  formSubmissionError.message }}
              </BMessage>
            </BField>
            <div class="columns is-multiline">
              <BField
                v-for="formField in Object.values(createClientForm.fields)"
                :key="formField.key"
                :class="['column', { 'required': formField.required, [`${formField.width}`]: formField.width }]"
                :label="$t(formField.label)"
                :type="createClientForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
                :message="createClientForm.formErrors[$t(formField.name)] || ''"
              >
                <BInput
                  :type="formField.type || 'text'"
                  v-model="formField.value"
                  :disabled="createClientForm.isFormSubmissionInProgress"
                  :placeholder="$t(formField.placeholder)"
                  @input="handleTouchForm"
                  rows="2"
                  :ref="$t(formField.name)"
                />
              </BField>
            </div>
            <div class="is-flex is-justify-content-flex-end">
              <BButton
                type="is-primary"
                native-type="submit"
                :disabled="isFormSubmissionInProgress"
                :loading="isFormSubmissionInProgress"
              >
                {{ $t('common.ctas.createClient') }}
              </BButton>
            </div>
          </form>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import * as ClientActions from '@/store/actions/Client.actions';
import * as ValidationService from '@/services/Validation.service';
import createClientForm from '@/forms/createClient.form';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'CreateClientView',

  data() {
    return {
      isPageLoading: false,
      isFormSubmissionInProgress: false,
      formSubmissionError: null,
      createClientForm: cloneDeep(createClientForm),
    };
  },

  computed: {
    ...mapGetters([
      'loggedInUser',
    ]),
  },

  mounted() {
    this.setInitialFormState();
  },

  methods: {
    setInitialFormState() {
      this.$refs[this.$t(this.createClientForm.fields.name.name)][0].$el.children[0].focus();
    },
    async handleSubmitCreateClient() {
      this.formSubmissionError = null;
      this.createClientForm.formErrors = {};
      this.createClientForm.formErrors = ValidationService.runFormValidation({ ...this.createClientForm.fields }, { i18n: true });

      if (!isObjectEmpty(this.createClientForm.formErrors)) return;

      this.createClientForm.isFormSubmissionInProgress = true;
      const { fields } = this.createClientForm;
      try {
        const newClientId = await this.$store.dispatch(
          ClientActions.CREATE_CLIENT,
          {
            newClient:
              {
                name: fields.name.value,
                description: fields.description.value,
                contactName: fields.contactName.value,
                contactEmail: fields.contactEmail.value,
                contactPhone: fields.contactPhone.value,
                legalId1: fields.id1.value,
                legalId2: fields.id2.value,
              },
          },
        );
        this.createClientForm.isFormSubmissionInProgress = false;
        this.$router.push(`/clients/${newClientId}/edit`);
      } catch (err) {
        console.log(err);
        this.formSubmissionError = err;
        this.createClientForm.isFormSubmissionInProgress = false;
      }
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
