<template>
  <BModal
      :active="!!costIdConfirmingDelete"
      @close="handleCloseDeleteModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="costIdConfirmingDelete" class="modal-card-title">
            {{ $t('common.ctas.delete') }}
            {{ $t('common.terms.cost') }}
          </p>
          <button
            type="button"
            class="delete"
            @click="handleCloseDeleteModal"
          ></button>
        </header>
        <section class="modal-card-body has-background-white u-p-a has-text-centered">
          <div>
            <p>
              <BIcon icon="alert" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('modals.deleteCostModal.warning') }}</h5>
            <h6 class="title is-6">{{ $t('modals.deleteCostModal.warningUndo') }}</h6>
            <div class="is-flex is-justify-content-center">
              <div v-if="costIdConfirmingDelete && costs[costIdConfirmingDelete]">
                <p class="has-text-weight-bold is-size-5">{{ costs[costIdConfirmingDelete].name }}</p>
                <div class="u-m-t-small"><CostTypeTag :costTypeId="costs[costIdConfirmingDelete].costTypeId" /></div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleCloseDeleteModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-danger"
              @click="handleConfirmDeleteCost(costIdConfirmingDelete)"
              :loading="costsDeleting.includes(costIdConfirmingDelete)"
            >
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.cost') }}
            </BButton>
          </div>
        </footer>
      </div>
    </BModal>
</template>

<script>
import { mapState } from 'vuex';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as CostActions from '@/store/actions/Cost.actions';
import CostTypeTag from '@/components/CostTypeTag.vue';

export default {
  name: 'CostConfirmDeleteModal',

  components: {
    CostTypeTag,
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    costId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      costsDeleting: [],
      costIdConfirmingDelete: null,
    };
  },

  watch: {
    costId(newVal) {
      this.costIdConfirmingDelete = newVal;
    },
  },

  computed: {
    ...mapState({
      costs: state => state.cost.costs,
    }),
  },

  methods: {
    async handleConfirmDeleteCost(costId) {
      this.costsDeleting = [...this.costsDeleting, costId];
      try {
        await this.$store.dispatch(CostActions.DELETE_COST_BY_ID, {
          costId,
        });
        this.handleCloseDeleteModal();
        NotificationService.showNotification(NotificationTypes.DELETE_COST.SUCCESS);
        console.log(this.$router.history.current.name.toLowerCase());
        if (this.$router.history.current.name.toLowerCase() === 'projectcosts') {
          return;
        }
        await this.$router.push(`/projects/${this.projectId}/costs`);
        this.$router.go();
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_COST.ERROR);
      }
      this.costsDeleting = this.costsDeleting.filter(a => a !== costId);
    },
    handleCloseDeleteModal() {
      this.$emit('onCostDeleteModalClosed');
    },
  },
};
</script>
