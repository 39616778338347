<template>
  <div>
    <div v-if="!isComponentLoading" class="card card--dark card--inset">
      <!-- <CreateCostCodeCategoryModal
        :isOpen="isAddCostCodeCategoryModalOpen"
        :initialCostCodeCategoryName="createCostCodeForm.fields.costCodeCategory.value"
        @close="handleToggleAddCostCodeCategoryModal"
        @submitSuccess="handleCreateCostCodeCategorySuccess"
      /> -->
      <div class="card-content">
        <BField v-if="submissionError">
          <BMessage type="is-danger">
            {{ submissionError.message }}
          </BMessage>
        </BField>
        <form @submit.prevent="handleSubmitCreateCostCode">
          <div :class="['columns', 'is-desktop', { 'is-multiline': areFieldsStacked }]">
            <BField
              v-for="formField in Object.values(createCostCodeForm.fields)"
              :key="formField.key"
              :class="['column', { 'required': formField.required }, { 'is-12': areFieldsStacked }]"
              :label="$t(formField.label)"
              :type="createCostCodeForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
              :message="createCostCodeForm.formErrors[$t(formField.name)] || ''"
            >
              <BInput
                v-if="!formField.autocomplete"
                v-model="formField.value"
                :disabled="createCostCodeForm.isFormSubmissionInProgress"
                @input="handleTouchForm"
              />
              <!-- <BAutocomplete
                v-if="formField.autocomplete"
                :class="[{
                  'disable-on-select': true,
                }]"
                :data="filteredCostCodeCategories"
                v-model="formField.value"
                :placeholder="$t(formField.placeholder)"
                field="name"
                :disabled="createCostCodeForm.isFormSubmissionInProgress || selectedCostCodeCategoryId"
                openOnFocus
                :maxHeight="200"
                clearable
                appendToBody
                :selectOnClickOutside="false"
                @select="handleSelectCostCodeCategory"
                @blur="handleCategoryFieldBlur"
                @input="handleTouchForm"
              >
                <template slot="footer">
                  <div @click="handleToggleAddCostCodeCategoryModal" type="is-info" outlined size="is-small">
                    <span class="autocomplete__footer__cta">
                      <BIcon icon="plus-circle-outline" customSize="mdi-18px" />
                      {{
                        (formField.value && !selectedCostCodeCategoryId)
                          ? $t('createCostCodeView.ctas.createCostCodeCategory', { costCodeCategory: formField.value })
                          : $t('common.ctas.createCostCodeCategory')
                      }}
                    </span>
                  </div>
                </template>
                <template v-if="filteredCostCodeCategories.length === 0" slot="empty">{{ $t('common.emptyStates.noResults') }}</template>
              </BAutocomplete> -->
            </BField>
            <BField :class="['column', { 'is-12': areFieldsStacked }]" style="margin-top: 26px;">
              <BButton
                type="is-primary"
                icon-left="plus-thick"
                nativeType="submit"
                :loading="createCostCodeForm.isFormSubmissionInProgress"
                :disabled="createCostCodeForm.isFormSubmissionInProgress"
              >
                {{ !costCodeId ? $t('common.ctas.createCostCode') : $t('common.ctas.updateCostCode') }}
              </BButton>
            </BField>
          </div>
        </form>
      </div>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isComponentLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import * as CostCodeActions from '@/store/actions/CostCode.actions';
// import * as CostCodeCategoryActions from '@/store/actions/CostCodeCategory.actions';
import * as ValidationService from '@/services/Validation.service';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import createCostCodeForm from '@/forms/createCostCode.form';
import { isObjectEmpty } from '@/helpers/dataHelpers';
// import CreateCostCodeCategoryModal from '@/components/CreateCostCodeCategoryModal.vue';

export default {
  name: 'CreateOrEditCostCodeCard',

  // components: {
  //   CreateCostCodeCategoryModal,
  // },

  props: {
    // Pass an exiting costCodeId if you wish to edit an existing costCode.
    costCodeId: {
      type: String,
      required: false,
      default: null,
    },
    areFieldsStacked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    'submitSuccess',
  ],

  data() {
    return {
      isComponentLoading: true,
      // isAddCostCodeCategoryModalOpen: false,
      createCostCodeForm: cloneDeep(createCostCodeForm),
      // selectedCostCodeCategoryId: null,
      submissionError: null,
    };
  },

  computed: {
    ...mapState({
      costCodeCategories: state => state.costCodeCategory.costCodeCategories,
      costCodes: state => state.costCode.costCodes,
    }),
    ...mapGetters([
      'activeOrganization',
    ]),
    // sortedCostCodeCategories() {
    //   return !isObjectEmpty(this.costCodeCategories) ? Object.values(this.costCodeCategories).sort((a, b) => (a.name > b.name ? 1 : -1)) : [];
    // },
    // filteredCostCodeCategories() {
    //   return this.createCostCodeForm.fields.costCodeCategory.value
    //     ? this.sortedCostCodeCategories
    //       .filter(({ name }) => name?.toLowerCase().includes(this.createCostCodeForm.fields.costCodeCategory.value.toLowerCase()))
    //     : [...this.sortedCostCodeCategories];
    // },
    activeCostCode() {
      return this.costCodes[this.costCodeId];
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    // handleSelectCostCodeCategory(costCodeCategory) {
    //   this.selectedCostCodeCategoryId = costCodeCategory ? costCodeCategory.id : null;
    //   if (this.selectedCostCodeCategoryId) {
    //     this.createCostCodeForm.fields.costCodeCategory.value = this.costCodeCategories[this.selectedCostCodeCategoryId].name;
    //   }
    // },
    // handleCategoryFieldBlur() {
    //   // Wrapped in a setTimeout to avoid race condition of events between @select and @blur.
    //   setTimeout(() => {
    //     if (!this.selectedCostCodeCategoryId) {
    //       this.createCostCodeForm.fields.costCodeCategory.value = '';
    //     }
    //   }, 200);
    // },
    // handleToggleAddCostCodeCategoryModal() {
    //   this.isAddCostCodeCategoryModalOpen = !this.isAddCostCodeCategoryModalOpen;
    // },
    // handleCreateCostCodeCategorySuccess(costCodeCategoryId) {
    //   this.selectedCostCodeCategoryId = costCodeCategoryId;
    //   this.createCostCodeForm.fields.costCodeCategory.value = this.costCodeCategories[this.selectedCostCodeCategoryId].name;
    //   this.handleToggleAddCostCodeCategoryModal();
    // },
    handleTouchForm() {
      // console.log('form touched');
    },
    async handleSubmitCreateCostCode() {
      this.submissionError = null;
      this.createCostCodeForm.formErrors = {};
      this.createCostCodeForm.formErrors = ValidationService.runFormValidation({ ...this.createCostCodeForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.createCostCodeForm.formErrors)) return;

      this.createCostCodeForm.isFormSubmissionInProgress = true;

      const costCodePayload = {
        code: this.createCostCodeForm.fields.code.value,
        name: this.createCostCodeForm.fields.name.value,
        // costCodeCategoryId: this.selectedCostCodeCategoryId,
      };

      try {
        let costCodeId;

        if (this.costCodeId) {
          costCodeId = await this.$store.dispatch(CostCodeActions.UPDATE_COST_CODE_BY_ID, {
            costCodeId: this.costCodeId,
            updatedCostCode: costCodePayload,
          });
          NotificationService.showNotification(NotificationTypes.UPDATE_COST_CODE.SUCCESS);
        } else {
          costCodeId = await this.$store.dispatch(
            CostCodeActions.CREATE_COST_CODE,
            { newCostCode: costCodePayload },
          );
          NotificationService.showNotification(NotificationTypes.CREATE_COST_CODE.SUCCESS);
        }
        this.createCostCodeForm = cloneDeep(createCostCodeForm);
        this.$emit('submitSuccess', costCodeId);
      } catch (err) {
        this.submissionError = err;
      }

      this.createCostCodeForm.isFormSubmissionInProgress = false;
    },
    async fetchInitialData() {
      this.isComponentLoading = true;
      // await Promise.all([
      //   this.$store.dispatch(
      //     CostCodeCategoryActions.FETCH_ORGANIZATION_COST_CODE_CATEGORIES,
      //     { organizationId: this.activeOrganization.id },
      //   ),
      // ]);
      this.setInitialFormState();
      this.isComponentLoading = false;
    },
    setInitialFormState() {
      this.createCostCodeForm = cloneDeep(createCostCodeForm);
      if (this.costCodeId) {
        const { code, name /* costCodeCategoryId */ } = this.activeCostCode;

        this.createCostCodeForm.fields.code.value = code;
        this.createCostCodeForm.fields.name.value = name;
        // this.selectedCostCodeCategoryId = costCodeCategoryId || null;
        // if (this.selectedCostCodeCategoryId) {
        //   this.createCostCodeForm.fields.costCodeCategory.value = this.costCodeCategories[this.selectedCostCodeCategoryId].name;
        // }
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
