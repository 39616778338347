<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <CreateOrEditVendorModal
        :isOpen="isAddVendorModalOpen"
        :initialVendorName="editCostForm.fields.vendor.value"
        @close="handleToggleAddVendorModal"
        @submitSuccess="handleCreateVendorSuccess"
      />
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ activeCost.name }}</h4>
        </div>
      </section>

      <section class="app-page__section">
        <CostConfirmDeleteModal :costId="costIdConfirmingDelete" :projectId="projectId" @onCostDeleteModalClosed="handleCloseDeleteModal" />
        <div>
          <form @submit.prevent="handleSubmitEditCost">
            <BField v-if="submissionError && submissionError.message">
              <BMessage type="is-danger">
                {{ submissionError.message }}
              </BMessage>
            </BField>
            <h5 class="title is-5 has-text-grey">
              {{ $t('common.terms.details') }}
            </h5>
            <div class="columns is-mobile is-multiline">
              <div
                :class="[
                  'field column is-12-mobile is-8-tablet',
                  { 'required': editCostForm.fields.name.required },
                ]"
              >
                <label class="label">{{ $t(editCostForm.fields.name.label) }}</label>
                <div :class="['control', { 'has-icons-left': editCostForm.fields.name.icon }]">
                  <input
                    :class="['input', 'input--icon-dark', { 'is-danger':  editCostForm.formErrors[$t(editCostForm.fields.name.name)] }]"
                    v-model="editCostForm.fields.name.value"
                    :disabled="editCostForm.isFormSubmissionInProgress"
                    @input="handleTouchForm"
                    :ref="editCostForm.fields.name.ref"
                  />
                  <span v-if="editCostForm.fields.name.icon" class="icon is-left">
                    {{ editCostForm.fields.name.icon }}
                  </span>
                </div>
                <p v-if="editCostForm.formErrors[$t(editCostForm.fields.name.name)]" class="help is-danger">
                  {{ editCostForm.formErrors[$t(editCostForm.fields.name.name)] }}
                </p>
              </div>
              <div
                :class="[
                  'field column is-12-mobile is-4-tablet',
                  { 'required': editCostForm.fields.costType.required },
                ]"
              >
                <label class="label">{{ $t(editCostForm.fields.costType.label) }}</label>
                <BSelect
                  v-model="editCostForm.fields.costType.value"
                  @input="handleTouchForm"
                  expanded
                >
                  <option v-for="costType in costTypes" :key="costType.id" :value="costType">
                    {{ $t(`common.costTypes.${costType.name}`) }}
                  </option>
                </BSelect>
                <p v-if="editCostForm.formErrors[$t(editCostForm.fields.costType.name)]" class="help is-danger">
                  {{ editCostForm.formErrors[$t(editCostForm.fields.costType.name)] }}
                </p>
              </div>
              <div
                :class="[
                  'field column is-6-mobile is-6-tablet',
                  { 'required': editCostForm.fields.startDate.required },
                ]"
              >
                <label class="label">{{ $t(editCostForm.fields.startDate.label) }}</label>
                <BDatepicker
                  v-model="editCostForm.fields.startDate.value"
                  :locale="$i18n.locale"
                  icon="calendar-today"
                  trapFocus
                  appendToBody
                  @input="handleTouchForm"
                />
                <p v-if="editCostForm.formErrors[$t(editCostForm.fields.startDate.name)]" class="help is-danger">
                  {{ editCostForm.formErrors[$t(editCostForm.fields.startDate.name)] }}
                </p>
              </div>
              <div
                :class="[
                  'field column is-6-mobile is-6-tablet',
                  { 'required': editCostForm.fields.endDate.required },
                ]"
              >
                <label class="label">{{ $t(editCostForm.fields.endDate.label) }}</label>
                <BDatepicker
                  v-model="editCostForm.fields.endDate.value"
                  :locale="$i18n.locale"
                  icon="calendar-today"
                  trapFocus
                  appendToBody
                  @input="handleTouchForm"
                />
                <p v-if="editCostForm.formErrors[$t(editCostForm.fields.endDate.name)]" class="help is-danger">
                  {{ editCostForm.formErrors[$t(editCostForm.fields.endDate.name)] }}
                </p>
              </div>
              <div
                :class="[
                  'field column is-12-mobile is-6-tablet',
                  { 'required': editCostForm.fields.vendor.required },
                ]"
              >
                <label class="label">{{ $t(editCostForm.fields.vendor.label) }}</label>
                <BAutocomplete
                  class="disable-on-select"
                  :data="filteredVendors"
                  v-model="editCostForm.fields.vendor.value"
                  :placeholder="$t(editCostForm.fields.vendor.placeholder)"
                  field="name"
                  :disabled="editCostForm.isFormSubmissionInProgress || selectedVendorId"
                  openOnFocus
                  :maxHeight="200"
                  clearable
                  :selectOnClickOutside="false"
                  @select="handleSelectVendor"
                  @blur="handleVendorFieldBlur"
                  @input="handleTouchForm"
                >
                  <template slot="footer">
                    <div @click="handleToggleAddVendorModal" type="is-info" outlined size="is-small">
                      <span class="autocomplete__footer__cta">
                        <BIcon icon="plus-circle-outline" customSize="mdi-18px" />
                        {{
                          (editCostForm.fields.vendor.value && !selectedVendorId)
                            ? $t('createCostView.ctas.createVendor', { vendorName: editCostForm.fields.vendor.value })
                            : $t('common.ctas.createVendor')
                        }}
                      </span>
                    </div>
                  </template>
                  <template v-if="filteredVendors.length === 0" slot="empty">
                    {{ $t('common.emptyStates.noResults') }}
                  </template>
                </BAutocomplete>
                <p v-if="editCostForm.formErrors[$t(editCostForm.fields.vendor.name)]" class="help is-danger">
                  {{ editCostForm.formErrors[$t(editCostForm.fields.vendor.name)] }}
                </p>
              </div>
              <div
                :class="[
                  'field column is-6-mobile is-6-tablet',
                  { 'required': editCostForm.fields.billingType.required },
                ]"
              >
                <label class="label">{{ $t(editCostForm.fields.billingType.label) }}</label>
                <div class="is-block">
                  <BRadio
                    v-for="billingType in billingTypes"
                    :key="billingType.label"
                    :native-value="billingType.value"
                    v-model="editCostForm.fields.billingType.value"
                    :disabled="editCostForm.isFormSubmissionInProgress || editCostForm.fields.billingType.disabled"
                    @input="handleTouchForm"
                  >
                    {{ billingType.label }}
                    <!-- <BTooltip :label="billingType.info" type="is-white" multilined appendToBody>
                      <BIcon icon="information-outline" size="is-small" type="is-dark" />
                    </BTooltip> -->
                  </BRadio>
                </div>
                <p v-if="editCostForm.formErrors[$t(editCostForm.fields.billingType.name)]" class="help is-danger">
                  {{ editCostForm.formErrors[$t(editCostForm.fields.billingType.name)] }}
                </p>
              </div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <BButton
                type="is-danger"
                iconLeft="delete"
                :disabled="editCostForm.isFormSubmissionInProgress"
                :loading="editCostForm.isFormSubmissionInProgress"
                @click="handleClickDeleteCost"
                @keyup.enter="handleClickDeleteCost"
                outlined
              >
                {{ $t('common.ctas.delete') }} {{ $t('common.terms.cost') }}
              </BButton>
              <BButton
                type="is-primary"
                icon-left="content-save"
                nativeType="submit"
                :loading="editCostForm.isFormSubmissionInProgress"
                :disabled="editCostForm.isFormSubmissionInProgress || !editCostForm.isTouched"
              >
                {{ $t('common.ctas.saveChanges') }}
              </BButton>
            </div>
          </form>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import * as Types from '@/constants/Types';
import * as CostActions from '@/store/actions/Cost.actions';
import * as VendorActions from '@/store/actions/Vendor.actions';
import * as ValidationService from '@/services/Validation.service';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import editCostForm from '@/forms/editCost.form';
import CreateOrEditVendorModal from '@/components/CreateOrEditVendorModal.vue';
import CostConfirmDeleteModal from '@/components/CostConfirmDeleteModal.vue';

export default {
  name: 'EditCostView',

  components: {
    CreateOrEditVendorModal,
    CostConfirmDeleteModal,
  },

  data() {
    return {
      isPageLoading: true,
      archivingError: null,
      submissionError: null,
      editCostForm: cloneDeep(editCostForm),
      formErrors: {},
      selectedVendorId: null,
      isAddVendorModalOpen: false,
      costIdConfirmingDelete: null,
      projectId: null,
    };
  },

  computed: {
    ...mapState({
      costs: state => state.cost.costs,
      projects: state => state.project.projects,
      costTypes: state => state.costType.costTypes,
      vendors: state => state.vendor.vendors,
    }),
    ...mapGetters([
      'activeOrganization',
      'isProjectArchived',
      'getBillingTypeByName',
    ]),
    billingTypes() {
      return [
        {
          label: this.$t('common.terms.billingTypes.advance'),
          info: this.$t('billingTypeInfo.advance'),
          value: this.getBillingTypeByName(Types.billingTypes.advance).id,
        },
        {
          label: this.$t('common.terms.billingTypes.lumpSum'),
          info: this.$t('billingTypeInfo.lumpSum'),
          value: this.getBillingTypeByName(Types.billingTypes.lumpSum).id,
        },
      ];
    },
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    activeCostId() {
      return this.$route.params.costId;
    },
    activeCost() {
      return this.costs[this.activeCostId];
    },
    sortedVendors() {
      return !isObjectEmpty(this.vendors) ? Object.values(this.vendors).sort((a, b) => (a.name > b.name ? 1 : -1)) : [];
    },
    filteredVendors() {
      return this.editCostForm.fields.vendor.value
        ? this.sortedVendors
          .filter(vendor => vendor?.name?.toLowerCase().includes(this.editCostForm.fields.vendor.value.toLowerCase()))
        : [...this.sortedVendors];
    },
    updatedCost() {
      const {
        editCostForm: {
          fields: {
            name,
            costType,
            startDate,
            endDate,
          },
        },
      } = this;

      return {
        name: name.value,
        costTypeId: costType.value.id,
        vendorId: this.selectedVendorId,
        startDate: startDate.value,
        endDate: endDate.value,
      };
    },
  },

  mounted() {
    this.fetchInitialData();
  },

  methods: {
    async handleSubmitEditCost() {
      this.formErrors = {};
      this.submissionError = null;
      this.formErrors = ValidationService.runFormValidation({ ...this.editCostForm }, { i18n: true });
      if (!isObjectEmpty(this.formErrors)) return;

      this.editCostForm.isFormSubmissionInProgress = true;
      try {
        await this.$store.dispatch(
          CostActions.UPDATE_COST_BY_ID,
          { costId: this.activeCostId, updatedCost: { ...this.updatedCost } },
        );

        NotificationService.showNotification(NotificationTypes.UPDATE_COST.SUCCESS);
        this.$router.push(`/projects/${this.activeProjectId}/costs/${this.activeCostId}/details`);
      } catch (err) {
        this.submissionError = err;
        console.error(err) // eslint-disable-line
        NotificationService.showNotification(NotificationTypes.UPDATE_PROJECT.ERROR);
      }
      this.editCostForm.isFormSubmissionInProgress = false;
    },
    handleTouchForm() {
      this.editCostForm.isTouched = true;
    },
    // @NOTE: this method is loosely coupled to the blur handler (unfortunately), due to potential race conditions.
    handleSelectVendor(vendor) {
      this.selectedVendorId = vendor ? vendor.id : null;
      if (this.selectedVendorId) {
        this.editCostForm.fields.vendor.value = this.vendors[this.selectedVendorId].name;
      }
    },
    handleVendorFieldBlur() {
      // Wrapped in a setTimeout to avoid race condition of events between @select and @blur.
      setTimeout(() => {
        if (!this.selectedVendorId) {
          this.editCostForm.fields.vendor.value = '';
        }
      }, 200);
    },
    handleToggleAddVendorModal() {
      this.isAddVendorModalOpen = !this.isAddVendorModalOpen;
    },
    handleCreateVendorSuccess(vendorId) {
      this.selectedVendorId = vendorId;
      this.editCostForm.fields.vendor.value = this.vendors[this.selectedVendorId].name;
      this.handleToggleAddVendorModal();
    },
    setInitialFormState() {
      const {
        name,
        startDate,
        endDate,
        costTypeId,
        vendorId,
        billingTypeId,
      } = this.activeCost;

      this.editCostForm.fields.name.value = name || '';
      this.editCostForm.fields.startDate.value = startDate ? new Date(startDate) : null;
      this.editCostForm.fields.endDate.value = endDate ? new Date(endDate) : null;
      this.editCostForm.fields.costType.value = this.costTypes[costTypeId];
      this.editCostForm.fields.billingType.value = billingTypeId;

      if (vendorId) {
        this.selectedVendorId = vendorId;
        this.editCostForm.fields.vendor.value = this.vendors[vendorId].name;
      }
    },
    async fetchInitialData() {
      this.isPageLoading = true;
      try {
        await Promise.all([
          this.$store.dispatch(CostActions.FETCH_COST_BY_ID, { costId: this.activeCostId }),
          this.$store.dispatch(VendorActions.FETCH_ORGANIZATION_VENDORS, { organizationId: this.activeOrganization.id }),
        ]);

        this.setInitialFormState();
        this.projectId = this.costs[this.activeCostId].projectId;
      } catch (err) {
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }

      this.isPageLoading = false;
    },
    handleCloseDeleteModal() {
      this.costIdConfirmingDelete = null;
    },
    handleClickDeleteCost() {
      this.costIdConfirmingDelete = this.activeCostId;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
