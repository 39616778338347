<template>
  <BTable
    :data="activityCategoriesData"
    hoverable
    striped
    class="card"
    :mobileCards="false"
    :defaultSortDirection="'asc'"
    :defaultSort="['name']"
  >
    <BModal
      :active="!!activityCategoryIdEditing"
      @close="handleCloseEditModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="activityCategoryIdEditing" class="modal-card-title">{{ $t('common.ctas.edit')}} {{ activityCategories[activityCategoryIdEditing].name }}</p>
          <button
            type="button"
            class="delete"
            @click="handleCloseEditModal"
          />
        </header>
        <div class="has-background-white u-p-a">
          <CreateOrEditActivityCategoryCard
            :activityCategoryId="activityCategoryIdEditing"
            isDark
            isInset
            inModal
            areFieldsStacked
            @submitSuccess="handleActivityCategoryUpdateSuccess"
          />
        </div>
      </div>
    </BModal>
    <BModal
      :active="!!activityCategoryIdConfirmingDelete"
      @close="handleCloseDeleteModal"
      hasModalCard
    >
      <div v-if="!isFetchingActivityCategoryDetails" class="modal-card">
        <header class="modal-card-head">
          <p v-if="activityCategoryIdConfirmingDelete" class="modal-card-title">
            {{ $t('common.ctas.delete') }}
            {{ $t('common.terms.activityCategory') }}
          </p>
          <button
            type="button"
            class="delete"
            @click="handleCloseDeleteModal"
          />
        </header>
        <section v-if="activityCategories[activityCategoryIdConfirmingDelete]" class="modal-card-body has-background-white u-p-a has-text-centered">
          <div v-if="canDeleteActivityCategory">
            <p>
              <BIcon icon="alert" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('activityCategoriesTable.deleteActivityCategoryModal.warning') }}</h5>
            <p v-if="activityCategories[activityCategoryIdConfirmingDelete]">
              {{ activityCategories[activityCategoryIdConfirmingDelete].name }}
            </p>
          </div>
          <div v-else>
            <p>
              <BIcon icon="alert-octagon" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('activityCategoriesTable.deleteActivityCategoryModal.notAllowed') }}</h5>
            <ul>
              <li
                v-for="activityId in activityCategories[activityCategoryIdConfirmingDelete].activities"
                :key="activityId.key"
                class="has-text-weight-bold u-p-b-small"
              >
                {{ activities[activityId].name }}
              </li>
            </ul>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleCloseDeleteModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-danger"
              @click="handleConfirmDeleteActivityCategory(activityCategoryIdConfirmingDelete)"
              :loading="activityCategoriesDeleting.includes(activityCategoryIdConfirmingDelete)"
              :disabled="!canDeleteActivityCategory"
            >
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.activityCategory') }}
            </BButton>
          </div>
        </footer>
      </div>
    </BModal>
    <BTableColumn
      field="name"
      :label="$t('activityCategoriesTable.columns.name')"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      {{ props.row.name }}
    </BTableColumn>
    <BTableColumn
      field="createdAt"
      :label="$t('activityCategoriesTable.columns.createdAt')"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale, {}) }}
    </BTableColumn>
    <BTableColumn
      v-if="isLoggedInUserAtLeastProjectManager"
      cell-class="vertical-align-middle"
      width="140"
      v-slot="props"
    >
      <div class="buttons" style="justify-content: center;">
        <BButton
          type="is-primary is-light"
          @click="() => handleClickEditActivityCategory(props.row.id)"
        >
          <BIcon icon="pencil" size="mdi-18px" />
        </BButton>
        <BButton
          type="is-primary is-light"
          @click="() => handleClickDeleteActivityCategory(props.row.id)"
          :loading="isFetchingActivityCategoryDetails && activityCategoryIdConfirmingDelete === props.row.id"
        >
          <BIcon icon="delete" size="mdi-18px" />
        </BButton>
      </div>
    </BTableColumn>
  </BTable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as ActivityCategoryActions from '@/store/actions/ActivityCategory.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import CreateOrEditActivityCategoryCard from '@/components/CreateOrEditActivityCategoryCard.vue';

export default {
  name: 'ActivityCategoriesTable',

  components: {
    CreateOrEditActivityCategoryCard,
  },

  data() {
    return {
      activityCategoriesDeleting: [],
      activityCategoryIdEditing: null,
      activityCategoryIdConfirmingDelete: null,
      isFetchingActivityCategoryDetails: false,
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      activityCategories: state => state.activityCategory.activityCategories,
    }),
    ...mapGetters([
      'isLoggedInUserAtLeastProjectManager',
    ]),
    activityCategoriesData() {
      return Object.values(this.activityCategories);
    },
    canDeleteActivityCategory() {
      return this.activityCategoryIdConfirmingDelete && this.activityCategories[this.activityCategoryIdConfirmingDelete]?.activities.length === 0;
    },
  },

  methods: {
    handleClickEditActivityCategory(activityCategoryId) {
      this.activityCategoryIdEditing = activityCategoryId;
    },
    handleCloseEditModal() {
      this.activityCategoryIdEditing = null;
    },
    handleCloseDeleteModal() {
      this.activityCategoryIdConfirmingDelete = null;
    },
    async handleClickDeleteActivityCategory(activityCategoryId) {
      this.isFetchingActivityCategoryDetails = true;
      try {
        await this.$store.dispatch(ActivityCategoryActions.FETCH_ACTIVITY_CATEGORY_BY_ID, { id: activityCategoryId });
        this.activityCategoryIdConfirmingDelete = activityCategoryId;
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isFetchingActivityCategoryDetails = false;
    },
    async handleConfirmDeleteActivityCategory(activityCategoryId) {
      this.activityCategoriesDeleting = [...this.activityCategoriesDeleting, activityCategoryId];
      try {
        await this.$store.dispatch(ActivityCategoryActions.DELETE_ACTIVITY_CATEGORY_BY_ID, {
          id: activityCategoryId,
        });
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY_CATEGORY.SUCCESS);
        this.handleCloseDeleteModal();
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY_CATEGORY.ERROR);
      }
      this.activityCategoriesDeleting = this.activityCategoriesDeleting.filter(a => a !== activityCategoryId);
    },
    handleActivityCategoryUpdateSuccess() {
      this.handleCloseEditModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_ACTIVITY_CATEGORY.SUCCESS);
    },
  },
};
</script>
