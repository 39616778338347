<template>
  <div class="app-page__container">
    <div v-if="!isLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.users')}}&nbsp;({{ Object.keys(users).length || 0 }})</h4>
        </div>
        <div v-if="isLoggedInUserAtLeastAdmin" class="app-page__header__action">
          <BButton
            type="is-primary"
            icon-left="plus-thick"
            outlined
            @click.prevent="handleClickCreateUser"
          >
            {{ $t('common.ctas.inviteUser')}}
          </BButton>
        </div>
      </section>

      <div class="app-page__section">
        <div class="card">
          <div class="card-content">
            <CardRow
              v-for="user in Object.values(users)"
              :key="user.id"
              isClickable
              @click.prevent="handleClickUserProfile(user.id)"
              @keyup.enter="handleClickUserProfile(user.id)"
            >
              <template v-slot:content-left>
                <UserAvatar :user="user" class="u-m-r-small" size="small" />
                <div>
                  <span>
                    <UserDisplayName :activeUserId="user.id" size="medium" is-inline :is-clickable="false" />
                    <small v-if="!user.isActive" class="has-text-danger is-italic">
                      {{ $t('common.terms.user') }} {{ $t('common.terms.archived') }}
                    </small>
                  </span>
                  <p class="subtitle is-size-6 is-size-7-mobile has-text-grey">
                    {{ user.email }}
                  </p>
                </div>
              </template>
              <template v-if="isUserInvitationPending(user.id)">
                <small class="is-italic has-text-warning">{{ $t('common.terms.invitationPending') }}</small>
              </template>
              <template v-if="isLoggedInUserAtLeastAdmin && isUserInvitationPending(user.id)" v-slot:content-right>
                <BButton
                  size="is-small"
                  icon-left="email"
                  type="is-primary is-light"
                  class="u-m-r-xsmall"
                  @click.prevent="event => handleResendInvitation(event, user.id)"
                  @keyup.enter.prevent="event => handleResendInvitation(event, user.id)"
                  :loading="userIdsResendingInvitationInProgress.includes(user.id)"
                  :disabled="
                    userIdsResendingInvitationInProgress.includes(user.id)
                    || invitationIdsDeletingInProgress.includes(user.id)
                  "
                >
                  {{ $t('common.ctas.resendInvitation') }}
                </BButton>
              </template>
            </CardRow>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as UserActions from '@/store/actions/User.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { userStatusTypes } from '@/constants/Types';
import { shapeUserDisplayName } from '@/helpers/userHelpers';
import UserDisplayName from '@/components/UserDisplayName.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import CardRow from '@/components/CardRow.vue';

export default {
  name: 'UsersView',

  components: {
    UserDisplayName,
    UserAvatar,
    CardRow,
  },

  data() {
    return {
      isLoading: true,
      userIdsResendingInvitationInProgress: [],
      invitationIdsDeletingInProgress: [],
    };
  },

  computed: {
    ...mapState({
      users: state => state.user.users,
      userStatuses: state => state.userStatus.userStatuses,
    }),
    ...mapGetters([
      'activeOrganization',
      'loggedInUser',
      'getUserStatusByName',
      'isLoggedInUserAtLeastAdmin',
    ]),
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    handleClickCreateUser() {
      this.$router.push('/users/create');
    },
    handleClickUserProfile(userId) {
      if (!this.users[userId].isActive) return;

      this.$router.push(`/users/${userId}/profile`);
    },
    async fetchInitialData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(UserActions.FETCH_ORGANIZATION_USERS, { organizationId: this.activeOrganization.id });
        this.isLoading = false;
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
      }
    },
    async handleResendInvitation(event, userId) {
      event.stopPropagation();

      try {
        this.userIdsResendingInvitationInProgress = [...this.userIdsResendingInvitationInProgress, userId];

        this.$store.dispatch(UserActions.RESEND_USER_INVITATION, { userId });

        NotificationService.showNotification(NotificationTypes.RESEND_INVITATION(shapeUserDisplayName(this.users[userId])).SUCCESS);
      } catch (err) {
        NotificationService.showNotification(NotificationTypes.RESEND_INVITATION(shapeUserDisplayName(this.users[userId])).ERROR);
      }

      this.userIdsResendingInvitationInProgress = this.userIdsResendingInvitationInProgress.filter(id => id !== userId);
    },
    isUserInvitationPending(userId) {
      const user = this.users[userId];

      return user.statusId === this.getUserStatusByName(userStatusTypes.invitationPending)?.id;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
