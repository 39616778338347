import { schema } from 'normalizr';

const activity = new schema.Entity('activities');
const auditLog = new schema.Entity('auditLogs');
const bill = new schema.Entity('bills');
const billItem = new schema.Entity('billItems');
const billItemType = new schema.Entity('billItemTypes');
const billingType = new schema.Entity('billingTypes');
const billStatus = new schema.Entity('billStatuses');
const budget = new schema.Entity('budgets');
const client = new schema.Entity('clients');
const comment = new schema.Entity('comments');
const costCode = new schema.Entity('costCodes');
const costCodeCategory = new schema.Entity('costCodeCategories');
const cost = new schema.Entity('costs');
const costType = new schema.Entity('costTypes');
const document = new schema.Entity('documents');
const invitationStatus = new schema.Entity('invitationStatus');
const invitation = new schema.Entity('invitations');
const organization = new schema.Entity('organizations');
const project = new schema.Entity('projects');
const projectStatus = new schema.Entity('projectStatuses');
const role = new schema.Entity('roles');
const tax = new schema.Entity('taxes');
const user = new schema.Entity('users');
const userStatus = new schema.Entity('userStatuses');
const vendor = new schema.Entity('vendors');
const activityCategory = new schema.Entity('activityCategories');
const subscription = new schema.Entity('subscriptions');
const subscriptionType = new schema.Entity('subscriptionTypes');
const rule = new schema.Entity('rules');
const changeOrder = new schema.Entity('changeOrders');

const userSchema = {
  organization,
  projects: [project],
  invitations: [invitation],
  createdTaxes: [tax],
  status: userStatus,
  roles: [role],
  createdActivities: [activity],
  createdBillItems: [billItem],
  createdBills: [bill],
  // createdClients: [client],
  createdCosts: [cost],
  createdInvitations: [invitation],
  createdOrganizations: [organization],
  createdProjectStatuses: [projectStatus],
  createdProjects: [project],
  createdVendors: [vendor],
  createdClients: [client],
  createdCostCodes: [costCode],
  createdCostCodeCategories: [costCodeCategory],
  createdSubscriptions: [subscription],
  createdRules: [rule],
  createdChangeOrders: [changeOrder],
  auditLogs: [auditLog],
  rules: [rule],
};

const organizationSchema = {
  creator: user,
  users: [user],
  taxes: [tax],
  // clients: [client],
  invitations: [invitation],
  projectStatuses: [projectStatus],
  vendors: [vendor],
  clients: [client],
  costCodes: [costCode],
  costCodeCategories: [costCodeCategory],
  auditLogs: [auditLog],
  roles: [role],
  subscriptions: [subscription],
  rules: [rule],
};

const projectStatusSchema = {
  creator: user,
  organization,
  projects: [project],
};

const projectSchema = {
  organization,
  creator: user,
  budget,
  status: projectStatus,
  users: [user],
  costs: [cost],
  client,
  budgets: [budget],
  activeBudget: budget,
  changeOrders: [changeOrder],
};

const costSchema = {
  creator: user,
  costType,
  vendor,
  project,
  activities: [activity],
  bills: [bill],
  billingType,
};

const vendorSchema = {
  creator: user,
  organization,
  costs: [cost],
};

const activitySchema = {
  creator: user,
  cost,
  budget,
  costCode,
  costCodeCategory,
  activityCategory,
  changeOrder,
  billItems: [billItem],
};

const activityCategorySchema = {
  creator: user,
  organization,
  activities: [activity],
};

const billSchema = {
  creator: user,
  cost,
  billItems: [billItem],
  status: billStatus,
  tax,
};

const billStatusSchema = {
  bills: [bill],
};

const billItemSchema = {
  creator: user,
  bill,
  activity,
  billItemType,
};

const billingTypeSchema = {
  costs: [cost],
};

const taxSchema = {
  organization,
  creator: user,
};

const clientSchema = {
  organization,
  creator: user,
  projects: [project],
};

const costCodeSchema = {
  organization,
  creator: user,
  activities: [activity],
  costCodeCategory,
};

const costCodeCategorySchema = {
  organization,
  creator: user,
  costCodes: [costCode],
};

const budgetSchema = {
  creator: user,
  activities: [activity],
  project,
  activeOnProject: project,
};

const auditLogSchema = {
  actor: user,
  organization,
};

const roleSchema = {
  users: [user],
  organization,
  creator: user,
};

const commentSchema = {
  commenter: user,
  organization,
};

const documentSchema = {
  creator: user,
  organization,
};

const subscriptionSchema = {
  creator: user,
  organization,
  subscriptionType,
};

const subscriptionTypeSchema = {
  subscriptions: [subscription],
};

const ruleSchema = {
  organization,
  creator: user,
  users: [user],
};

const changeOrderSchema = {
  project,
  creator: user,
  activities: [activity],
};

user.define(userSchema);
organization.define(organizationSchema);
project.define(projectSchema);
projectStatus.define(projectStatusSchema);
budget.define(budgetSchema);
cost.define(costSchema);
vendor.define(vendorSchema);
activity.define(activitySchema);
bill.define(billSchema);
billItem.define(billItemSchema);
billStatus.define(billStatusSchema);
billingType.define(billingTypeSchema);
tax.define(taxSchema);
client.define(clientSchema);
costCode.define(costCodeSchema);
costCodeCategory.define(costCodeCategorySchema);
budget.define(budgetSchema);
auditLog.define(auditLogSchema);
role.define(roleSchema);
comment.define(commentSchema);
document.define(documentSchema);
activityCategory.define(activityCategorySchema);
subscription.define(subscriptionSchema);
subscriptionType.define(subscriptionTypeSchema);
rule.define(ruleSchema);
changeOrder.define(changeOrderSchema);

export {
  user,
  organization,
  project,
  projectStatus,
  cost,
  costType,
  userStatus,
  invitationStatus,
  vendor,
  activity,
  activityCategory,
  bill,
  billItem,
  billItemType,
  billStatus,
  billingType,
  tax,
  client,
  costCode,
  costCodeCategory,
  budget,
  auditLog,
  role,
  comment,
  document,
  subscription,
  subscriptionType,
  rule,
  changeOrder,
};
