<template>
  <div>
    <div class="filters__wrapper">
      <p class="has-text-grey has-text-weight-bold">
        <BIcon icon="filter" size="is-small" />
        {{ $t('common.terms.filters') }}
      </p>
      <div class="column">
        <BDropdown
          multiple
          v-model="selectedCostTypes"
        >
          <BButton slot="trigger" icon-right="chevron-down">
            {{ $t('common.terms.costType') }}
          </BButton>
          <BDropdownItem v-for="costType in costTypes" :key="costType.id" :value="costType.id" class="filters__dropdown-item">
            <BCheckbox :value="selectedCostTypes.includes(costType.id)" @click.native.prevent="() => {}">
              <CostTypeTag :costTypeId="costType.id" />
            </BCheckbox>
          </BDropdownItem>
          <hr class="dropdown-divider">
          <BDropdownItem @click="handleSelectAllCostTypes">
            <span class="has-text-weight-bold">{{ $t('common.ctas.selectAll') }}</span>
          </BDropdownItem>
        </BDropdown>
      </div>
    </div>
    <BTable
      :data="filteredCostsTableData"
      hoverable
      @click="handleClickRow"
      @keyup.enter="handleClickRow"
      :class="{ card:true, 'is-empty-table':filteredCostsTableData.length == 0 }"
      :mobileCards="false"
      detailed
      :showDetailIcon="false"
      :rowClass="handleRowClass"
      :defaultSortDirection="'asc'"
      :defaultSort="['name', 'asc']"
    >
      <CostConfirmDeleteModal :costId="costIdConfirmingDelete" :projectId="projectId" @onCostDeleteModalClosed="handleCloseDeleteModal" />
      <template slot="empty">
        <div class="has-text-grey u-p-a has-text-centered">
          {{ $t('projectDetailsView.costsEmptyState') }}
        </div>
      </template>
      <BTableColumn
        field="name"
        :label="$t('common.terms.name')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        :subheading="$t('common.terms.total')"
        sortable
        v-slot="props"
        :width="350"
      >
        <div>
          <p class="has-text-weight-bold is-size-5">{{ props.row.name }}</p>
          <p class="is-italic">
            <span v-if="props.row.vendor.name">
              <BIcon icon="account-hard-hat" size="is-small" />
              {{ props.row.vendor.name }}
            </span>
            <span v-else class="has-text-grey">{{ $t('common.terms.noVendor') }}</span>
          </p>
          <div class="u-m-t-small"><CostTypeTag :costTypeId="props.row.costTypeId" /></div>
        </div>
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.contracted')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        :subheading="displayCurrency(costContractedTotal, { prefix: '$', multiplier: 100 })"
        v-slot="props"
        sortable
        numeric
      >
        {{ displayCurrency(getCostTotalActivityBudget(props.row.id) || 0, { prefix: '$', multiplier: 100 }) }}
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.completed')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        :subheading="displayCurrency(costCompletedTotal, { prefix: '$', multiplier: 100 })"
        v-slot="props"
        sortable
        numeric
      >
        {{ displayCurrency(getCostTotalAdvanceAmount(props.row.id) || 0, { prefix: '$', multiplier: 100 }) }}
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.remainingBalance')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        :subheading="displayCurrency(costRemainingBalanceTotal, { prefix: '$', multiplier: 100 })"
        v-slot="props"
        sortable
        numeric
      >
        {{ displayCurrency(getCostRemainingBalance(props.row.id) || 0, { prefix: '$', multiplier: 100 }) }}
      </BTableColumn>
      <BTableColumn
        field="progress"
        :label="$t('common.terms.progress')"
        cell-class="vertical-align-middle"
        width="120"
        centered
      >
      <template #subheading>
        <vc-donut
          :sections="[{
            value: (costProgressTotal * 100),
            color: '#5e72e4',
          }]"
          :size="60"
          :thickness="20"
          :auto-adjust-text-size="false"
        >
          <p class="has-text-weight-bold">
            {{ Math.round(convertFractionToPercent(costProgressTotal)) }}%
          </p>
        </vc-donut>
      </template>
      <template v-slot="props">
        <vc-donut
          :sections="[{
            value: (getCostTotalAdvancePercent(props.row.id) * 100) || 0,
            color: '#5e72e4',
          }]"
          :size="60"
          :thickness="20"
          :auto-adjust-text-size="false"
        >
          <p class="has-text-weight-bold">
            {{ Math.round(convertFractionToPercent(getCostTotalAdvancePercent(props.row.id))) }}%
          </p>
        </vc-donut>
      </template>
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.startDate')"
        subheading="--"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        v-slot="props"
        sortable
        field="startDate"
        width="140"
      >
        {{ props.row.startDate ? new Date(props.row.startDate).toLocaleDateString($i18n.locale, { }) : 'N/A' }}
      </BTableColumn>
      <BTableColumn width="120" v-slot="props" cell-class="vertical-align-middle">
        <div class="buttons" style="justify-content: center;">
          <BTooltip :label="$t('common.terms.details')" position="is-top" :triggers="['hover']" type="is-dark" class="u-m-r-small">
            <BButton
              type="is-primary is-light"
              @click="(e) => { e.stopPropagation(); props.toggleDetails(props.row) }"
              @keyup.enter="props.toggleDetails(props.row)"
            >
              <BIcon icon="dots-vertical" />
            </BButton>
          </BTooltip>
          <BTooltip
          v-if="isLoggedInUserAtLeastProjectManager"
          :label="$t('common.ctas.delete')"
          position="is-top"
          :triggers="['hover']"
          type="is-dark"
          size="is-small"
          style="max-width: 100px;"
          appendToBody
        >
          <BButton
            type="is-primary is-light"
            @click="(e) => { e.stopPropagation(); handleClickDeleteCost(props.row.id)}"
            @keyup.enter="handleClickDeleteCost(props.row.id)"
          >
            <BIcon icon="delete" />
          </BButton>
        </BTooltip>
        </div>
      </BTableColumn>
      <template slot="detail" slot-scope="props">
        <ProjectCostsTableRowDetails :costId="props.row.id" />
      </template>
    </BTable>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import CostTypeTag from '@/components/CostTypeTag.vue';
import ProjectCostsTableRowDetails from '@/components/ProjectCostsTableRowDetails.vue';
import CostConfirmDeleteModal from '@/components/CostConfirmDeleteModal.vue';

export default {
  name: 'ProjectCostsTable',

  components: {
    CostTypeTag,
    ProjectCostsTableRowDetails,
    CostConfirmDeleteModal,
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedCostTypes: [],
      costsDeleting: [],
      costIdConfirmingDelete: null,
    };
  },

  computed: {
    ...mapState({
      costs: state => state.cost.costs,
      bills: state => state.bill.bills,
      activities: state => state.activity.activities,
      projects: state => state.project.projects,
      costTypes: state => state.costType.costTypes,
      vendors: state => state.vendor.vendors,
      users: state => state.user.users,
    }),
    ...mapGetters([
      'getCostsByProjectId',
      'getCostTotalAdvanceAmount',
      'getCostTotalAdvancePercent',
      'getCostTotalActivityBudget',
      'getCostTotalClosedAmount',
      'getCostRemainingBalance',
      'isLoggedInUserAtLeastProjectManager',
    ]),
    costsTableData() {
      return this.getCostsByProjectId(this.activeProjectId)
        .map(cost => ({ ...cost, costType: this.costTypes[cost.costTypeId], vendor: this.vendors[cost.vendorId] || {} }));
    },
    filteredCostsTableData() {
      return this.costsTableData.filter(cost => this.selectedCostTypes.includes(cost.costTypeId));
    },
    activeProjectId() {
      return this.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    costContractedTotal() {
      return this.filteredCostsTableData.reduce((total, cost) => total + (this.getCostTotalActivityBudget(cost.id) || 0), 0);
    },
    costCompletedTotal() {
      return this.filteredCostsTableData.reduce((total, cost) => total + (this.getCostTotalAdvanceAmount(cost.id) || 0), 0);
    },
    costRemainingBalanceTotal() {
      return this.filteredCostsTableData.reduce((total, cost) => total + (this.getCostRemainingBalance(cost.id) || 0), 0);
    },
    costProgressTotal() {
      let progressTotal = 0;
      if (this.costContractedTotal > 0) {
        progressTotal = this.costCompletedTotal / this.costContractedTotal;
      }
      return progressTotal;
    },
  },

  created() {
    this.handleSelectAllCostTypes();
  },

  methods: {
    displayCurrency,
    addSuffix,
    convertFractionToPercent,
    handleRowClass() {
      return 'has-cursor-pointer';
    },
    handleClickRow(row) {
      this.$router.push(`/projects/${this.activeProjectId}/costs/${row.id}/details`);
    },
    handleSelectAllCostTypes() {
      this.selectedCostTypes = Object.keys(this.costTypes);
    },
    getLatestCostBill(costId) {
      return Object.values(this.bills)
        .filter(bill => bill.costId === costId)
        .sort((billA, billB) => billB.number - billA.number)[0];
    },
    handleCloseDeleteModal() {
      this.costIdConfirmingDelete = null;
    },
    handleClickDeleteCost(costId) {
      this.costIdConfirmingDelete = costId;
    },
  },
};
</script>

<style scoped lang="scss">
.costs-table__advance-column__header .th-wrap {
  text-align: center !important;
}
</style>
