<template>
  <div class="card has-background-primary-light has-border-primary product-card u-m-b">
    <div class="card-content">
      <p class="u-m-b-small is-size-5">
        <span class="has-text-weight-bold">{{ $t(`subscriptionPlans.${planName}.name`) }}</span>
      </p>
      <p class="subtitle is-size-6 u-m-b-0">
       {{ $t(`subscriptionPlans.${planName}.description`) }}
      </p>
      <div class="content">
        <ul>
          <li
            v-for="feature in $t(`subscriptionPlans.${planName}.features`)"
            :key="feature.key"
          >
            {{ feature }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionPlanCard',

  props: {
    planName: {
      type: String,
      required: true,
    },
  },
};
</script>
