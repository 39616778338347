import store from '@/store';
import * as RoleActions from '@/store/actions/Role.actions';
import * as TypeActions from '@/store/actions/Type.actions';
import * as SubscriptionActions from '@/store/actions/Subscription.actions';

// eslint-disable-next-line import/prefer-default-export
export const fetchOrganizationRequiredData = organizationId => (
  Promise.all([
    store.dispatch(TypeActions.FETCH_ALL_TYPES),
    store.dispatch(RoleActions.FETCH_ORGANIZATION_ROLES, { organizationId }),
    store.dispatch(SubscriptionActions.FETCH_ORGANIZATION_SUBSCRIPTIONS, { organizationId }),
  ])
);
