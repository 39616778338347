<template>
  <aside
    :class="[
      'side-navigation',
      { 'side-navigation--is-active': isSideNavigationOpen }
    ]"
  >
    <div class="u-m-b-large">
      <div v-if="isSideNavigationOpen" class="side-navigation__menu-icon__container">
        <BIcon
          v-if="isSideNavigationOpen"
          icon="close"
          class="side-navigation__menu-icon"
          @click.native="handleToggleSideNavigation(false)"
        />
        &nbsp;
      </div>
      <div class="image is-inline-block side-navigation__logo__icon">
        <TheLogo />
      </div>
      <BDropdown
        class="is-block custom-dropdown"
        :mobile-modal="false"
        @active-change="handleToggleOrganizationDropdown"
      >
        <span slot="trigger">
          <div class="side-navigation__organization-name__container is-size-5 has-text-weight-bold is-5 u-m-b-0">
            <div class="side-navigation__organization-name__text">{{ activeOrganization.name }}</div>
            <BIcon
              :class="{
                'side-navigation__organization-name__icon': true,
                'side-navigation__organization-name__icon--active': isOrganizationDropdownActive,
              }"
              icon="chevron-down"
            />
            <span class="is-size-7 is-block">{{ $t('common.titles.organizationSettings') }}</span>
          </div>
        </span>
        <BDropdownItem @click="handleOrganizationDropdownClick('/users')">
          <BIcon icon="account" size="is-small" />&nbsp;{{ $t('common.titles.users') }}
        </BDropdownItem>
        <BDropdownItem @click="handleOrganizationDropdownClick('/clients')">
          <BIcon icon="briefcase-account" size="is-small" />&nbsp;{{ $t('common.titles.clients') }}
        </BDropdownItem>
        <BDropdownItem @click="handleOrganizationDropdownClick('/vendors')">
          <BIcon icon="account-hard-hat" size="is-small" />&nbsp;{{ $t('common.titles.vendors') }}
        </BDropdownItem>
        <BDropdownItem @click="handleOrganizationDropdownClick('/cost-codes')">
          <BIcon icon="tag" size="is-small" />&nbsp;{{ $t('common.titles.costCodes') }}
        </BDropdownItem>
        <BDropdownItem @click="handleOrganizationDropdownClick('/activity-categories')">
          <BIcon icon="folder-text-outline" size="is-small" />&nbsp;{{ $t('common.titles.activityCategories') }}
        </BDropdownItem>
        <BDropdownItem v-if="isLoggedInUserAtLeastAdmin" @click="handleOrganizationDropdownClick('/audit-logs')">
          <BIcon icon="database-search" size="is-small" />&nbsp;{{ $t('common.titles.auditLogs') }}
        </BDropdownItem>
        <!-- <BDropdownItem v-if="isLoggedInUserAtLeastOwner" @click="handleOrganizationDropdownClick('/rules')">
          <BIcon icon="database-search" size="is-small" />&nbsp;{{ $t('common.titles.rules') }}
        </BDropdownItem> -->
        <BDropdownItem v-if="isLoggedInUserAtLeastAdmin" @click="handleOrganizationDropdownClick('/organization-settings')">
          <BIcon icon="cog" size="is-small" />&nbsp;{{ $t('common.titles.organizationSettings') }}
        </BDropdownItem>
      </BDropdown>
    </div>
    <div v-for="menuLink in menuLinks" :key="menuLink.key" class="side-navigation__links">
      <router-link :to="menuLink.to" @click.native="handleToggleSideNavigation(false)">
        <p :class="[
            'side-navigation__links__item',
            'is-flex',
            { 'side-navigation__links__item--is-active': isTabActive(menuLink.activePath) },
          ]"
        >
          <BIcon :icon="menuLink.icon" class="u-m-r-small" />{{ $t(menuLink.label) }}
        </p>
      </router-link>
    </div>
    <div class="side-navigation__bottom">
      <div>
        <span
          aria-role="button"
          @click="handleClickProfile"
          @keyup.enter="handleClickProfile"
          class="button--reset is-small"
        >
          <BIcon icon="account" custom-size="mdi-18px" />&nbsp;{{ $t('common.titles.myProfile') }}
        </span>
      </div>
      <div>
        <LocaleSwitcher position="is-top-right" />
      </div>
      <div>
        <span
          aria-role="button"
          @click="handleLogOut"
          @keyup.enter="handleLogOut"
          class="button--reset is-small"
        >
          <BIcon icon="logout" custom-size="mdi-18px" />&nbsp;{{ $t('common.ctas.logOut') }}
        </span>
      </div>
    </div>
  </aside>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import * as UiMutations from '@/store/mutations/Ui.mutations';
import * as AuthActions from '@/store/actions/Auth.actions';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import TheLogo from '@/components/TheLogo.vue';

export default {
  name: 'SideNavigation',

  components: {
    LocaleSwitcher,
    TheLogo,
  },

  data() {
    return {
      isComponentLoading: false,
      isOrganizationDropdownActive: false,
      menuLinks: [
        {
          label: 'common.titles.projects',
          icon: 'table',
          to: '/projects',
          activePath: '/projects',
        },
        {
          label: 'common.terms.bills',
          icon: 'playlist-check',
          to: '/bills',
          activePath: '/bills',
        },
        {
          label: 'common.terms.reports',
          icon: 'finance',
          to: '/reports',
          activePath: '/reports',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      isSideNavigationOpen: state => state.ui.isSideNavigationOpen,
    }),
    ...mapGetters([
      'activeOrganization',
      'loggedInUser',
      'isLoggedInUserAtLeastAdmin',
      'isLoggedInUserAtLeastOwner',
    ]),
  },

  created() {
    // this.fetchInitialData();
  },

  methods: {
    ...mapMutations({
      setSideNavigationState: UiMutations.SET_SIDE_NAVIGATION_STATE,
    }),
    handleToggleSideNavigation(isSideNavigationOpen) {
      this.setSideNavigationState({ isSideNavigationOpen });
    },
    handleToggleOrganizationDropdown(isDropdownActive) {
      this.isOrganizationDropdownActive = isDropdownActive;
    },
    handleOrganizationDropdownClick(route) {
      this.$router.push(route);
      this.handleToggleSideNavigation(false);
    },
    handleClickProfile() {
      this.$router.push(`/users/${this.loggedInUser.id}/profile`);
      this.handleToggleSideNavigation(false);
    },
    isTabActive(urlPath) {
      return this.$route.path === urlPath;
    },
    async handleLogOut() {
      await this.$store.dispatch(AuthActions.LOG_OUT_USER);
      this.$router.push('/');
      this.handleToggleSideNavigation(false);
    },
    async fetchInitialData() {
      this.isComponentLoading = true;
      // Fetch any data here.
      this.isComponentLoading = false;
    },
  },
};
</script>

<style lang="scss">

.side-navigation {
  // hide when printing page
  @media print { display: none; }

  background-color: $white;
  border-right: 1px solid $grey-lighter;
  top: 0;
  bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: $side-navigation-width;
  z-index: 40;
  padding: $global-whitespace-medium;
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: $dark-shadow;

  @media screen and (max-width: $breakpoint-to-hide-side-navigation) {
    transform: translateX(-$side-navigation-width);
    z-index: 99999999;
    position: fixed;
    top: 0;

    &--is-active {
      transform: translateX(0);
    }
  }

  ul {
    li {
      padding: $global-whitespace-xsmall $global-whitespace-small;
      cursor: pointer;

      &:hover:not(.placeholder) {
        background-color: rgba($white, 0.1);
      }
    }
  }
}

.side-navigation__bottom {
  margin-top: auto;

  > div:not(:last-child) {
    margin-bottom: $global-whitespace-small;
  }
}

.side-navigation__menu-icon__container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: $global-whitespace-medium;
}

.side-navigation__menu-icon {
  cursor: pointer;

  @media screen and (min-width: ($breakpoint-to-hide-side-navigation + 1)) {
    display: none;
  }
}

// .side-navigation__organization-name__container {
//   // placeholder
// }

.side-navigation__organization-name__text {
  display: inline-block;
  width: 144px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  margin-bottom: -($global-whitespace-xsmall);
}

.side-navigation__organization-name__icon {
  vertical-align: text-bottom;
  transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.side-navigation__organization-name__icon--active {
  transform: rotate(-180deg);
}

.side-navigation__links__item {
  padding: $global-whitespace-xsmall $global-whitespace-small;
  border-radius: 6px;
  color: $dark;
  margin-bottom: $global-whitespace-small;

  &--is-active {
    color: $primary;
    background-color: $primary-light;
    font-weight: $weight-bold;
  }

  &:hover {
    color: darken($primary, 10);
  }
}

.side-navigation__logo__icon {
  width: 140px;
  margin-right: $global-whitespace-xsmall;
  margin-bottom: $global-whitespace-medium;
}
</style>
