<template>
  <BTable
    :data="tableData"
    hoverable
    detailed
    :mobile-cards="false"
    :show-detail-icon="false"
    detail-key="id"
    ref="activitiesTable"
    :class="{card:true, 'is-empty-table':tableData.length == 0 }"
    :defaultSort="['bill', 'desc']"
    :rowClass="(row) => row.changeOrderId && 'has-background-warning-light'"
  >
    <BModal
      :active="!!activityIdEditing"
      @close="handleCloseEditModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="activityIdEditing" class="modal-card-title">{{ $t('common.ctas.edit') }} {{ stateActivities[activityIdEditing].name }}</p>
          <button
            type="button"
            class="delete"
            @click="handleCloseEditModal"
          />
        </header>
        <div class="modal-card-body">
          <CreateOrEditActivityCard
            :costId="costId"
            :activityId="activityIdEditing"
            :isDark="false"
            :isInset="false"
            areFieldsStacked
            :hasBorderRadius="false"
            @submitSuccess="handleActivityUpdateSuccess"
          />
        </div>
      </div>
    </BModal>
    <ActivityConfirmDeleteModal
      :active="!!activityIdConfirmingDelete"
      :activityId="activityIdConfirmingDelete"
      :isDeleting="activityIdConfirmingDelete && activitiesDeleting.includes(activityIdConfirmingDelete)"
      @close="handleCloseDeleteModal"
      @confirmDeleteActivity="() => handleConfirmDeleteActivity(activityIdConfirmingDelete)"
    />
    <template slot="empty">
      <div class="has-text-grey u-p-a has-text-centered">
        {{ $t('costDetailsView.activities.emptyState') }}
      </div>
    </template>
    <BTableColumn
      field="name"
      :label="$t('common.terms.name')"
      cell-class="vertical-align-middle"
      :subheading="$t('common.terms.total')"
      sortable
      v-slot="props"
    >
      <p class="u-m-b-xsmall">
        {{ props.row.name }}
      </p>
      <CostCodeTag :costCodeId="props.row.costCodeId" />
    </BTableColumn>
    <BTableColumn
      field="activityCategory"
      :label="$t('common.terms.category')"
      width="200"
      cell-class="vertical-align-middle"
      v-slot="props"
    >
      <p :class="[{ 'has-text-grey is-italic': !props.row.activityCategoryId }]">
        {{ props.row.activityCategoryId ? activityCategories[props.row.activityCategoryId].name : $t('common.terms.uncategorized') }}
      </p>
    </BTableColumn>
    <BTableColumn
      v-if="isLumpSumBillingType"
      field="bill"
      :customSort="sortBills"
      :label="$t('common.terms.bill')"
      subheading="--"
      width="200"
      cell-class="vertical-align-middle"
      sortable
      v-slot="props"
    >
      <router-link
        v-if="getBillByActivityId(props.row.id)" :to="`/bills/${getBillByActivityId(props.row.id).id}`"
        class="cost-activities-table__bill-link"
      >
        <p class="has-text-weight-bold is-inline u-m-r-xsmall">
          {{ getBillByActivityId(props.row.id).number }}
          <span v-if="getBillByActivityId(props.row.id).name">
            - {{ getBillByActivityId(props.row.id).name }}
          </span>
        </p>
        <BillStatusTag :billStatusId="getBillByActivityId(props.row.id).statusId" size="small" />
      </router-link>
    </BTableColumn>
    <BTableColumn
      :label="$t('common.terms.contracted')"
      numeric
      cell-class="vertical-align-middle"
      :subheading="displayCurrency(activityContractedTotal, { prefix: '$', multiplier: 100 })"
      v-slot="props"
    >
      <p>{{ displayCurrency(calculateActivityBudget(props.row.unitValue, props.row.quantity), { prefix: '$', multiplier: 100 }) }}</p>
      <small>
        {{ props.row.quantity }} {{ props.row.unit }} @ {{ displayCurrency(props.row.unitValue, { multiplier: 100, prefix: '$' }) }}
      </small>
    </BTableColumn>
    <BTableColumn
      v-if="isAdvanceBillingType"
      :label="$t('common.terms.completed')"
      numeric
      cell-class="vertical-align-middle"
      :subheading="displayCurrency(activityCompletedTotal, { prefix: '$', multiplier: 100 })"
      v-slot="props"
    >
      <p>
        {{ displayCurrency(getActivityTotalAdvanceAmount(props.row.id), { prefix: '$', multiplier: 100 }) }}
      </p>
    </BTableColumn>
    <BTableColumn
      v-if="isAdvanceBillingType"
      :label="$t('common.terms.remainingBalance')"
      numeric
      cell-class="vertical-align-middle"
      :subheading="displayCurrency(activityRemainingBalanceTotal, { prefix: '$', multiplier: 100 })"
      v-slot="props"
    >
      {{ displayCurrency(getActivityRemainingBalanceAmount(props.row.id), { prefix: '$', multiplier: 100 }) }}
    </BTableColumn>
    <BTableColumn
      sortable
      :customSort="sortChangeOrders"
      :label="$t('common.terms.changeOrder')"
      subheading="--"
      cell-class="vertical-align-middle"
      v-slot="props"
      centered
    >
      <router-link v-if="props.row.changeOrderId" :to="`/projects/${projectId}/change-orders/${props.row.changeOrderId}/details`">
        <BIcon icon="format-list-group-plus" size="is-small" />
        {{ changeOrders[props.row.changeOrderId] ? changeOrders[props.row.changeOrderId].name : '' }}
      </router-link>
      <span v-else class="has-text-grey">
        --
      </span>
    </BTableColumn>
    <BTableColumn
      v-if="isAdvanceBillingType"
      :label="$t('common.terms.progress')"
      cell-class="vertical-align-middle"
      width="120"
      centered
    >
    <template #subheading>
      <vc-donut
        :sections="[{
          value: (activityProgressTotal * 100) || 0,
          color: '#5e72e4',
        }]"
        :size="60"
        :thickness="20"
        :auto-adjust-text-size="false"
      >
        <p class="has-text-weight-bold">
          {{ Math.round(convertFractionToPercent(activityProgressTotal)) }}%
        </p>
      </vc-donut>
    </template>
    <template v-slot="props">
      <vc-donut
        :sections="[{
          value: (getActivityTotalAdvancePercent(props.row.id) * 100) || 0,
          color: '#5e72e4',
        }]"
        :size="60"
        :thickness="20"
        :auto-adjust-text-size="false"
      >
        <p class="has-text-weight-bold">
          {{ Math.round(convertFractionToPercent(getActivityTotalAdvancePercent(props.row.id))) }}%
        </p>
      </vc-donut>
    </template>

    </BTableColumn>
    <BTableColumn
      field="rowActions"
      label=""
      cell-class="vertical-align-middle"
      :width="!isLoggedInUserAtLeastProjectManager ? 80 : 170"
      v-slot="props"
    >
      <div class="buttons" style="justify-content: center;">
        <BTooltip
          v-if="isAdvanceBillingType"
          :label="$t('common.titles.advanceHistory')"
          position="is-top"
          :triggers="['hover']"
          type="is-dark"
          class="u-m-r-small"
          appendToBody
        >
          <BButton
            type="is-primary is-light"
            @click="props.toggleDetails(props.row)"
            @keyup.enter="props.toggleDetails(props.row)"
          >
            <BIcon icon="format-list-bulleted" />
          </BButton>
        </BTooltip>
        <BTooltip
          v-if="isLoggedInUserAtLeastProjectManager"
          :label="$t('common.ctas.edit')"
          position="is-top"
          :triggers="['hover']"
          type="is-dark"
          class="u-m-r-small"
          appendToBody
        >
          <BButton
            type="is-primary is-light"
            @click="() => handleClickEditActivity(props.row.id)"
            @keyup.enter="() => handleClickEditActivity(props.row.id)"
          >
            <BIcon icon="pencil" />
          </BButton>
        </BTooltip>
        <BTooltip
          v-if="isLoggedInUserAtLeastProjectManager"
          :label="getActivityTotalAdvanceAmount(props.row.id) > 0 ? $t('costActivitiesTable.activityDeleteRestriction') : $t('common.ctas.delete')"
          position="is-top"
          :triggers="['hover']"
          type="is-dark"
          :multilined="getActivityTotalAdvanceAmount(props.row.id) > 0"
          :size="getActivityTotalAdvanceAmount(props.row.id) > 0 ? 'is-small' : undefined"
          style="max-width: 100px;"
          appendToBody
        >
          <BButton
            type="is-primary is-light"
            :disabled="getActivityTotalAdvanceAmount(props.row.id) > 0"
            @click="handleClickDeleteActivity(props.row.id)"
            @keyup.enter="handleClickDeleteActivity(props.row.id)"
          >
            <BIcon icon="delete" />
          </BButton>
        </BTooltip>
      </div>
    </BTableColumn>
    <template slot="detail" slot-scope="props">
      <ActivityRowDetails :activeActivityId="props.row.id" />
    </template>
  </BTable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as ActivityActions from '@/store/actions/Activity.actions';
import * as Types from '@/constants/Types';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import ActivityRowDetails from '@/components/ActivityRowDetails.vue';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import CostCodeTag from '@/components/CostCodeTag.vue';
import BillStatusTag from '@/components/BillStatusTag.vue';
import ActivityConfirmDeleteModal from '@/components/ActivityConfirmDeleteModal.vue';

export default {
  name: 'CostActivitiesTable',

  components: {
    ActivityRowDetails,
    BillStatusTag,
    CreateOrEditActivityCard,
    CostCodeTag,
    ActivityConfirmDeleteModal,
  },

  props: {
    costId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    /**
     * Pass an array of activities to display. If empty, component will default to
     * getting all activities matching the costId from global Vuex state.
     * */
    activities: {
      type: Array,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      openedDetailsTableRowIds: [], // An array of activity ids for table rows with details opened.
      activitiesDeleting: [],
      activityIdEditing: null,
      activityIdConfirmingDelete: null,
    };
  },

  computed: {
    ...mapState({
      activityCategories: state => state.activityCategory.activityCategories,
      bills: state => state.bill.bills,
      costs: state => state.cost.costs,
      costCodes: state => state.costCode.costCodes,
      costCodeCategories: state => state.costCodeCategory.costCodeCategories,
      stateActivities: state => state.activity.activities,
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    ...mapGetters([
      'getCostActivities',
      'getActivityTotalAdvanceAmount',
      'getActivityTotalAdvancePercent',
      'getActivityRemainingBalanceAmount',
      'getActivityRemainingBalancePercent',
      'getBillingTypeByName',
      'getBillByActivityId',
      'isLoggedInUserAtLeastAdmin',
      'isLoggedInUserAtLeastProjectManager',
    ]),

    activeCost() {
      return this.costs[this.costId];
    },
    tableData() {
      return this.activities ? this.activities : this.getCostActivities(this.costId);
    },

    isAdvanceBillingType() {
      return this.activeCost.billingTypeId === this.getBillingTypeByName(Types.billingTypes.advance).id;
    },
    isLumpSumBillingType() {
      return this.activeCost.billingTypeId === this.getBillingTypeByName(Types.billingTypes.lumpSum).id;
    },
    activityCompletedTotal() {
      return this.tableData.reduce((total, activity) => total + this.getActivityTotalAdvanceAmount(activity.id), 0);
    },
    activityContractedTotal() {
      return this.tableData.reduce((total, activity) => total + this.calculateActivityBudget(activity.unitValue, activity.quantity), 0);
    },
    activityRemainingBalanceTotal() {
      return this.tableData.reduce((total, activity) => total + this.getActivityRemainingBalanceAmount(activity.id), 0);
    },
    activityProgressTotal() {
      let progressTotal = 0;
      if (this.activityContractedTotal > 0) {
        progressTotal = this.activityCompletedTotal / this.activityContractedTotal;
      }
      return progressTotal;
    },

  },

  methods: {
    calculateActivityBudget,
    displayCurrency,
    convertFractionToPercent,
    addSuffix,
    async handleConfirmDeleteActivity(activityId) {
      this.activitiesDeleting = [...this.activitiesDeleting, activityId];
      try {
        await this.$store.dispatch(ActivityActions.DELETE_ACTIVITY_BY_ID, {
          activityId,
        });
        this.handleCloseDeleteModal();
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY.SUCCESS);
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY.ERROR);
      }
      this.activitiesDeleting = this.activitiesDeleting.filter(a => a !== activityId);
      this.activityIdConfirmingDelete = null;
    },
    handleClickEditActivity(activityId) {
      this.activityIdEditing = activityId;
    },
    handleClickDeleteActivity(activityId) {
      this.activityIdConfirmingDelete = activityId;
    },
    handleCloseEditModal() {
      this.activityIdEditing = null;
    },
    handleCloseDeleteModal() {
      this.activityIdConfirmingDelete = null;
    },
    handleActivityUpdateSuccess() {
      this.handleCloseEditModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_ACTIVITY.SUCCESS);
    },
    sortChangeOrders(a, b, isAsc) {
      const aValue = a.changeOrderId ? this.changeOrders[a.changeOrderId]?.name ?? '' : '';
      const bValue = b.changeOrderId ? this.changeOrders[b.changeOrderId]?.name ?? '' : '';

      if (aValue === '') return 1;
      if (bValue === '') return -1;
      if (aValue === bValue) return 0;

      // eslint-disable-next-line
      return isAsc ? (aValue > bValue ? 1 : -1) : (bValue > aValue ? 1 : -1);
    },
    sortBills(a, b, isAsc) {
      const aNumber = this.getBillByActivityId(a.id).number;
      const bNumber = this.getBillByActivityId(b.id).number;
      if (aNumber === bNumber) {
        return 0;
      }
      if (aNumber > bNumber) {
        return isAsc ? 1 : -1;
      }
      return isAsc ? -1 : 1;
    },
    getActivityCategoryName(categoryId) {
      if (this.activityCategories !== null && this.activityCategories.length > 0) {
        return this.activityCategories[categoryId].name;
      }
      return '...';
    },
  },
};
</script>

<style lang="scss" scoped>
  .cost-activities-table__bill-link {
    color: $dark !important;

    &:hover {
      color: $primary !important;
      text-decoration: underline;
    }
  }
</style>
