<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <UpgradeSubscriptionModal :isOpen="isSubscriptionModalOpen" @close="toggleSubscriptionModal" />
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.projects') }}&nbsp;({{ projectsArray.length || 0 }})</h4>
        </div>
        <div v-if="isLoggedInUserAtLeastProjectManager" class="app-page__header__action">
          <BButton
            type="is-primary"
            outlined
            iconLeft="plus-thick"
            @click="handleClickCreateProject"
            @keyup.enter="handleClickCreateProject"
          >
            {{ $t('common.ctas.createProject') }}
          </BButton>
        </div>
      </section>

      <section class="app-page__section">
        <div class="columns">
          <div
            v-for="total in projectTotals"
            :key="total.key"
            class="column"
          >
            <TotalsSummaryCard
              :title="total.title"
              :tooltipInfo="total.tooltipInfo"
            >
              <AnimatedNumber
                :value="total.amount"
                :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                :duration="400"
                :class="[
                'card-content u-p-t-0 u-p-b-small u-p-x title is-size-4 is-size-3-fullhd has-text-weight-black u-m-b-0 has-text-right',
                { 'is-clickable is-underlined': total.hasTooltip }
                ]"
              />
            </TotalsSummaryCard>
          </div>
        </div>
      </section>

      <section class="app-page__section">
        <div class="is-flex is-justify-content-flex-end">
          <BSwitch
            class="u-m-y-small"
            rounded
            v-model="areArchivedProjectsIncluded"
          >
            <span class="has-text-weight-bold">{{ $t('projectListView.showArchivedProjects') }}</span>
          </BSwitch>
        </div>
        <BTable
          v-if="projectsArray.length > 0"
          :data="projectsArray"
          hoverable
          @click="handleClickRow"
          @keyup.enter="handleClickRow"
          :class="{card:true, 'is-empty-table': projectsArray.length == 0}"
          :mobile-cards="false"
          :row-class="handleRowClass"
          :default-sort-direction="'asc'"
          :default-sort="['createdAt', 'desc']"
          scrollable
        >
          <BTableColumn
            field="name"
            :label="$t('projectsTable.columns.project')"
            cell-class="vertical-align-middle u-p-l"
            header-class="u-p-l"
            sortable
            :th-attrs="() => ({ style: { 'min-width': '150px' } })"
          >
            <template #subheading>
              <div class="u-p-l-small">
                <p class="title is-5 has-text-weight-black">{{ $t('common.terms.total') }}</p>
              </div>
            </template>
            <template v-slot="props">
              <p class="title is-5">{{ props.row.name }}</p>
              <p class="subtitle is-size-6 is-italic u-m-b-xsmall">{{ props.row.description }}</p>
              <BTag v-if="isProjectArchived(props.row.id)" type="is-warning is-light">{{ $t('common.terms.archived') }}</BTag>
            </template>
          </BTableColumn>
          <BTableColumn
            field="budgeted"
            :label="$t('common.terms.budgeted')"
            :subheading="displayCurrency(totalBudgetedAllProjects,{ prefix: '$', multiplier: 100 })"
            cell-class="vertical-align-middle"
            v-slot="props"
            sortable
            numeric
          >
            {{ displayCurrency(getTotalBudgetValue(projects[props.row.id].activeBudgetId), { prefix: '$', multiplier: 100 }) }}
          </BTableColumn>
          <BTableColumn
            field="contracted"
            :label="$t('common.terms.contracted')"
            :subheading="displayCurrency(totalContractedAllProjects,{ prefix: '$', multiplier: 100 })"
            cell-class="vertical-align-middle"
            v-slot="props"
            sortable
            numeric
          >
            {{ displayCurrency(getProjectTotalActivityBudget(props.row.id), { prefix: '$', multiplier: 100 }) }}
          </BTableColumn>
          <BTableColumn
            field="completed"
            :label="$t('common.terms.completed')"
            :subheading="displayCurrency(totalCompletedAllProjects,{ prefix: '$', multiplier: 100 })"
            cell-class="vertical-align-middle"
            v-slot="props"
            sortable
            numeric
          >
            {{ displayCurrency(getProjectTotalAdvanceAmount(props.row.id), { prefix: '$', multiplier: 100 }) }}
          </BTableColumn>
          <BTableColumn
            field="remainingBalance"
            :label="$t('common.terms.totalClosed')"
            :subheading="displayCurrency(totalClosedAllProjects,{ prefix: '$', multiplier: 100 })"
            cell-class="vertical-align-middle"
            v-slot="props"
            sortable
            numeric
          >
            {{ displayCurrency(getProjectTotalClosedAmount(props.row.id) + getProjectTotalClosedTaxAmount(props.row.id), { prefix: '$', multiplier: 100 }) }}
          </BTableColumn>
          <!-- <BTableColumn
            field="progress"
            :label="$t('common.terms.progress')"
            cell-class="vertical-align-middle"
            width="120"
            centered
          >
          <template #subheading>
            <vc-donut
              :sections="[{
                value: (totalAdvancePercentAllProjects) <= 100 ? (totalAdvancePercentAllProjects) : 0,
                color: '#5e72e4',
              }]"
              :size="60"
              :thickness="20"
              :auto-adjust-text-size="false"
            >
              <p class="has-text-weight-bold">
                {{ Math.round(convertFractionToPercent(totalAdvancePercentAllProjects)) }}%
              </p>
            </vc-donut>
          </template>
          <template v-slot="props">
            <vc-donut
              :sections="[{
                value: (getProjectTotalAdvancePercent(props.row.id) * 100) <= 100 ? (getProjectTotalAdvancePercent(props.row.id) * 100) : 0,
                color: '#5e72e4',
              }]"
              :size="60"
              :thickness="20"
              :auto-adjust-text-size="false"
            >
              <p class="has-text-weight-bold">
                {{ Math.round(convertFractionToPercent(getProjectTotalAdvancePercent(props.row.id))) }}%
              </p>
            </vc-donut>
          </template>
          </BTableColumn> -->
          <BTableColumn
            field="createdAt"
            :label="$t('projectsTable.columns.createdAt')"
            subheading="--"
            cell-class="vertical-align-middle"
            v-slot="props"
            sortable
          >
            {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale, {}) }}
          </BTableColumn>
        </BTable>
        <div v-else class="card">
          <div class="card-content has-text-centered">
            <p class="has-text-grey title is-4">
              {{ $t('projectListView.emptyState') }}
            </p>
            <div class="u-m-y-large">
            <img src="../assets/images/product-illustrations/projects-empty-state.svg" style="height: 150px;" />
            </div>
            <BButton
              v-if="isLoggedInUserAtLeastProjectManager"
              class="is-inline-block"
              type="is-primary"
              size="is-large"
              @click="handleClickCreateProject"
              @keyup.enter="handleClickCreateProject"
            >
              {{ $t('projectListView.emptyStateCta') }}
            </BButton>
            <p v-else>
              {{ $t('projectListView.emptyStateCreateNotAllowed') }}
            </p>
          </div>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';
import { mapState, mapGetters } from 'vuex';
import * as ProjectActions from '@/store/actions/Project.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import TotalsSummaryCard from '@/components/TotalsSummaryCard.vue';
import UpgradeSubscriptionModal from '@/components/UpgradeSubscriptionModal.vue';

export default {
  name: 'Projects',

  components: {
    AnimatedNumber,
    TotalsSummaryCard,
    UpgradeSubscriptionModal,
  },

  data() {
    return {
      isPageLoading: true,
      isSubscriptionModalOpen: false,
      areArchivedProjectsIncluded: true,
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      projects: state => state.project.projects,
      clients: state => state.client.clients,
      activeProjectCount: state => state.project.activeProjectCount,
    }),
    ...mapGetters([
      'loggedInUser',
      'getTotalBudgetValue',
      'activeOrganization',
      'getOrganizationSubscription',
      'getSubscriptionTypeByName',
      'isLoggedInUserAtLeastAdmin',
      'getProjectTotalActivityBudget',
      'getProjectTotalAdvanceAmount',
      'getProjectTotalAdvancePercent',
      'getProjectTotalClosedAmount',
      'getProjectTotalClosedTaxAmount',
      'getProjectTotalOpenTaxAmount',
      'getOpenBillItemsByProjectId',
      'isLoggedInUserAtLeastProjectManager',
      'getLoggedInUserProjects',
      'isProjectArchived',
    ]),
    projectsArray() {
      return this.getLoggedInUserProjects.filter(p => (this.areArchivedProjectsIncluded ? p : !this.isProjectArchived(p.id)));
    },
    totalBudgetedAllProjects() {
      return this.projectsArray.reduce((total, project) => total + this.getTotalBudgetValue(project.activeBudgetId), 0) ?? 0;
    },
    totalContractedAllProjects() {
      return this.projectsArray.reduce((total, project) => total + this.getProjectTotalActivityBudget(project.id), 0);
    },
    totalCompletedAllProjects() {
      return this.projectsArray.reduce((total, project) => total + this.getProjectTotalAdvanceAmount(project.id), 0);
    },
    totalInOpenBillsAllProjects() {
      return this.projectsArray.reduce((total, project) => total + this.getOpenBillItemsByProjectId(project.id).reduce((acc, curr) => (acc + curr.amount), 0), 0);
    },
    totalClosedAllProjects() {
      return this.projectsArray.reduce((total, project) => total + this.getProjectTotalClosedAmount(project.id), 0);
    },
    totalClosedTaxAllProjects() {
      return this.projectsArray.reduce((total, project) => total + this.getProjectTotalClosedTaxAmount(project.id), 0);
    },
    totalOpenTaxAllProjects() {
      return this.projectsArray.reduce((total, project) => total + this.getProjectTotalOpenTaxAmount(project.id), 0);
    },
    projectTotals() {
      return [
        {
          title: this.$t('common.terms.budgeted'),
          amount: this.totalBudgetedAllProjects,
          tooltipInfo: this.$t('costDetailsView.summaryInfo.budgeted'),
        },
        {
          title: this.$t('common.terms.contracted'),
          amount: this.totalContractedAllProjects,
          // titleFigure: this.getCostTotalAdvanceAmount(this.activeCostId) / this.getCostTotalActivityBudget(this.activeCostId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.contracted'),
        },
        {
          title: this.$t('common.terms.inOpenBills'),
          amount: this.totalInOpenBillsAllProjects + this.totalOpenTaxAllProjects,
          // titleFigure: this.totalCompletedAllProjects / this.totalContractedAllProjects,
          tooltipInfo: this.$t('costDetailsView.summaryInfo.inOpenBills'),
        },
        // {
        //   title: this.$t('common.terms.remainingBalance'),
        //   amount: this.getCostRemainingBalance(this.activeCostId),
        //   titleFigure: this.getCostRemainingBalance(this.activeCostId) / this.getCostTotalActivityBudget(this.activeCostId),
        //   tooltipInfo: this.$t('costDetailsView.summaryInfo.remainingBalance'),
        // },
        {
          title: this.$t('common.terms.totalClosed'),
          amount: this.totalClosedAllProjects + this.totalClosedTaxAllProjects,
          // titleFigure: this.totalClosedAllProjects / this.totalContractedAllProjects,
          tooltipInfo: this.$t('costDetailsView.summaryInfo.totalClosed'),
          // tax: this.totalClosedTaxAllProjects,
        },
      ];
    },
    doesOrganizationHaveProSubscription() {
      return this.getOrganizationSubscription.subscriptionTypeId === this.getSubscriptionTypeByName('pro').id;
    },
    totalAdvancePercentAllProjects() {
      let totalPercents = 0;
      if (this.totalContractedAllProjects > 0) {
        totalPercents = this.totalCompletedAllProjects / this.totalContractedAllProjects;
      }
      return totalPercents;
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    displayCurrency,
    addSuffix,
    convertFractionToPercent,
    handleRowClass() {
      return 'has-cursor-pointer';
    },
    handleClickRow(projectRow) {
      this.$router.push(`/projects/${projectRow.id}/details`);
    },
    handleClickCreateProject() {
      if (this.activeProjectCount > 0 && !this.doesOrganizationHaveProSubscription) {
        this.toggleSubscriptionModal();
      } else {
        this.$router.push('/projects/create');
      }
    },
    toggleSubscriptionModal() {
      this.isSubscriptionModalOpen = !this.isSubscriptionModalOpen;
    },
    async fetchInitialData() {
      this.isPageLoading = true;
      try {
        if (this.isLoggedInUserAtLeastAdmin) {
          await this.$store.dispatch(ProjectActions.FETCH_ORGANIZATION_PROJECTS, { organizationId: this.activeOrganization.id });
        } else {
          await this.$store.dispatch(ProjectActions.FETCH_USER_PROJECTS, { userId: this.loggedInUser.id });
        }
      } catch (err) {
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isPageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.subcontracts-table__advance-column__header .th-wrap {
  text-align: center !important;
}
</style>
