<template>
  <div id="verify-email-view" class="app-page__container">
    <div v-if="!isPageLoading && !isEmailVerified" class="form-container">
      <form
        v-if="!isObjectEmpty(authUser) && !isLogInFormInProgress"
        class="has-text-centered"
        @submit.prevent="handleResendVerificationEmail"
      >
        <h3 class="title is-3">{{ $t('verifyEmailView.title') }}</h3>
        <p class="is-size-5 u-m-b-large" v-html="$t('verifyEmailView.body', { email: authUser.email })" />
        <p class="is-size-7 u-m-b-xsmall">
          {{ $t('verifyEmailView.helpText') }}
        </p>
        <BField v-if="emailVerificationError">
          <BMessage type="is-danger">
            {{ emailVerificationError.message }}
          </BMessage>
        </BField>
        <BButton
          native-type="submit"
          size="is-small"
          type="is-text"
          class="is-fullwidth has-text-primary"
          :loading="isResendEmailInProgress"
          :disabled="isResendEmailInProgress"
        >
          {{ $t('verifyEmailView.cta') }}
        </BButton>
      </form>
      <form v-else-if="isObjectEmpty(authUser)" @submit.prevent="handleSubmitLogIn">
        <h3 class="title is-3 has-text-centered">{{ $t('verifyEmailView.logInCta') }}</h3>
        <BField v-if="logInError">
          <BMessage type="is-danger">
            {{ logInError.message }}
          </BMessage>
        </BField>
        <BField
          label="Email"
          :type="logInFormErrors[logInForm.userEmail.name] ? 'is-danger' : ''"
          :message="logInFormErrors[logInForm.userEmail.name] || ''"
        >
          <BInput
            v-model="logInForm.userEmail.value"
            :name="logInForm.userEmail.name"
            :disabled="isLogInFormInProgress"
          />
        </BField>
        <BField
          label="Password"
          :type="logInFormErrors[logInForm.userPassword.name] ? 'is-danger' : ''"
          :message="logInFormErrors[logInForm.userPassword.name] || ''"
        >
          <BInput
            v-model="logInForm.userPassword.value"
            type="password"
            password-reveal
            :disabled="isLogInFormInProgress"
          />
        </BField>
        <BButton
          native-type="submit"
          type="is-primary"
          value="Log In"
          class="is-fullwidth"
          :loading="isLogInFormInProgress"
          :disabled="isLogInFormInProgress"
        >
          {{ $t('verifyEmailView.logInCta') }}
        </BButton>
      </form>
    </div>
    <div v-else-if="!isPageLoading && isEmailVerified" class="form-container">
      <h3 class="title is-3 has-text-centered">{{ $t('verifyEmailView.verifiedEmail') }}</h3>
        <BField v-if="emailVerificationError">
          <BMessage type="is-danger">
            <!-- @TODO Localized error message? -->
            {{ verificationCodeError.message }}
          </BMessage>
        </BField>
      <p class="is-size-5 u-m-b-large has-text-centered" v-html="$t('verifyEmailView.redirectCopy', { secondsRemaining: secondsUntilRedirect })" />
    </div>
    <BLoading is-full-page :active.sync="isPageLoading" :can-cancel="false"></BLoading>
  </div>
</template>

<script>
import {
  mapState,
} from 'vuex';
import * as AuthActions from '@/store/actions/Auth.actions';
import * as UserActions from '@/store/actions/User.actions';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as ValidationService from '@/services/Validation.service';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'VerifyEmail',

  data() {
    return {
      logInForm: {
        userEmail: {
          name: 'Email Address',
          value: '',
          required: true,
          validator: ValidationService.validateEmail,
        },
        userPassword: {
          name: 'Password',
          value: '',
          required: true,
        },
      },
      logInFormErrors: {},
      logInError: null,
      isLogInFormInProgress: false,
      emailVerificationError: null,
      isResendEmailInProgress: false,
      isPageLoading: true,
      isEmailVerified: false,
      secondsUntilRedirect: 3,
      verificationCodeError: null,
      redirectInterval: null,
    };
  },

  computed: {
    ...mapState({
      authUser: state => state.auth.authUser,
    }),
    logInPayload() {
      return {
        userEmail: this.logInForm.userEmail.value,
        userPassword: this.logInForm.userPassword.value,
      };
    },
  },

  created() {
    this.getInitialState();
  },

  beforeDestroy() {
    if (this.redirectInterval) clearInterval(this.redirectInterval);
  },

  methods: {
    isObjectEmpty,
    async getInitialState() {
      if (!isObjectEmpty(this.authUser) && this.$route.query.oobCode) {
        console.log('did I try to apply verification code?');
        await this.applyVerificationCode();
      }

      this.isPageLoading = false;
    },
    async handleSubmitLogIn() {
      this.logInErrors = null;
      this.logInFormErrors = ValidationService.runFormValidation({ ...this.logInForm });
      if (!isObjectEmpty(this.logInFormErrors)) return;

      this.isLogInFormInProgress = true;
      try {
        await this.$store.dispatch(AuthActions.LOG_IN_WITH_EMAIL_AND_PASSWORD, this.logInPayload);
        const user = await this.$store.dispatch(UserActions.FETCH_USER_BY_ID, { userId: this.authUser.uid });
        this.$store.commit(UserMutations.SET_LOGGED_IN_USER, user.result);
        await this.applyVerificationCode();
        this.isLogInFormInProgress = false;
      } catch (err) {
        this.logInError = err;
        this.isLogInFormInProgress = false;
      }
    },
    async handleResendVerificationEmail() {
      try {
        this.emailVerificationError = null;
        this.isResendEmailInProgress = true;
        await this.$store.dispatch(AuthActions.SEND_EMAIL_VERIFICATION);
        this.isResendEmailInProgress = false;
      } catch (err) {
        this.isResendEmailInProgress = false;
        this.emailVerificationError = err;
      }
    },
    async applyVerificationCode() {
      try {
        const actionCode = this.$route.query.oobCode;
        await this.$store.dispatch(AuthActions.APPLY_EMAIL_VERIFICATION_CODE, { actionCode });
        this.isEmailVerified = true;
        this.handleRedirect();
      } catch (err) {
        this.verificationCodeError = err;
      }
    },
    handleRedirect() {
      this.redirectInterval = setInterval(() => {
        if (this.secondsUntilRedirect > 0) {
          this.secondsUntilRedirect -= 1;
        } else {
          this.$router.push('/');
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  margin: 0 auto;
  @media screen and (min-width: $tablet) {
    max-width: 500px;
  }
}
</style>
