<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
        {{ $t('common.notifications.archivedProject') }}
      </BMessage>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4 u-m-b-xsmall">
            {{ $t('common.terms.bills') }}
          </h4>
          <h5 class="is-size-5 has-text-grey has-text-weight-bold">
            {{ activeProject.name }}
          </h5>
          <!-- <p v-if="activeCost.vendorId" class="subtitle is-size-6 is-italic has-text-weight-normal">
            <BIcon icon="account-hard-hat" size="is-small" />
            {{ vendors[activeCost.vendorId].name }}
          </p> -->
        </div>
        <div class="app-page__header__action">
          <div class="buttons is-small is-align-items-flex-start">
            <BButton type="is-primary" outlined size="is-small" iconLeft="upload" @click="$router.push(`/projects/${activeProjectId}/bills/import`)">
              {{ $t('common.ctas.importBills') }}
            </BButton>
            <!-- <BButton type="is-primary" size="is-small" iconLeft="plus-thick">
              &nbsp;
              {{ $t('common.ctas.createBill') }}
            </BButton> -->
          </div>
        </div>
      </section>
      <section class="app-page__section">
        <ProjectBillsTable :projectId="activeProjectId" />
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as ProjectActions from '@/store/actions/Project.actions';
// import * as BillActions from '@/store/actions/Bill.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import ProjectBillsTable from '@/components/ProjectBillsTable.vue';

export default {
  name: 'CostBills',

  components: {
    ProjectBillsTable,
  },

  data() {
    return {
      isPageLoading: true,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      costs: state => state.cost.costs,
      vendors: state => state.vendor.vendors,
    }),
    ...mapGetters([
      'activeOrganization',
      'isProjectArchived',
    ]),
    activeCostId() {
      return this.$route.params.costId;
    },
    activeCost() {
      return this.costs[this.activeCostId];
    },
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
  },

  created() {
    this.fetchinitialData();
  },

  methods: {
    async fetchinitialData() {
      this.isPageLoading = true;
      try {
        await Promise.all([
          this.$store.dispatch(ProjectActions.FETCH_BILLS_BY_PROJECT_ID, { projectId: this.$route.params.projectId }),
          // this.$store.dispatch(ProjectActions.FETCH_PROJECT_BY_ID, { projectId: this.$route.params.projectId }),
        ]);
      } catch {
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isPageLoading = false;
    },
  },
};
</script>
