<template>
  <BTag :class="[tagClass, 'has-text-weight-bold']">
    {{ $t(`common.costTypes.${costTypes[costTypeId].name}`) }}
  </BTag>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CostTypeTag',

  props: {
    costTypeId: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      costTypes: state => state.costType.costTypes,
    }),
    tagClass() {
      const costType = this.costTypes[this.costTypeId];
      return costType.name;
    },
  },
};
</script>

<style scoped lang="scss">
$tag-purple: #cfdfff;
$tag-green: #d1f7c4;
$tag-blue: #d0f0fd;
$tag-red: #fee2d5;
$tag-yellow: #ffeab6;
$tag-pink: #ffdaf6;

.contract { background-color: $tag-purple; }
.materials { background-color: $tag-green; }
.equipment { background-color: $tag-blue; }
.payroll { background-color: $tag-red; }
.indirect { background-color: $tag-yellow; }
.labor { background-color: $tag-pink; }
</style>
