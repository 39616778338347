<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
        {{ $t('common.notifications.archivedProject') }}
      </BMessage>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">
            {{ activeBudget.name }}
          </h4>
          <p v-if="activeBudget.description" class="subtitle is-italic is-size-6">
            {{ activeBudget.description }}
          </p>
        </div>
         <div class="app-page__header__action">
          <div class="columns is-1 is-variable">
            <div class="column">
              <BButton
                type="is-primary"
                size="is-small"
                outlined
                icon-left="finance"
                @click="handleClickBudgetReport"
                @keyup.enter="handleClickBudgetReport"
              >
                {{ $t('common.titles.activitiesByCostCode') }}
              </BButton>
            </div>
          </div>
        </div>
      </section>
      <section class="app-page__section">
        <div class="columns">
          <div
            v-for="total in costTotals"
            :key="total.key"
            class="column"
          >
            <div class="card">
              <header class="card-header">
                <p class="card-header-title has-text-grey">{{ total.title }}</p>
                <p v-if="total.percent" class="card-header-icon has-text-weight-bold">
                  <animated-number
                    :value="total.percent"
                    :formatValue="(value) => addSuffix(convertFractionToPercent(`${value}`), '%')"
                    :duration="400"
                  />
                </p>
                <p
                  v-else-if="total.percentOfBudget"
                  :class="[
                    'card-header-icon',
                    'has-text-weight-bold',
                    total.percentOfBudget > 1 ? 'has-text-danger' : 'has-text-success'
                  ]"
                >
                  <animated-number
                    :value="total.percentOfBudget"
                    :formatValue="(value) => addSuffix(convertFractionToPercent(`${value}`), '%')"
                    :duration="400"
                  />
                </p>
              </header>
              <div class="card-content">
                <h4 class="title is-4">
                  <animated-number
                    :value="total.amount"
                    :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                    :duration="400"
                  />
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="app-page__section">
        <div class="app-page__header">
          <div class="app-page__header__title">
            <div style="display: flex; align-items: baseline">
              <h5 class="title is-5 has-text-grey u-m-b-0 u-m-r-small">
                {{ $t('common.terms.activities') }}
              </h5>
              <BTabs type="is-toggle" v-model="activeView" class="empty-tabs" size="is-small">
                <BTabItem :label="$t('projectBudgetsView.activityViewModes.allActivities')" icon="format-list-bulleted" />
                <BTabItem :label="$t('projectBudgetsView.activityViewModes.groupedByCategory')" icon="folder-text-outline" />
              </BTabs>
            </div>
          </div>
          <div class="app-page__header__action">
            <BButton
              type="is-primary"
              size="is-small"
              outlined
              icon-left="finance"
              @click="handleClickImportActivities"
              @keyup.enter="handleClickImportActivities"
            >
              {{ $t('common.ctas.importActivities') }}
            </BButton>
          </div>
        </div>
      </section>
      <div v-if="activeView === 0">
        <section class="app-page__section">
          <CreateOrEditActivityCard v-if="!isProjectArchived(activeProject.id)" :budgetId="activeBudgetId" />
        </section>
        <section class="app-page__section">
          <BudgetActivitiesTable :budgetId="activeBudgetId" :projectId="activeProjectId" />
        </section>
      </div>
      <div v-if="activeView === 1">
        <section class="app-page__section">
          <CreateActivityCategoryCard v-if="!isProjectArchived(activeProject.id)" :budgetId="activeBudgetId" @addCategory="handleAddTempCategory" />
        </section>
        <section class="app-page__section">
          <BudgetActivityCategoriesTable
            :budgetId="activeBudgetId"
            :projectId="activeProjectId"
            :categories="budgetActivityCategories"
            :openedCategories="openedCategories"
            @createActivity="handleCreateCategorizedActivity"
            @openCategory="handleOpenCategoryRowDetails"
            @closeCategory="handleCloseCategoryRowDetails"
          />
        </section>
      </div>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AnimatedNumber from 'animated-number-vue';
import * as ProjectActions from '@/store/actions/Project.actions';
import * as BudgetActions from '@/store/actions/Budget.actions';
import * as NotificationService from '@/services/Notification.service';
import { displayCurrency } from '@/helpers/stringHelpers';
import * as NotificationTypes from '@/constants/NotificationTypes';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import CreateActivityCategoryCard from '@/components/CreateActivityCategoryCard.vue';
import BudgetActivitiesTable from '@/components/BudgetActivitiesTable.vue';
import BudgetActivityCategoriesTable from '@/components/BudgetActivityCategoriesTable.vue';

export default {
  name: 'ProjectBudgetDetails',

  components: {
    BudgetActivitiesTable,
    BudgetActivityCategoriesTable,
    CreateOrEditActivityCard,
    CreateActivityCategoryCard,
    AnimatedNumber,
  },

  data() {
    return {
      isPageLoading: true,
      activeView: 0,
      tempCategories: {},
      openedCategories: [],
    };
  },

  computed: {
    ...mapState({
      activityCategories: state => state.activityCategory.activityCategories,
      projects: state => state.project.projects,
      costs: state => state.cost.costs,
      vendors: state => state.vendor.vendors,
      budgets: state => state.budget.budgets,
    }),
    ...mapGetters([
      'getTotalBudgetValue',
      'getBudgetActivities',
      'isProjectArchived',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    activeBudgetId() {
      return this.$route.params.budgetId;
    },
    activeBudget() {
      return this.budgets[this.activeBudgetId];
    },
    costTotals() {
      return [
        {
          title: this.$t('common.terms.budgeted'),
          amount: this.getTotalBudgetValue(this.activeBudgetId),
        },
      ];
    },
    budgetActivityCategories() {
      return this.getBudgetActivities(this.activeBudgetId)
        .filter(activity => activity.activityCategoryId)
        .reduce((acc, currActivity) => (
          [...new Set([...acc, currActivity.activityCategoryId])]
        ), ['no-category', ...Object.values(this.tempCategories)])
        .map(activityCategoryId => (
          activityCategoryId === 'no-category'
            ? { name: 'common.terms.uncategorized', id: 'no-category' }
            : this.activityCategories[activityCategoryId]
        ))
        .sort((a, b) => (
          b.id === 'no-category' // eslint-disable-line no-nested-ternary
            ? -1
            : (a.name > b.name ? 1 : -1)
        ));
    },
  },

  created() {
    this.fetchinitialData();
  },

  methods: {
    displayCurrency,
    async fetchinitialData() {
      this.isPageLoading = true;
      try {
        await this.$store.dispatch(ProjectActions.FETCH_PROJECT_BY_ID, { projectId: this.$route.params.projectId });
        await this.$store.dispatch(BudgetActions.FETCH_BUDGET_BY_ID, { budgetId: this.$route.params.budgetId });
      } catch {
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isPageLoading = false;
    },
    handleClickImportActivities() {
      this.$router.push(`/projects/${this.activeProjectId}/budgets/${this.activeBudgetId}/activities/import`);
    },
    handleAddTempCategory(categoryId) {
      this.tempCategories = {
        ...this.tempCategories,
        [categoryId]: categoryId,
      };

      this.openedCategories = [...this.openedCategories, categoryId];
    },
    handleCreateCategorizedActivity(activityId) {
      const categories = { ...this.tempCategories };
      delete categories[activityId];

      this.tempCategories = { ...categories };
    },
    handleOpenCategoryRowDetails(row) {
      this.openedCategories = [...this.openedCategories, row.id];
    },
    handleCloseCategoryRowDetails(row) {
      this.openedCategories = this.openedCategories.filter(c => c.id !== row.id);
    },
    handleClickBudgetReport() {
      this.$router.push(`/reports/activities/by-cost-code?project=${this.activeProjectId}&budget=${this.activeBudgetId}`);
    },
  },
};
</script>

<style lang="scss">
.empty-tabs {
  > .tab-content {
    padding: 0 !important;
  }
}
</style>
