import axios from 'axios';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { objectToUrlParams } from '@/helpers/dataHelpers';

const authTokenInterceptor = async config => {
  const user = firebase.auth().currentUser;
  const token = user ? (await user.getIdToken()) : null;

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // eslint-disable-line
  }

  return config;
};

const BASE_URL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.baseURL = BASE_URL;
axios.interceptors.request.use(authTokenInterceptor);

export const user = {
  signUp: payload => axios.post('/users/sign-up', payload),
  create: payload => axios.post('/users', payload),
  findOne: id => axios.get(`/users/${id}`),
  findMany: payload => axios.get(`/users/${objectToUrlParams(payload)}`),
  updateOne: (id, payload) => axios.patch(`/users/${id}`, payload),
  project: {
    findMany: id => axios.get(`/users/${id}/projects`),
  },
  invitation: {
    resend: id => axios.patch(`/users/${id}/resend-invitation`),
  },
  role: {
    updateOne: (id, payload) => axios.patch(`/users/${id}/roles`, payload),
  },
  bill: {
    findMany: id => axios.get(`/users/${id}/bills`),
  },
};

export const organization = {
  create: payload => axios.post('/organizations/', payload),
  user: {
    findMany: id => axios.get(`/organizations/${id}/users`),
  },
  vendor: {
    findMany: (id, params) => axios.get(`/organizations/${id}/vendors${objectToUrlParams(params)}`),
  },
  tax: {
    findMany: id => axios.get(`/organizations/${id}/taxes`),
  },
  bill: {
    findMany: id => axios.get(`/organizations/${id}/bills`),
  },
  client: {
    findMany: id => axios.get(`/organizations/${id}/clients`),
  },
  costCode: {
    findMany: (id, params) => axios.get(`/organizations/${id}/cost-codes${objectToUrlParams(params)}`),
  },
  costCodeCategory: {
    findMany: id => axios.get(`/organizations/${id}/cost-code-categories`),
  },
  auditLog: {
    findMany: (id, params) => axios.get(`/organizations/${id}/audit-logs${objectToUrlParams(params)}`),
  },
  role: {
    findMany: id => axios.get(`/organizations/${id}/roles`),
  },
  project: {
    findMany: id => axios.get(`/organizations/${id}/projects`),
    count: (id, params) => axios.get(`/organizations/${id}/projects/count${objectToUrlParams(params)}`),
  },
  activityCategory: {
    findMany: id => axios.get(`/organizations/${id}/activity-categories`),
  },
  subscription: {
    findMany: id => axios.get(`/organizations/${id}/subscriptions`),
  },
};

export const project = {
  create: payload => axios.post('/projects', payload),
  findOne: id => axios.get(`/projects/${id}`),
  bill: {
    findMany: id => axios.get(`/projects/${id}/bills`),
  },
  budget: {
    findMany: id => axios.get(`/projects/${id}/budgets`),
  },
  changeOrder: {
    findMany: id => axios.get(`/projects/${id}/change-orders`),
  },
  updateOne: (id, payload) => axios.patch(`/projects/${id}`, payload),
};

export const invitation = {
  accept: id => axios.patch(`/invitations/accept/${id}`),
};

export const type = {
  findMany: () => axios.get('/types'),
};

export const vendor = {
  findOne: id => axios.get(`vendors/${id}`),
  create: payload => axios.post('/vendors', payload),
  updateOne: (id, payload) => axios.patch(`/vendors/${id}`, payload),
  deleteOne: id => axios.delete(`/vendors/${id}`),
};

export const tax = {
  create: payload => axios.post('/taxes', payload),
};

export const cost = {
  create: payload => axios.post('/costs', payload),
  findOne: id => axios.get(`/costs/${id}`),
  bill: {
    findMany: id => axios.get(`/costs/${id}/bills`),
  },
  update: (id, payload) => axios.patch(`/costs/${id}`, payload),
  deleteOne: id => axios.delete(`/costs/${id}`),
};

export const budget = {
  create: payload => axios.post('/budgets', payload),
  findOne: id => axios.get(`/budgets/${id}`),
  deleteOne: id => axios.delete(`/budgets/${id}`),
};

export const activity = {
  create: payload => axios.post('/activities', payload),
  createMany: payload => axios.post('/activities/bulk', payload),
  updateOne: (id, payload) => axios.patch(`/activities/${id}`, payload),
  deleteOne: id => axios.delete(`/activities/${id}`),
  billItem: {
    findMany: id => axios.get(`/activities/${id}/bill-items`),
  },
};

export const bill = {
  create: payload => axios.post('/bills', payload),
  updateOne: (id, payload) => axios.patch(`/bills/${id}`, payload),
  findOne: id => axios.get(`/bills/${id}`),
  billStatus: {
    updateOne: (id, payload) => axios.patch(`/bills/${id}/status`, payload),
  },
  comment: {
    create: (id, payload) => axios.post(`/bills/${id}/comments`, payload),
    findMany: id => axios.get(`/bills/${id}/comments`),
  },
  document: {
    create: (id, payload) => {
      const formData = new FormData();
      formData.append('file', payload.file);
      return axios.post(`/bills/${id}/documents`, formData, { headers: { 'content-type': 'multipart/form-data' } });
    },
    findOne: (id, documentId) => axios.get(`/bills/${id}/documents/${documentId}`),
    findMany: id => axios.get(`/bills/${id}/documents`),
    download: (id, documentId) => axios.get(`/bills/${id}/documents/${documentId}/download`, { responseType: 'arraybuffer' }),
    delete: (id, documentId) => axios.delete(`/bills/${id}/documents/${documentId}`),
  },
};

export const client = {
  create: payload => axios.post('/clients', payload),
  updateOne: (id, payload) => axios.patch(`/clients/${id}`, payload),
  findOne: id => axios.get(`/clients/${id}`),
};

export const costCode = {
  create: payload => axios.post('/cost-codes', payload),
  createMany: payload => axios.post('/cost-codes/bulk', payload),
  updateOne: (id, payload) => axios.patch(`/cost-codes/${id}`, payload),
  findOne: id => axios.get(`/cost-codes/${id}`),
  deleteOne: id => axios.delete(`/cost-codes/${id}`),
};

export const costCodeCategory = {
  create: payload => axios.post('/cost-code-categories', payload),
  // update: (id, payload) => axios.patch(`/cost-code-categories/${id}`, payload),
  findOne: id => axios.get(`/cost-code-categories/${id}`),
};

export const auditLog = {
  bill: {
    findMany: id => axios.get(`/audit-logs/bills/${id}`),
  },
};

export const activityCategory = {
  create: payload => axios.post('/activity-categories', payload),
  findOne: id => axios.get(`/activity-categories/${id}`),
  updateOne: (id, payload) => axios.patch(`/activity-categories/${id}`, payload),
  deleteOne: id => axios.delete(`/activity-categories/${id}`),
};

export const subscription = {
  updateOne: (id, payload) => axios.patch(`/subscriptions/${id}`, payload),
};

export const stripe = {
  setupIntent: {
    create: payload => axios.post('/stripe/setup-intents', payload),
  },
  portalSession: {
    create: payload => axios.post('/stripe/portal-sessions', payload),
  },
};

export const rule = {
  create: payload => axios.post('/rules', payload),
};

export const changeOrder = {
  create: payload => axios.post('/change-orders', payload),
  findOne: id => axios.get(`/change-orders/${id}`),
  updateOne: (id, payload) => axios.patch(`/change-orders/${id}`, payload),
  deleteOne: id => axios.delete(`/change-orders/${id}`),
};
