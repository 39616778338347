<template>
  <BModal :active="isOpen" hasModalCard :width="600" @close="$emit('close')">
    <form @submit.prevent="handleSubmitCreateChangeOrder">
      <div class="modal-card">
        <div class="modal-card-head">
          <p class="modal-card-title">
            {{ !activeChangeOrderId ? $t('common.ctas.createChangeOrder') : `${$t('common.ctas.updateChangeOrder')} ${activeChangeOrder.name}` }}
          </p>
        </div>
        <div class="modal-card-body">
          <BField v-if="formSubmissionError">
            <BMessage type="is-danger">
              {{  formSubmissionError.message }}
            </BMessage>
          </BField>
          <BField
            v-for="formField in Object.values(createChangeOrderForm.fields)"
            :key="formField.key"
            :class="['column', { 'required': formField.required }]"
            :label="$t(formField.label)"
            :type="createChangeOrderForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
            :message="createChangeOrderForm.formErrors[$t(formField.name)] || ''"
          >
            <BInput
              v-model="formField.value"
              :disabled="createChangeOrderForm.isFormSubmissionInProgress"
              :placeholder="$t(formField.placeholder)"
              :ref="formField.ref"
              @input="handleTouchForm"
            />
          </BField>
        </div>
        <footer class="modal-card-foot">
          <BButton nativeType="submit" type="is-primary" expanded :loading="createChangeOrderForm.isFormSubmissionInProgress">
            {{ !activeChangeOrderId ? $t('common.ctas.createChangeOrder') : $t('common.ctas.updateChangeOrder') }}
          </BButton>
        </footer>
      </div>
    </form>
  </BModal>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import createChangeOrderForm from '@/forms/createChangeOrder.form';
import * as ChangeOrderActions from '@/store/actions/ChangeOrder.actions';
import * as ValidationService from '@/services/Validation.service';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'CreateOrEditVendorModal',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    initialChangeOrderName: {
      type: String,
      required: false,
      default: '',
    },
    activeChangeOrderId: {
      type: String,
      required: false,
      default: null,
    },
    projectId: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: [
    'submitSuccess',
    'close',
  ],

  data() {
    return {
      createChangeOrderForm: cloneDeep(createChangeOrderForm),
      formSubmissionError: null,
    };
  },

  computed: {
    ...mapState({
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    activeChangeOrder() {
      return this.activeChangeOrderId ? this.changeOrders[this.activeChangeOrderId] : null;
    },
  },

  created() {
    this.setInitialFormState();
  },

  watch: {
    isOpen(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.setInitialFormState();
      }
    },
  },

  methods: {
    setInitialFormState() {
      if (this.activeChangeOrderId && this.activeChangeOrder) {
        const { name } = this.activeChangeOrder;

        this.createChangeOrderForm.fields.name.value = name;
      } else {
        this.createChangeOrderForm.fields.name.value = this.initialChangeOrderName;
      }

      // Set focus on first form field. We use nextTick to make sure the DOM is rendered and the element exists.
      this.$nextTick(() => {
        if (this.$refs[this.createChangeOrderForm.fields.name.ref]) {
          this.$refs[this.createChangeOrderForm.fields.name.ref][0].focus();
          // This click method is the only way to ensure we draw focus away from autocomplete dropdowns.
          // Since autocomplete dropdowns are sometimes rendered in the BODY they will sit on top of our modals,
          // and we want to avoid that. An example is when this modal is used inside the `CreateCostModal`
          // via the vendor autocomplete in that component.
          this.$refs[this.createChangeOrderForm.fields.name.ref][0].$el.click();
        }
      });
    },
    async handleSubmitCreateChangeOrder() {
      this.formSubmissionError = null;
      this.createChangeOrderForm.formErrors = {};
      this.createChangeOrderForm.formErrors = ValidationService.runFormValidation({ ...this.createChangeOrderForm.fields }, { i18n: true });

      if (!isObjectEmpty(this.createChangeOrderForm.formErrors)) return;

      this.createChangeOrderForm.isFormSubmissionInProgress = true;
      const { fields } = this.createChangeOrderForm;
      try {
        const payload = {
          name: fields.name.value,
          ...this.projectId && { projectId: this.projectId },
        };
        let changeOrderId;

        if (this.activeChangeOrderId) {
          changeOrderId = await this.$store.dispatch(
            ChangeOrderActions.UPDATE_CHANGE_ORDER_BY_ID,
            { id: this.activeChangeOrderId, updatedChangeOrder: { ...payload } },
          );

          NotificationService.showNotification(NotificationTypes.UPDATE_CHANGE_ORDER.SUCCESS);
        } else {
          changeOrderId = await this.$store.dispatch(
            ChangeOrderActions.CREATE_CHANGE_ORDER,
            { newChangeOrder: { ...payload } },
          );

          NotificationService.showNotification(NotificationTypes.CREATE_CHANGE_ORDER.SUCCESS);
        }
        this.createChangeOrderForm.isFormSubmissionInProgress = false;
        this.$emit('submitSuccess', changeOrderId);
      } catch (err) {
        console.log(err);
        this.formSubmissionError = err;
        this.createChangeOrderForm.isFormSubmissionInProgress = false;
      }
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
