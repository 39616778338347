import { normalize } from 'normalizr';
import * as CommentMutations from '@/store/mutations/Comment.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as CommentActions from '@/store/actions/Comment.actions';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  comments: {},
});

const receiveComments = (state, payload) => {
  state.comments = {
    ...state.comments,
    ...payload.comments,
  };

  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  getCommentsByResourceId(state) {
    return resourceId => Object.values(state.comments).filter(comment => comment.resourceId === resourceId);
  },
};

const mutations = {
  [CommentMutations.COMMENT_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [CommentMutations.COMMENT_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [CommentMutations.COMMENT_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [CommentMutations.RECEIVE_COMMENTS]: receiveComments,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveComments,
  [UserMutations.RECEIVE_USERS]: receiveComments,
};

const actions = {
  [CommentActions.FETCH_COMMENTS_BY_RESOURCE_ID]: async (context, payload) => {
    context.commit(CommentMutations.COMMENT_REQUEST_DATA);

    const { resourceType, resourceId } = payload;

    try {
      const { data: { comments } } = await Api[resourceType].comment.findMany(resourceId);

      const normalizedComments = normalize(comments, [schemas.comment]);
      context.commit(CommentMutations.RECEIVE_COMMENTS, { ...normalizedComments.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
  [CommentActions.CREATE_COMMENT]: async (context, payload) => {
    context.commit(CommentMutations.COMMENT_REQUEST_DATA);

    const { resourceType, resourceId, content } = payload;

    try {
      const { data: { comment } } = await Api[resourceType].comment.create(resourceId, { content });

      const normalizedComments = normalize(comment, schemas.comment);
      context.commit(CommentMutations.RECEIVE_COMMENTS, { ...normalizedComments.entities });

      return comment.id;
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
