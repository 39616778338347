<template>
  <div
    :class="{
      'comment-wrapper': true,
      'is-flex': true,
      'comment-wrapper--highlighted': isHighlighted,
      [customClass]: customClass,
    }"
  >
    <UserAvatar :user="commenter" size="small" />
    <div class="u-m-l-small">
      <div class="is-flex is-align-items-flex-end">
        <UserDisplayName :activeUserId="commenter.id" />
        <small class="u-m-l-xsmall">
          {{ new Date(comment.createdAt).toLocaleDateString($i18n.locale, {}) }}
          {{ new Date(comment.createdAt).toLocaleTimeString($i18n.locale, {}) }}
        </small>
      </div>
      <p>
        {{ comment.content }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserAvatar from '@/components/UserAvatar.vue';
import UserDisplayName from '@/components/UserDisplayName.vue';

export default {
  name: 'Comment',

  components: {
    UserAvatar,
    UserDisplayName,
  },

  props: {
    commentId: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
      default: undefined,
    },
    isHighlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapState({
      comments: state => state.comment.comments,
      users: state => state.user.users,
    }),
    comment() {
      return this.comments[this.commentId];
    },
    commenter() {
      return this.users[this.comment.commenterUserId];
    },
  },
};
</script>

<style lang="scss" scoped>
  .comment-wrapper {
    transition: background-color 1s;

    &--highlighted {
      background-color: lighten($primary, 30%);
    }
  }
</style>
