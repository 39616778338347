import { normalize } from 'normalizr';
import * as RoleActions from '@/store/actions/Role.actions';
import * as RoleMutations from '@/store/mutations/Role.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';
import {
  atLeastProjectManagerRoles,
  atLeastProjectAssistantRoles,
  atLeastAdminRoles,
  atLeastOwnerRoles,
  systemRoleNames,
} from '@/constants/Role.constants';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  roles: {},
});

const receiveRoles = (state, payload) => {
  state.roles = {
    ...state.roles,
    ...payload.roles,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  getSystemRoleByName(state) {
    return name => (
      Object.values(state.roles)
        .filter(r => r.isSystemRole && r.name === name)[0]
    );
  },
  getAvailableSystemRoles(state) {
    return Object.values(state.roles)
      .filter(r => r.name !== systemRoleNames.superadmin && r.name !== systemRoleNames.owner);
  },
  doesUserHaveAnyRoles(state, getters, rootState) {
    return (userId, roles) => {
      const user = rootState.user.users[userId];
      const userRoleIds = user?.roles;

      const roleIds = (roles ?? [])
        .filter(r => !!getters.getSystemRoleByName(r))
        .map(r => getters.getSystemRoleByName(r)?.id);

      const authorizedRoles = userRoleIds.filter(r => roleIds.includes(r));

      return authorizedRoles.length > 0;
    };
  },
  doesLoggedInUserHaveAnyRoles(state, getters, rootState) {
    return roles => getters.doesUserHaveAnyRoles(rootState.user?.loggedInUserId, roles);
  },
  isLoggedInUserAtLeastProjectManager(state, getters) {
    return getters.doesLoggedInUserHaveAnyRoles([...atLeastProjectManagerRoles]);
  },
  isLoggedInUserAtLeastProjectAssistant(state, getters) {
    return getters.doesLoggedInUserHaveAnyRoles([...atLeastProjectAssistantRoles]);
  },
  isLoggedInUserAtLeastAdmin(state, getters) {
    return getters.doesLoggedInUserHaveAnyRoles([...atLeastAdminRoles]);
  },
  isLoggedInUserAtLeastOwner(state, getters) {
    return getters.doesLoggedInUserHaveAnyRoles([...atLeastOwnerRoles]);
  },
};

const mutations = {
  [RoleMutations.ROLE_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [RoleMutations.ROLE_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [RoleMutations.ROLE_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [RoleMutations.RECEIVE_ROLES]: receiveRoles,
  [UserMutations.RECEIVE_USERS]: receiveRoles,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveRoles,
};

const actions = {
  [RoleActions.FETCH_ORGANIZATION_ROLES]: async (context, payload) => {
    context.commit(RoleMutations.ROLE_REQUEST_DATA);
    try {
      const {
        data: {
          roles,
        },
      } = await Api.organization.role.findMany(payload.organizationId);

      context.commit(RoleMutations.RECEIVE_ROLES, {
        ...normalize(roles, [schemas.role]).entities,
      });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data);
    }
  },
  // [RoleActions.CREATE_TAX]: async (context, payload) => {
  //   context.commit(RoleMutations.TAX_BEGIN_WRITE_DATA);

  //   try {
  //     const { data: { tax } } = await Api.tax.create(payload);
  //     const normalizedRole = normalize(tax, schemas.tax);
  //     context.commit(RoleMutations.RECEIVE_TAXES, {
  //       ...normalizedRole.entities,
  //     });

  //     return normalizedRole.result; // Created tax id.
  //   } catch (err) {
  //     console.error(err.response); // eslint-disable-line no-console
  //     throw new Error(err.response.data.message);
  //   }
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
