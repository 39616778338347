<template>
  <div class="card">
    <CreateCostModal
      v-if="isLoggedInUserAtLeastProjectManager && !isProjectArchived(activeProjectId)"
      class="u-m-b"
      :projectId="activeProjectId"
      :isOpen="isCreateCostModalOpen"
      @close="handleCloseCreateCostModal"
      @submitSuccess="handleCreateCostSuccess"
    />
    <header class="card-header">
      <p class="card-header-title has-text-weight-black is-size-5">
        {{ $t('projectCostsSummary.recentCosts') }}
      </p>
      <div class="card-header-icon">
        <BButton
          v-if="isLoggedInUserAtLeastProjectManager && !isProjectArchived(activeProjectId)"
          size="is-small"
          iconLeft="plus-thick"
          type="is-primary is-outlined"
          @click.prevent="handleOpenCreateCostModal"
          @keyup.enter.prevent="handleOpenCreateCostModal"
        >
          {{ $t('common.ctas.createCost') }}
        </BButton>
      </div>
    </header>
    <div class="card-content is-paddingless">
      <BTable
        :data="latestThreeCosts"
        hoverable
        @click="handleClickRow"
        @keyup.enter="handleClickRow"
        :class="{ card: true, 'is-empty-table': latestThreeCosts.length == 0 }"
        style="border-radius: 0; box-shadow: none;"
        :mobileCards="false"
        detailed
        :showDetailIcon="false"
        :rowClass="handleRowClass"
        :defaultSortDirection="'desc'"
        :defaultSort="['createdAt', 'desc']"
        :showHeader="false"
      >
        <template slot="empty">
          <div class="has-text-grey u-p-a has-text-centered">
            {{ $t('projectDetailsView.costsEmptyState') }}
          </div>
        </template>
        <BTableColumn
          field="name"
          :label="$t('common.terms.name')"
          cell-class="vertical-align-middle u-p-l"
          header-class="u-p-l"
          sortable
          v-slot="props"
        >
          <div>
            <p class="has-text-weight-bold is-size-5">{{ props.row.name }}</p>
            <p class="is-italic">
              <span v-if="props.row.vendor.name">
                <BIcon icon="account-hard-hat" size="is-small" />
                {{ props.row.vendor.name }}
              </span>
              <span v-else class="has-text-grey">{{ $t('common.terms.noVendor') }}</span>
            </p>
          </div>
        </BTableColumn>
        <!-- <BTableColumn
          :label="$t('common.terms.contracted')"
          cell-class="vertical-align-middle u-p-l"
          header-class="u-p-l"
          v-slot="props"
          sortable
        >
          {{ displayCurrency(getCostTotalActivityBudget(props.row.id) || 0, { prefix: '$', multiplier: 100 }) }}
        </BTableColumn>
        <BTableColumn
          :label="$t('common.terms.completed')"
          cell-class="vertical-align-middle u-p-l"
          header-class="u-p-l"
          v-slot="props"
          sortable
        >
          {{ displayCurrency(getCostTotalAdvanceAmount(props.row.id) || 0, { prefix: '$', multiplier: 100 }) }}
        </BTableColumn>
        <BTableColumn
          :label="$t('common.terms.remainingBalance')"
          cell-class="vertical-align-middle u-p-l"
          header-class="u-p-l"
          v-slot="props"
          sortable
        >
          {{ displayCurrency(getCostRemainingBalance(props.row.id) || 0, { prefix: '$', multiplier: 100 }) }}
        </BTableColumn> -->
        <BTableColumn
          :label="$t('common.terms.costType')"
          cell-class="vertical-align-middle u-p-l"
          v-slot="props"
        >
          <div><CostTypeTag :costTypeId="props.row.costTypeId" /></div>
        </BTableColumn>
        <!-- <BTableColumn
          field="progress"
          :label="$t('common.terms.progress')"
          cell-class="vertical-align-middle"
          width="120"
          centered
        >
        <template v-slot="props">
          <vc-donut
            :sections="[{
              value: (getCostTotalAdvancePercent(props.row.id) * 100) <= 100 ? (getCostTotalAdvancePercent(props.row.id) * 100) : 0,
              color: '#5e72e4',
            }]"
            :size="50"
            :thickness="20"
            :auto-adjust-text-size="false"
          >
            <p class="has-text-weight-bold is-size-7">
              {{ Math.round(convertFractionToPercent(getCostTotalAdvancePercent(props.row.id))) }}%
            </p>
          </vc-donut>
        </template>
      </BTableColumn> -->
      </BTable>
    </div>
    <footer class="card-footer">
      <div class="card-footer-item">
        <router-link :to="`/projects/${activeProjectId}/costs`">
          {{ $t('common.ctas.viewAll.masculine') }}
        </router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { convertFractionToPercent } from '../helpers/numberHelpers';
import { displayCurrency } from '../helpers/stringHelpers';
import CostTypeTag from './CostTypeTag.vue';
import CreateCostModal from './CreateCostModal.vue';

export default {
  name: 'ProjectCostsSummary',

  components: {
    CostTypeTag,
    CreateCostModal,
  },

  props: {
    activeProjectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isCreateCostModalOpen: false,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      costs: state => state.cost.costs,
      vendors: state => state.vendor.vendors,
      costTypes: state => state.costType.costTypes,
    }),
    ...mapGetters([
      'getCostsByProjectId',
      'getCostTotalAdvanceAmount',
      'getCostTotalAdvancePercent',
      'getCostTotalActivityBudget',
      'getCostTotalClosedAmount',
      'getCostRemainingBalance',
      'isLoggedInUserAtLeastProjectManager',
      'isProjectArchived',
    ]),
    costsTableData() {
      return this.getCostsByProjectId(this.activeProjectId)
        .map(cost => ({ ...cost, costType: this.costTypes[cost.costTypeId], vendor: this.vendors[cost.vendorId] || {} }));
    },
    sortedCostsTableData() {
      return [...this.costsTableData].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    },
    latestThreeCosts() {
      return [...this.sortedCostsTableData].slice(0, 3);
    },
  },

  methods: {
    convertFractionToPercent,
    displayCurrency,
    handleRowClass() {
      return 'has-cursor-pointer';
    },
    handleClickRow(row) {
      this.$router.push(`/projects/${this.activeProjectId}/costs/${row.id}/details`);
    },
    handleOpenCreateCostModal() {
      this.isCreateCostModalOpen = true;
    },
    handleCloseCreateCostModal() {
      this.isCreateCostModalOpen = false;
    },
    handleCreateCostSuccess() {
      this.isCreateCostModalOpen = false;
    },
  },
};
</script>
