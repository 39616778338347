import { baseForm, baseField } from '@/forms/base.form';

const createBudgetForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'createBudgetForm.name.label',
      name: 'createBudgetForm.name.name',
      placeholder: 'createBudgetForm.name.placeholder',
      value: '',
      required: true,
      width: 'is-4',
    },
    description: {
      ...baseField,
      label: 'createBudgetForm.description.label',
      name: 'createBudgetForm.description.name',
      placeholder: 'createBudgetForm.description.placeholder',
      value: '',
      required: false,
      width: 'is-5',
    },
  },
};

export default createBudgetForm;
