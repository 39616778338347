export const LOG_IN_WITH_EMAIL_AND_PASSWORD = 'logInWithEmailAndPassword';
export const LOG_IN_WITH_EXISTING_SESSION_TOKEN = 'logInWithExistingSessionToken';
export const LOG_OUT_USER = 'logOutUser';
export const CREATE_AUTH_USER_WITH_EMAIL_AND_PASSWORD = 'createUserWithEmailAndPassword';
export const LOG_IN_WITH_GOOGLE_AUTH = 'logInWithGoogleAuth';
export const SEND_EMAIL_VERIFICATION = 'sendEmailVerification';
export const APPLY_EMAIL_VERIFICATION_CODE = 'applyEmailVerificationCode';
export const SEND_PASSWORD_RESET_EMAIL = 'sendPasswordResetEmail';
export const VERIFY_PASSWORD_RESET_CODE = 'verifyPasswordResetCode';
export const CONFIRM_PASSWORD_RESET = 'confirmPasswordReset';
