<template>
  <BTable
    :data="tableData"
    :loading="isLoading"
    :total="total"
    paginated
    backend-pagination
    :perPage="pageSize"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    @page-change="page => { $emit('pageChange', page); }"
    hoverable
    :mobile-cards="false"
    ref="auditLogsTable"
    class="card u-p-b"
    :defaultSortDirection="'desc'"
    :defaultSort="['createdAt']"
    narrowed
    scrollable
  >
    <BTableColumn
      field="creator"
      :label="$t('common.terms.user')"
      cellClass="vertical-align-middle"
      v-slot="props"
    >
      <div class="is-flex is-align-items-center">
        <UserAvatar :user="users[props.row.actorUserId]" class="u-m-r-small" size="xsmall" isInline />
        <UserDisplayName :activeUserId="props.row.actorUserId" />
      </div>
    </BTableColumn>
    <BTableColumn
      field="createdAt"
      :label="$t('common.terms.createdAt')"
      cellClass="vertical-align-middle"
      sortable
      v-slot="props"
    >
      {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale, {}) }}
      {{ new Date(props.row.createdAt).toLocaleTimeString($i18n.locale, {}) }}
    </BTableColumn>
    <BTableColumn
      field="actionType"
      :label="$t('common.terms.action')"
      cellClass="vertical-align-middle"
      v-slot="props"
    >
      <BIcon :icon="getActionIcon(props.row.actionType)" class="u-m-r-xsmall" customSize="mdi-18px" />
      {{ $t(`auditLogActionTypes.${props.row.actionType}`) }}
    </BTableColumn>
    <BTableColumn
      field="resourceType"
      :label="$t('common.terms.resourceType')"
      cellClass="vertical-align-middle"
      v-slot="props"
    >
      {{ $t(`common.terms.${props.row.resourceType}`) }}
    </BTableColumn>
    <BTableColumn
      field="resourceName"
      :label="$t('common.terms.resourceName')"
      cellClass="vertical-align-middle"
      v-slot="props"
    >
      {{ getResourceName(props.row, props.row.resourceType, props.row.actionType) }}
    </BTableColumn>
  </BTable>
</template>

<script>
import { mapState } from 'vuex';
import UserDisplayName from '@/components/UserDisplayName.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import { shapeUserDisplayName } from '@/helpers/userHelpers';

export default {
  name: 'AuditLogsTable',

  components: {
    UserDisplayName,
    UserAvatar,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 20,
    },
    tableData: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState({
      users: state => state.user.users,
      auditLogs: state => state.auditLog.auditLogs,
    }),
  },

  methods: {
    getActionIcon(actionType) {
      let icon;
      switch (actionType) {
      case 'create':
        icon = 'plus-thick';
        break;
      case 'read':
        icon = 'eye';
        break;
      case 'update':
        icon = 'refresh';
        break;
      case 'delete':
        icon = 'delete';
        break;
      default:
        icon = '';
      }

      return icon;
    },
    getResourceName(resource, resourceType, actionType) {
      let resourceState;
      let name;

      if (actionType === 'read' || actionType === 'update' || actionType === 'create') resourceState = resource.currentState;
      if (actionType === 'delete') resourceState = resource.previousState;

      if (
        resourceType === 'budget'
        || resourceType === 'cost'
        || resourceType === 'activity'
        || resourceType === 'project'
        || resourceType === 'client'
        || resourceType === 'organization'
        || resourceType === 'vendor'
      ) {
        name = resourceState?.name ?? '';
      }

      if (resourceType === 'invitation') name = resourceState.user ? shapeUserDisplayName(resourceState.user) : 'N/A';

      if (resourceType === 'user') name = shapeUserDisplayName(resourceState);

      if (resourceType === 'bill') name = `${resourceState.number}${resourceState.name ? ' ' : ''}${resourceState.name || ''}`;

      if (resourceType === 'costCode') {
        name = `
          ${resourceState.costCodeCategory ? resourceState.costCodeCategory.name : ''}
          ${resourceState.code}
          ${resourceState.name || ''}
        `;
      }

      return name;
    },
  },
};
</script>
