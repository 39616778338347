<template>
  <div class="is-flex is-align-items-center">
    <ConfirmActionModal :ref="`document-link__confirm-delete__${documentId}`">
      <p>
        <BIcon icon="alert" size="is-large" type="is-danger" />
      </p>
      <p class="is-size-5 u-m-t-small">
        {{ $t('confirmDeleteDocumentModal.bodyText', { documentName: shapeDocumentDisplayName(activeDocument.fileName)}) }}
      </p>
    </ConfirmActionModal>
    <a
      class="is-primary"
      :href="doesLinkToViewer ? `/${resourceType}/${resourceId}/documents/${documentId}/` : null"
      :target="doesLinkToViewer ? '_blank' : null"
      :role="!doesLinkToViewer ? 'button' : null"
      v-on="!doesLinkToViewer ? { click: handleDownloadFile, keyup: handleDownloadFile } : {}"
      tabindex="0"
    >
      <BIcon icon="file" size="is-small" /> {{ shapeDocumentDisplayName(activeDocument.fileName) }}
    </a>
    <BButton
      type="is-text"
      size="is-small"
      class="document-link__delete-button"
      :loading="isDeleting"
      :disabled="isDeleting"
      @click="handleDeleteDocument"
    >
      <BIcon icon="close" customSize="mdi-18px" />
    </BButton>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as Api from '@/services/Api.service';
import * as DocumentConstants from '@/constants/Document.constants';
import * as DocumentActions from '@/store/actions/Document.actions';
import ConfirmActionModal from '@/components/ConfirmActionModal.vue';
import { getFileExtension, isImageFile, shapeDocumentDisplayName } from '@/helpers/documentHelpers';

export default {
  name: 'DocumentLink',

  components: {
    ConfirmActionModal,
  },

  props: {
    documentId: {
      type: String,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
    resourceId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isDeleting: false,
    };
  },

  computed: {
    ...mapState({
      documents: state => state.document.documents,
    }),
    activeDocument() {
      return this.documents[this.documentId];
    },
    doesLinkToViewer() {
      const { fileName } = this.activeDocument;
      const shapedName = shapeDocumentDisplayName(fileName);
      const extension = getFileExtension(shapedName);

      return isImageFile(extension);
    },
  },

  methods: {
    shapeDocumentDisplayName,
    async handleDownloadFile(event) {
      if (event.type === 'keyup' && event.which !== 13) return;

      const file = await Api[this.resourceType].document.download(this.resourceId, this.documentId);
      const { fileName } = this.activeDocument;
      const shapedName = shapeDocumentDisplayName(fileName);
      const extension = getFileExtension(shapedName);
      const data = Buffer.from(file.data).toString('base64');
      const mimeType = DocumentConstants.fileMimeTypes[extension];
      const href = `data:${mimeType};base64, ${data}`;

      if (isImageFile(extension)) {
        this.documentData = href;
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.download = shapedName;
        a.href = href;
        a.click();
      }
    },
    async handleDeleteDocument() {
      this.isDeleting = true;

      const confirmed = await this.showDeleteDocumentConfirmationModal();
      if (!confirmed) {
        this.isDeleting = false;
        return;
      }

      await this.$store.dispatch(DocumentActions.DELETE_DOCUMENT_BY_ID, {
        resourceType: this.resourceType,
        resourceId: this.resourceId,
        documentId: this.documentId,
      });

      this.isDeleting = false;
    },
    async showDeleteDocumentConfirmationModal() {
      const confirmed = await this.$refs[`document-link__confirm-delete__${this.documentId}`].show({
        title: this.$t('confirmDeleteDocumentModal.title', { documentName: this.shapeDocumentDisplayName(this.activeDocument.fileName) }),
        confirmButton: this.$t('confirmDeleteDocumentModal.confirmCta'),
        cancelButton: this.$t('confirmDeleteDocumentModal.cancelCta'),
      });

      return confirmed;
    },
  },
};
</script>

<style lang="scss">
  .document-link__delete-button {
    color: $danger !important;
    background-color: transparent !important;
    height: 1.5em !important;
    margin-left: $global-whitespace-xsmall;
    margin-top: -2px;

    &:hover {
      background-color: transparent !important;
      color: darken($danger, 20%) !important;
    }

    &.is-loading {
      &:after {
        border: 2px $danger solid !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
      }
    }
  }
</style>
