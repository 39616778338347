<template>
  <div class="app-page__container">
    <div v-if="!isLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.terms.reports')}}</h4>
        </div>
      </section>

      <div class="app-page__section">
        <div class="columns is-multiline">
          <div v-for="report in reports" class="column is-12-mobile is-6-tablet is-4-desktop is-3-hd" :key="report.path">
            <div class="card reports__report-card" role="button" @click="() => handleClickReport(report.path)">
              <div class="card-content has-text-weight-bold u-p-b-0 is-size-5">
                {{ $t(report.title) }}
              </div>
              <div class="card-content">
                {{ $t(report.description) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reports',

  data() {
    return {
      isLoading: false,
      reports: [
        {
          title: 'reportsView.reports.activitiesByCostCode.title',
          description: 'reportsView.reports.activitiesByCostCode.description',
          path: '/reports/activities/by-cost-code',
        },
      ],
    };
  },

  methods: {
    handleClickReport(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.reports__report-card {
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50,50,93,.08), 0 3px 6px rgba(0,0,0,.06);
    transition: all .1s ease;
  }
}

.reports__coming-soon__container {
  border-radius: $radius-2xlarge;
  border: 1px solid $primary;
  background: $grey-lighter;
}
</style>
