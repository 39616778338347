<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
        {{ $t('common.notifications.archivedProject') }}
      </BMessage>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">
            {{ $t('common.terms.budgets') }}
          </h4>
          <p class="subtitle has-text-grey has-text-weight-bold is-size-5">{{ activeProject.name }}</p>
        </div>
        <div class="app-page__header__action" style="width: 33%;">
          <BField :label="$t('projectBudgetsView.activeProjectBudget')" style="width: 100%;">
            <BSelect
              placeholder="Select a budget"
              :value="activeBudgetId"
              @input="handleActiveBudgetChange"
              :loading="isActiveBudgetChangeInProgress"
              :key="forceSelectRerenderKey"
              expanded
              :disabled="isProjectArchived(activeProject.id)"
            >
              <option
                v-for="option in getBudgetsByProjectId(activeProjectId)"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </BSelect>
          </BField>
        </div>
      </section>
      <section class="app-page__section">
        <div v-if="activeBudget" class="card">
          <header class="card-header">
            <p class="card-header-title u-p-b-0 is-size-5">
              <span class="has-text-grey">{{ $t('common.terms.budgeted') }}</span>
            </p>
          </header>
          <header class="card-header">
            <p class="card-header-title u-p-t-0 has-text-grey">
              <span class="is-size-6">{{ activeBudget.name }}</span>
              <span v-if="activeBudget.description" class="is-size-6 is-italic has-text-weight-normal u-m-l-xsmall">
                --&nbsp;{{ activeBudget.description }}
              </span>
            </p>
          </header>
          <div class="card-content u-p-t-0">
            <h4 class="title is-4">
              <animated-number
                :value="activeBudgetTotal"
                :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                :duration="400"
              />
            </h4>
          </div>
        </div>
        <div v-else class="card">
          <div class="card-content">
            <p class="has-text-centered has-text-weight-bold is-size-5 has-text-grey">
              {{ $t('projectBudgetsView.activeBudgetEmptyState') }}
            </p>
          </div>
        </div>
      </section>
      <section class="app-page__section">
        <div class="app-page__header">
          <div class="app-page__header__title">
            <div style="display: flex; align-items: baseline">
              <h5 class="title is-5 has-text-grey u-m-b-0">
                {{ $t('projectBudgetsView.otherBudgets') }}
              </h5>
            </div>
          </div>
        </div>
        <CreateBudgetCard v-if="!isProjectArchived(activeProject.id)" :projectId="activeProjectId" />
      </section>
      <section class="app-page__section">
        <ProjectBudgetsTable :projectId="activeProjectId" />
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AnimatedNumber from 'animated-number-vue';
import * as ProjectActions from '@/store/actions/Project.actions';
import * as BudgetActions from '@/store/actions/Budget.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { displayCurrency } from '@/helpers/stringHelpers';
import CreateBudgetCard from '@/components/CreateBudgetCard.vue';
import ProjectBudgetsTable from '@/components/ProjectBudgetsTable.vue';

export default {
  name: 'ProjectBudgets',

  components: {
    ProjectBudgetsTable,
    CreateBudgetCard,
    AnimatedNumber,
  },

  data() {
    return {
      isPageLoading: true,
      isActiveBudgetChangeInProgress: false,
      forceSelectRerenderKey: 0,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      budgets: state => state.budget.budgets,
    }),
    ...mapGetters([
      'getTotalBudgetValue',
      'getBudgetsByProjectId',
      'isProjectArchived',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    activeBudgetId() {
      return this.activeProject.activeBudgetId;
    },
    activeBudget() {
      return this.budgets[this.activeBudgetId];
    },
    activeBudgetTotal() {
      return this.getTotalBudgetValue(this.activeBudget?.id);
    },
  },

  created() {
    this.fetchinitialData();
  },

  methods: {
    displayCurrency,
    async fetchinitialData() {
      this.isPageLoading = true;
      try {
        await Promise.all([
          this.$store.dispatch(ProjectActions.FETCH_PROJECT_BY_ID, { projectId: this.$route.params.projectId }),
          this.$store.dispatch(BudgetActions.FETCH_PROJECT_BUDGETS, { projectId: this.$route.params.projectId }),
        ]);
      } catch {
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isPageLoading = false;
    },
    async handleActiveBudgetChange(newActiveBudget) {
      this.isActiveBudgetChangeInProgress = true;
      try {
        await this.$store.dispatch(
          ProjectActions.EDIT_PROJECT,
          { projectId: this.activeProjectId, updatedProject: { activeBudgetId: newActiveBudget } },
        );
        await this.$store.dispatch(BudgetActions.FETCH_PROJECT_BUDGETS, { projectId: this.$route.params.projectId });
      } catch (err) {
        console.log(err);
        NotificationService.showNotification(NotificationTypes.UPDATE_PROJECT.ERROR);
        this.forceSelectRerenderKey += 1;
      }
      this.isActiveBudgetChangeInProgress = false;
    },
  },
};
</script>
