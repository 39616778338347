<template>
  <div>
    <div class="filters__wrapper">
      <p class="has-text-grey has-text-weight-bold">
        <BIcon icon="filter" size="is-small" />
        {{ $t('common.terms.filters') }}
      </p>
      <div class="column">
      <BDropdown
        multiple
        v-model="selectedBillStatusTypes"
      >
        <BButton slot="trigger" icon-right="chevron-down">
          {{ $t('common.terms.billStatus') }}
        </BButton>
        <BDropdownItem v-for="billStatus in billStatuses" :key="billStatus.id" :value="billStatus.id" class="filters__dropdown-item">
          <BCheckbox :value="selectedBillStatusTypes.includes(billStatus.id)" @click.native.prevent="() => {}">
            <BillStatusTag size="small" :billStatusId="billStatus.id" />
          </BCheckbox>
        </BDropdownItem>
        <hr class="dropdown-divider">
        <BDropdownItem @click="handleSelectAllBillStatusTypes">
          <span class="has-text-weight-bold">{{ $t('common.ctas.selectAll') }}</span>
        </BDropdownItem>
      </BDropdown>
      </div>
    </div>
    <BTable
      :data="filteredBillsTableData"
      hoverable
      @click="handleClickRow"
      @keyup.enter="handleClickRow"
      class="card"
      :mobile-cards="false"
      :row-class="handleRowClass"
      :default-sort-direction="'desc'"
      :default-sort="['number', 'desc']"
    >
      <template slot="empty">
        <div class="has-text-grey u-p-a has-text-centered">
          {{ $t('costDetailsView.bills.emptyState') }}
        </div>
      </template>
      <BTableColumn
        field="number"
        :label="$t('common.terms.number')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        v-slot="props"
      >
        {{ props.row.number }}
      </BTableColumn>
      <BTableColumn
        field="name"
        :label="$t('common.terms.name')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        v-slot="props"
      >
        <span v-if="props.row.name">{{ props.row.name }}</span>
        <span v-else class="has-text-grey">N/A</span>
      </BTableColumn>
      <BTableColumn
        field="totalValue"
        :label="$t('common.terms.value')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        v-slot="props"
      >
        {{ displayCurrency(props.row.totalValue, { prefix: '$', multiplier: 100 }) }}
      </BTableColumn>
      <BTableColumn
        field="status.name"
        :label="$t('common.terms.billStatus')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        v-slot="props"
      >
        <BillStatusTag :billStatusId="props.row.status.id" />
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.createdAt')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        v-slot="props"
        sortable
        field="createdAt"
      >
        {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale) }}
        {{ new Date(props.row.createdAt).toLocaleTimeString($i18n.locale) }}
      </BTableColumn>
    </BTable>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import BillStatusTag from '@/components/BillStatusTag.vue';

export default {
  name: 'CostBillsTable',

  components: {
    BillStatusTag,
  },

  props: {
    costId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedBillStatusTypes: [],
    };
  },

  computed: {
    ...mapState({
      costs: state => state.cost.costs,
      bills: state => state.bill.bills,
      billStatuses: state => state.billStatus.billStatuses,
    }),
    ...mapGetters([
      'getBillsByCostId',
      'getBillActivities',
      'getBillStatusDraft',
      'getBillTotalAfterTax',
    ]),
    billsTableData() {
      return this.getBillsByCostId(this.costId)
        .map(bill => ({
          ...bill,
          status: this.billStatuses[bill.statusId],
          totalValue: this.getBillTotalAfterTax(bill.id),
        }));
    },
    filteredBillsTableData() {
      return this.billsTableData.filter(bill => this.selectedBillStatusTypes.includes(bill.statusId));
    },
    activeCost() {
      return this.costs[this.costId];
    },
  },

  created() {
    this.selectedBillStatusTypes = Object.keys(this.billStatuses);
  },

  methods: {
    displayCurrency,
    addSuffix,
    convertFractionToPercent,
    handleRowClass() {
      return 'has-cursor-pointer';
    },
    handleSelectAllBillStatusTypes() {
      this.selectedBillStatusTypes = Object.keys(this.billStatuses);
    },
    calculateCostActivityTotal(costId) {
      return this.getActivitiesByCostId(costId)
        .reduce((acc, activity) => { // eslint-disable-line
          return acc + calculateActivityBudget((activity?.unitValue ?? 0), (activity?.quantity ?? 0));
        }, 0);
    },
    handleClickRow(row) {
      if (row.statusId === this.getBillStatusDraft.id) {
        this.$router.push(`/projects/${this.projectId}/costs/${this.costId}/bills/${row.id}/edit-draft`);
      } else {
        this.$router.push(`/bills/${row.id}`);
        // this.$router.push(`/projects/${this.projectId}/costs/${this.costId}/bills/${row.id}/details`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.costs-table__advance-column__header .th-wrap {
  text-align: center !important;
}

.filters__wrapper {
  display: flex;
  align-items: center;

  > * {
    margin-right: $global-whitespace-xsmall;
  }
}

.filters__dropdown-item {
  background-color: transparent !important;
  color: $dark !important;
}
</style>
