<template>
  <div>
  <div v-if="!isComponentLoading" class="card card--dark card--inset">
    <div class="card-content">
      <form @submit.prevent="handleSubmitCreateBudget">
        <BField v-if="formSubmissionError">
          <BMessage type="is-danger">
            {{  formSubmissionError.message }}
          </BMessage>
        </BField>
        <div class="columns">
          <BField
            v-for="formField in Object.values(createBudgetForm.fields)"
            :key="formField.key"
            :class="['column', { 'required': formField.required, [`${formField.width}`]: formField.width }]"
            :label="$t(formField.label)"
            :type="createBudgetForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
            :message="createBudgetForm.formErrors[$t(formField.name)] || ''"
          >
            <BInput
              :type="formField.type || 'text'"
              v-model="formField.value"
              :disabled="createBudgetForm.isFormSubmissionInProgress"
              :placeholder="$t(formField.placeholder)"
              @input="handleTouchForm"
              :ref="$t(formField.name)"
            />
          </BField>
          <BField class="column" style="margin-top: 26px;">
            <BButton
              type="is-primary"
              native-type="submit"
              :disabled="createBudgetForm.isFormSubmissionInProgress"
              :loading="createBudgetForm.isFormSubmissionInProgress"
            >
              {{ $t('common.ctas.createBudget') }}
            </BButton>
          </BField>
        </div>
      </form>
    </div>
  </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isComponentLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import * as BudgetActions from '@/store/actions/Budget.actions';
import * as ValidationService from '@/services/Validation.service';
import createBudgetForm from '@/forms/createBudget.form';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'CreateBudgetCard',

  props: {
    projectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isComponentLoading: false,
      createBudgetForm: cloneDeep(createBudgetForm),
      formSubmissionError: null,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
    }),
    ...mapGetters([
      'activeOrganization',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
  },

  methods: {
    handleTouchForm() {
      // console.log('form touched');
    },
    async handleSubmitCreateBudget() {
      this.formSubmissionError = null;
      this.createBudgetForm.formErrors = {};
      this.createBudgetForm.formErrors = ValidationService.runFormValidation({ ...this.createBudgetForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.createBudgetForm.formErrors)) return;

      this.createBudgetForm.isFormSubmissionInProgress = true;

      try {
        await this.$store.dispatch(
          BudgetActions.CREATE_BUDGET,
          {
            newBudget: {
              projectId: this.projectId,
              name: this.createBudgetForm.fields.name.value,
              description: this.createBudgetForm.fields.description.value,
              ...!this.activeProject.activeBudget && { activeOnProjectId: this.activeProjectId },
            },
          },
        );
        this.createBudgetForm = cloneDeep(createBudgetForm);
      } catch (err) {
        this.formSubmissionError = err;
      }

      this.createBudgetForm.isFormSubmissionInProgress = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
