import { normalize } from 'normalizr';
import * as ChangeOrderMutations from '@/store/mutations/ChangeOrder.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';
import * as CostMutations from '@/store/mutations/Cost.mutations';
import * as BudgetMutations from '@/store/mutations/Budget.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as ChangeOrderActions from '@/store/actions/ChangeOrder.actions';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  changeOrders: {},
});

const receiveChangeOrders = (state, payload) => {
  state.changeOrders = {
    ...state.changeOrders,
    ...payload.changeOrders,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const deleteChangeOrder = (state, payload) => {
  const updatedChangeOrders = { ...state.changeOrders };
  delete updatedChangeOrders[payload.changeOrderId];
  state.changeOrders = { ...updatedChangeOrders };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  getChangeOrdersByProjectId(state) {
    return projectId => Object.values(state.changeOrders)
      .filter(changeOrder => changeOrder.projectId === projectId);
  },
  getTotalChangeOrderValue(state, getters, rootState, rootGetters) {
    return changeOrderId => {
      const changeOrderActivities = Object.values(rootState.activity.activities)
        .filter(activity => activity.changeOrderId && activity.changeOrderId === changeOrderId);

      return changeOrderActivities.reduce((acc, activity) => (acc + rootGetters.getActivityChangeOrder(activity.id)), 0);
    };
  },
};

const mutations = {
  [ChangeOrderMutations.CHANGE_ORDER_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [ChangeOrderMutations.CHANGE_ORDER_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [ChangeOrderMutations.CHANGE_ORDER_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [ChangeOrderMutations.RECEIVE_CHANGE_ORDERS]: receiveChangeOrders,
  [ChangeOrderMutations.DELETE_CHANGE_ORDER]: deleteChangeOrder,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveChangeOrders,
  [BudgetMutations.RECEIVE_BUDGETS]: receiveChangeOrders,
  [CostMutations.RECEIVE_COSTS]: receiveChangeOrders,
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveChangeOrders,
  [UserMutations.RECEIVE_USERS]: receiveChangeOrders,
};

const actions = {
  [ChangeOrderActions.CREATE_CHANGE_ORDER]: async (context, payload) => {
    context.commit(ChangeOrderMutations.CHANGE_ORDER_BEGIN_WRITE_DATA);

    try {
      const { data: { changeOrder } } = await Api.changeOrder.create(payload.newChangeOrder);
      const normalizedChangeOrder = normalize(changeOrder, schemas.changeOrder);
      context.commit(ChangeOrderMutations.RECEIVE_CHANGE_ORDERS, {
        ...normalizedChangeOrder.entities,
      });

      return normalizedChangeOrder.result; // Created changeOrder id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  [ChangeOrderActions.FETCH_PROJECT_CHANGE_ORDERS]: async (context, payload) => {
    context.commit(ChangeOrderMutations.CHANGE_ORDER_REQUEST_DATA);

    try {
      const { projectId } = payload;
      const { data: { changeOrders } } = await Api.project.changeOrder.findMany(projectId);
      const normalizedChangeOrders = normalize(changeOrders, [schemas.changeOrder]);
      context.commit(ChangeOrderMutations.RECEIVE_CHANGE_ORDERS, { ...normalizedChangeOrders.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  [ChangeOrderActions.FETCH_CHANGE_ORDER_BY_ID]: async (context, payload) => {
    context.commit(ChangeOrderMutations.CHANGE_ORDER_REQUEST_DATA);

    try {
      const { changeOrderId } = payload;
      const { data: { changeOrder } } = await Api.changeOrder.findOne(changeOrderId);
      const normalizedChangeOrder = normalize(changeOrder, schemas.changeOrder);

      context.commit(ChangeOrderMutations.RECEIVE_CHANGE_ORDERS, { ...normalizedChangeOrder.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  [ChangeOrderActions.UPDATE_CHANGE_ORDER_BY_ID]: async (context, payload) => {
    context.commit(ChangeOrderMutations.CHANGE_ORDER_BEGIN_WRITE_DATA);

    try {
      const { id, updatedChangeOrder } = payload;

      const { data: { changeOrder } } = await Api.changeOrder.updateOne(id, updatedChangeOrder);

      const normalizedChangeOrder = normalize(changeOrder, schemas.changeOrder);
      context.commit(ChangeOrderMutations.RECEIVE_CHANGE_ORDERS, {
        ...normalizedChangeOrder.entities,
      });

      return normalizedChangeOrder.result; // Updated changeOrder id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  async [ChangeOrderActions.DELETE_CHANGE_ORDER_BY_ID](context, payload) {
    context.commit(ChangeOrderMutations.CHANGE_ORDER_BEGIN_WRITE_DATA);
    try {
      await Api.changeOrder.deleteOne(payload.changeOrderId);

      context.commit(ChangeOrderMutations.DELETE_CHANGE_ORDER, { changeOrderId: payload.changeOrderId });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
