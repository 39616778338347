<template>
  <BDropdown aria-role="list" v-model="$i18n.locale" :position="position" :mobileModal="false">
    <div slot="trigger" aria-role="button" class="button--reset is-small">
      <BIcon icon="earth" custom-size="mdi-18px" />&nbsp;
      <span class="locale-dropdown__locale-name">{{ $t('common.localeName', $i18n.locale) }}</span>
    </div>
    <BDropdownItem
      v-for="locale in $i18n.availableLocales"
      :value="locale"
      :key="locale.key"
      @click="handleSelectLocale(locale)"
      @keyup.enter="handleSelectLocale(locale)"
    >
      {{ $t('common.localeName', locale) }}
    </BDropdownItem>
  </BDropdown>
</template>

<script>
import * as Storage from '@/constants/Storage';

export default {
  name: 'LocaleSwitcher',

  props: {
    position: {
      type: String,
      required: false,
      default: 'is-bottom-right',
    },
  },

  methods: {
    handleSelectLocale(locale) {
      localStorage.setItem(Storage.selectedLocale, locale);
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss" scoped>
.locale-dropdown__locale-name {
  vertical-align: text-bottom;
}
</style>
