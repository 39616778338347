export const baseForm = {
  isFormSubmissionInProgress: false,
  formErrors: {},
  isTouched: false,
};

export const baseField = {
  label: '',
  name: '',
  value: '',
  required: false,
  disabled: false,
  type: 'text',
};

export const inputTypes = {
  text: 'text',
  date: 'date',
  number: 'number',
  select: 'select',
  checkbox: 'checkbox',
  radio: 'radio',
};
