<template>
  <div>
    <BModal
      :active="isPreviewModalOpen"
      hasModalCard
      @close="handleClosePreviewModal"
      trapFocus
      ariaModal
      :canCancel="true"
      fullScreen
    >
      <form @submit.prevent="handleSubmitProjectBills" class="modal-card">
        <!-- <div class="modal-card"> -->
          <div class="modal-card-head">
            <p class="modal-card-title">{{ $t('importProjectBillsView.billsPreview', { total: billsPreview.length })}}</p>
            <!-- <BButton type="is-text" size="is-small" @click="handleClosePreviewModal">
              <BIcon icon="close" />
            </BButton> -->
          </div>
          <div class="modal-card-body">
            <BField v-if="previewErrors.length > 0">
              <BMessage type="is-danger">
                <p v-for="error in previewErrors" :key="error.index">
                  {{ $t('importProjectBillsView.previewError', { lineNumber: error.index + 1 }) }}
                </p>
              </BMessage>
            </BField>
            <BField v-if="submissionError">
              <BMessage type="is-danger">
                {{ submissionError.message }}
              </BMessage>
            </BField>
            <BTable
              :data="billsPreview"
              hoverable
              striped
              narrowed
              class="card"
              :mobile-cards="false"
              :rowClass="getPreviewRowClass"
            >
              <BTableColumn
                field="name"
                :label="$t('importProjectBillsView.previewTable.columns.name')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                {{ props.row.name }}
              </BTableColumn>
              <BTableColumn
                field="vendor"
                :label="$t('importProjectBillsView.previewTable.columns.vendor')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                {{ fuzzyFindVendorByName(props.row.vendor) ? fuzzyFindVendorByName(props.row.vendor).name : props.row.vendor }}
                <BTag v-if="!fuzzyFindVendorByName(props.row.vendor)" type="is-success is-light">
                  &nbsp;
                  {{ $t('common.terms.new') }}
                </BTag>
              </BTableColumn>
              <BTableColumn
                :label="$t('importProjectBillsView.previewTable.columns.costCode')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                {{ props.row.costCodeId ? costCodes[props.row.costCodeId].code : 'N/A' }}
              </BTableColumn>
              <BTableColumn
                field="amount"
                :label="$t('importProjectBillsView.previewTable.columns.amount')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                <span :class="{ 'has-text-danger': Number(props.row.amount < 0)}">
                  {{ displayCurrency(Number(props.row.amount), { prefix: '$' }) }}
                </span>
              </BTableColumn>
              <BTableColumn
                :label="$t('importProjectBillsView.previewTable.columns.costType')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                <BSelect
                  v-model="props.row.costTypeId"
                  expanded
                >
                  <option v-for="costType in costTypeObjects" :key="costType.id" :value="costType.id">
                    {{ $t(`common.costTypes.${costType.name}`) }}
                  </option>
                </BSelect>
              </BTableColumn>
              <BTableColumn
                :label="$t('importProjectBillsView.previewTable.columns.billStatus')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                <BSelect
                  v-model="props.row.statusId"
                  expanded
                >
                  <option v-for="billStatus in billStatusObjects" :key="billStatus.id" :value="billStatus.id">
                    {{ $t(`common.billStatuses.${billStatus.name}`) }}
                  </option>
                </BSelect>
              </BTableColumn>
            </BTable>
          </div>
          <footer class="modal-card-foot">
            <div style="display: flex; width: 100%; justify-content: flex-end;">
              <div class="buttons">
                <BButton
                  type="light"
                  @click="handleClosePreviewModal"
                  @keyup.enter="handleClosePreviewModal"
                >
                  {{ $t('common.ctas.cancel') }}
                </BButton>
                <BButton
                  nativeType="submit"
                  type="is-primary"
                  :loading="isSubmissionInProgress"
                  size="is-large"
                  :disabled="previewErrors.length > 0"
                >
                  {{ $t('common.ctas.importProjectBills') }}
                </BButton>
              </div>
            </div>
          </footer>
        <!-- </div> -->
      </form>
    </BModal>
    <section class="app-page__header">
      <div class="app-page__header__title">
        <h4 class="title is-4">{{ $t('common.titles.importProjectBills') }}</h4>
      </div>
    </section>
    <section class="app-page__section">
      <div class="card u-m-b">
        <div class="card-content">
          <div>
            <h4 class="title is-5">
              1 -
              <span>
                {{ $t('importProjectBillsView.step1.subtitle') }}
              </span>
            </h4>
            <BField class="is-primary mt-2">
              <BButton icon-left="download" @click="handleDownloadTemplate">
              {{ $t('importProjectBillsView.ctas.downloadTemplate') }}
              </BButton>
            </BField>
          </div>
        </div>
      </div>
      <div class="card u-m-b">
        <div class="card-content">
          <div>
            <h4 class="title is-5">
              2 -
              <span>
                {{ $t('importProjectBillsView.step2.subtitle') }}
              </span>
            </h4>
            <p class="step__body">
              {{ $t('importProjectBillsView.step2.body') }}
            </p>
          </div>
        </div>
      </div>
      <div class="card u-m-b">
        <div class="card-content">
          <div>
            <h4 class="title is-5">
              3 -
              <span>
                {{ $t('importProjectBillsView.step3.subtitle') }}
              </span>
            </h4>
            <BField v-if="fileError">
              <BMessage type="is-danger">
                {{ fileError.message }}
              </BMessage>
            </BField>
            <BField>
              <BUpload v-model="file" dragDrop expanded>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <BIcon v-if="!isProcessingCsv" icon="upload" size="is-large" />
                      <span v-else style="min-height: 44px; display: block;">
                        <BLoading :isFullPage="false" :canCancel="false" :active="isProcessingCsv" />
                      </span>
                    </p>
                    <p>{{ $t('common.ctas.fileUpload') }}</p>
                  </div>
                </section>
              </BUpload>
            </BField>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Papaparse from 'papaparse';
import { saveAs } from 'file-saver';
import * as CostCodeActions from '@/store/actions/CostCode.actions';
import { camelCase, displayCurrency } from '@/helpers/stringHelpers';
import {
  costTypes,
  billStatusTypes,
} from '../constants/Types';

export default {
  name: 'ImportProjectBills',

  props: {
    submissionError: {
      type: Object,
      default: null,
    },
    isSubmissionInProgress: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'submit',
  ],

  data() {
    return {
      costTypes,
      file: null,
      fileError: null,
      isProcessingCsv: false,
      billsPreview: [],
      isPreviewModalOpen: false,
    };
  },

  watch: {
    async file() {
      if (!this.file) {
        return;
      }
      const acceptedFileTypes = [
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];

      if (!acceptedFileTypes.includes(this.file.type)) {
        this.fileError = { message: 'Incorrect file type, please upload a CSV' };
        this.file = null;
        return;
      }

      this.isProcessingCsv = true;
      await this.parseProjectBillsFile(this.file, this.showFilePreview);
      this.isProcessingCsv = false;
    },
  },

  computed: {
    ...mapState({
      costTypeObjects: state => state.costType.costTypes,
      billStatusObjects: state => state.billStatus.billStatuses,
      costCodes: state => state.costCode.costCodes,
    }),
    ...mapGetters([
      'fuzzyFindVendorByName',
      'getCostTypeByName',
      'getBillStatusByName',
      'activeOrganization',
      'fuzzyFindCostCodeByCode',
    ]),
    previewErrors() {
      return this.billsPreview
        .map((bill, index) => ({ ...bill, index }))
        .filter(bill => !bill.name || !bill.vendor || !bill.amount);
    },
  },

  methods: {
    displayCurrency,
    handleSubmitProjectBills() {
      this.$emit('submit', this.billsPreview);
    },
    handleDownloadTemplate() {
      const template = Papaparse.unparse({
        fields: [
          this.$t('importProjectBillsView.template.name').toUpperCase(),
          this.$t('importProjectBillsView.template.vendor').toUpperCase(),
          this.$t('importProjectBillsView.template.amount').toUpperCase(),
          this.$t('importProjectBillsView.template.costType').toUpperCase(),
          this.$t('importProjectBillsView.template.costCode').toUpperCase(),
          // this.$t('importProjectBillsView.template.unitValue').toUpperCase(),
        ],
        // data: [
        //   [
        //     this.$t('importProjectBillsView.template.codeExample'),
        //     this.$t('importProjectBillsView.template.nameExample'),
        //   ],
        // ],
      });
      const fileName = this.$t('importProjectBillsView.template.fileName');
      const file = new File([template], fileName, {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(file);
    },
    parseProjectBillsFile(file, callback) {
      return new Promise((resolve, reject) => {
        Papaparse.parse(file, {
          header: true,
          skipEmptyLines: true, // need for some reason last line is empty
          complete: parsed => resolve(callback(parsed)),
          error: error => reject(error),
        });
      });
    },
    async showFilePreview(parsedFile) {
      this.isProcessingCsv = false;
      const { data } = parsedFile;
      this.billsPreview = await this.shapeBillProperties(data);
      this.isPreviewModalOpen = true;
    },
    async shapeBillProperties(importedProjectBills) {
      const spanishKeys = {
        NOMBRE: 'NAME',
        PROVEEDOR: 'VENDOR',
        MONTO: 'AMOUNT',
        'CODIGO DE COSTO': 'COST CODE',
        'TIPO DE COSTO': 'COST TYPE',
      };

      const parsedBills = importedProjectBills.map(bill => (
        Object.entries(bill).reduce((acc, [key, value]) => ({
          ...acc,
          // This ternary will transform spanish to english keys so they can be sent to backend.
          // @TODO -- come up with solution that will support multiple languages in an agnostic fashion.
          [Object.keys(spanishKeys).includes(key) ? camelCase(spanishKeys[key]) : camelCase(key)]: value,
        }), {})
      ));

      for (let i = 0; i < parsedBills.length; i += 1) {
        const { costCode } = parsedBills[i];
        if (!costCode) {
          continue; // eslint-disable-line
        }

        await this.$store.dispatch(CostCodeActions.FETCH_ORGANIZATION_COST_CODES, { // eslint-disable-line
          organizationId: this.activeOrganization.id,
          params: { pageNumber: 1, pageSize: 1, search: parsedBills[i].costCode },
        });
      }
      const shapedBills = parsedBills.map(bill => {
        const {
          name,
          vendor,
          amount,
          costCode,
          costType,
        } = bill;
        // console.log(camelCase(costType));
        const costTypeSpanishToEnglish = {
          materiales: 'materials',
          manoDeObra: 'labor',
          equipo: 'equipment',
          contrato: 'contract',
          planilla: 'payroll',
          indirecto: 'indirect',
        };

        let translatedCostType = costType;
        if (Object.keys(costTypeSpanishToEnglish).includes(camelCase(costType))) {
          translatedCostType = costTypeSpanishToEnglish[camelCase(costType)];
        }

        let parsedAmount = amount;
        if (typeof amount === 'string') {
          parsedAmount = amount.replace(/$/g, ''); // remove dollar sign if present
          parsedAmount = amount.replace(/,/g, ''); // remove commas if present

          // replace parens to negative sign if present. Normal negative numbers with a (-) sign will just bypass this check.
          const isNegativeWithParens = parsedAmount.includes('(') && parsedAmount.includes(')');
          if (isNegativeWithParens) {
            parsedAmount = parsedAmount.replace(/\(/g, '-'); // replace opening parenthesis with negative sign
            parsedAmount = parsedAmount.replace(/\)/g, ''); // remove closing parenthesis
          }
          parsedAmount = parseFloat(parsedAmount);
        }

        return {
          name,
          vendor,
          amount: parsedAmount,
          costCodeId: this.fuzzyFindCostCodeByCode(costCode)?.id,
          costTypeId: this.getCostTypeByName(translatedCostType)?.id ?? this.getCostTypeByName(costTypes.materials).id,
          statusId: this.getBillStatusByName(billStatusTypes.approved).id,
        };
      });
      return shapedBills;
    },
    getPreviewRowClass(row) {
      const {
        name,
        vendor,
        amount,
      } = row;

      return !name || !vendor || !amount ? 'has-background-danger-light has-text-danger' : '';
    },
    clearFile() {
      this.file = null;
      this.billsPreview = [];
    },
    handleClosePreviewModal() {
      this.clearFile();
      this.isPreviewModalOpen = false;
    },
  },
};
</script>
