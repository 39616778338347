import { normalize } from 'normalizr';
import * as ProjectActions from '@/store/actions/Project.actions';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as CostMutations from '@/store/mutations/Cost.mutations';
import * as AuthMutations from '@/store/mutations/Auth.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as ClientMutations from '@/store/mutations/Client.mutations';
import * as BudgetMutations from '@/store/mutations/Budget.mutations';
import * as ChangeOrderMutations from '@/store/mutations/ChangeOrder.mutations';
import * as api from '@/services/Api.service';
import * as schemas from '@/store/schemas';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  projects: {},
  activeProjectCount: 0,
});

function receiveProjects(state, payload) {
  state.projects = {
    ...state.projects,
    ...payload.projects,
  };

  state.activeProjectCount = payload.activeProjectCount ?? 0;

  state.isFetchingData = false;
}

const state = getInitialState();

const getters = {
  getLoggedInUserProjects(state, getters, rootState, rootGetters) {
    const user = rootGetters.loggedInUser;
    if (rootGetters.isLoggedInUserAtLeastAdmin) {
      return Object.values(state.projects);
    }

    return Object.values(state.projects).filter(p => p.users && p.users.includes(user.id));
  },
  getProjectCostActivities(state, getters, rootState, rootGetters) {
    return projectId => rootGetters.getCostsByProjectId(projectId)
      .map(cost => rootGetters.getCostActivities(cost.id))
      .reduce((acc, activitiesArray) => ([...acc, ...activitiesArray]), []);
  },
  getProjectTotalActivityBudget(state, getters, rootState, rootGetters) {
    return projectId => (
      rootGetters.getCostsByProjectId(projectId)
        .map(cost => rootGetters.getCostTotalActivityBudget(cost.id))
        .reduce((total, costTotal) => total + costTotal, 0)
    );
  },
  getProjectTotalAdvanceAmount(state, getters, rootState, rootGetters) {
    return (projectId, options) => (
      rootGetters.getCostsByProjectId(projectId)
        .map(cost => rootGetters.getCostTotalAdvanceAmount(cost.id, options))
        .reduce((total, costTotal) => total + costTotal, 0)
    );
  },
  getProjectTotalAdvancePercent(state, getters) {
    return (projectId, options) => (
      getters.getProjectTotalAdvanceAmount(projectId, options) / getters.getProjectTotalActivityBudget(projectId)
    );
  },
  getProjectTotalDownPaymentAmount(state, getters, rootState, rootGetters) {
    return (projectId, options) => (
      rootGetters.getCostsByProjectId(projectId)
        .map(cost => rootGetters.getCostTotalDownPaymentAmount(cost.id, options))
        .reduce((total, costTotal) => total + costTotal, 0)
    );
  },
  getProjectTotalRetainageAmount(state, getters, rootState, rootGetters) {
    return (projectId, options) => (
      rootGetters.getCostsByProjectId(projectId)
        .map(cost => rootGetters.getCostTotalRetainageAmount(cost.id, options))
        .reduce((total, costTotal) => total + costTotal, 0)
    );
  },
  getProjectTotalDeductionAmount(state, getters, rootState, rootGetters) {
    return (projectId, options) => (
      rootGetters.getCostsByProjectId(projectId)
        .map(cost => rootGetters.getCostTotalDeductionAmount(cost.id, options))
        .reduce((total, costTotal) => total + costTotal, 0)
    );
  },
  getProjectTotalClosedAmount(state, getters, rootState, rootGetters) {
    return projectId => (
      rootGetters.getCostsByProjectId(projectId)
        .map(cost => rootGetters.getCostTotalClosedAmount(cost.id))
        .reduce((total, costTotal) => total + costTotal, 0)
    );
  },
  getProjectTotalClosedTaxAmount(state, getters, rootState, rootGetters) {
    return projectId => (
      rootGetters.getCostsByProjectId(projectId)
        .map(cost => rootGetters.getCostTotalClosedTaxAmount(cost.id))
        .reduce((total, costTotal) => total + costTotal, 0)
    );
  },
  getProjectTotalOpenTaxAmount(state, getters, rootState, rootGetters) {
    return projectId => (
      rootGetters.getCostsByProjectId(projectId)
        .map(cost => rootGetters.getCostTotalOpenTaxAmount(cost.id))
        .reduce((total, costTotal) => total + costTotal, 0)
    );
  },
  isProjectArchived(state, getters, rootState, rootGetters) {
    return projectId => state.projects[projectId].statusId === rootGetters.getProjectStatusByName('archived')?.id;
  },
};

const mutations = {
  [ProjectMutations.PROJECT_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [ProjectMutations.PROJECT_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [ProjectMutations.PROJECT_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [ProjectMutations.RECEIVE_PROJECTS]: receiveProjects,
  [UserMutations.RECEIVE_USERS]: receiveProjects,
  [CostMutations.RECEIVE_COSTS]: receiveProjects,
  [BillMutations.RECEIVE_BILLS]: receiveProjects,
  [ClientMutations.RECEIVE_CLIENTS]: receiveProjects,
  [BudgetMutations.RECEIVE_BUDGETS]: receiveProjects,
  [ChangeOrderMutations.RECEIVE_CHANGE_ORDERS]: receiveProjects,
  [AuthMutations.LOG_OUT](state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  async [ProjectActions.CREATE_PROJECT](context, payload) {
    context.commit(ProjectMutations.PROJECT_BEGIN_WRITE_DATA);
    try {
      const { newProject } = payload;

      const { data: { project } } = await api.project.create(newProject);
      const normalizedProjects = normalize(project, schemas.project);

      context.commit(ProjectMutations.RECEIVE_PROJECTS, { ...normalizedProjects.entities });
      context.commit(ProjectMutations.PROJECT_COMPLETE_WRITE_DATA);

      return project.id;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ProjectActions.EDIT_PROJECT](context, payload) {
    context.commit(ProjectMutations.PROJECT_BEGIN_WRITE_DATA);
    try {
      const { projectId, updatedProject } = payload;

      const { data: { project } } = await api.project.updateOne(projectId, updatedProject);
      const normalizedProjects = normalize(project, schemas.project);

      context.commit(ProjectMutations.RECEIVE_PROJECTS, { ...normalizedProjects.entities });
      context.commit(ProjectMutations.PROJECT_COMPLETE_WRITE_DATA);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ProjectActions.FETCH_PROJECT_BY_ID](context, payload) {
    context.commit(ProjectMutations.PROJECT_REQUEST_DATA);
    try {
      const { data: { project } } = await api.project.findOne(payload.projectId);
      const normalizedProject = normalize(project, schemas.project);
      context.commit(ProjectMutations.RECEIVE_PROJECTS, normalizedProject.entities);

      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ProjectActions.FETCH_USER_PROJECTS](context, payload) {
    context.commit(ProjectMutations.PROJECT_REQUEST_DATA);
    try {
      const { data: { projects, activeProjectCount } } = await api.user.project.findMany(payload.userId);
      const normalizedProjects = normalize(projects, [schemas.project]);

      context.commit(ProjectMutations.RECEIVE_PROJECTS, { ...normalizedProjects.entities, activeProjectCount });
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ProjectActions.FETCH_ORGANIZATION_PROJECTS](context, payload) {
    context.commit(ProjectMutations.PROJECT_REQUEST_DATA);
    try {
      const { data: { projects, activeProjectCount } } = await api.organization.project.findMany(payload.organizationId);
      const normalizedProjects = normalize(projects, [schemas.project]);

      context.commit(ProjectMutations.RECEIVE_PROJECTS, { ...normalizedProjects.entities, activeProjectCount });
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ProjectActions.FETCH_ORGANIZATION_ACTIVE_PROJECT_COUNT](context, payload) {
    context.commit(ProjectMutations.PROJECT_REQUEST_DATA);
    const { organizationId } = payload;
    try {
      const { data: { count: activeProjectCount } } = await api.organization.project.count(organizationId, { ...payload });

      context.commit(ProjectMutations.RECEIVE_PROJECTS, { activeProjectCount });
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ProjectActions.FETCH_BILLS_BY_PROJECT_ID](context, payload) {
    context.commit(ProjectMutations.PROJECT_REQUEST_DATA);
    try {
      const { data: { bills } } = await api.project.bill.findMany(payload.projectId);
      const normalizedProjects = normalize(bills, [schemas.bill]);

      context.commit(ProjectMutations.RECEIVE_PROJECTS, { ...normalizedProjects.entities });
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
