<template>
  <BTable
    :data="this.tableData"
    hoverable
    detailed
    :mobile-cards="false"
    :show-detail-icon="false"
    detail-key="id"
    ref="activitiesTable"
    :class="['card', customClass]"
    :defaultSort="['name', 'asc']"
  >
    <BModal
      :active="!!activityIdEditing"
      @close="handleCloseModal"
      :width="800"
    >
        <header class="modal-card-head">
          <p v-if="activityIdEditing" class="modal-card-title">{{ $t('common.ctas.edit') }} {{ stateActivities[activityIdEditing].name }}</p>
          <button
            type="button"
            class="delete"
            @click="handleCloseModal"
          />
        </header>
        <div class="has-background-white">
          <CreateOrEditActivityCard
            :projectId="activeChangeOrder.projectId"
            :changeOrderId="changeOrderId"
            :activityId="activityIdEditing"
            :isDark="false"
            :isInset="false"
            areFieldsStacked
            :hasBorderRadius="false"
            @submitSuccess="handleActivityUpdateSuccess"
          />
        </div>
    </BModal>
    <template slot="empty">
      <div class="has-text-grey u-p-a has-text-centered">
        {{ $t('changeOrderActivitiesTable.emptyState') }}
      </div>
    </template>
    <BTableColumn
      field="name"
      :label="$t('common.terms.name')"
      width="200"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      <p class="u-m-b-xsmall">
        {{ props.row.name }}
      </p>
      <CostCodeTag :costCodeId="props.row.costCodeId" />
    </BTableColumn>
    <BTableColumn
      field="totalBudget"
      :label="activities[0] && activities[0].costId ? $t('common.terms.contracted') : $t('common.terms.budgeted')"
      numeric
      cell-class="vertical-align-middle"
      v-slot="props"
    >
      <p>{{ displayCurrency(calculateActivityBudget(props.row.unitValue, props.row.quantity), { prefix: '$', multiplier: 100 }) }}</p>
      <small>
        {{ props.row.quantity }} {{ props.row.unit }} @ {{ displayCurrency(props.row.unitValue, { multiplier: 100, prefix: '$' }) }}
      </small>
    </BTableColumn>
    <BTableColumn
      cell-class="vertical-align-middle"
      width="140"
      v-slot="props"
    >
      <div class="buttons" style="justify-content: center;">
        <BTooltip
          v-if="isLoggedInUserAtLeastProjectManager"
          :label="$t('common.ctas.edit')"
          position="is-top"
          :triggers="['hover']"
          type="is-dark"
          class="u-m-r-small"
          appendToBody
        >
          <BButton
            type="is-primary is-light"
            @click="() => handleClickEditActivity(props.row.id)"
            @keyup.enter="() => handleClickEditActivity(props.row.id)"
          >
            <BIcon icon="pencil" />
          </BButton>
        </BTooltip>
      </div>
    </BTableColumn>
  </BTable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency } from '@/helpers/stringHelpers';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import CostCodeTag from '@/components/CostCodeTag.vue';

export default {
  name: 'ChangeOrderActivitiesTable',

  components: {
    CreateOrEditActivityCard,
    CostCodeTag,
  },

  props: {
    changeOrderId: {
      type: String,
      required: true,
    },
    /**
     * Pass an array of activities to display. If empty, component will default to
     * getting all activities matching the changeOrderId from global Vuex state.
     * */
    activities: {
      type: Array,
      required: false,
      default: null,
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      openedDetailsTableRowIds: [], // An array of activity ids for table rows with details opened.
      activityIdEditing: null,
    };
  },

  computed: {
    ...mapState({
      activityCategories: state => state.activityCategory.activityCategories,
      stateActivities: state => state.activity.activities,
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    ...mapGetters([
      'getChangeOrderActivities',
      'isLoggedInUserAtLeastProjectManager',
    ]),
    tableData() {
      return this.activities ? this.activities : this.getChangeOrderActivities(this.changeOrderId).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    activeChangeOrder() {
      return this.changeOrders[this.changeOrderId];
    },
  },

  methods: {
    calculateActivityBudget,
    displayCurrency,
    handleClickEditActivity(activityId) {
      this.activityIdEditing = activityId;
    },
    handleCloseModal() {
      this.activityIdEditing = null;
    },
    handleActivityUpdateSuccess() {
      this.handleCloseModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_ACTIVITY.SUCCESS);
    },
  },
};
</script>
