import { baseForm, baseField } from '@/forms/base.form';

const editCostForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'createCostForm.costName.label',
      name: 'createCostForm.costName.name',
      value: '',
      required: true,
    },
    costType: {
      ...baseField,
      label: 'createCostForm.costType.label',
      name: 'createCostForm.costType.name',
      value: null,
      required: true,
    },
    startDate: {
      ...baseField,
      label: 'createCostForm.startDate.label',
      name: 'createCostForm.startDate.name',
      value: null,
      required: false,
    },
    endDate: {
      ...baseField,
      label: 'createCostForm.endDate.label',
      name: 'createCostForm.endDate.name',
      value: null,
      required: false,
    },
    vendor: {
      ...baseField,
      label: 'createCostForm.vendor.label',
      name: 'createCostForm.vendor.name',
      placeholder: 'createCostForm.vendor.placeholder',
      value: '',
      required: false,
    },
    billingType: {
      ...baseField,
      label: 'createCostForm.billingType.label',
      name: 'createCostForm.billingType.name',
      value: '',
      required: true,
      disabled: true,
    },
  },
};

export default editCostForm;
