import * as UserMutations from '@/store/mutations/User.mutations';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  invitations: {},
});

const receiveInvitations = (state, payload) => {
  state.invitations = {
    ...state.invitations,
    ...payload.invitations,
  };
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  // Placeholder
};

const mutations = {
  [UserMutations.RECEIVE_USERS]: receiveInvitations,
};

const actions = {
  // Placeholder
};

export default {
  state,
  getters,
  actions,
  mutations,
};
