<template>
  <BTable
    :data="this.tableData"
    hoverable
    :mobile-cards="false"
    ref="activitiesTable"
    class="card"
  >
    <BModal
      :active="!!activityIdEditing"
      :width="400"
      @close="handleCloseEditModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="activityIdEditing" class="modal-card-title">{{ $t('common.ctas.edit') }} {{ stateActivities[activityIdEditing].name }}</p>
          <button
            type="button"
            class="delete"
            @click="handleCloseEditModal"
          />
        </header>
        <div class="modal-card-body">
          <CreateOrEditActivityCard
            :costId="costId"
            :activityId="activityIdEditing"
            :isDark="false"
            :isInset="false"
            areFieldsStacked
            :hasBorderRadius="false"
            @submitSuccess="handleActivityUpdateSuccess"
          />
        </div>
      </div>
    </BModal>
    <BModal
      :active="!!activityIdConfirmingDelete"
      @close="handleCloseDeleteModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="activityIdConfirmingDelete" class="modal-card-title">
            {{ $t('common.ctas.delete') }}
            {{ $t('common.terms.activity') }}
          </p>
          <button
            type="button"
            class="delete"
            @click="handleCloseDeleteModal"
          />
        </header>
        <section class="modal-card-body has-background-white u-p-a has-text-centered">
          <div>
            <p>
              <BIcon icon="alert" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('costActivitiesTable.deleteActivityModal.warning') }}</h5>
            <div class="is-flex is-justify-content-center">
              <ActivityCard :activityId="activityIdConfirmingDelete" style="width: 300px;" />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleCloseDeleteModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-danger"
              @click="handleConfirmDeleteActivity(activityIdConfirmingDelete)"
              :loading="activitiesDeleting.includes(activityIdConfirmingDelete)"
            >
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.activity') }}
            </BButton>
          </div>
        </footer>
      </div>
    </BModal>
    <template slot="empty">
      <div class="has-text-grey u-p-a has-text-centered">
        {{ $t('billActivitiesTable.emptyState') }}
      </div>
    </template>
    <BTableColumn
      field="activityDescription"
      :label="$t('common.terms.name')"
      width="200"
      cell-class="vertical-align-middle"
      v-slot="props"
    >
      <p class="u-m-b-xsmall">{{ props.row.name }}</p>
      <CostCodeTag :costCodeId="props.row.costCodeId" />
    </BTableColumn>
    <BTableColumn
      field="totalBudget"
      :label="$t('common.terms.contracted')"
      numeric
      cell-class="vertical-align-middle"
      v-slot="props"
    >
      <div v-if="props.row.quantity && props.row.unitValue && props.row.unit">
        <p>${{ displayCurrency(calculateActivityBudget(props.row.unitValue, props.row.quantity), { multiplier: 100 }) }}</p>
        <small>
          {{ props.row.quantity }} {{ props.row.unit }} @ ${{ displayCurrency(props.row.unitValue, { multiplier: 100 }) }}
        </small>
      </div>
    </BTableColumn>
    <BTableColumn
      field="rowDetails"
      label=""
      cell-class="vertical-align-middle"
      width="170"
      v-slot="props"
    >
      <div class="buttons" style="justify-content: center;">
        <BTooltip
          :label="!canEditActivity(props.row.id) ? $t('billActivitiesTable.activityDeleteRestriction') : $t('common.ctas.edit')"
          position="is-top"
          :multilined="!canEditActivity(props.row.id)"
          :triggers="['hover']"
          type="is-dark"
          class="u-m-r-small"
        >
          <BButton
            type="is-primary is-light"
            :disabled="!canEditActivity(props.row.id)"
            @click="() => handleClickEditActivity(props.row.id)"
            @keyup.enter="() => handleClickEditActivity(props.row.id)"
          >
            <BIcon icon="pencil" />
          </BButton>
        </BTooltip>
        <BTooltip
          :label="!canEditActivity(props.row.id) ? $t('billActivitiesTable.activityDeleteRestriction') : $t('common.ctas.delete')"
          position="is-top"
          :triggers="['hover']"
          :multilined="!canEditActivity(props.row.id)"
          :size="!canEditActivity(props.row.id) ? 'is-small' : undefined"
          type="is-dark"
          style="max-width: 100px;"
        >
          <BButton
            type="is-primary is-light"
            :disabled="!canEditActivity(props.row.id)"
            @click="handleClickDeleteActivity(props.row.id)"
            @keyup.enter="handleClickDeleteActivity(props.row.id)"
          >
            <BIcon icon="delete" />
          </BButton>
        </BTooltip>
      </div>
    </BTableColumn>
  </BTable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as ActivityActions from '@/store/actions/Activity.actions';
import * as BillItemMutations from '@/store/mutations/BillItem.mutations';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import ActivityCard from '@/components/ActivityCard.vue';
import CostCodeTag from '@/components/CostCodeTag.vue';

export default {
  name: 'BillActivitiesTable',

  components: {
    CreateOrEditActivityCard,
    ActivityCard,
    CostCodeTag,
  },

  props: {
    costId: {
      type: String,
      required: true,
    },
    billId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Pass an array of activities to display. If empty, component will default to
     * getting all activities matching the costId from global Vuex state.
     * */
    activities: {
      type: Array,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      activitiesDeleting: [],
      activityIdEditing: null,
      activityIdConfirmingDelete: null,
    };
  },

  computed: {
    ...mapState({
      bills: state => state.bill.bills,
      stateActivities: state => state.activity.activities,
    }),
    ...mapGetters([
      'getCostActivities',
      'getBillStatusByName',
      'getBillItemByActivityAndBillId',
    ]),
    tableData() {
      return this.activities ? this.activities : this.getCostActivities(this.costId);
    },
  },

  methods: {
    calculateActivityBudget,
    displayCurrency,
    addSuffix,
    canEditActivity(activityId) {
      return !!activityId;
    },
    async handleConfirmDeleteActivity(activityId) {
      this.activitiesDeleting = [...this.activitiesDeleting, activityId];
      try {
        await this.$store.dispatch(ActivityActions.DELETE_ACTIVITY_BY_ID, { activityId });
        this.handleCloseDeleteModal();
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY.SUCCESS);

        const billItem = this.getBillItemByActivityAndBillId(activityId, this.billId);
        this.$store.commit(BillItemMutations.DELETE_BILL_ITEM_BY_ID, { billItemId: billItem.id });
        this.$emit('updateActivity');
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY.ERROR);
      }
      this.activitiesDeleting = this.activitiesDeleting.filter(a => a !== activityId);
    },
    handleClickEditActivity(activityId) {
      this.activityIdEditing = activityId;
    },
    handleClickDeleteActivity(activityId) {
      this.activityIdConfirmingDelete = activityId;
    },
    handleCloseEditModal() {
      this.activityIdEditing = null;
    },
    handleCloseDeleteModal() {
      this.activityIdConfirmingDelete = null;
    },
    handleActivityUpdateSuccess() {
      this.handleCloseEditModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_ACTIVITY.SUCCESS);
      this.$emit('updateActivity');
    },
  },
};
</script>
