import * as TypeMutations from '@/store/mutations/Type.mutations';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  costTypes: {},
});

const receiveCostTypes = (state, payload) => {
  state.costTypes = {
    ...state.costTypes,
    ...payload.costTypes,
  };
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  getCostTypeByName(state) {
    return name => Object.values(state.costTypes)
      .filter(costType => costType.name === name)[0];
  },
};

const mutations = {
  [TypeMutations.RECEIVE_TYPES]: receiveCostTypes,
};

const actions = {
  // Placeholder
};

export default {
  state,
  getters,
  actions,
  mutations,
};
