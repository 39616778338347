<template>
  <div
    :class="[
      'user-avatar__wrapper',
      `user-avatar__wrapper--${size}`,
    ]"
  >
    <figure
      :class="[
        'image',
        getAvatarImageSizeClass,
        `user-avatar__container--${size}`,
        { 'user-avatar__container--faded': isFaded },
        customClass
      ]"
    >
      <div
        :class="[
          'user-avatar__background',
          `user-avatar__background--${size}`
        ]"
      >
        <span v-if="userInitials" class="user-avatar__initials">{{ userInitials }}</span>
        <span v-else class="user-avatar__initials"><b-icon icon="account" /></span>
      </div>
    </figure>
  </div>
</template>

<script>
import { getInitialsFromFirstAndLast } from '@/helpers/stringHelpers';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'UserAvatar',

  props: {
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    size: {
      type: String,
      required: false,
      validator(propValue) {
        // The value must match one of these strings
        return ['xsmall', 'small', 'medium', 'large'].indexOf(propValue) !== -1;
      },
      default: 'medium',
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
    isFaded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    getAvatarImageSizeClass() {
      let avatarImageSizeClass;
      if (this.size === 'xsmall') {
        avatarImageSizeClass = 'is-24x24';
      } else if (this.size === 'small') {
        avatarImageSizeClass = 'is-32x32';
      } else if (this.size === 'medium') {
        avatarImageSizeClass = 'is-48x48';
      } else if (this.size === 'large') {
        avatarImageSizeClass = 'is-64x64';
      }

      return avatarImageSizeClass;
    },
    userInitials() {
      if (!this.user || isObjectEmpty(this.user)) return '';
      return getInitialsFromFirstAndLast(this.user.firstName, this.user.lastName);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar__wrapper {
  position: relative;
}

.user-avatar__wrapper--xsmall {
  max-height: 30px;
}

.user-avatar__wrapper--small {
  max-height: 38px;
}

.user-avatar__wrapper--medium {
  max-height: 54px;
}

.user-avatar__wrapper--large {
  max-height: 70px;
}

.user-avatar__container--xsmall {
  min-width: 24px;
  margin: 2px;
}

.user-avatar__container--small {
  min-width: 32px;
  margin: 3px;
}

.user-avatar__container--medium {
  min-width: 48px;
  margin: 3px;
}

.user-avatar__container--large {
  min-width: 64px;
  margin: 3px;
}

.user-avatar__container--faded {
  background-color: $white;
  opacity: 0.5;
}

.user-avatar__background {
  font-weight: $weight-medium;
  background-color: $white;
  box-shadow: $avatar-image-box-shadow;
  border-radius: $radius-rounded;
}

.user-avatar__background--xsmall {
  font-size: $size-7;
  width: 24px;
  height: 24px;
}

.user-avatar__background--small {
  font-size: $size-6;
  width: 32px;
  height: 32px;
}

.user-avatar__background--medium {
  font-size: $size-5;
  width: 48px;
  height: 48px;
}

.user-avatar__background--large {
  font-size: $size-5;
  width: 64px;
  height: 64px;
}

.user-avatar__image {
  box-shadow: $avatar-image-box-shadow;
}

.user-avatar__initials {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
