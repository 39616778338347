import { normalize } from 'normalizr';
import * as CostMutations from '@/store/mutations/Cost.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as CostActions from '@/store/actions/Cost.actions';
import * as VendorMutations from '@/store/mutations/Vendor.mutations';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';
import * as Types from '@/constants/Types';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  costs: {},
  costCounts: {},
});

const receiveCosts = (state, payload) => {
  state.costs = {
    ...state.costs,
    ...payload.costs,
  };
  state.costCounts = {
    ...state.costCounts,
    ...payload.costCounts,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

function deleteCost(state, payload) {
  const updatedCosts = { ...state.costs };
  delete updatedCosts[payload.costId];
  state.costs = { ...updatedCosts };
  state.isFetchingData = false;
  state.isWritingData = false;
}

const state = getInitialState();

const getters = {
  getCostsByProjectId(state) {
    return projectId => Object.values(state.costs)
      .filter(cost => cost.projectId === projectId);
  },
  getCostBillsByStatus(state, getters, rootState, rootGetters) {
    return (costId, billStatusName) => (
      (state.costs[costId]?.bills ?? [])
        .map(billId => rootState.bill.bills[billId])
        .filter(bill => bill.statusId === rootGetters.getBillStatusByName(billStatusName).id)
    );
  },
  getOpenBillsByCostId(state, getters) {
    return costId => ([
      ...getters.getCostBillsByStatus(costId, Types.billStatusTypes.draft),
      ...getters.getCostBillsByStatus(costId, Types.billStatusTypes.rejected),
      ...getters.getCostBillsByStatus(costId, Types.billStatusTypes.pendingApproval),
      ...getters.getCostBillsByStatus(costId, Types.billStatusTypes.approved),
    ]);
  },
  getUnapprovedOpenBillsByCostId(state, getters) {
    return costId => ([
      ...getters.getCostBillsByStatus(costId, Types.billStatusTypes.draft),
      ...getters.getCostBillsByStatus(costId, Types.billStatusTypes.rejected),
      ...getters.getCostBillsByStatus(costId, Types.billStatusTypes.pendingApproval),
    ]);
  },
  getClosedBillsByCostId(state, getters) {
    return costId => ([
      ...getters.getCostBillsByStatus(costId, Types.billStatusTypes.closed),
    ]);
  },
  // getOpenBillsByCostId()
  getDraftBillByCostId(state, getters, rootState, rootGetters) {
    return costId => {
      const draftStatus = rootGetters.getBillStatusDraft;
      const costBillsInDraftStatus = getters.getCostBillsByStatus(costId, draftStatus.name);

      return costBillsInDraftStatus.length ? costBillsInDraftStatus[0] : null;
    };
  },
  getCostTotalAdvanceAmount(state, getters, rootState, rootGetters) {
    return (costId, options = { excludedBillIds: [], excludedBillStatusNames: [] }) => rootGetters.getBillsByCostId(costId)
      .filter(bill => (
        !options.excludedBillIds?.includes(bill.id)
        && !options.excludedBillStatusNames?.includes(rootState.billStatus.billStatuses[bill.statusId].name)
      ))
      .map(bill => rootGetters.getBillTotalAdvance(bill.id))
      .reduce((costTotal, billTotal) => costTotal + billTotal, 0);
  },
  getCostTotalAdvancePercent(state, getters) {
    return (costId, options = { excludedBillIds: [], excludedBillStatusNames: [] }) => (
      getters.getCostTotalAdvanceAmount(costId, options) / getters.getCostTotalActivityBudget(costId)
    );
  },
  getCostTotalDownPaymentAmount(state, getters, rootState, rootGetters) {
    return (costId, options = { excludedBillIds: [], excludedBillStatusNames: [] }) => rootGetters.getBillsByCostId(costId)
      .filter(bill => (
        !options.excludedBillIds?.includes(bill.id)
        && !options.excludedBillStatusNames?.includes(rootState.billStatus.billStatuses[bill.statusId].name)
      ))
      .map(bill => rootGetters.getBillTotalDownPayment(bill.id))
      .reduce((costTotal, billTotal) => costTotal + billTotal, 0);
  },
  getCostTotalRetainageAmount(state, getters, rootState, rootGetters) {
    return (costId, options = { excludedBillIds: [], excludedBillStatusNames: [] }) => rootGetters.getBillsByCostId(costId)
      .filter(bill => (
        !options.excludedBillIds?.includes(bill.id)
        && !options.excludedBillStatusNames?.includes(rootState.billStatus.billStatuses[bill.statusId].name)
      ))
      .map(bill => rootGetters.getBillTotalRetainage(bill.id))
      .reduce((costTotal, billTotal) => costTotal + billTotal, 0);
  },
  getCostTotalDeductionAmount(state, getters, rootState, rootGetters) {
    return (costId, options = { excludedBillIds: [], excludedBillStatusNames: [] }) => rootGetters.getBillsByCostId(costId)
      .filter(bill => (
        !options.excludedBillIds?.includes(bill.id)
        && !options.excludedBillStatusNames?.includes(rootState.billStatus.billStatuses[bill.statusId].name)
      ))
      .map(bill => rootGetters.getBillTotalDeduction(bill.id))
      .reduce((costTotal, billTotal) => costTotal + billTotal, 0);
  },
  getCostTotalActivityBudget(state, getters, rootState, rootGetters) {
    return costId => (
      rootGetters.getCostActivities(costId)
        .map(activity => rootGetters.getActivityBudget(activity.id))
        .reduce((total, currentAmount) => total + currentAmount, 0)
    );
  },
  getCostRemainingBalance(state, getters) {
    return costId => getters.getCostTotalActivityBudget(costId) - getters.getCostTotalAdvanceAmount(costId);
  },
  getCostTotalClosedAmount(state, getters, rootState, rootGetters) {
    return costId => (
      getters.getClosedBillsByCostId(costId)
        .reduce((acc, curr) => (acc + rootGetters.getBillItemsByBillId(curr.id).reduce((billAcc, billCurr) => (billAcc + billCurr.amount), 0)), 0)
    );
  },
  getCostTotalClosedTaxAmount(state, getters, rootState, rootGetters) {
    return costId => (
      getters.getClosedBillsByCostId(costId)
        .reduce((billAcc, billCurr) => (billAcc + rootGetters.getBillItemsByBillId(billCurr.id)
          .reduce((billItemAcc, billItemCurr) => (billItemAcc + ((billCurr?.taxId && billItemCurr?.isTaxed) ? (billItemCurr.amount * rootState.tax.taxes[billCurr.taxId]?.percent) : 0)), 0)),
        0)
    );
  },
  getCostTotalOpenTaxAmount(state, getters, rootState, rootGetters) {
    return costId => (
      getters.getOpenBillsByCostId(costId)
        .reduce((billAcc, billCurr) => (billAcc + rootGetters.getBillItemsByBillId(billCurr.id)
          .reduce((billItemAcc, billItemCurr) => (billItemAcc + ((billCurr?.taxId && billItemCurr?.isTaxed) ? (billItemCurr.amount * rootState.tax.taxes[billCurr.taxId]?.percent) : 0)), 0)),
        0)
    );
  },
};

const mutations = {
  [CostMutations.COST_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [CostMutations.COST_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [CostMutations.COST_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [CostMutations.RECEIVE_COSTS]: receiveCosts,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveCosts,
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveCosts,
  [BillMutations.RECEIVE_BILLS]: receiveCosts,
  [VendorMutations.RECEIVE_VENDORS]: receiveCosts,
  [CostMutations.DELETE_COST]: deleteCost,
};

const actions = {
  [CostActions.CREATE_COST]: async (context, payload) => {
    context.commit(CostMutations.COST_BEGIN_WRITE_DATA);

    try {
      const { data: { cost } } = await Api.cost.create(payload.newCost);
      const normalizedCost = normalize(cost, schemas.cost);
      context.commit(CostMutations.RECEIVE_COSTS, {
        ...normalizedCost.entities,
      });

      return normalizedCost.result; // Created cost id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [CostActions.FETCH_COST_BY_ID]: async (context, payload) => {
    context.commit(CostMutations.COST_REQUEST_DATA);

    try {
      const { costId } = payload;
      const { data: { cost } } = await Api.cost.findOne(costId);
      const normalizedCost = normalize(cost, schemas.cost);
      context.commit(CostMutations.RECEIVE_COSTS, { ...normalizedCost.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [CostActions.UPDATE_COST_BY_ID]: async (context, payload) => {
    context.commit(CostMutations.COST_BEGIN_WRITE_DATA);
    const { costId, updatedCost } = payload;
    try {
      const { data: { cost } } = await Api.cost.update(costId, updatedCost);
      const normalizedCost = normalize(cost, schemas.cost);
      context.commit(CostMutations.RECEIVE_COSTS, {
        ...normalizedCost.entities,
      });

      return normalizedCost.result; // Updated cost id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  async [CostActions.DELETE_COST_BY_ID](context, payload) {
    context.commit(CostMutations.COST_BEGIN_WRITE_DATA);
    try {
      await Api.cost.deleteOne(payload.costId);

      context.commit(CostMutations.DELETE_COST, { costId: payload.costId });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
