<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.activityCategories') }}&nbsp;({{ Object.keys(this.activityCategories).length || 0 }})</h4>
        </div>
      </section>

      <section v-if="isLoggedInUserAtLeastProjectManager" class="app-page__section">
        <CreateOrEditActivityCategoryCard />
      </section>

      <section class="app-page__section">
        <ActivityCategoriesTable />
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as ActivityCategoryActions from '@/store/actions/ActivityCategory.actions';
import ActivityCategoriesTable from '@/components/ActivityCategoriesTable.vue';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import CreateOrEditActivityCategoryCard from '@/components/CreateOrEditActivityCategoryCard.vue';

export default {
  name: 'ActivityCategories',

  components: {
    CreateOrEditActivityCategoryCard,
    ActivityCategoriesTable,
  },

  data() {
    return {
      isPageLoading: true,
    };
  },

  computed: {
    ...mapState({
      activityCategories: state => state.activityCategory.activityCategories,
    }),
    ...mapGetters([
      'activeOrganization',
      'isLoggedInUserAtLeastProjectManager',
    ]),
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    async fetchInitialData() {
      this.isPageLoading = true;

      try {
        await this.$store.dispatch(ActivityCategoryActions.FETCH_ORGANIZATION_ACTIVITY_CATEGORIES, { organizationId: this.activeOrganization.id });
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }

      this.isPageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
