<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BModal
        :active="isPreviewModalOpen"
        hasModalCard
        @close="handleClosePreviewModal"
        trapFocus
        ariaModal
        :canCancel="true"
      >
        <form @submit.prevent="handleSubmitCostCodes">
          <div class="modal-card" >
            <div class="modal-card-head">
              <p class="modal-card-title">{{ $t('importCostCodesView.costCodesPreview', { total: costCodesPreview.length })}}</p>
              <BButton type="is-text" size="is-small" @click="handleClosePreviewModal">
                <BIcon icon="close" />
              </BButton>
            </div>
            <div class="modal-card-body">
              <BField v-if="previewErrors.length > 0">
                <BMessage type="is-danger">
                  <p v-for="error in previewErrors" :key="error.index">
                    {{ $t('importCostCodesView.previewError', { lineNumber: error.index + 1 }) }}
                  </p>
                </BMessage>
              </BField>
              <BField v-if="submissionError">
                <BMessage type="is-danger">
                  {{ submissionError.message }}
                </BMessage>
              </BField>
              <BTable
                :data="costCodesPreview"
                hoverable
                striped
                narrowed
                class="card"
                :mobile-cards="false"
                :rowClass="getPreviewRowClass"
              >
                <!-- <BTableColumn
                  field="category"
                  :label="$t('costCodesTable.columns.costCodeCategory')"
                  header-class="u-p-l"
                  cell-class="vertical-align-middle u-p-l"
                  v-slot="props"
                  sortable
                >
                  <span v-if="props.row.category">{{ props.row.category }}</span>
                  <span v-else class="has-text-grey">N/A</span>
                </BTableColumn> -->
                <BTableColumn
                  field="code"
                  :label="$t('costCodesTable.columns.code')"
                  cell-class="vertical-align-middle"
                  v-slot="props"
                  sortable
                >
                  {{ props.row.code }}
                </BTableColumn>
                <BTableColumn
                  field="name"
                  :label="$t('costCodesTable.columns.name')"
                  cell-class="vertical-align-middle"
                  v-slot="props"
                  sortable
                >
                  {{ props.row.name }}
                </BTableColumn>
              </BTable>
            </div>
            <footer class="modal-card-foot">
              <div style="display: flex; width: 100%; justify-content: flex-end;">
                <div class="buttons">
                  <BButton
                    type="light"
                    @click="handleClosePreviewModal"
                    @keyup.enter="handleClosePreviewModal"
                  >
                    {{ $t('common.ctas.cancel') }}
                  </BButton>
                  <BButton
                    nativeType="submit"
                    type="is-primary"
                    :loading="isSubmissionInProgress"
                    size="is-large"
                    :disabled="previewErrors.length > 0"
                  >
                    {{ $t('common.ctas.importCostCodes') }}
                  </BButton>
                </div>
              </div>
            </footer>
          </div>
        </form>
      </BModal>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.importCostCodes') }}</h4>
        </div>
      </section>
      <section class="app-page__section">
        <div class="card u-m-b">
          <div class="card-content">
            <div>
              <h4 class="title is-5">
                1 -
                <span>
                  {{ $t('importCostCodesView.step1.subtitle') }}
                </span>
              </h4>
              <BField class="is-primary mt-2">
                <BButton icon-left="download" @click="handleDownloadTemplate">
                {{ $t('importCostCodesView.ctas.downloadTemplate') }}
                </BButton>
              </BField>
            </div>
          </div>
        </div>
        <div class="card u-m-b">
          <div class="card-content">
            <div>
              <h4 class="title is-5">
                2 -
                <span>
                  {{ $t('importCostCodesView.step2.subtitle') }}
                </span>
              </h4>
              <p class="step__body">
                {{ $t('importCostCodesView.step2.body') }}
              </p>
            </div>
          </div>
        </div>
        <div class="card u-m-b">
          <div class="card-content">
            <div>
              <h4 class="title is-5">
                3 -
                <span>
                  {{ $t('importCostCodesView.step3.subtitle') }}
                </span>
              </h4>
              <BField v-if="fileError">
                <BMessage type="is-danger">
                  {{ fileError.message }}
                </BMessage>
              </BField>
              <BField>
                <BUpload v-model="file" dragDrop expanded>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <BIcon v-if="!isProcessingCsv" icon="upload" size="is-large" />
                        <span v-else style="min-height: 44px; display: block;">
                          <BLoading :isFullPage="false" :canCancel="false" :active="isProcessingCsv" />
                        </span>
                      </p>
                      <p>{{ $t('common.ctas.fileUpload') }}</p>
                    </div>
                  </section>
                </BUpload>
              </BField>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import Papaparse from 'papaparse';
import { saveAs } from 'file-saver';
import * as CostCodeActions from '@/store/actions/CostCode.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';

export default {
  name: 'ImportCostCodes',

  data() {
    return {
      isPageLoading: false,
      file: null,
      fileError: null,
      submissionError: null,
      isPreviewModalOpen: false,
      isSubmissionInProgress: false,
      costCodesPreview: [],
      isProcessingCsv: false,
    };
  },

  computed: {
    previewErrors() {
      return this.costCodesPreview
        .map((costCode, index) => ({ ...costCode, index }))
        .filter(costCode => !costCode.code || !costCode.name);
    },
  },

  watch: {
    file() {
      if (!this.file) {
        return;
      }
      const acceptedFileTypes = [
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];

      if (!acceptedFileTypes.includes(this.file.type)) {
        this.fileError = { message: 'Incorrect file type, please upload a CSV' };
        this.file = null;
        return;
      }

      this.parseCostCodesFile(this.file, this.showFilePreview);
    },
  },

  methods: {
    handleDownloadTemplate() {
      const template = Papaparse.unparse({
        fields: [this.$t('importCostCodesView.template.code').toUpperCase(), this.$t('importCostCodesView.template.name').toUpperCase()],
        data: [
          [
            this.$t('importCostCodesView.template.codeExample'),
            this.$t('importCostCodesView.template.nameExample'),
          ],
        ],
      });
      const fileName = this.$t('importCostCodesView.template.fileName');
      const file = new File([template], fileName, {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(file);
    },
    parseCostCodesFile(file, callback) {
      this.isProcessingCsv = true;
      Papaparse.parse(file, {
        header: true,
        skipEmptyLines: true, // need for some reason last line is empty
        complete: parsed => callback(parsed),
        error: console.error,
      });
    },
    showFilePreview(parsedFile) {
      this.isProcessingCsv = false;
      const { data } = parsedFile;
      this.costCodesPreview = this.shapeCostCodeProperties(data);
      this.isPreviewModalOpen = true;
    },
    shapeCostCodeProperties(importedCostCodes) {
      const spanishKeys = {
        NOMBRE: 'NAME',
        CODIGO: 'CODE',
      };
      return importedCostCodes.map(costCode => (
        Object.entries(costCode).reduce((acc, [key, value]) => ({
          ...acc,
          // This ternary will transform spanish to english keys so they can be sent to backend.
          // @TODO -- come up with solution that will support multiple languages in an agnostic fashion.
          [Object.keys(spanishKeys).includes(key) ? spanishKeys[key].toLowerCase() : key.toLowerCase()]: value,
        }), {})
      ));
    },
    handleClosePreviewModal() {
      this.clearFile();
      this.isPreviewModalOpen = false;
    },
    clearFile() {
      this.file = null;
      this.costCodesPreview = [];
    },
    getPreviewRowClass(row) {
      const { code, name } = row;
      return !code || !name ? 'has-background-danger-light has-text-danger' : '';
    },
    async handleSubmitCostCodes() {
      this.isSubmissionInProgress = true;

      if (this.previewErrors.length > 0) return;

      try {
        await this.$store.dispatch(
          CostCodeActions.CREATE_COST_CODES,
          { newCostCodes: this.costCodesPreview },
        );

        NotificationService.showNotification(NotificationTypes.BULK_IMPORT_COST_CODES(this.costCodesPreview.length).SUCCESS);
        this.$router.push('/cost-codes');
      } catch (err) {
        this.submissionError = err;
      }

      this.isSubmissionInProgress = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
