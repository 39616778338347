<template>
  <div class="app-page__container">
    <div>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.auditLogs') }}</h4>
        </div>
      </section>

      <section class="app-page__section">
        <AuditLogsTable
          :isLoading="isTableLoading"
          :tableData="tableData"
          :pageSize="pageSize"
          :total="total"
          @pageChange="handlePageChange"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as AuditLogActions from '@/store/actions/AuditLog.actions';
import AuditLogsTable from '@/components/AuditLogsTable.vue';

export default {
  name: 'AuditLogs',

  components: {
    AuditLogsTable,
  },

  data() {
    return {
      isTableLoading: true,
      pageNumber: 1,
      pageSize: 20,
      tableData: [],
    };
  },

  computed: {
    ...mapState({
      auditLogs: state => state.auditLog.auditLogs,
      total: state => state.auditLog.auditLogCount,
    }),
    ...mapGetters([
      'activeOrganization',
    ]),
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    async fetchInitialData() {
      this.isTableLoading = true;

      const auditLogIds = await this.$store.dispatch(AuditLogActions.FETCH_ORGANIZATION_AUDIT_LOGS, {
        organizationId: this.activeOrganization.id,
        params: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        },
      });
      this.tableData = auditLogIds.map(id => this.auditLogs[id]);
      this.isTableLoading = false;
    },
    async handlePageChange(page) {
      this.pageNumber = page;
      await this.fetchInitialData();
    },
  },
};
</script>

<style scoped lang="scss">

</style>
