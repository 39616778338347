<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
        {{ $t('common.notifications.archivedProject') }}
      </BMessage>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">
            {{ activeChangeOrder.name }}
          </h4>
        </div>
      </section>

      <section class="app-page__section">
        <div class="columns">
          <div
            v-for="total in changeOrderTotals"
            :key="total.key"
            class="column"
          >
            <TotalsSummaryCard
              :title="total.title"
            >
              <div class="card-content u-p-t-0 u-p-b-small u-p-x">
                <div class="title is-size-4 is-size-3-fullhd has-text-weight-black u-m-b-0 has-text-right">
                  <AnimatedNumber
                    :value="total.total"
                    :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                    :duration="400"
                    :class="[{ 'is-clickable is-underlined': total.hasTooltip }]"
                  />
                </div>
              </div>
            </TotalsSummaryCard>
          </div>
        </div>
      </section>

      <section class="app-page__section">
        <div class="app-page__header">
          <div class="app-page__header__title">
            <div style="display: flex; align-items: baseline">
              <h5 class="title is-5 has-text-grey u-m-b-0 u-m-r-small">
                {{ $t('common.terms.activities') }}
              </h5>
              <BTabs type="is-toggle" v-model="costOrBudgetTab" class="empty-tabs" size="is-small">
                <BTabItem :label="$t('common.terms.budgeted')" icon="folder-text-outline" />
                <BTabItem :label="$t('common.terms.contracted')" icon="format-list-bulleted" />
              </BTabs>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section class="app-page__section">
          <ChangeOrderActivitiesTable :changeOrderId="activeChangeOrderId" :projectId="activeProjectId" :activities="tableActivities" />
        </section>
      </div>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AnimatedNumber from 'animated-number-vue';
import * as ChangeOrderActions from '@/store/actions/ChangeOrder.actions';
import * as NotificationService from '@/services/Notification.service';
import { displayCurrency } from '@/helpers/stringHelpers';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import * as NotificationTypes from '@/constants/NotificationTypes';
import ChangeOrderActivitiesTable from '@/components/ChangeOrderActivitiesTable.vue';
import TotalsSummaryCard from '../components/TotalsSummaryCard.vue';

export default {
  name: 'ProjectChangeOrderDetails',

  components: {
    ChangeOrderActivitiesTable,
    AnimatedNumber,
    TotalsSummaryCard,
  },

  data() {
    return {
      isPageLoading: true,
      costOrBudgetTab: 0,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    ...mapGetters([
      'isProjectArchived',
      'getChangeOrderCostActivities',
      'getChangeOrderBudgetActivities',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    activeChangeOrderId() {
      return this.$route.params.changeOrderId;
    },
    activeChangeOrder() {
      return this.changeOrders[this.activeChangeOrderId];
    },
    changeOrderTotals() {
      return [
        {
          title: this.$t('common.terms.budgeted'),
          total: this.calculateChangeOrderBudgetActivitiesTotal(this.activeChangeOrderId),
        },
        {
          title: this.$t('common.terms.contracted'),
          total: this.calculateChangeOrderCostActivitiesTotal(this.activeChangeOrderId),
        },
      ];
    },
    tableActivities() {
      return this.costOrBudgetTab === 0 ? this.getChangeOrderBudgetActivities(this.activeChangeOrderId) : this.getChangeOrderCostActivities(this.activeChangeOrderId);
    },
  },

  created() {
    this.fetchinitialData();
  },

  methods: {
    displayCurrency,
    async fetchinitialData() {
      this.isPageLoading = true;
      try {
        await this.$store.dispatch(ChangeOrderActions.FETCH_CHANGE_ORDER_BY_ID, { changeOrderId: this.$route.params.changeOrderId });
      } catch {
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isPageLoading = false;
    },
    handleClickImportActivities() {
      this.$router.push(`/projects/${this.activeProjectId}/change-orders/${this.activeChangeOrderId}/activities/import`);
    },
    calculateActivitiesTotal(activities) {
      return activities
        .reduce((acc, activity) => { // eslint-disable-line
          return acc + calculateActivityBudget(activity.unitValue, activity.quantity);
        }, 0);
    },
    calculateChangeOrderBudgetActivitiesTotal(changeOrderId) {
      return this.calculateActivitiesTotal(this.getChangeOrderBudgetActivities(changeOrderId));
    },
    calculateChangeOrderCostActivitiesTotal(changeOrderId) {
      return this.calculateActivitiesTotal(this.getChangeOrderCostActivities(changeOrderId));
    },
  },
};
</script>

<style lang="scss">
.empty-tabs {
  > .tab-content {
    padding: 0 !important;
  }
}
</style>
