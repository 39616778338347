export const systemRoleNames = {
  superadmin: 'superadmin',
  owner: 'owner',
  admin: 'admin',
  projectManager: 'projectManager',
  projectAssistant: 'projectAssistant',
  member: 'member',
};

export const atLeastMemberRoles = [
  systemRoleNames.member,
  systemRoleNames.projectAssistant,
  systemRoleNames.projectManager,
  systemRoleNames.admin,
  systemRoleNames.owner,
  systemRoleNames.superadmin,
];

export const atLeastProjectAssistantRoles = [
  systemRoleNames.projectAssistant,
  systemRoleNames.projectManager,
  systemRoleNames.admin,
  systemRoleNames.owner,
  systemRoleNames.superadmin,
];

export const atLeastProjectManagerRoles = [
  systemRoleNames.projectManager,
  systemRoleNames.admin,
  systemRoleNames.owner,
  systemRoleNames.superadmin,
];

export const atLeastAdminRoles = [
  systemRoleNames.admin,
  systemRoleNames.owner,
  systemRoleNames.superadmin,
];

export const atLeastOwnerRoles = [
  systemRoleNames.owner,
  systemRoleNames.superadmin,
];
