import { baseForm, baseField } from '@/forms/base.form';
import { validateEmail } from '@/services/Validation.service';

const createUserForm = {
  ...baseForm,
  fields: {
    firstName: {
      ...baseField,
      label: 'common.formFields.firstName.label',
      name: 'common.formFields.firstName.name',
      value: '',
      required: true,
    },
    lastName: {
      ...baseField,
      label: 'common.formFields.lastName.label',
      name: 'common.formFields.lastName.name',
      value: '',
      required: true,
    },
    email: {
      ...baseField,
      label: 'common.formFields.email.label',
      name: 'common.formFields.email.name',
      value: '',
      required: true,
      validator: validateEmail,
    },
    role: {
      ...baseField,
      label: 'common.terms.role',
      value: null,
    },
  },
};

export default createUserForm;
