<template>
  <div class="floating-action-button is-hidden-desktop">
    <BButton @click="onClick" :type="buttonType" rounded>
      <slot></slot>
    </BButton>
  </div>
</template>

<script>
export default {
  name: 'FloatingActionButton',

  props: {
    onClick: {
      type: Function,
      required: true,
    },
    buttonType: {
      type: String,
      required: false,
      default: 'is-primary',
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-action-button {
  position: fixed;
  z-index: 39;
  bottom: $global-whitespace-medium;
  right: $global-whitespace-medium;
}
</style>
