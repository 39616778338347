<template>
  <BModal :active="isOpen" hasModalCard :width="600" @close="$emit('close')">
    <form @submit.prevent="handleSubmitCreateClient">
      <div class="modal-card">
        <div class="modal-card-head">
          <p class="modal-card-title">
            {{ !activeClientId ? $t('common.ctas.createClient') : `${$t('common.ctas.updateClient')} ${activeClient.name}` }}
          </p>
        </div>
        <div class="modal-card-body">
          <BField v-if="formSubmissionError">
            <BMessage type="is-danger">
              {{  formSubmissionError.message }}
            </BMessage>
          </BField>
          <BField
            v-for="formField in Object.values(createClientForm.fields)"
            :key="formField.key"
            :class="['column', { 'required': formField.required }]"
            :label="$t(formField.label)"
            :type="createClientForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
            :message="createClientForm.formErrors[$t(formField.name)] || ''"
          >
            <BInput
              v-model="formField.value"
              :disabled="createClientForm.isFormSubmissionInProgress"
              :placeholder="$t(formField.placeholder)"
              :ref="formField.ref"
              @input="handleTouchForm"
            />
          </BField>
        </div>
        <footer class="modal-card-foot">
          <BButton nativeType="submit" type="is-primary" expanded :loading="createClientForm.isFormSubmissionInProgress">
            {{ !activeClientId ? $t('common.ctas.createClient') : $t('common.ctas.updateClient') }}
          </BButton>
        </footer>
      </div>
    </form>
  </BModal>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import createClientForm from '@/forms/createClient.form';
import * as ClientActions from '@/store/actions/Client.actions';
import * as ValidationService from '@/services/Validation.service';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'CreateOrEditClientModal',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    initialClientName: {
      type: String,
      required: false,
      default: '',
    },
    activeClientId: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: [
    'submitSuccess',
    'close',
  ],

  data() {
    return {
      createClientForm: cloneDeep(createClientForm),
      formSubmissionError: null,
    };
  },

  computed: {
    ...mapState({
      clients: state => state.client.clients,
    }),

    activeClient() {
      return this.activeClientId ? this.clients[this.activeClientId] : null;
    },
  },

  created() {
    this.setInitialFormState();
  },

  watch: {
    isOpen(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.setInitialFormState();
      }
    },
  },

  methods: {
    setInitialFormState() {
      if (this.activeClientId && this.activeClient) {
        const {
          name,
          description,
          contactName,
          contactEmail,
          contactPhone,
          legalId1,
          legalId2,
        } = this.activeClient;

        this.createClientForm.fields.name.value = name;
        this.createClientForm.fields.description.value = description;
        this.createClientForm.fields.contactName.value = contactName;
        this.createClientForm.fields.contactPhone.value = contactPhone;
        this.createClientForm.fields.contactEmail.value = contactEmail;
        this.createClientForm.fields.id1.value = legalId1;
        this.createClientForm.fields.id2.value = legalId2;
      } else {
        this.createClientForm.fields.name.value = this.initialClientName;
      }

      // Set focus on first form field. We use nextTick to make sure the DOM is rendered and the element exists.
      this.$nextTick(() => {
        if (this.$refs[this.createClientForm.fields.name.ref]) {
          this.$refs[this.createClientForm.fields.name.ref][0].focus();
          // This click method is the only way to ensure we draw focus away from autocomplete dropdowns.
          // Since autocomplete dropdowns are sometimes rendered in the BODY they will sit on top of our modals.
          this.$refs[this.createClientForm.fields.name.ref][0].$el.click();
        }
      });
    },
    async handleSubmitCreateClient() {
      this.formSubmissionError = null;
      this.createClientForm.formErrors = {};
      this.createClientForm.formErrors = ValidationService.runFormValidation({ ...this.createClientForm.fields }, { i18n: true });

      if (!isObjectEmpty(this.createClientForm.formErrors)) return;

      this.createClientForm.isFormSubmissionInProgress = true;
      const { fields } = this.createClientForm;
      try {
        const payload = {
          name: fields.name.value,
          description: fields.description.value,
          contactName: fields.contactName.value,
          contactEmail: fields.contactEmail.value,
          contactPhone: fields.contactPhone.value,
          legalId1: fields.id1.value,
          legalId2: fields.id2.value,
        };
        let clientId;

        if (this.activeClientId) {
          clientId = await this.$store.dispatch(
            ClientActions.UPDATE_CLIENT,
            { clientId: this.activeClientId, updatedClient: { ...payload } },
          );
        } else {
          clientId = await this.$store.dispatch(
            ClientActions.CREATE_CLIENT,
            { newClient: { ...payload } },
          );
        }
        this.createClientForm.isFormSubmissionInProgress = false;
        NotificationService.showNotification(NotificationTypes.CREATE_CLIENT.SUCCESS);
        this.$emit('submitSuccess', clientId);
      } catch (err) {
        console.log(err);
        this.formSubmissionError = err;
        this.createClientForm.isFormSubmissionInProgress = false;
      }
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
