import { systemRoleNames } from '@/constants/Role.constants';

export const sortRolesByLevel = (roleA, roleB) => { // eslint-disable-line
  if (!roleA?.name || !roleB?.name) return 0;

  const roleOrder = [
    systemRoleNames.owner,
    systemRoleNames.admin,
    systemRoleNames.projectManager,
    systemRoleNames.projectAssistant,
    systemRoleNames.member,
  ];

  return roleOrder.indexOf(roleA.name) - roleOrder.indexOf(roleB.name);
};
