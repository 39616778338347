import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      projects: state => state.project.projects,
      organizations: state => state.organization.organizations,
      users: state => state.user.users,
      costs: state => state.cost.costs,
      bills: state => state.bill.bills,
      clients: state => state.client.clients,
      budgets: state => state.budget.budgets,
      documents: state => state.document.documents,
      vendors: state => state.vendor.vendors,
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    allProps() {
      return {
        ...this.projects,
        ...this.organizations,
        ...this.users,
        ...this.costs,
        ...this.bills,
        ...this.clients,
        ...this.budgets,
        ...this.documents,
        ...this.vendors,
        ...this.changeOrders,
      };
    },
    routeTitle() {
      return this.$route?.meta(this.$route)?.pageTitle ?? [];
    },
  },

  watch: {
    allProps() {
      this.setDocumentTitle();
    },
  },

  updated() {
    this.setDocumentTitle();
  },

  methods: {
    setDocumentTitle() {
      document.title = this.generateDocumentTitle();
    },
    generateDocumentTitle() {
      if (this.routeTitle.length > 0) {
        return `${[...this.routeTitle].reverse().reduce((accTitle, component) => (`${this.getTitleComponentName(component)} ${accTitle}`), ' | Contract Flow')}`;
      }

      return 'Contract Flow';
    },
    getTitleComponentName(titleComponent) {
      if (!titleComponent.entity) return titleComponent.title;

      if (
        this[titleComponent.entity]
        && this[titleComponent.entity][this.$route?.params[titleComponent.param]]
        && (this.doesEntityHaveAttributes(titleComponent, 'attribute') || this.doesEntityHaveAttributes(titleComponent, 'fallbackAttribute'))
      ) {
        return this.parseTitleComponent(titleComponent, 'attribute') || this.parseTitleComponent(titleComponent, 'fallbackAttribute');
      }

      return this.$route?.params[titleComponent.param];
    },
    doesEntityHaveAttributes(titleComponent, attributeKey) {
      const {
        entity,
        param,
      } = titleComponent;
      const attribute = titleComponent[attributeKey];
      let attributesExist;

      if (Array.isArray(attribute)) {
        attributesExist = attribute.map(a => !!this[entity][this.$route?.params[param]][a]);
      } else {
        attributesExist = [!!this[entity][this.$route?.params[param]][attribute]];
      }

      return !attributesExist.includes(false);
    },
    parseTitleComponent(titleComponent, attributeKey) {
      const { entity, param } = titleComponent;

      const attribute = titleComponent[attributeKey];
      let parsedAttributes = '';

      if (Array.isArray(attribute)) {
        if (attribute.every(a => !this[entity][this.$route?.params[param]][a])) {
          parsedAttributes = '';
        } else {
          attribute.forEach(attr => {
            parsedAttributes += this[entity][this.$route?.params[param]][attr] + ' '; // eslint-disable-line
          });
        }
      } else {
        if (!attribute) { // eslint-disable-line
          parsedAttributes = '';
        } else {
          parsedAttributes = this[entity][this.$route?.params[param]][attribute];
        }
      }

      return parsedAttributes;
    },
  },
};
