// eslint-disable-next-line import/prefer-default-export
export const costTypes = {
  contract: 'contract',
  payroll: 'payroll',
  indirect: 'indirect',
  materials: 'materials',
  equipment: 'equipment',
};

export const billStatusTypes = {
  draft: 'draft',
  pendingApproval: 'pendingApproval',
  approved: 'approved',
  rejected: 'rejected',
  closed: 'closed',
};

export const billingTypes = {
  advance: 'advance',
  lumpSum: 'lumpSum',
};

export const billItemTypes = {
  advance: 'advance',
  deduction: 'deduction',
  downPayment: 'downPayment',
  retainage: 'retainage',
};

export const userStatusTypes = {
  inactive: 'inactive',
  invitationPending: 'invitationPending',
  deleted: 'deleted',
  archived: 'archived',
  active: 'active',
};
