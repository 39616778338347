<template>
  <div class="card">
    <CreateCostModal
      v-if="isLoggedInUserAtLeastProjectManager && !isProjectArchived(activeProjectId)"
      class="u-m-b"
      :projectId="activeProjectId"
      :isOpen="isCreateCostModalOpen"
      @close="handleCloseCreateCostModal"
      @submitSuccess="handleCreateCostSuccess"
    />
    <header class="card-header">
      <p class="card-header-title has-text-weight-black is-size-5">
        {{ $t('projectBillsSummary.recentBills') }}
      </p>
      <!-- <div class="card-header-icon">
        <BButton
          v-if="isLoggedInUserAtLeastProjectManager && !isProjectArchived(activeProjectId)"
          size="is-small"
          iconLeft="plus-thick"
          type="is-primary is-outlined"
          @click.prevent="handleOpenCreateCostModal"
          @keyup.enter.prevent="handleOpenCreateCostModal"
        >
          {{ $t('common.ctas.createCost') }}
        </BButton>
      </div> -->
    </header>
    <div class="card-content is-paddingless">
      <BTable
        :data="latestThreeBills"
        hoverable
        @click="handleClickRow"
        @keyup.enter="handleClickRow"
        class="card"
        style="border-radius: 0; box-shadow: none;"
        :mobile-cards="false"
        :row-class="handleRowClass"
        :default-sort-direction="'desc'"
        :default-sort="['createdAt', 'desc']"
        :showHeader="false"
      >
        <template slot="empty">
          <div class="has-text-grey u-p-a has-text-centered">
            {{ $t('costDetailsView.bills.emptyState') }}
          </div>
        </template>
        <BTableColumn
          field="number"
          :label="$t('common.terms.number')"
          cell-class="vertical-align-middle u-p-l"
          header-class="u-p-l"
          sortable
          v-slot="props"
        >
          <div class="has-text-weight-bold is-size-5">
            {{ $t('common.terms.bill') }} {{ props.row.number }}
          </div>
          <span v-if="props.row.name" class="is-italic is-size-6">{{ props.row.name }}</span>
          <div class="u-m-t-xsmall">
            <span class="has-text-weight-bold">{{ $t('common.terms.cost') }}:</span> {{ props.row.cost.name }}
          </div>
        </BTableColumn>
        <BTableColumn
          field="cost.name"
          :label="$t('common.terms.billStatus')"
          cell-class="vertical-align-middle u-p-l"
          header-class="u-p-l"
          sortable
          v-slot="props"
        >
          <BillStatusTag :billStatusId="props.row.statusId" size="small" />
        </BTableColumn>
        <BTableColumn
          field="totalValue"
          :label="$t('common.terms.value')"
          cell-class="vertical-align-middle u-p-l"
          header-class="u-p-l"
          sortable
          v-slot="props"
        >
          {{ displayCurrency(getBillTotalAfterTax(props.row.id), { prefix: '$', multiplier: 100 }) }}
        </BTableColumn>
        <!-- <BTableColumn
          :label="$t('common.terms.createdAt')"
          cell-class="vertical-align-middle u-p-l"
          header-class="u-p-l"
          v-slot="props"
          sortable
          field="createdAt"
        >
          {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale) }}
          {{ new Date(props.row.createdAt).toLocaleTimeString($i18n.locale) }}
        </BTableColumn> -->
      </BTable>
    </div>
    <footer class="card-footer">
      <div class="card-footer-item">
        <router-link :to="`/projects/${activeProjectId}/bills`">
          {{ $t('common.ctas.viewAll.feminine') }}
        </router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { convertFractionToPercent } from '../helpers/numberHelpers';
import { displayCurrency } from '../helpers/stringHelpers';
// import CostTypeTag from './CostTypeTag.vue';
import CreateCostModal from './CreateCostModal.vue';
import BillStatusTag from './BillStatusTag.vue';

export default {
  name: 'ProjectBillsSummary',

  components: {
    // CostTypeTag,
    CreateCostModal,
    BillStatusTag,
  },

  props: {
    activeProjectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isCreateCostModalOpen: false,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      costs: state => state.cost.costs,
      bills: state => state.bill.bills,
      costTypes: state => state.costType.costTypes,
    }),
    ...mapGetters([
      'getBillStatusDraft',
      'getCostsByProjectId',
      'getBillsByProjectId',
      'getBillTotalAfterTax',
      'getCostTotalAdvanceAmount',
      'getCostTotalAdvancePercent',
      'getCostTotalActivityBudget',
      'getCostTotalClosedAmount',
      'getCostRemainingBalance',
      'isLoggedInUserAtLeastProjectManager',
      'isProjectArchived',
    ]),
    billsTableData() {
      return this.getBillsByProjectId(this.activeProjectId)
        .map(bill => ({ ...bill, cost: this.costs[bill.costId] || {} }));
    },
    sortedBillsTableData() {
      return [...this.billsTableData].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    },
    latestThreeBills() {
      return [...this.sortedBillsTableData].slice(0, 3);
    },
  },

  methods: {
    convertFractionToPercent,
    displayCurrency,
    handleRowClass() {
      return 'has-cursor-pointer';
    },
    handleClickRow(row) {
      if (row.statusId === this.getBillStatusDraft.id) {
        this.$router.push(`/projects/${this.projectId}/costs/${row.costId}/bills/${row.id}/edit-draft`);
      } else {
        this.$router.push(`/bills/${row.id}`);
        // this.$router.push(`/projects/${this.projectId}/costs/${this.costId}/bills/${row.id}/details`);
      }
    },
    handleOpenCreateCostModal() {
      this.isCreateCostModalOpen = true;
    },
    handleCloseCreateCostModal() {
      this.isCreateCostModalOpen = false;
    },
    handleCreateCostSuccess() {
      this.isCreateCostModalOpen = false;
    },
  },
};
</script>
