import {
  NotificationProgrammatic as Notification,
  ToastProgrammatic as Toast,
} from 'buefy';

export const showNotification = (notificationType, optionalParams) => { // eslint-disable-line
  const notification = {
    queue: false,
    ...notificationType,
    ...optionalParams,
  };

  Notification.open(notification);
};

export const showToast = (notificationType, optionalParams) => { // eslint-disable-line
  const notification = {
    queue: false,
    ...notificationType,
    ...optionalParams,
  };

  Toast.open(notification);
};
