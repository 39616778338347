<template>
  <span
    class="user-display-name"
    @click.prevent="handleUserClick"
    @keyup.enter="handleUserClick"
    :class="{ 'is-clickable': isClickable }"
  >
    <small
      v-if="size === 'small'"
      :class="[
        'is-size-7',
        { 'is-block': !isInline },
      ]"
      style="word-break: break-word;"
    >
      {{ userDisplayName }}
    </small>
    <h6
      v-if="size === 'medium'"
      :class="[
        'title',
        'is-6',
        { 'is-inline': isInline },
      ]"
      style="word-break: normal;"
    >
      {{ userDisplayName }}
    </h6>
    <h5
      v-else-if="size === 'large'"
      :class="[
        'title',
        'is-5',
        { 'is-inline': isInline },
      ]"
    >
      {{ userDisplayName }}
    </h5>
    <h4
      v-else-if="size === 'x-large'"
      :class="[
        'title',
        'is-4',
        { 'is-inline': isInline },
      ]"
    >
      {{ userDisplayName }}
    </h4>
  </span>
</template>

<script>
import { mapState } from 'vuex';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import { shapeUserDisplayName } from '@/helpers/userHelpers';

export default {
  name: 'UserDisplayName',

  props: {
    activeUserId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      validator(propValue) {
        // The value must match one of these strings
        /* @ TODO -- more sizes 'xsmall', 'small', */
        return ['small', 'medium', 'large', 'x-large'].indexOf(propValue) !== -1;
      },
      default: 'medium',
    },
    isInline: {
      type: Boolean,
      required: false,
      default: false,
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    ...mapState({
      users: state => state.user.users,
    }),
    activeUser() {
      return !isObjectEmpty(this.users[this.activeUserId]) ? this.users[this.activeUserId] : {};
    },
    userDisplayName() {
      return isObjectEmpty(this.activeUser) ? '' : shapeUserDisplayName(this.activeUser);
    },
  },

  methods: {
    handleUserClick() {
      if (!this.isClickable) return;

      this.$router.push(`/users/${this.activeUserId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
  .is-clickable {
    cursor: pointer;
  }

  .user-display-name {
    word-break: normal;
  }
</style>
