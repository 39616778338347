import { baseForm, baseField } from '@/forms/base.form';
import { validateEmail } from '@/services/Validation.service';

const editClientForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'editClientForm.name.label',
      name: 'editClientForm.name.name',
      placeholder: 'editClientForm.name.placeholder',
      value: '',
      required: true,
      width: 'is-6',
    },
    description: {
      ...baseField,
      label: 'editClientForm.description.label',
      name: 'editClientForm.description.name',
      placeholder: 'editClientForm.description.placeholder',
      value: '',
      required: false,
      width: 'is-6',
      type: 'textarea',
    },
    contactName: {
      ...baseField,
      label: 'editClientForm.contactName.label',
      name: 'editClientForm.contactName.name',
      placeholder: 'editClientForm.contactName.placeholder',
      value: '',
      required: false,
      width: 'is-4',
    },
    contactEmail: {
      ...baseField,
      label: 'editClientForm.contactEmail.label',
      name: 'editClientForm.contactEmail.name',
      value: '',
      required: false,
      validator: validateEmail,
      width: 'is-4',
    },
    contactPhone: {
      ...baseField,
      label: 'editClientForm.contactPhone.label',
      name: 'editClientForm.contactPhone.name',
      value: '',
      required: false,
      masked: true,
      rawValue: '',
      width: 'is-4',
    },
    legalId1: {
      ...baseField,
      label: 'editClientForm.id1.label',
      name: 'editClientForm.id1.name',
      value: '',
      required: false,
      width: 'is-4',
    },
    legalId2: {
      ...baseField,
      label: 'editClientForm.id2.label',
      name: 'editClientForm.id2.name',
      value: '',
      required: false,
      width: 'is-4',
    },
  },
};

export default editClientForm;
