<template>
  <div class="card is-flex is-flex-direction-column" style="height: 100%;">
    <header class="card-header">
      <p class="card-header-title has-text-grey has-text-weight-black">
        {{ title }}
        <BTooltip
          v-if="tooltipInfo"
          :label="tooltipInfo"
          type="is-dark"
          multilined
          position="is-top"
        >
          <BIcon
            icon="information-outline"
            customSize="mdi-18px"
            class="u-m-l-xsmall"
          />
        </BTooltip>
      </p>
      <p v-if="titleFigure !== undefined" class="card-header-icon has-text-weight-bold">
        <AnimatedNumber
          :value="titleFigure"
          :formatValue="(value) => addSuffix(convertFractionToPercent(`${value}`), '%')"
          :duration="400"
        />
      </p>
    </header>
    <div v-if="amount !== undefined" class="card-content totals-summary-card__card-content u-p-t-small u-m-b-0">
      <div class="is-flex is-justify-content-space-between">
        <div class="title is-size-4 is-size-3-fullhd has-text-weight-black u-m-b-0 has-text-right">
          <AnimatedNumber
            :value="amount"
            :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
            :duration="400"
          />
        </div>
      </div>
    </div>
    <slot></slot>
    <div v-if="percent !== undefined" class="card-content totals-summary-card__card-content" style="margin-top: auto;">
      <div class="is-flex is-justify-content-space-between is-size-7 has-text-weight-bold">
        <div>
          {{ percentLabel }}
        </div>
        <div :class="{ 'has-text-danger': percent > 1 }">
          <AnimatedNumber
            :value="percent"
            :formatValue="(value) => addSuffix(convertFractionToPercent(`${value}`), '%')"
            :duration="400"
          />
        </div>
      </div>
      <BProgress v-if="percent < 1" :value="percent * 100" type="is-primary" size="is-small" class="u-m-t-xsmall" />
      <BProgress v-else :max="percent * 100" size="is-small" class="u-m-t-xsmall">
        <template #bar>
          <BProgressBar :value="100" type="is-primary" />
          <BProgressBar :value="(((percent - 1) * amount) / percent) * 100" type="is-danger is-light" />
        </template>
      </BPRogress>
    </div>
    <!-- <footer class="card-footer is-block" style="margin-left: auto;">
      <a href="#" class="card-footer-item">See More</a>
    </footer> -->
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';

export default {
  name: 'TotalsSummaryCard',

  components: {
    AnimatedNumber,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    // A number to be displayed on right hand side of card title. (typically percentages)
    titleFigure: {
      type: Number,
      required: false,
      default: undefined,
    },
    amount: {
      type: Number,
      required: false,
      default: undefined,
    },
    tooltipInfo: {
      type: String,
      required: false,
      default: undefined,
    },
    percent: {
      type: Number,
      required: false,
      default: undefined,
    },
    percentLabel: {
      type: String,
      required: false,
      default: 'Progress',
    },
  },

  methods: {
    displayCurrency,
    convertFractionToPercent,
    addSuffix,
  },
};
</script>

<style lang="scss">
progress::-webkit-progress-bar { background: lighten($primary, 32%) !important; }
progress::-webkit-progress-value { border-radius: 25px; }
progress::-moz-progress-bar { background: lighten($primary, 32%) !important; }

progress-bar {
  &.is-primary {
    background: lighten($primary, 32%) !important;
  }
}

.totals-summary-card__card-content {
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
    padding-top: 0 !important;
  }

  &:not(:last-child) {
    padding-bottom: 0;
    margin-bottom: $global-whitespace-small;
  }
}
</style>
