export const shapeDocumentDisplayName = fileName => fileName.split('_____START_CF_UUID_____')[0];

// Taken from this SO answer:
// https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
export const getFileExtension = fileName => {
  const shapedName = shapeDocumentDisplayName(fileName);

  return shapedName.slice((shapedName.lastIndexOf('.') - 1 >>> 0) + 2); // eslint-disable-line
};

export const isImageFile = extension => extension.match(/(jpg|jpeg|png|gif|svg|webp|avif|apng)$/i);
