<template>
  <BTable
    :data="categories"
    ref="budgetActivityCategoriesTable"
    hoverable
    detailed
    :mobileCards="false"
    :showHeader="false"
    detailKey="id"
    :openedDetailed="openedCategories"
    class="card"
    @detailsOpen="handleOpenCategoryRowDetails"
    @detailsClosed="handleCloseCategoryRowDetails"
  >
    <BTableColumn
      field="activityDescription"
      :label="$t('common.terms.name')"
      cell-class="vertical-align-middle"
      v-slot="props"
      :td-attrs="() => ({ style: { width: '100%' } })"
    >
      <div :ref="`budgetActivityCategoryRow__${props.row.id}`" class="has-text-weight-bold is-size-5">
        {{ props.row.id === 'no-category' ? $t(props.row.name) : props.row.name }}
        {{ `(${budgetActivitiesByCategory(props.row.id).length})` }}
      </div>
    </BTableColumn>
    <BTableColumn
      field="activityDescription"
      :label="$t('common.terms.total')"
      cell-class="vertical-align-middle"
      numeric
      v-slot="props"
    >
      <div class="has-text-weight-bold is-size-5">
        {{ displayCurrency(totalAmountByCategory(props.row.id), { multiplier: 100, prefix: '$' }) }}
      </div>
    </BTableColumn>
    <template slot="detail" slot-scope="props">
      <section class="app-page__section">
        <CreateOrEditActivityCard
          v-if="!isProjectArchived(activeProject.id)"
          :budgetId="budgetId"
          :activityCategoryId="props.row.id"
          customClass="u-border-radius-bottom-right-0 u-border-radius-bottom-left-0"
          @submitSuccess="handleCreateActivity"
        />
      </section>
      <BudgetActivitiesTable
        :activities="budgetActivitiesByCategory(props.row.id)"
        :budgetId="budgetId"
        :customClass="!isProjectArchived(activeProject.id) ? 'u-border-radius-top-left-0 u-border-radius-top-right-0' : ''"
      />
    </template>
    <template #empty>
      <div class="has-text-grey u-p-a has-text-centered">
        {{ $t('budgetActivitiesTable.emptyState') }}
      </div>
    </template>
  </BTable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency } from '@/helpers/stringHelpers';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import BudgetActivitiesTable from '@/components/BudgetActivitiesTable.vue';

export default {
  name: 'BudgetActivityCategoriesTable',

  components: {
    CreateOrEditActivityCard,
    BudgetActivitiesTable,
  },

  props: {
    budgetId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    openedCategories: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
    }),
    ...mapGetters([
      'getBudgetActivities',
      'isProjectArchived',
    ]),
    budgetActivitiesByCategory() {
      return categoryId => this.getBudgetActivities(this.budgetId)
        .filter(a => (
          categoryId === 'no-category'
            ? !a.activityCategoryId
            : a.activityCategoryId === categoryId
        ))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    totalAmountByCategory() {
      return categoryId => this.budgetActivitiesByCategory(categoryId)
        .reduce((acc, { unitValue, quantity }) => (acc + calculateActivityBudget(unitValue, quantity)), 0);
    },
    activeProject() {
      return this.projects[this.projectId];
    },
  },

  methods: {
    displayCurrency,
    handleOpenCategoryRowDetails(row) {
      this.$emit('openCategory', row);
    },
    handleCloseCategoryRowDetails(row) {
      this.$emit('closeCategory', row);
    },
    handleCreateActivity(activityId) {
      this.$emit('createActivity', activityId);
    },
  },
};
</script>
