<template>
  <div>
    <BModal
      :active="isPreviewModalOpen"
      hasModalCard
      @close="handleClosePreviewModal"
      trapFocus
      ariaModal
      :canCancel="true"
    >
      <form @submit.prevent="handleSubmitActivities">
        <div class="modal-card" >
          <div class="modal-card-head">
            <p class="modal-card-title">{{ $t('importActivitiesView.activitiesPreview', { total: activitiesPreview.length })}}</p>
            <BButton type="is-text" size="is-small" @click="handleClosePreviewModal">
              <BIcon icon="close" />
            </BButton>
          </div>
          <div class="modal-card-body">
            <BField v-if="previewErrors.length > 0">
              <BMessage type="is-danger">
                <p v-for="error in previewErrors" :key="error.index">
                  {{ $t('importActivitiesView.previewError', { lineNumber: error.index + 1 }) }}
                </p>
              </BMessage>
            </BField>
            <BField v-if="submissionError">
              <BMessage type="is-danger">
                {{ submissionError.message }}
              </BMessage>
            </BField>
            <BTable
              :data="activitiesPreview"
              hoverable
              striped
              narrowed
              class="card"
              :mobile-cards="false"
              :rowClass="getPreviewRowClass"
            >
              <BTableColumn
                field="name"
                :label="$t('importActivitiesView.previewTable.columns.name')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                {{ props.row.name }}
              </BTableColumn>
              <BTableColumn
                field="quantity"
                :label="$t('importActivitiesView.previewTable.columns.quantity')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                {{ props.row.quantity }}
              </BTableColumn>
              <BTableColumn
                field="unit"
                :label="$t('importActivitiesView.previewTable.columns.unit')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                {{ props.row.unit }}
              </BTableColumn>
              <BTableColumn
                field="unitValue"
                :label="$t('importActivitiesView.previewTable.columns.unitValue')"
                cell-class="vertical-align-middle"
                v-slot="props"
                sortable
              >
                {{ props.row.unitValue }}
              </BTableColumn>
            </BTable>
          </div>
          <footer class="modal-card-foot">
            <div style="display: flex; width: 100%; justify-content: flex-end;">
              <div class="buttons">
                <BButton
                  type="light"
                  @click="handleClosePreviewModal"
                  @keyup.enter="handleClosePreviewModal"
                >
                  {{ $t('common.ctas.cancel') }}
                </BButton>
                <BButton
                  nativeType="submit"
                  type="is-primary"
                  :loading="isSubmissionInProgress"
                  size="is-large"
                  :disabled="previewErrors.length > 0"
                >
                  {{ $t('common.ctas.importActivities') }}
                </BButton>
              </div>
            </div>
          </footer>
        </div>
      </form>
    </BModal>
    <section class="app-page__header">
      <div class="app-page__header__title">
        <h4 class="title is-4">{{ $t('common.titles.importActivities') }}</h4>
      </div>
    </section>
    <section class="app-page__section">
      <div class="card u-m-b">
        <div class="card-content">
          <div>
            <h4 class="title is-5">
              1 -
              <span>
                {{ $t('importActivitiesView.step1.subtitle') }}
              </span>
            </h4>
            <BField class="is-primary mt-2">
              <BButton icon-left="download" @click="handleDownloadTemplate">
              {{ $t('importActivitiesView.ctas.downloadTemplate') }}
              </BButton>
            </BField>
          </div>
        </div>
      </div>
      <div class="card u-m-b">
        <div class="card-content">
          <div>
            <h4 class="title is-5">
              2 -
              <span>
                {{ $t('importActivitiesView.step2.subtitle') }}
              </span>
            </h4>
            <p class="step__body">
              {{ $t('importActivitiesView.step2.body') }}
            </p>
          </div>
        </div>
      </div>
      <div class="card u-m-b">
        <div class="card-content">
          <div>
            <h4 class="title is-5">
              3 -
              <span>
                {{ $t('importActivitiesView.step3.subtitle') }}
              </span>
            </h4>
            <BField v-if="fileError">
              <BMessage type="is-danger">
                {{ fileError.message }}
              </BMessage>
            </BField>
            <BField>
              <BUpload v-model="file" dragDrop expanded>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <BIcon v-if="!isProcessingCsv" icon="upload" size="is-large" />
                      <span v-else style="min-height: 44px; display: block;">
                        <BLoading :isFullPage="false" :canCancel="false" :active="isProcessingCsv" />
                      </span>
                    </p>
                    <p>{{ $t('common.ctas.fileUpload') }}</p>
                  </div>
                </section>
              </BUpload>
            </BField>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Papaparse from 'papaparse';
import { saveAs } from 'file-saver';
import { camelCase } from '@/helpers/stringHelpers';

export default {
  name: 'ImportActivities',

  props: {
    submissionError: {
      type: Object,
      default: null,
    },
    isSubmissionInProgress: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'submit',
  ],

  data() {
    return {
      file: null,
      fileError: null,
      isProcessingCsv: false,
      activitiesPreview: [],
      isPreviewModalOpen: false,
    };
  },

  watch: {
    file() {
      if (!this.file) {
        return;
      }
      const acceptedFileTypes = [
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];

      if (!acceptedFileTypes.includes(this.file.type)) {
        this.fileError = { message: 'Incorrect file type, please upload a CSV' };
        this.file = null;
        return;
      }

      this.parseActivitiesFile(this.file, this.showFilePreview);
    },
  },

  computed: {
    previewErrors() {
      return this.activitiesPreview
        .map((activity, index) => ({ ...activity, index }))
        .filter(activity => !activity.name || !activity.quantity || !activity.unit || !activity.unitValue);
    },
  },

  methods: {
    handleSubmitActivities() {
      this.$emit('submit', this.activitiesPreview);
    },
    handleDownloadTemplate() {
      const template = Papaparse.unparse({
        fields: [
          this.$t('importActivitiesView.template.name').toUpperCase(),
          this.$t('importActivitiesView.template.quantity').toUpperCase(),
          this.$t('importActivitiesView.template.unit').toUpperCase(),
          this.$t('importActivitiesView.template.unitValue').toUpperCase(),
        ],
        // data: [
        //   [
        //     this.$t('importActivitiesView.template.codeExample'),
        //     this.$t('importActivitiesView.template.nameExample'),
        //   ],
        // ],
      });
      const fileName = this.$t('importActivitiesView.template.fileName');
      const file = new File([template], fileName, {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(file);
    },
    parseActivitiesFile(file, callback) {
      this.isProcessingCsv = true;
      Papaparse.parse(file, {
        header: true,
        skipEmptyLines: true, // need for some reason last line is empty
        complete: parsed => callback(parsed),
        error: console.error,
      });
    },
    showFilePreview(parsedFile) {
      this.isProcessingCsv = false;
      const { data } = parsedFile;
      this.activitiesPreview = this.shapeActivityProperties(data);
      this.isPreviewModalOpen = true;
    },
    shapeActivityProperties(importedActivities) {
      const spanishKeys = {
        NOMBRE: 'NAME',
        CANTIDAD: 'QUANTITY',
        UNIDAD: 'UNIT',
        'VALOR UNITARIO': 'UNIT VALUE',
      };
      return importedActivities.map(activity => (
        Object.entries(activity).reduce((acc, [key, value]) => ({
          ...acc,
          // This ternary will transform spanish to english keys so they can be sent to backend.
          // @TODO -- come up with solution that will support multiple languages in an agnostic fashion.
          [Object.keys(spanishKeys).includes(key) ? camelCase(spanishKeys[key]) : camelCase(key)]: value,
        }), {})
      ));
    },
    getPreviewRowClass(row) {
      const {
        name,
        quantity,
        unit,
        unitValue,
      } = row;

      return !name || !quantity || !unit || !unitValue ? 'has-background-danger-light has-text-danger' : '';
    },
    clearFile() {
      this.file = null;
      this.activitiesPreview = [];
    },
    handleClosePreviewModal() {
      this.clearFile();
      this.isPreviewModalOpen = false;
    },
  },
};
</script>
