<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ activeProject.name }}</h4>
        </div>
      </section>

      <section class="app-page__section">
        <div>
          <form @submit.prevent="handleSubmitEditProject">
            <BField v-if="formSubmissionError">
              <BMessage type="is-danger">
                {{ formSubmissionError.message }}
              </BMessage>
            </BField>
            <h5 class="title is-5 has-text-grey">
              {{ $t('common.terms.details') }}
            </h5>
            <div class="columns is-multiline">
              <BField
                class="column is-6"
                :label="$t(editProjectForm.name.label)"
                :type="formErrors[$t(editProjectForm.name.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(editProjectForm.name.name)] || ''"
              >
                <BInput
                  v-model="editProjectForm.name.value"
                  :ref="editProjectForm.name.name"
                  :disabled="isFormSubmissionInProgress"
                  @input="handleTouchForm"
                />
              </BField>
              <BField
                class="column is-6"
                :label="$t(editProjectForm.description.label)"
                :type="formErrors[$t(editProjectForm.description.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(editProjectForm.description.name)] || ''"
              >
                <BInput
                  v-model="editProjectForm.description.value"
                  :ref="editProjectForm.description.name"
                  :disabled="isFormSubmissionInProgress"
                  @input="handleTouchForm"
                  type="textarea"
                  maxlength="200"
                  rows="2"
                />
              </BField>
              <BField
                class="column is-6"
                :label="$t(editProjectForm.client.label)"
                :type="formErrors[$t(editProjectForm.client.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(editProjectForm.client.name)] || ''"
              >
                <BSelect
                  :placeholder="$t(editProjectForm.client.placeholder)"
                  v-model="editProjectForm.client.value"
                  expanded
                >
                  <option
                    v-for="option in clients"
                    :value="option.id"
                    :key="option.id"
                  >
                    {{ option.name }}
                  </option>
                </BSelect>
              </BField>
            </div>
            <h5 class="title is-5 has-text-grey">
              {{ $t('common.titles.users') }}
            </h5>
            <div class="columns">
              <div class="column">
                <div class="card">
                  <div class="card-content is-flex u-p-b-0">
                    <h3 class="title is-6 u-m-b-xsmall">
                      {{ $t('common.terms.members') }}
                    </h3>
                    <BDropdown
                      position="is-bottom-left"
                      append-to-body
                      aria-role="menu"
                      trap-focus
                      style="margin-left: auto;"
                      :scrollable="true"
                      maxHeight="300px"
                    >
                      <template #trigger>
                        <BButton
                          type="is-primary"
                          outlined
                          size="is-small"
                          icon-left="plus-thick"
                        >
                          Anadir Miembro
                        </BButton>
                      </template>
                      <!-- <BDropdownItem
                        aria-role="menu-item"
                        custom
                      >
                        <input type="text" class="input" />
                      </BDropdownItem> -->
                      <template v-if="addUsersList.length > 0">
                        <BDropdownItem
                          v-for="user in addUsersList"
                          :key="user.id"
                          aria-role="menu-item"
                          custom
                          style="width: 300px;"
                        >
                          <CardRow isCompact isClickable @click="handleAddUserToProject(user.id)">
                            <template v-slot:content-left>
                              <UserAvatar :user="user" class="u-m-r-small" size="xsmall" />
                              <div>
                                <span>
                                  <UserDisplayName :activeUserId="user.id" size="medium" is-inline :is-clickable="false" />
                                  <!-- <small v-if="!user.isActive" class="has-text-danger is-size-7 is-italic">
                                    User Archived
                                  </small> -->
                                </span>
                              </div>
                            </template>
                            <template v-slot:content-right>
                              <BLoading
                                v-if="isUserBeingAddedToProject.includes(user.id)"
                                :is-full-page="false"
                                :can-cancel="false" size="is-small"
                              />
                            </template>
                          </CardRow>
                        </BDropdownItem>
                      </template>
                      <div v-else class="u-p-a" style="width: 300px;">
                        No hay mas usuarios.
                      </div>
                    </BDropdown>
                  </div>
                  <div class="card-content">
                    <CardRow
                      v-for="user in projectUsers"
                      :key="user.id"
                    >
                      <template v-slot:content-left>
                        <UserAvatar :user="user" class="u-m-r-small" size="small" />
                        <div>
                          <span>
                            <UserDisplayName :activeUserId="user.id" size="medium" is-inline :is-clickable="false" />
                            <!-- <small v-if="!user.isActive" class="has-text-danger is-size-7 is-italic">
                              User Archived
                            </small> -->
                          </span>
                          <p class="subtitle is-size-6 is-size-7-mobile has-text-grey">
                            {{ user.email }}
                          </p>
                        </div>
                      </template>
                      <template v-slot:content-right>
                        <BButton
                          type="is-danger is-light"
                          size="is-small"
                          @click="handleRemoveUserFromProject(user.id)"
                          :disabled="user.id === loggedInUser.id"
                        >
                          <BIcon icon="close" size="mdi-18px" />
                        </BButton>
                      </template>
                  </CardRow>
                  </div>
                </div>
              </div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <BButton
                type="is-danger"
                :iconLeft="isProjectArchived(activeProject.id) ? 'folder-lock-open': 'folder-lock'"
                :disabled="isFormSubmissionInProgress || isProjectArchivingInProgress"
                :loading="isProjectArchivingInProgress"
                @click="handleArchiveProject"
                @keyup.enter="handleArchiveProject"
                outlined
              >
                {{ isProjectArchived(activeProject.id) ? $t('common.ctas.unarchive') : $t('common.ctas.archive') }} {{ $t('common.terms.project') }}
              </BButton>
              <BButton
                type="is-primary"
                native-type="submit"
                :disabled="isFormSubmissionInProgress || isProjectArchivingInProgress"
                :loading="isFormSubmissionInProgress"
              >
                {{ $t('common.ctas.save') }}
              </BButton>
            </div>
          </form>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import * as ProjectActions from '@/store/actions/Project.actions';
import * as UserActions from '@/store/actions/User.actions';
import * as ClientActions from '@/store/actions/Client.actions';
import * as ValidationService from '@/services/Validation.service';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import CardRow from '@/components/CardRow.vue';
import UserDisplayName from '@/components/UserDisplayName.vue';
import UserAvatar from '@/components/UserAvatar.vue';

export default {
  name: 'EditProjectView',

  components: {
    CardRow,
    UserDisplayName,
    UserAvatar,
  },

  data() {
    return {
      isPageLoading: true,
      isFormSubmissionInProgress: false,
      isProjectArchivingInProgress: false,
      archivingError: null,
      formSubmissionError: null,
      editProjectForm: {
        name: {
          label: 'editProjectForm.name.label',
          name: 'editProjectForm.name.name',
          placeholder: 'editProjectForm.name.placeholder',
          value: '',
          required: true,
        },
        description: {
          label: 'editProjectForm.description.label',
          name: 'editProjectForm.description.name',
          placeholder: 'editProjectForm.description.placeholder',
          value: '',
          required: false,
        },
        client: {
          label: 'editProjectForm.client.label',
          name: 'editProjectForm.client.name',
          placeholder: 'editProjectForm.client.placeholder',
          value: null,
          required: false,
        },
      },
      formErrors: {},
      isUserBeingAddedToProject: [],
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      users: state => state.user.users,
      clients: state => state.client.clients,
    }),
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
      'getProjectStatusByName',
      'isProjectArchived',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    projectUsers() {
      return this.activeProject.users.map(id => this.users[id]);
    },
    addUsersList() {
      const projectUserIds = this.projectUsers.map(user => user.id);
      const nonProjectUsers = Object.values(this.users).filter(user => !projectUserIds.includes(user.id));

      return [...nonProjectUsers];
    },
    shapeUpdatedProject() {
      const {
        editProjectForm: {
          name,
          description,
          client,
        },
      } = this;

      return {
        name: name.value,
        description: description.value,
        clientId: client.value,
      };
    },
    statusIdForArchiveToggle() {
      return this.isProjectArchived(this.activeProjectId) ? this.getProjectStatusByName('active')?.id : this.getProjectStatusByName('archived')?.id;
    },
  },

  mounted() {
    this.fetchInitialData();
  },

  methods: {
    async handleSubmitEditProject() {
      this.formErrors = {};
      this.formSubmissionError = null;
      this.formErrors = ValidationService.runFormValidation({ ...this.editProjectForm }, { i18n: true });
      if (!isObjectEmpty(this.formErrors)) return;

      this.isFormSubmissionInProgress = true;
      try {
        await this.$store.dispatch(
          ProjectActions.EDIT_PROJECT,
          { projectId: this.activeProjectId, updatedProject: { ...this.shapeUpdatedProject } },
        );

        NotificationService.showNotification(NotificationTypes.UPDATE_PROJECT.SUCCESS);
        this.$router.push(`/projects/${this.activeProjectId}/details`);
      } catch (err) {
        this.formSubmissionError = err;
        console.error(err) // eslint-disable-line
        NotificationService.showNotification(NotificationTypes.UPDATE_PROJECT.ERROR);
      }
      this.isFormSubmissionInProgress = false;
    },
    async handleAddUserToProject(userId) {
      this.isUserBeingAddedToProject.push(userId);
      try {
        await this.$store.dispatch(
          ProjectActions.EDIT_PROJECT,
          {
            projectId: this.activeProjectId,
            updatedProject: {
              userIds: [...this.projectUsers.map(u => u.id), userId],
            },
          },
        );
      } catch (err) {
        console.error(err) // eslint-disable-line
        NotificationService.showNotification(NotificationTypes.UPDATE_PROJECT.ERROR);
      }

      this.isUserBeingAddedToProject = this.isUserBeingAddedToProject
        .filter(id => id !== userId);
    },
    async handleRemoveUserFromProject(userId) {
      try {
        await this.$store.dispatch(
          ProjectActions.EDIT_PROJECT,
          {
            projectId: this.activeProjectId,
            updatedProject: {
              removedUserIds: [userId],
            },
          },
        );
      } catch (err) {
        console.error(err) // eslint-disable-line
        NotificationService.showNotification(NotificationTypes.UPDATE_PROJECT.ERROR);
      }
    },
    async handleArchiveProject() {
      this.isProjectArchivingInProgress = true;
      try {
        await this.$store.dispatch(
          ProjectActions.EDIT_PROJECT,
          { projectId: this.activeProjectId, updatedProject: { statusId: this.statusIdForArchiveToggle } },
        );

        NotificationService.showNotification(NotificationTypes.UPDATE_PROJECT.SUCCESS);
        this.$router.push(`/projects/${this.activeProjectId}/details`);
      } catch (err) {
        this.archivingError = err;
        console.error(err) // eslint-disable-line
        NotificationService.showNotification(NotificationTypes.UPDATE_PROJECT.ERROR);
      }
      this.isProjectArchivingInProgress = false;
    },
    handleTouchForm() {
      console.log('form touched');
    },
    setInitialFormState() {
      const {
        name,
        description,
        clientId,
      } = this.activeProject;

      this.editProjectForm.name.value = name || '';
      this.editProjectForm.description.value = description || '';
      this.editProjectForm.client.value = this.clients[clientId]?.id;
    },
    async fetchInitialData() {
      this.isPageLoading = true;

      await Promise.all([
        this.$store.dispatch(ProjectActions.FETCH_PROJECT_BY_ID, { projectId: this.activeProjectId }),
        this.$store.dispatch(UserActions.FETCH_ORGANIZATION_USERS, { organizationId: this.activeOrganization.id }),
        this.$store.dispatch(ClientActions.FETCH_ORGANIZATION_CLIENTS, { organizationId: this.activeOrganization.id }),
      ]);

      this.setInitialFormState();
      this.isPageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
