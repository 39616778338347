var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"user-display-name",class:{ 'is-clickable': _vm.isClickable },on:{"click":function($event){$event.preventDefault();return _vm.handleUserClick($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleUserClick($event)}}},[(_vm.size === 'small')?_c('small',{class:[
      'is-size-7',
      { 'is-block': !_vm.isInline } ],staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.userDisplayName)+" ")]):_vm._e(),(_vm.size === 'medium')?_c('h6',{class:[
      'title',
      'is-6',
      { 'is-inline': _vm.isInline } ],staticStyle:{"word-break":"normal"}},[_vm._v(" "+_vm._s(_vm.userDisplayName)+" ")]):(_vm.size === 'large')?_c('h5',{class:[
      'title',
      'is-5',
      { 'is-inline': _vm.isInline } ]},[_vm._v(" "+_vm._s(_vm.userDisplayName)+" ")]):(_vm.size === 'x-large')?_c('h4',{class:[
      'title',
      'is-4',
      { 'is-inline': _vm.isInline } ]},[_vm._v(" "+_vm._s(_vm.userDisplayName)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }