export function addPrefix(string, prefix) {
  return prefix ? `${prefix}${string}` : string;
}

export function addSuffix(string, suffix) {
  return suffix ? `${string}${suffix}` : string;
}

export function displayCurrency(number, options = { multiplier: 0, prefix: '', compact: false }) {
  if (!number) return addPrefix('0.00', options.prefix);

  if (options.multiplier) {
    number /= options.multiplier; // eslint-disable-line no-param-reassign
  }

  // @TODO implement new currency shaper.
  if (options.compact) console.log('compact currency');

  const shapedString = number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return addPrefix(shapedString, options.prefix);
}

export function titleCase(str) {
  return str.replace(/\b(\w)/g, char => char.toUpperCase());
}

export function camelCase(str) {
  return titleCase(str.toLowerCase()).split(' ').join('').replace(/\b(\w)/g, char => char.toLowerCase());
}

export function getInitialsFromFirstAndLast(firstName, lastName) {
  if (!firstName || !lastName) return '';

  const fullName = titleCase(firstName.concat(' ', lastName));

  return fullName.match(/\b(\w)/g).join('').substring(0, 2);
}

export function removeLineBreaks(str) {
  return str.replace(/\r?\n|\r/g, '');
}

export function doesStringHaveLineBreaks(str) {
  return str.match(/\r?\n|\r/g);
}

export function truncateString(string, maxLength) {
  if (string.length <= maxLength) return string;

  const truncatedString = string.substring(0, maxLength);

  return truncatedString.concat('...');
}
