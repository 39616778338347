<template>
  <nav
    id="app-header"
    :class="[
      'navbar',
      'is-fixed-top',
      { 'navbar--no-shadow': doesRouteHaveBreadcrumbs },
      { 'has-side-navigation': doesRouteHaveSideNavigation },
    ]"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-menu">
      <div class="navbar-start">
        <div v-if="doesRouteHaveSideNavigation" class="navbar-item menu-button__container">
          <BIcon icon="menu" @click.native="handleToggleSideNavigation(true)" />
        </div>
        <div v-else class="navbar-item">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="navbar-item navbar-center is-hidden-mobile">
        <div style="width: 180px;">
          <TheLogo />
        </div>
      </div>
      <div class="navbar-end">
        <div v-if="!isObjectEmpty(authUser)" class="navbar-item">
          <div class="buttons">
            <BButton type="is-white" @click="handleLogOut" @keyup.enter="handleLogOut" icon-left="logout">
              {{ $t('common.ctas.logOut') }}
            </BButton>
          </div>
        </div>
        <div v-else-if="isObjectEmpty(authUser)" class="navbar-item">
          <div class="buttons">
            <BButton type="is-white" tag="router-link" to="/log-in">
              {{ $t('common.ctas.logIn') }}
            </BButton>
            <BButton type="is-primary" outlined tag="router-link" to="/sign-up">
              {{ $t('common.ctas.signUp') }}
            </BButton>
          </div>
        </div>
        <div v-else class="navbar-item">
          <BDropdown class="u-m-b-xsmall" position="is-bottom-left" :mobile-modal="false">
            <div class="app-header__avatar__container" slot="trigger">
              <user-avatar
                size="small"
              />
            </div>
            <BDropdown-item @click="handleUserProfileClick">
              <BIcon icon="account" size="is-small" />&nbsp;&nbsp;My Profile
            </BDropdown-item>
            <BDropdown-item separator />
            <BDropdown-item @click="handleLogOut">
              <BIcon icon="logout" size="is-small" />&nbsp;&nbsp;Log Out
            </BDropdown-item>
          </BDropdown>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import * as AuthActions from '@/store/actions/Auth.actions';
import * as UiMutations from '@/store/mutations/Ui.mutations';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import TheLogo from './TheLogo.vue';

export default {
  name: 'AppHeader',

  components: {
    LocaleSwitcher,
    TheLogo,
  },

  props: {
    doesRouteHaveSideNavigation: {
      type: Boolean,
      required: false,
      default: false,
    },
    doesRouteHaveBreadcrumbs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isAvatarDropdownActive: false,
      isStandupLaunching: false,
    };
  },

  computed: {
    ...mapState({
      authUser: state => state.auth.authUser,
    }),
    ...mapGetters([
      'loggedInUser',
    ]),
  },

  methods: {
    ...mapMutations({
      setSideNavigationState: UiMutations.SET_SIDE_NAVIGATION_STATE,
    }),
    isObjectEmpty,
    async handleLogOut() {
      await this.$store.dispatch(AuthActions.LOG_OUT_USER);
      this.$router.push('/');
    },
    handleToggleSideNavigation(isSideNavigationOpen) {
      this.setSideNavigationState({ isSideNavigationOpen });
    },
    handleAvatarClick(event) {
      event.preventDefault();
    },
    handleUserProfileClick() {
      this.$router.push('/my-profile');
    },
  },
};
</script>

<style lang="scss" scoped>
.has-side-navigation {
  right: 0;
  @media screen and (min-width: $breakpoint-to-hide-side-navigation) {
    width: calc(100vw - #{$side-navigation-width});
    left: $side-navigation-width;
  }
}

.navbar {
  box-shadow: $light-shadow;
  flex: 1;
  display: flex;
  justify-content: center;

  &--no-shadow {
    border-bottom: 1px solid $grey-lighter;
    box-shadow: none;
  }
}

.navbar-start {
  flex: 1;
}

.navbar-end {
  flex: 1;
}

.navbar__visitor-links {
  display: flex;
}

.menu-button__container {
  @media screen and (min-width: $breakpoint-to-hide-side-navigation) {
    display: none;
  }
}

.app-header__avatar__container {
  cursor: pointer;
}

.navbar-item.navbar-center {
  flex-direction: column;
  justify-content: center;
}

.app-header__logo__icon {
  width: 22px;
}
</style>
