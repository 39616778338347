import * as TypeMutations from '@/store/mutations/Type.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as BillItemMutations from '@/store/mutations/BillItem.mutations';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  billItemTypes: {},
});

const receiveBillItemTypes = (state, payload) => {
  state.billItemTypes = {
    ...state.billItemTypes,
    ...payload.billItemTypes,
  };
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  getBillItemTypeByName(state) {
    return name => Object.values(state.billItemTypes)
      .filter(billItemType => billItemType.name === name)[0];
  },
};

const mutations = {
  [TypeMutations.RECEIVE_TYPES]: receiveBillItemTypes,
  [BillMutations.RECEIVE_BILLS]: receiveBillItemTypes,
  [BillItemMutations.RECEIVE_BILL_ITEMS]: receiveBillItemTypes,
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveBillItemTypes,
};

const actions = {
  // Placeholder
};

export default {
  state,
  getters,
  actions,
  mutations,
};
