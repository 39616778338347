import * as UserMutations from '@/store/mutations/User.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as TypeMutations from '@/store/mutations/Type.mutations';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  projectStatuses: {},
});

const receiveProjectStatuses = (state, payload) => {
  state.projectStatuses = {
    ...state.projectStatuses,
    ...payload.projectStatuses,
  };
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  getProjectStatusByName(state) {
    return name => Object.values(state.projectStatuses).find(status => status.name === name);
  },
};

const mutations = {
  [UserMutations.RECEIVE_USERS]: receiveProjectStatuses,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveProjectStatuses,
  [TypeMutations.RECEIVE_TYPES]: receiveProjectStatuses,
};

const actions = {
  // Placeholder
};

export default {
  state,
  getters,
  actions,
  mutations,
};
