import { normalize } from 'normalizr';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as UserActions from '@/store/actions/User.actions';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as AuthMutations from '@/store/mutations/Auth.mutations';
import * as ActivityCategoryMutations from '@/store/mutations/ActivityCategory.mutations';
import * as ClientMutations from '@/store/mutations/Client.mutations';
import * as AuditLogMutations from '@/store/mutations/AuditLog.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as CommentMutations from '@/store/mutations/Comment.mutations';
import * as VendorMutations from '@/store/mutations/Vendor.mutations';
import * as Api from '@/services/Api.service';
import * as schemas from '@/store/schemas';

const getInitialState = () => ({
  loggedInUserId: null,
  isWritingData: false,
  isFetchingData: false,
  users: {},
});

const receiveUsers = (state, payload) => {
  state.users = {
    ...state.users,
    ...payload.users,
  };
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  loggedInUser(state) {
    return state.users[state.loggedInUserId] || {};
  },
};

const mutations = {
  [UserMutations.USER_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [UserMutations.USER_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [UserMutations.USER_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [UserMutations.RECEIVE_USERS]: receiveUsers,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveUsers,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveUsers,
  [ClientMutations.RECEIVE_CLIENTS]: receiveUsers,
  [AuditLogMutations.RECEIVE_AUDIT_LOGS]: receiveUsers,
  [BillMutations.RECEIVE_BILLS]: receiveUsers,
  [ActivityCategoryMutations.RECEIVE_ACTIVITY_CATEGORIES]: receiveUsers,
  [CommentMutations.RECEIVE_COMMENTS]: receiveUsers,
  [VendorMutations.RECEIVE_VENDORS]: receiveUsers,
  [UserMutations.SET_LOGGED_IN_USER](state, payload) {
    state.loggedInUserId = payload;
  },
  [AuthMutations.LOG_OUT](state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  async [UserActions.CREATE_USER_SIGN_UP](context, payload) {
    context.commit(UserMutations.USER_BEGIN_WRITE_DATA);
    const { newUser } = payload;
    const { data: { user } } = await Api.user.signUp(newUser); // eslint-disable-line

    const normalizedUser = normalize(user, schemas.user);
    context.commit(UserMutations.RECEIVE_USERS, normalizedUser.entities);
    context.commit(UserMutations.USER_COMPLETE_WRITE_DATA);

    return normalizedUser;
  },

  async [UserActions.CREATE_USER](context, payload) {
    context.commit(UserMutations.USER_BEGIN_WRITE_DATA);
    try {
      const { newUser } = payload;
      const { data: { user } } = await Api.user.create(newUser); // eslint-disable-line

      const normalizedUser = normalize(user, schemas.user);
      context.commit(UserMutations.RECEIVE_USERS, normalizedUser.entities);
      context.commit(UserMutations.USER_COMPLETE_WRITE_DATA);

      return normalizedUser;
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [UserActions.FETCH_USERS](context, payload) {
    context.commit(UserMutations.USER_REQUEST_DATA);
    try {
      const { data: { users } } = await Api.user.findMany(payload); // eslint-disable-line
      const normalizedUsers = normalize(users, [schemas.user]);

      context.commit(UserMutations.RECEIVE_USERS, normalizedUsers.entities);

      return normalizedUsers;
    } catch (err) {
      console.error(err);
      throw new Error(err.message);
    }
  },

  async [UserActions.FETCH_USER_BY_ID](context, payload) {
    context.commit(UserMutations.USER_REQUEST_DATA);
    try {
      const { data: { user } } = await Api.user.findOne(payload.userId);
      const normalizedUser = normalize(user, schemas.user);

      context.commit(UserMutations.RECEIVE_USERS, normalizedUser.entities);
      return user.id;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.message);
    }
  },

  async [UserActions.FETCH_ORGANIZATION_USERS](context, payload) {
    context.commit(UserMutations.USER_REQUEST_DATA);
    try {
      const { data: { users } } = await Api.organization.user.findMany(payload.organizationId);
      const normalizedUsers = normalize(users, [schemas.user]);

      context.commit(UserMutations.RECEIVE_USERS, normalizedUsers.entities);
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.message);
    }
  },

  async [UserActions.UPDATE_USER](context, payload) {
    context.commit(UserMutations.USER_BEGIN_WRITE_DATA);
    try {
      const { id, updatedUser } = payload;
      const { data: { user } } = await Api.user.updateOne(id, updatedUser);
      const normalizedUser = normalize(user, schemas.user);

      context.commit(UserMutations.RECEIVE_USERS, normalizedUser.entities);
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.message);
    }
  },

  async [UserActions.UPDATE_USER_ROLE](context, payload) {
    context.commit(UserMutations.USER_BEGIN_WRITE_DATA);
    try {
      const { id, roleId } = payload;
      const { data: { user } } = await Api.user.role.updateOne(id, { roleId });
      const normalizedUser = normalize(user, schemas.user);

      context.commit(UserMutations.RECEIVE_USERS, normalizedUser.entities);
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err.message);
    }
  },

  async [UserActions.ACCEPT_INVITATION](context, payload) {
    context.commit(UserMutations.USER_BEGIN_WRITE_DATA);
    try {
      const { data: { user } } = await Api.invitation.accept(payload.invitationId);

      const normalizedUser = normalize(user, schemas.user);

      context.commit(UserMutations.RECEIVE_USERS, normalizedUser.entities);
      await firebase.auth().currentUser.reload();

      context.commit(
        AuthMutations.SET_AUTH_USER,
        { authUser: firebase.auth().currentUser },
      );
      context.commit(UserMutations.USER_COMPLETE_WRITE_DATA);

      return normalizedUser;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [UserActions.RESEND_USER_INVITATION](context, payload) {
    context.commit(UserMutations.USER_BEGIN_WRITE_DATA);
    try {
      const { data: { user } } = await Api.user.invitation.resend(payload.userId);

      const normalizedUser = normalize(user, schemas.user);

      context.commit(UserMutations.RECEIVE_USERS, normalizedUser.entities);
      context.commit(UserMutations.USER_COMPLETE_WRITE_DATA);

      return normalizedUser;
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
