<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <UpgradeSubscriptionModal :isOpen="isSubscriptionModalOpen" :canCancel="false" />
      <CreateOrEditClientModal
          :isOpen="isAddClientModalOpen"
          :initialClientName="selectedClientName"
          @close="handleToggleAddClientModal"
          @submitSuccess="handleCreateClientSuccess"
        />
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('createProjectView.title') }}</h4>
        </div>
      </section>

      <section class="app-page__section">
        <div>
          <form @submit.prevent="handleSubmitCreateProject">
            <h5 class="title is-5 has-text-grey">
              {{ $t('createProjectView.subtitle') }}
            </h5>
            <BField v-if="apiError">
              <BMessage type="is-danger">
                {{ apiError.message }}
              </BMessage>
            </BField>
            <div class="columns is-multiline">
              <BField
                :class="['column', 'is-6', { 'required': createProjectForm.name.required }]"
                :label="$t(createProjectForm.name.label)"
                :type="formErrors[$t(createProjectForm.name.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(createProjectForm.name.name)] || ''"
              >
                <BInput
                  v-model="createProjectForm.name.value"
                  :ref="createProjectForm.name.name"
                  :disabled="isFormSubmissionInProgress"
                  @input="handleTouchForm"
                />
              </BField>
              <BField
                class="column is-6"
                :label="$t(createProjectForm.description.label)"
                :type="formErrors[$t(createProjectForm.description.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(createProjectForm.description.name)] || ''"
              >
                <BInput
                  v-model="createProjectForm.description.value"
                  :ref="createProjectForm.description.name"
                  :disabled="isFormSubmissionInProgress"
                  @input="handleTouchForm"
                  type="textarea"
                  maxlength="200"
                  rows="2"
                />
              </BField>
              <BField
                class="column is-6"
                :label="$t(createProjectForm.client.label)"
                :type="formErrors[$t(createProjectForm.client.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(createProjectForm.client.name)] || ''"
              >
                  <BAutocomplete
                  v-model="selectedClientName"
                      :disabled="isFormSubmissionInProgress"
                      ref="autocompleteClientField"
                      :data="filteredClientsArray"
                      field="id"
                      :placeholder="$t(createProjectForm.client.placeholder)"
                      @select="handleSelectClient"
                      :custom-formatter="option => option.name"
                      open-on-focus
                      clearable
                      selectable-footer>
                      <template #footer>
                        <div @click="handleToggleAddClientModal" type="is-info" outlined size="is-small" style="width: 100%;">
                          <span class="autocomplete__footer__cta">
                            <BIcon icon="plus-circle-outline" customSize="mdi-18px" />
                            {{
                              (selectedClientName && !createProjectForm.client.value)
                                ? $t('createProjectView.ctas.createClient', { clientName: selectedClientName })
                                : $t('common.ctas.createClient')
                            }}
                          </span>
                        </div>
                      </template>
                      <template #empty>
                        {{ $t('common.emptyStates.noResults') }}
                      </template>
                  </BAutocomplete>
              </BField>
            </div>
            <div class="is-flex is-justify-content-flex-end">
              <BButton
                type="is-primary"
                native-type="submit"
                :disabled="isFormSubmissionInProgress"
                :loading="isFormSubmissionInProgress"
              >
                {{ $t('common.ctas.createProject') }}
              </BButton>
            </div>
          </form>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as ProjectActions from '@/store/actions/Project.actions';
import * as ClientActions from '@/store/actions/Client.actions';
import * as ValidationService from '@/services/Validation.service';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import UpgradeSubscriptionModal from '@/components/UpgradeSubscriptionModal.vue';
import CreateOrEditClientModal from '@/components/CreateOrEditClientModal.vue';

export default {
  name: 'CreateProjectView',

  components: {
    UpgradeSubscriptionModal,
    CreateOrEditClientModal,
  },

  data() {
    return {
      isPageLoading: false,
      isFormSubmissionInProgress: false,
      formErrors: {},
      apiError: null,
      selectedClientName: '',
      isAddClientModalOpen: false,
    };
  },

  computed: {
    ...mapState({
      clients: state => state.client.clients,
      activeProjectCount: state => state.project.activeProjectCount,
    }),
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
      'getProjectStatusByName',
      'getSubscriptionTypeByName',
      'getOrganizationSubscription',
    ]),
    createProjectForm() {
      return {
        name: {
          label: 'createProjectForm.name.label',
          name: 'createProjectForm.name.name',
          placeholder: 'createProjectForm.name.placeholder',
          value: '',
          required: true,
        },
        description: {
          label: 'createProjectForm.description.label',
          name: 'createProjectForm.description.name',
          placeholder: 'createProjectForm.description.placeholder',
          value: '',
          required: false,
        },
        client: {
          label: 'createProjectForm.client.label',
          name: 'createProjectForm.client.name',
          placeholder: 'createProjectForm.client.placeholder',
          value: null,
          required: false,
        },
      };
    },
    shapeNewProject() {
      const { createProjectForm: { name, description, client } } = this;
      return {
        name: name.value,
        description: description.value,
        clientId: client.value,
      };
    },
    doesOrganizationHaveProSubscription() {
      return this.getOrganizationSubscription?.subscriptionTypeId === this.getSubscriptionTypeByName('pro').id;
    },
    isSubscriptionModalOpen() {
      return this.activeProjectCount > 0 && !this.doesOrganizationHaveProSubscription;
    },
    clientsArray() {
      return Object.values(this.clients);
    },
    filteredClientsArray() {
      return this.clientsArray.filter(option => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.selectedClientName.toLowerCase()) >= 0);
    },
  },

  async mounted() {
    await this.fetchInitialData();
  },

  methods: {
    setInitialFormState() {
      this.$refs[this.createProjectForm.name.name].$el.children[0].focus();
    },
    async fetchInitialData() {
      this.isPageLoading = true;
      const activeProjectStatus = this.getProjectStatusByName('active');

      await Promise.all([
        this.$store.dispatch(ClientActions.FETCH_ORGANIZATION_CLIENTS, { organizationId: this.activeOrganization.id }),
        this.$store.dispatch(ProjectActions.FETCH_ORGANIZATION_ACTIVE_PROJECT_COUNT, { organizationId: this.activeOrganization.id, statusId: activeProjectStatus.id }),
      ]);

      // this.setInitialFormState();
      this.isPageLoading = false;
    },
    async handleSubmitCreateProject() {
      this.formErrors = {};
      this.apiError = null;
      this.formErrors = ValidationService.runFormValidation({ ...this.createProjectForm }, { i18n: true });
      if (!isObjectEmpty(this.formErrors)) return;

      this.isFormSubmissionInProgress = true;
      try {
        const newProjectId = await this.$store.dispatch(
          ProjectActions.CREATE_PROJECT,
          { newProject: { ...this.shapeNewProject } },
        );

        this.$router.push(`/projects/${newProjectId}/details`);
      } catch (err) {
        this.apiError = err;
      }
      this.isFormSubmissionInProgress = false;
    },
    handleTouchForm() {
      console.log('form touched');
    },

    handleToggleAddClientModal() {
      this.isAddClientModalOpen = !this.isAddClientModalOpen;
    },
    handleCreateClientSuccess(clientId) {
      this.createProjectForm.client.value = clientId;
      this.selectedClientName = this.clients[clientId].name;
      this.handleToggleAddClientModal();
    },
    handleSelectClient(option) {
      if (option !== null) {
        this.createProjectForm.client.value = option.id;
      } else {
        this.createProjectForm.client.value = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
