import * as TypeMutations from '@/store/mutations/Type.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  userStatuses: {},
});

const receiveUserStatusTypes = (state, payload) => {
  state.userStatuses = {
    ...state.userStatuses,
    ...payload.userStatuses,
  };
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  getUserStatusByName(state) {
    return name => Object.values(state.userStatuses)
      .filter(userStatus => userStatus.name === name)[0];
  },
};

const mutations = {
  [TypeMutations.RECEIVE_TYPES]: receiveUserStatusTypes,
  [UserMutations.RECEIVE_USERS]: receiveUserStatusTypes,
};

const actions = {
  // Placeholder
};

export default {
  state,
  getters,
  actions,
  mutations,
};
