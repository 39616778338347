<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <ImportProjectBills
        :submissionError="submissionError"
        :isSubmissionInProgress="isSubmissionInProgress"
        @submit="handleSubmitProjectBills"
      />
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Currency from '@/constants/Currency';
import * as VendorActions from '@/store/actions/Vendor.actions';
import * as BillActions from '@/store/actions/Bill.actions';
import * as CostActions from '@/store/actions/Cost.actions';
// import * as BudgetActions from '@/store/actions/Budget.actions';
import * as ActivityActions from '@/store/actions/Activity.actions';
// import * as NotificationService from '@/services/Notification.service';
// import * as NotificationTypes from '@/constants/NotificationTypes';
import ImportProjectBills from '@/components/ImportProjectBills.vue';
import { billingTypes, billItemTypes } from '../constants/Types';

export default {
  name: 'ImportProjectBillsView',

  components: {
    ImportProjectBills,
  },

  data() {
    return {
      isPageLoading: false,
      currencyOptions: { ...Currency.defaultCurrency },
      submissionError: null,
      isSubmissionInProgress: false,
    };
  },

  computed: {
    ...mapGetters([
      'activeOrganization',
      'getBillingTypeByName',
      'fuzzyFindVendorByName',
      'getBillItemTypeByName',

    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    async handleSubmitProjectBills(bills) {
      console.log(bills);
      this.isSubmissionInProgress = true;
      for (let i = 0; i < bills.length; i++) { // eslint-disable-line
        const { name, statusId, amount, vendor, costTypeId, costCodeId } = bills[i]; // eslint-disable-line

        let vendorId = this.fuzzyFindVendorByName(vendor)?.id ?? null;
        if (!vendorId) {
          vendorId = await this.$store.dispatch(VendorActions.CREATE_VENDOR, { name: vendor }); // eslint-disable-line
        }

        const costId = await this.$store.dispatch(CostActions.CREATE_COST, { // eslint-disable-line
          newCost: {
            projectId: this.activeProjectId,
            name,
            costTypeId,
            startDate: new Date(),
            endDate: new Date(),
            billingTypeId: this.getBillingTypeByName(billingTypes.lumpSum).id,
            vendorId,
          },
        });

        const activityId = await this.$store.dispatch(ActivityActions.CREATE_ACTIVITY, { // eslint-disable-line
          newActivity: {
            name,
            quantity: 1,
            unit: this.$t('common.terms.unit'),
            unitValue: Number(amount * 100),
            costId,
            costCodeId,
          },
        });

        await this.$store.dispatch(BillActions.CREATE_BILL, { // eslint-disable-line
          newBill: {
            costId,
            name,
            billItems: [{
              activityId,
              amount: Number(amount * 100),
              billItemTypeId: this.getBillItemTypeByName(billItemTypes.advance).id,
              isTaxed: false,
            }],
            statusId,
          },
        });
      }

      // try {
      //   // eslint-disable-next-line
      //   await this.$store.dispatch(
      //     ActivityActions.CREATE_ACTIVITIES,
      //     {
      //       activities: activities.map(a => ({ ...a, unitValue: this.$ci.parse(a.unitValue || 0, this.currencyOptions) })),
      //       ...this.activeCostId && { costId: this.activeCostId },
      //       ...this.activeBudgetId && { budgetId: this.activeBudgetId },
      //     },
      //   );

      //   NotificationService.showNotification(NotificationTypes.BULK_IMPORT_ACTIVITIES(activities.length).SUCCESS);
      //   const costOrBudget = this.activeCostId ? `/costs/${this.activeCostId}` : `/budgets/${this.activeBudgetId}`;
      //   this.$router.push(`/projects/${this.activeProjectId}${costOrBudget}/details`);
      // } catch (err) {
      //   this.submissionError = err;
      // }

      this.isSubmissionInProgress = false;
      this.$router.push(`/projects/${this.activeProjectId}/bills`);
    },
    async fetchInitialData() {
      await Promise.all([
        this.$store.dispatch(VendorActions.FETCH_ORGANIZATION_VENDORS, { organizationId: this.activeOrganization.id }),
      ]);
      // if (this.activeCostId) {
      //   const costId = this.activeCostId;
      //   await this.$store.dispatch(CostActions.FETCH_COST_BY_ID, { costId });
      // } else if (this.activeBudgetId) {
      //   const budgetId = this.activeBudgetId;
      //   await this.$store.dispatch(BudgetActions.FETCH_BUDGET_BY_ID, { budgetId });
      // }

      this.isPageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
