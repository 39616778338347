<template>
  <div class="content">
    <div v-if="!isComponentLoading" class="columns">
      <div class="column">
        <h5 class="title is-5 has-text-grey">{{ $t('common.terms.details') }}</h5>
        <p>
          <strong>{{ $t('common.terms.unit') }}:&nbsp;</strong> {{ activeActivity.unit }}
        </p>
        <p>
          <strong>{{ $t('common.terms.quantity') }}:&nbsp;</strong> {{ activeActivity.quantity }}
        </p>
        <p>
          <strong>{{ $t('common.terms.unitValue') }}:&nbsp;</strong> ${{ displayCurrency(activeActivity.unitValue, { multiplier: 100 }) }}
        </p>
        <p>
          <strong>{{ $t('common.terms.createdAt') }}:&nbsp;</strong> {{ (new Date(activeActivity.createdAt)).toLocaleDateString() }}
        </p>
      </div>
      <div class="column is-8">
        <h5 class="title is-5 has-text-grey">{{ $t('activityRowDetails.advanceHistory') }}</h5>
        <BTable
          v-if="activeBillItems.length > 0"
          :data="activeBillItems"
          hoverable
          :mobile-cards="false"
          ref="advancesTable"
          class="card"
        >
          <BTableColumn
            field="advanceNumber"
            :label="$t('common.terms.number')"
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            {{ props.row.bill.number }}
          </BTableColumn>
          <BTableColumn
            field="advanceCreatedAt"
            :label="$t('common.terms.createdAt')"
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            {{ new Date(props.row.createdAt).toLocaleDateString() }}
          </BTableColumn>
          <BTableColumn
            field="advance"
            :label="$t('common.terms.completed')"
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            {{ addSuffix(convertFractionToPercent(calculateTotalAdvanceSnapshot(props.row.bill.number) / activityBudget), '%') }}
            &nbsp;/&nbsp;
            {{ displayCurrency(calculateTotalAdvanceSnapshot(props.row.bill.number), { multiplier: 100, prefix: '$' }) }}
            &nbsp;/&nbsp;
            {{ (calculateTotalAdvanceSnapshot(props.row.bill.number) / activeActivity.unitValue).toFixed(1) }}
            {{ activeActivity.unit }}
          </BTableColumn>
          <BTableColumn
            field="advanceDifferenceFromLast"
            :label="$t('common.terms.advanceThisBill')"
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            {{ props.index !== activeBillItems.length - 1 ? addSuffix(
                convertFractionToPercent(
                  (calculateTotalAdvanceSnapshot(props.row.bill.number) - calculateTotalAdvanceSnapshot(props.row.bill.number - 1))
                  / activityBudget
                ),
                '%'
              )
              : addSuffix(convertFractionToPercent(props.row.amount / activityBudget), '%')
            }}
            &nbsp;/&nbsp;
            {{ props.index !== activeBillItems.length - 1 ?
              displayCurrency(
                calculateTotalAdvanceSnapshot(props.row.bill.number) - calculateTotalAdvanceSnapshot(props.row.bill.number - 1),
                { prefix: '$', multiplier: 100 }
              ) : displayCurrency(
                props.row.amount,
                { prefix: '$', multiplier: 100 }
              )
            }}
            &nbsp;/&nbsp;
            {{ props.index !== activeBillItems.length - 1 ?
              (
                ((calculateTotalAdvanceSnapshot(props.row.bill.number) - calculateTotalAdvanceSnapshot(props.row.bill.number - 1)) / activeActivity.unitValue).toFixed(1)
              ) : (
                (props.row.amount / activeActivity.unitValue).toFixed(1)
              )
            }}
            {{ activeActivity.unit }}
          </BTableColumn>
          <BTableColumn
            field="advanceStatus"
            :label="$t('common.terms.billStatus')"
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            <BillStatusTag :billStatusId="props.row.bill.statusId" size="small" />
          </BTableColumn>
        </BTable>
        <div v-else class="has-text-center has-text-grey has-text-weight-bold">
          {{ $t('activityRowDetails.emptyState') }}
        </div>
      </div>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isComponentLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as ActivityActions from '@/store/actions/Activity.actions';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import BillStatusTag from '@/components/BillStatusTag.vue';

export default {
  name: 'ActivityRowDetails',

  components: {
    BillStatusTag,
  },

  props: {
    activeActivityId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isComponentLoading: false,
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      bills: state => state.bill.bills,
      billItems: state => state.billItem.billItems,
      billStatuses: state => state.bill.billStatuses,
    }),
    activeActivity() {
      return this.activities[this.activeActivityId];
    },
    activeBillItems() {
      return Object.values(this.billItems)
        .filter(billItem => billItem.activityId === this.activeActivityId)
        .map(billItem => ({
          ...billItem,
          bill: { ...this.bills[billItem.billId] },
        }))
        .sort((a, b) => b.bill.number - a.bill.number);
    },
    activityBudget() {
      const { unitValue, quantity } = this.activeActivity;
      return this.calculateActivityBudget(unitValue, quantity);
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    displayCurrency,
    convertFractionToPercent,
    addSuffix,
    calculateActivityBudget,
    async fetchInitialData() {
      this.isComponentLoading = true;

      await this.$store.dispatch(
        ActivityActions.FETCH_ACTIVITY_BILL_ITEMS,
        { activityId: this.activeActivityId },
      );

      this.isComponentLoading = false;
    },
    calculateTotalAdvanceSnapshot(billNumber) {
      return [...this.activeBillItems].reverse().slice(0, billNumber).reduce((total, curr) => (total + curr.amount), 0);
    },
  },
};
</script>
