<template>
  <BModal :active="isOpen" hasModalCard :width="600" @close="$emit('close')">
    <form @submit.prevent="handleSubmitCreateVendor">
      <div class="modal-card">
        <div class="modal-card-head">
          <p class="modal-card-title">
            {{ !activeVendorId ? $t('common.ctas.createVendor') : `${$t('common.ctas.updateVendor')} ${activeVendor.name}` }}
          </p>
        </div>
        <div class="modal-card-body">
          <BField v-if="formSubmissionError">
            <BMessage type="is-danger">
              {{  formSubmissionError.message }}
            </BMessage>
          </BField>
          <BField
            v-for="formField in Object.values(createVendorForm.fields)"
            :key="formField.key"
            :class="['column', { 'required': formField.required }]"
            :label="$t(formField.label)"
            :type="createVendorForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
            :message="createVendorForm.formErrors[$t(formField.name)] || ''"
          >
            <BInput
              v-model="formField.value"
              :disabled="createVendorForm.isFormSubmissionInProgress"
              :placeholder="$t(formField.placeholder)"
              :ref="formField.ref"
              @input="handleTouchForm"
            />
          </BField>
        </div>
        <footer class="modal-card-foot">
          <BButton nativeType="submit" type="is-primary" expanded :loading="createVendorForm.isFormSubmissionInProgress">
            {{ !activeVendorId ? $t('common.ctas.createVendor') : $t('common.ctas.updateVendor') }}
          </BButton>
        </footer>
      </div>
    </form>
  </BModal>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import createVendorForm from '@/forms/createVendor.form';
import * as VendorActions from '@/store/actions/Vendor.actions';
import * as ValidationService from '@/services/Validation.service';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'CreateOrEditVendorModal',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    initialVendorName: {
      type: String,
      required: false,
      default: '',
    },
    activeVendorId: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: [
    'submitSuccess',
    'close',
  ],

  data() {
    return {
      createVendorForm: cloneDeep(createVendorForm),
      formSubmissionError: null,
    };
  },

  computed: {
    ...mapState({
      vendors: state => state.vendor.vendors,
    }),
    activeVendor() {
      return this.activeVendorId ? this.vendors[this.activeVendorId] : null;
    },
  },

  created() {
    this.setInitialFormState();
  },

  watch: {
    isOpen(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.setInitialFormState();
      }
    },
  },

  methods: {
    setInitialFormState() {
      if (this.activeVendorId && this.activeVendor) {
        const {
          name,
          contactName,
          contactPhone,
          contactEmail,
          legalId1,
          legalId2,
        } = this.activeVendor;

        this.createVendorForm.fields.name.value = name;
        this.createVendorForm.fields.contactName.value = contactName;
        this.createVendorForm.fields.contactPhone.value = contactPhone;
        this.createVendorForm.fields.contactEmail.value = contactEmail;
        this.createVendorForm.fields.id1.value = legalId1;
        this.createVendorForm.fields.id2.value = legalId2;
      } else {
        this.createVendorForm.fields.name.value = this.initialVendorName;
      }

      // Set focus on first form field. We use nextTick to make sure the DOM is rendered and the element exists.
      this.$nextTick(() => {
        if (this.$refs[this.createVendorForm.fields.name.ref]) {
          this.$refs[this.createVendorForm.fields.name.ref][0].focus();
          // This click method is the only way to ensure we draw focus away from autocomplete dropdowns.
          // Since autocomplete dropdowns are sometimes rendered in the BODY they will sit on top of our modals,
          // and we want to avoid that. An example is when this modal is used inside the `CreateCostModal`
          // via the vendor autocomplete in that component.
          this.$refs[this.createVendorForm.fields.name.ref][0].$el.click();
        }
      });
    },
    async handleSubmitCreateVendor() {
      this.formSubmissionError = null;
      this.createVendorForm.formErrors = {};
      this.createVendorForm.formErrors = ValidationService.runFormValidation({ ...this.createVendorForm.fields }, { i18n: true });

      if (!isObjectEmpty(this.createVendorForm.formErrors)) return;

      this.createVendorForm.isFormSubmissionInProgress = true;
      const { fields } = this.createVendorForm;
      try {
        const payload = {
          name: fields.name.value,
          contactName: fields.contactName.value,
          contactEmail: fields.contactEmail.value,
          contactPhone: fields.contactPhone.value,
          legalId1: fields.id1.value,
          legalId2: fields.id2.value,
        };
        let vendorId;

        if (this.activeVendorId) {
          vendorId = await this.$store.dispatch(
            VendorActions.UPDATE_VENDOR_BY_ID,
            { id: this.activeVendorId, updatedVendor: { ...payload } },
          );
        } else {
          vendorId = await this.$store.dispatch(
            VendorActions.CREATE_VENDOR,
            { ...payload },
          );
        }
        this.createVendorForm.isFormSubmissionInProgress = false;
        NotificationService.showNotification(NotificationTypes.CREATE_VENDOR.SUCCESS);
        this.$emit('submitSuccess', vendorId);
      } catch (err) {
        console.log(err);
        this.formSubmissionError = err;
        this.createVendorForm.isFormSubmissionInProgress = false;
      }
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
