<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.activitiesByCostCode') }}</h4>
        </div>
      </section>

      <div class="app-page__section">
        <BField
          :label="$t('reportActivitiesByCostCodeView.formFields.project.label')"
          :name="$t('reportActivitiesByCostCodeView.formFields.project.name')"
        >
          <BSelect
            v-model="selectedProjectId"
            expanded
          >
            <option v-for="project in getLoggedInUserProjects" :key="project.id" :value="project.id">
              {{ project.name }}
            </option>
          </BSelect>
        </BField>
        <BField
          v-if="selectedProjectId && getBudgetsByProjectId(selectedProjectId).length > 0"
          :label="$t('reportActivitiesByCostCodeView.formFields.budget.label')"
          :name="$t('reportActivitiesByCostCodeView.formFields.budget.name')"
        >
          <BSelect
            v-model="selectedBudgetId"
            expanded
          >
            <option v-for="budget in getBudgetsByProjectId(selectedProjectId)" :key="budget.id" :value="budget.id">
              {{ budget.name }}
            </option>
          </BSelect>
        </BField>
        <BMessage
          v-else-if="selectedProjectId"
          type="is-info"
          aria-close-label="Close message">
            {{ $t('reportActivitiesByCostCodeView.noBudgets') }}
      </BMessage>
      </div>

      <div class="app-page__section">
        <BTable
          :data="tableData"
          ref="projectActivitiesByCostCodeTable"
          hoverable
          detailed
          customDetailRow
          :mobileCards="false"
          detailKey="id"
          :class="{card: true, 'is-empty-table': tableData.length == 0}"
          checkable
          checkbox-position="right"
          :checked-rows.sync="userSelectedCosts"
        >
          <BModal
            :active="activityEditing!==null"
            @close="handleCloseEditActivityModal"
            hasModalCard
          >
            <div class="modal-card">
              <header class="modal-card-head">
                <p v-if="activityEditing!==null" class="modal-card-title">{{ $t('common.ctas.edit') }} {{ activityEditing.name }}</p>
                <button
                  type="button"
                  class="delete"
                  @click="handleCloseEditActivityModal"
                />
              </header>
              <div class="modal-card-body">
                <CreateOrEditActivityCard
                v-if="activityEditing!==null"
                  :activityId="activityEditing.id"
                  :costId="activityEditing.costId"
                  :budgetId="activityEditing.budgetId"
                  :isDark="false"
                  :isInset="false"
                  areFieldsStacked
                  :hasBorderRadius="false"
                  @submitSuccess="handleActivityUpdateSuccess"
                />
              </div>
            </div>
          </BModal>
          <BTableColumn
            sortable
            :customSort="sortCostCodes"
            :label="$t('common.terms.costCode')"
            :subheading="$t('common.terms.total')"
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            <div :ref="`projectCostCodeRow__${props.row.id}`">
              <CostCodeTag v-if="props.row.id !== 'no-cost-code'" :costCodeId="props.row.id" size="is-medium" />
              <span v-else>
                {{ $t(props.row.name) }}
              </span>
              ({{ props.row.activities.length }})
            </div>
          </BTableColumn>
          <BTableColumn
            :label="$t('common.terms.budgeted')"
            :subheading="reportBudgetedTotal"
            cell-class="vertical-align-middle"
            numeric
            v-slot="props"
          >
            <div>
              {{ displayCurrency(getTotalBudgetedByCostCode(props.row.id), { multiplier: 100, prefix: '$' }) }}
            </div>
          </BTableColumn>
          <BTableColumn
            :label="$t('common.terms.contracted')"
            :subheading="reportContractedTotal"
            cell-class="vertical-align-middle"
            numeric
            v-slot="props"
          >
            <div>
              <span :class="['report__custom-cell', { 'report__custom-cell--is-danger': isContractedMoreThanBudgeted(props.row.id) }]">
                {{ displayCurrency(getTotalContractedByCostCode(props.row.id), { multiplier: 100, prefix: '$' }) }}
              </span>
            </div>
          </BTableColumn>
          <BTableColumn
            :label="$t('common.terms.completed')"
            :subheading="reportCompletedTotal"
            cell-class="vertical-align-middle"
            numeric
            v-slot="props"
          >
            <div>
              {{ displayCurrency(getTotalAdvancedByCostCode(props.row.id), { multiplier: 100, prefix: '$' }) }}
            </div>
          </BTableColumn>
          <BTableColumn
            :label="$t('common.terms.remainingBalance')"
            :subheading="reportRemainingBalanceTotal"
            cell-class="vertical-align-middle"
            numeric
            v-slot="props"
          >
            <div>
              {{ displayCurrency(getTotalRemainingBalanceByCostCode(props.row.id), { multiplier: 100, prefix: '$' }) }}
            </div>
          </BTableColumn>
          <BTableColumn
            :label="$t('common.terms.costToComplete')"
            :subheading="reportCostToCompleteTotal"
            cell-class="vertical-align-middle"
            numeric
            v-slot="props"
          >
            <div>
              {{ displayCurrency(getTotalCostToCompleteByCostCode(props.row.id), { multiplier: 100, prefix: '$' }) }}
            </div>
          </BTableColumn>
          <template slot="detail" slot-scope="props">
            <tr v-for="activity in props.row.activities" :key="activity.id">
              <td></td>
              <td class="u-p-l-large">
                <div class="columns">
                  <div class="column is-narrow" v-if="isLoggedInUserAtLeastProjectManager">
                    <BTooltip
                      :label="$t('common.ctas.edit')"
                      position="is-top"
                      :triggers="['hover']"
                      type="is-dark"
                      class="u-m-r-small"
                      appendToBody
                    >
                      <BButton
                        type="is-primary is-light"
                        size="is-small"
                        @click="() => handleClickEditActivity(activity)"
                        @keyup.enter="() => handleClickEditActivity(activity)"
                      >
                        <BIcon icon="pencil" size="is-small" />
                      </BButton>
                    </BTooltip>
                  </div>
                  <div class="column">
                    <p>
                      {{ activity.name }}
                    </p>
                    <router-link
                      :to="getCostOrBudgetUrl(activity)"
                    >
                      <p class="is-italic is-size-7">
                        <BIcon :icon="activity.costId ? 'file-document-edit-outline' : 'cash-multiple'" size="is-small" />
                        &nbsp;&nbsp;
                        {{ activity.costId ? costs[activity.costId].name : budgets[activity.budgetId].name }}
                      </p>
                    </router-link>
                  </div>
                </div>
              </td>
              <td class="has-text-right">
                {{ activity.budgetId ? displayCurrency(getActivityBudget(activity.id), { multiplier: 100, prefix: '$' }) : ''}}
              </td>
              <td class="has-text-right">
                {{ activity.costId ? displayCurrency(getActivityBudget(activity.id), { multiplier: 100, prefix: '$' }) : ''}}
              </td>
              <td class="has-text-right">
                {{ activity.costId ? displayCurrency(getActivityTotalAdvanceAmount(activity.id), { multiplier: 100, prefix: '$' }) : ''}}
              </td>
              <td class="has-text-right">
                {{ activity.costId ? displayCurrency(getActivityRemainingBalanceAmount(activity.id), { multiplier: 100, prefix: '$' }) : ''}}
              </td>
              <!-- <td v-show="columnsVisible['sold'].display" class="has-text-centered">{{ item.sold }}</td>
              <td v-show="columnsVisible['available'].display" class="has-text-centered">{{ item.available }}</td>
              <td v-show="columnsVisible['cleared'].display" class="has-text-centered">
                <span :class="
                    [
                        'tag',
                        {'is-danger': item.sold / item.available <= 0.45},
                        {'is-success': item.sold / item.available > 0.45}
                    ]">
                    {{ Math.round((item.sold / item.available) * 100) }}%
                </span>
              </td> -->
            </tr>
          </template>
          <template #empty>
            <div class="has-text-grey u-p-a has-text-centered">
              <template v-if="selectedProjectId">{{ $t('reportActivitiesByCostCodeView.table.noCosts') }}</template>
              <template v-else>{{ $t('reportActivitiesByCostCodeView.table.emptyState') }}</template>
            </div>
          </template>
        </BTable>
      </div>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as ProjectActions from '@/store/actions/Project.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { displayCurrency } from '@/helpers/stringHelpers';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import CostCodeTag from '@/components/CostCodeTag.vue';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';

export default {
  name: 'ReportActivitiesByCostCode',

  components: {
    CostCodeTag,
    CreateOrEditActivityCard,
  },

  data() {
    return {
      isPageLoading: true,
      activityEditing: null,
      openedCostCodes: [],
      userSelectedCosts: [],
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      costCodes: state => state.costCode.costCodes,
      costs: state => state.cost.costs,
      budgets: state => state.budget.budgets,
    }),
    ...mapGetters([
      'activeOrganization',
      'loggedInUser',
      'isLoggedInUserAtLeastAdmin',
      'isLoggedInUserAtLeastProjectManager',
      'getLoggedInUserProjects',
      'getBudgetsByProjectId',
      'getProjectCostActivities',
      'getBudgetActivities',
      'getActivityBudget',
      'getActivityTotalAdvanceAmount',
      'getActivityRemainingBalanceAmount',
    ]),
    selectedProjectId: {
      get() { return this.$route.query.project; },
      set(newValue) {
        const { query } = this.$route;

        if (!newValue) {
          delete query.project;
          this.$router.replace({ query }).catch(() => {});
        } else if (newValue !== query.project) {
          this.selectedBudgetId = null;
          this.$router.replace({ query: { ...query, project: newValue } }).catch(() => {});
        }
      },
    },
    selectedBudgetId: {
      get() { return this.$route.query.budget; },
      set(newValue) {
        const { query } = this.$route;

        if (!newValue) {
          delete query.budget;
          this.$router.replace({ query }).catch(() => {});
        } else if (newValue !== query.budget) {
          this.$router.replace({ query: { ...query, budget: newValue } }).catch(() => {});
        }
      },
    },
    projectAndBudgetCostCodes() {
      let costActivities = [];
      if (this.selectedProjectId) {
        costActivities = this.getProjectCostActivities(this.selectedProjectId);
      }

      let budgetActivities = [];
      if (this.selectedBudgetId) {
        budgetActivities = this.getBudgetActivities(this.selectedBudgetId);
      }

      const activitiesData = [
        ...costActivities,
        ...budgetActivities,
      ];

      if (activitiesData.length === 0) {
        return {};
      }

      return activitiesData.reduce((acc, activity) => {
        const costCodeId = activity.costCodeId || 'no-cost-code';
        return {
          ...acc,
          [costCodeId]: {
            ...(costCodeId !== 'no-cost-code') && this.costCodes[activity.costCodeId],
            ...(costCodeId === 'no-cost-code') && { name: 'common.terms.uncategorized', id: 'no-cost-code' },
            activities: [
              ...acc[costCodeId] ? acc[costCodeId].activities : [],
              activity,
            ],
          },
        };
      }, {});
    },
    tableData() {
      return !isObjectEmpty(this.projectAndBudgetCostCodes) ? Object.values(this.projectAndBudgetCostCodes) : [];
    },
    aggregatorTableData() {
      return this.userSelectedCosts.length > 0 ? this.userSelectedCosts : this.tableData;
    },
    reportBudgetedTotal() {
      const budgetedTotal = this.aggregatorTableData.reduce((total, costCode) => total + this.getTotalBudgetedByCostCode(costCode.id), 0);
      return displayCurrency(budgetedTotal, { multiplier: 100, prefix: '$' });
    },
    reportContractedTotal() {
      const contractedTotal = this.aggregatorTableData.reduce((total, costCode) => total + this.getTotalContractedByCostCode(costCode.id), 0);
      return displayCurrency(contractedTotal, { multiplier: 100, prefix: '$' });
    },
    reportCompletedTotal() {
      const completedTotal = this.aggregatorTableData.reduce((total, costCode) => total + this.getTotalAdvancedByCostCode(costCode.id), 0);
      return displayCurrency(completedTotal, { multiplier: 100, prefix: '$' });
    },
    reportRemainingBalanceTotal() {
      const remainingBalanceTotal = this.aggregatorTableData.reduce((total, costCode) => total + this.getTotalRemainingBalanceByCostCode(costCode.id), 0);
      return displayCurrency(remainingBalanceTotal, { multiplier: 100, prefix: '$' });
    },
    reportCostToCompleteTotal() {
      const costToCompleteTotal = this.aggregatorTableData.reduce((total, costCode) => total + this.getTotalCostToCompleteByCostCode(costCode.id), 0);
      return displayCurrency(costToCompleteTotal, { multiplier: 100, prefix: '$' });
    },
  },

  async created() {
    await this.fetchInitialData();
    this.setInitialQueryState();
  },

  methods: {
    displayCurrency,
    async fetchInitialData() {
      this.isPageLoading = true;
      try {
        if (this.isLoggedInUserAtLeastAdmin) {
          await this.$store.dispatch(ProjectActions.FETCH_ORGANIZATION_PROJECTS, { organizationId: this.activeOrganization.id });
        } else {
          await this.$store.dispatch(ProjectActions.FETCH_USER_PROJECTS, { userId: this.loggedInUser.id });
        }
      } catch (err) {
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isPageLoading = false;
    },
    getTotalBudgetedByCostCode(costCodeId) {
      return this.projectAndBudgetCostCodes[costCodeId].activities
        .filter(activity => activity.budgetId)
        .reduce((total, activity) => total + this.getActivityBudget(activity.id), 0);
    },
    getTotalContractedByCostCode(costCodeId) {
      return this.projectAndBudgetCostCodes[costCodeId].activities
        .filter(activity => activity.costId)
        .reduce((total, activity) => total + this.getActivityBudget(activity.id), 0);
    },
    getTotalAdvancedByCostCode(costCodeId) {
      return this.projectAndBudgetCostCodes[costCodeId].activities
        .filter(activity => activity.costId)
        .reduce((total, activity) => total + this.getActivityTotalAdvanceAmount(activity.id), 0);
    },
    getTotalRemainingBalanceByCostCode(costCodeId) {
      return this.getTotalContractedByCostCode(costCodeId) - this.getTotalAdvancedByCostCode(costCodeId);
    },
    getTotalCostToCompleteByCostCode(costCodeId) {
      return this.getTotalBudgetedByCostCode(costCodeId) - this.getTotalAdvancedByCostCode(costCodeId);
    },
    isContractedMoreThanBudgeted(costCodeId) {
      return this.getTotalBudgetedByCostCode(costCodeId) < this.getTotalContractedByCostCode(costCodeId);
    },
    getCostOrBudgetUrl(activity) {
      let url = `/projects/${this.selectedProjectId}`;

      if (activity.costId) {
        url += `/costs/${activity.costId}/details`;
      } else {
        url += `/budgets/${activity.budgetId}/details`;
      }

      return url;
    },
    sortCostCodes(a, b, isAsc) {
      const aValue = a.code === undefined ? undefined : `${a.code}${a.name ? ` - ${a.name}` : ''}`;
      const bValue = b.code === undefined ? undefined : `${b.code}${b.name ? ` - ${a.name}` : ''}`;

      if (aValue === '' || aValue === undefined) return 1;
      if (bValue === '' || bValue === undefined) return -1;
      if (aValue === bValue) return 0;

      // eslint-disable-next-line
      return isAsc ? (aValue > bValue ? 1 : -1) : (bValue > aValue ? 1 : -1);
    },
    setInitialQueryState() {
      const { project, budget } = this.$route.query;

      // if budget is passed but project is not, it is a bad URL so we remove the budget altogether.
      if (budget && !project) {
        const query = { ...this.$route.query };
        delete query.budget;
        this.$router.replace({ query }).catch(() => {});
      }

      if (project) {
        // Project not found
        if (!this.projects[project]) {
          const query = { ...this.$route.query };
          delete query.project;
          this.$router.replace({ query }).catch(() => {});
        } else {
          this.selectedProjectId = project;
        }

        // invalid budget or not found
        if (budget && (!this.budgets[budget] || this.budgets[budget].projectId !== project)) {
          const query = { ...this.$route.query };
          delete query.project;
          this.$router.replace({ query }).catch(() => {});
        } else if (budget) {
          this.selectedBudgetId = budget;
        }
      }
    },
    handleClickEditActivity(activity) {
      this.activityEditing = activity;
    },
    handleCloseEditActivityModal() {
      this.activityEditing = null;
    },
    handleActivityUpdateSuccess() {
      this.handleCloseEditActivityModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_ACTIVITY.SUCCESS);
    },
  },
};
</script>

<style scoped lang="scss">
.report__custom-cell {
  padding: $global-whitespace-xsmall;
  border-radius: $radius;

  &--is-danger {
    background-color: $danger;
    color: $white;
  }
}
</style>
