<template>
  <div class="columns">
    <div class="column">
      <p class="is-size-5 has-text-grey has-text-weight-bold u-m-b-small">
        {{ $t('projectCostsTable.rowDetails.costSummary') }}
      </p>
      <p>
        {{ $t('common.terms.createdBy') }}:
        <UserDisplayName :activeUserId="costs[costId].createdByUserId" isInline />
      </p>
      <p class="u-m-t-xsmall">
        {{ $t('common.terms.createdAt') }}:
        <span class="has-text-weight-bold">{{ new Date(costs[costId].createdAt).toLocaleDateString($i18n.locale, {}) }}</span>
      </p>
    </div>
    <!-- <div class="column">
      <p class="is-size-5 has-text-grey has-text-weight-bold u-m-b-small">
        {{ $t('projectCostsTable.rowDetails.costProgress') }}
      </p>
      <vc-donut
        :sections="[
          {
            value: (getCostTotalAdvancePercent(costId) * 100) || 0,
            color: '#5e72e4',
          },
        ]"
        :size="100"
        :thickness="20"
        :auto-adjust-text-size="false"
        style="justify-content: flex-start;"
      >
        <p class="has-text-weight-bold">
          {{ Math.round(convertFractionToPercent(getCostTotalAdvancePercent(costId))) }}%
        </p>
      </vc-donut>
    </div> -->
    <!-- <div class="column is-3">
      <p class="is-size-5 has-text-grey has-text-weight-bold u-m-b-small">
        {{ $t('projectCostsTable.rowDetails.costSummary') }}
      </p>
      <div v-for="total in costTotals" :key="total.key">
        <p class="u-m-b-xsmall">
          <span class="is-italic">{{ total.title }}: &nbsp;</span>
          <span class="has-text-weight-bold">{{ displayCurrency(total.amount, { prefix: '$', multiplier: 100 }) }}</span>
        </p>
      </div>
    </div> -->
    <div class="column is-3">
      <div v-if="getLatestCostBill(costId)">
        <p class="is-size-5 has-text-grey has-text-weight-bold u-m-b-small">
          {{ $t('projectCostsTable.rowDetails.latestBill') }}
        </p>
        <BillSummaryCard
          :billId="getLatestCostBill(costId).id"
          isCompact
          isStatusVisible
          @click.native="() => { $router.push(`/bills/${getLatestCostBill(costId).id}`) }"
          @keyup.native.enter="() => { $router.push(`/bills/${getLatestCostBill(costId).id}`) }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { displayCurrency } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import UserDisplayName from '@/components/UserDisplayName.vue';
import BillSummaryCard from '@/components/BillSummaryCard.vue';

export default {
  name: 'ProjectCostsTableRowDetails',

  components: {
    BillSummaryCard,
    UserDisplayName,
  },

  props: {
    costId: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      costs: state => state.cost.costs,
      bills: state => state.bill.bills,
    }),
    ...mapGetters([
      'getCostTotalActivityBudget',
      'getCostTotalAdvanceAmount',
      'getCostRemainingBalance',
      'getCostTotalClosedAmount',
      'getCostTotalAdvancePercent',
    ]),
    costTotals() {
      return [
        {
          title: this.$t('common.terms.contracted'),
          amount: this.getCostTotalActivityBudget(this.costId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.contracted'),
        },
        {
          title: this.$t('common.terms.completed'),
          amount: this.getCostTotalAdvanceAmount(this.costId),
          titleFigure: this.getCostTotalAdvanceAmount(this.costId) / this.getCostTotalActivityBudget(this.costId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.completed'),
        },
        {
          title: this.$t('common.terms.remainingBalance'),
          amount: this.getCostRemainingBalance(this.costId),
          titleFigure: this.getCostRemainingBalance(this.costId) / this.getCostTotalActivityBudget(this.costId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.remainingBalance'),
        },
        {
          title: this.$t('common.terms.totalClosed'),
          amount: this.getCostTotalClosedAmount(this.costId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.totalClosed'),
        },
      ];
    },
  },

  methods: {
    displayCurrency,
    convertFractionToPercent,
    getLatestCostBill(costId) {
      return Object.values(this.bills)
        .filter(bill => bill.costId === costId)
        .sort((billA, billB) => billB.number - billA.number)[0];
    },
  },
};
</script>
