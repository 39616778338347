<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <ImportActivities
        :submissionError="submissionError"
        :isSubmissionInProgress="isSubmissionInProgress"
        @submit="handleSubmitActivities"
      />
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import * as Currency from '@/constants/Currency';
import * as CostActions from '@/store/actions/Cost.actions';
import * as BudgetActions from '@/store/actions/Budget.actions';
import * as ActivityActions from '@/store/actions/Activity.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import ImportActivities from '@/components/ImportActivities.vue';

export default {
  name: 'ImportCostActivities',

  components: {
    ImportActivities,
  },

  data() {
    return {
      isPageLoading: false,
      currencyOptions: { ...Currency.defaultCurrency },
      submissionError: null,
      isSubmissionInProgress: false,
    };
  },

  computed: {
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeCostId() {
      return this.$route.params.costId;
    },
    activeBudgetId() {
      return this.$route.params.budgetId;
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    async handleSubmitActivities(activities) {
      console.log(activities);
      this.isSubmissionInProgress = true;

      try {
        // eslint-disable-next-line
        await this.$store.dispatch(
          ActivityActions.CREATE_ACTIVITIES,
          {
            activities: activities.map(a => ({ ...a, unitValue: this.$ci.parse(a.unitValue || 0, this.currencyOptions) })),
            ...this.activeCostId && { costId: this.activeCostId },
            ...this.activeBudgetId && { budgetId: this.activeBudgetId },
          },
        );

        NotificationService.showNotification(NotificationTypes.BULK_IMPORT_ACTIVITIES(activities.length).SUCCESS);
        const costOrBudget = this.activeCostId ? `/costs/${this.activeCostId}` : `/budgets/${this.activeBudgetId}`;
        this.$router.push(`/projects/${this.activeProjectId}${costOrBudget}/details`);
      } catch (err) {
        this.submissionError = err;
      }

      this.isSubmissionInProgress = false;
    },
    async fetchInitialData() {
      if (this.activeCostId) {
        const costId = this.activeCostId;
        await this.$store.dispatch(CostActions.FETCH_COST_BY_ID, { costId });
      } else if (this.activeBudgetId) {
        const budgetId = this.activeBudgetId;
        await this.$store.dispatch(BudgetActions.FETCH_BUDGET_BY_ID, { budgetId });
      }

      this.isPageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
