<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
        {{ $t('common.notifications.archivedProject') }}
      </BMessage>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">
            {{ $t('common.titles.changeOrders') }}
          </h4>
          <p class="subtitle has-text-grey has-text-weight-bold is-size-5">{{ activeProject.name }}</p>
        </div>
      </section>
      <section v-if="isLoggedInUserAtLeastProjectManager" class="app-page__section">
        <CreateChangeOrderCard v-if="!isProjectArchived(activeProject.id)" :projectId="activeProjectId" />
      </section>
      <section class="app-page__section">
        <ProjectChangeOrdersTable :projectId="activeProjectId" />
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import AnimatedNumber from 'animated-number-vue';
import * as ChangeOrderActions from '@/store/actions/ChangeOrder.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { displayCurrency } from '@/helpers/stringHelpers';
import CreateChangeOrderCard from '@/components/CreateChangeOrderCard.vue';
import ProjectChangeOrdersTable from '@/components/ProjectChangeOrdersTable.vue';

export default {
  name: 'ProjectChangeOrders',

  components: {
    ProjectChangeOrdersTable,
    CreateChangeOrderCard,
    // AnimatedNumber,
  },

  data() {
    return {
      isPageLoading: true,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
    }),
    ...mapGetters([
      'isProjectArchived',
      'isLoggedInUserAtLeastProjectManager',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
  },

  created() {
    this.fetchinitialData();
  },

  methods: {
    displayCurrency,
    async fetchinitialData() {
      this.isPageLoading = true;
      try {
        await Promise.all([
          this.$store.dispatch(ChangeOrderActions.FETCH_PROJECT_CHANGE_ORDERS, { projectId: this.$route.params.projectId }),
        ]);
      } catch {
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isPageLoading = false;
    },
  },
};
</script>
