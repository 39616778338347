import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getBrowserLocale } from '@/helpers/i18nHelpers';
import * as Storage from '@/constants/Storage';

Vue.use(VueI18n);

function getLocales() {
  const localeFiles = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const locales = [];

  localeFiles.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      locales.push(matched[1]); // eslint-disable-line prefer-destructuring
    }
  });

  return { locales, localeFiles };
}

function setInitialLocale() {
  const { locales } = getLocales();
  const savedLocale = localStorage.getItem(Storage.selectedLocale);

  if (savedLocale) return savedLocale;

  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (locales.includes(browserLocale)) {
    return browserLocale;
  }

  return 'en-US';
}

function loadLocaleMessages() {
  const { locales, localeFiles } = getLocales();

  const messages = {};
  localeFiles.keys().forEach((key, index) => {
    messages[locales[index]] = localeFiles(key);
  });

  return messages;
}

export default new VueI18n({
  locale: setInitialLocale(),
  fallbackLocale: 'en-US',
  messages: loadLocaleMessages(),
});
