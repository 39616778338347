<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 v-if="activeClient" class="title is-4">{{ activeClient.name }}</h4>
        </div>
      </section>

      <section class="app-page__section">
        <div>
          <form @submit.prevent="handleSubmitEditClient">
            <h5 class="title is-5 has-text-grey">
              {{ $t('common.terms.details') }}
            </h5>
            <BField v-if="formSubmissionError">
              <BMessage type="is-danger">
                {{  formSubmissionError.message }}
              </BMessage>
            </BField>
            <div class="columns is-multiline">
              <BField
                v-for="formField in Object.values(editClientForm.fields)"
                :key="formField.key"
                :class="['column', { 'required': formField.required, [`${formField.width}`]: formField.width }]"
                :label="$t(formField.label)"
                :type="editClientForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
                :message="editClientForm.formErrors[$t(formField.name)] || ''"
              >
                <BInput
                  :type="formField.type || 'text'"
                  v-model="formField.value"
                  :disabled="editClientForm.isFormSubmissionInProgress || !isLoggedInUserAtLeastProjectManager"
                  :placeholder="$t(formField.placeholder)"
                  @input="handleTouchForm"
                  rows="2"
                  :ref="$t(formField.name)"
                />
              </BField>
            </div>
            <div v-if="isLoggedInUserAtLeastProjectManager" class="is-flex is-justify-content-flex-end">
              <BButton
                type="is-primary"
                native-type="submit"
                :disabled="editClientForm.isFormSubmissionInProgress"
                :loading="editClientForm.isFormSubmissionInProgress"
              >
                {{ $t('common.ctas.save') }}
              </BButton>
            </div>
          </form>
          <section class="u-m-t">
            <h5 class="title is-5 has-text-grey">
              {{ $t('common.titles.projects') }}
              &nbsp;
              ({{ clientProjects.length || 0 }})
            </h5>
            <div class="card">
              <div class="card-content">
                <p v-if="clientProjects.length < 1" class="has-text-grey u-p-y title is-5 has-text-centered">
                  {{ $t('editClientView.projectsEmptyState') }}
                </p>
                <CardRow
                  v-else
                  v-for="project in clientProjects"
                  :key="project.id"
                  isClickable
                  @click="() => $router.push(`/projects/${project.id}/details`)"
                >
                  <template v-slot:content-left>
                    <p class="has-text-weight-bold">{{ project.name }}</p>
                  </template>
                </CardRow>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters, mapState } from 'vuex';
import * as ClientActions from '@/store/actions/Client.actions';
// import * as ProjectActions from '@/store/actions/Project.actions';
// import * as UserActions from '@/store/actions/User.actions';
import * as ValidationService from '@/services/Validation.service';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import editClientForm from '@/forms/editClient.form';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import CardRow from '@/components/CardRow.vue';
// import UserDisplayName from '@/components/UserDisplayName.vue';
// import UserAvatar from '@/components/UserAvatar.vue';

export default {
  name: 'EditClientView',

  components: {
    CardRow,
  },

  data() {
    return {
      isPageLoading: false,
      formSubmissionError: null,
      editClientForm: cloneDeep(editClientForm),
    };
  },

  computed: {
    ...mapState({
      clients: state => state.client.clients,
      users: state => state.user.users,
      projects: state => state.project.projects,
    }),
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
      'isLoggedInUserAtLeastProjectManager',
    ]),
    activeClientId() {
      return this.$route.params.clientId;
    },
    activeClient() {
      return this.clients[this.activeClientId];
    },
    clientProjects() {
      return this.activeClient?.projects?.map(id => this.projects[id]) || [];
    },
  },

  mounted() {
    this.fetchInitialData();
  },

  methods: {
    handleTouchForm() {
      console.log('form touched');
    },
    async handleSubmitEditClient() {
      this.formSubmissionError = null;
      this.editClientForm.formErrors = {};
      this.editClientForm.formErrors = ValidationService.runFormValidation({ ...this.editClientForm.fields }, { i18n: true });

      if (!isObjectEmpty(this.editClientForm.formErrors)) return;

      this.editClientForm.isFormSubmissionInProgress = true;
      const { fields } = this.editClientForm;
      try {
        await this.$store.dispatch(
          ClientActions.UPDATE_CLIENT,
          {
            clientId: this.activeClientId,
            updatedClient:
              {
                name: fields.name.value,
                description: fields.description.value,
                contactName: fields.contactName.value,
                contactEmail: fields.contactEmail.value,
                contactPhone: fields.contactPhone.value,
                legalId1: fields.legalId1.value,
                legalId2: fields.legalId2.value,
              },
          },
        );
        this.editClientForm.isFormSubmissionInProgress = false;
        NotificationService.showNotification(NotificationTypes.UPDATE_CLIENT.SUCCESS);
      } catch (err) {
        console.log(err);
        this.setInitialFormState();
        this.formSubmissionError = err;
        this.editClientForm.isFormSubmissionInProgress = false;
        NotificationService.showNotification(NotificationTypes.UPDATE_CLIENT.ERROR);
      }
    },
    setInitialFormState() {
      const {
        name,
        description,
        contactName,
        contactEmail,
        contactPhone,
        legalId1,
        legalId2,
      } = this.activeClient;

      this.editClientForm.fields.name.value = name || '';
      this.editClientForm.fields.description.value = description || '';
      this.editClientForm.fields.contactName.value = contactName || '';
      this.editClientForm.fields.contactEmail.value = contactEmail || '';
      this.editClientForm.fields.contactPhone.value = contactPhone || '';
      this.editClientForm.fields.legalId1.value = legalId1 || '';
      this.editClientForm.fields.legalId2.value = legalId2 || '';
    },
    async fetchInitialData() {
      this.isPageLoading = true;

      await this.$store.dispatch(ClientActions.FETCH_CLIENT_BY_ID, { clientId: this.activeClientId });

      this.setInitialFormState();
      this.isPageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
