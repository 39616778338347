import * as UiMutations from '@/store/mutations/Ui.mutations';

const getInitialState = () => ({
  isSideNavigationOpen: false,
});

const state = getInitialState();

const getters = {
  // PLACEHOLDER
};

const mutations = {
  [UiMutations.SET_SIDE_NAVIGATION_STATE](state, payload) {
    state.isSideNavigationOpen = payload.isSideNavigationOpen;
  },
};

const actions = {
  // PLACEHOLDER
};

export default {
  state,
  getters,
  mutations,
  actions,
};
