<template>
  <div class="app-page__container">
    <section class="app-page__section">
      <div class="has-text-centered u-p-a-large">
        <BIcon icon="lock" size="is-large" class="u-m-b-small" />
        <h3 class="title is-4 has-text-grey-dark">{{ $t('unauthorizedView.unauthorizedMessage') }}</h3>
        <router-link to="/">
          <p class="is-size-5">{{ $t('unauthorizedView.cta') }}</p>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized',
};
</script>
