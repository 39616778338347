<template>
  <div>
    <div
      v-for="document in currentlyUploadingDocuments"
      :key="document.id"
      :class="[
        'global-banner',
        'has-text-white',
        'u-p-a-xsmall',
        { 'has-background-success': document.status === 'success' },
        { 'has-background-danger': document.status === 'error' },
        { 'has-background-dark': document.status === 'loading' },
      ]"
    >
      <div style="flex: 1;" class="is-size-7">
         {{ $t(getBannerText(document)) }} {{ document.name }}
      </div>
      <div>
        <div v-if="document.status === 'loading'" style="width: 100px;">
          <progress class="progress is-small is-primary" max="100" />
        </div>
        <BIcon v-else-if="document.status === 'success'" icon="check" />
        <button class="naked-button" v-else-if="document.status === 'error'" @click="() => handleRemoveNotification(document.id)">
          <BIcon icon="close" style="cursor: pointer;" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as DocumentMutations from '@/store/mutations/Document.mutations';

export default {
  name: 'DocumentUploadStatusBanner',

  computed: {
    ...mapState({
      currentlyUploadingDocuments: state => state.document.currentlyUploadingDocuments,
    }),
  },

  methods: {
    getBannerText(document) {
      let text;

      if (document.status === 'loading') {
        text = 'documentUploadStatusBanner.uploading';
      } else if (document.status === 'error') {
        text = 'documentUploadStatusBanner.error';
      } else if (document.status === 'success') {
        text = 'documentUploadStatusBanner.success';
      }

      return text;
    },
    handleRemoveNotification(id) {
      this.$store.commit(DocumentMutations.REMOVE_DOCUMENT_FROM_QUEUE, { id });
    },
  },
};
</script>

<style scoped lang="scss">
.global-banner {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  align-items: center;
  transition: background-color 0.2s;
}

.naked-button {
  background: none;
  border: none;
  color: $white;
}
</style>
