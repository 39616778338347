import { baseForm, baseField } from '@/forms/base.form';
import { masks } from '@/directives/Cleave.directive';

const createActivityForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'createActivityForm.name.label',
      name: 'createActivityForm.name.name',
      placeholder: 'createActivityForm.name.placeholder',
      value: '',
      required: true,
      ref: 'name',
    },
    quantity: {
      ...baseField,
      label: 'createActivityForm.quantity.label',
      name: 'createActivityForm.quantity.name',
      placeholder: 'createActivityForm.quantity.placeholder',
      value: null,
      rawValue: null,
      required: true,
      mask: masks.numeral(),
      ref: 'quantity',
    },
    unit: {
      ...baseField,
      label: 'createActivityForm.unit.label',
      name: 'createActivityForm.unit.name',
      placeholder: 'createActivityForm.unit.placeholder',
      value: null,
      required: true,
    },
    unitValue: {
      ...baseField,
      label: 'createActivityForm.unitValue.label',
      name: 'createActivityForm.unitValue.name',
      placeholder: 'createActivityForm.unitValue.placeholder',
      value: null,
      rawValue: null,
      required: true,
      icon: '$',
      ref: 'unitValue',
    },
    costCode: {
      ...baseField,
      label: 'createActivityForm.costCode.label',
      name: 'createActivityForm.costCode.name',
      placeholder: 'createActivityForm.costCode.placeholder',
      value: null,
      required: false,
    },
    activityCategory: {
      ...baseField,
      label: 'createActivityForm.activityCategory.label',
      name: 'createActivityForm.activityCategory.name',
      placeholder: 'createActivityForm.activityCategory.placeholder',
      value: null,
      required: false,
    },
    changeOrder: {
      ...baseField,
      label: 'createActivityForm.changeOrder.label',
      name: 'createActivityForm.changeOrder.name',
      placeholder: 'createActivityForm.changeOrder.placeholder',
      value: null,
      required: false,
    },
  },
};

export default createActivityForm;
