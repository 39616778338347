<template>
  <div>
    <BTable
      :data="budgetsTableData"
      hoverable
      @click="handleClickRow"
      @keyup.enter="handleClickRow"
      class="card"
      :mobile-cards="false"
      :row-class="handleRowClass"
      :default-sort-direction="'asc'"
      :default-sort="['name', 'asc']"
    >
      <BudgetConfirmDeleteModal :budgetId="budgetIdConfirmingDelete" :projectId="projectId" @onBudgetDeleteModalClosed="handleCloseDeleteModal" />
      <template slot="empty">
        <div class="has-text-grey u-p-a has-text-centered">
          {{ $t('projectBudgetsTable.emptyState') }}
        </div>
      </template>
      <BTableColumn
        :label="$t('common.terms.name')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        v-slot="props"
      >
        <div>
          {{ props.row.name }}
          <BTag
            v-if="projects[props.row.projectId].activeBudgetId === props.row.id"
            type="is-info is-light"
            size="is-medium"
            class="u-m-l-small"
          >
            {{ $t('common.terms.active') }}
          </BTag>
        </div>
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.description')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        v-slot="props"
      >
        {{ props.row.description }}
      </BTableColumn>
      <BTableColumn
        field="totalValue"
        :label="$t('common.terms.value')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        v-slot="props"
      >
        {{ displayCurrency(props.row.totalValue, { prefix: '$', multiplier: 100 }) }}
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.createdAt')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        v-slot="props"
        sortable
        field="createdAt"
      >
        {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale) }}
        {{ new Date(props.row.createdAt).toLocaleTimeString($i18n.locale) }}
      </BTableColumn>
      <BTableColumn width="120" v-slot="props" cell-class="vertical-align-middle">
        <div class="buttons" style="justify-content: center;">
          <BTooltip :label="$t('common.terms.details')" position="is-top" :triggers="['hover']" type="is-dark" class="u-m-r-small">
            <BButton
              type="is-primary is-light"
              @click="handleClickRow(props.row)"
              @keyup.enter="handleClickRow(props.row)"
            >
              <BIcon icon="dots-vertical" />
            </BButton>
          </BTooltip>
          <BTooltip
            v-if="isLoggedInUserAtLeastProjectManager"
            :label="$t('common.ctas.delete')"
            position="is-top"
            :triggers="['hover']"
            type="is-dark"
            size="is-small"
            style="max-width: 100px;"
            appendToBody
        >
          <BButton
            type="is-primary is-light"
            @click="(e) => { e.stopPropagation(); handleClickDeleteBudget(props.row.id)}"
            @keyup.enter="handleClickDeleteBudget(props.row.id)"
          >
            <BIcon icon="delete" />
          </BButton>
        </BTooltip>
        </div>
      </BTableColumn>
    </BTable>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency } from '@/helpers/stringHelpers';
import BudgetConfirmDeleteModal from '@/components/BudgetConfirmDeleteModal.vue';

export default {
  name: 'ProjectBudgetsTable',

  components: {
    BudgetConfirmDeleteModal,
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      budgetIdConfirmingDelete: null,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
    }),
    ...mapGetters([
      'getBudgetsByProjectId',
      'getTotalBudgetValue',
      'isLoggedInUserAtLeastProjectManager',
    ]),
    budgetsTableData() {
      return this.getBudgetsByProjectId(this.projectId)
        .map(budget => ({
          ...budget,
          totalValue: this.getTotalBudgetValue(budget.id),
        }));
    },
    // filteredBillsTableData() {
    //   return this.budgetsTableData.filter(bill => this.selectedBillStatusTypes.includes(bill.statusId));
    // },
  },

  methods: {
    displayCurrency,
    handleRowClass() {
      return 'has-cursor-pointer';
    },
    handleSelectAllBillStatusTypes() {
      this.selectedBillStatusTypes = Object.keys(this.billStatuses);
    },
    calculateBudgetActivityTotal(budgetId) {
      return this.getActivitiesByBudget(budgetId)
        .reduce((acc, activity) => { // eslint-disable-line
          return acc + calculateActivityBudget(activity.unitValue, activity.quantity);
        }, 0);
    },
    handleClickRow(row) {
      this.$router.push(`/projects/${this.projectId}/budgets/${row.id}/details`);
    },
    handleCloseDeleteModal() {
      this.budgetIdConfirmingDelete = null;
    },
    handleClickDeleteBudget(budgetId) {
      this.budgetIdConfirmingDelete = budgetId;
    },
  },
};
</script>

<style scoped lang="scss">
.costs-table__advance-column__header .th-wrap {
  text-align: center !important;
}

.filters__wrapper {
  display: flex;
  align-items: center;

  > * {
    margin-right: $global-whitespace-xsmall;
  }
}

.filters__dropdown-item {
  background-color: transparent !important;
  color: $dark !important;
}
</style>
