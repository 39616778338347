import Vue from 'vue';
import VueRouter from 'vue-router';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import i18n from '@/i18n';
import store from '@/store';
import * as RoleConstants from '@/constants/Role.constants';
import ProjectsView from '@/views/Projects.view.vue';
import BillsView from '@/views/Bills.view.vue';
import CostDetailsView from '@/views/CostDetails.view.vue';
import CreateOrEditBillView from '@/views/CreateOrEditBill.view.vue';
import LogInView from '@/views/LogIn.view.vue';
import SignUpView from '@/views/SignUp.view.vue';
import ProjectDetailsView from '@/views/ProjectDetails.view.vue';
import CreateProjectView from '@/views/CreateProject.view.vue';
import EditProjectView from '@/views/EditProject.view.vue';
import VerifyEmailView from '@/views/VerifyEmail.view.vue';
import ForgotPasswordView from '@/views/ForgotPassword.view.vue';
import OrganizationUsersView from '@/views/OrganizationUsers.view.vue';
import UserProfileView from '@/views/UserProfile.view.vue';
import CreateUserView from '@/views/CreateUser.view.vue';
import AcceptInvitationView from '@/views/AcceptInvitation.view.vue';
import CostBillsView from '@/views/CostBills.view.vue';
import ClientsView from '@/views/Clients.view.vue';
import CreateClientView from '@/views/CreateClient.view.vue';
import EditClientView from '@/views/EditClient.view.vue';
import CostCodesView from '@/views/CostCodes.view.vue';
import ImportCostCodesView from '@/views/ImportCostCodes.view.vue';
import ImportActivitiesView from '@/views/ImportActivities.view.vue';
import ProjectBudgetsView from '@/views/ProjectBudgets.view.vue';
import ProjectBudgetDetailsView from '@/views/ProjectBudgetDetails.view.vue';
import ProjectChangeOrdersView from '@/views/ProjectChangeOrders.view.vue';
import ProjectChangeOrderDetailsView from '@/views/ProjectChangeOrderDetails.view.vue';
import AuditLogsView from '@/views/AuditLogs.view.vue';
import UnauthorizedView from '@/views/Unauthorized.view.vue';
import ActivityCategoriesView from '@/views/ActivityCategories.view.vue';
import ViewDocumentView from '@/views/ViewDocument.view.vue';
import OrganizationSettingsView from '@/views/OrganizationSettings.view.vue';
import EditCostView from '@/views/EditCost.view.vue';
import ReportsView from '@/views/Reports.view.vue';
import ReportActivitiesByCostCode from '@/views/ReportActivitiesByCostCode.view.vue';
import VendorsView from '@/views/Vendors.view.vue';
import ProjectBillsView from '@/views/ProjectBills.view.vue';
import ProjectCostsView from '@/views/ProjectCosts.view.vue';
import ImportProjectBillsView from '@/views/ImportProjectBills.view.vue';
// import RulesView from '@/views/Rules.view.vue';

Vue.use(VueRouter);

const paths = {
  root: '/',
  signUp: '/sign-up',
  acceptInvitation: '/accept-invitation',
  activityCategories: '/activity-categories',
  logIn: '/log-in',
  resetPassword: '/reset-password',
  authActions: '/auth-actions',
  verifyEmail: '/verify-email',
  projects: '/projects',
  createProject: '/projects/create',
  projectDetails: '/projects/:projectId/details',
  editProject: '/projects/:projectId/edit',
  organizationUsers: '/users',
  userProfile: '/users/:userId/profile',
  createUser: '/users/create',
  bills: '/bills/:billId?',
  costDetails: '/projects/:projectId/costs/:costId/details',
  costBills: '/projects/:projectId/costs/:costId/bills',
  editCost: '/projects/:projectId/costs/:costId/edit',
  importCostActivities: '/projects/:projectId/costs/:costId/activities/import',
  importBudgetActivities: '/projects/:projectId/budgets/:budgetId/activities/import',
  importProjectBills: '/projects/:projectId/bills/import',
  createAdvanceBill: '/projects/:projectId/costs/:costId/create-advance-bill',
  createLumpSumBill: '/projects/:projectId/costs/:costId/create-lump-sum-bill',
  editBillDraft: '/projects/:projectId/costs/:costId/bills/:billId/edit-draft',
  clients: '/clients',
  createClient: '/clients/create',
  editClient: '/clients/:clientId/edit',
  costCodes: '/cost-codes',
  importCostCodes: '/cost-codes/import',
  projectBudgets: '/projects/:projectId/budgets',
  projectBudgetDetails: '/projects/:projectId/budgets/:budgetId/details',
  projectChangeOrders: '/projects/:projectId/change-orders',
  projectCosts: '/projects/:projectId/costs',
  projectChangeOrderDetails: '/projects/:projectId/change-orders/:changeOrderId/details',
  projectBills: '/projects/:projectId/bills',
  auditLogs: '/audit-logs',
  unauthorized: '/unauthorized',
  viewDocument: '/:resourceType/:resourceId/documents/:documentId',
  organizationSettings: '/organization-settings',
  reports: '/reports',
  reportActivitiesByCostCode: '/reports/activities/by-cost-code',
  vendors: '/vendors',
  // rules: '/rules',
};

const unauthenticatedRoutes = [
  paths.signUp,
  paths.logIn,
  paths.resetPassword,
  paths.acceptInvitation,
];

const routesWithAppHeader = [
  paths.logIn,
  paths.signUp,
  paths.authActions,
  paths.verifyEmail,
  paths.resetPassword,
  paths.acceptInvitation,
];

const routesWithNoSideNavigation = [
  paths.logIn,
  paths.signUp,
  paths.authActions,
  paths.verifyEmail,
  paths.resetPassword,
  paths.acceptInvitation,
  paths.viewDocument,
];

const getRouteMetaParams = route => ({
  requiresAuth: !unauthenticatedRoutes.includes(route.path),
  hasSideNavigation: !routesWithNoSideNavigation.includes(route.path),
  hasAppHeader: routesWithAppHeader.includes(route.path),
});

const routes = [
  {
    path: paths.root,
    redirect: paths.projects,
    meta: route => ({
      ...getRouteMetaParams(route),
    }),
  },
  {
    path: paths.logIn,
    name: 'Log In',
    component: LogInView,
    meta: route => ({
      ...getRouteMetaParams(route),
      pageTitle: [
        { title: i18n.t('common.pageTitles.logIn') },
      ],
    }),
  },
  {
    path: paths.signUp,
    name: 'Sign Up',
    component: SignUpView,
    meta: route => ({
      ...getRouteMetaParams(route),
      pageTitle: [
        { title: i18n.t('common.pageTitles.signUp') },
      ],
    }),
  },
  {
    path: paths.authActions,
    redirect: to => {
      const { query } = to;
      if (query.mode === 'verifyEmail') {
        return { path: '/verify-email', query };
      }
      if (query.mode === 'resetPassword') {
        return { path: 'reset-password', query };
      }
      return { path: '/log-in' };
    },
    meta: route => ({
      ...getRouteMetaParams(route),
    }),
  },
  {
    path: paths.verifyEmail,
    name: 'Verify Email',
    component: VerifyEmailView,
    meta: route => ({
      ...getRouteMetaParams(route),
      pageTitle: [
        { title: i18n.t('common.pageTitles.verifyEmail') },
      ],
    }),
  },
  {
    path: paths.resetPassword,
    name: 'Forgot Password',
    component: ForgotPasswordView,
    meta: route => ({
      ...getRouteMetaParams(route),
      pageTitle: [
        { title: i18n.t('common.pageTitles.resetPassword') },
      ],
    }),
  },
  {
    path: paths.unauthorized,
    name: 'Unauthorized',
    component: UnauthorizedView,
    meta: route => ({
      ...getRouteMetaParams(route),
      pageTitle: [
        { title: i18n.t('common.pageTitles.unauthorized') },
      ],
    }),
  },
  {
    path: paths.organizationUsers,
    name: 'Organization Users',
    component: OrganizationUsersView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.users'), path: '/users' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.users') },
      ],
    }),
  },
  {
    path: paths.projects,
    name: 'Projects',
    component: ProjectsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.projects') },
      ],
    }),
  },
  {
    path: paths.clients,
    name: 'Clients',
    component: ClientsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.clients'), path: '/clients' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.clients') },
      ],
    }),
  },
  {
    path: paths.reports,
    name: 'Reports',
    component: ReportsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.terms.reports'), path: '/reports' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.reports') },
      ],
    }),
  },
  {
    path: paths.reportActivitiesByCostCode,
    name: 'Report - Activities By Cost Code',
    component: ReportActivitiesByCostCode,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.terms.reports'), path: '/reports' },
        { title: i18n.t('common.titles.activitiesByCostCode'), path: '/reports/activities/by-cost-code' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.reportActivitiesByCostCode') },
      ],
    }),
  },
  {
    path: paths.bills,
    name: 'Bills',
    component: BillsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.bills'), path: '/bills' },
        ...route.params.billId ? [{
          entity: 'bills',
          path: `/bills/${route.params.billId}`,
          param: 'billId',
          attribute: ['number'],
        }] : [],
      ],
      pageTitle: [
        ...!route.params.billId ? [{ title: i18n.t('common.pageTitles.bills') }] : [],
        ...route.params.billId ? [
          { title: i18n.t('common.terms.bill') },
          {
            entity: 'bills',
            param: 'billId',
            attribute: ['number'],
          },
        ] : [],
      ],
    }),
  },
  {
    path: paths.createProject,
    name: 'Create Project',
    component: CreateProjectView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        { title: i18n.t('common.ctas.createProject'), path: '/projects/create' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.createProject') },
      ],
      allowedRoles: [...RoleConstants.atLeastProjectManagerRoles],
    }),
  },
  {
    path: paths.createClient,
    name: 'Create Client',
    component: CreateClientView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.clients'), path: '/clients' },
        { title: i18n.t('common.ctas.createClient'), path: '/clients/create' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.createClient') },
      ],
      allowedRoles: [...RoleConstants.atLeastProjectManagerRoles],
    }),
  },
  {
    path: paths.costCodes,
    name: 'Cost Codes',
    component: CostCodesView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.costCodes'), path: '/cost-codes' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.costCodes') },
      ],
    }),
  },
  {
    path: paths.vendors,
    name: 'Vendors',
    component: VendorsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.vendors'), path: '/vendors' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.vendors') },
      ],
    }),
  },
  {
    path: paths.activityCategories,
    name: 'Activity Categories',
    component: ActivityCategoriesView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.activityCategories'), path: '/activity-categories' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.activityCategories') },
      ],
    }),
  },
  {
    path: paths.auditLogs,
    name: 'Audit Logs',
    component: AuditLogsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.auditLogs'), path: '/audit-logs' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.auditLogs') },
      ],
      allowedRoles: [...RoleConstants.atLeastAdminRoles],
    }),
  },
  {
    path: paths.importCostCodes,
    name: 'Import Cost Codes',
    component: ImportCostCodesView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.costCodes'), path: paths.costCodes },
        { title: i18n.t('common.titles.importCostCodes'), path: paths.importCostCodes },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.importCostCodes') },
      ],
      allowedRoles: [...RoleConstants.atLeastProjectManagerRoles],
    }),
  },
  {
    path: paths.projectDetails,
    name: 'Project Details',
    component: ProjectDetailsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
      ],
      pageTitle: [
        {
          entity: 'projects',
          param: 'projectId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.editClient,
    name: 'Edit Client',
    component: EditClientView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.clients'), path: '/clients' },
        {
          entity: 'clients',
          path: `/clients/${route.params.clientId}/edit`,
          param: 'clientId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.ctas.edit'),
          path: `/clients/${route.params.clientId}/edit`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.editClient') },
        {
          entity: 'clients',
          param: 'clientId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.editProject,
    name: 'Edit Project',
    component: EditProjectView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.ctas.edit'),
          path: `/projects/${route.params.projectId}/edit`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.editProject') },
        {
          entity: 'projects',
          param: 'projectId',
          attribute: 'name',
        },
      ],
      allowedRoles: [...RoleConstants.atLeastProjectManagerRoles],
    }),
  },
  {
    path: paths.costDetails,
    name: 'Cost Details',
    component: CostDetailsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.costs'), path: `/projects/${route.params.projectId}/costs` },
        {
          entity: 'costs',
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/details`,
          param: 'costId',
          attribute: 'name',
        },
      ],
      pageTitle: [
        {
          entity: 'costs',
          param: 'costId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.editCost,
    name: 'Edit Cost',
    component: EditCostView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.costs'), path: `projects/${route.params.projectId}/costs` },
        {
          entity: 'costs',
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/details`,
          param: 'costId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.ctas.edit'),
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/edit`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.editCost') },
        {
          entity: 'costs',
          param: 'costId',
          attribute: 'name',
        },
      ],
      allowedRoles: [...RoleConstants.atLeastProjectAssistantRoles],
    }),
  },
  {
    path: paths.costBills,
    name: 'Cost Bills',
    component: CostBillsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.costs'), path: `projects/${route.params.projectId}/costs` },
        {
          entity: 'costs',
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/details`,
          param: 'costId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.terms.bills'),
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/bills`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.costBills') },
        {
          entity: 'costs',
          param: 'costId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.importCostActivities,
    name: 'Import Cost Activites',
    component: ImportActivitiesView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.costs'), path: `projects/${route.params.projectId}/costs` },
        {
          entity: 'costs',
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/details`,
          param: 'costId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.titles.importActivities'),
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/activities/import`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.importActivities') },
        {
          entity: 'costs',
          param: 'costId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.importBudgetActivities,
    name: 'Import Budget Activites',
    component: ImportActivitiesView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.budgets'), path: `/projects/${route.params.projectId}/budgets` },
        {
          entity: 'budgets',
          path: `/projects/${route.params.projectId}/costs/${route.params.budgetId}/details`,
          param: 'budgetId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.titles.importActivities'),
          path: `/projects/${route.params.projectId}/budgets/${route.params.budgetId}/activities/import`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.importActivities') },
        {
          entity: 'budgets',
          param: 'budgetId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.importProjectBills,
    name: 'Import Project Bills',
    component: ImportProjectBillsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.bills'), path: `/projects/${route.params.projectId}/bills` },
        {
          title: i18n.t('common.titles.importProjectBills'),
          path: `/projects/${route.params.projectId}/bills/import`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.importProjectBills') },
        {
          entity: 'projects',
          param: 'projectId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.createAdvanceBill,
    name: 'Create Advance Bill',
    component: CreateOrEditBillView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.costs'), path: `projects/${route.params.projectId}/costs` },
        {
          entity: 'costs',
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/details`,
          param: 'costId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.ctas.createAdvanceBill'),
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/create-advance-bill`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.createAdvanceBill') },
      ],
      allowedRoles: [...RoleConstants.atLeastProjectManagerRoles],
    }),
  },
  {
    path: paths.createLumpSumBill,
    name: 'Create Lump Sum Bill',
    component: CreateOrEditBillView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.costs'), path: `/projects/${route.params.projectId}/costs` },
        {
          entity: 'costs',
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/details`,
          param: 'costId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.ctas.createBill'),
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/create-lump-sum-bill`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.createLumpSumBill') },
      ],
    }),
  },
  {
    path: paths.editBillDraft,
    name: 'Edit Bill Draft',
    component: CreateOrEditBillView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.costs'), path: `projects/${route.params.projectId}/costs` },
        {
          entity: 'costs',
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/details`,
          param: 'costId',
          attribute: 'name',
        },
        { title: i18n.t('common.titles.bills'), path: '' },
        {
          entity: 'bills',
          path: `/bills/${route.params.billId}`,
          param: 'billId',
          attribute: 'number',
        },
        {
          title: i18n.t('common.ctas.editOpenBill', {
            billNumber: store.getters.getBillById(route.params.billId)?.number ?? '',
            billStatusName: i18n.t(`common.billStatuses.${store.state.billStatus.billStatuses[store.getters.getBillById(route.params.billId)?.statusId]?.name}`),
          }),
          path: `/projects/${route.params.projectId}/costs/${route.params.costId}/bills/${route.params.billId}/edit-draft`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.editBillDraft') },
        {
          entity: 'bills',
          param: 'billId',
          attribute: 'number',
        },
      ],
    }),
  },
  {
    path: paths.projectBudgets,
    name: 'ProjectBudgets',
    component: ProjectBudgetsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.titles.budgets'),
          path: `/projects/${route.params.projectId}/budgets`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.projectBudgets') },
        {
          entity: 'projects',
          param: 'projectId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.projectBudgetDetails,
    name: 'ProjectBudgetDetails',
    component: ProjectBudgetDetailsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.titles.budgets'),
          path: `/projects/${route.params.projectId}/budgets`,
        },
        {
          entity: 'budgets',
          path: `/projects/${route.params.projectId}/budgets/${route.params.budgetId}/details`,
          param: 'budgetId',
          attribute: 'name',
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.budgetDetails') },
        {
          entity: 'budgets',
          param: 'budgetId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.projectChangeOrders,
    name: 'ProjectChangeOrders',
    component: ProjectChangeOrdersView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.titles.changeOrders'),
          path: `/projects/${route.params.projectId}/change-orders`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.changeOrders') },
        {
          entity: 'projects',
          param: 'projectId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.projectBills,
    name: 'ProjectBills',
    component: ProjectBillsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.titles.bills'),
          path: `/projects/${route.params.projectId}/bills`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.bills') },
        {
          entity: 'projects',
          param: 'projectId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.projectCosts,
    name: 'ProjectCosts',
    component: ProjectCostsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.titles.costs'),
          path: `/projects/${route.params.projectId}/costs`,
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.costs') },
        {
          entity: 'projects',
          param: 'projectId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.projectChangeOrderDetails,
    name: 'ProjectChangeOrderDetails',
    component: ProjectChangeOrderDetailsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.projects'), path: '/projects' },
        {
          entity: 'projects',
          path: `/projects/${route.params.projectId}/details`,
          param: 'projectId',
          attribute: 'name',
        },
        {
          title: i18n.t('common.titles.changeOrders'),
          path: `/projects/${route.params.projectId}/change-orders`,
        },
        {
          entity: 'changeOrders',
          path: `/projects/${route.params.projectId}/change-orders/${route.params.changeOrderId}/details`,
          param: 'changeOrderId',
          attribute: 'name',
        },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.changeOrderDetails') },
        {
          entity: 'changeOrders',
          param: 'changeOrderId',
          attribute: 'name',
        },
      ],
    }),
  },
  {
    path: paths.userProfile,
    name: 'User Profile',
    component: UserProfileView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.users'), path: '/users' },
        {
          entity: 'users',
          path: `/users/${route.params.userId}/profile`,
          param: 'userId',
          attribute: ['firstName', 'lastName'],
          fallbackAttribute: 'email',
        },
      ],
      pageTitle: [
        ...(store.getters.loggedInUser.id !== route.params.userId) ? [
          { title: i18n.t('common.pageTitles.userProfile') },
          {
            entity: 'users',
            param: 'userId',
            attribute: ['firstName', 'lastName'],
            fallbackAttribute: 'email',
          },
        ] : [],
        ...(store.getters.loggedInUser.id === route.params.userId) ? [
          { title: i18n.t('common.pageTitles.myProfile') },
        ] : [],
      ],
    }),
  },
  {
    path: paths.createUser,
    name: 'Create User',
    component: CreateUserView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.users'), path: '/users' },
        { title: i18n.t('common.ctas.createUser'), path: '/users/create' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.createUser') },
      ],
    }),
  },
  {
    path: paths.acceptInvitation,
    name: 'Accept Invitation',
    component: AcceptInvitationView,
    meta: route => ({
      ...getRouteMetaParams(route),
      pageTitle: [
        { title: i18n.t('common.pageTitles.acceptInvitation') },
      ],
    }),
  },
  {
    path: paths.viewDocument,
    name: 'View Document',
    component: ViewDocumentView,
    meta: route => ({
      ...getRouteMetaParams(route),
      pageTitle: [
        { title: i18n.t('common.pageTitles.viewDocument') },
        {
          entity: 'documents',
          param: 'documentId',
          attribute: 'fileName',
        },
      ],
    }),
  },
  {
    path: paths.organizationSettings,
    name: 'Organization Settings',
    component: OrganizationSettingsView,
    meta: route => ({
      ...getRouteMetaParams(route),
      breadcrumbs: [
        { title: i18n.t('common.titles.organizationSettings'), path: '/organization-settings' },
      ],
      pageTitle: [
        { title: i18n.t('common.pageTitles.organizationSettings') },
      ],
      allowedRoles: [...RoleConstants.atLeastAdminRoles],
    }),
  },
  // {
  //   path: paths.rules,
  //   name: 'Organization Rules',
  //   component: RulesView,
  //   meta: route => ({
  //     ...getRouteMetaParams(route),
  //     breadcrumbs: [
  //       { title: i18n.t('common.titles.rules'), path: '/rules' },
  //     ],
  //     pageTitle: [
  //       { title: i18n.t('common.pageTitles.rules') },
  //     ],
  //     allowedRoles: [...RoleConstants.atLeastOwnerRoles],
  //   }),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function routerPathForAuthenticatedUsers(to, from, next) {
  if (
    firebase.auth().currentUser.emailVerified
    && (
      to.path === paths.logIn
      || to.path === paths.verifyEmail
      || to.path === paths.signUp
      || to.path === paths.resetPassword
    )
  ) {
    next(paths.root);
  } else {
    next();
  }
}

function routerPathForUnauthenticatedUsers(to, from, next) {
  if (!getRouteMetaParams(to).requiresAuth) next();
  else next(paths.logIn);
}

router.beforeEach((to, from, next) => {
  if (firebase.auth().currentUser) routerPathForAuthenticatedUsers(to, from, next);
  else routerPathForUnauthenticatedUsers(to, from, next);
});

export default router;
