import { baseForm, baseField } from '@/forms/base.form';

const createActivityCategoryForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'createActivityCategoryForm.name.label',
      name: 'createActivityCategoryForm.name.name',
      placeholder: 'createActivityCategoryForm.name.placeholder',
      value: '',
      required: true,
      ref: 'activityCategoryName',
    },
  },
};

export default createActivityCategoryForm;
