<template>
  <form @submit.prevent="handleCommentSubmission" style="width: 100%;">
    <div
      class="is-flex is-align-items-center is-flex-wrap-wrap"
    >
      <div class="comment__avatar-and-text-area is-flex is-align-items-flex-end">
        <div class="u-m-r-small">
          <UserAvatar :user="loggedInUser" size="small" />
        </div>
        <div
          :class="[
            'comment__text-area',
            'u-m-r-small',
            'u-p-y-xsmall',
            'has-background-white',
            size === 'medium' ? 'is-size-5' : '',
            size === 'small' ? 'is-size-6' : '',
          ]"
          ref="commentTextArea"
          v-contenteditable:newCommentText="true"
          :placeholder="$t('common.ctas.addComment')"
          @keydown.enter="handleCommentSubmission"
        />
      </div>
      <div class="update-comment-button__container">
        <BButton
          native-type="submit"
          type="is-primary"
          :size="`is-${size}`"
          :disabled="newCommentText === ''"
          :loading="isLoading"
          iconLeft="send"
          class="is-hidden-tablet"
        >
        </BButton>
        <BButton
          native-type="submit"
          type="is-primary"
          :size="`is-${size}`"
          :disabled="newCommentText === ''"
          :loading="isLoading"
          iconLeft="send"
          class="is-hidden-mobile"
        >
          <span class="is-hidden-mobile">{{ $t('common.ctas.addComment') }}</span>
        </BButton>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
// import * as Types from '@/constants/Types';
// import * as CommentActions from '@/store/actions/Comment.actions';
import UserAvatar from '@/components/UserAvatar.vue';

export default {
  name: 'CommentTextInput',

  components: {
    UserAvatar,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      validator(propValue) {
        // The value must match one of these strings
        return ['small', 'medium'].indexOf(propValue) !== -1;
      },
      default: 'medium',
    },
  },

  data() {
    return {
      isHoveringComment: false,
      isCommentUpdating: false,
      isEditingComment: false,
      newCommentText: '',
    };
  },

  computed: {
    ...mapGetters([
      'loggedInUser',
    ]),
    // payloadForNewComment() {
    //   return {
    //     commentDate: this.activeCommentDate,
    //     commentText: this.newCommentText,
    //     commentStatus: Types.GOAL_UNMARKED,
    //     workspaceId: this.activeWorkspaceId,
    //     userId: this.activeUserId,
    //   };
    // },
    // payloadForUpdatedComment() {
    //   return {
    //     commentText: this.newCommentText,
    //   };
    // },
    // activeComment() {
    //   if (this.comments[this.activeCommentId]) {
    //     return { ...this.comments[this.activeCommentId] };
    //   }

    //   return {
    //     commentText: '',
    //     commentDate: this.activeCommentDate,
    //     commentStatus: Types.GOAL_UNMARKED,
    //   };
    // },
  },

  methods: {
    async handleCommentSubmission($event) {
      if ($event) $event.preventDefault();

      this.$emit('submit', this.newCommentText);
      this.newCommentText = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.comment__avatar-and-text-area {
  flex-grow: 1;
}

.comment__text-area {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid $grey-light;
  min-width: 230px;

  &:focus {
    outline: none;
    border-bottom: 2px solid $grey;
  }
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: $grey;
}

.update-comment-button__container {
  display: flex;
  justify-content: flex-end;
}
</style>
