import { baseForm, baseField } from '@/forms/base.form';

const acceptInvitationForm = {
  ...baseForm,
  fields: {
    password: {
      ...baseField,
      label: 'common.formFields.password.name',
      name: 'common.formFields.password.name',
      value: '',
      required: true,
      type: 'password',
    },
  },
};

export default acceptInvitationForm;
