<template>
  <div>
    <div
      v-if="!isComponentLoading"
      :class="[
      'create-or-edit-activity-card',
      'card',
      customClass,
      { 'card--dark': isDark },
      { 'card--inset': isInset },
      { 'card--no-radius': !hasBorderRadius }
      ]"
    >
      <CreateOrEditChangeOrderModal
        :isOpen="isAddChangeOrderModalOpen"
        :initialChangeOrderName="createActivityForm.fields.changeOrder.value"
        :projectId="activeProject && activeProject.id"
        @close="handleToggleAddChangeOrderModal"
        @submitSuccess="handleCreateChangeOrderSuccess"
      />
      <div class="card-content">
        <BField v-if="submissionError">
          <BMessage type="is-danger">
            {{ submissionError.message }}
          </BMessage>
        </BField>
        <BField v-if="areAmountsDisabled">
          <BMessage type="is-warning">
            {{ $t('createOrEditActivityCard.activityEditRestriction') }}
          </BMessage>
        </BField>
        <form @submit.prevent="handleSubmitActivity">
          <div class="columns is-mobile is-multiline">
            <div
              :class="[
                'field column is-12-mobile is-5-tablet is-5-desktop is-6-widescreen',
                { 'is-12': areFieldsStacked },
                { 'required': createActivityForm.fields.name.required },
              ]"
            >
              <label :class="['label', size]">{{ $t(createActivityForm.fields.name.label) }}</label>
              <div :class="['control', { 'has-icons-left': createActivityForm.fields.name.icon }]">
                <input
                  :class="['input', 'input--icon-dark', size, { 'is-danger':  createActivityForm.formErrors[$t(createActivityForm.fields.name.name)] }]"
                  v-model="createActivityForm.fields.name.value"
                  :ref="createActivityForm.fields.name.ref"
                  :disabled="createActivityForm.isFormSubmissionInProgress"
                  @input="handleTouchForm"
                />
                <span v-if="createActivityForm.fields.name.icon" class="icon is-left">
                  {{ createActivityForm.fields.name.icon }}
                </span>
              </div>
              <p v-if="createActivityForm.formErrors[$t(createActivityForm.fields.name.name)]" class="help is-danger">
                {{ createActivityForm.formErrors[$t(createActivityForm.fields.name.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-4-mobile is-2-tablet is-2-desktop',
                { 'is-12': areFieldsStacked },
                { 'required': createActivityForm.fields.quantity.required },
              ]"
            >
              <label :class="['label', size]">{{ $t(createActivityForm.fields.quantity.label) }}</label>
              <div :class="['control', { 'has-icons-left': createActivityForm.fields.quantity.icon }]">
                <input
                  :class="['input', 'input--icon-dark', size, { 'is-danger':  createActivityForm.formErrors[$t(createActivityForm.fields.quantity.name)] }]"
                  :disabled="createActivityForm.isFormSubmissionInProgress || areAmountsDisabled"
                  @input="event => handleMaskedInput(event, createActivityForm.fields.quantity.ref)"
                  v-cleave="createActivityForm.fields.quantity.mask"
                  v-model="createActivityForm.fields.quantity.value"
                  :ref="createActivityForm.fields.quantity.ref"
                />
                <span v-if="createActivityForm.fields.quantity.icon" class="icon is-left">
                  {{ createActivityForm.fields.quantity.icon }}
                </span>
              </div>
              <p v-if="createActivityForm.formErrors[$t(createActivityForm.fields.quantity.name)]" class="help is-danger">
                {{ createActivityForm.formErrors[$t(createActivityForm.fields.quantity.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-4-mobile is-2-tablet is-2-desktop',
                { 'is-12': areFieldsStacked },
                { 'required': createActivityForm.fields.unit.required },
              ]"
            >
              <label :class="['label', size]">{{ $t(createActivityForm.fields.unit.label) }}</label>
              <div :class="['control', { 'has-icons-left': createActivityForm.fields.unit.icon }]">
                <input
                  :class="['input', 'input--icon-dark', size, { 'is-danger':  createActivityForm.formErrors[$t(createActivityForm.fields.unit.name)] }]"
                  v-model="createActivityForm.fields.unit.value"
                  :disabled="createActivityForm.isFormSubmissionInProgress || areAmountsDisabled"
                  @input="handleTouchForm"
                />
                <span v-if="createActivityForm.fields.unit.icon" class="icon is-left">
                  {{ createActivityForm.fields.unit.icon }}
                </span>
              </div>
              <p v-if="createActivityForm.formErrors[$t(createActivityForm.fields.unit.name)]" class="help is-danger">
                {{ createActivityForm.formErrors[$t(createActivityForm.fields.unit.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-4-mobile is-3-tablet is-2-widescreen',
                { 'is-12': areFieldsStacked },
                { 'required': createActivityForm.fields.unitValue.required },
              ]"
            >
              <label :class="['label', size]">{{ $t(createActivityForm.fields.unitValue.label) }}</label>
              <div :class="['control', { 'has-icons-left': createActivityForm.fields.unitValue.icon }]">
                <input
                  :class="['input', 'input--icon-dark', size, { 'is-danger':  createActivityForm.formErrors[$t(createActivityForm.fields.unitValue.name)] }]"
                  v-currency="currencyOptions"
                  v-model="createActivityForm.fields.unitValue.value"
                  :disabled="createActivityForm.isFormSubmissionInProgress || areAmountsDisabled"
                  @input="handleTouchForm"
                  :ref="createActivityForm.fields.unitValue.ref"
                />
                <span v-if="createActivityForm.fields.unitValue.icon" class="icon is-left">
                  {{ createActivityForm.fields.unitValue.icon }}
                </span>
              </div>
              <p v-if="createActivityForm.formErrors[$t(createActivityForm.fields.unitValue.name)]" class="help is-danger">
                {{ createActivityForm.formErrors[$t(createActivityForm.fields.unitValue.name)] }}
              </p>
            </div>
            <div
              :class="[
                'field column is-12-mobile is-5-tablet is-6-widescreen',
                { 'is-12': areFieldsStacked },
                { 'required': createActivityForm.fields.costCode.required },
              ]"
            >
              <label :class="['label', size]">{{ $t(createActivityForm.fields.costCode.label) }}</label>
              <div :class="['control', { 'has-icons-left': createActivityForm.fields.costCode.icon }]">
                <BAutocomplete
                  :class="[{
                    'disable-on-select': true,
                  }]"
                  field="label"
                  :data="filteredCostCodes"
                  v-model="createActivityForm.fields.costCode.value"
                  :placeholder="$t(createActivityForm.fields.costCode.placeholder)"
                  :disabled="createActivityForm.isFormSubmissionInProgress || selectedCostCodeId"
                  openOnFocus
                  :maxHeight="200"
                  clearable
                  appendToBody
                  :loading="areCostCodesLoading"
                  :selectOnClickOutside="false"
                  @select="handleSelectCostCode"
                  @blur="handleCostCodeFieldBlur"
                  @input="handleTouchForm"
                  @typing="handleCostCodeAutocompleteTyping"
                  :size="size"
                >
                  <template slot-scope="props">
                    <span v-if="props.option.costCodeCategory">
                      {{ props.option.costCodeCategory.name }} -
                    </span>
                    {{ props.option.code }} {{ props.option.name }}
                  </template>
                  <template #empty>{{ $t('common.emptyStates.noResults') }}</template>
                </BAutocomplete>
                <span v-if="createActivityForm.fields.costCode.icon" class="icon is-left">
                  {{ createActivityForm.fields.costCode.icon }}
                </span>
              </div>
            </div>
            <div
              :class="[
                'field column is-12-mobile is-3-tablet is-3-widescreen',
                { 'is-12': areFieldsStacked },
                { 'required': createActivityForm.fields.activityCategory.required },
              ]"
            >
              <label :class="['label', size]">{{ $t(createActivityForm.fields.activityCategory.label) }}</label>
              <div :class="['control', { 'has-icons-left': createActivityForm.fields.activityCategory.icon }]">
                <BAutocomplete
                  :class="[{
                    'disable-on-select': true,
                  }]"
                  field="name"
                  :data="filteredActivityCategories"
                  v-model="createActivityForm.fields.activityCategory.value"
                  :placeholder="$t(createActivityForm.fields.activityCategory.placeholder)"
                  :disabled="createActivityForm.isFormSubmissionInProgress || selectedCategoryId"
                  openOnFocus
                  :maxHeight="200"
                  clearable
                  appendToBody
                  :selectOnClickOutside="false"
                  @select="handleSelectActivityCategory"
                  @blur="handleActivityCategoryFieldBlur"
                  @input="handleTouchForm"
                  :size="size"
                >
                  <template slot-scope="props">
                    {{ props.option.name }}
                  </template>
                  <template #empty>{{ $t('common.emptyStates.noResults') }}</template>
                </BAutocomplete>
                <span v-if="createActivityForm.fields.activityCategory.icon" class="icon is-left">
                  {{ createActivityForm.fields.activityCategory.icon }}
                </span>
              </div>
            </div>
            <div
              :class="[
                'field column is-12-mobile is-4-tablet is-3-widescreen',
                { 'is-12': areFieldsStacked },
                { 'required': createActivityForm.fields.changeOrder.required },
              ]"
            >
              <label :class="['label', size]">{{ $t(createActivityForm.fields.changeOrder.label) }}</label>
              <div :class="['control', { 'has-icons-left': createActivityForm.fields.changeOrder.icon }]">
                <BAutocomplete
                  :class="[{
                    'disable-on-select': true,
                  }]"
                  field="name"
                  :data="filteredChangeOrders"
                  v-model="createActivityForm.fields.changeOrder.value"
                  :placeholder="$t(createActivityForm.fields.changeOrder.placeholder)"
                  :disabled="createActivityForm.isFormSubmissionInProgress || selectedChangeOrderId"
                  openOnFocus
                  :maxHeight="200"
                  clearable
                  appendToBody
                  :selectOnClickOutside="false"
                  @select="handleSelectChangeOrder"
                  @blur="handleChangeOrderFieldBlur"
                  @input="handleTouchForm"
                  :size="size"
                >
                  <template slot-scope="props">
                    {{ props.option.name }}
                  </template>
                  <template slot="footer">
                    <div @click="handleToggleAddChangeOrderModal" type="is-info" outlined size="is-small" style="width: 100%;">
                      <span class="autocomplete__footer__cta">
                        <BIcon icon="plus-circle-outline" customSize="mdi-18px" />
                        {{
                          (createActivityForm.fields.changeOrder.value && !selectedChangeOrderId)
                            ? $t('createOrEditActivityCard.ctas.createChangeOrder', { changeOrderName: createActivityForm.fields.changeOrder.value })
                            : $t('common.ctas.createChangeOrder')
                        }}
                      </span>
                    </div>
                  </template>
                  <template #empty>{{ $t('common.emptyStates.noResults') }}</template>
                </BAutocomplete>
                <span v-if="createActivityForm.fields.changeOrder.icon" class="icon is-left">
                  {{ createActivityForm.fields.changeOrder.icon }}
                </span>
              </div>
            </div>
            <BField :class="['column', 'is-flex', 'is-align-items-flex-end', 'is-justify-content-flex-end', { 'is-12': areFieldsStacked }]">
              <BButton
                type="is-primary"
                icon-left="plus-thick"
                nativeType="submit"
                :loading="createActivityForm.isFormSubmissionInProgress"
                :disabled="createActivityForm.isFormSubmissionInProgress"
              >
                {{ !activityId ? $t('common.ctas.createActivity') : $t('common.ctas.updateActivity') }}
              </BButton>
            </BField>
          </div>
        </form>
      </div>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isComponentLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import * as Currency from '@/constants/Currency';
import * as ValidationService from '@/services/Validation.service';
import * as ActivityActions from '@/store/actions/Activity.actions';
import * as CostCodeActions from '@/store/actions/CostCode.actions';
import * as ActivityCategoryActions from '@/store/actions/ActivityCategory.actions';
import * as ChangeOrderActions from '@/store/actions/ChangeOrder.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import createActivityForm from '@/forms/createActivity.form';
import { cleave, masks } from '@/directives/Cleave.directive';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import CreateOrEditChangeOrderModal from '@/components/CreateOrEditChangeOrderModal.vue';

export default {
  name: 'CreateOrEditActivityCard',

  directives: { cleave },

  components: {
    CreateOrEditChangeOrderModal,
  },

  props: {
    costId: {
      type: String,
      required: false,
      default: null,
    },
    budgetId: {
      type: String,
      required: false,
      default: null,
    },
    projectId: {
      type: String,
      required: false,
      default: null,
    },
    changeOrderId: {
      type: String,
      required: false,
      default: null,
    },
    activityCategoryId: {
      type: String,
      require: false,
      default: null,
    },
    // Pass an exiting activityId if you wish to edit an existing activity.
    activityId: {
      type: String,
      required: false,
      default: null,
    },
    // Pass onSubmit if you want to control the submit payload.
    // Otherwise it will post to the API and create the activity for the costId.
    onSubmit: {
      type: Function,
      required: false,
      default: null,
    },
    isDark: {
      type: Boolean,
      required: false,
      default: true,
    },
    isInset: {
      type: Boolean,
      required: false,
      default: true,
    },
    areFieldsStacked: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasBorderRadius: {
      type: Boolean,
      required: false,
      default: true,
    },
    size: {
      type: String,
      required: false,
      validator(propValue) {
        // The value must match one of these strings
        return ['is-small', 'is-default', 'is-medium'].indexOf(propValue) !== -1;
      },
      default: 'is-default',
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      isComponentLoading: false,
      masks,
      createActivityForm: cloneDeep(createActivityForm),
      submissionError: null,
      currencyOptions: { ...Currency.defaultCurrency },
      selectedCostCodeId: null,
      selectedCategoryId: null,
      selectedChangeOrderId: null,
      isAddCostCodeModalOpen: false,
      isAddChangeOrderModalOpen: false,
      areCostCodesLoading: false,
      costCodeIds: [],
      costCodeAutocompleteTimeout: null,
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      activityCategories: state => state.activityCategory.activityCategories,
      budgets: state => state.budget.budgets,
      costs: state => state.cost.costs,
      costCodes: state => state.costCode.costCodes,
      costCodeCategories: state => state.costCodeCategory.costCodeCategories,
      projects: state => state.project.projects,
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    ...mapGetters([
      'activeOrganization',
      'getActivityTotalAdvanceAmount',
      'getChangeOrdersByProjectId',
    ]),
    activeCostId() {
      return this.costId;
    },
    activeCost() {
      return this.costs[this.activeCostId];
    },
    activeBudgetId() {
      return this.budgetId;
    },
    activeBudget() {
      return this.budgets[this.activeBudgetId];
    },
    activeActivity() {
      return this.activities[this.activityId];
    },
    activeProject() {
      if (!this.projectId) {
        const projectId = this.activeCost ? this.activeCost.projectId : this.activeBudget.projectId;
        return this.projects[projectId];
      }

      return this.projects[this.projectId];
    },
    sortedCostCodes() {
      return (this.costCodeIds.length > 0)
        ? this.costCodeIds.map(id => this.costCodes[id])
          .map(costCode => ({
            ...costCode,
            costCodeCategory: this.costCodeCategories[costCode?.costCodeCategoryId],
            label: this.shapeCostCodeLabel(costCode.id),
          }))
          .sort((a, b) => {
            if (a.costCodeCategory && b.costCodeCategory) {
              return a.costCodeCategory.name > b.costCodeCategory.name ? 1 : -1;
            }

            if (a.costCodeCategory && !b.costCodeCategory) {
              return a.costCodeCategory.name > b.code ? 1 : -1;
            }

            if (!a.costCodeCategory && b.costCodeCategory) {
              return a.code > b.costCodeCategory.name ? 1 : -1;
            }

            return a.code > b.code ? 1 : -1;
          })
        : [];
    },
    filteredCostCodes() {
      return this.createActivityForm.fields.costCode.value
        ? this.sortedCostCodes
          .filter(({ code, name, costCodeCategory }) => (
            code?.toLowerCase().includes(this.createActivityForm.fields.costCode.value.toLowerCase())
            || costCodeCategory?.name.toLowerCase().includes(this.createActivityForm.fields.costCode.value.toLowerCase())
            || name?.toLowerCase().includes(this.createActivityForm.fields.costCode.value.toLowerCase())
          ))
        : [...this.sortedCostCodes];
    },
    sortedActivityCategories() {
      return Object.values(this.activityCategories).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    filteredActivityCategories() {
      return this.newActivityCategoryName
        ? this.sortedActivityCategories
          .filter(({ name }) => (
            name?.toLowerCase().includes(this.newActivityCategoryName.toLowerCase())
          ))
        : [...this.sortedActivityCategories];
    },
    sortedChangeOrders() {
      return this.getChangeOrdersByProjectId(this.activeProject.id).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    filteredChangeOrders() {
      return this.createActivityForm.fields.changeOrder.value
        ? this.sortedChangeOrders
          .filter(({ name }) => (
            name?.toLowerCase().includes(this.createActivityForm.fields.changeOrder.value.toLowerCase())
          ))
        : [...this.sortedChangeOrders];
    },
    areAmountsDisabled() {
      return this.activityId && this.getActivityTotalAdvanceAmount(this.activityId) > 0;
    },
  },

  async created() {
    await this.fetchInitialData();
    this.setInitialFormState();
  },

  methods: {
    handleSelectCostCode(costCode) {
      this.selectedCostCodeId = costCode ? costCode.id : null;
    },
    handleSelectActivityCategory(activityCategory) {
      this.selectedCategoryId = activityCategory ? activityCategory.id : null;
    },
    handleSelectChangeOrder(changeOrder) {
      this.selectedChangeOrderId = changeOrder ? changeOrder.id : null;
    },
    handleCostCodeFieldBlur() {
      // Wrapped in a setTimeout to avoid race condition of events between @select and @blur.
      setTimeout(() => {
        if (!this.selectedCostCodeId) {
          this.createActivityForm.fields.costCode.value = '';
        }
      }, 200);
    },
    handleActivityCategoryFieldBlur() {
      // Wrapped in a setTimeout to avoid race condition of events between @select and @blur.
      setTimeout(() => {
        if (!this.selectedCategoryId) {
          this.createActivityForm.fields.activityCategory.value = '';
        }
      }, 200);
    },
    handleChangeOrderFieldBlur() {
      // Wrapped in a setTimeout to avoid race condition of events between @select and @blur.
      setTimeout(() => {
        if (!this.selectedChangeOrderId) {
          this.createActivityForm.fields.changeOrder.value = '';
        }
      }, 200);
    },
    handleToggleAddChangeOrderModal() {
      this.isAddChangeOrderModalOpen = !this.isAddChangeOrderModalOpen;
    },
    handleCreateChangeOrderSuccess(changeOrderId) {
      this.selectedChangeOrderId = changeOrderId;
      this.createActivityForm.fields.changeOrder.value = this.changeOrders[this.selectedChangeOrderId].name;
      this.handleToggleAddChangeOrderModal();
    },
    handleTouchForm() {
      console.log('form touched');
    },
    async fetchInitialData() {
      this.isComponentLoading = true;
      const [{ costCodeIds }] = await Promise.all([
        this.$store.dispatch(
          CostCodeActions.FETCH_ORGANIZATION_COST_CODES,
          {
            organizationId: this.activeOrganization.id,
            params: { pageNumber: 1, pageSize: 50 },
          },
        ),
        this.$store.dispatch(
          ActivityCategoryActions.FETCH_ORGANIZATION_ACTIVITY_CATEGORIES,
          { organizationId: this.activeOrganization.id },
        ),
        ...this.activeProject?.id && [
          this.$store.dispatch(
            ChangeOrderActions.FETCH_PROJECT_CHANGE_ORDERS,
            { projectId: this.activeProject.id },
          )],
      ]);
      this.costCodeIds = costCodeIds;
      this.isComponentLoading = false;
    },
    async handleCostCodeAutocompleteTyping() {
      this.areCostCodesLoading = true;
      clearTimeout(this.costCodeAutocompleteTimeout);
      this.costCodeAutocompleteTimeout = setTimeout(async () => {
        try {
          const { costCodeIds } = await this.$store.dispatch(
            CostCodeActions.FETCH_ORGANIZATION_COST_CODES,
            {
              organizationId: this.activeOrganization.id,
              params: { pageNumber: 1, pageSize: 50, search: this.createActivityForm.fields.costCode.value },
            },
          );
          this.costCodeIds = costCodeIds;
        } catch (err) {
          console.error(err);
          NotificationService.showNotification(NotificationTypes.FETCH_COST_CODES.ERROR);
        }
      }, 300);

      this.areCostCodesLoading = false;
    },
    async handleSubmitActivity() {
      this.submissionError = null;
      this.createActivityForm.formErrors = {};
      this.createActivityForm.formErrors = ValidationService.runFormValidation({ ...this.createActivityForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.createActivityForm.formErrors)) return;

      this.createActivityForm.isFormSubmissionInProgress = true;

      const categoryId = this.selectedCategoryId || (this.activityCategoryId === 'no-category' ? null : this.activityCategoryId) || null;

      const activityPayload = {
        name: this.createActivityForm.fields.name.value,
        quantity: Number(this.createActivityForm.fields.quantity.rawValue),
        unit: this.createActivityForm.fields.unit.value,
        unitValue: this.$ci.parse(this.createActivityForm.fields.unitValue.value || 0, this.currencyOptions),
        ...this.costId && { costId: this.costId },
        ...this.budgetId && { budgetId: this.budgetId },
        ...this.selectedCostCodeId && { costCodeId: this.selectedCostCodeId },
        ...categoryId && { activityCategoryId: categoryId },
        ...this.selectedChangeOrderId && { changeOrderId: this.selectedChangeOrderId },
      };

      if (this.onSubmit) {
        this.onSubmit(activityPayload);
        this.$emit('submitSuccess');
      } else {
        try {
          let activityId;
          if (this.activityId) {
            activityId = await this.$store.dispatch(ActivityActions.UPDATE_ACTIVITY_BY_ID, {
              activityId: this.activityId,
              updatedActivity: activityPayload,
            });
          } else {
            activityId = await this.$store.dispatch(ActivityActions.CREATE_ACTIVITY, { newActivity: activityPayload });
          }
          this.$emit('submitSuccess', activityId);
        } catch (err) {
          console.log(err);
          this.submissionError = err;
        }
      }

      this.createActivityForm.isFormSubmissionInProgress = false;

      this.setInitialFormState();
    },
    handleMaskedInput(event, inputKey) {
      this.createActivityForm.fields[inputKey].rawValue = event.target.cleave.getRawValue(); // eslint-disable-line
      this.createActivityForm.fields[inputKey].value = event.target.cleave.getFormattedValue(); // eslint-disable-line
    },
    setInitialFormState() {
      this.createActivityForm = cloneDeep(createActivityForm);
      if (this.activityId) {
        const {
          name,
          unit,
          unitValue,
          quantity,
          costCodeId,
          activityCategoryId,
          changeOrderId,
        } = this.activeActivity;

        this.createActivityForm.fields.name.value = name;
        this.$refs.quantity.cleave.setRawValue(Number(quantity));
        this.createActivityForm.fields.unit.value = unit;
        this.createActivityForm.fields.unitValue.value = (unitValue / 100).toString();
        this.$ci.setValue(this.$refs.unitValue, unitValue);

        this.selectedCostCodeId = costCodeId || null;
        this.selectedCategoryId = activityCategoryId || this.activityCategoryId || null;
        this.selectedChangeOrderId = changeOrderId || null;

        if (this.selectedCostCodeId) {
          this.createActivityForm.fields.costCode.value = this.shapeCostCodeLabel(this.selectedCostCodeId);
        }

        if (this.activityCategoryId && this.activityCategoryId !== 'no-category') {
          this.createActivityForm.fields.activityCategory.value = this.activityCategories[this.activityCategoryId]?.name;
        }

        if (this.selectedCategoryId) {
          this.createActivityForm.fields.activityCategory.value = this.activityCategories[this.selectedCategoryId]?.name;
        }

        if (this.selectedChangeOrderId) {
          this.createActivityForm.fields.changeOrder.value = this.changeOrders[this.selectedChangeOrderId]?.name;
        }
      } else {
        this.createActivityForm = cloneDeep(createActivityForm);
        this.selectedCostCodeId = null;
        this.selectedCategoryId = null;
        this.selectedChangeOrderId = null;
        this.$refs.quantity.cleave.setRawValue('');
      }

      // setTimeout here to avoid race condition with other timed events.
      setTimeout(() => {
        if (this.$refs.name) this.$refs.name.focus();
      }, 210);
    },
    shapeCostCodeLabel(costCodeId) {
      const costCode = this.costCodes[costCodeId];
      const code = costCode?.code;
      const name = costCode?.name;
      const category = this.costCodeCategories[costCode?.costCodeCategoryId]?.name;

      return `${category ? `${category} ` : ''} ${code} ${name}`;
    },
  },
};
</script>

<style scoped lang="scss">
.create-or-edit-activity-card {
  // hide when printing page
  @media print { display: none; }
}
</style>
