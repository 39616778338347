<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BModal
        :active="isRolesModalOpen"
        hasModalCard
        trapFocus
        ariaModal
        :canCancel="false"
      >
        <div class="modal-card" >
          <div class="modal-card-head">
            <p class="modal-card-title">{{ $t('common.ctas.edit') }} {{ $t('common.terms.role') }}</p>
            <!-- <BButton type="is-text" size="is-small" @click="handleToggleRolesModal">
              <BIcon icon="close" />
            </BButton> -->
          </div>
          <div class="modal-card-body">
            <!-- ERROR NOTIFICATION -->
            <BField v-if="roleUpdateApiError">
              <BMessage type="is-danger">
                {{ roleUpdateApiError.message }}
              </BMessage>
            </BField>
            <CardRow
              v-for="role in getAvailableSystemRoles.sort(sortRolesByLevel)"
              :key="role.id"
              isClickable
              @click="handleSelectRole(role.id)"
            >
              <template v-slot:content-left>
                <div class="is-flex is-justify-content-space-between is-flex-grow-1">
                  <div>
                    <h5 class="title is-5">{{ $t(`common.roleNames.${role.name}`) }}</h5>
                    <p class="subtitle is-size-6">{{ $t(`common.roleDescriptions.${role.name}`) }}</p>
                  </div>
                  <div v-if="selectedRoleId === role.id" class="is-flex is-align-items-center" style="width: 21px;">
                    <BIcon icon="check" type="is-success" customSize="mdi-36px" />
                  </div>
                  <div v-else class="is-flex is-align-items-center" style="width: 21px;" />
                </div>
              </template>
              <template v-slot:content-right>
              </template>
            </CardRow>
          </div>
        </div>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleToggleRolesModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-primary"
              :disabled="!isRoleSelectDirty"
              @click.prevent="handleSubmitUpdateRole"
              :loading="isRoleUpdateInProgress"
            >
              {{ $t('common.ctas.save') }}
            </BButton>
          </div>
        </footer>
      </BModal>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <UserDisplayName :activeUserId="activeUser.id" size="x-large" :isClickable="false" />
        </div>
      </section>

      <section class="app-page__section">
        <div>
          <form @submit.prevent="handleSubmitUpdateUser">
            <BField v-if="apiError">
              <BMessage type="is-danger">
                {{ apiError.message }}
              </BMessage>
            </BField>
            <h5 class="title is-5 has-text-grey">
              {{ $t('userProfileView.sectionTitles.details' )}}
            </h5>
            <div class="columns">
              <BField
                class="column"
                :label="$t(editUserForm.firstName.label)"
                :type="formErrors[$t(editUserForm.firstName.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(editUserForm.firstName.name)] || ''"
              >
                <BInput
                  v-model="editUserForm.firstName.value"
                  :ref="$t(editUserForm.firstName.name)"
                  :disabled="isFormSubmissionInProgress || editUserForm.firstName.disabled || !canLoggedInUserEditProfile"
                  @input="handleTouchForm"
                />
              </BField>
              <BField
                class="column"
                :label="$t(editUserForm.lastName.label)"
                :type="formErrors[$t(editUserForm.lastName.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(editUserForm.lastName.name)] || ''"
              >
                <BInput
                  v-model="editUserForm.lastName.value"
                  :ref="$t(editUserForm.lastName.name)"
                  :disabled="isFormSubmissionInProgress || editUserForm.lastName.disabled || !canLoggedInUserEditProfile"
                  @input="handleTouchForm"
                />
              </BField>
            </div>
            <div class="columns">
              <BField
                class="column is-6"
                :label="$t(editUserForm.email.label)"
                :type="formErrors[$t(editUserForm.email.name)] ? 'is-danger' : ''"
                :message="formErrors[$t(editUserForm.email.name)] || ''"
              >
                <BInput
                  v-model="editUserForm.email.value"
                  :ref="$t(editUserForm.email.name)"
                  :disabled="isFormSubmissionInProgress || editUserForm.email.disabled"
                  @input="handleTouchForm"
                />
              </BField>
            </div>
            <!-- <div class="is-flex u-m-b">
              <h5 class="title is-5 has-text-grey">
                {{ $t('userProfileView.sectionTitles.projects' )}}
              </h5>
              <BButton
                type="is-primary"
                outlined
                size="is-small"
                style="margin-left: auto;"
                icon-left="plus-thick"
              >
                {{ $t('common.ctas.addProject') }}
              </BButton>
            </div>
            <div class="columns">
              <div class="column">
                <div class="card">
                  <div class="card-content">
                    <CardRow
                      v-for="projectId in activeUser.projects"
                      :key="projectId"
                      isClickable
                      @click="() => handleProjectClick(projectId)"
                    >
                      <template v-slot:content-left>
                        <h6 class="title is-6">{{ projects[projectId].name }}</h6>
                      </template>
                      <BTag rounded>
                        {{ projectStatuses[projects[projectId].statusId].name }}
                      </BTag>
                      <template v-slot:content-right>
                        <p>{{ projects[projectId].description }}</p>
                      </template>
                    </CardRow>
                  </div>
                </div>
              </div>
            </div> -->
            <h5 class="title is-5 has-text-grey u-m-t">
              {{ $t('common.terms.role' )}}
            </h5>
            <div class="columns">
              <div class="column">
                <div class="card">
                  <div class="card-content">
                    <CardRow
                      v-for="roleId in activeUser.roles"
                      :key="roleId"
                    >
                      <template v-slot:content-left>
                        <div>
                          <h5 class="title is-5">{{ $t(`common.roleNames.${roles[roleId].name}`) }}</h5>
                          <p class="subtitle is-size-6">{{ $t(`common.roleDescriptions.${roles[roleId].name}`) }}</p>
                        </div>
                      </template>
                      <template v-slot:content-right>
                        <BNotification
                          v-if="doesUserHaveAnyRoles(activeUser.id, [systemRoleNames.owner])"
                          type="is-primary is-light"
                          :closable="false"
                        >
                          {{ $t('userProfileView.roles.ownerDisclaimer') }}
                        </BNotification>
                        <BButton
                          v-else-if="isLoggedInUserAtLeastAdmin"
                          size="is-small"
                          type="is-primary is-light"
                          @click.prevent="handleToggleRolesModal"
                        >
                          {{ $t('common.ctas.edit') }} {{ $t('common.terms.role') }}
                        </BButton>
                      </template>
                    </CardRow>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="canLoggedInUserEditProfile"
              class="is-flex is-justify-content-flex-end"
            >
              <BButton
                type="is-primary"
                native-type="submit"
                :disabled="isFormSubmissionInProgress"
                :loading="isFormSubmissionInProgress"
              >
                {{ $t('common.ctas.save') }}
              </BButton>
            </div>
          </form>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import * as ProjectActions from '@/store/actions/Project.actions';
import * as UserActions from '@/store/actions/User.actions';
import * as ValidationService from '@/services/Validation.service';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import { sortRolesByLevel } from '@/helpers/roleHelpers';
import { systemRoleNames, atLeastAdminRoles } from '@/constants/Role.constants';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import CardRow from '@/components/CardRow.vue';
import UserDisplayName from '@/components/UserDisplayName.vue';

export default {
  name: 'UserProfile',

  components: {
    CardRow,
    UserDisplayName,
  },

  data() {
    return {
      systemRoleNames,
      atLeastAdminRoles,
      isRolesModalOpen: false,
      isPageLoading: true,
      isFormSubmissionInProgress: false,
      selectedRoleId: null,
      isRoleSelectDirty: false,
      isRoleUpdateInProgress: false,
      roleUpdateApiError: null,
      editUserForm: {
        firstName: {
          label: 'common.formFields.firstName.label',
          name: 'common.formFields.firstName.name',
          value: '',
          required: true,
        },
        lastName: {
          label: 'common.formFields.lastName.label',
          name: 'common.formFields.firstName.name',
          value: '',
          required: true,
        },
        email: {
          label: 'common.formFields.email.label',
          name: 'common.formFields.email.name',
          value: '',
          required: true,
          disabled: true,
          validator: ValidationService.validateEmail,
        },
      },
      formErrors: {},
      apiError: null,
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      users: state => state.user.users,
      projectStatuses: state => state.projectStatus.projectStatuses,
      roles: state => state.role.roles,
    }),
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
      'doesUserHaveAnyRoles',
      'getAvailableSystemRoles',
      'isLoggedInUserAtLeastAdmin',
    ]),
    activeUserId() {
      return this.$route.params.userId;
    },
    activeUser() {
      return this.users[this.activeUserId];
    },
    canLoggedInUserEditProfile() {
      return this.isLoggedInUserAtLeastAdmin || this.loggedInUser.id === this.activeUser.id;
    },
  },

  mounted() {
    this.fetchInitialData();
  },

  methods: {
    isObjectEmpty,
    sortRolesByLevel,
    async handleSubmitUpdateUser() {
      try {
        this.apiError = null;
        this.formErrors = {};
        this.formErrors = ValidationService.runFormValidation({ ...this.editProjectForm });
        if (!isObjectEmpty(this.formErrors)) return;

        this.isFormSubmissionInProgress = true;
        await this.$store.dispatch(
          UserActions.UPDATE_USER,
          {
            id: this.activeUser.id,
            updatedUser: {
              firstName: this.editUserForm.firstName.value,
              lastName: this.editUserForm.lastName.value,
            },
          },
        );

        NotificationService.showNotification(NotificationTypes.UPDATE_USER_PROFILE.SUCCESS);
      } catch (err) {
        this.apiError = err;
        console.error(err);
        NotificationService.showNotification(NotificationTypes.UPDATE_USER_PROFILE.ERROR);
      }

      this.isFormSubmissionInProgress = false;
    },
    async handleSubmitUpdateRole() {
      this.roleUpdateApiError = null;
      this.isRoleUpdateInProgress = true;
      try {
        await this.$store.dispatch(
          UserActions.UPDATE_USER_ROLE,
          {
            id: this.activeUser.id,
            roleId: this.selectedRoleId,
          },
        );

        NotificationService.showNotification(NotificationTypes.UPDATE_USER_ROLES.SUCCESS);
        this.handleToggleRolesModal();
      } catch (err) {
        console.error(err);
        this.roleUpdateApiError = err;
        NotificationService.showNotification(NotificationTypes.UPDATE_USER_ROLES.ERROR);
      }

      this.isRoleUpdateInProgress = false;
      [this.selectedRoleId] = this.activeUser.roles;
      this.isRoleSelectDirty = false;
    },
    handleTouchForm() {
      console.log('form touched');
    },
    handleProjectClick(projectId) {
      this.$router.push(`/projects/${projectId}/details`);
    },
    setInitialFormState() {
      const {
        firstName,
        lastName,
        email,
        roles,
      } = this.activeUser;
      this.editUserForm.firstName.value = firstName || '';
      this.editUserForm.lastName.value = lastName || '';
      this.editUserForm.email.value = email || '';

      [this.selectedRoleId] = roles;
    },
    handleSelectRole(roleId) {
      this.selectedRoleId = roleId;
      this.isRoleSelectDirty = true;
    },
    async fetchInitialData() {
      this.isPageLoading = true;

      await Promise.all([
        this.$store.dispatch(UserActions.FETCH_USER_BY_ID, { userId: this.activeUserId }),
      ]);

      this.setInitialFormState();
      this.isPageLoading = false;
    },
    handleToggleRolesModal() {
      this.isRolesModalOpen = !this.isRolesModalOpen;
      [this.selectedRoleId] = this.activeUser.roles;
      this.isRoleSelectDirty = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
