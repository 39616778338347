import { normalize } from 'normalizr';
import * as OrganizationActions from '@/store/actions/Organization.actions';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as AuthMutations from '@/store/mutations/Auth.mutations';
import * as ClientMutations from '@/store/mutations/Client.mutations';
import * as Api from '@/services/Api.service';
import * as schemas from '@/store/schemas';

const getInitialState = () => ({
  activeOrganizationId: null,
  isWritingData: false,
  isFetchingData: false,
  organizations: {},
});

function receiveOrganizations(state, payload) {
  state.organizations = {
    ...state.organizations,
    ...payload.organizations,
  };
  state.isFetchingData = false;
}

const state = getInitialState();

const getters = {
  activeOrganization(state) {
    return state.organizations[state.activeOrganizationId] || {};
  },
};

const mutations = {
  [OrganizationMutations.ORGANIZATION_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [OrganizationMutations.ORGANIZATION_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [OrganizationMutations.ORGANIZATION_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveOrganizations,
  [UserMutations.RECEIVE_USERS]: receiveOrganizations,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveOrganizations,
  [ClientMutations.RECEIVE_CLIENTS]: receiveOrganizations,
  [OrganizationMutations.SET_ACTIVE_ORGANIZATION](state, payload) {
    state.activeOrganizationId = payload;
  },
  [AuthMutations.LOG_OUT](state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  async [OrganizationActions.CREATE_ORGANIZATION](context, payload) {
    context.commit(OrganizationMutations.ORGANIZATION_BEGIN_WRITE_DATA);
    const { newOrganization } = payload;
    const { data: { organization } } = await Api.organization.create(newOrganization);
    const normalizedOrganization = normalize(organization, schemas.organization);

    context.commit(OrganizationMutations.RECEIVE_ORGANIZATIONS, normalizedOrganization.entities);
    context.commit(OrganizationMutations.ORGANIZATION_COMPLETE_WRITE_DATA);

    return normalizedOrganization;
  },

  // async [OrganizationActions.FETCH_ORGANIZATION_BY_ID](context, payload) {
  //   context.commit(OrganizationMutations.ORGANIZATION_REQUEST_DATA);
  //   try {
  //     const project = await DatabaseService.getOrganizationById(payload.projectId);
  //     if (project.exists) {
  //       const projectDocument = { [project.id]: { id: project.id, ...project.data() } };
  //       context.commit(OrganizationMutations.RECEIVE_ORGANIZATIONS, { organizations: projectDocument });
  //     } else {
  //       context.commit(OrganizationMutations.RECEIVE_ORGANIZATIONS, {});
  //     }
  //     return;
  //   } catch (err) {
  //     console.error(err); // eslint-disable-line no-console
  //     throw new Error(err);
  //   }
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
