<template>
  <div id="app" class="has-navbar-fixed-top">
    <ErrorBoundary>
      <div v-if="!isAppLoading" class="app-content-wrapper">
        <FloatingActionButton
          v-if="doesRouteHaveSideNavigation"
          class="app__floating-menu-button"
          buttonType="is-primary"
          :onClick="() => handleToggleSideNavigation(!isSideNavigationOpen)"
        >
          <BIcon custom-size="mdi-18px" icon="menu" class="u-m-r-xsmall" />menu
        </FloatingActionButton>
        <SideNavigation v-if="doesRouteHaveSideNavigation" />
        <div
          :class="[
            'main-wrapper',
            { 'has-side-navigation': doesRouteHaveSideNavigation },
            { 'has-app-header': doesRouteHaveAppHeader },
          ]"
        >
          <AppHeader v-if="doesRouteHaveAppHeader"
            :doesRouteHaveSideNavigation="doesRouteHaveSideNavigation"
            :doesRouteHaveBreadcrumbs="!!doesRouteHaveBreadcrumbs"
          />
          <main>
            <DocumentUploadStatusBanner />
            <Breadcrumbs v-if="!!doesRouteHaveBreadcrumbs" :route="$route" />
            <RouterView />
          </main>
        </div>
      </div>
      <BLoading is-full-page :active.sync="isAppLoading" :can-cancel="false"></BLoading>
    </ErrorBoundary>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import * as AuthActions from '@/store/actions/Auth.actions';
import * as UserActions from '@/store/actions/User.actions';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as UiMutations from '@/store/mutations/Ui.mutations';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import { shapeUserDisplayName } from '@/helpers/userHelpers';
import { fetchOrganizationRequiredData } from '@/helpers/organizationHelpers';
import { fetchFeatures } from '@/plugins/GrowthBook';
import setDocumentTitle from '@/mixins/setDocumentTitle';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import SideNavigation from '@/components/SideNavigation.vue';
import AppHeader from '@/components/AppHeader.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import FloatingActionButton from '@/components/FloatingActionButton.vue';
import DocumentUploadStatusBanner from '@/components/DocumentUploadStatusBanner.vue';

export default {
  components: {
    AppHeader,
    SideNavigation,
    Breadcrumbs,
    FloatingActionButton,
    DocumentUploadStatusBanner,
    ErrorBoundary,
  },

  mixins: [setDocumentTitle],

  data() {
    return {
      isInitialStateLoading: true,
    };
  },

  computed: {
    ...mapState({
      isSessionAuthLoading: state => state.auth.isSessionAuthLoading,
      isUserLoggingOut: state => state.auth.isUserLoggingOut,
      authUser: state => state.auth.authUser,
      isSideNavigationOpen: state => state.ui.isSideNavigationOpen,
    }),
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
      'getSystemRoleByName',
    ]),
    doesRouteHaveAppHeader() {
      if (typeof this.$route.meta !== 'function') return false;
      return this.$route.meta(this.$route).hasAppHeader;
    },
    doesRouteHaveSideNavigation() {
      if (typeof this.$route.meta !== 'function') return false;
      return this.$route.meta(this.$route).hasSideNavigation;
    },
    doesRouteHaveBreadcrumbs() {
      if (typeof this.$route.meta !== 'function') return false;
      return this.$route.meta(this.$route).breadcrumbs;
    },
    isAppLoading() {
      return (
        this.isSessionAuthLoading
        || this.isUserLoggingOut
        || this.isInitialStateLoading
      );
    },
  },

  async created() {
    await fetchFeatures(this.$GrowthBook);
    this.getInitialState();
  },

  beforeUpdate() {
    if (typeof this.$route.meta === 'function' && this.$route.meta(this.$route).requiresAuth && isObjectEmpty(this.authUser)) {
      this.$router.push('/log-in');
    }

    this.verifyRouteAccess();
  },

  methods: {
    handleToggleSideNavigation(isSideNavigationOpen) {
      this.$store.commit(UiMutations.SET_SIDE_NAVIGATION_STATE, { isSideNavigationOpen });
    },
    ...mapActions({
      logInWithExistingSessionToken: AuthActions.LOG_IN_WITH_EXISTING_SESSION_TOKEN,
      logOutUser: AuthActions.LOG_OUT_USER,
    }),
    async getInitialState() {
      if (!this.authUser || isObjectEmpty(this.authUser)) {
        try {
          await this.logInWithExistingSessionToken();
          const userId = await this.$store.dispatch(UserActions.FETCH_USER_BY_ID, {
            userId: this.authUser.uid,
          });
          this.$store.commit(UserMutations.SET_LOGGED_IN_USER, userId);
          this.$store.commit(OrganizationMutations.SET_ACTIVE_ORGANIZATION, this.loggedInUser.organizationId);

          await fetchOrganizationRequiredData(this.activeOrganization.id);

          this.$FullStory.identify(this.loggedInUser.id, {
            displayName: shapeUserDisplayName(this.loggedInUser),
            email: this.loggedInUser.email,
          });

          this.$GrowthBook.setAttributes({
            userId: this.loggedInUser.id,
            organizationId: this.loggedInUser.organizationId,
          });

          this.isInitialStateLoading = false;
        } catch (err) {
          console.error(err);

          if (this.$route.meta(this.$route).requiresAuth) {
            this.logOutUser();
            this.$router.push('/log-in');
          }
          this.isInitialStateLoading = false;
        }
      }
    },
    verifyRouteAccess() {
      if (typeof this.$route.meta === 'function' && this.$route.meta(this.$route).allowedRoles?.length > 0) {
        const requiredRoleIds = this.$route.meta(this.$route).allowedRoles.map(this.getSystemRoleByName).map(r => r.id);

        const doesUserHaveAnyOfAllowedRoles = this.loggedInUser.roles.filter(r => requiredRoleIds.includes(r)).length > 0;

        if (!doesUserHaveAnyOfAllowedRoles) {
          this.$router.push('/unauthorized');
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import './styles/main.scss';
.app__floating-menu-button {
  // hide when printing page
  @media print { display: none; }
}

.has-side-navigation {
  @media screen and (min-width: ($breakpoint-to-hide-side-navigation + 1)) {
    margin-left: calc(#{$side-navigation-width});
  }
}

.has-app-header {
  padding-top: 3.75rem;
}
</style>
