import { normalize } from 'normalizr';
import * as ClientActions from '@/store/actions/Client.actions';
import * as ClientMutations from '@/store/mutations/Client.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  clients: {},
});

const receiveClients = (state, payload) => {
  state.clients = {
    ...state.clients,
    ...payload.clients,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  // placeholder
};

const mutations = {
  [ClientMutations.CLIENT_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [ClientMutations.CLIENT_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [ClientMutations.CLIENT_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [ClientMutations.RECEIVE_CLIENTS]: receiveClients,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveClients,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveClients,
};

const actions = {
  async [ClientActions.CREATE_CLIENT](context, payload) {
    context.commit(ClientMutations.CLIENT_BEGIN_WRITE_DATA);

    try {
      const { data: { client } } = await Api.client.create(payload.newClient);
      const normalizedClient = normalize(client, schemas.client);
      context.commit(ClientMutations.RECEIVE_CLIENTS, {
        ...normalizedClient.entities,
      });

      return normalizedClient.result; // Created bill id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ClientActions.FETCH_ORGANIZATION_CLIENTS](context, payload) {
    context.commit(ClientMutations.CLIENT_REQUEST_DATA);
    try {
      const { data: { clients } } = await Api.organization.client.findMany(payload.organizationId);
      const normalizedClients = normalize(clients, [schemas.client]);
      context.commit(ClientMutations.RECEIVE_CLIENTS, normalizedClients.entities);
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [ClientActions.FETCH_CLIENT_BY_ID](context, payload) {
    context.commit(ClientMutations.CLIENT_REQUEST_DATA);
    try {
      const { data: { client } } = await Api.client.findOne(payload.clientId);
      const normalizedClient = normalize(client, schemas.client);
      context.commit(ClientMutations.RECEIVE_CLIENTS, normalizedClient.entities);

      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [ClientActions.UPDATE_CLIENT](context, payload) {
    context.commit(ClientMutations.CLIENT_BEGIN_WRITE_DATA);

    const { clientId, updatedClient } = payload;

    try {
      const { data: { client } } = await Api.client.updateOne(clientId, updatedClient);
      const normalizedClient = normalize(client, schemas.client);
      context.commit(ClientMutations.RECEIVE_CLIENTS, {
        ...normalizedClient.entities,
      });

      return normalizedClient.result; // Created bill id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
