import cloneDeep from 'lodash.clonedeep';

export function isArrayEmpty(array) {
  return !Array.isArray(array) || !array.length;
}

export function isObjectEmpty(object) {
  if (!object) return true;
  return Object.entries(object).length === 0 && object.constructor === Object;
}

export const objectToUrlParams = paramsObject => {
  let queryString = '';
  Object.keys(paramsObject).forEach(key => {
    if (queryString !== '') {
      queryString += '&';
    } else {
      queryString += '?';
    }
    queryString += `${key}=${encodeURIComponent(paramsObject[key])}`;
  });

  return queryString;
};

export const removeObjectKeys = (obj, keysToRemove) => (obj
  && Object.keys(obj).length > 0
  ? Object.keys(obj)
    .filter(key => !keysToRemove.includes(key))
    .reduce((newObj, key) => ({
      ...newObj,
      // @ts-ignore
      [key]: obj[key],
    }), {})
  : null);

export const arrayToDictionaryWithId = arr => (
  arr.reduce((acc, curr) => ({ ...acc, [curr.id]: { ...curr } }), {})
);

export const deepDictionary = obj => {
  if (!obj) return obj;

  const newObj = cloneDeep(obj);

  Object.entries(newObj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      newObj[key] = cloneDeep(deepDictionary(arrayToDictionaryWithId(value)));
    } else if (value && typeof value === 'object') {
      newObj[key] = cloneDeep(deepDictionary(value));
    }
  });

  return newObj;
};

export const deepRemoveKeys = (obj, keysToRemove) => {
  if (!obj) return obj;

  const newObj = cloneDeep(obj);

  Object.entries(newObj).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      newObj[key] = cloneDeep(deepRemoveKeys(value, keysToRemove));
    }
  });

  return removeObjectKeys(newObj, keysToRemove);
};
