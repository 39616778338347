export const CREATE_USER = 'createUser';
export const CREATE_USER_SIGN_UP = 'createUserSignUp';
export const CREATE_USERS = 'createUsers';
export const FETCH_USER_BY_ID = 'fetchUserById';
export const FETCH_ALL_USERS = 'fetchAllUsers';
export const FETCH_USERS = 'fetchUsers';
export const FETCH_ORGANIZATION_USERS = 'fetchOrganizationUsers';
export const UPDATE_USER = 'updateUser';
export const ACCEPT_INVITATION = 'acceptInvitation';
export const RESEND_USER_INVITATION = 'resendUserInvitation';
export const UPDATE_USER_ROLE = 'updateUserRole';
