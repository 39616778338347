<template>
  <BModal
    :active="isOpen"
    trap-focus
    aria-modal
    hasModalCard
    :can-cancel="canCancel"
    @close="handleCloseModal({ hasUserUpgraded: false })"
    scroll="keep"
  >
    <div class="modal-card" style="max-width: 500px;">
      <header class="modal-card-head">
          <p class="card-header-title is-inline is-size-4 has-text-centered u-p-a-0">
            {{ $t('upgradeSubscriptionModal.title') }}
          </p>
      </header>
      <div class="modal-card-body">
        <div class="has-text-centered">
          <p> {{ $t('upgradeSubscriptionModal.ownerText') }}</p>
          <img src="../assets/images/product-illustrations/upgrade-plan.svg" style="height: 150px;" />
        </div>
        <div v-if="isLoggedInUserAtLeastOwner" class="u-p-t-small">
          <SubscriptionPlanCard planName="pro" />
          <p class="label u-m-t">{{ $t('common.formFields.creditCard.label') }}</p>
          <StripeElements
            :stripeKey="stripeKey"
            :elementsOptions="{ locale: $i18n.locale }"
            #default="{ elements }"
            ref="elms"
          >
            <StripeElement
              type="card"
              class="u-m-b"
              :elements="elements"
              @change="handleCardFieldChange"
              ref="stripeCardComponent"
            />
          </StripeElements>
          <BMessage
            class="u-m-t"
            v-if="paymentError"
            role="alert"
            type="is-danger"
          >
            {{ paymentError }}
          </BMessage>
        </div>
        <div v-else class="u-p-t-small has-text-centered">
          <p class="u-m-b-small is-size-5 has-text-weight-bold">
            {{ $t('upgradeSubscriptionModal.nonOwnerText') }}
          </p>
        </div>
      </div>
      <footer class="modal-card-foot">
        <BButton
          type="is-primary"
          class="pay-with-stripe"
          size="is-large"
          @click="handleCompletePayment"
          @keyup.enter="handleCompletePayment"
          :disabled='!isCardFieldComplete'
          :loading="isPaymentInProgress"
          expanded
        >
          {{ $t('upgradeSubscriptionModal.cta') }}
        </BButton>
      </footer>
    </div>
  </BModal>
</template>

<script>
import { mapGetters } from 'vuex';
// import { Card } from '@juanfabrega/vue-stripe-elements-plus';
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus';
// import * as SubscriptionTypes from '@/constants/Subscription.types';
import * as NotificationTypes from '@/constants/NotificationTypes';
// import * as ApiService from '@/services/Api.service';
import * as NotificationService from '@/services/Notification.service';
import * as SubscriptionActions from '@/store/actions/Subscription.actions';
import * as Api from '@/services/Api.service';
import SubscriptionPlanCard from '@/components/SubscriptionPlanCard.vue';

export default {
  name: 'UpgradeSubscriptionModal',

  components: {
    StripeElements,
    StripeElement,
    SubscriptionPlanCard,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    // workspaceUserCount: {
    //   type: Number,
    //   required: true,
    // },
    canCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      isCardFieldComplete: false,
      paymentError: null,
      isPaymentInProgress: false,
    };
  },

  computed: {
    ...mapGetters([
      'activeOrganization',
      'isLoggedInUserAtLeastOwner',
      'getOrganizationSubscription',
      'getSubscriptionTypeByName',
    ]),
    stripeKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
  },

  methods: {
    handleCloseModal({ hasUserUpgraded }) {
      this.$emit('close', { hasUserUpgraded });
    },
    async handleCompletePayment() {
      this.isPaymentInProgress = true;
      this.paymentError = null;

      try {
        const { data: { setupIntent } } = await Api.stripe.setupIntent.create({
          organizationId: this.activeOrganization.id,
        });

        const cardElement = this.$refs.stripeCardComponent.stripeElement; // eslint-disable-line
        const stripeInstance = this.$refs.elms.instance;

        await stripeInstance.confirmCardSetup(setupIntent.client_secret, { payment_method: { card: cardElement } });
        console.log(this.getOrganizationSubscription);
        console.log(this.getSubscriptionTypeByName('pro'));
        await this.$store.dispatch(SubscriptionActions.UPDATE_SUBSCRIPTION_BY_ID, {
          subscriptionId: this.getOrganizationSubscription.id,
          updatedSubscription: { subscriptionTypeId: this.getSubscriptionTypeByName('pro').id },
        });

        NotificationService.showNotification(NotificationTypes.UPGRADE_SUBSCRIPTION.SUCCESS);
        this.$router.push('/projects/create');
      } catch (err) {
        this.paymentError = err;
      }

      this.isPaymentInProgress = false;
    },
    handleCardFieldChange(event) {
      this.isCardFieldComplete = event.complete;
    },
  },
};
</script>

<style lang="scss">
.StripeElement {
  background: $white;
  border: 1px solid $grey-lighter !important;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: none;
  font-size: $body-size !important;
  height: 2.5em;
  margin: auto;
  padding-bottom: calc(0.35em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.65em - 1px);
  font-family: $family-sans-serif;
}

.product-card {
  border: 1px solid $primary;
  box-shadow: $tight-shadow;
}
</style>
