import { baseForm, baseField } from '@/forms/base.form';

const createCostCodeForm = {
  ...baseForm,
  fields: {
    code: {
      ...baseField,
      label: 'createCostCodeForm.code.label',
      name: 'createCostCodeForm.code.name',
      value: '',
      required: true,
    },
    name: {
      ...baseField,
      label: 'createCostCodeForm.name.label',
      name: 'createCostCodeForm.name.name',
      value: null,
      required: true,
    },
    // costCodeCategory: {
    //   ...baseField,
    //   label: 'createCostCodeForm.costCodeCategory.label',
    //   name: 'createCostCodeForm.costCodeCategory.name',
    //   value: '',
    //   required: false,
    //   autocomplete: true,
    //   placeholder: 'createCostCodeForm.costCodeCategory.placeholder',
    // },
  },
};

export default createCostCodeForm;
