<template>
  <div class="app-page__container">
    <div>
      <form
        v-if="this.$route.query.invitationId && !loggedInUser.id"
        class="sign-up-form"
        @submit.prevent="handleAcceptInvitation"
      >
        <h4 class="title is-4 has-text-centered">
          {{ $t('acceptInvitationView.welcome') }}
        </h4>
        <div class="title is-2 has-text-centered">
          <span role="img" aria-label="hello">👋</span>
          <span class="is-size-6 is-block has-text-weight-normal">
            {{ this.$route.query.email }}
          </span>
        </div>
        <p class="u-p-b has-text-centered">
          {{ $t('acceptInvitationView.joinTeam') }}
          <span class="has-text-weight-bold">
            {{ this.$route.query.organizationName }}
          </span>
        </p>
        <BField v-if="acceptInvitationError">
          <BMessage type="is-danger">
            {{ acceptInvitationError ? acceptInvitationError.message : '' }}
          </BMessage>
        </BField>
        <BField
          v-for="formField in Object.values(acceptInvitationForm.fields)"
          :key="formField.name"
          :class="[{ 'required': formField.required }]"
          :label="$t(formField.label)"
          :type="acceptInvitationForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
          :message="acceptInvitationForm.formErrors[$t(formField.name)] || ''"
        >
          <BInput
            v-model="formField.value"
            :ref="formField.name"
            :disabled="acceptInvitationForm.isFormSubmissionInProgress || formField.disabled"
            @input="handleTouchForm"
            :type="formField.type"
            :password-reveal="formField.type === 'password' || false"
          />
        </BField>
        <BButton
          type="is-primary"
          native-type="submit"
          size="is-medium"
          class="is-fullwidth"
          :loading="acceptInvitationForm.isFormSubmissionInProgress"
          :disabled="acceptInvitationForm.isFormSubmissionInProgress"
        >
          {{ $t('common.ctas.signUp') }}
        </BButton>
      </form>
      <div v-else-if="loggedInUser.id">
        User already logged in.
      </div>
    </div>
    <BLoading is-full-page :active.sync="isLoading" :can-cancel="false"></BLoading>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import * as AuthActions from '@/store/actions/Auth.actions';
import * as UserActions from '@/store/actions/User.actions';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as ValidationService from '@/services/Validation.service';
import acceptInvitationFrom from '@/forms/acceptInvitation.form';
import { fetchOrganizationRequiredData } from '@/helpers/organizationHelpers';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import { shapeUserDisplayName } from '@/helpers/userHelpers';

export default {
  name: 'AcceptInvitationView',

  data() {
    return {
      acceptInvitationForm: cloneDeep(acceptInvitationFrom),
      acceptInvitationError: null,
      isInvitationLoading: false,
    };
  },

  computed: {
    ...mapState({
      showLoading: state => state.auth.showLoading,
      authUser: state => state.auth.authUser,
    }),
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
    ]),
    isLoading() {
      return this.showLoading || this.isInvitationLoading;
    },
    isFormSubmissionInProgress() {
      return this.isEmailSignUpInProgress;
    },
  },

  methods: {
    async handleAcceptInvitation() {
      this.acceptInvitationError = null;
      this.acceptInvitationForm.formErrors = ValidationService.runFormValidation({ ...this.acceptInvitationForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.acceptInvitationForm.formErrors)) return;

      this.acceptInvitationForm.isFormSubmissionInProgress = true;
      try {
        await this.$store.dispatch(
          AuthActions.CREATE_AUTH_USER_WITH_EMAIL_AND_PASSWORD,
          { email: this.$route.query.email, password: this.acceptInvitationForm.fields.password.value },
        );

        const newUser = await this.$store.dispatch(
          UserActions.ACCEPT_INVITATION,
          { invitationId: this.$route.query.invitationId },
        );

        this.$store.commit(UserMutations.SET_LOGGED_IN_USER, newUser.result);
        this.$store.commit(OrganizationMutations.SET_ACTIVE_ORGANIZATION, this.loggedInUser.organizationId);

        await fetchOrganizationRequiredData(this.activeOrganization.id);

        this.$FullStory.identify(this.loggedInUser.id, {
          displayName: shapeUserDisplayName(this.loggedInUser),
          email: this.loggedInUser.email,
        });

        this.$router.push('/');
        this.acceptInvitationForm.isFormSubmissionInProgress = false;
      } catch (err) {
        this.acceptInvitationError = err;
        this.acceptInvitationForm.isFormSubmissionInProgress = false;
      }
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-up-form {
  margin: 0 auto;
  @media screen and (min-width: $tablet) {
    max-width: 500px;
  }
}
</style>
