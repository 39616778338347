import { titleCase } from '@/helpers/stringHelpers';

/**
 * @function shapeUserDisplayName Takes in a user object and returns a displayName string.
 * @param {Object} user The user object (ideally including first and last name)
 * @returns {String} Display name for the user.
 */
export const shapeUserDisplayName = (user) => { // eslint-disable-line
  let displayName = '';

  if (!user) {
    displayName = '';
  } else if (user.firstName && user.lastName) {
    displayName = `${user.firstName} ${user.lastName}`;
    displayName = titleCase(displayName.toLowerCase());
  } else if (!user.firstName || !user.lastName) {
    displayName = user.email;
  }

  return displayName;
};
