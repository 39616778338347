<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
        {{ $t('common.notifications.archivedProject') }}
      </BMessage>
      <section class="app-page__header u-m-b-0">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.costs') }}</h4>
          <p class="subtitle u-m-b-xsmall">{{ activeProject.name }}</p>
          <router-link v-if="clients[activeProject.clientId]" :to="`/clients/${activeProject.clientId}/edit`">
            <p class="is-size-6 is-italic has-text-primary">
              <BIcon icon="briefcase-account" size="is-small" />
              &nbsp;
              {{ clients[activeProject.clientId].name }}
            </p>
          </router-link>
        </div>
        <div class="app-page__header__action">
          <div class="buttons is-small is-align-items-flex-start">
            <BButton
              type="is-primary"
              iconLeft="plus-thick"
              @click.prevent="handleOpenCreateCostModal"
              @keyup.enter.prevent="handleOpenCreateCostModal"
            >
              {{ $t('common.ctas.createCost') }}
            </BButton>
          </div>
        </div>
      </section>

      <section class="app-page__section">
        <CreateCostModal
          v-if="isLoggedInUserAtLeastProjectManager && !isProjectArchived(activeProject.id)"
          class="u-m-b"
          :projectId="activeProjectId"
          :isOpen="isCreateCostModalOpen"
          @close="handleCloseCreateCostModal"
          @submitSuccess="handleCreateCostSuccess"
        />
        <ProjectCostsTable :projectId="this.activeProjectId" />
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as Types from '@/constants/Types';
import * as BillStatusConstants from '@/constants/BillStatus.constants';
import * as ProjectActions from '@/store/actions/Project.actions';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import CreateCostModal from '@/components/CreateCostModal.vue';
import ProjectCostsTable from '@/components/ProjectCostsTable.vue';

export default {
  name: 'ProjectCostsView',

  components: {
    CreateCostModal,
    ProjectCostsTable,
  },

  data() {
    return {
      isPageLoading: true,
      isCreateCostModalOpen: false,
      selectedCostTypes: [],
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      projects: state => state.project.projects,
      costTypes: state => state.costType.costTypes,
      clients: state => state.client.clients,
    }),
    ...mapGetters([
      'activeOrganization',
      'getTotalBudgetValue',
      'getProjectTotalActivityBudget',
      'getProjectTotalAdvanceAmount',
      'getProjectTotalDownPaymentAmount',
      'getProjectTotalRetainageAmount',
      'getProjectTotalDeductionAmount',
      'getProjectTotalClosedAmount',
      'getProjectTotalClosedTaxAmount',
      'getProjectTotalOpenTaxAmount',
      'isLoggedInUserAtLeastProjectManager',
      'isLoggedInUserAtLeastProjectAssistant',
      'getOpenBillItemsByProjectId',
      'getClosedBillItemsByProjectId',
      'isProjectArchived',
    ]),
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    budgetedVsContracted() {
      return {
        title: `${this.$t('common.terms.budgeted')} vs. ${this.$t('common.terms.contracted')}`,
        budgeted: this.getTotalBudgetValue(this.activeProject.activeBudgetId) ?? 0,
        contracted: this.getProjectTotalActivityBudget(this.activeProjectId),
        budgetedLabel: this.$t('common.terms.budgeted'),
        contractedLabel: this.$t('common.terms.contracted'),
        percentOfBudgetedContracted: (
          ((this.getProjectTotalActivityBudget(this.activeProjectId) - this.getTotalBudgetValue(this.activeProject.activeBudgetId))
          / this.getTotalBudgetValue(this.activeProject.activeBudgetId)) + 1
        ),
        percentLabel: 'Percent of Budget Contracted',
        // tooltipInfo: this.$t('costDetailsView.summaryInfo.contracted'),
      };
    },
    advancePercent() {
      return this.getProjectTotalAdvanceAmount(this.activeProjectId) / this.getProjectTotalActivityBudget(this.activeProjectId);
    },
    projectTotals() {
      return [
        {
          title: this.$t('common.terms.budgeted'),
          total: this.getTotalBudgetValue(this.activeProject.activeBudgetId) ?? 0,
          tooltipInfo: this.$t('costDetailsView.summaryInfo.budgeted'),
          hasTooltip: false,
        },
        {
          title: this.$t('common.terms.contracted'),
          total: this.getProjectTotalActivityBudget(this.activeProjectId),
          // titleFigure: this.getCostTotalAdvanceAmount(this.activeCostId) / this.getCostTotalActivityBudget(this.activeCostId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.contracted'),
          hasTooltip: false,
        },
        {
          title: this.$t('common.terms.inOpenBills'),
          // total: this.getProjectTotalAdvanceAmount(this.activeProjectId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.inOpenBills'),
          advance: this.getProjectTotalAdvanceAmount(this.activeProjectId, { excludedBillStatusNames: [Types.billStatusTypes.closed] }),
          downPayment: this.getProjectTotalDownPaymentAmount(this.activeProjectId, { excludedBillStatusNames: [Types.billStatusTypes.closed] }),
          retainage: Math.abs(this.getProjectTotalRetainageAmount(this.activeProjectId, { excludedBillStatusNames: [Types.billStatusTypes.closed] })),
          deduction: Math.abs(this.getProjectTotalDeductionAmount(this.activeProjectId, { excludedBillStatusNames: [Types.billStatusTypes.closed] })),
          total: (
            this.getOpenBillItemsByProjectId(this.activeProjectId).reduce((acc, curr) => (acc + curr.amount), 0)
            + this.getProjectTotalOpenTaxAmount(this.activeProjectId)
          ),
          tax: this.getProjectTotalOpenTaxAmount(this.activeProjectId),
          hasTooltip: true,
        },
        {
          title: this.$t('common.terms.totalClosed'),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.totalClosed'),
          advance: this.getProjectTotalAdvanceAmount(this.activeProjectId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] }),
          downPayment: this.getProjectTotalDownPaymentAmount(this.activeProjectId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] }),
          retainage: Math.abs(this.getProjectTotalRetainageAmount(this.activeProjectId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] })),
          deduction: Math.abs(this.getProjectTotalDeductionAmount(this.activeProjectId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] })),
          tax: this.getProjectTotalClosedTaxAmount(this.activeProjectId),
          total: (
            this.getProjectTotalClosedAmount(this.activeProjectId)
            + this.getProjectTotalClosedTaxAmount(this.activeProjectId)
          ),
          hasTooltip: true,
        },
      ];
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    displayCurrency,
    addSuffix,
    convertFractionToPercent,
    handleCloseCreateCostModal() {
      this.isCreateCostModalOpen = false;
    },
    handleOpenCreateCostModal() {
      this.isCreateCostModalOpen = true;
    },
    handleCreateCostSuccess() {
      this.isCreateCostModalOpen = false;
    },
    async fetchInitialData() {
      this.isPageLoading = true;
      const projectId = this.activeProjectId;
      await Promise.all([
        this.$store.dispatch(ProjectActions.FETCH_PROJECT_BY_ID, { projectId }),
      ]);

      this.isPageLoading = false;
    },
  },
};
</script>

<style lang="scss">
.project-details-view__total-card__breakdown-tooltip {
  > .tooltip-content {
    padding: 0;
  }
}
</style>
