import { normalize } from 'normalizr';
import * as TaxActions from '@/store/actions/Tax.actions';
import * as TaxMutations from '@/store/mutations/Tax.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as CostMutations from '@/store/mutations/Cost.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  taxes: {},
});

const receiveTaxes = (state, payload) => {
  state.taxes = {
    ...state.taxes,
    ...payload.taxes,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  getTaxByBillId(state, getters, rootState) {
    return billId => {
      const bill = rootState.bill.bills[billId];
      const { taxId } = bill;

      return taxId ? state.taxes[taxId] : null;
    };
  },
};

const mutations = {
  [TaxMutations.TAX_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [TaxMutations.TAX_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [TaxMutations.TAX_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [TaxMutations.RECEIVE_TAXES]: receiveTaxes,
  [BillMutations.RECEIVE_BILLS]: receiveTaxes,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveTaxes,
  [CostMutations.RECEIVE_COSTS]: receiveTaxes,
};

const actions = {
  [TaxActions.FETCH_ORGANIZATION_TAXES]: async (context, payload) => {
    context.commit(TaxMutations.TAX_REQUEST_DATA);
    try {
      const {
        data: {
          taxes,
        },
      } = await Api.organization.tax.findMany(payload.organizationId);

      context.commit(TaxMutations.RECEIVE_TAXES, {
        ...normalize(taxes, [schemas.tax]).entities,
      });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data);
    }
  },
  [TaxActions.CREATE_TAX]: async (context, payload) => {
    context.commit(TaxMutations.TAX_BEGIN_WRITE_DATA);

    try {
      const { data: { tax } } = await Api.tax.create(payload);
      const normalizedTax = normalize(tax, schemas.tax);
      context.commit(TaxMutations.RECEIVE_TAXES, {
        ...normalizedTax.entities,
      });

      return normalizedTax.result; // Created tax id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
