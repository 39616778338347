<template>
  <BTable
    :data="tableData"
    :loading="isLoading"
    :total="total"
    paginated
    backend-pagination
    :perPage="pageSize"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    @page-change="page => { $emit('pageChange', page); }"
    hoverable
    :mobile-cards="false"
    ref="costCodesTable"
    class="card u-p-b"
    backend-sorting
    :defaultSortDirection="sortDirection"
    :defaultSort="[sortBy, sortDirection]"
    @sort="(field, order) => { $emit('sortTable', field, order); }"
    narrowed
    scrollable
  >
    <BModal
      :active="!!costCodeIdEditing"
      @close="handleCloseEditModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="costCodeIdEditing" class="modal-card-title">{{ $t('common.ctas.edit')}} {{ costCodes[costCodeIdEditing].code }}</p>
          <button
            type="button"
            class="delete"
            @click="handleCloseEditModal"
          />
        </header>
        <div class="has-background-white u-p-a">
          <CreateOrEditCostCodeCard
            :costCodeId="costCodeIdEditing"
            isDark
            isInset
            inModal
            areFieldsStacked
            @submitSuccess="handleCostCodeUpdateSuccess"
          />
        </div>
      </div>
    </BModal>
    <BModal
      :active="!!costCodeIdConfirmingDelete"
      @close="handleCloseDeleteModal"
      hasModalCard
    >
      <div v-if="!isFetchingCostCodeDetails" class="modal-card">
        <header class="modal-card-head">
          <p v-if="costCodeIdConfirmingDelete" class="modal-card-title">
            {{ $t('common.ctas.delete') }}
            {{ $t('common.terms.costCode') }}
          </p>
          <button
            type="button"
            class="delete"
            @click="handleCloseDeleteModal"
          />
        </header>
        <section v-if="costCodes[costCodeIdConfirmingDelete]" class="modal-card-body has-background-white u-p-a has-text-centered">
          <div v-if="canDeleteCostCode">
            <p>
              <BIcon icon="alert" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('costCodesTable.deleteCostCodeModal.warning') }}</h5>
            <p>
              <CostCodeTag v-if="costCodes[costCodeIdConfirmingDelete]" :costCodeId="costCodeIdConfirmingDelete" size="is-large" />
            </p>
          </div>
          <div v-else>
            <p>
              <BIcon icon="alert-octagon" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('costCodesTable.deleteCostCodeModal.notAllowed') }}</h5>
            <ul>
              <li
                v-for="activityId in costCodes[costCodeIdConfirmingDelete].activities"
                :key="activityId.key"
                class="has-text-weight-bold u-p-b-small"
              >
                {{ activities[activityId].name }}
              </li>
            </ul>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleCloseDeleteModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-danger"
              @click="handleConfirmDeleteCostCode(costCodeIdConfirmingDelete)"
              :loading="costCodesDeleting.includes(costCodeIdConfirmingDelete)"
              :disabled="!canDeleteCostCode"
            >
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.costCode') }}
            </BButton>
          </div>
        </footer>
      </div>
    </BModal>
    <BTableColumn
      field="code"
      :label="$t('costCodesTable.columns.code')"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      {{ props.row.code }}
    </BTableColumn>
    <BTableColumn
      field="name"
      :label="$t('costCodesTable.columns.name')"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      {{ props.row.name }}
    </BTableColumn>
    <!-- <BTableColumn
      field="createdAt"
      :label="$t('costCodesTable.columns.createdAt')"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale, {}) }}
    </BTableColumn> -->
    <BTableColumn
      v-if="isLoggedInUserAtLeastProjectManager"
      cell-class="vertical-align-middle"
      width="140"
      v-slot="props"
    >
      <div class="buttons" style="justify-content: center;">
        <BButton
          type="is-primary is-light"
          @click="() => handleClickEditCostCode(props.row.id)"
        >
          <BIcon icon="pencil" size="mdi-18px" />
        </BButton>
        <BButton
          type="is-primary is-light"
          @click="() => handleClickDeleteCostCode(props.row.id)"
          :loading="isFetchingCostCodeDetails && costCodeIdConfirmingDelete === props.row.id"
        >
          <BIcon icon="delete" size="mdi-18px" />
        </BButton>
      </div>
    </BTableColumn>
  </BTable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as CostCodeActions from '@/store/actions/CostCode.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import CreateOrEditCostCodeCard from '@/components/CreateOrEditCostCodeCard.vue';
import CostCodeTag from '@/components/CostCodeTag.vue';

export default {
  name: 'CostCodesTable',

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 20,
    },
    sortBy: {
      type: String,
      required: false,
      default: 'createdAt',
    },
    sortDirection: {
      type: String,
      required: false,
      default: 'desc',
    },
    tableData: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },

  emits: [
    'pageChange',
    'sortTable',
  ],

  components: {
    CreateOrEditCostCodeCard,
    CostCodeTag,
  },

  data() {
    return {
      costCodesDeleting: [],
      costCodeIdEditing: null,
      costCodeIdConfirmingDelete: null,
      isFetchingCostCodeDetails: false,
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      costCodes: state => state.costCode.costCodes,
    }),
    ...mapGetters([
      'isLoggedInUserAtLeastProjectManager',
    ]),
    canDeleteCostCode() {
      return this.costCodeIdConfirmingDelete && this.costCodes[this.costCodeIdConfirmingDelete]?.activities.length === 0;
    },
  },

  methods: {
    handleClickEditCostCode(costCodeId) {
      this.costCodeIdEditing = costCodeId;
    },
    handleCloseEditModal() {
      this.costCodeIdEditing = null;
    },
    handleCloseDeleteModal() {
      this.costCodeIdConfirmingDelete = null;
    },
    async handleClickDeleteCostCode(costCodeId) {
      this.isFetchingCostCodeDetails = true;
      try {
        await this.$store.dispatch(CostCodeActions.FETCH_COST_CODE_BY_ID, { costCodeId });
        this.costCodeIdConfirmingDelete = costCodeId;
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isFetchingCostCodeDetails = false;
    },
    async handleConfirmDeleteCostCode(costCodeId) {
      this.costCodesDeleting = [...this.costCodesDeleting, costCodeId];
      try {
        await this.$store.dispatch(CostCodeActions.DELETE_COST_CODE_BY_ID, {
          costCodeId,
        });
        NotificationService.showNotification(NotificationTypes.DELETE_COST_CODE.SUCCESS);
        this.handleCloseDeleteModal();
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_COST_CODE.ERROR);
      }
      this.costCodesDeleting = this.costCodesDeleting.filter(a => a !== costCodeId);
    },
    handleCostCodeUpdateSuccess() {
      this.handleCloseEditModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_COST_CODE.SUCCESS);
    },
  },
};
</script>
