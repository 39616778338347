<template>
  <BModal
      :active="!!budgetIdConfirmingDelete"
      @close="handleCloseDeleteModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="budgetIdConfirmingDelete" class="modal-card-title">
            {{ $t('common.ctas.delete') }}
            {{ $t('common.terms.budget') }}
          </p>
          <button
            type="button"
            class="delete"
            @click="handleCloseDeleteModal"
          />
        </header>
        <section class="modal-card-body has-background-white u-p-a has-text-centered">
          <div>
            <p>
              <BIcon icon="alert" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('modals.deleteBudgetModal.warning') }}</h5>
            <h6 class="title is-6">{{ $t('modals.deleteBudgetModal.warningUndo') }}</h6>
            <div class="is-flex is-justify-content-center">
              <div v-if="budgetIdConfirmingDelete && budgets[budgetIdConfirmingDelete]">
                <p class="has-text-weight-bold is-size-5">{{ budgets[budgetIdConfirmingDelete].name }}</p>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleCloseDeleteModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-danger"
              @click="handleConfirmDeleteBudget(budgetIdConfirmingDelete)"
              :loading="budgetsDeleting.includes(budgetIdConfirmingDelete)"
            >
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.budget') }}
            </BButton>
          </div>
        </footer>
      </div>
    </BModal>
</template>

<script>
import { mapState } from 'vuex';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as BudgetActions from '@/store/actions/Budget.actions';

export default {
  name: 'BudgetConfirmDeleteModal',

  props: {
    projectId: {
      type: String,
      required: true,
    },
    budgetId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      budgetsDeleting: [],
      budgetIdConfirmingDelete: null,
    };
  },

  watch: {
    budgetId(newVal) {
      this.budgetIdConfirmingDelete = newVal;
    },
  },

  computed: {
    ...mapState({
      budgets: state => state.budget.budgets,
    }),
  },

  methods: {
    async handleConfirmDeleteBudget(budgetId) {
      this.budgetsDeleting = [...this.budgetsDeleting, budgetId];
      try {
        await this.$store.dispatch(BudgetActions.DELETE_BUDGET_BY_ID, {
          budgetId,
        });
        this.handleCloseDeleteModal();
        NotificationService.showNotification(NotificationTypes.DELETE_BUDGET.SUCCESS);
        if (this.$router.history.current.name.toLowerCase() === 'projectbudgets') {
          return;
        }
        await this.$router.push(`/projects/${this.projectId}/budgets`);
        this.$router.go();
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_BUDGET.ERROR);
      }
      this.budgetsDeleting = this.budgetsDeleting.filter(a => a !== budgetId);
    },
    handleCloseDeleteModal() {
      this.$emit('onBudgetDeleteModalClosed');
    },
  },
};
</script>
