<template>
  <div class="app-page__container">
    <div v-if="!isLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.organizationSettings')}}</h4>
        </div>
      </section>

      <div class="app-page__section">
        <div class="app-page__header">
          <div class="app-page__header__title">
            <div style="display: flex; align-items: baseline">
              <h5 class="title is-5 has-text-grey u-m-b-0">{{ $t('common.terms.subscriptionPlan') }}</h5>
            </div>
          </div>
          <div v-if="isLoggedInUserAtLeastOwner" class="app-page__header__action">
            <BButton
              type="is-primary"
              outlined
              @click="handleClickManageSubscription"
              @keyup.enter="handleClickManageSubscription"
            >
              <BIcon icon="credit-card-outline" class="u-m-r-xsmall" customSize="mdi-18px" />
              {{ $t('organizationSettingsView.subscription.cta') }}
            </BButton>
          </div>
        </div>
      </div>
      <SubscriptionPlanCard :planName="subscriptionTypeName" />
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as SubscriptionActions from '@/store/actions/Subscription.actions';
import * as Api from '@/services/Api.service';
import SubscriptionPlanCard from '@/components/SubscriptionPlanCard.vue';

export default {
  name: 'OrganizationSettingsView',

  components: {
    SubscriptionPlanCard,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapState({
      subscriptionTypes: state => state.subscriptionType.subscriptionTypes,
    }),
    ...mapGetters([
      'activeOrganization',
      'getOrganizationSubscription',
      'isLoggedInUserAtLeastOwner',
    ]),
    subscriptionTypeName() {
      return this.subscriptionTypes[this.getOrganizationSubscription.subscriptionTypeId]?.name;
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    async fetchInitialData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(SubscriptionActions.FETCH_ORGANIZATION_SUBSCRIPTIONS, { organizationId: this.activeOrganization.id });
        this.isLoading = false;
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
      }
    },
    async handleClickManageSubscription() {
      const { data: { session } } = await Api.stripe.portalSession.create({ returnUrl: `https://${location.host}${this.$route.path}`}); // eslint-disable-line
      window.location.replace(session.url);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
