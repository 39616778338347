<template>
  <BDropdown
    v-if="isEditable"
    aria-role="list"
    appendToBody
    position="is-top-left"
  >
    <BTag
      :class="[tagClass, 'has-text-weight-bold', sizeClass[size], 'cursor-pointer']"
      slot="trigger"
      slot-scope="{ active }"
    >
      <span>
        <span>{{ $t(`common.billStatuses.${billStatuses[billStatusId].name}`) }}</span>
        &nbsp;
        <BIcon :icon="active ? 'chevron-up' : 'chevron-down'" size="is-small" class="u-m-r-0" />
      </span>
    </BTag>
    <BDropdown-item
      v-for="option in dropdownOptions"
      :key="option.key"
      aria-role="listitem"
      :disabled="option.isDisabled"
      @click="handleUpdateBillStatus(option)"
      @keyup.enter="handleUpdateBillStatus(option)"
    >
      {{ option.label }}
    </BDropdown-item>
  </BDropdown>
  <BTag v-else :class="[tagClass, 'has-text-weight-bold', sizeClass[size]]">
    {{ $t(`common.billStatuses.${billStatuses[billStatusId].name}`) }}
  </BTag>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BillStatusTag',

  props: {
    billStatusId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      validator(propValue) {
        return ['small', 'medium', 'large'].indexOf(propValue) !== -1;
      },
      default: 'medium',
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapState({
      billStatuses: state => state.billStatus.billStatuses,
    }),
    tagClass() {
      const billStatus = this.billStatuses[this.billStatusId];
      return `bill-status__${billStatus.name}`;
    },
    sizeClass() {
      return {
        small: 'is-size-7',
        medium: 'is-size-6',
        large: 'is-size-5',
      };
    },
    dropdownOptions() {
      const options = Object.values(this.billStatuses).map(billStatus => ({
        ...billStatus,
        label: this.$t(`common.billStatuses.${billStatus.name}`),
        isDisabled: false,
      }));

      return options.map(option => {
        if (option.id === this.billStatusId) {
          option.isDisabled = true; // eslint-disable-line no-param-reassign
        }

        return option;
      });
    },
  },
  methods: {
    handleUpdateBillStatus(billStatus) {
      this.$emit('update', { billStatus });
    },
  },
};
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
