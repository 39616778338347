<template>
  <BModal :active.sync="isOpen" hasModalCard :width="600" @close="$emit('close')">
    <form @submit.prevent="handleSubmitCreateTax">
      <div class="modal-card">
        <div class="modal-card-head">
          <p class="modal-card-title">{{ $t('common.ctas.createTax') }}</p>
        </div>
        <div class="modal-card-body">
          <BField v-if="formSubmissionError">
            <BMessage type="is-danger">
              {{  formSubmissionError.message }}
            </BMessage>
          </BField>
          <BField grouped>
            <BField
              v-for="formField in Object.values(createTaxForm.fields)"
              :key="formField.key"
              :class="['column', { 'required': formField.required }]"
              :label="$t(formField.label)"
              :type="createTaxForm.formErrors[$t(formField.name)] ? 'is-danger' : ''"
              :message="createTaxForm.formErrors[$t(formField.name)] || ''"
            >
              <BInput
                v-model="formField.value"
                :disabled="createTaxForm.isFormSubmissionInProgress"
                :placeholder="$t(formField.placeholder)"
                @input="handleTouchForm"
                expanded
                :custom-class="formField.class"
              />
              <div v-if="formField.addons && formField.addons.right" class="control">
                <span class="input__addon--right">{{ formField.addons.right }}</span>
              </div>
            </BField>
          </BField>
        </div>
        <footer class="modal-card-foot">
          <BButton nativeType="submit" type="is-primary" expanded :loading="createTaxForm.isFormSubmissionInProgress">
            {{ $t('common.ctas.createTax') }}
          </BButton>
        </footer>
      </div>
    </form>
  </BModal>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import createTaxForm from '@/forms/createTax.form';
import * as TaxActions from '@/store/actions/Tax.actions';
import * as ValidationService from '@/services/Validation.service';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'CreateTaxModal',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    initialTaxName: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      createTaxForm: cloneDeep(createTaxForm),
      formSubmissionError: null,
    };
  },

  watch: {
    isOpen(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.setInitialFormState();
      }
    },
  },

  methods: {
    setInitialFormState() {
      this.createTaxForm.fields.name.value = this.initialTaxName;
    },
    async handleSubmitCreateTax() {
      this.formSubmissionError = null;
      this.createTaxForm.formErrors = {};
      this.createTaxForm.formErrors = ValidationService.runFormValidation({ ...this.createTaxForm.fields }, { i18n: true });

      if (!isObjectEmpty(this.createTaxForm.formErrors)) return;

      this.createTaxForm.isFormSubmissionInProgress = true;
      const { fields } = this.createTaxForm;
      try {
        const createdTaxId = await this.$store.dispatch(
          TaxActions.CREATE_TAX,
          {
            name: fields.name.value,
            percent: Number(fields.percent.value) / 100,
          },
        );
        this.createTaxForm.isFormSubmissionInProgress = false;
        this.$emit('submitSuccess', createdTaxId);
      } catch (err) {
        console.log(err);
        this.formSubmissionError = err;
        this.createTaxForm.isFormSubmissionInProgress = false;
      }
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
