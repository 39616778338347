export const SET_AUTH_USER = 'AUTH/SET_AUTH_USER';
export const SET_LOG_IN_ERROR = 'AUTH/SET_LOG_IN_ERROR';
export const AUTH_REQUEST_DATA = 'AUTH/REQUEST_DATA';
export const SIGN_UP_FORM_REQUEST_DATA = 'AUTH/SIGN_UP_FORM_REQUEST_DATA';
export const LOG_IN_FORM_REQUEST_DATA = 'AUTH/LOG_IN_FORM_REQUEST_DATA';
export const SESSION_AUTH_REQUEST_DATA = 'AUTH/SESSION_AUTH_REQUEST_DATA';
export const LOG_OUT = 'AUTH/LOG_OUT';
export const SET_LOG_OUT_IN_PROGRESS = 'AUTH/SET_LOG_OUT_IN_PROGRESS';
export const GOOGLE_AUTH_REQUEST_DATA = 'AUTH/GOOGLE_AUTH_REQUEST_DATA';
export const SET_SIGN_UP_ERROR = 'AUTH/SET_SIGN_UP_ERROR';
