import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as CostMutations from '@/store/mutations/Cost.mutations';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';
import * as ChangeOrderMutations from '@/store/mutations/ChangeOrder.mutations';
import * as BillItemMutations from '@/store/mutations/BillItem.mutations';
import * as BillStatusConstants from '@/constants/BillStatus.constants';
import * as Types from '@/constants/Types';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  billItems: {},
});

const receiveBillItems = (state, payload) => {
  state.billItems = {
    ...state.billItems,
    ...payload.billItems,
  };
  state.isFetchingData = false;
};

function deleteBillItem(state, payload) {
  const updatedBillItems = { ...state.billItems };
  delete updatedBillItems[payload.billItemId];
  state.billItems = { ...updatedBillItems };
  state.isFetchingData = false;
  state.isWritingData = false;
}

const state = getInitialState();

const getters = {
  getBillItemByActivityAndBillId(state) {
    return (activityId, billId) => Object.values(state.billItems)
      .filter(billItem => billItem.billId === billId && billItem.activityId === activityId)[0];
  },
  getBillItemsByBillId(state) {
    return billId => Object.values(state.billItems).filter(billItem => billItem.billId === billId);
  },
  getBillItemsByBillIdAndTypeName(_state, getters, _rootState, rootGetters) {
    return (billId, billItemTypeName) => getters.getBillItemsByBillId(billId)
      .filter(billItem => rootGetters.getBillItemTypeByName(billItemTypeName).id === billItem.billItemTypeId);
  },
  getBillItemsByActivityId(state) {
    return activityId => Object.values(state.billItems).filter(billItem => activityId === billItem.activityId);
  },
  getBillItemsByCostId(state, getters, rootState) {
    return (costId, options = { excludedBillIds: [], excludedBillStatusNames: [] }) => Object.values(state.billItems).filter(billItem => (
      !options.excludedBillIds?.includes(billItem.billId)
      && !options.excludedBillStatusNames?.includes(rootState.billStatus.billStatuses[rootState.bill.bills[billItem.billId]?.statusId]?.name)
      && rootState.bill.bills[billItem.billId].costId === costId
    ));
  },
  getBillItemsByProjectId(state, getters, rootState, rootGetters) {
    return (projectId, options = { excludedBillIds: [], excludedBillStatusNames: [] }) => (
      rootGetters.getCostsByProjectId(projectId).map(cost => (
        getters.getBillItemsByCostId(cost.id, options)
      )).flat()
    );
  },
  getOpenBillItemsByCostId(state, getters) {
    return (costId, options = {}) => getters.getBillItemsByCostId(costId, { ...options, excludedBillStatusNames: [Types.billStatusTypes.closed] });
  },
  getClosedBillItemsByCostId(state, getters) {
    return (costId, options = {}) => getters.getBillItemsByCostId(costId, { ...options, excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] });
  },
  getOpenBillItemsByProjectId(state, getters) {
    return (costId, options = {}) => getters.getBillItemsByProjectId(costId, { ...options, excludedBillStatusNames: [Types.billStatusTypes.closed] });
  },
  getClosedBillItemsByProjectId(state, getters) {
    return (projectId, options = {}) => getters.getBillItemsByProjectId(projectId, { ...options, excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] });
  },
};

const mutations = {
  [BillMutations.RECEIVE_BILLS]: receiveBillItems,
  [BillItemMutations.RECEIVE_BILL_ITEMS]: receiveBillItems,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveBillItems,
  [CostMutations.RECEIVE_COSTS]: receiveBillItems,
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveBillItems,
  [ChangeOrderMutations.RECEIVE_CHANGE_ORDERS]: receiveBillItems,
  [BillItemMutations.DELETE_BILL_ITEM_BY_ID]: deleteBillItem,
};

const actions = {
  // Placeholder
};

export default {
  state,
  getters,
  actions,
  mutations,
};
