import { normalize } from 'normalizr';
import * as CostMutations from '@/store/mutations/Cost.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';
import * as ChangeOrderMutations from '@/store/mutations/ChangeOrder.mutations';
import * as BillActions from '@/store/actions/Bill.actions';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';
import * as Types from '@/constants/Types';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  bills: {},
});

const receiveBills = (state, payload) => {
  state.bills = {
    ...state.bills,
    ...payload.bills,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  getBillById(state) {
    return billId => state.bills[billId];
  },
  getBillsByCostId(state) {
    return costId => Object.values(state.bills)
      .filter(bill => bill.costId === costId);
  },
  getBillsByProjectId(state, getters, rootState, rootGetters) {
    return projectId => Object.values(state.bills)
      .filter(bill => {
        const costIds = rootGetters.getCostsByProjectId(projectId).map(cost => cost.id);
        return costIds.some(costId => costId === bill.costId);
      });
  },
  getBillTotalAdvance(state, getters, rootState, rootGetters) {
    return billId => Object.values(rootState.billItem.billItems)
      .filter(billItem => (
        billItem.billId === billId
        && billItem.billItemTypeId === rootGetters.getBillItemTypeByName(Types.billItemTypes.advance).id))
      .reduce((acc, curr) => acc + curr.amount, 0);
  },
  getBillTotalDownPayment(state, getters, rootState, rootGetters) {
    return billId => Object.values(rootState.billItem.billItems)
      .filter(billItem => (
        billItem.billId === billId
        && billItem.billItemTypeId === rootGetters.getBillItemTypeByName(Types.billItemTypes.downPayment).id))
      .reduce((acc, curr) => acc + curr.amount, 0);
  },
  getBillTotalRetainage(state, getters, rootState, rootGetters) {
    return billId => Object.values(rootState.billItem.billItems)
      .filter(billItem => (
        billItem.billId === billId
        && billItem.billItemTypeId === rootGetters.getBillItemTypeByName(Types.billItemTypes.retainage).id))
      .reduce((acc, curr) => acc + curr.amount, 0);
  },
  getBillTotalDeduction(state, getters, rootState, rootGetters) {
    return billId => Object.values(rootState.billItem.billItems)
      .filter(billItem => (
        billItem.billId === billId
        && billItem.billItemTypeId === rootGetters.getBillItemTypeByName(Types.billItemTypes.deduction).id))
      .reduce((acc, curr) => (acc + curr.amount), 0);
  },
  getBillsByStatusId(state) {
    return statusId => Object.values(state.bills)
      .filter(bill => bill.statusId === statusId);
  },
  getBillTotalBeforeTax(state, getters, rootState) {
    return billId => {
      const billItems = Object.values(rootState.billItem.billItems).filter(billItem => billItem.billId === billId);
      return billItems.reduce((acc, curr) => (acc + curr.amount), 0);
    };
  },
  getBillTotalAfterTax(state, getters, rootState, rootGetters) {
    return billId => {
      const { taxId } = state.bills[billId];
      const taxRate = taxId ? rootState.tax.taxes[taxId]?.percent ?? 0 : 0;
      const totalBeforeTax = getters.getBillTotalBeforeTax(billId);
      const totalBillTax = rootGetters.getBillItemsByBillId(billId)
        .filter(billItem => billItem.isTaxed)
        .reduce((acc, curr) => (acc + (curr.amount * taxRate)), 0);
      return totalBeforeTax + totalBillTax;
    };
  },
  getBillTotalTaxAmount(state, getters, rootState, rootGetters) {
    return billId => {
      const { taxId } = state.bills[billId];
      const taxRate = taxId ? rootState.tax.taxes[taxId].percent : 0;
      const totalBillTax = rootGetters.getBillItemsByBillId(billId)
        .filter(billItem => billItem.isTaxed)
        .reduce((acc, curr) => (acc + (curr.amount * taxRate)), 0);
      return totalBillTax;
    };
  },
  getBillByActivityId(state, getters, rootState, rootGetters) {
    return activityId => (
      rootState.bill.bills[rootGetters.getBillItemsByActivityId(activityId)[0]?.billId]
    );
  },
};

const mutations = {
  [BillMutations.BILL_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [BillMutations.BILL_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [BillMutations.BILL_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [BillMutations.RECEIVE_BILLS]: receiveBills,
  [CostMutations.RECEIVE_COSTS]: receiveBills,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveBills,
  [ChangeOrderMutations.RECEIVE_CHANGE_ORDERS]: receiveBills,
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveBills,
};

const actions = {
  [BillActions.CREATE_BILL]: async (context, payload) => {
    context.commit(BillMutations.BILL_BEGIN_WRITE_DATA);

    try {
      const { data: { bill } } = await Api.bill.create(payload.newBill);
      const normalizedBill = normalize(bill, schemas.bill);
      context.commit(BillMutations.RECEIVE_BILLS, {
        ...normalizedBill.entities,
      });

      return normalizedBill.result; // Created bill id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [BillActions.UPDATE_BILL]: async (context, payload) => {
    context.commit(BillMutations.BILL_BEGIN_WRITE_DATA);

    try {
      const { data: { bill } } = await Api.bill.updateOne(payload.billId, payload.updatedBill);
      const normalizedBill = normalize(bill, schemas.bill);
      context.commit(BillMutations.RECEIVE_BILLS, {
        ...normalizedBill.entities,
      });

      return;
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [BillActions.UPDATE_BILL_STATUS_BY_BILL_ID]: async (context, payload) => {
    context.commit(BillMutations.BILL_BEGIN_WRITE_DATA);

    try {
      const { data: { bill } } = await Api.bill.billStatus.updateOne(payload.billId, payload.updatedBill);
      const normalizedBill = normalize(bill, schemas.bill);
      context.commit(BillMutations.RECEIVE_BILLS, {
        ...normalizedBill.entities,
      });

      return;
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [BillActions.FETCH_BILL_BY_ID]: async (context, payload) => {
    context.commit(BillMutations.BILL_REQUEST_DATA);

    try {
      const { data: { bill } } = await Api.bill.findOne(payload.billId);
      const normalizedBill = normalize(bill, schemas.bill);
      context.commit(BillMutations.RECEIVE_BILLS, { ...normalizedBill.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [BillActions.FETCH_ORGANIZATION_BILLS]: async (context, payload) => {
    context.commit(BillMutations.BILL_REQUEST_DATA);

    try {
      const { data: { bills } } = await Api.organization.bill.findMany(payload.organizationId);

      const normalizedBills = normalize(bills, [schemas.bill]);
      context.commit(BillMutations.RECEIVE_BILLS, { ...normalizedBills.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [BillActions.FETCH_USER_BILLS]: async (context, payload) => {
    context.commit(BillMutations.BILL_REQUEST_DATA);

    try {
      const { data: { bills } } = await Api.user.bill.findMany(payload.userId);

      const normalizedBills = normalize(bills, [schemas.bill]);
      context.commit(BillMutations.RECEIVE_BILLS, { ...normalizedBills.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [BillActions.FETCH_BILLS_BY_COST_ID]: async (context, payload) => {
    context.commit(BillMutations.BILL_REQUEST_DATA);

    try {
      const { data: { bills } } = await Api.cost.bill.findMany(payload.costId);

      const normalizedBills = normalize(bills, [schemas.bill]);
      context.commit(BillMutations.RECEIVE_BILLS, { ...normalizedBills.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
