<template>
  <div :class="[
    'card',
    'u-m-b-small',
    'bill-summary-card__wrapper',
    { 'bill-summary-card__wrapper--archived': isProjectArchived((costs[bill.costId].projectId)) },
  ]">
    <div class="card-content">
      <BLoading
        :active.sync="isLoading"
        :is-full-page="false"
        :can-cancel="false"
        class="bill-summary-card__loading-spinner"
      />
      <div>
        <BTag
          v-if="isProjectArchived(costs[bill.costId].projectId)"
          type="is-warning is-light"
          class="u-m-b-xsmall"
        >
          <BIcon icon="lock" size="is-small" />
          <span>{{ $t('common.terms.archived') }}</span>
        </BTag>
      </div>
      <div v-if="!isCompact" class="u-m-b is-size-7">
        <p class="is-italic">
          <BIcon icon="table" size="is-small" />
          &nbsp;&nbsp;
          {{ projects[costs[bill.costId].projectId].name }}
        </p>
        <p class="is-italic">
          <BIcon icon="file-document-edit-outline" size="is-small" />
          &nbsp;&nbsp;
          {{ costs[bill.costId].name }}
        </p>
        <p v-if="vendors[costs[bill.costId].vendorId]" class="is-italic">
          <BIcon icon="account-hard-hat" size="is-small" />
          &nbsp;&nbsp;
          {{ vendors[costs[bill.costId].vendorId].name }}
        </p>
      </div>
      <div class="columns">
        <div class="column u-p-b-0">
          <p :class="[{ 'is-size-5': !isCompact, 'is-size-6': isCompact }]">
            {{ $t('common.terms.bill')  }} {{ bill.number }}
            <span v-if="bill.name">
              &nbsp;-&nbsp;
              {{ bill.name }}
            </span>
          </p>
        </div>
        <div v-if="isStatusVisible" class="column is-5 has-text-right">
          <BillStatusTag :billStatusId="bill.statusId" size="small" />
        </div>
      </div>
      <div class="columns">
        <div class="column u-p-t-0">
          <div class="has-text-right">
            <div class="u-m-y">
              <div class="columns is-size-7">
                <p class="column u-p-y-0 has-text-left">
                  {{ $t('common.terms.subtotal') }}
                </p>
                <p class="column u-p-y-0">
                  {{ displayCurrency(getBillTotalBeforeTax(bill.id), { prefix: '$', multiplier: 100 }) }}
                </p>
              </div>
              <div class="columns is-size-7">
                <p class="column u-p-y-0 has-text-left">
                  {{ $t('common.terms.tax') }}
                </p>
                <p class="column u-p-y-0">
                  + {{ displayCurrency(getBillTotalTaxAmount(bill.id), { prefix: '$', multiplier: 100 }) }}
                </p>
              </div>
            </div>
            <p class="has-text-weight-bold is-size-5">
              {{ displayCurrency(getBillTotalAfterTax(bill.id), { prefix: '$', multiplier: 100 }) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer columns">
      <div class="is-size-7 has-text-right column" style="width: 100%;">
        <span class="has-text-weight-bold is-block">{{ $t('common.terms.createdAt') }}</span>
        <span class="is-italic">{{ new Date(bill.createdAt).toLocaleDateString($i18n.locale) }}</span>
      </div>
      <div class="is-size-7 has-text-right column" style="width: 100%;">
        <span class="has-text-weight-bold is-block">{{ $t('billsView.billSummaryCard.requestedBy') }}:</span>
        <span class="is-italic" style="word-break: break-all !important;">
          <UserDisplayName :activeUserId="bill.createdByUserId" isInline size="small" />
        </span>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { displayCurrency } from '@/helpers/stringHelpers';
import UserDisplayName from '@/components/UserDisplayName.vue';
import BillStatusTag from '@/components/BillStatusTag.vue';

export default {
  name: 'BillSummaryCard',

  components: {
    UserDisplayName,
    BillStatusTag,
  },

  props: {
    billId: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCompact: {
      type: Boolean,
      required: false,
      default: false,
    },
    isStatusVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapState({
      vendors: state => state.vendor.vendors,
      bills: state => state.bill.bills,
      costs: state => state.cost.costs,
      projects: state => state.project.projects,
      users: state => state.user.users,
    }),
    ...mapGetters([
      'getBillTotalBeforeTax',
      'getBillTotalAfterTax',
      'getBillTotalTaxAmount',
      'isProjectArchived',
    ]),
    bill() {
      return this.bills[this.billId];
    },
  },

  methods: {
    displayCurrency,
  },
};
</script>

<style lang="scss">
.bill-summary-card__wrapper {
  cursor: pointer;
  box-shadow: $tight-shadow;
  border: 1px solid $grey-light;

  > * {
    padding: $global-whitespace-small;
  }

  &--archived {
    background-color: $grey-lightest;
    box-shadow: none;
  }
}

.bill-summary-card__loading-spinner {
  & .loading-icon {
    position: absolute;
    top: $global-whitespace-large;
    right: $global-whitespace-small;

    &:after {
      width: 1em;
      height: 1em;
    }
  }
}
</style>
