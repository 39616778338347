<template>
  <div>
    <CreateOrEditChangeOrderModal
      :isOpen="!!changeOrderIdEditing"
      @close="handleCloseEditChangeOrderModal"
      @submitSuccess="handleCloseEditChangeOrderModal"
      :activeChangeOrderId="changeOrderIdEditing"
    />
    <BModal
      :active="!!changeOrderIdConfirmingDelete"
      @close="handleCloseDeleteModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="changeOrderIdConfirmingDelete" class="modal-card-title">
            {{ $t('common.ctas.delete') }}
            {{ $t('common.terms.changeOrder') }}
          </p>
          <button
            type="button"
            class="delete"
            @click="handleCloseDeleteModal"
          />
        </header>
        <section class="modal-card-body has-background-white u-p-a has-text-centered">
          <div>
            <p>
              <BIcon icon="alert" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('projectChangeOrdersTable.deleteChangeOrderModal.warning') }}</h5>
            <div class="is-flex is-justify-content-center">
              {{ changeOrderDeletingName }}
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleCloseDeleteModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-danger"
              @click="handleConfirmDeleteChangeOrder(changeOrderIdConfirmingDelete)"
              :loading="changeOrdersDeleting.includes(changeOrderIdConfirmingDelete)"
            >
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.changeOrder') }}
            </BButton>
          </div>
        </footer>
      </div>
    </BModal>
    <BTable
      :data="changeOrdersTableData"
      hoverable
      @click="handleClickRow"
      @keyup.enter="handleClickRow"
      :class="{card: true, 'is-empty-table' : changeOrdersTableData.length == 0}"
      :mobile-cards="false"
      :row-class="handleRowClass"
      :default-sort-direction="'asc'"
      :default-sort="['name', 'asc']"
    >
      <template slot="empty">
        <div class="has-text-grey u-p-a has-text-centered">
          {{ $t('projectChangeOrdersTable.emptyState') }}
        </div>
      </template>
      <BTableColumn
        field="name"
        :label="$t('common.terms.name')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        :subheading="$t('common.terms.total')"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.budgeted')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        :subheading="changeOrderBudgetedTotal"
        v-slot="props"
      >
      {{ displayCurrency(calculateChangeOrderBudgetActivitiesTotal(props.row.id), { prefix: '$', multiplier: 100 }) }}

      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.contracted')"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        sortable
        :subheading="changeOrderContractedTotal"
        v-slot="props"
      >
        {{ displayCurrency(calculateChangeOrderCostActivitiesTotal(props.row.id), { prefix: '$', multiplier: 100 }) }}
      </BTableColumn>
      <BTableColumn
        :label="$t('common.terms.createdAt')"
        subheading="--"
        cell-class="vertical-align-middle u-p-l"
        header-class="u-p-l"
        v-slot="props"
        sortable
        field="createdAt"
      >
        {{ new Date(props.row.createdAt).toLocaleDateString($i18n.locale) }}
        {{ new Date(props.row.createdAt).toLocaleTimeString($i18n.locale) }}
      </BTableColumn>
      <BTableColumn
        label=""
        width="168"
        v-slot="props"
      >
        <BTooltip
          v-if="isLoggedInUserAtLeastProjectManager"
          :label="$t('common.ctas.edit')"
          position="is-top"
          :triggers="['hover']"
          type="is-dark"
          class="u-m-r-small"
          appendToBody
        >
          <BButton
            type="is-primary is-light"
            @click="handleClickEditChangeOrder(props.row.id)"
            @keyup.enter="handleClickEditChangeOrder(props.row.id)"
          >
            <BIcon icon="pencil" />
          </BButton>
        </BTooltip>
        <BTooltip :label="$t('common.terms.details')" position="is-top" :triggers="['hover']" type="is-dark" class="u-m-r-small">
          <BButton
            tag="router-link"
            :to="`/projects/${projectId}/change-orders/${props.row.id}/details`"
            type="is-primary is-light"
          >
            <BIcon icon="dots-vertical" />
          </BButton>
        </BTooltip>
        <BTooltip :label="$t('common.ctas.delete')" position="is-top" :triggers="['hover']" type="is-dark" class="u-m-r-small">
          <BButton
            type="is-primary is-light"
            @click="(e) => { e.stopPropagation(); handleClickDeleteChangeOrder(props.row.id) }"
            @keyup.enter="(e) => { e.stopPropagation(); handleClickDeleteChangeOrder(props.row.id) }"
          >
            <BIcon icon="delete" />
          </BButton>
        </BTooltip>
      </BTableColumn>
    </BTable>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as ChangeOrderActions from '@/store/actions/ChangeOrder.actions';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency } from '@/helpers/stringHelpers';
import CreateOrEditChangeOrderModal from '@/components/CreateOrEditChangeOrderModal.vue';

export default {
  name: 'ProjectChangeOrdersTable',

  components: {
    CreateOrEditChangeOrderModal,
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      changeOrderIdEditing: null,
      changeOrderIdConfirmingDelete: null,
      changeOrdersDeleting: [],
      budgetedTotal: [],
    };
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    ...mapGetters([
      'getChangeOrdersByProjectId',
      'getChangeOrderCostActivities',
      'getChangeOrderBudgetActivities',
      'isLoggedInUserAtLeastProjectManager',
    ]),
    changeOrdersTableData() {
      return this.getChangeOrdersByProjectId(this.projectId);
    },
    changeOrderDeletingName() {
      if (this.changeOrderIdConfirmingDelete === null) {
        return '';
      }

      return this.changeOrders[this.changeOrderIdConfirmingDelete]?.name || '';
    },
    changeOrderBudgetedTotal() {
      const budgetedTotal = this.changeOrdersTableData.reduce((total, changeOrder) => total + this.calculateChangeOrderBudgetActivitiesTotal(changeOrder.id), 0);
      return displayCurrency(budgetedTotal, { prefix: '$', multiplier: 100 });
    },
    changeOrderContractedTotal() {
      const contractedTotal = this.changeOrdersTableData.reduce((total, changeOrder) => total + this.calculateChangeOrderCostActivitiesTotal(changeOrder.id), 0);
      return displayCurrency(contractedTotal, { prefix: '$', multiplier: 100 });
    },
  },

  methods: {
    displayCurrency,
    handleRowClass() {
      return 'has-cursor-pointer';
    },
    handleClickEditChangeOrder(changeOrderId) {
      this.changeOrderIdEditing = changeOrderId;
    },
    handleCloseEditChangeOrderModal() {
      this.changeOrderIdEditing = null;
    },
    handleClickDeleteChangeOrder(changeOrderId) {
      this.changeOrderIdConfirmingDelete = changeOrderId;
    },
    handleCloseDeleteModal() {
      this.changeOrderIdConfirmingDelete = null;
    },
    async handleConfirmDeleteChangeOrder(changeOrderId) {
      this.changeOrdersDeleting = [...this.changeOrdersDeleting, changeOrderId];
      try {
        await this.$store.dispatch(ChangeOrderActions.DELETE_CHANGE_ORDER_BY_ID, {
          changeOrderId,
        });
        this.handleCloseDeleteModal();
        NotificationService.showNotification(NotificationTypes.DELETE_CHANGE_ORDER.SUCCESS);
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_CHANGE_ORDER.ERROR);
      }
      this.changeOrdersDeleting = this.changeOrdersDeleting.filter(a => a !== changeOrderId);
    },
    calculateActivitiesTotal(activities) {
      return activities
        .reduce((acc, activity) => { // eslint-disable-line
          return acc + calculateActivityBudget(activity.unitValue, activity.quantity);
        }, 0);
    },
    calculateChangeOrderBudgetActivitiesTotal(changeOrderId) {
      return this.calculateActivitiesTotal(this.getChangeOrderBudgetActivities(changeOrderId));
    },
    calculateChangeOrderCostActivitiesTotal(changeOrderId) {
      return this.calculateActivitiesTotal(this.getChangeOrderCostActivities(changeOrderId));
    },
    handleClickRow(row) {
      this.$router.push(`/projects/${this.projectId}/change-orders/${row.id}/details`);
    },
  },
};
</script>

<style scoped lang="scss">
.costs-table__advance-column__header .th-wrap {
  text-align: center !important;
}

.filters__wrapper {
  display: flex;
  align-items: center;

  > * {
    margin-right: $global-whitespace-xsmall;
  }
}

.filters__dropdown-item {
  background-color: transparent !important;
  color: $dark !important;
}
</style>
