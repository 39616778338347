<template>
  <BModal
    :active.sync="isModalOpen"
    :canCancel="false"
    hasModalCard
  >
    <div class="modal-card">
      <header v-if="title" class="modal-card-head">
        <p class="modal-card-title">
          {{ title }}
        </p>
      </header>
      <section class="modal-card-body has-background-white u-p-a has-text-centered">
        <slot></slot>
      </section>
      <footer class="modal-card-foot" style="justify-content: flex-end;">
        <div class="buttons">
          <BButton v-if="cancelButton" @click="_cancel">
            {{ cancelButton }}
          </BButton>
          <BButton
            v-if="confirmButton"
            type="is-danger"
            @click="_confirm"
          >
            {{ confirmButton }}
          </BButton>
        </div>
      </footer>
    </div>
  </BModal>
</template>

<script>
export default {
  name: 'ConfirmActionModal',

  data() {
    return {
      isModalOpen: false,
      // Parameters that change depending on the type of dialogue
      title: undefined,
      // message: undefined, // Main text content
      confirmButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
      cancelButton: 'Go Back', // text for cancel button

      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      // this.message = opts.message;
      this.confirmButton = opts.confirmButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.isModalOpen = true;
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.isModalOpen = false;
      this.resolvePromise(true);
    },

    _cancel() {
      this.isModalOpen = false;
      this.resolvePromise(false);
    },
  },
};
</script>
