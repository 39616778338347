<template>
  <div class="app-page__container is-fluid" style="height: 100vh;">
    <BillDetailsModal
      :isOpen="!!activeBillId"
      :billId="activeBillId"
      @close="() => { $router.go(-1) }"
      @update="() => { createBillStatusLists() }"
    />
    <ConfirmActionModal ref="confirm-bill-status-update__modal">
      <p>
        <BIcon icon="alert" size="is-large" type="is-danger" />
      </p>
      <p v-if="isLoggedInUserAtLeastAdmin" class="is-size-5 u-m-t-small">{{ $t('confirmReopenBillModal.bodyTextForAdmin') }}</p>
      <p v-else class="is-size-5 u-m-t-small">{{ $t('confirmReopenBillModal.bodyTextForNonAdmin') }}</p>
      <p v-if="isLoggedInUserAtLeastAdmin" class="is-size-5 has-text-weight-bold u-m-t-small">{{ $t('confirmReopenBillModal.bodyCta') }}</p>
    </ConfirmActionModal>
    <ConfirmActionModal ref="disallow-bill-status-update__modal">
      <p>
        <BIcon icon="lock" size="is-large" type="is-danger" />
      </p>
      <p class="is-size-5 u-m-t-small">{{ $t('disallowBillStatusUpdateModal.bodyText') }}</p>
    </ConfirmActionModal>
    <div v-if="!isPageLoading" style="height: 100%;">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">
            {{ $t('common.terms.bills') }}
          </h4>
        </div>
        <div class="app-page__header__action">
        </div>
      </section>

      <!-- FILTERS -->
      <div class="filters__wrapper u-m-b-small">
        <p class="has-text-grey has-text-weight-bold u-m-r-small">
          <BIcon icon="filter" size="is-small" />
          {{ $t('common.terms.filters') }}
        </p>
        <BDropdown
          multiple
          v-model="filters.users"
        >
          <div slot="trigger">
            <BButton icon-right="chevron-down" iconLeft="account">
              {{ $t('billsView.billSummaryCard.requestedBy') }}
            </BButton>
            <div v-if="filters.users.length > 0" class="filters__dropdown-trigger__indicator">
              {{ filters.users.length }}
            </div>
          </div>
          <BDropdownItem v-for="user in users" :key="user.id" :value="user.id" class="filters__dropdown-item">
            <BCheckbox :value="filters.users.includes(user.id)" @click.native.prevent="() => {}">
              <span v-if="user.firstName && user.lastName" class="has-text-dark">{{ user.firstName }} {{ user.lastName }}</span>
              <span v-else class="has-text-dark">{{ user.email }}</span>
            </BCheckbox>
          </BDropdownItem>
          <hr class="dropdown-divider">
          <BDropdownItem @click="handleSelectAll('users')" class="filters__dropdown-item">
            <BCheckbox :value="areAllUsersSelected" :indeterminate="areSomeUsersSelected" @click.native.prevent="()=>{}">
              <span class="has-text-weight-bold">{{ allOptionsSelectedLabel(areAllUsersSelected) }}</span>
            </BCheckbox>
          </BDropdownItem>
        </BDropdown>
        <BDropdown
          multiple
          v-model="filters.vendors"
        >
          <div slot="trigger">
            <BButton icon-right="chevron-down" iconLeft="account-hard-hat">
              {{ $t('common.terms.vendor') }}
            </BButton>
            <div v-if="filters.vendors.length > 0" class="filters__dropdown-trigger__indicator">
              {{ filters.vendors.length }}
            </div>
          </div>
          <BDropdownItem v-for="vendor in vendorsWithNoVendorOption" :key="vendor.id" :value="vendor.id" class="filters__dropdown-item">
            <BCheckbox :value="filters.vendors.includes(vendor.id)" :disabled="shouldDisableVendorCheckbox(vendor)" @click.native.prevent="() => {}">
              <span class="has-text-dark">{{ vendor.name }}</span>
            </BCheckbox>
          </BDropdownItem>
          <hr class="dropdown-divider">
          <BDropdownItem @click="handleSelectAll('vendors')" class="filters__dropdown-item">
            <BCheckbox :value="areAllVendorsSelected" :indeterminate="areSomeVendorsSelected" @click.native.prevent="() => {}">
              <span class="has-text-weight-bold">{{ allOptionsSelectedLabel(areAllVendorsSelected) }}</span>
            </BCheckbox>
          </BDropdownItem>
        </BDropdown>
        <BDropdown
          multiple
          v-model="filters.projects"
        >
          <div slot="trigger">
            <BButton icon-right="chevron-down" iconLeft="table">
              {{ $t('common.terms.project') }}
            </BButton>
            <div v-if="filters.projects.length > 0" class="filters__dropdown-trigger__indicator">
              {{ filters.projects.length }}
            </div>
          </div>
          <BDropdownItem v-for="project in getLoggedInUserProjects" :key="project.id" :value="project.id" class="filters__dropdown-item">
            <BCheckbox :value="filters.projects.includes(project.id)" :disabled="shouldDisableProjectCheckbox(project)" @click.native.prevent="() => {}">
              <span class="has-text-dark">{{ project.name }}</span>
            </BCheckbox>
          </BDropdownItem>
          <hr class="dropdown-divider">
          <BDropdownItem @click="handleSelectAll('projects')" class="filters__dropdown-item">
            <BCheckbox :value="areAllProjectsSelected" :indeterminate="areSomeProjectsSelected" @click.native.prevent="() => {}">
              <span class="has-text-weight-bold">{{ allOptionsSelectedLabel(areAllProjectsSelected) }}</span>
            </BCheckbox>
          </BDropdownItem>
        </BDropdown>
        <BDropdown
          :disabled="Object.keys(costsDropdownOptions).length === 0"
          multiple
          v-model="filters.costs"
        >
          <div slot="trigger">
            <BButton icon-right="chevron-down" iconLeft="file-document-edit-outline">
              {{ $t('common.terms.cost') }}
            </BButton>
            <div v-if="filters.costs.length > 0" class="filters__dropdown-trigger__indicator">
              {{ filters.costs.length }}
            </div>
          </div>
          <BDropdownItem v-for="cost in costsDropdownOptions" :key="cost.id" :value="cost.id" class="filters__dropdown-item">
            <BCheckbox :value="filters.costs.includes(cost.id)" @click.native.prevent="() => {}">
              <span class="has-text-dark">{{ cost.name }}</span>
            </BCheckbox>
          </BDropdownItem>
          <hr class="dropdown-divider">
          <BDropdownItem @click="handleSelectAll('costs')" class="filters__dropdown-item">
            <BCheckbox :value="areAllCostsSelected" :indeterminate="areSomeCostsSelected" @click.native.prevent="() => {}">
              <span class="has-text-weight-bold">{{ allOptionsSelectedLabel(areAllCostsSelected) }}</span>
            </BCheckbox>
          </BDropdownItem>
        </BDropdown>
      </div>

      <!-- APPLIED FILTERS-->
        <BField grouped group-multiline class="u-p-t-xsmall">
          <div v-for="projectId in filters.projects" :key="projectId" class="control">
            <BTag
              type="is-primary is-light"
              size="is-small"
              icon="table"
              closable
              attached
              closeType="is-primary is-light"
              @close="() => handleRemoveFilter('projects', projectId)"
            >
              {{ projects[projectId].name }}
            </BTag>
          </div>
          <div v-for="costId in filters.costs" :key="costId" class="control">
            <BTag
              type="is-primary is-light"
              size="is-small"
              icon="file-document-edit-outline"
              closable
              attached
              closeType="is-primary is-light"
              @close="() => handleRemoveFilter('costs', costId)"
            >
              {{ costs[costId].name }}
            </BTag>
          </div>
          <div v-for="vendorId in filters.vendors" :key="vendorId" class="control">
            <BTag
              type="is-primary is-light"
              size="is-small"
              icon="account-hard-hat"
              closable
              attached
              closeType="is-primary is-light"
              @close="() => handleRemoveFilter('vendors', vendorId)"
            >
              {{ vendorsWithNoVendorOption[vendorId].name }}
            </BTag>
          </div>
          <div v-for="userId in filters.users" :key="userId" class="control">
            <BTag
              type="is-primary is-light"
              size="is-small"
              icon="account"
              closable
              attached
              closeType="is-primary is-light"
              @close="() => handleRemoveFilter('users', userId)"
            >
              {{ shapeUserDisplayName(users[userId]) }}
            </BTag>
          </div>
        </BField>

        <div class="is-flex is-justify-content-flex-end">
          <BSwitch
            class="u-m-y-small"
            rounded
            v-model="areArchivedProjectsIncluded"
          >
            <span class="has-text-weight-bold">{{ $t('projectListView.showArchivedProjects') }}</span>
          </BSwitch>
        </div>

      <!-- BILLS BOARD -->
      <section class="app-page__section is-flex" style="height: 100%; flex-direction: column;">
        <div class="columns is-mobile" style="flex-grow: 1; overflow-x: auto;">
          <div
            v-for="listEntry in Object.entries(billStatusLists)" :key="listEntry[0]"
            class="column bills-view__column u-p-x-xsmall"
            style="min-width: 280px; height: 100%;"
          >
            <div class="card bills-view__list-container is-flex" style="height: 100%; flex-direction: column;">
              <header class="card-header">
                <div class="card-header-title">
                  <BillStatusTag :billStatusId="listEntry[1].billStatus.id" />
                </div>
              </header>
              <div class="has-background-grey u-p-x-small u-p-y-small bills-view__list-container__total">
                <h5 class="title is-6 has-text-white">
                  <span>Total:</span>
                  &nbsp;
                  <AnimatedNumber
                    :value="addTotalFromBills(applyFilters(listEntry[1].billIds))"
                    :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                    :duration="400"
                  />
                </h5>
              </div>
              <div class="card-content u-p-a-small" style="flex-grow: 1; overflow: auto;">
                <Container
                  group-name="bills"
                  :get-child-payload="(index) => getChildPayload(index, listEntry[0])"
                  @drop="onDrop(listEntry[0], $event)"
                  drag-class="bills-view__card-ghost"
                  drop-class="bills-view__card-ghost-drop"
                  :drop-placeholder="dropPlaceholderOptions"
                  style="height: 100%;"
                  dragHandleSelector=".is-movable"
                >
                  <Draggable v-for="billId in applyFilters(listEntry[1].billIds)" :key="billId">
                    <template v-if="isProjectArchived(costs[bills[billId].costId].projectId) && areArchivedProjectsIncluded">
                    <BillSummaryCard
                      :billId="billId"
                      :isLoading="isBillInProgress === billId"
                      @click.native="() => handleOpenBillDetailsModal(billId)"
                      @keyup.native.enter="() => handleOpenBillDetailsModal(billId)"
                      class=""
                    />
                  </template>
                    <template v-else-if="!isProjectArchived(costs[bills[billId].costId].projectId)">
                      <BillSummaryCard
                      :billId="billId"
                      :isLoading="isBillInProgress === billId"
                      @click.native="() => handleOpenBillDetailsModal(billId)"
                      @keyup.native.enter="() => handleOpenBillDetailsModal(billId)"
                      class="is-movable"
                    />
                    </template>
                  </Draggable>
                </Container>
              </div>
              <footer class="card-footer">
                <div class="card-footer-item">&nbsp;</div>
              </footer>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd';
import AnimatedNumber from 'animated-number-vue';
import * as BillActions from '@/store/actions/Bill.actions';
import * as Types from '@/constants/Types';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as Flags from '@/constants/Flags'; // eslint-disable-line
import { displayCurrency } from '@/helpers/stringHelpers';
import { shapeUserDisplayName } from '@/helpers/userHelpers';
import BillStatusTag from '@/components/BillStatusTag.vue';
import BillSummaryCard from '@/components/BillSummaryCard.vue';
import BillDetailsModal from '@/components/BillDetailsModal.vue';
import ConfirmActionModal from '@/components/ConfirmActionModal.vue';

const defaultFilters = {
  projects: [],
  costs: [],
  vendors: [],
  users: [],
};

export default {
  name: 'BillsView',

  components: {
    AnimatedNumber,
    Container,
    Draggable,
    BillStatusTag,
    BillSummaryCard,
    BillDetailsModal,
    ConfirmActionModal,
  },

  data() {
    return {
      isPageLoading: false,
      billStatusLists: {},
      dropPlaceholderOptions: {
        className: 'bills-view__drop-preview',
        animationDuration: '150',
        showOnTop: false,
      },
      isBillInProgress: null,
      activeBillId: null,
      filters: {
        projects: [...defaultFilters.projects],
        costs: [...defaultFilters.costs],
        vendors: [...defaultFilters.vendors],
        users: [...defaultFilters.users],
      },
      filtersQueryParams: {
        ...defaultFilters,
      },
      areArchivedProjectsIncluded: true,
      filteredBills: [],
    };
  },

  computed: {
    ...mapState({
      billStatuses: state => state.billStatus.billStatuses,
      bills: state => state.bill.bills,
      costs: state => state.cost.costs,
      projects: state => state.project.projects,
      users: state => state.user.users,
      vendors: state => state.vendor.vendors,
    }),
    ...mapGetters([
      'activeOrganization',
      'loggedInUser',
      'getBillStatusByName',
      'getBillsByStatusId',
      'getBillTotalAfterTax',
      'isLoggedInUserAtLeastAdmin',
      'getLoggedInUserProjects',
      'isProjectArchived',
    ]),
    vendorsWithNoVendorOption() {
      const noVendorOption = {
        noVendor: {
          id: 'noVendor',
          name: this.$t('billsView.filters.noVendor'),
        },
      };
      return { ...this.vendors, ...noVendorOption };
    },
    costsDropdownOptions() {
      if (this.filters.projects.length > 0) {
        const costOptions = {};
        this.filters.projects.forEach(projectId => {
          Object.keys(this.costs).forEach(costId => {
            if (this.costs[costId].projectId === projectId) {
              let vendorCheck = true;
              if (this.filters.vendors.length > 0) {
                vendorCheck = this.filters.vendors.includes(this.costs[costId].vendorId);
                if (this.costs[costId].vendorId === null && this.filters.vendors.includes('noVendor')) {
                  vendorCheck = true;
                }
              }
              if (vendorCheck) {
                costOptions[costId] = this.costs[costId];
              }
            }
          });
        });
        return costOptions;
      }
      return {};
    },
    areSomeProjectsSelected() {
      return this.filters.projects.length > 0 && !this.areAllProjectsSelected;
    },
    areAllProjectsSelected() {
      return this.getLoggedInUserProjects.every(project => this.filters.projects.includes(project.id));
    },
    areSomeCostsSelected() {
      return this.filters.costs.length > 0 && !this.areAllCostsSelected;
    },
    areAllCostsSelected() {
      const costKeys = Object.keys(this.costs);
      return costKeys.every(id => this.filters.costs.includes(id));
    },
    areSomeVendorsSelected() {
      return this.filters.vendors.length > 0 && !this.areAllVendorsSelected;
    },
    areAllVendorsSelected() {
      const vendorKeys = Object.keys(this.vendorsWithNoVendorOption);
      return vendorKeys.every(id => this.filters.vendors.includes(id));
    },
    areSomeUsersSelected() {
      return this.filters.users.length > 0 && !this.areAllUsersSelected;
    },
    areAllUsersSelected() {
      const userKeys = Object.keys(this.users);
      return userKeys.every(id => this.filters.users.includes(id));
    },
  },

  async created() {
    await this.fetchInitialData();
    this.createBillStatusLists();

    if (this.$route.params.billId) {
      this.activeBillId = this.$route.params.billId;
    }
  },

  watch: {
    '$route.params.billId'(id) { // eslint-disable-line
      this.activeBillId = id;
    },
    filters: {
      handler(updatedFilters) {
        Object.keys(updatedFilters).forEach(filterKey => {
          this.updateFiltersQueryString(filterKey);
          if (filterKey === 'users' || filterKey === 'vendors') {
            this.updateProjectsFilters();
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    displayCurrency,
    shapeUserDisplayName,
    // REMINDER: On each drag/drop, this code runs 4 times, asynchronously since we have 4 containers that
    // all trigger onDrop methods. Keep in mind when debugging.
    async onDrop(billStatusId, dropResult) {
      const { payload: billId } = dropResult;

      // If container isn't affected just return.
      if (dropResult.removedIndex === null && dropResult.addedIndex === null) {
        return;
      }

      this.billStatusLists[billStatusId] = {
        ...this.billStatusLists[billStatusId],
        billIds: [...this.applyDrag(this.billStatusLists[billStatusId].billIds, dropResult)],
      };

      if (dropResult.addedIndex !== null) {
        // If org can open bills after previous is APPROVED
        // Then show modal when bill moves from APPROVED or CLOSED
        // to any other status.
        if (this.$GrowthBook.isOn(Flags.OPEN_BILL_WHEN_PREVIOUS_APPROVED)) {
          if (
            (this.isBillClosed(billId) || this.isBillApproved(billId))
            && (
              billStatusId !== this.getBillStatusByName('closed').id
              && billStatusId !== this.getBillStatusByName('approved').id
            )
          ) {
            const isConfirmed = await this.showReopenConfirmationModal();
            if (!isConfirmed) {
              return; // return early to cancel drag/drop action.
            }
          }
        } else if (this.isBillClosed(billId)) { // Default behavior where modal is shown when moving bills from CLOSED status.
          const isConfirmed = await this.showReopenConfirmationModal();
          if (!isConfirmed) {
            return; // return early to cancel drag/drop action.
          }
        }

        if (
          this.activeOrganization.id === Flags.FLAGGED_ORG_ID
          && this.loggedInUser.id !== Flags.ALLOWED_USER_ID
        ) {
          if (
            (this.isBillApproved(billId) && billStatusId !== this.getBillStatusByName('closed').id)
            || (!this.isBillClosed(billId) && billStatusId === this.getBillStatusByName('approved').id)
            || (!this.isBillApproved(billId) && billStatusId === this.getBillStatusByName('closed').id)
          ) {
            const allowed = await this.$refs['disallow-bill-status-update__modal'].show({
              title: this.$t('disallowBillStatusUpdateModal.title'),
              // ...this.isLoggedInUserAtLeastAdmin && { confirmButton: this.$t('disallowBillStatusUpdateModal.confirmCta') },
              cancelButton: this.$t('disallowBillStatusUpdateModal.cancelCta'),
            });
            if (!allowed) {
              this.isBillInProgress = null;
              // Wrapped in timeout so that it executes after other containers
              // have finished their `applyDrag` and so this one will be the final arbiter of truth.
              setTimeout(() => {
                this.createBillStatusLists();
              }, 100);
              return;
            }
          }
        }
      }

      if (dropResult.addedIndex !== null) {
        try {
          this.isBillInProgress = billId;

          await this.$store.dispatch(
            BillActions.UPDATE_BILL_STATUS_BY_BILL_ID,
            { billId, updatedBill: { statusId: billStatusId } },
          );
        } catch (err) {
          this.isBillInProgress = null;
          this.createBillStatusLists();
          NotificationService.showNotification(NotificationTypes.UPDATE_BILL.ERROR);
        }
        this.isBillInProgress = null;
      }
    },
    getChildPayload(index, list) {
      return this.billStatusLists[list].billIds[index];
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]; // eslint-disable-line prefer-destructuring
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
    createBillStatusLists() {
      this.billStatusLists = {
        [this.getBillStatusByName(Types.billStatusTypes.draft).id]: {
          billStatus: { ...this.getBillStatusByName(Types.billStatusTypes.draft) },
          billIds: [
            ...this.getBillsByStatusId(this.getBillStatusByName(Types.billStatusTypes.draft).id).map(bill => bill.id),
          ],
        },
        [this.getBillStatusByName(Types.billStatusTypes.pendingApproval).id]: {
          billStatus: { ...this.getBillStatusByName(Types.billStatusTypes.pendingApproval) },
          billIds: [
            ...this.getBillsByStatusId(this.getBillStatusByName(Types.billStatusTypes.pendingApproval).id)
              .sort((billA, billB) => new Date(billA.createdAt) - new Date(billB.createdAt))
              .map(bill => bill.id),
          ],
        },
        [this.getBillStatusByName(Types.billStatusTypes.rejected).id]: {
          billStatus: { ...this.getBillStatusByName(Types.billStatusTypes.rejected) },
          billIds: [
            ...this.getBillsByStatusId(this.getBillStatusByName(Types.billStatusTypes.rejected).id)
              .sort((billA, billB) => new Date(billA.createdAt) - new Date(billB.createdAt))
              .map(bill => bill.id),
          ],
        },
        [this.getBillStatusByName(Types.billStatusTypes.approved).id]: {
          billStatus: { ...this.getBillStatusByName(Types.billStatusTypes.approved) },
          billIds: [
            ...this.getBillsByStatusId(this.getBillStatusByName(Types.billStatusTypes.approved).id)
              .sort((billA, billB) => new Date(billA.createdAt) - new Date(billB.createdAt))
              .map(bill => bill.id),
          ],
        },
        [this.getBillStatusByName(Types.billStatusTypes.closed).id]: {
          billStatus: { ...this.getBillStatusByName(Types.billStatusTypes.closed) },
          billIds: [
            ...this.getBillsByStatusId(this.getBillStatusByName(Types.billStatusTypes.closed).id)
              .sort((billA, billB) => new Date(billB.createdAt) - new Date(billA.createdAt))
              .map(bill => bill.id),
          ],
        },
      };
    },
    handleOpenBillDetailsModal(billId) {
      this.$router.push(`/bills/${billId}`);
    },
    async fetchInitialData() {
      this.isPageLoading = true;
      this.populateFiltersFromQueryString();
      if (this.isLoggedInUserAtLeastAdmin) {
        await this.$store.dispatch(BillActions.FETCH_ORGANIZATION_BILLS, { organizationId: this.activeOrganization.id });
      } else {
        await this.$store.dispatch(BillActions.FETCH_USER_BILLS, { userId: this.loggedInUser.id });
      }
      this.isPageLoading = false;
    },
    handleSelectAll(entity) {
      if (this.areAllOptionsSelected(entity)) {
        this.filters[entity] = [];
      } else if (entity === 'vendors') {
        this.filters[entity] = [...Object.keys(this.vendorsWithNoVendorOption), ...defaultFilters[entity]];
      } else if (entity === 'costs') {
        this.filters[entity] = [...Object.keys(this.costsDropdownOptions), ...defaultFilters[entity]];
      } else {
        this.filters[entity] = [...Object.keys(this[entity]), ...defaultFilters[entity]];
      }
    },
    areAllOptionsSelected(entity) {
      switch (entity) {
      case 'projects': return this.areAllProjectsSelected;
      case 'costs': return this.areAllCostsSelected;
      case 'vendors': return this.areAllVendorsSelected;
      case 'users': return this.areAllUsersSelected;
      default: return false;
      }
    },
    allOptionsSelectedLabel(areAllSelected) {
      if (areAllSelected) {
        return this.$t('common.ctas.unselectAll');
      }
      return this.$t('common.ctas.selectAll');
    },
    applyFilters(billIds) {
      const filteredIds = billIds
        .map(billId => ({
          ...this.bills[billId],
          projectId: this.costs[this.bills[billId].costId].projectId,
          vendorId: this.costs[this.bills[billId].costId].vendorId ?? 'noVendor',
        }))
        .filter(bill => (
          this.filterByProject(bill)
          && this.filterByCost(bill)
          && this.filterByVendor(bill)
          && this.filterByUser(bill)
        ))
        .map(bill => bill.id);
      return filteredIds;
    },

    filterByProject(bill) {
      return this.filters.projects.length === 0 || this.filters.projects.includes(bill.projectId);
    },
    filterByCost(bill) {
      return this.filters.costs.length === 0 || this.filters.costs.includes(bill.costId);
    },
    filterByVendor(bill) {
      const vendorId = bill.vendorId === null ? 'noVendor' : bill.vendorId;
      return this.filters.vendors.length === 0 || this.filters.vendors.includes(vendorId);
    },
    filterByUser(bill) {
      return this.filters.users.length === 0 || this.filters.users.includes(bill.createdByUserId);
    },
    shouldDisableProjectCheckbox(project) {
      if (this.filters.users.length > 0) {
        const projectsInBills = [];
        this.filteredBills.forEach(billId => {
          projectsInBills.push(this.costs[this.bills[billId].costId].projectId);
        });
        return !projectsInBills.includes(project.id);
      }
      return false;
    },
    shouldDisableVendorCheckbox(vendor) {
      if (this.filters.users.length > 0) {
        const costsIds = [];
        const vendorsIds = [];
        this.filters.users.forEach(userId => {
          Object.keys(this.bills).forEach(billId => {
            if (this.bills[billId].createdByUserId === userId && !costsIds.includes(this.bills[billId].costId)) {
              costsIds.push(this.bills[billId].costId);
            }
          });
          costsIds.forEach(costId => {
            const vendorId = this.costs[costId].vendorId === null ? 'noVendor' : this.costs[costId].vendorId;
            if (!vendorsIds.includes(vendorId)) {
              vendorsIds.push(vendorId);
            }
          });
        });
        if (this.filters.vendors.length > 0) {
          const toRemove = this.filters.vendors.filter(vendorId => !vendorsIds.includes(vendorId));
          toRemove.forEach(vendorId => {
            const removeIndex = this.filters.vendors.indexOf(vendorId);
            if (removeIndex > -1) {
              this.filters.vendors.splice(removeIndex, 1);
            }
          });
        }
        return !vendorsIds.includes(vendor.id);
      }
      return false;
    },
    updateProjectsFilters() {
      this.filteredBills = [];
      if (this.filters.users.length > 0 || this.filters.vendors.length > 0) {
        Object.keys(this.bills).forEach(billId => {
          const hasUser = this.filterByUser(this.bills[billId]);
          const hasVendor = this.filterByVendor(this.costs[this.bills[billId].costId]);
          if (hasUser && hasVendor && !this.filteredBills.includes(billId)) {
            this.filteredBills.push(billId);
          }
        });
      }
    },
    updateFiltersQueryString(entity) {
      const { query } = this.$route;
      this.filtersQueryParams[entity] = JSON.stringify(this.filters[entity]);
      this.$router.replace({ query: { ...query, ...this.filtersQueryParams } }).catch(() => {});
    },
    handleRemoveFilter(entity, id) {
      this.filters = {
        ...this.filters,
        [entity]: this.filters[entity].filter(filterId => filterId !== id),
      };
    },
    populateFiltersFromQueryString() {
      const { query } = this.$route;
      const filtersKeys = Object.keys(this.filtersQueryParams);
      filtersKeys.forEach(key => {
        if (key in query && key in this.filters) {
          try {
            const data = JSON.parse(query[key]);
            if (Array.isArray(data)) {
              this.filters[key] = data;
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    addTotalFromBills(billIds) {
      if (!billIds || billIds.length === 0) {
        return 0;
      }

      return billIds.reduce((total, billId) => (total + this.getBillTotalAfterTax(billId)), 0);
    },
    isBillClosed(billId) {
      const bill = this.bills[billId];
      const closedBillStatus = this.getBillStatusByName('closed');

      return bill.statusId === closedBillStatus.id;
    },
    isBillApproved(billId) {
      const bill = this.bills[billId];
      const approvedBillStatus = this.getBillStatusByName('approved');

      return bill.statusId === approvedBillStatus.id;
    },
    async showReopenConfirmationModal() {
      const confirmed = await this.$refs['confirm-bill-status-update__modal'].show({
        title: this.$t('confirmReopenBillModal.title'),
        ...this.isLoggedInUserAtLeastAdmin && { confirmButton: this.$t('confirmReopenBillModal.confirmCta') },
        cancelButton: this.$t('confirmReopenBillModal.cancelCta'),
      });

      if (!confirmed) {
        this.isBillInProgress = null;
        // Wrapped in timeout so that it executes after other containers
        // have finished their `applyDrag` and so this one will be the final arbiter of truth.
        setTimeout(() => {
          this.createBillStatusLists();
        }, 100);

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
.bills-view__list-container {
  background: $grey-light;

  &__total {
    box-shadow: inset $shadow;
  }
}

.bills-view__card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.bills-view__card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}

.bills-view__drop-preview {
  background: $grey;
  margin-bottom: $global-whitespace-small;
  border-radius: $radius-2xlarge;
}
</style>
