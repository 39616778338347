<template>
    <BModal
      :active="active"
      @close="handleCloseDeleteModal"
      hasModalCard
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="activityId" class="modal-card-title">
            {{ $t('common.ctas.delete') }}
            {{ $t('common.terms.activity') }}
          </p>
          <button
            type="button"
            class="delete"
            @click="handleCloseDeleteModal"
          />
        </header>
        <section class="modal-card-body has-background-white u-p-a has-text-centered">
          <div>
            <p>
              <BIcon icon="alert" size="is-large" type="is-danger" />
            </p>
            <h5 class="title is-5">{{ $t('costActivitiesTable.deleteActivityModal.warning') }}</h5>
            <div class="is-flex is-justify-content-center">
              <ActivityCard v-if="activityId" :activityId="activityId" style="width: 300px;" />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <div class="buttons">
            <BButton @click="handleCloseDeleteModal">
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-danger"
              @click="handleConfirmDeleteActivity()"
              :loading="isDeleting"
            >
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.activity') }}
            </BButton>
          </div>
        </footer>
      </div>
    </BModal>
</template>

<script>
import ActivityCard from '@/components/ActivityCard.vue';

export default {
  name: 'ActivityConfirmDeleteModal',

  props: {
    activityId: {
      type: String,
      required: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ActivityCard,
  },

  methods: {
    handleCloseDeleteModal() {
      this.$emit('close');
    },

    handleConfirmDeleteActivity() {
      this.$emit('confirmDeleteActivity');
    },
  },
};
</script>
