import { normalize } from 'normalizr';
import * as AuditLogMutations from '@/store/mutations/AuditLog.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as AuditLogActions from '@/store/actions/AuditLog.actions';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  auditLogs: {},
  auditLogCount: 0,
});

const receiveAuditLogs = (state, payload) => {
  state.auditLogs = {
    ...state.auditLogs,
    ...payload.auditLogs,
  };

  if (payload.auditLogCount) state.auditLogCount = payload.auditLogCount;

  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  getAuditLogsByResourceId(state) {
    return resourceId => Object.values(state.auditLogs).filter(log => log.resourceId === resourceId);
  },
};

const mutations = {
  [AuditLogMutations.AUDIT_LOG_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [AuditLogMutations.AUDIT_LOG_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [AuditLogMutations.AUDIT_LOG_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [AuditLogMutations.RECEIVE_AUDIT_LOGS]: receiveAuditLogs,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveAuditLogs,
  [UserMutations.RECEIVE_USERS]: receiveAuditLogs,
};

const actions = {
  [AuditLogActions.FETCH_AUDIT_LOGS_BY_RESOURCE_ID]: async (context, payload) => {
    context.commit(AuditLogMutations.AUDIT_LOG_REQUEST_DATA);

    const { resourceType, resourceId } = payload;

    try {
      const { data: { auditLogs } } = await Api.auditLog[resourceType].findMany(resourceId);

      const normalizedAuditLogs = normalize(auditLogs, [schemas.auditLog]);
      context.commit(AuditLogMutations.RECEIVE_AUDIT_LOGS, { ...normalizedAuditLogs.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [AuditLogActions.FETCH_ORGANIZATION_AUDIT_LOGS]: async (context, payload) => {
    context.commit(AuditLogMutations.AUDIT_LOG_REQUEST_DATA);
    const { organizationId, params } = payload;
    try {
      const { data: { auditLogs, _count: auditLogCount } } = await Api.organization.auditLog.findMany(organizationId, params);

      const normalizedAuditLogs = normalize(auditLogs, [schemas.auditLog]);
      context.commit(AuditLogMutations.RECEIVE_AUDIT_LOGS, { ...normalizedAuditLogs.entities, auditLogCount });

      return Object.keys(normalizedAuditLogs.entities.auditLogs);
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
