import { normalize } from 'normalizr';
import * as TypeActions from '@/store/actions/Type.actions';
import * as TypeMutations from '@/store/mutations/Type.mutations';
import * as Api from '@/services/Api.service';
import * as schemas from '@/store/schemas';

const getInitialState = () => ({
  isFetchingData: false,
});

const receiveTypes = state => {
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  // Placeholder
};

const mutations = {
  [TypeMutations.RECEIVE_TYPES]: receiveTypes,
  [TypeMutations.TYPE_REQUEST_DATA]: state => {
    state.isFetchingData = true;
  },
};

const actions = {
  [TypeActions.FETCH_ALL_TYPES]: async context => {
    context.commit(TypeMutations.TYPE_REQUEST_DATA);
    try {
      const {
        data: {
          projectStatuses,
          costTypes,
          userStatuses,
          invitationStatuses,
          billStatuses,
          billingTypes,
          billItemTypes,
          subscriptionTypes,
        },
      } = await Api.type.findMany();

      context.commit(TypeMutations.RECEIVE_TYPES, {
        ...normalize(projectStatuses, [schemas.projectStatus]).entities,
        ...normalize(costTypes, [schemas.costType]).entities,
        ...normalize(invitationStatuses, [schemas.invitationStatus]).entities,
        ...normalize(userStatuses, [schemas.userStatus]).entities,
        ...normalize(billStatuses, [schemas.billStatus]).entities,
        ...normalize(billingTypes, [schemas.billingType]).entities,
        ...normalize(billItemTypes, [schemas.billItemType]).entities,
        ...normalize(subscriptionTypes, [schemas.subscriptionType]).entities,
      });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
