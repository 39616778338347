<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <BillBreakdownModal
        :advanceInputs="advanceInputs"
        :isOpen="isBillBreakdownModalOpen"
        :activities="this.isLumpSumBillingType ? this.newActivities : this.costActivities"
        :isSubmissionInProgress="isBillSubmissionInProgress"
        @close="handleToggleBillBreakdownModal"
        @submit="handleBillBreakdownSubmit"
      />
      <BModal
        :active="isImportActivitiesModalOpen"
        @close="handleCloseImportActivitiesModal"
        hasModalCard
        trapFocus
        ariaModal
        :canCancel="true"
      >
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ $t('common.titles.importActivities') }}</p>
            <button
              type="button"
              class="delete"
              @click="handleCloseImportActivitiesModal"
            />
          </header>
          <div class="has-background-white u-p-a">
            <ImportActivities
              @submit="handleSubmitImportActivities"
            />
          </div>
        </div>
      </BModal>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4 u-m-b-small">
            {{
              activeBillId
                ? $t('common.ctas.editOpenBill', {
                  billNumber: activeBill.number, billStatusName: $t(`common.billStatuses.${billStatuses[activeBill.statusId].name}`),
                })
                : $t('common.ctas.createBill')
              }}
          </h4>
          <h5 class="is-size-5 has-text-grey has-text-weight-bold is-italic">
            {{ activeProject.name }}
          </h5>
          <h4 class="title is-5">
            {{ activeCost.name }}
            &nbsp;
            <CostTypeTag :costTypeId="activeCost.costTypeId" class="u-m-l-xsmall" />
          </h4>
          <p v-if="vendors[activeCost.vendorId]" class="subtitle is-size-6 is-italic has-text-weight-normal">
            <BIcon icon="account-hard-hat" size="is-small" />
            {{ vendors[activeCost.vendorId].name }}
          </p>
        </div>
        <div class="app-page__header__action">
          <div class="buttons is-small is-align-items-flex-start">
            <BButton
              type="is-text"
              size="is-small"
              outlined
              icon-left="arrow-left"
              @click="handleCancelClick"
              @keyup.enter="handleCancelClick"
            >
              {{ $t('common.ctas.cancel') }}
            </BButton>
            <BButton
              type="is-primary"
              size="is-small"
              iconLeft="eraser"
              outlined
              @click="() => handleSubmitBill(Types.billStatusTypes.draft)"
              @keyup.enter="() => handleSubmitBill(Types.billStatusTypes.draft)"
              :loading="isBillSubmissionInProgress"
            >
              {{ $t('common.ctas.saveBillDraft') }}
            </BButton>
            <BButton
              type="is-primary"
              size="is-small"
              iconLeft="check-bold"
              @click="handleToggleBillBreakdownModal"
              @keyup.enter="handleToggleBillBreakdownModal"
              :loading="isBillSubmissionInProgress || isFetchingTaxes"
            >
              {{ $t('common.ctas.finalizeBill') }}
            </BButton>
          </div>
        </div>
      </section>
      <section v-if="apiError" class="app-page__section">
        <BMessage type="is-danger">
          {{ apiError.message }}
        </BMessage>
      </section>
      <section class="app-page__section">
        <div class="columns">
          <div
            v-for="total in billTotals"
            :key="total.key"
            class="column"
          >
            <div class="card bill-totals-card">
              <header class="card-header">
                <p class="card-header-title has-text-grey">{{ $t(total.title) }}</p>
                <p v-if="total.percent" class="card-header-icon has-text-weight-bold">
                  <animated-number
                    :value="total.percent"
                    :formatValue="(value) => addSuffix(convertFractionToPercent(value), '%')"
                    :duration="400"
                  />
                </p>
                <p
                  v-else-if="total.percentOfBudget"
                  :class="[
                    'card-header-icon',
                    'has-text-weight-bold',
                    total.percentOfBudget > 1 ? 'has-text-danger' : 'has-text-success'
                  ]"
                >
                  <animated-number
                    :value="total.percentOfBudget"
                    :formatValue="(value) => addSuffix(convertFractionToPercent(value), '%')"
                    :duration="400"
                  />
                </p>
              </header>
              <div class="card-content u-p-t-0">
                <p class="is-size-5 is-italic">
                  <animated-number
                    v-if="total.amountDifference !== undefined"
                    :value="total.amountDifference"
                    :formatValue="(value) => displayCurrency(value, { prefix: '+ $', multiplier: 100 })"
                    :duration="400"
                  />
                </p>
                <h4 class="title is-4">
                  <animated-number
                    :value="total.amount"
                    :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                    :duration="400"
                  />
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="app-page__section u-m-t-large">
        <div class="app-page__header">
          <div class="app-page__header__title">
            <div style="display: flex; align-items: baseline">
              <h5 class="title is-5 has-text-grey">{{ $t('common.terms.activities') }}</h5>
            </div>
          </div>
          <div class="app-page__header__action is-align-items-flex-start">
            <BButton type="is-primary" size="is-small" outlined iconLeft="upload" @click="handleClickImportActivities">
              {{ $t('common.ctas.importActivities') }}
            </BButton>
          </div>
        </div>
      </section>
      <section v-if="isLumpSumBillingType" class="app-page__section">
        <CreateOrEditActivityCard :costId="activeCostId" :onSubmit="handleAddActivity" />
      </section>
      <section class="app-page__section">
        <BillActivitiesTable
          v-if="isLumpSumBillingType"
          :activities="newActivities"
          :costId="activeCostId"
          :billId="activeBillId"
          @updateActivity="initializeActivities"
        />
        <BTable
          v-else-if="isAdvanceBillingType"
          :data="costActivities"
          hoverable
          detailed
          :mobile-cards="false"
          detail-key="id"
          class="card"
          :defaultSortDirection="'asc'"
          :defaultSort="['name']"
          :rowClass="(row) => row.changeOrderId && 'has-background-warning-light'"
        >
          <BTableColumn
            field="name"
            label="Nombre"
            cell-class="vertical-align-middle"
            v-slot="props"
            sortable
          >
            {{ props.row.name }}
          </BTableColumn>
          <BTableColumn
            field="totalBudget"
            label="Contratado"
            numeric
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            <p>
              {{ displayCurrency(getActivityBudget(props.row.id), { prefix: '$', multiplier: 100 }) }}
            </p>
            <small>
              {{ props.row.quantity }} {{ props.row.unit }} @ ${{ displayCurrency(props.row.unitValue, { multiplier: 100 }) }}
            </small>
          </BTableColumn>
          <BTableColumn
            field="totalBudget"
            label="Avance Actual"
            numeric
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            <p>
              {{ displayCurrency(getActivityTotalAdvanceAmount(props.row.id, { excludedBillStatuses: [...excludedBillStatusTypes] }), { prefix: '$', multiplier: 100 }) }}
            </p>
            <p class="is-italic">
              {{ displayCurrency(getActivityTotalAdvanceUnits(props.row.id, { excludedBillStatuses: [...excludedBillStatusTypes] } )) }}
              {{ props.row.unit }}
            </p>
            <p class="is-italic">
              {{ convertFractionToPercent(getActivityTotalAdvancePercent(props.row.id, { excludedBillStatuses: [...excludedBillStatusTypes] } )) }}%
            </p>
          </BTableColumn>
          <BTableColumn
            field="currentAdvance"
            label="Nuevo Avance"
            cell-class="vertical-align-middle create-advance-bill__table__progress-input-column"
            v-slot="props"
            centered
            :width="300"
          >
            <ProgressInputCard
              :value="advanceInputs[props.row.id]"
              :activityId="props.row.id"
              @change="(value) => handleProgressInputChange(value, props.row.id)"
            />
          </BTableColumn>
          <BTableColumn
            field="currentAdvance"
            label="Avance Previsto"
            cell-class="vertical-align-middle"
            numeric
            v-slot="props"
          >
            {{ displayCurrency(advanceInputs[props.row.id], { prefix: '+ $', multiplier: 100 }) }}
            <div style="white-space: nowrap;">
              {{
                displayCurrency(
                  (
                    advanceInputs[props.row.id]
                    + getActivityTotalAdvanceAmount(props.row.id, { excludedBillStatuses: [...excludedBillStatusTypes] })
                  ),
                  { prefix: '= $', multiplier: 100 }
                )
              }}
            </div>
          </BTableColumn>
          <!-- <BTableColumn
            field="remainingBalance"
            label="Avance Restante"
            cell-class="vertical-align-middle"
            numeric
            v-slot="props"
          >
            {{
              displayCurrency((getActivityBudget(props.row.id) / 100) - (advanceInputs[props.row.id] + (getActivityTotalAdvanceAmount(props.row.id) / 100)), { prefix: '$'})
            }}
          </BTableColumn> -->
          <template slot="detail" slot-scope="props">
            <ActivityRowDetails :activeActivityId="props.row.id" />
          </template>
        </BTable>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AnimatedNumber from 'animated-number-vue';
import * as Currency from '@/constants/Currency';
import * as BillActions from '@/store/actions/Bill.actions';
import * as CostActions from '@/store/actions/Cost.actions';
import * as TaxActions from '@/store/actions/Tax.actions';
import * as DocumentActions from '@/store/actions/Document.actions';
import { cleave, masks } from '@/directives/Cleave.directive';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { calculateAdvanceAmount } from '@/helpers/advanceHelpers';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import * as Types from '@/constants/Types';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as NotificationService from '@/services/Notification.service';
import ActivityRowDetails from '@/components/ActivityRowDetails.vue';
import CostTypeTag from '@/components/CostTypeTag.vue';
import ProgressInputCard from '@/components/ProgressInputCard.vue';
import BillActivitiesTable from '@/components/BillActivitiesTable.vue';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import BillBreakdownModal from '@/components/BillBreakdownModal.vue';
import ImportActivities from '@/components/ImportActivities.vue';

export default {
  name: 'CreateOrEditBillView',

  directives: { cleave },

  components: {
    ActivityRowDetails,
    AnimatedNumber,
    CostTypeTag,
    ProgressInputCard,
    BillActivitiesTable,
    CreateOrEditActivityCard,
    BillBreakdownModal,
    ImportActivities,
  },

  data() {
    return {
      masks,
      Types,
      currencyOptions: { ...Currency.defaultCurrency },
      isPageLoading: true,
      advanceInputs: [],
      isBillSubmissionInProgress: false,
      newActivities: [],
      apiError: null,
      isBillBreakdownModalOpen: false,
      isFetchingTaxes: false,
      billBreakdownItems: [],
      isAdvanceTaxed: false,
      billName: '',
      billDocuments: null,
      selectedTaxId: null,
      excludedBillStatusTypes: [
        Types.billStatusTypes.draft,
        Types.billStatusTypes.rejected,
        Types.billStatusTypes.pendingApproval,
      ],
      isImportActivitiesModalOpen: false,
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      costs: state => state.cost.costs,
      projects: state => state.project.projects,
      vendors: state => state.vendor.vendors,
      bills: state => state.bill.bills,
      taxes: state => state.tax.taxes,
      billStatuses: state => state.billStatus.billStatuses,
    }),
    ...mapGetters([
      'getBillStatusByName',
      'getBillItemByActivityAndBillId',
      'getBillActivities',
      'getBillingTypeByName',
      'activeOrganization',
      'getBillItemTypeByName',
      'getActivityTotalAdvanceAmount',
      'getActivityBudget',
      'getActivityTotalAdvanceUnits',
      'getActivityTotalAdvancePercent',
      'getCostTotalAdvanceAmount',
    ]),
    activeCostId() {
      return this.$route.params.costId;
    },
    activeCost() {
      return this.costs[this.activeCostId];
    },
    activeProject() {
      return this.projects[this.activeCost.projectId];
    },
    activeBillId() {
      return this.$route.params.billId;
    },
    activeBill() {
      return this.bills[this.activeBillId];
    },
    costActivities() {
      return Object.values(this.activities)
        .filter(activity => activity.costId === this.activeCostId);
    },
    isAdvanceBillingType() {
      return this.activeCost.billingTypeId === this.getBillingTypeByName(Types.billingTypes.advance).id;
    },
    isLumpSumBillingType() {
      return this.activeCost.billingTypeId === this.getBillingTypeByName(Types.billingTypes.lumpSum).id;
    },
    costTotalActivityBudget() {
      return (this.isLumpSumBillingType ? this.newActivities : this.costActivities)
        .reduce((acc, activity) => { // eslint-disable-line
          return acc + calculateActivityBudget(activity.unitValue, activity.quantity);
        }, 0);
    },
    costTotalAdvancePreview() {
      if (isObjectEmpty(this.advanceInputs)) return 0;

      return Object.values({ ...this.advanceInputs }).reduce((acc, val) => acc + val, 0);
    },
    billTotals() {
      return this.isLumpSumBillingType ? (
        [
          {
            title: 'createBillView.billTotals.contractedOnBill',
            amount: this.costTotalActivityBudget,
            percentOfBudget: this.costTotalActivityBudget / this.activeCost.budget,
          },
        ]
      ) : (
        [
          {
            title: 'createBillView.billTotals.contracted',
            amount: this.costTotalActivityBudget,
            percentOfBudget: this.costTotalActivityBudget / this.activeCost.budget,
          },
          {
            title: 'createBillView.billTotals.previousAdvance',
            amount: this.getCostTotalAdvanceAmount(this.activeCostId, { excludedBillStatusNames: [...this.excludedBillStatusTypes] }),
            percent: this.getCostTotalAdvanceAmount(this.activeCostId, { excludedBillStatusNames: [...this.excludedBillStatusTypes] }) / this.costTotalActivityBudget || 0,
          },
          {
            title: 'createBillView.billTotals.billAdvance',
            amountDifference: this.costTotalAdvancePreview,
            amount: this.costTotalAdvancePreview + this.getCostTotalAdvanceAmount(this.activeCostId, { excludedBillStatusNames: [...this.excludedBillStatusTypes] }),
            percent: (this.costTotalAdvancePreview + this.getCostTotalAdvanceAmount(this.activeCostId, { excludedBillStatusNames: [...this.excludedBillStatusTypes] })) / this.costTotalActivityBudget,
          },
          // {
          //   title: 'Saldo Restante Previsto',
          //   amount: this.costRemainingBalancePreview,
          //   percent: (this.costRemainingBalancePreview / this.costTotalActivityBudget).toFixed(4),
          // },
        ]
      );
    },
  },

  async created() {
    await this.fetchInitialData();
    if (this.isLumpSumBillingType) {
      this.initializeActivities();
    }
  },

  methods: {
    displayCurrency,
    calculateActivityBudget,
    calculateAdvanceAmount,
    convertFractionToPercent,
    addSuffix,
    handleClickImportActivities() {
      this.isImportActivitiesModalOpen = true;
    },
    handleCloseImportActivitiesModal() {
      this.isImportActivitiesModalOpen = false;
    },
    handleSubmitImportActivities(activities) {
      const shapedActivities = activities.map(a => ({
        ...a,
        quantity: Number(a.quantity),
        costId: this.activeCostId,
        unitValue: this.$ci.parse(a.unitValue || 0, this.currencyOptions),
      }));
      this.newActivities = [...this.newActivities, ...shapedActivities];
      this.isImportActivitiesModalOpen = false;
    },
    handleCancelClick() {
      this.$router.push(`/projects/${this.activeCost.projectId}/costs/${this.activeCostId}/details`);
    },
    async handleSubmitBill(billStatusName) {
      this.apiError = null;
      this.isBillSubmissionInProgress = true;
      let billId = this.activeBillId;

      try {
        if (this.activeBillId) {
          await this.$store.dispatch(BillActions.UPDATE_BILL, {
            billId: this.activeBillId,
            updatedBill: {
              costId: this.activeCostId,
              name: this.billName,
              taxId: this.selectedTaxId,
              billItems: [...this.shapeBillItems()],
              statusId: this.getBillStatusByName(billStatusName).id,
            },
          });
          NotificationService.showNotification(NotificationTypes.UPDATE_BILL.SUCCESS);
        } else {
          billId = await this.$store.dispatch(BillActions.CREATE_BILL, {
            newBill: {
              costId: this.activeCostId,
              ...this.selectedTaxId && { taxId: this.selectedTaxId },
              ...this.billName && { name: this.billName },
              billItems: [...this.shapeBillItems()],
              statusId: this.getBillStatusByName(billStatusName).id,
            },
          });
          NotificationService.showNotification(NotificationTypes.CREATE_BILL.SUCCESS);
        }

        if (this.billDocuments?.length > 0) {
          this.billDocuments.forEach(file => {
            this.$store.dispatch(DocumentActions.CREATE_DOCUMENT, {
              resourceType: 'bill',
              resourceId: billId,
              file,
            });
          });
        }

        this.$router.push(`/projects/${this.activeCost.projectId}/details`);
      } catch (err) {
        this.apiError = err;
      }
      this.isBillSubmissionInProgress = false;
    },
    handleBillBreakdownSubmit(payload) {
      this.billBreakdownItems = [...payload.billBreakdownItems];
      this.isAdvanceTaxed = payload.isAdvanceTaxed;
      this.billName = payload.billName;
      this.selectedTaxId = payload.taxId;
      this.billDocuments = payload.documents;

      this.handleSubmitBill(payload.billStatusType);
    },
    async handleToggleBillBreakdownModal() {
      const isOpening = !this.isBillBreakdownModalOpen;
      if (isOpening) {
        this.isFetchingTaxes = true;
        try {
          await this.$store.dispatch(TaxActions.FETCH_ORGANIZATION_TAXES, { organizationId: this.activeOrganization.id });
        } catch (err) {
          console.error(err);
        }

        this.isFetchingTaxes = false;
      }
      this.isBillBreakdownModalOpen = !this.isBillBreakdownModalOpen;
    },
    handleProgressInputChange(value, activityId) {
      this.advanceInputs[activityId] = value;
    },
    handleAddActivity(activity) {
      console.log(activity);
      this.newActivities.push(activity);
    },
    shapeBillItems() {
      let billItems = [];

      if (this.activeBillId) {
        billItems = this.isAdvanceBillingType ? (
          [
            ...Object.entries(this.advanceInputs)
              .map(([key, value]) => ({
                activityId: key,
                amount: value,
                ...this.getBillItemByActivityAndBillId(key, this.activeBillId) ? {
                  id: this.getBillItemByActivityAndBillId(key, this.activeBillId).id,
                  createdByUserId: this.getBillItemByActivityAndBillId(key, this.activeBillId).createdByUserId,
                  isTaxed: this.isAdvanceTaxed,
                  billItemTypeId: this.getBillItemByActivityAndBillId(key, this.activeBillId).billItemTypeId,
                } : {
                  isTaxed: this.isAdvanceTaxed,
                  billItemTypeId: this.getBillItemTypeByName('advance').id,
                },
              })),
            ...this.billBreakdownItems,
          ]
        ) : (
          this.newActivities.map(activity => {
            const updatedActivity = { ...activity };
            delete updatedActivity.costId;
            return {
              activityId: activity.id,
              amount: activity.id ? this.getActivityBudget(activity.id) : calculateActivityBudget(activity.unitValue, activity.quantity),
              activity: updatedActivity,
              ...this.getBillItemByActivityAndBillId(activity.id, this.activeBillId) ? {
                id: this.getBillItemByActivityAndBillId(activity.id, this.activeBillId).id,
                createdByUserId: this.getBillItemByActivityAndBillId(activity.id, this.activeBillId).createdByUserId,
                isTaxed: this.isAdvanceTaxed,
                billItemTypeId: this.getBillItemByActivityAndBillId(activity.id, this.activeBillId).billItemTypeId,
              } : {
                isTaxed: this.isAdvanceTaxed,
                billItemTypeId: this.getBillItemTypeByName('advance').id,
              },
            };
          })
        );
      } else {
        billItems = this.isAdvanceBillingType ? (
          [
            ...Object.entries(this.advanceInputs).map(([key, value]) => ({
              activityId: key,
              amount: value,
              billItemTypeId: this.getBillItemTypeByName('advance').id,
              isTaxed: this.isAdvanceTaxed,
            })),
            ...this.billBreakdownItems,
          ]
        ) : (
          this.newActivities.map(activity => {
            const updatedActivity = { ...activity };
            delete updatedActivity.costId;
            return {
              activityId: null,
              amount: calculateActivityBudget(activity.unitValue, activity.quantity),
              activity: updatedActivity,
              billItemTypeId: this.getBillItemTypeByName('advance').id,
              isTaxed: !!this.selectedTaxId,
            };
          })
        );
      }

      return billItems;
    },
    initializeAdvanceInput(activityId) {
      let existingBillItem;

      if (this.activeBillId) {
        existingBillItem = this.getBillItemByActivityAndBillId(activityId, this.activeBillId);
      }

      const initialValue = existingBillItem ? existingBillItem.amount : 0;

      return initialValue;
    },
    async fetchInitialData() {
      const billId = this.activeBillId;
      const costId = this.activeCostId;

      if (billId) {
        await this.$store.dispatch(BillActions.FETCH_BILL_BY_ID, { billId });
      } else {
        await this.$store.dispatch(CostActions.FETCH_COST_BY_ID, { costId });
      }

      this.setInitialFormState();
      this.isPageLoading = false;
    },
    setInitialFormState() {
      this.advanceInputs = this.costActivities
        .map(activity => ({ id: activity.id, value: this.initializeAdvanceInput(activity.id) }))
        .reduce((acc, current) => ({ ...acc, [current.id]: current.value }), {});
    },
    initializeActivities() {
      this.newActivities = [...this.getBillActivities(this.activeBillId)];
    },
  },
};
</script>

<style lang="scss">
.percent-advance-button {
  &:not(:last-child) {
    margin-right: $global-whitespace-xsmall;
  }
}

.bill-totals-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.marked-activity-icon {
  position: absolute;
  right: -6px;
  top: -6px;
  z-index: 1;
  background-color: $white;
  border-radius: 100px;
  width: 18px;
  height: 18px;
  box-shadow: $dark-shadow;
}

.create-advance-bill__table__progress-input-column {
  min-width: 400px;
}
</style>
