import Cleave from 'cleave.js';

/**
 * We add a new instance of Cleave when the element
 * is bound and destroy it when it's unbound.
 */
export const cleave = {
  inserted: (el, binding) => {
    const element = el.tagName === 'INPUT' ? el : el.querySelector('input');
    element.cleave = new Cleave(element, binding.value || {});
  },
  update: el => {
    const element = el.tagName === 'INPUT' ? el : el.querySelector('input');
    const event = new Event('input', { bubbles: true });
    requestAnimationFrame(() => {
      element.value = element.cleave.properties.result;
      element.dispatchEvent(event);
    });
  },
  unbind(el) {
    const input = el.tagName === 'INPUT' ? el : el.querySelector('input');
    input.cleave.destroy(); // eslint-disable-line
  },
};

export const masks = {
  numeral: options => ({
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    ...options?.prefix && { prefix: options.prefix },
  }),
  percent: () => ({
    numericOnly: true,
    delimiter: '.',
    blocks: [3, 4],
  }),
};
