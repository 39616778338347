import { baseForm, baseField } from '@/forms/base.form';
// import { validateEmail } from '@/services/Validation.service';

const signUpForm = {
  ...baseForm,
  fields: {
    organizationName: {
      ...baseField,
      label: 'common.formFields.organizationName.name',
      name: 'common.formFields.organizationName.name',
      value: '',
      required: true,
    },
    email: {
      ...baseField,
      label: 'common.formFields.email.name',
      name: 'common.formFields.email.name',
      value: '',
      required: true,
      // validator: validateEmail,
    },
    password: {
      ...baseField,
      label: 'common.formFields.password.name',
      name: 'common.formFields.password.name',
      value: '',
      required: true,
      type: 'password',
    },
  },
};

export default signUpForm;
