import { normalize } from 'normalizr';
import * as Api from '@/services/Api.service';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';
import * as ActivityCategoryMutations from '@/store/mutations/ActivityCategory.mutations';
import * as ActivityCategoryActions from '@/store/actions/ActivityCategory.actions';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as BudgetMutations from '@/store/mutations/Budget.mutations';
import * as schemas from '@/store/schemas';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  activityCategories: {},
});

const receiveActivityCategories = (state, payload) => {
  state.activityCategories = {
    ...state.activityCategories,
    ...payload.activityCategories,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

function deleteActivityCategory(state, payload) {
  const updatedActivityCategories = { ...state.activityCategories };
  delete updatedActivityCategories[payload.activityCategoryId];
  state.activityCategories = { ...updatedActivityCategories };
  state.isFetchingData = false;
  state.isWritingData = false;
}

const state = getInitialState();

const getters = {
  // Placeholder
};

const mutations = {
  [ActivityCategoryMutations.ACTIVITY_CATEGORY_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [ActivityCategoryMutations.ACTIVITY_CATEGORY_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [ActivityCategoryMutations.ACTIVITY_CATEGORY_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [UserMutations.RECEIVE_USERS]: receiveActivityCategories,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveActivityCategories,
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveActivityCategories,
  [ActivityCategoryMutations.RECEIVE_ACTIVITY_CATEGORIES]: receiveActivityCategories,
  [BillMutations.RECEIVE_BILLS]: receiveActivityCategories,
  [BudgetMutations.RECEIVE_BUDGETS]: receiveActivityCategories,
  [ActivityCategoryMutations.DELETE_ACTIVITY_CATEGORY]: deleteActivityCategory,
};

const actions = {
  async [ActivityCategoryActions.FETCH_ORGANIZATION_ACTIVITY_CATEGORIES](context, payload) {
    context.commit(ActivityCategoryMutations.ACTIVITY_CATEGORY_REQUEST_DATA);
    try {
      const { data: { activityCategories } } = await Api.organization.activityCategory.findMany(payload.organizationId);
      const normalizedActivityCategories = normalize(activityCategories, [schemas.activityCategory]);
      context.commit(ActivityCategoryMutations.RECEIVE_ACTIVITY_CATEGORIES, normalizedActivityCategories.entities);
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [ActivityCategoryActions.CREATE_ACTIVITY_CATEGORY](context, payload) {
    context.commit(ActivityCategoryMutations.ACTIVITY_CATEGORY_BEGIN_WRITE_DATA);

    try {
      const { data: { activityCategory } } = await Api.activityCategory.create(payload);
      const normalizedActivityCategory = normalize(activityCategory, schemas.activityCategory);
      context.commit(ActivityCategoryMutations.RECEIVE_ACTIVITY_CATEGORIES, {
        ...normalizedActivityCategory.entities,
      });

      return normalizedActivityCategory.result; // Created cost code category.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ActivityCategoryActions.UPDATE_ACTIVITY_CATEGORY_BY_ID](context, payload) {
    context.commit(ActivityCategoryMutations.ACTIVITY_CATEGORY_BEGIN_WRITE_DATA);
    const { activityCategoryId, updatedActivityCategory } = payload;
    try {
      const { data: { activityCategory } } = await Api.activityCategory.updateOne(activityCategoryId, updatedActivityCategory);
      const normalizedActivityCategory = normalize(activityCategory, schemas.activityCategory);
      context.commit(ActivityCategoryMutations.RECEIVE_ACTIVITY_CATEGORIES, {
        ...normalizedActivityCategory.entities,
      });

      return normalizedActivityCategory.result; // Created cost code category.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ActivityCategoryActions.FETCH_ACTIVITY_CATEGORY_BY_ID](context, payload) {
    context.commit(ActivityCategoryMutations.ACTIVITY_CATEGORY_BEGIN_WRITE_DATA);
    const { id } = payload;
    try {
      const { data: { activityCategory } } = await Api.activityCategory.findOne(id);
      const normalizedActivityCategory = normalize(activityCategory, schemas.activityCategory);
      context.commit(ActivityCategoryMutations.RECEIVE_ACTIVITY_CATEGORIES, {
        ...normalizedActivityCategory.entities,
      });

      return normalizedActivityCategory.result; // Created cost code category.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },

  async [ActivityCategoryActions.DELETE_ACTIVITY_CATEGORY_BY_ID](context, payload) {
    context.commit(ActivityCategoryMutations.ACTIVITY_CATEGORY_BEGIN_WRITE_DATA);
    try {
      await Api.activityCategory.deleteOne(payload.id);

      context.commit(ActivityCategoryMutations.DELETE_ACTIVITY_CATEGORY, { activityCategoryId: payload.id });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
