import { normalize } from 'normalizr';
import * as DocumentMutations from '@/store/mutations/Document.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as DocumentActions from '@/store/actions/Document.actions';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const UPLOAD_STATUS = {
  loading: 'loading',
  success: 'success',
  error: 'error',
};

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  documents: {},
  currentlyUploadingDocuments: {},
});

const receiveDocuments = (state, payload) => {
  state.documents = {
    ...state.documents,
    ...payload.documents,
  };

  state.isFetchingData = false;
  state.isWritingData = false;
};

const initiateUpload = (state, payload) => {
  state.currentlyUploadingDocuments = {
    ...state.currentlyUploadingDocuments,
    [payload.id]: {
      id: payload.id,
      status: UPLOAD_STATUS.loading,
      name: payload.name,
      resourceId: payload.resourceId,
      resourceType: payload.resourceType,
    },
  };
};

const uploadSuccess = (state, payload) => {
  state.currentlyUploadingDocuments = {
    ...state.currentlyUploadingDocuments,
    [payload.id]: { ...state.currentlyUploadingDocuments[payload.id], status: UPLOAD_STATUS.success },
  };

  setTimeout(() => {
    const updatedUploadingDocuments = { ...state.currentlyUploadingDocuments };
    delete updatedUploadingDocuments[payload.id];
    state.currentlyUploadingDocuments = { ...updatedUploadingDocuments };
  }, 4000);
};

const removeDocumentFromQueue = (state, payload) => {
  const updatedUploadingDocuments = { ...state.currentlyUploadingDocuments };
  delete updatedUploadingDocuments[payload.id];
  state.currentlyUploadingDocuments = { ...updatedUploadingDocuments };
};

const uploadError = (state, payload) => {
  state.currentlyUploadingDocuments = {
    ...state.currentlyUploadingDocuments,
    [payload.id]: { ...state.currentlyUploadingDocuments[payload.id], status: UPLOAD_STATUS.error },
  };
};

function deleteDocument(state, payload) {
  const updatedDocuments = { ...state.documents };
  delete updatedDocuments[payload.documentId];
  state.documents = { ...updatedDocuments };
  state.isFetchingData = false;
  state.isWritingData = false;
}

const state = getInitialState();

const getters = {
  getDocumentsByResourceId(state) {
    return resourceId => Object.values(state.documents).filter(doc => doc.resourceId === resourceId);
  },
  getCurrentlyUploadingDocumentsByResourceId(state) {
    return resourceId => Object.values(state.currentlyUploadingDocuments)
      .filter(doc => doc.resourceId === resourceId && doc.status === UPLOAD_STATUS.loading);
  },
};

const mutations = {
  [DocumentMutations.DOCUMENT_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [DocumentMutations.DOCUMENT_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [DocumentMutations.DOCUMENT_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [DocumentMutations.RECEIVE_DOCUMENTS]: receiveDocuments,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveDocuments,
  [UserMutations.RECEIVE_USERS]: receiveDocuments,
  [DocumentMutations.INITIATE_UPLOAD]: initiateUpload,
  [DocumentMutations.UPLOAD_SUCCESS]: uploadSuccess,
  [DocumentMutations.UPLOAD_ERROR]: uploadError,
  [DocumentMutations.REMOVE_DOCUMENT_FROM_QUEUE]: removeDocumentFromQueue,
  [DocumentMutations.DELETE_DOCUMENT]: deleteDocument,
};

const actions = {
  [DocumentActions.FETCH_DOCUMENTS_BY_RESOURCE_ID]: async (context, payload) => {
    context.commit(DocumentMutations.DOCUMENT_REQUEST_DATA);

    const { resourceType, resourceId } = payload;

    try {
      const { data: { documents } } = await Api[resourceType].document.findMany(resourceId);

      const normalizedDocuments = normalize(documents, [schemas.document]);
      context.commit(DocumentMutations.RECEIVE_DOCUMENTS, { ...normalizedDocuments.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  [DocumentActions.FETCH_DOCUMENT_BY_ID]: async (context, payload) => {
    context.commit(DocumentMutations.DOCUMENT_REQUEST_DATA);

    const { resourceType, resourceId, documentId } = payload;

    try {
      const { data: { document } } = await Api[resourceType].document.findOne(resourceId, documentId);

      const normalizedDocuments = normalize(document, schemas.document);
      context.commit(DocumentMutations.RECEIVE_DOCUMENTS, { ...normalizedDocuments.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  [DocumentActions.CREATE_DOCUMENT]: async (context, payload) => {
    context.commit(DocumentMutations.DOCUMENT_BEGIN_WRITE_DATA);

    const { resourceType, resourceId, file } = payload;
    const tempId = Math.random().toString();

    context.commit(DocumentMutations.INITIATE_UPLOAD, {
      id: tempId,
      name: file.name,
      resourceType,
      resourceId,
    });

    try {
      const { data: { document } } = await Api[resourceType].document.create(resourceId, { file });

      const normalizedDocuments = normalize(document, schemas.document);
      context.commit(DocumentMutations.RECEIVE_DOCUMENTS, { ...normalizedDocuments.entities });
      context.commit(DocumentMutations.UPLOAD_SUCCESS, { id: tempId });
      return document.id;
    } catch (err) {
      context.commit(DocumentMutations.UPLOAD_ERROR, { id: tempId });
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  [DocumentActions.DELETE_DOCUMENT_BY_ID]: async (context, payload) => {
    context.commit(DocumentMutations.DOCUMENT_BEGIN_WRITE_DATA);

    const { resourceType, resourceId, documentId } = payload;

    try {
      await Api[resourceType].document.delete(resourceId, documentId);

      context.commit(DocumentMutations.DELETE_DOCUMENT, { documentId: payload.documentId });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
