var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.isTouched && _vm.value)?_c('BIcon',{staticClass:"progress-input-card__marked-activity-icon",attrs:{"icon":"checkbox-marked-circle","type":"is-success","custumSize":"mdi-24px"}}):_vm._e(),_c('div',{staticClass:"card card--dark card--inset"},[_c('div',{staticClass:"card-content u-p-a-small"},[_c('div',{staticClass:"columns u-m-a-0 u-m-b-xsmall"},[_c('small',{staticClass:"column u-p-y-0 has-text-weight-bold"},[_vm._v(_vm._s(_vm.$t('progressInputCard.columnLabels.increment')))]),_c('div',{staticClass:"column u-p-x-0",staticStyle:{"max-width":"100px"}}),_c('small',{staticClass:"column u-p-y-0 has-text-weight-bold"},[_vm._v(_vm._s(_vm.$t('progressInputCard.columnLabels.total')))])]),_vm._l((Object.entries(_vm.progressInput.values)),function(valueType,index){return _c('div',{key:valueType[0],class:[
          'columns',
          'is-mobile',
          'u-m-a-0',
          'is-vcentered',
          { 'u-m-b-xsmall': index !== Object.keys(_vm.progressInput.values).length - 1 }
        ]},[_c('div',{staticClass:"column u-p-a-0 progress-input-card__input-column"},[_c('div',{staticClass:"field"},[_c('div',{class:[
                'control',
                'has-icons-left'
              ]},[((_vm.progressInput.type)==='checkbox')?_c('input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.currencyOptions),expression:"currencyOptions"},{name:"model",rawName:"v-model",value:(valueType[1].increment.value),expression:"valueType[1].increment.value"}],ref:valueType[1].increment.ref,refInFor:true,class:[
                  'input',
                  _vm.getFieldType(valueType[0], 'increment'),
                  'has-text-right',
                  'progress-input-card__input',
                  'is-small'
                ],attrs:{"disabled":_vm.isLoading,"type":"checkbox"},domProps:{"checked":Array.isArray(valueType[1].increment.value)?_vm._i(valueType[1].increment.value,null)>-1:(valueType[1].increment.value)},on:{"change":function($event){var $$a=valueType[1].increment.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(valueType[1].increment, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(valueType[1].increment, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(valueType[1].increment, "value", $$c)}}}}):((_vm.progressInput.type)==='radio')?_c('input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.currencyOptions),expression:"currencyOptions"},{name:"model",rawName:"v-model",value:(valueType[1].increment.value),expression:"valueType[1].increment.value"}],ref:valueType[1].increment.ref,refInFor:true,class:[
                  'input',
                  _vm.getFieldType(valueType[0], 'increment'),
                  'has-text-right',
                  'progress-input-card__input',
                  'is-small'
                ],attrs:{"disabled":_vm.isLoading,"type":"radio"},domProps:{"checked":_vm._q(valueType[1].increment.value,null)},on:{"change":function($event){return _vm.$set(valueType[1].increment, "value", null)}}}):_c('input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.currencyOptions),expression:"currencyOptions"},{name:"model",rawName:"v-model",value:(valueType[1].increment.value),expression:"valueType[1].increment.value"}],ref:valueType[1].increment.ref,refInFor:true,class:[
                  'input',
                  _vm.getFieldType(valueType[0], 'increment'),
                  'has-text-right',
                  'progress-input-card__input',
                  'is-small'
                ],attrs:{"disabled":_vm.isLoading,"type":_vm.progressInput.type},domProps:{"value":(valueType[1].increment.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(valueType[1].increment, "value", $event.target.value)}}}),(valueType[0] === 'currency')?_c('span',{staticClass:"icon is-left"},[_vm._v("$")]):_vm._e(),(valueType[0] === 'units')?_c('span',{staticClass:"icon is-left"},[_vm._v(_vm._s(_vm.activeActivity.unit))]):_vm._e(),(valueType[0] === 'percent')?_c('span',{staticClass:"icon is-left"},[_vm._v("%")]):_vm._e()]),(valueType[1].increment.error)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.$t(valueType[1].increment.error))+" ")]):_vm._e()])]),_c('small',{staticClass:"column has-text-weight-bold u-p-a-0",staticStyle:{"max-width":"100px"}},[_vm._v(_vm._s(valueType[1].increment.label))]),_c('div',{staticClass:"column has-text-center u-p-a-0"},[_c('div',{staticClass:"field"},[_c('div',{class:[
                'control',
                'has-icons-left'
              ]},[((_vm.progressInput.type)==='checkbox')?_c('input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.currencyOptions),expression:"currencyOptions"},{name:"model",rawName:"v-model",value:(valueType[1].total.value),expression:"valueType[1].total.value"}],ref:valueType[1].total.ref,refInFor:true,class:[
                  'input',
                  _vm.getFieldType(valueType[0], 'total'),
                  'has-text-right',
                  'progress-input-card__input',
                  'is-small'
                ],attrs:{"disabled":_vm.isLoading,"type":"checkbox"},domProps:{"checked":Array.isArray(valueType[1].total.value)?_vm._i(valueType[1].total.value,null)>-1:(valueType[1].total.value)},on:{"blur":_vm.handleBlur,"change":function($event){var $$a=valueType[1].total.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(valueType[1].total, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(valueType[1].total, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(valueType[1].total, "value", $$c)}}}}):((_vm.progressInput.type)==='radio')?_c('input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.currencyOptions),expression:"currencyOptions"},{name:"model",rawName:"v-model",value:(valueType[1].total.value),expression:"valueType[1].total.value"}],ref:valueType[1].total.ref,refInFor:true,class:[
                  'input',
                  _vm.getFieldType(valueType[0], 'total'),
                  'has-text-right',
                  'progress-input-card__input',
                  'is-small'
                ],attrs:{"disabled":_vm.isLoading,"type":"radio"},domProps:{"checked":_vm._q(valueType[1].total.value,null)},on:{"blur":_vm.handleBlur,"change":function($event){return _vm.$set(valueType[1].total, "value", null)}}}):_c('input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.currencyOptions),expression:"currencyOptions"},{name:"model",rawName:"v-model",value:(valueType[1].total.value),expression:"valueType[1].total.value"}],ref:valueType[1].total.ref,refInFor:true,class:[
                  'input',
                  _vm.getFieldType(valueType[0], 'total'),
                  'has-text-right',
                  'progress-input-card__input',
                  'is-small'
                ],attrs:{"disabled":_vm.isLoading,"type":_vm.progressInput.type},domProps:{"value":(valueType[1].total.value)},on:{"blur":_vm.handleBlur,"input":function($event){if($event.target.composing){ return; }_vm.$set(valueType[1].total, "value", $event.target.value)}}}),(valueType[0] === 'currency')?_c('span',{staticClass:"icon is-left"},[_vm._v("$")]):_vm._e(),(valueType[0] === 'units')?_c('span',{staticClass:"icon is-left"},[_vm._v(_vm._s(_vm.activeActivity.unit))]):_vm._e(),(valueType[0] === 'percent')?_c('span',{staticClass:"icon is-left"},[_vm._v("%")]):_vm._e()]),(valueType[1].total.error)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.$t(valueType[1].total.error))+" ")]):_vm._e()])])])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }