<template>
  <BTable
    :data="this.tableData"
    hoverable
    detailed
    :mobile-cards="false"
    :show-detail-icon="false"
    detail-key="id"
    ref="activitiesTable"
    :class="['card', customClass]"
    :defaultSort="['name', 'asc']"
    :rowClass="(row) => row.changeOrderId && 'has-background-warning-light'"
  >
    <ActivityConfirmDeleteModal
      :active="!!activityIdConfirmingDelete"
      :activityId="activityIdConfirmingDelete"
      :isDeleting="activityIdConfirmingDelete && activitiesDeleting.includes(activityIdConfirmingDelete)"
      @close="handleCloseDeleteModal"
      @confirmDeleteActivity="() => handleConfirmDeleteActivity(activityIdConfirmingDelete)"
    />
    <BModal
      :active="!!activityIdEditing"
      @close="handleCloseModal"
      :width="800"
    >
        <header class="modal-card-head">
          <p v-if="activityIdEditing" class="modal-card-title">{{ $t('common.ctas.edit') }} {{ stateActivities[activityIdEditing].name }}</p>
          <button
            type="button"
            class="delete"
            @click="handleCloseModal"
          />
        </header>
        <div class="has-background-white">
          <CreateOrEditActivityCard
            :budgetId="budgetId"
            :activityId="activityIdEditing"
            :isDark="false"
            :isInset="false"
            areFieldsStacked
            :hasBorderRadius="false"
            @submitSuccess="handleActivityUpdateSuccess"
          />
        </div>
    </BModal>
    <template slot="empty">
      <div class="has-text-grey u-p-a has-text-centered">
        {{ $t('budgetActivitiesTable.emptyState') }}
      </div>
    </template>
    <BTableColumn
      field="name"
      :label="$t('common.terms.name')"
      width="200"
      cell-class="vertical-align-middle"
      v-slot="props"
      sortable
    >
      <p class="u-m-b-xsmall">
        {{ props.row.name }}
      </p>
      <CostCodeTag :costCodeId="props.row.costCodeId" />
    </BTableColumn>
    <BTableColumn
      field="activityCategory"
      :label="$t('common.terms.category')"
      width="200"
      cell-class="vertical-align-middle"
      v-slot="props"
    >
      <p :class="[{ 'has-text-grey is-italic': !props.row.activityCategoryId }]">
        {{ props.row.activityCategoryId ? activityCategories[props.row.activityCategoryId].name : $t('common.terms.uncategorized') }}
      </p>
    </BTableColumn>
    <BTableColumn
      sortable
      :customSort="sortChangeOrders"
      :label="$t('common.terms.changeOrder')"
      cell-class="vertical-align-middle"
      v-slot="props"
      centered
    >
      <router-link v-if="props.row.changeOrderId" :to="`/projects/${projectId}/change-orders/${props.row.changeOrderId}/details`">
        <BIcon icon="format-list-group-plus" size="is-small" />
        {{ changeOrders[props.row.changeOrderId] ? changeOrders[props.row.changeOrderId].name : '' }}
      </router-link>
      <span v-else class="has-text-grey">
        --
      </span>
    </BTableColumn>
    <BTableColumn
      field="totalBudget"
      :label="$t('common.terms.budgeted')"
      numeric
      cell-class="vertical-align-middle"
      v-slot="props"
    >
      <p>{{ displayCurrency(calculateActivityBudget(props.row.unitValue, props.row.quantity), { prefix: '$', multiplier: 100 }) }}</p>
      <small>
        {{ props.row.quantity }} {{ props.row.unit }} @ {{ displayCurrency(props.row.unitValue, { multiplier: 100, prefix: '$' }) }}
      </small>
    </BTableColumn>
    <BTableColumn
      cell-class="vertical-align-middle"
      width="140"
      v-slot="props"
    >
      <div class="buttons" style="justify-content: center;">
        <BButton
          type="is-primary is-light"
          @click="() => handleClickEditActivity(props.row.id)"
          @keyup.enter="() => handleClickEditActivity(props.row.id)"
        >
          <BIcon icon="pencil" size="mdi-18px" />
        </BButton>
        <BButton
          type="is-primary is-light"
          @click="() => handleClickDeleteActivity(props.row.id)"
          @keyup.enter="() => handleClickDeleteActivity(props.row.id)"
        >
          <BIcon icon="delete" size="mdi-18px" />
        </BButton>
      </div>
    </BTableColumn>
  </BTable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as ActivityActions from '@/store/actions/Activity.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency } from '@/helpers/stringHelpers';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import CostCodeTag from '@/components/CostCodeTag.vue';
import ActivityConfirmDeleteModal from '@/components/ActivityConfirmDeleteModal.vue';

export default {
  name: 'BudgetActivitiesTable',

  components: {
    ActivityConfirmDeleteModal,
    CreateOrEditActivityCard,
    CostCodeTag,
  },

  props: {
    budgetId: {
      type: String,
      required: true,
    },
    /**
     * Pass an array of activities to display. If empty, component will default to
     * getting all activities matching the budgetId from global Vuex state.
     * */
    activities: {
      type: Array,
      required: false,
      default: null,
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      openedDetailsTableRowIds: [], // An array of activity ids for table rows with details opened.
      activitiesDeleting: [],
      activityIdEditing: null,
      activityIdConfirmingDelete: null,
    };
  },

  computed: {
    ...mapState({
      activityCategories: state => state.activityCategory.activityCategories,
      stateActivities: state => state.activity.activities,
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    ...mapGetters([
      'getBudgetActivities',
    ]),
    tableData() {
      return this.activities ? this.activities : this.getBudgetActivities(this.budgetId).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },

  methods: {
    calculateActivityBudget,
    displayCurrency,
    handleClickEditActivity(activityId) {
      this.activityIdEditing = activityId;
    },
    handleCloseModal() {
      this.activityIdEditing = null;
    },
    handleClickDeleteActivity(activityId) {
      this.activityIdConfirmingDelete = activityId;
    },
    handleCloseDeleteModal() {
      this.activityIdConfirmingDelete = null;
    },
    async handleConfirmDeleteActivity(activityId) {
      this.activitiesDeleting = [...this.activitiesDeleting, activityId];
      try {
        await this.$store.dispatch(ActivityActions.DELETE_ACTIVITY_BY_ID, {
          activityId,
        });
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY.SUCCESS);
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY.ERROR);
      }
      this.activitiesDeleting = this.activitiesDeleting.filter(a => a !== activityId);
      this.activityIdConfirmingDelete = null;
    },
    handleActivityUpdateSuccess() {
      this.handleCloseModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_ACTIVITY.SUCCESS);
    },
    sortChangeOrders(a, b, isAsc) {
      const aValue = a.changeOrderId ? this.changeOrders[a.changeOrderId]?.name ?? '' : '';
      const bValue = b.changeOrderId ? this.changeOrders[b.changeOrderId]?.name ?? '' : '';

      if (aValue === '') return 1;
      if (bValue === '') return -1;
      if (aValue === bValue) return 0;

      // eslint-disable-next-line
      return isAsc ? (aValue > bValue ? 1 : -1) : (bValue > aValue ? 1 : -1);
    },
  },
};
</script>
