import Vue from 'vue';
import Buefy from 'buefy';
import * as FullStory from '@fullstory/browser';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import Donut from 'vue-css-donut-chart';
import VueCurrencyInput from 'vue-currency-input';
import 'vue-css-donut-chart/dist/vcdonut.css';
import contenteditableDirective from 'vue-contenteditable-directive';
import VueHtmlToPaper from 'vue-html-to-paper';
import AppConfig from '@/plugins/config/AppConfig';
import { GrowthBookVue } from '@/plugins/GrowthBook';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

// store.subscribeAction(action => {
//   console.table([action.type, action.payload]);
// });

Vue.config.productionTip = false;
const { config } = AppConfig.load();

FullStory.init({
  orgId: '13W2CC',
  devMode: process.env.VUE_APP_ENV !== 'production',
});
Vue.prototype.$FullStory = FullStory;

const vueHtmlToPaperOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  // Use existing document style tags to inject styles into html-to-paper printing result
  styles: [
    ...new Set(
      [...window.document.styleSheets]
        .map(style => (
          style.href !== null
            ? style.href
            : `data:text/css;base64,${btoa(
              unescape(
                encodeURIComponent(
                  [...style.cssRules].map(rule => rule.cssText).join(''),
                ),
              ),
            )}`
        ))
        .filter(s => s !== null && s !== 'data:text/css;base64,'),
    ),
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(AppConfig);
Vue.use(Buefy);
Vue.use(Donut);
Vue.use(VueCurrencyInput);
Vue.use(contenteditableDirective);
Vue.use(VueHtmlToPaper, vueHtmlToPaperOptions);
Vue.use(GrowthBookVue);

let app = '';

firebase.initializeApp(config.firebaseConfig);

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      i18n,
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  }
});
