<template>
  <div class="app-page__container">
    <BillBreakdownModal
      :isOpen="isBillBreakdownModalOpen"
      :activities="getCostActivities(activeCostId)"
      :isSubmissionInProgress="isBillSubmissionInProgress"
      :defaultBillItemType="modalBillItemType"
      @close="handleToggleBillBreakdownModal"
      @submit="handleSubmitNewBill"
    />
    <div v-if="!isPageLoading">
      <BMessage v-if="isProjectArchived(activeProject.id)" type="is-warning">
        {{ $t('common.notifications.archivedProject') }}
      </BMessage>
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h5 class="is-size-5 has-text-grey has-text-weight-bold">
            {{ activeProject.name }}
          </h5>
          <h4 class="title is-4">
            {{ activeCost.name }}
            &nbsp;
            <CostTypeTag :costTypeId="activeCost.costTypeId" class="u-m-l-xsmall" />
          </h4>
          <p v-if="activeCost.vendorId" class="subtitle is-size-6 is-italic has-text-weight-normal">
            <BIcon icon="account-hard-hat" size="is-small" />
            {{ vendors[activeCost.vendorId].name }}
          </p>
        </div>
        <div class="app-page__header__action">
          <div class="buttons is-small is-align-items-flex-start">
            <BButton
              v-if="isLoggedInUserAtLeastProjectAssistant"
              type="is-primary"
              size="is-small"
              outlined
              icon-left="pencil"
              @click="handleClickEditCost"
              @keyup.enter="handleClickEditCost"
            >
              {{ $t('common.ctas.editDetails') }}
            </BButton>
            <BButton
              v-if="isAdvanceBillingType"
              type="is-primary"
              icon-left="format-list-bulleted"
              size="is-small"
              outlined
              @click="handleGoToAdvanceHistory"
              @keyup.enter="handleGoToAdvanceHistory"
            >
              {{ $t('common.terms.billHistory') }}
            </BButton>
            <div v-if="isLoggedInUserAtLeastProjectManager && !isProjectArchived(activeProject.id)">
              <div v-if="isAdvanceBillingType" class="is-flex" style="align-items: middle;">
                <BDropdown v-if="!currentOpenBill" position="is-bottom-left" :mobile-modal="false">
                  <BButton
                    slot="trigger"
                    type="is-primary"
                    size="is-small"
                    icon-left="plus-thick"
                    :disabled="!doesCostHaveActivities"
                  >
                    {{ $t('common.ctas.createBill') }}
                  </BButton>
                  <BDropdown-item
                    v-for="billItemType in billItemTypes"
                    :key="billItemType.id"
                    class="is-size-6 has-text-weight-bold"
                    @click="() => handleClickCreateBill(billItemType.name)"
                    @keyup.enter="() => handleClickCreateBill(billItemType.name)"
                  >
                    {{ $t(`common.billItemTypes.${billItemType.name}`) }}
                  </BDropdown-item>
                </BDropdown>
                <BButton
                  v-else
                  type="is-primary"
                  icon-left="pencil"
                  size="is-small"
                  :disabled="!doesCostHaveActivities"
                  @click="() => handleClickCreateBill('advance')"
                  @keyup.enter="() => handleClickCreateBill('advance')"
                >
                  {{
                    $t('common.ctas.editOpenBill', {
                      billNumber: currentOpenBill.number,
                      billStatusName: $t(`common.billStatuses.${billStatuses[currentOpenBill.statusId].name}`)
                    })
                  }}
                </BButton>
                <BTooltip
                  :label="$t('costDetailsView.noActivitiesInfo')"
                  type="is-white"
                  multilined
                  appendToBody
                  position="is-left"
                >
                  <BIcon
                    v-if="!doesCostHaveActivities"
                    icon="information-outline"
                    class="u-m-l-xsmall"
                    style="margin-top: 8px;"
                  />
                </BTooltip>
              </div>
              <BButton
                v-else-if="isLumpSumBillingType"
                type="is-primary"
                size="is-small"
                icon-left="plus-thick"
                @click="handleClickCreateBill"
                @keyup.enter="handleClickCreateBill"
              >
                {{ $t('common.ctas.createBill') }}
              </BButton>
            </div>
          </div>
        </div>
      </section>
      <section class="app-page__section">
        <div class="columns">
          <div
            v-for="total in costTotals"
            :key="total.key"
            class="column"
          >
            <TotalsSummaryCard
              :title="total.title"
              :tooltipInfo="total.tooltipInfo"
            >
              <div class="card-content u-p-t-0 u-p-b-small u-p-x">
                <div class="title is-size-4 is-size-3-fullhd has-text-weight-black u-m-b-0 has-text-right">
                  <BTooltip
                    multilined
                    appendToBody
                    :active="total.hasTooltip"
                    :triggers="['click']"
                    :autoClose="['outside', 'escape']"
                    position="is-left"
                    type="is-white"
                    class="cost-details-view__total-card__breakdown-tooltip"
                  >
                    <AnimatedNumber
                      :value="total.total"
                      :formatValue="(value) => displayCurrency(value, { prefix: '$', multiplier: 100 })"
                      :duration="400"
                      :class="[{ 'is-clickable is-underlined': total.hasTooltip }]"
                    />
                    <template v-slot:content>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.billItemTypes.advance') }}
                        </div>
                        <div class="column has-text-right">
                          {{ displayCurrency(total.advance, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row ">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.billItemTypes.downPayment') }}
                        </div>
                        <div class="column has-text-right">
                          + {{ displayCurrency(total.downPayment, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row ">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.billItemTypes.retainage') }}
                        </div>
                        <div class="column has-text-right">
                          - {{ displayCurrency(total.retainage, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row ">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.billItemTypes.deduction') }}
                        </div>
                        <div class="column has-text-right">
                          - {{ displayCurrency(total.deduction, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                      <div class="columns is-mobile u-m-a-0 cost-details-view__total-card__row ">
                        <div class="column is-6 has-text-left">
                          {{ $t('common.terms.taxes') }}
                        </div>
                        <div class="column has-text-right">
                          + {{ displayCurrency(total.tax, { prefix: '$', multiplier: 100 }) }}
                        </div>
                      </div>
                    </template>
                  </BTooltip>
                </div>
              </div>
            </TotalsSummaryCard>
          </div>
        </div>
      </section>
      <section class="app-page__section u-m-t-large">
        <div class="app-page__header">
          <div class="app-page__header__title">
            <div v-if="isAdvanceBillingType" style="display: flex; align-items: baseline">
              <h5 class="title is-5 has-text-grey u-m-b-0">
                {{ $t('common.terms.activities') }}
              </h5>
            </div>
            <BTabs v-else type="is-toggle" v-model="activeLumpSumBillingView" class="empty-tabs">
              <BTabItem :label="$t('common.terms.bills')" />
              <BTabItem :label="$t('common.terms.activities')" />
            </BTabs>
          </div>
          <div v-if="isAdvanceBillingType" class="app-page__header__action is-align-items-flex-start">
            <BButton type="is-primary" size="is-small" outlined iconLeft="upload" @click="handleClickImportActivities">
              {{ $t('common.ctas.importActivities') }}
            </BButton>
          </div>
        </div>
        <div v-if="isAdvanceBillingType">
          <CreateOrEditActivityCard
            v-if="isLoggedInUserAtLeastProjectManager && !isProjectArchived(activeProject.id)"
            :costId="activeCostId" class="u-m-b"
          />
          <CostActivitiesTable v-if="getCostActivities" :costId="activeCostId" :projectId="activeProjectId" />
        </div>
        <div v-else-if="isLumpSumBillingType">
          <div>
          <CostBillsTable v-if="activeLumpSumBillingView === 0" :costId="activeCostId" :projectId="activeProjectId" />
          <CostActivitiesTable v-if="getCostActivities && activeLumpSumBillingView === 1" :costId="activeCostId" :projectId="activeProjectId" />
          </div>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';
import { mapState, mapGetters } from 'vuex';
import * as BillActions from '@/store/actions/Bill.actions';
import * as CostActions from '@/store/actions/Cost.actions';
import * as TaxActions from '@/store/actions/Tax.actions';
import * as Types from '@/constants/Types';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as BillStatusConstants from '@/constants/BillStatus.constants';
import * as Flags from '@/constants/Flags';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { calculateAdvanceAmount } from '@/helpers/advanceHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import CostTypeTag from '@/components/CostTypeTag.vue';
import CostActivitiesTable from '@/components/CostActivitiesTable.vue';
import CostBillsTable from '@/components/CostBillsTable.vue';
import BillBreakdownModal from '@/components/BillBreakdownModal.vue';
import TotalsSummaryCard from '@/components/TotalsSummaryCard.vue';

export default {
  name: 'CostDetails',

  components: {
    AnimatedNumber,
    TotalsSummaryCard,
    CreateOrEditActivityCard,
    CostTypeTag,
    CostActivitiesTable,
    CostBillsTable,
    BillBreakdownModal,
  },

  data() {
    return {
      Types,
      isPageLoading: true,
      isBillBreakdownModalOpen: false,
      modalBillItemType: null,
      isBillSubmissionInProgress: false,
      activeLumpSumBillingView: 0,
      isFetchingTaxes: false,
    };
  },

  computed: {
    ...mapState({
      activities: state => state.activity.activities,
      vendors: state => state.vendor.vendors,
      costs: state => state.cost.costs,
      projects: state => state.project.projects,
      billItemTypes: state => state.billItemType.billItemTypes,
      billStatuses: state => state.billStatus.billStatuses,
    }),
    ...mapGetters([
      'activeOrganization',
      'getCostActivities',
      'getBillingTypeByName',
      'getCostTotalAdvanceAmount',
      'getBillStatusByName',
      'getOpenBillsByCostId',
      'getUnapprovedOpenBillsByCostId',
      'getOpenBillItemsByCostId',
      'getClosedBillItemsByCostId',
      'getCostTotalActivityBudget',
      'getCostTotalClosedAmount',
      'getCostTotalClosedTaxAmount',
      'getCostTotalOpenTaxAmount',
      'getCostTotalDownPaymentAmount',
      'getCostTotalRetainageAmount',
      'getCostTotalDeductionAmount',
      'isLoggedInUserAtLeastProjectManager',
      'isLoggedInUserAtLeastProjectAssistant',
      'isProjectArchived',
    ]),
    isAdvanceBillingType() {
      return this.activeCost.billingTypeId === this.getBillingTypeByName(Types.billingTypes.advance).id;
    },
    isLumpSumBillingType() {
      return this.activeCost.billingTypeId === this.getBillingTypeByName(Types.billingTypes.lumpSum).id;
    },
    doesCostHaveActivities() {
      return this.getCostActivities(this.activeCostId).length > 0;
    },
    activeProjectId() {
      return this.$route.params.projectId;
    },
    activeProject() {
      return this.projects[this.activeProjectId];
    },
    activeCostId() {
      return this.$route.params.costId;
    },
    activeCost() {
      return this.costs[this.activeCostId];
    },
    currentOpenBill() {
      // Some orgs want to be able to open bills when the previous one is APPROVED but not necessarily CLOSED.
      // The default workflow is to only allow creating a new bill when the previous one is CLOSED.
      const openBill = this.$GrowthBook.isOn(Flags.OPEN_BILL_WHEN_PREVIOUS_APPROVED)
        ? this.getUnapprovedOpenBillsByCostId(this.activeCostId)[0]
        : this.getOpenBillsByCostId(this.activeCostId)[0];

      return openBill;
    },
    costTotals() {
      return [
        {
          title: this.$t('common.terms.contracted'),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.contracted'),
          total: this.getCostTotalActivityBudget(this.activeCostId),
          hasTooltip: false,
        },
        {
          title: this.$t('common.terms.inOpenBills'),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.inOpenBills'),
          advance: this.getCostTotalAdvanceAmount(this.activeCostId, { excludedBillStatusNames: [Types.billStatusTypes.closed] }),
          downPayment: this.getCostTotalDownPaymentAmount(this.activeCostId, { excludedBillStatusNames: [Types.billStatusTypes.closed] }),
          retainage: Math.abs(this.getCostTotalRetainageAmount(this.activeCostId, { excludedBillStatusNames: [Types.billStatusTypes.closed] })),
          deduction: Math.abs(this.getCostTotalDeductionAmount(this.activeCostId, { excludedBillStatusNames: [Types.billStatusTypes.closed] })),
          total: (
            this.getOpenBillItemsByCostId(this.activeCostId).reduce((acc, curr) => (acc + curr.amount), 0)
            + this.getCostTotalOpenTaxAmount(this.activeCostId)
          ),
          tax: this.getCostTotalOpenTaxAmount(this.activeCostId),
          hasTooltip: true,
        },
        {
          title: this.$t('common.terms.totalClosed'),
          // amount: this.getCostTotalClosedAmount(this.activeCostId),
          tooltipInfo: this.$t('costDetailsView.summaryInfo.totalClosed'),
          advance: this.getCostTotalAdvanceAmount(this.activeCostId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] }),
          downPayment: this.getCostTotalDownPaymentAmount(this.activeCostId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] }),
          retainage: Math.abs(this.getCostTotalRetainageAmount(this.activeCostId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] })),
          deduction: Math.abs(this.getCostTotalDeductionAmount(this.activeCostId, { excludedBillStatusNames: [...BillStatusConstants.openBillStatusNames] })),
          tax: this.getCostTotalClosedTaxAmount(this.activeCostId),
          total: (
            this.getClosedBillItemsByCostId(this.activeCostId).reduce((acc, curr) => (acc + curr.amount), 0)
            + this.getCostTotalClosedTaxAmount(this.activeCostId)
          ),
          hasTooltip: true,
        },
      ];
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    displayCurrency,
    calculateAdvanceAmount,
    convertFractionToPercent,
    addSuffix,
    handleClickEditCost() {
      this.$router.push(`/projects/${this.activeProjectId}/costs/${this.activeCostId}/edit`);
    },
    handleClickImportActivities() {
      this.$router.push(`/projects/${this.activeProjectId}/costs/${this.activeCostId}/activities/import`);
    },
    handleClickCreateBill(billItemType) {
      if (this.isAdvanceBillingType) {
        if (billItemType === 'advance') {
          const billId = this.currentOpenBill?.id;
          if (billId) {
            this.$router.push(`/projects/${this.activeCost.projectId}/costs/${this.activeCostId}/bills/${billId}/edit-draft`);
          } else {
            this.$router.push(`/projects/${this.activeCost.projectId}/costs/${this.activeCostId}/create-advance-bill`);
          }
        } else {
          this.handleToggleBillBreakdownModal();
          this.modalBillItemType = billItemType;
        }
      } else if (this.isLumpSumBillingType) {
        this.$router.push(`/projects/${this.activeCost.projectId}/costs/${this.activeCostId}/create-lump-sum-bill`);
      }
    },
    async handleToggleBillBreakdownModal() {
      const isOpening = !this.isBillBreakdownModalOpen;
      if (isOpening) {
        this.isFetchingTaxes = true;
        try {
          await this.$store.dispatch(TaxActions.FETCH_ORGANIZATION_TAXES, { organizationId: this.activeOrganization.id });
        } catch (err) {
          console.error(err);
        }

        this.isFetchingTaxes = false;
      }
      this.isBillBreakdownModalOpen = !this.isBillBreakdownModalOpen;
    },
    async handleSubmitNewBill(payload) {
      this.isBillSubmissionInProgress = true;

      const billBreakdownItems = [...payload.billBreakdownItems];
      const {
        billName,
        taxId,
      } = payload;
      try {
        await this.$store.dispatch(BillActions.CREATE_BILL, {
          newBill: {
            costId: this.activeCostId,
            ...taxId && { taxId },
            ...billName && { name: billName },
            billItems: [...billBreakdownItems],
            statusId: this.getBillStatusByName(Types.billStatusTypes.pendingApproval).id,
          },
        });
        this.handleToggleBillBreakdownModal();
        NotificationService.showNotification(NotificationTypes.CREATE_BILL.SUCCESS);
      } catch (err) {
        NotificationService.showNotification(NotificationTypes.CREATE_BILL.ERROR);
      }

      this.isBillSubmissionInProgress = false;
    },
    async fetchInitialData() {
      const costId = this.activeCostId;
      await this.$store.dispatch(CostActions.FETCH_COST_BY_ID, { costId });

      this.isPageLoading = false;
    },
    handleTouchForm() {
      console.log('form touched');
    },
    handleGoToAdvanceHistory() {
      this.$router.push(`/projects/${this.activeProjectId}/costs/${this.activeCostId}/bills`);
    },
  },
};
</script>

<style lang="scss">
.cost-details-view__total-card__row {
  padding: ($global-whitespace-xsmall / 2);

  &:not(:last-child) {
    border-bottom: 1px solid $grey-lightest;
  }

  &:hover {
    background: $white-ter;
  }
}

.cost-details-view__total-card__breakdown-tooltip {
  > .tooltip-content {
    padding: 0;
  }
}
</style>
