import { normalize } from 'normalizr';
import * as Api from '@/services/Api.service';
import * as SubscriptionMutations from '@/store/mutations/Subscription.mutations';
import * as SubscriptionActions from '@/store/actions/Subscription.actions';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as schemas from '@/store/schemas';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  subscriptions: {},
});

const receiveSubscriptions = (state, payload) => {
  state.subscriptions = {
    ...state.subscriptions,
    ...payload.subscriptions,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

const state = getInitialState();

const getters = {
  getOrganizationSubscription(state, getters, rootState) {
    const organizationId = rootState.organization.activeOrganizationId;

    return Object.values(state.subscriptions).find(s => s.organizationId === organizationId);
  },
};

const mutations = {
  [SubscriptionMutations.SUBSCRIPTION_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [SubscriptionMutations.SUBSCRIPTION_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [SubscriptionMutations.SUBSCRIPTION_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [UserMutations.RECEIVE_USERS]: receiveSubscriptions,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveSubscriptions,
  [SubscriptionMutations.RECEIVE_SUBSCRIPTIONS]: receiveSubscriptions,
};

const actions = {
  async [SubscriptionActions.FETCH_ORGANIZATION_SUBSCRIPTIONS](context, payload) {
    context.commit(SubscriptionMutations.SUBSCRIPTION_REQUEST_DATA);
    try {
      const { data: { subscriptions } } = await Api.organization.subscription.findMany(payload.organizationId);
      const normalizedSubscriptions = normalize(subscriptions, [schemas.subscription]);
      context.commit(SubscriptionMutations.RECEIVE_SUBSCRIPTIONS, normalizedSubscriptions.entities);
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [SubscriptionActions.UPDATE_SUBSCRIPTION_BY_ID](context, payload) {
    context.commit(SubscriptionMutations.SUBSCRIPTION_BEGIN_WRITE_DATA);
    const { subscriptionId, updatedSubscription } = payload;
    try {
      const { data: { subscription } } = await Api.subscription.updateOne(subscriptionId, updatedSubscription);
      const normalizedSubscription = normalize(subscription, schemas.subscription);
      context.commit(SubscriptionMutations.RECEIVE_SUBSCRIPTIONS, {
        ...normalizedSubscription.entities,
      });

      return normalizedSubscription.result; // Created cost code category.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
