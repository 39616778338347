import { render, staticRenderFns } from "./Vendors.view.vue?vue&type=template&id=055a03a1&scoped=true&"
import script from "./Vendors.view.vue?vue&type=script&lang=js&"
export * from "./Vendors.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055a03a1",
  null
  
)

export default component.exports