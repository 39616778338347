<template>
  <div id="forgot-password-view" class="app-page__container">
    <div v-if="!isPageLoading" class="form-container">
      <h3 class="title is-3 has-text-centered">Recover Your Password</h3>
      <form
        v-if="isSendCodeFormVisible"
        @submit.prevent="handleSubmitSendResetPasswordCode"
      >
        <BField v-if="resetPasswordError">
          <BMessage type="is-danger">
            {{ resetPasswordError.message }}
          </BMessage>
        </BField>
        <BField v-if="sendResetPasswordCodeError">
          <BMessage type="is-danger">
            {{ sendResetPasswordCodeError.message }}
          </BMessage>
        </BField>
        <BField v-if="verifyPasswordResetCodeError">
          <BMessage type="is-danger">
            {{ verifyPasswordResetCodeError.message }}
          </BMessage>
        </BField>
        <BField
          :label="sendResetPasswordCodeForm.userEmail.label"
          :type="sendResetPasswordCodeFormErrors[sendResetPasswordCodeForm.userEmail.name] ? 'is-danger' : ''"
          :message="sendResetPasswordCodeFormErrors[sendResetPasswordCodeForm.userEmail.name] || ''"
        >
          <BInput
            v-model="sendResetPasswordCodeForm.userEmail.value"
            :disabled="isSendResetPasswordCodeInProgress"
          />
        </BField>
        <BButton
          v-if="!isPasswordResetCodeSent"
          native-type="submit"
          type="is-primary"
          class="is-fullwidth"
          :loading="isSendResetPasswordCodeInProgress"
          :disabled="isSendResetPasswordCodeInProgress"
        >
          Send me a link to reset my password
        </BButton>
      </form>
      <p v-else-if="isPasswordResetCodeSent" class="has-text-centered">We've sent you a link to reset your password</p>
      <form
        v-if="isResetPasswordFormVisible"
        @submit.prevent="handleSubmitResetPassword"
      >
        <BField v-if="resetPasswordError">
          <BMessage type="is-danger">
            {{ resetPasswordError.message }}
          </BMessage>
        </BField>
        <BField
          label="Password"
          :type="resetPasswordFormErrors[resetPasswordForm.userPassword.name] ? 'is-danger' : ''"
          :message="resetPasswordFormErrors[resetPasswordForm.userPassword.name] || ''"
        >
          <BInput
            v-model="resetPasswordForm.userPassword.value"
            type="password"
            password-reveal
            :disabled="isResetPasswordFormInProgress"
          />
        </BField>
        <BButton
          native-type="submit"
          type="is-primary"
          class="is-fullwidth"
          :loading="isResetPasswordFormInProgress"
          :disabled="isResetPasswordFormInProgress"
        >
          Reset Password
        </BButton>
      </form>
      <div v-else-if="isPasswordResetComplete" class="form-container">
        <h3 class="title is-3">Your password has been reset successfully!</h3>
          <p class="is-size-5 u-m-b-large">
            We're redirecting you to your account in: <span class="has-text-primary">{{ secondsUntilRedirect }} seconds</span>.
          </p>
      </div>
    </div>
    <BLoading is-full-page :active.sync="isPageLoading" :can-cancel="false"></BLoading>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import * as AuthActions from '@/store/actions/Auth.actions';
import * as UserActions from '@/store/actions/User.actions';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as ValidationService from '@/services/Validation.service';
import { shapeUserDisplayName } from '@/helpers/userHelpers';
import { fetchOrganizationRequiredData } from '@/helpers/organizationHelpers';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'ForgotPassword',

  data() {
    return {
      resetPasswordForm: {
        userPassword: {
          name: 'Password',
          value: '',
          required: true,
        },
      },
      resetPasswordFormErrors: {},
      resetPasswordError: null,
      isResetPasswordFormInProgress: false,
      sendResetPasswordCodeForm: {
        userEmail: {
          name: 'Email',
          label: 'Email',
          value: '',
          required: true,
        },
      },
      sendResetPasswordCodeFormErrors: {},
      sendResetPasswordCodeError: null,
      isSendResetPasswordCodeInProgress: false,
      verifyPasswordResetCodeError: null,
      isPasswordResetCodeSent: false,
      userEmail: null,
      isPageLoading: true,
      isPasswordResetComplete: false,
      secondsUntilRedirect: 3,
      redirectInterval: null,
    };
  },

  computed: {
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
    ]),
    ...mapState({
      authUser: state => state.auth.authUser,
    }),
    logInPayload() {
      return {
        userEmail: this.resetPasswordForm.userEmail.value,
        userPassword: this.resetPasswordForm.userPassword.value,
      };
    },
    isSendCodeFormVisible() {
      return (
        !this.isPasswordResetCodeSent
        && (
          !this.$route.query.oobCode
          || this.verifyPasswordResetCodeError
          || (this.resetPasswordError && this.resetPasswordError.message && this.resetPasswordError.message.includes('INVALID_OOB_CODE'))
        )
      );
    },
    isResetPasswordFormVisible() {
      return (
        this.$route.query.oobCode
        && !this.isPasswordResetComplete
        && (
          !this.verifyPasswordResetCodeError
          || (this.resetPasswordError && this.resetPasswordError.message && !this.resetPasswordError.message.includes('INVALID_OOB_CODE'))
        )
      );
    },
  },

  created() {
    this.getInitialState();
  },

  beforeDestroy() {
    if (this.redirectInterval) clearInterval(this.redirectInterval);
  },

  methods: {
    isObjectEmpty,
    async getInitialState() {
      if (this.$route.query.oobCode) {
        try {
          this.userEmail = await this.$store.dispatch(
            AuthActions.VERIFY_PASSWORD_RESET_CODE,
            { actionCode: this.$route.query.oobCode },
          );
        } catch (err) {
          this.verifyPasswordResetCodeError = err;
        }
      }

      this.isPageLoading = false;
    },
    async handleSubmitResetPassword() {
      this.resetPasswordErrors = null;
      this.resetPasswordFormErrors = ValidationService.runFormValidation({ ...this.resetPasswordForm });
      if (!isObjectEmpty(this.resetPasswordFormErrors)) return;

      this.isResetPasswordFormInProgress = true;
      try {
        await this.$store.dispatch(
          AuthActions.CONFIRM_PASSWORD_RESET,
          { actionCode: this.$route.query.oobCode, newPassword: this.resetPasswordForm.userPassword.value },
        );
        await this.$store.dispatch(
          AuthActions.LOG_IN_WITH_EMAIL_AND_PASSWORD,
          { userEmail: this.userEmail, userPassword: this.resetPasswordForm.userPassword.value },
        );
        const userId = await this.$store.dispatch(UserActions.FETCH_USER_BY_ID, { userId: this.authUser.uid });
        this.$store.commit(UserMutations.SET_LOGGED_IN_USER, userId);
        this.$store.commit(OrganizationMutations.SET_ACTIVE_ORGANIZATION, this.loggedInUser.organizationId);

        await fetchOrganizationRequiredData(this.activeOrganization.id);

        this.$FullStory.identify(this.loggedInUser.id, {
          displayName: shapeUserDisplayName(this.loggedInUser),
          email: this.loggedInUser.email,
        });

        this.$GrowthBook.setAttributes({
          userId: this.loggedInUser.id,
          organizationId: this.loggedInUser.organizationId,
        });

        this.isPasswordResetComplete = true;
        this.isResetPasswordFormInProgress = false;
        this.handleRedirect();
      } catch (err) {
        this.resetPasswordError = err;
        this.isResetPasswordFormInProgress = false;
      }
    },
    async handleSubmitSendResetPasswordCode() {
      this.sendResetPasswordCodeFormErrors = null;
      this.sendResetPasswordCodeError = null;
      this.sendResetPasswordCodeFormErrors = ValidationService.runFormValidation({ ...this.sendResetPasswordCodeForm });
      if (!isObjectEmpty(this.sendResetPasswordCodeFormErrors)) return;

      try {
        this.sendResetPasswordCodeError = null;
        this.isSendResetPasswordCodeInProgress = true;
        await this.$store.dispatch(
          AuthActions.SEND_PASSWORD_RESET_EMAIL,
          { userEmail: this.sendResetPasswordCodeForm.userEmail.value },
        );
        this.isSendResetPasswordCodeInProgress = false;
        this.isPasswordResetCodeSent = true;
      } catch (err) {
        this.isSendResetPasswordCodeInProgress = false;
        this.sendResetPasswordCodeError = err;
      }
    },
    handleRedirect() {
      this.redirectInterval = setInterval(() => {
        if (this.secondsUntilRedirect > 0) {
          this.secondsUntilRedirect -= 1;
        } else {
          this.$router.push('/');
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  margin: 0 auto;
  @media screen and (min-width: $tablet) {
    max-width: 500px;
  }
}
</style>
