<template>
  <BTag v-if="costCodeId && costCode" type="is-primary is-light" :size="size">
    <BIcon icon="tag" :custom-size="iconSize" />
    <span>
      {{ costCode.code }} - {{ costCode.name || ''}}
    </span>
  </BTag>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CostCodeTag',

  props: {
    costCodeId: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      validator(propValue) {
        return ['is-small', 'is-medium', 'is-large'].indexOf(propValue) !== -1;
      },
      default: 'is-small',
    },
  },

  computed: {
    ...mapState({
      costCodes: state => state.costCode.costCodes,
    }),
    costCode() {
      return this.costCodes[this.costCodeId];
    },
    iconSize() {
      let size = 'mdi-12px';

      if (this.size === 'is-medium') size = 'mdi-18px';
      if (this.size === 'is-large') size = 'mdi-24px;';

      return size;
    },
  },
};
</script>
