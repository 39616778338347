import { normalize } from 'normalizr';
import * as schemas from '@/store/schemas';
import * as ActivityActions from '@/store/actions/Activity.actions';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';
import * as ActivityCategoryMutations from '@/store/mutations/ActivityCategory.mutations';
import * as CostMutations from '@/store/mutations/Cost.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as BudgetMutations from '@/store/mutations/Budget.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as CostCodeMutations from '@/store/mutations/CostCode.mutations';
import * as ChangeOrderMutations from '@/store/mutations/ChangeOrder.mutations';
import * as Api from '@/services/Api.service';
import { calculateActivityBudget } from '@/helpers/activityHelpers';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  activities: {},
});

function receiveActivities(state, payload) {
  state.activities = {
    ...state.activities,
    ...payload.activities,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
}

function deleteActivity(state, payload) {
  const updatedActivities = { ...state.activities };
  delete updatedActivities[payload.activityId];
  state.activities = { ...updatedActivities };
  state.isFetchingData = false;
  state.isWritingData = false;
}

const state = getInitialState();

const getters = {
  getCostActivities(state) {
    return costId => Object.values(state.activities)
      .filter(activity => activity.costId === costId);
  },
  getBudgetActivities(state) {
    return budgetId => Object.values(state.activities)
      .filter(activity => activity.budgetId === budgetId);
  },
  getChangeOrderActivities(state) {
    return changeOrderId => Object.values(state.activities)
      .filter(activity => activity.changeOrderId === changeOrderId);
  },
  getChangeOrderBudgetActivities(state, getters) {
    return changeOrderId => getters.getChangeOrderActivities(changeOrderId).filter(co => co.budgetId);
  },
  getChangeOrderCostActivities(state, getters) {
    return changeOrderId => getters.getChangeOrderActivities(changeOrderId).filter(co => co.costId);
  },
  getBillActivities(state, getters, rootState) {
    return billId => Object.values(rootState.billItem.billItems)
      .filter(billItem => billItem.billId === billId)
      .map(billItem => state.activities[billItem.activityId]);
  },
  getActivityTotalAdvanceAmount(state, getters, rootState) {
    const bills = rootState.bill.bills; // eslint-disable-line prefer-destructuring
    return (activityId, options = { excludedBillStatuses: [] }) => Object.values(rootState.billItem.billItems)
      .filter(billItem => (
        billItem.activityId === activityId
        && !options.excludedBillStatuses.includes(rootState.billStatus.billStatuses[bills[billItem.billId].statusId].name)
      ))
      .reduce((total, billItem) => total + billItem.amount, 0);
  },
  getActivityTotalAdvancePercent(state, getters) {
    return (activityId, options) => {
      const activity = state.activities[activityId];
      const budget = calculateActivityBudget(activity.unitValue, activity.quantity);
      return getters.getActivityTotalAdvanceAmount(activityId, options) / budget;
    };
  },
  getActivityRemainingBalanceAmount(state, getters) {
    return (activityId, options = { excludedBillStatuses: [] }) => (
      getters.getActivityBudget(activityId) - getters.getActivityTotalAdvanceAmount(activityId, options)
    );
  },
  getActivityRemainingBalancePercent(state, getters) {
    return (activityId, options = { excludedBillStatuses: [] }) => (
      (getters.getActivityBudget(activityId) - getters.getActivityTotalAdvanceAmount(activityId, options))
      / getters.getActivityBudget(activityId)
    );
  },
  getActivityTotalAdvanceUnits(state, getters) {
    return (activityId, options) => {
      const activity = state.activities[activityId];
      return getters.getActivityTotalAdvancePercent(activityId, options) * activity.quantity;
    };
  },
  getActivityBudget(state) {
    return activityId => {
      const activity = state.activities[activityId];
      return calculateActivityBudget(activity.unitValue, activity.quantity);
    };
  },
};

const mutations = {
  [ActivityMutations.ACTIVITY_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [ActivityMutations.ACTIVITY_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [ActivityMutations.ACTIVITY_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveActivities,
  [CostMutations.RECEIVE_COSTS]: receiveActivities,
  [BillMutations.RECEIVE_BILLS]: receiveActivities,
  [BudgetMutations.RECEIVE_BUDGETS]: receiveActivities,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveActivities,
  [CostCodeMutations.RECEIVE_COST_CODES]: receiveActivities,
  [ActivityCategoryMutations.RECEIVE_ACTIVITY_CATEGORIES]: receiveActivities,
  [ChangeOrderMutations.RECEIVE_CHANGE_ORDERS]: receiveActivities,
  [ActivityMutations.DELETE_ACTIVITY]: deleteActivity,
};

const actions = {
  async [ActivityActions.CREATE_ACTIVITY](context, payload) {
    context.commit(ActivityMutations.ACTIVITY_BEGIN_WRITE_DATA);
    try {
      const { data: { activity } } = await Api.activity.create({ ...payload.newActivity });
      const normalizedActivity = normalize(activity, schemas.activity);

      context.commit(ActivityMutations.RECEIVE_ACTIVITIES, normalizedActivity.entities);

      return normalizedActivity.result;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [ActivityActions.DELETE_ACTIVITY_BY_ID](context, payload) {
    context.commit(ActivityMutations.ACTIVITY_BEGIN_WRITE_DATA);
    try {
      await Api.activity.deleteOne(payload.activityId);

      context.commit(ActivityMutations.DELETE_ACTIVITY, { activityId: payload.activityId });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [ActivityActions.UPDATE_ACTIVITY_BY_ID](context, payload) {
    context.commit(ActivityMutations.ACTIVITY_BEGIN_WRITE_DATA);
    try {
      const { activityId, updatedActivity } = payload;
      const { data: { activity } } = await Api.activity.updateOne(activityId, updatedActivity);
      const normalizedActivity = normalize(activity, schemas.activity);

      context.commit(ActivityMutations.RECEIVE_ACTIVITIES, normalizedActivity.entities);

      return normalizedActivity.result;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [ActivityActions.FETCH_ACTIVITY_BILL_ITEMS](context, payload) {
    context.commit(ActivityMutations.ACTIVITY_REQUEST_DATA);
    try {
      const { activityId } = payload;
      const { data: { billItems } } = await Api.activity.billItem.findMany(activityId);
      const normalizedBillItems = normalize(billItems, [schemas.billItem]);

      context.commit(ActivityMutations.RECEIVE_ACTIVITIES, normalizedBillItems.entities);

      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },

  async [ActivityActions.CREATE_ACTIVITIES](context, payload) {
    context.commit(ActivityMutations.ACTIVITY_BEGIN_WRITE_DATA);
    try {
      await Api.activity.createMany({ ...payload });
      context.commit(ActivityMutations.ACTIVITY_COMPLETE_WRITE_DATA);
      return;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
