<template>
  <div>
    <div v-if="error" class="app-page__container is-flex is-flex-direction-column is-justify-content-center is-align-items-center" style="max-width: 600px; height: 100vh;">
      <div class="u-m-b" style="width: 250px;">
        <TheLogo />
      </div>
      <img src="../assets/images/product-illustrations/error-warning.svg" style="height: 280px;" />
      <div class="has-text-centered u-m-y-large is-size-5">
        {{ $t('errorBoundary.bodyText') }}
      </div>
      <div class="has-text-centered is-flex is-justify-content-center">
        <div class="buttons">
          <BButton size="is-large" type="is-primary" outlined @click="handleClickTakeMeHome" @keyup.enter="handleClickTakeMeHome">
            {{ $t('errorBoundary.takeMeHomeCta') }}
          </BButton>
          <BButton size="is-large" type="is-primary" @click="handleClickReloadPage" @keyup.enter="handleClickReloadPage">
            {{ $t('errorBoundary.reloadPageCta') }}
          </BButton>
        </div>
      </div>
    </div>
    <slot v-else></slot>
  </div>
</template>

<script>
import TheLogo from '@/components/TheLogo.vue';

export default {
  name: 'ErrorBoundary',

  components: {
    TheLogo,
  },

  data() {
    return {
      error: null,
    };
  },

  watch: {
    $route() {
      this.error = null;
    },
  },

  errorCaptured(err) {
    this.error = err;
    console.error(err);
  },

  methods: {
    handleClickTakeMeHome() {
      this.$router.push('/');
    },
    handleClickReloadPage() {
      this.$router.go();
    },
  },
};
</script>
