import * as TypeMutations from '@/store/mutations/Type.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  subscriptionTypes: {},
});

const receiveSubscriptionTypes = (state, payload) => {
  state.subscriptionTypes = {
    ...state.subscriptionTypes,
    ...payload.subscriptionTypes,
  };
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  getSubscriptionTypeByName(state) {
    return name => Object.values(state.subscriptionTypes).find(s => s.name === name);
  },
};

const mutations = {
  [TypeMutations.RECEIVE_TYPES]: receiveSubscriptionTypes,
  [OrganizationMutations.RECEIVE_ORGANIZATIONS]: receiveSubscriptionTypes,
  [UserMutations.RECEIVE_USERS]: receiveSubscriptionTypes,
};

const actions = {
  // Placeholder
};

export default {
  state,
  getters,
  actions,
  mutations,
};
