<template>
  <div class="app-page__container">
    <CreateOrEditVendorModal
      :isOpen="isAddVendorModalOpen"
      @close="handleCloseAddVendorModal"
      @submitSuccess="handleCreateVendorSuccess"
    />
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.vendors') }}</h4>
        </div>
        <div v-if="isLoggedInUserAtLeastProjectManager" class="app-page__header__action">
          <BButton
            type="is-primary"
            outlined
            @click="handleClickCreateVendor"
            @keyup.enter="handleClickCreateVendor"
          >
            <BIcon icon="plus-thick" class="u-m-r-xsmall" customSize="mdi-18px" />
            {{ $t('common.ctas.createVendor') }}
          </BButton>
        </div>
      </section>

      <section class="app-page__section">
        <div class="columns u-m-b-0 is-multiline is-vcentered is-reverse-mobile">
          <div class="column u-p-b-0">
            <div class="has-text-weight-bold">
              {{ $t('vendorsView.vendorSearchField.foundText', { total }) }}
              {{ search ? $t('vendorsView.vendorSearchField.matching', { search }) : '' }}
            </div>
          </div>
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <BInput
              icon="magnify"
              v-model="search"
              @input="handleSearchInput"
              :placeholder="$t('vendorsView.vendorSearchField.placeholder')"
            />
          </div>
        </div>
        <VendorsTable
          :isLoading="isTableLoading"
          :tableData="tableData"
          :pageSize="pageSize"
          :total="total"
          @pageChange="handlePageChange"
          @sort="handleSort"
          @updateVendor="handleUpdateVendor"
          @deleteVendor="handleDeleteVendor"
        />
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as VendorActions from '@/store/actions/Vendor.actions';
import CreateOrEditVendorModal from '@/components/CreateOrEditVendorModal.vue';
import VendorsTable from '@/components/VendorsTable.vue';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';

export default {
  name: 'Vendors',

  components: {
    CreateOrEditVendorModal,
    VendorsTable,
  },

  data() {
    return {
      isPageLoading: false,
      isTableLoading: true,
      isAddVendorModalOpen: false,
      pageSize: 20,
      pageNumber: 1,
      sortBy: 'name',
      sortDirection: 'asc',
      tableData: [],
      total: 0,
      search: '',
      searchDebounceTimeout: null,
    };
  },

  computed: {
    ...mapState({
      vendors: state => state.vendor.vendors,
    }),
    ...mapGetters([
      'activeOrganization',
      'isLoggedInUserAtLeastProjectManager',
    ]),
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    handleClickCreateVendor() {
      this.toggleVendorModal();
    },
    handleCreateVendorSuccess() {
      this.toggleVendorModal();
      this.fetchInitialData();
    },
    handleCloseAddVendorModal() {
      this.toggleVendorModal();
    },
    handleUpdateVendor() {
      this.fetchInitialData();
    },
    handleDeleteVendor() {
      this.fetchInitialData();
    },
    toggleVendorModal() {
      this.isAddVendorModalOpen = !this.isAddVendorModalOpen;
    },
    async fetchInitialData() {
      this.isTableLoading = true;

      try {
        const { vendorIds, vendorCount } = await this.$store.dispatch(VendorActions.FETCH_ORGANIZATION_VENDORS, {
          organizationId: this.activeOrganization.id,
          params: {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortDirection: this.sortDirection,
            sortBy: this.sortBy,
            ...this.search && { search: this.search },
          },
        });

        this.tableData = vendorIds.map(id => this.vendors[id]);
        this.total = vendorCount;
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isTableLoading = false;
      this.isPageLoading = false;
    },
    async handlePageChange(page) {
      this.pageNumber = page;
      await this.fetchInitialData();
    },
    async handleSort({ field, order }) {
      this.sortBy = field;
      this.sortDirection = order;
      await this.fetchInitialData();
    },
    handleSearchInput() {
      this.pageNumber = 1;
      clearTimeout(this.searchDebounceTimeout);
      this.searchDebounceTimeout = setTimeout(() => {
        this.fetchInitialData();
      }, 200);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
