var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'user-avatar__wrapper',
    ("user-avatar__wrapper--" + _vm.size) ]},[_c('figure',{class:[
      'image',
      _vm.getAvatarImageSizeClass,
      ("user-avatar__container--" + _vm.size),
      { 'user-avatar__container--faded': _vm.isFaded },
      _vm.customClass
    ]},[_c('div',{class:[
        'user-avatar__background',
        ("user-avatar__background--" + _vm.size)
      ]},[(_vm.userInitials)?_c('span',{staticClass:"user-avatar__initials"},[_vm._v(_vm._s(_vm.userInitials))]):_c('span',{staticClass:"user-avatar__initials"},[_c('b-icon',{attrs:{"icon":"account"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }