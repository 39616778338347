import { baseForm, baseField } from '@/forms/base.form';
import { validateEmail } from '@/services/Validation.service';

const createVendorForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'createVendorForm.name.label',
      name: 'createVendorForm.name.name',
      placeholder: 'createVendorForm.name.placeholder',
      value: '',
      required: true,
      ref: 'create-vendor-form__vendor-name',
    },
    contactName: {
      ...baseField,
      label: 'createVendorForm.contactName.label',
      name: 'createVendorForm.contactName.name',
      placeholder: 'createVendorForm.contactName.placeholder',
      value: '',
      required: false,
    },
    contactEmail: {
      ...baseField,
      label: 'createVendorForm.contactEmail.label',
      name: 'createVendorForm.contactEmail.name',
      value: '',
      required: false,
      validator: validateEmail,
    },
    contactPhone: {
      ...baseField,
      label: 'createVendorForm.contactPhone.label',
      name: 'createVendorForm.contactPhone.name',
      value: '',
      required: false,
      masked: true,
      rawValue: '',
    },
    id1: {
      ...baseField,
      label: 'createVendorForm.id1.label',
      name: 'createVendorForm.id1.name',
      value: '',
      required: false,
    },
    id2: {
      ...baseField,
      label: 'createVendorForm.id2.label',
      name: 'createVendorForm.id2.name',
      value: '',
      required: false,
    },
  },
};

export default createVendorForm;
