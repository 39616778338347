import i18n from '@/i18n';

export function validateEmail(value) {
  return /^[A-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? null : true;
}

export function isRequiredError(inputName) {
  return `${inputName} ${i18n.t('common.errors.requiredError')}`;
}

export function isInvalidError(inputName) {
  return `${i18n.t('common.errors.invalidError')} ${inputName}`;
}

export function runInputValidation(input, options = { i18n: false }) {
  const {
    value,
    required,
    validator,
    name: inputName,
  } = input;

  let error = null;
  const name = options.i18n ? i18n.t(inputName) : inputName;

  if (!value && required) {
    error = isRequiredError(name);
  } else if (value && validator && validator(value)) {
    error = isInvalidError(name);
  }

  return error;
}

export function runFormValidation(form, options = { i18n: false }) {
  const errors = {};

  Object.keys(form).forEach(key => {
    if (runInputValidation(form[key])) {
      const name = options.i18n ? i18n.t(form[key].name) : form[key].name;
      errors[name] = runInputValidation(form[key], { i18n: options.i18n });
    }
  });

  return errors;
}
