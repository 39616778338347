import Vue from 'vue';
import Vuex from 'vuex';
import ui from '@/store/modules/ui.module';
import activity from '@/store/modules/activity.module';
import auth from '@/store/modules/auth.module';
import project from '@/store/modules/project.module';
import user from '@/store/modules/user.module';
import organization from '@/store/modules/organization.module';
import projectStatus from '@/store/modules/projectStatus.module';
import invitation from '@/store/modules/invitation.module';
import type from '@/store/modules/type.module';
import costType from '@/store/modules/costType.module';
import vendor from '@/store/modules/vendor.module';
import cost from '@/store/modules/cost.module';
import bill from '@/store/modules/bill.module';
import billItem from '@/store/modules/billItem.module';
import billStatus from '@/store/modules/billStatus.module';
import billingType from '@/store/modules/billingType.module';
import tax from '@/store/modules/tax.module';
import billItemType from '@/store/modules/billItemType.module';
import userStatus from '@/store/modules/userStatus.module';
import client from '@/store/modules/client.module';
import costCode from '@/store/modules/costCode.module';
import costCodeCategory from '@/store/modules/costCodeCategory.module';
import budget from '@/store/modules/budget.module';
import auditLog from '@/store/modules/auditLog.module';
import role from '@/store/modules/role.module';
import comment from '@/store/modules/comment.module';
import activityCategory from '@/store/modules/activityCategory.module';
import document from '@/store/modules/document.module';
import subscription from '@/store/modules/subscription.module';
import subscriptionType from '@/store/modules/subscriptionType.module';
import changeOrder from '@/store/modules/changeOrder.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    activity,
    activityCategory,
    costType,
    document,
    invitation,
    organization,
    ui,
    auth,
    project,
    projectStatus,
    type,
    user,
    vendor,
    cost,
    bill,
    billItem,
    billStatus,
    billingType,
    tax,
    billItemType,
    userStatus,
    client,
    costCode,
    costCodeCategory,
    budget,
    auditLog,
    role,
    comment,
    subscription,
    subscriptionType,
    changeOrder,
  },
});
