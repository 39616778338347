import { baseForm, baseField } from '@/forms/base.form';

const createTaxForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'createTaxForm.name.label',
      name: 'createTaxForm.name.name',
      placeholder: 'createTaxForm.name.placeholder',
      value: '',
      required: true,
    },
    percent: {
      ...baseField,
      label: 'createTaxForm.percent.label',
      name: 'createTaxForm.percent.name',
      placeholder: 'createTaxForm.percent.placeholder',
      value: '',
      required: false,
      addons: {
        right: '%',
      },
      class: 'has-text-right',
    },
  },
};

export default createTaxForm;
