<template>
  <div>
    <BField grouped group-multiline>
      <div class="control">
        <BSwitch v-model="isShowingActivitiesWithNoAdvance" size="is-small">
          <span class="has-text-weight-bold">{{ $t('billDetailsModal.tabs.breakdown.filters.showActivitiesWithNoAdvance') }}</span>
        </BSwitch>
      </div>
    </BField>
    <BTable
      :data="this.filteredTableData"
      hoverable
      detailed
      :mobile-cards="false"
      :show-detail-icon="false"
      detail-key="id"
      ref="activitiesTable"
      class="card"
    >
      <BModal
        :active="!!activityIdEditing"
        :width="400"
        @close="handleCloseEditModal"
        hasModalCard
      >
        <div class="modal-card">
          <header class="modal-card-head">
            <p v-if="activityIdEditing" class="modal-card-title">{{ $t('common.ctas.edit') }} {{ stateActivities[activityIdEditing].name }}</p>
            <button
              type="button"
              class="delete"
              @click="handleCloseEditModal"
            />
          </header>
          <div class="modal-card-body">
            <CreateOrEditActivityCard
              :costId="costId"
              :activityId="activityIdEditing"
              :isDark="false"
              :isInset="false"
              areFieldsStacked
              :hasBorderRadius="false"
              @submitSuccess="handleActivityUpdateSuccess"
            />
          </div>
        </div>
      </BModal>
      <BModal
        :active="!!activityIdConfirmingDelete"
        @close="handleCloseDeleteModal"
        hasModalCard
      >
        <div class="modal-card">
          <header class="modal-card-head">
            <p v-if="activityIdConfirmingDelete" class="modal-card-title">
              {{ $t('common.ctas.delete') }}
              {{ $t('common.terms.activity') }}
            </p>
            <button
              type="button"
              class="delete"
              @click="handleCloseDeleteModal"
            />
          </header>
          <section class="modal-card-body has-background-white u-p-a has-text-centered">
            <div>
              <p>
                <BIcon icon="alert" size="is-large" type="is-danger" />
              </p>
              <h5 class="title is-5">{{ $t('costActivitiesTable.deleteActivityModal.warning') }}</h5>
              <div class="is-flex is-justify-content-center">
                <ActivityCard :activityId="activityIdConfirmingDelete" style="width: 300px;" />
              </div>
            </div>
          </section>
          <footer class="modal-card-foot" style="justify-content: flex-end;">
            <div class="buttons">
              <BButton @click="handleCloseDeleteModal">
                {{ $t('common.ctas.cancel') }}
              </BButton>
              <BButton
                type="is-danger"
                @click="handleConfirmDeleteActivity(activityIdConfirmingDelete)"
                :loading="activitiesDeleting.includes(activityIdConfirmingDelete)"
              >
                {{ $t('common.ctas.delete') }}
                {{ $t('common.terms.activity') }}
              </BButton>
            </div>
          </footer>
        </div>
      </BModal>
      <template slot="empty">
        <div class="has-text-grey u-p-a has-text-centered">
          {{ $t('costDetailsView.activities.emptyState') }}
        </div>
      </template>
      <BTableColumn
        field="activityDescription"
        :label="$t('common.terms.name')"
        width="200"
        cell-class="vertical-align-middle"
        v-slot="props"
      >
        <p class="u-m-b-xsmall">
          {{ props.row.name }}
        </p>
        <CostCodeTag :costCodeId="props.row.costCodeId" />
      </BTableColumn>
      <BTableColumn
        field="totalBudget"
        :label="$t('common.terms.contracted')"
        numeric
        cell-class="vertical-align-middle"
        v-slot="props"
      >
        <p>{{ displayCurrency(calculateActivityBudget(props.row.unitValue, props.row.quantity), { prefix: '$', multiplier: 100 }) }}</p>
        <small>
          {{ props.row.quantity }} {{ props.row.unit }} @ {{ displayCurrency(props.row.unitValue, { multiplier: 100, prefix: '$' }) }}
        </small>
      </BTableColumn>
      <BTableColumn
        field="totalCompleted"
        :label="$t('common.terms.completed')"
        numeric
        cell-class="vertical-align-middle"
        v-slot="props"
      >
        <p>{{ displayCurrency(calculateTotalAdvanceSnapshot(activeBill.number, props.row.id), { prefix: '$', multiplier: 100 }) }}</p>
        <p></p>
        <small>
          {{ convertFractionToPercent(calculateTotalAdvanceSnapshot(activeBill.number, props.row.id) / calculateActivityBudget(props.row.unitValue, props.row.quantity)) }}%
          &nbsp;/&nbsp;
          {{ (calculateTotalAdvanceSnapshot(activeBill.number, props.row.id) / props.row.unitValue).toFixed(1) }} {{ props.row.unit }}
        </small>
      </BTableColumn>
      <BTableColumn
        field="advanceThisBill"
        :label="$t('common.terms.advanceThisBill')"
        numeric
        cell-class="vertical-align-middle"
        v-slot="props"
      >
        <div v-if="advanceBillItems.find(billItem => billItem.activityId === props.row.id)">
          <p>
            {{ displayCurrency(advanceBillItems.find(billItem => billItem.activityId === props.row.id).amount || 0, { prefix: '$', multiplier: 100 }) }}
          </p>
          <small>
            {{ convertFractionToPercent((advanceBillItems.find(billItem => billItem.activityId === props.row.id).amount || 0) / calculateActivityBudget(props.row.unitValue, props.row.quantity)) }}%
            &nbsp;/&nbsp;
            {{ ((advanceBillItems.find(billItem => billItem.activityId === props.row.id).amount || 0) / props.row.unitValue).toFixed(1) }} {{ props.row.unit }}
          </small>
        </div>
        <div v-else>
          {{ displayCurrency(0, { prefix: '$' }) }}
        </div>
      </BTableColumn>
      <!-- <BTableColumn
        field="currentAdvance"
        :label="$t('common.terms.progress')"
        cell-class="vertical-align-middle"
        width="120"
        centered
        v-slot="props"
      >
        <vc-donut
          :sections="[{
            value: (getActivityTotalAdvancePercent(props.row.id) * 100) || 0,
            color: '#5e72e4',
          }]"
          :size="60"
          :thickness="20"
          :auto-adjust-text-size="false"
        >
          <p class="has-text-weight-bold">
            {{ Math.round(convertFractionToPercent(getActivityTotalAdvancePercent(props.row.id))) }}%
          </p>
        </vc-donut>
      </BTableColumn> -->
      <BTableColumn
        field="rowDetails"
        label=""
        cell-class="vertical-align-middle"
        width="170"
        v-slot="props"
      >
        <div class="buttons" style="justify-content: center;">
          <BTooltip :label="$t('common.titles.advanceHistory')" position="is-top" :triggers="['hover']" type="is-dark" class="u-m-r-small">
            <BButton
              type="is-primary is-light"
              @click="props.toggleDetails(props.row)"
              @keyup.enter="props.toggleDetails(props.row)"
            >
              <BIcon icon="format-list-bulleted" />
            </BButton>
          </BTooltip>
        </div>
      </BTableColumn>
      <template slot="detail" slot-scope="props">
        <ActivityRowDetails :activeActivityId="props.row.id" />
      </template>
    </BTable>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';
import * as ActivityActions from '@/store/actions/Activity.actions';
import * as Types from '@/constants/Types';
import { calculateActivityBudget } from '@/helpers/activityHelpers';
import { displayCurrency, addSuffix } from '@/helpers/stringHelpers';
import { convertFractionToPercent } from '@/helpers/numberHelpers';
import ActivityRowDetails from '@/components/ActivityRowDetails.vue';
import CreateOrEditActivityCard from '@/components/CreateOrEditActivityCard.vue';
import CostCodeTag from '@/components/CostCodeTag.vue';
import ActivityCard from '@/components/ActivityCard.vue';

export default {
  name: 'BillDetailsActivitesTable',

  components: {
    ActivityCard,
    ActivityRowDetails,
    CreateOrEditActivityCard,
    CostCodeTag,
  },

  props: {
    billId: {
      type: String,
      required: true,
    },
    costId: {
      type: String,
      required: true,
    },
    /**
     * Pass an array of activities to display. If empty, component will default to
     * getting all activities matching the costId from global Vuex state.
     * */
    activities: {
      type: Array,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      openedDetailsTableRowIds: [], // An array of activity ids for table rows with details opened.
      activitiesDeleting: [],
      activityIdEditing: null,
      activityIdConfirmingDelete: null,
      isShowingActivitiesWithNoAdvance: false,
    };
  },

  computed: {
    ...mapState({
      bills: state => state.bill.bills,
      costCodes: state => state.costCode.costCodes,
      costCodeCategories: state => state.costCodeCategory.costCodeCategories,
      stateActivities: state => state.activity.activities,
    }),
    ...mapGetters([
      'getCostActivities',
      'getCostTotalActivityBudget',
      'getActivityTotalAdvanceAmount',
      'getActivityTotalAdvancePercent',
      'getActivityRemainingBalanceAmount',
      'getActivityRemainingBalancePercent',
      'getBillItemsByActivityId',
      'getBillItemsByBillIdAndTypeName',
    ]),
    tableData() {
      return this.activities ? this.activities : this.getCostActivities(this.costId);
    },
    filteredTableData() {
      return this.isShowingActivitiesWithNoAdvance
        ? this.tableData
        : this.tableData.filter(activity => (
          this.advanceBillItems.find(billItem => billItem.activityId === activity.id))?.amount);
    },
    activeBill() {
      return this.bills[this.billId];
    },
    advanceBillItems() {
      return this.getBillItemsByBillIdAndTypeName(this.billId, Types.billItemTypes.advance);
    },
  },

  methods: {
    calculateActivityBudget,
    displayCurrency,
    convertFractionToPercent,
    addSuffix,
    async handleConfirmDeleteActivity(activityId) {
      this.activitiesDeleting = [...this.activitiesDeleting, activityId];
      try {
        await this.$store.dispatch(ActivityActions.DELETE_ACTIVITY_BY_ID, {
          activityId,
        });
        this.handleCloseDeleteModal();
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY.SUCCESS);
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.DELETE_ACTIVITY.ERROR);
      }
      this.activitiesDeleting = this.activitiesDeleting.filter(a => a !== activityId);
    },
    handleClickEditActivity(activityId) {
      this.activityIdEditing = activityId;
    },
    handleClickDeleteActivity(activityId) {
      this.activityIdConfirmingDelete = activityId;
    },
    handleCloseEditModal() {
      this.activityIdEditing = null;
    },
    handleCloseDeleteModal() {
      this.activityIdConfirmingDelete = null;
    },
    handleActivityUpdateSuccess() {
      this.handleCloseEditModal();
      NotificationService.showNotification(NotificationTypes.UPDATE_ACTIVITY.SUCCESS);
    },
    calculateTotalAdvanceSnapshot(billNumber, activityId) {
      return [...this.getBillItemsByActivityId(activityId)]
        .map(billItem => ({ ...billItem, bill: this.bills[billItem.billId] }))
        .sort((a, b) => b.bill.number - a.bill.number)
        .reverse()
        .slice(0, billNumber)
        .reduce((total, curr) => (total + curr.amount), 0);
    },
  },
};
</script>
