import { normalize } from 'normalizr';
import * as BudgetMutations from '@/store/mutations/Budget.mutations';
import * as ProjectMutations from '@/store/mutations/Project.mutations';
import * as ActivityMutations from '@/store/mutations/Activity.mutations';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as BudgetActions from '@/store/actions/Budget.actions';
import * as schemas from '@/store/schemas';
import * as Api from '@/services/Api.service';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  budgets: {},
});

const receiveBudgets = (state, payload) => {
  state.budgets = {
    ...state.budgets,
    ...payload.budgets,
  };
  state.isFetchingData = false;
  state.isWritingData = false;
};

function deleteBudget(state, payload) {
  const updatedBudgets = { ...state.budgets };
  delete updatedBudgets[payload.budgetId];
  state.budgets = { ...updatedBudgets };
  state.isFetchingData = false;
  state.isWritingData = false;
}

const state = getInitialState();

const getters = {
  getBudgetsByProjectId(state) {
    return projectId => Object.values(state.budgets)
      .filter(budget => budget.projectId === projectId);
  },
  getTotalBudgetValue(state, getters, rootState, rootGetters) {
    return budgetId => {
      const budgetActivities = Object.values(rootState.activity.activities)
        .filter(activity => activity.budgetId && activity.budgetId === budgetId);

      return budgetActivities.reduce((acc, activity) => (acc + rootGetters.getActivityBudget(activity.id)), 0);
    };
  },
};

const mutations = {
  [BudgetMutations.BUDGET_BEGIN_WRITE_DATA](state) {
    state.isWritingData = true;
  },
  [BudgetMutations.BUDGET_COMPLETE_WRITE_DATA](state) {
    state.isWritingData = false;
  },
  [BudgetMutations.BUDGET_REQUEST_DATA](state) {
    state.isFetchingData = true;
  },
  [BudgetMutations.RECEIVE_BUDGETS]: receiveBudgets,
  [ProjectMutations.RECEIVE_PROJECTS]: receiveBudgets,
  [ActivityMutations.RECEIVE_ACTIVITIES]: receiveBudgets,
  [UserMutations.RECEIVE_USERS]: receiveBudgets,
  [BudgetMutations.DELETE_BUDGET]: deleteBudget,
};

const actions = {
  [BudgetActions.CREATE_BUDGET]: async (context, payload) => {
    context.commit(BudgetMutations.BUDGET_BEGIN_WRITE_DATA);

    try {
      const { data: { budget } } = await Api.budget.create(payload.newBudget);
      const normalizedBudget = normalize(budget, schemas.budget);
      context.commit(BudgetMutations.RECEIVE_BUDGETS, {
        ...normalizedBudget.entities,
      });

      return normalizedBudget.result; // Created budget id.
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [BudgetActions.FETCH_PROJECT_BUDGETS]: async (context, payload) => {
    context.commit(BudgetMutations.BUDGET_REQUEST_DATA);

    try {
      const { projectId } = payload;
      const { data: { budgets } } = await Api.project.budget.findMany(projectId);
      const normalizedBudgets = normalize(budgets, [schemas.budget]);
      context.commit(BudgetMutations.RECEIVE_BUDGETS, { ...normalizedBudgets.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  [BudgetActions.FETCH_BUDGET_BY_ID]: async (context, payload) => {
    context.commit(BudgetMutations.BUDGET_REQUEST_DATA);

    try {
      const { budgetId } = payload;
      const { data: { budget } } = await Api.budget.findOne(budgetId);
      const normalizedBudget = normalize(budget, schemas.budget);
      context.commit(BudgetMutations.RECEIVE_BUDGETS, { ...normalizedBudget.entities });
    } catch (err) {
      console.error(err.response); // eslint-disable-line no-console
      throw new Error(err.response.data.message);
    }
  },
  async [BudgetActions.DELETE_BUDGET_BY_ID](context, payload) {
    context.commit(BudgetMutations.BUDGET_BEGIN_WRITE_DATA);
    try {
      await Api.budget.deleteOne(payload.budgetId);

      context.commit(BudgetMutations.DELETE_BUDGET, { budgetId: payload.budgetId });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw new Error(err);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
