<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.clients') }}&nbsp;({{ clientsArray.length || 0 }})</h4>
        </div>
        <div v-if="isLoggedInUserAtLeastProjectManager" class="app-page__header__action">
          <BButton
            type="is-primary"
            outlined
            iconLeft="plus-thick"
            @click="handleClickCreateClient"
            @keyup.enter="handleClickCreateClient"
          >
            {{ $t('common.ctas.createClient') }}
          </BButton>
        </div>
      </section>

      <section class="app-page__section">
        <BTable
          v-if="clientsArray.length > 0"
          :data="clientsArray"
          hoverable
          @click="handleClickRow"
          @keyup.enter="handleClickRow"
          class="card"
          :mobile-cards="false"
          :row-class="handleRowClass"
        >
          <BTableColumn
            field="name"
            :label="$t('clientsTable.columns.name')"
            cell-class="vertical-align-middle u-p-l"
            header-class="u-p-l"
            v-slot="props"
          >
            {{ props.row.name }}
          </BTableColumn>
          <BTableColumn
            field="description"
            :label="$t('clientsTable.columns.description')"
            cell-class="vertical-align-middle"
            v-slot="props"
          >
            {{ props.row.description }}
          </BTableColumn>
        </BTable>
        <div v-else class="card">
          <div class="card-content has-text-centered">
            <p class="has-text-grey u-p-t title is-5">
              {{ $t('clientListView.emptyState') }}
            </p>
            <BButton
              class="is-inline-block"
              type="is-primary"
              @click="handleClickCreateClient"
              @keyup.enter="handleClickCreateClient"
            >
              {{ $t('clientListView.emptyStateCta') }}
            </BButton>
          </div>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as ClientActions from '@/store/actions/Client.actions';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';

export default {
  name: 'Clients',

  data() {
    return {
      isPageLoading: true,
    };
  },

  computed: {
    ...mapState({
      clients: state => state.client.clients,
      activities: state => state.activity.activities,
    }),
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
      'isLoggedInUserAtLeastProjectManager',
    ]),
    clientsArray() {
      const clientsArray = Object.values(this.clients);
      return clientsArray;
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    handleRowClass() {
      return 'has-cursor-pointer';
    },
    handleClickRow(clientRow) {
      this.$router.push(`/clients/${clientRow.id}/edit`);
    },
    handleClickCreateClient() {
      this.$router.push('/clients/create');
    },
    async fetchInitialData() {
      this.isPageLoading = true;

      try {
        await this.$store.dispatch(ClientActions.FETCH_ORGANIZATION_CLIENTS, { organizationId: this.activeOrganization.id });
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isPageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
