<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.titles.costCodes') }}</h4>
        </div>
        <div v-if="isLoggedInUserAtLeastProjectManager" class="app-page__header__action">
          <BButton
            type="is-primary"
            outlined
            @click="handleClickImportCostCodes"
            @keyup.enter="handleClickImportCostCodes"
          >
            <BIcon icon="upload" class="u-m-r-xsmall" customSize="mdi-18px" />
            {{ $t('common.ctas.importCostCodes') }}
          </BButton>
        </div>
      </section>

      <section v-if="isLoggedInUserAtLeastProjectManager" class="app-page__section">
        <CreateOrEditCostCodeCard @submitSuccess="handleCreateCostCode" />
      </section>

      <section class="app-page__section">
        <div class="columns u-m-b-0 is-multiline is-vcentered is-reverse-mobile">
          <div class="column u-p-b-0">
            <div class="has-text-weight-bold">
              {{ $t('costCodesView.costCodeSearchField.foundText', { total }) }}
              {{ search ? $t('costCodesView.costCodeSearchField.matching', { search }) : '' }}
            </div>
          </div>
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <BInput
              icon="magnify"
              v-model="search"
              @input="handleSearchInput"
              :placeholder="$t('costCodesView.costCodeSearchField.placeholder')"
            />
          </div>
        </div>
        <CostCodesTable
          :isLoading="isTableLoading"
          :tableData="tableData"
          :pageSize="pageSize"
          :sortBy="sortBy"
          :sortDirection="sortDirection"
          :total="total"
          @pageChange="handlePageChange"
          @sortTable="handleSortTable"
        />
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as CostCodeActions from '@/store/actions/CostCode.actions';
import CreateOrEditCostCodeCard from '@/components/CreateOrEditCostCodeCard.vue';
import CostCodesTable from '@/components/CostCodesTable.vue';
import * as NotificationService from '@/services/Notification.service';
import * as NotificationTypes from '@/constants/NotificationTypes';

export default {
  name: 'CostCodes',

  components: {
    CreateOrEditCostCodeCard,
    CostCodesTable,
  },

  data() {
    return {
      isPageLoading: false,
      isTableLoading: true,
      pageSize: 20,
      pageNumber: 1,
      sortBy: 'createdAt',
      sortDirection: 'desc',
      tableData: [],
      total: 0,
      search: '',
      searchDebounceTimeout: null,
    };
  },

  computed: {
    ...mapState({
      costCodes: state => state.costCode.costCodes,
    }),
    ...mapGetters([
      'activeOrganization',
      'isLoggedInUserAtLeastProjectManager',
    ]),
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    handleClickImportCostCodes() {
      this.$router.push('/cost-codes/import');
    },
    handleCreateCostCode() {
      this.fetchInitialData();
    },
    async fetchInitialData() {
      this.isTableLoading = true;

      try {
        const { costCodeIds, costCodeCount } = await this.$store.dispatch(CostCodeActions.FETCH_ORGANIZATION_COST_CODES, {
          organizationId: this.activeOrganization.id,
          params: {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            ...this.search && { search: this.search },
          },
        });

        this.tableData = costCodeIds.map(id => this.costCodes[id]);
        this.total = costCodeCount;
      } catch (err) {
        console.error(err);
        NotificationService.showNotification(NotificationTypes.LOAD_PAGE.ERROR);
      }
      this.isTableLoading = false;
      this.isPageLoading = false;
    },
    async handlePageChange(page) {
      this.pageNumber = page;
      await this.fetchInitialData();
    },
    async handleSortTable(field, order) {
      this.sortBy = field;
      this.sortDirection = order;
      await this.fetchInitialData();
    },
    handleSearchInput() {
      this.pageNumber = 1;
      clearTimeout(this.searchDebounceTimeout);
      this.searchDebounceTimeout = setTimeout(() => {
        this.fetchInitialData();
      }, 200);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
