<template>
  <div id="log-in-view" class="app-page__container">
    <h3 class="title is-3 has-text-centered u-m-y-large">{{ $t('logInView.welcomeMessage') }}</h3>
    <form class="log-in-form" @submit.prevent="handleLogInWithEmailAndPassword">
      <BField v-if="error">
        <BMessage type="is-danger">
          {{ error.message }}
        </BMessage>
      </BField>
      <BField
        :label="logInForm.userEmail.label"
        :type="formErrors[logInForm.userEmail.name] ? 'is-danger' : ''"
        :message="formErrors[logInForm.userEmail.name] || ''"
      >
        <BInput
          v-model="logInForm.userEmail.value"
          :name="logInForm.userEmail.name"
          :disabled="isLogInFormInProgress"
        />
      </BField>
      <BField
        :label="logInForm.userPassword.label"
        :type="formErrors[logInForm.userPassword.name] ? 'is-danger' : ''"
        :message="formErrors[logInForm.userPassword.name] || ''"
      >
        <BInput
          v-model="logInForm.userPassword.value"
          type="password"
          password-reveal
          :disabled="isLogInFormInProgress"
        />
      </BField>
      <BButton
        native-type="submit"
        type="is-primary"
        value="Log In"
        class="is-fullwidth u-m-b"
        :loading="isLogInWithEmailInProgress"
        :disabled="isLogInFormInProgress"
      >
        {{ $t('common.ctas.logIn') }}
      </BButton>
      <BField class="has-text-centered">
        <BButton
          type="is-text"
          size="is-small"
          @click="handleClickResetPassword"
          @keyup.enter="handleClickResetPassword"
          style="margin: 0 auto;"
        >
          {{ $t('logInView.forgotPassword') }}
        </BButton>
      </BField>
    </form>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import * as ValidationService from '@/services/Validation.service';
import * as AuthActions from '@/store/actions/Auth.actions';
import * as UserActions from '@/store/actions/User.actions';
import * as UserMutations from '@/store/mutations/User.mutations';
import * as OrganizationMutations from '@/store/mutations/Organization.mutations';
import { shapeUserDisplayName } from '@/helpers/userHelpers';
import { fetchOrganizationRequiredData } from '@/helpers/organizationHelpers';
import { isObjectEmpty } from '@/helpers/dataHelpers';

export default {
  name: 'LogIn',

  data() {
    return {
      formErrors: {},
      isLogInWithEmailInProgress: false,
      loginHandlerError: null,
    };
  },

  computed: {
    ...mapGetters([
      'loggedInUser',
      'activeOrganization',
    ]),
    ...mapState({
      authError: state => state.auth.logInError,
      authUser: state => state.auth.authUser,
      logInForm() {
        return {
          userEmail: {
            name: this.$t('common.formFields.email.name'),
            value: '',
            required: true,
            validator: ValidationService.validateEmail,
            label: this.$t('common.formFields.email.label'),
          },
          userPassword: {
            name: this.$t('common.formFields.password.name'),
            value: '',
            required: true,
            label: this.$t('common.formFields.password.label'),
          },
        };
      },
    }),
    isLogInFormInProgress() {
      return this.isLogInWithEmailInProgress;
    },
    error() {
      const error = this.authError || this.loginHandlerError;
      return error ?? null;
    },
  },

  methods: {
    async handleLogInWithEmailAndPassword() {
      this.formErrors = ValidationService.runFormValidation({ ...this.logInForm });
      if (!isObjectEmpty(this.formErrors)) return;

      this.isLogInWithEmailInProgress = true;
      try {
        await this.$store.dispatch(AuthActions.LOG_IN_WITH_EMAIL_AND_PASSWORD, {
          userEmail: this.logInForm.userEmail.value.trim(),
          userPassword: this.logInForm.userPassword.value,
        });
        const userId = await this.$store.dispatch(UserActions.FETCH_USER_BY_ID, { userId: this.authUser.uid });
        this.$store.commit(UserMutations.SET_LOGGED_IN_USER, userId);
        this.$store.commit(OrganizationMutations.SET_ACTIVE_ORGANIZATION, this.loggedInUser.organizationId);

        await fetchOrganizationRequiredData(this.activeOrganization.id);

        this.$FullStory.identify(this.loggedInUser.id, {
          displayName: shapeUserDisplayName(this.loggedInUser),
          email: this.loggedInUser.email,
        });

        this.$GrowthBook.setAttributes({
          userId: this.loggedInUser.id,
          organizationId: this.loggedInUser.organizationId,
        });

        this.$router.push('/');
      } catch (err) {
        this.loginHandlerError = err;
        this.isLogInWithEmailInProgress = false;
      }
    },
    handleClickResetPassword() {
      this.$router.push('/reset-password');
    },
  },
};
</script>

<style lang="scss" scoped>
.log-in-form {
  margin: 0 auto;
  @media screen and (min-width: $tablet) {
    max-width: 500px;
  }
}
</style>
