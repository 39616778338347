<template>
  <div>
    <BModal :active="true" :canCancel="false" fullScreen>
      <div v-if="!isPageLoading">
        <img :src="documentData" />
      </div>
      <div v-else style="position: relative; min-height: 150px;">
        <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
      </div>
    </BModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as Api from '@/services/Api.service';
import * as DocumentActions from '@/store/actions/Document.actions';
import * as DocumentConstants from '@/constants/Document.constants';
import { getFileExtension, isImageFile, shapeDocumentDisplayName } from '@/helpers/documentHelpers';

export default {
  name: 'DocumentViewerView',

  data() {
    return {
      documentData: null,
      isPageLoading: false,
    };
  },

  computed: {
    ...mapState({
      documents: state => state.document.documents,
    }),
    resourceType() { return this.$route.params.resourceType; },
    resourceId() { return this.$route.params.resourceId; },
    documentId() { return this.$route.params.documentId; },
  },

  async created() {
    await this.fetchInitialData();
    this.downloadDocument();
  },

  methods: {
    async fetchInitialData() {
      this.isPageLoading = true;

      await this.$store.dispatch(DocumentActions.FETCH_DOCUMENT_BY_ID, {
        resourceType: this.resourceType,
        resourceId: this.resourceId,
        documentId: this.documentId,
      });

      this.isPageLoading = false;
    },
    async downloadDocument() {
      const file = await Api[this.resourceType].document.download(this.resourceId, this.documentId);
      const { fileName } = this.documents[this.documentId];
      const shapedName = shapeDocumentDisplayName(fileName);
      const extension = getFileExtension(shapedName);
      const data = Buffer.from(file.data).toString('base64');
      const mimeType = DocumentConstants.fileMimeTypes[extension];
      const href = `data:${mimeType};base64, ${data}`;

      if (isImageFile(extension)) {
        this.documentData = href;
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.download = shapedName;
        a.href = href;
        a.click();
        window.close();
      }
    },
  },
};
</script>
