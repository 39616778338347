import * as TypeMutations from '@/store/mutations/Type.mutations';
import * as BillMutations from '@/store/mutations/Bill.mutations';
import * as Types from '../../constants/Types';

const getInitialState = () => ({
  isWritingData: false,
  isFetchingData: false,
  billStatuses: {},
});

const receiveBillStatusTypes = (state, payload) => {
  state.billStatuses = {
    ...state.billStatuses,
    ...payload.billStatuses,
  };
  state.isFetchingData = false;
};

const state = getInitialState();

const getters = {
  getBillStatusByName(state) {
    return name => Object.values(state.billStatuses)
      .filter(billStatus => billStatus.name === name)[0];
  },
  getBillStatusDraft(state) {
    return Object.values(state.billStatuses)
      .filter(billStatus => billStatus.name === Types.billStatusTypes.draft)[0];
  },
  getBillStatusPaymentRequested(state) {
    return Object.values(state.billStatuses)
      .filter(billStatus => billStatus.name === Types.billStatusTypes.paymentRequested)[0];
  },
};

const mutations = {
  [TypeMutations.RECEIVE_TYPES]: receiveBillStatusTypes,
  [BillMutations.RECEIVE_BILLS]: receiveBillStatusTypes,
};

const actions = {
  // Placeholder
};

export default {
  state,
  getters,
  actions,
  mutations,
};
