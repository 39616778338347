<template>
  <nav
    v-if="displayedBreadcrumbs"
    class="breadcrumbs has-background-white u-p-a-small"
  >
    <div v-if="doesRouteHaveSideNavigation" class="is-hidden-desktop" style="height: 21px; display: flex; align-items: center;">
      <BButton
        size="is-small"
        iconLeft="menu"
        type="is-primary"
        rounded
        @click="handleToggleSideNavigation(true)"
      >
        menu
      </BButton>
      <span class="has-text-grey u-p-l-xsmall">></span>
    </div>
    <div
      v-for="(breadcrumb, index) in displayedBreadcrumbs"
      :key="breadcrumb.key"
    >
      <span v-if="breadcrumb.path">
        <router-link
          :to="breadcrumb.path"
          :class="[
            'breadcrumbs__item',
            { 'breadcrumbs__item--is-active': isBreadcrumbActiveRoute(breadcrumb) }
          ]"
        >
          {{ getBreadcrumbName(breadcrumb) }}
        </router-link>
        <span v-if="index < displayedBreadcrumbs.length - 1" class="has-text-grey">></span>
      </span>
      <span v-else class="has-text-grey breadcrumbs__item">
        {{ getBreadcrumbName(breadcrumb) }}
        <span v-if="index < displayedBreadcrumbs.length - 1" class="has-text-grey">></span>
      </span>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import * as UiMutations from '@/store/mutations/Ui.mutations';

export default {
  name: 'Breadcrumbs',

  props: {
    route: {
      type: Object,
      required: false,
      default: null,
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapState({
      projects: state => state.project.projects,
      organizations: state => state.organization.organizations,
      users: state => state.user.users,
      costs: state => state.cost.costs,
      bills: state => state.bill.bills,
      billStatuses: state => state.billStatus.billStatuses,
      clients: state => state.client.clients,
      budgets: state => state.budget.budgets,
      changeOrders: state => state.changeOrder.changeOrders,
    }),
    routeBreadcrumbs() {
      return this.route?.meta(this.route)?.breadcrumbs;
    },
    displayedBreadcrumbs() {
      return this.breadcrumbs || this.routeBreadcrumbs;
    },
    doesRouteHaveSideNavigation() {
      if (typeof this.$route.meta !== 'function') return false;
      return this.$route.meta(this.$route).hasSideNavigation;
    },
  },

  methods: {
    getBreadcrumbName(breadcrumb) {
      if (!breadcrumb.entity) return breadcrumb.title;

      if (
        this[breadcrumb.entity]
        && this[breadcrumb.entity][this.route?.params[breadcrumb.param]]
        && (this.doesEntityHaveAttributes(breadcrumb, 'attribute') || this.doesEntityHaveAttributes(breadcrumb, 'fallbackAttribute'))
      ) {
        return this.parseBreadcrumbAttributes(breadcrumb, 'attribute') || this.parseBreadcrumbAttributes(breadcrumb, 'fallbackAttribute');
      }

      return this.route?.params[breadcrumb.param];
    },
    isBreadcrumbActiveRoute(breadcrumb) {
      return breadcrumb.path === this.route?.path;
    },
    doesEntityHaveAttributes(breadcrumb, attributeKey) {
      const {
        entity,
        param,
      } = breadcrumb;
      const attribute = breadcrumb[attributeKey];
      let attributesExist;

      if (Array.isArray(attribute)) {
        attributesExist = attribute.map(a => !!this[entity][this.route?.params[param]][a]);
      } else {
        attributesExist = [!!this[entity][this.route?.params[param]][attribute]];
      }

      return !attributesExist.includes(false);
    },
    parseBreadcrumbAttributes(breadcrumb, attributeKey) {
      const { entity, param } = breadcrumb;
      const attribute = breadcrumb[attributeKey];
      let parsedAttributes = '';

      if (Array.isArray(attribute)) {
        if (attribute.every(a => !this[entity][this.route?.params[param]][a])) {
          parsedAttributes = '';
        } else {
          attribute.forEach(attr => {
            parsedAttributes += this[entity][this.route?.params[param]][attr] + ' '; // eslint-disable-line
          });
        }
      } else {
        if (!attribute) { // eslint-disable-line
          parsedAttributes = '';
        } else {
          parsedAttributes = this[entity][this.route?.params[param]][attribute];
        }
      }

      return parsedAttributes;
    },
    handleToggleSideNavigation(isSideNavigationOpen) {
      this.$store.commit(UiMutations.SET_SIDE_NAVIGATION_STATE, { isSideNavigationOpen });
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  // hide when printing page
  @media print { display: none; }

  border-radius: 0.2rem;
  box-shadow: $light-shadow;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  align-items: center;
}

.breadcrumbs__item {
  line-height: 1;
  margin: 0 $global-whitespace-xsmall;

  &--is-active {
    color: $primary;
    background-color: $primary-light;
    font-weight: $weight-bold;
    border-radius: 6px;
    padding: $global-whitespace-xsmall $global-whitespace-small;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}
</style>
