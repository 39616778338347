import { baseForm, baseField } from '@/forms/base.form';

const createChangeOrderForm = {
  ...baseForm,
  fields: {
    name: {
      ...baseField,
      label: 'createChangeOrderForm.name.label',
      name: 'createChangeOrderForm.name.name',
      placeholder: 'createChangeOrderForm.name.placeholder',
      value: '',
      required: true,
      width: 'is-6',
      ref: 'createChangeOrderForm__fields__name',
    },
  },
};

export default createChangeOrderForm;
