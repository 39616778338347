<template>
  <div class="card card--inset card--dark">
    <div class="card-content">
      <BField v-if="apiError">
        <BMessage type="is-danger">
          {{ apiError.message }}
        </BMessage>
      </BField>
      <form @submit.prevent="handleCreateNewActivityCategory" class="columns">
        <div
          :class="[
            'field column is-12-mobile is-7-tablet is-6-widescreen',
            'required',
          ]"
        >
          <label :class="['label']">{{ $t('createActivityCategoryForm.name.label') }}</label>
          <div :class="['control']">
            <BAutocomplete
              :data="filteredActivityCategories"
              v-model="createActivityCategoryForm.fields.name.value"
              :placeholder="$t('createActivityCategoryForm.name.placeholder')"
              openOnFocus
              :maxHeight="200"
              clearable
              appendToBody
              :selectOnClickOutside="false"
              clearOnSelect
              @select="handleSelectActivityCategory"
              @blur="handleActivityCategoryFieldBlur"
              @input="handleTouchForm"
            >
              <template slot="header">
                <div @click="handleCreateNewActivityCategory" type="is-info" outlined size="is-small">
                  <span class="autocomplete__footer__cta">
                    <BIcon icon="plus-circle-outline" customSize="mdi-18px" />
                    {{
                      (createActivityCategoryForm.fields.name.value)
                        ? $t('createActivityCategoryCard.autocompleteField.cta', { categoryName: createActivityCategoryForm.fields.name.value })
                        : $t('common.ctas.createActivityCategory')
                    }}
                  </span>
                </div>
              </template>
              <template slot-scope="props">
                <span>
                  {{ props.option.name }}
                </span>
              </template>
            </BAutocomplete>
          </div>
          <p v-if="createActivityCategoryForm.formErrors[$t(createActivityCategoryForm.fields.name.name)]" class="help is-danger">
            {{ createActivityCategoryForm.formErrors[$t(createActivityCategoryForm.fields.name.name)] }}
          </p>
          <p class="help">
            {{ $t('createActivityCategoryForm.name.helpText') }}
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import * as ValidationService from '@/services/Validation.service';
import * as ActivityCategoryActions from '@/store/actions/ActivityCategory.actions';
import createActivityCategoryForm from '@/forms/createActivityCategory.form';

export default {
  name: 'CreateActivityCategoryCard',

  props: {
    budgetId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isComponentLoading: true,
      createActivityCategoryForm: cloneDeep(createActivityCategoryForm),
      isCreatingActivityCategory: false,
      apiError: null,
    };
  },

  computed: {
    ...mapState({
      activityCategories: state => state.activityCategory.activityCategories,
    }),
    ...mapGetters([
      'getBudgetActivities',
      'activeOrganization',
    ]),
    budgetActivityCategories() {
      return this.getBudgetActivities(this.budgetId)
        .filter(activity => activity.activityCategoryId)
        .reduce((acc, currActivity) => (
          [...new Set([...acc, currActivity.activityCategoryId])]
        ), ['no-category'])
        .map(activityCategoryId => (
          activityCategoryId === 'no-category'
            ? { name: 'common.terms.uncategorized', id: 'no-category' }
            : this.activityCategories[activityCategoryId]
        ));
    },
    sortedActivityCategories() {
      return Object.values(this.activityCategories).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    deDupedActivityCategories() {
      return this.sortedActivityCategories.filter(a => !this.budgetActivityCategories.map(c => c.id).includes(a.id));
    },
    filteredActivityCategories() {
      return this.newActivityCategoryName
        ? this.deDupedActivityCategories
          .filter(({ name }) => (
            name?.toLowerCase().includes(this.newActivityCategoryName.toLowerCase())
          ))
        : [...this.deDupedActivityCategories];
    },
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    async fetchInitialData() {
      this.isComponentLoading = true;

      await this.$store.dispatch(
        ActivityCategoryActions.FETCH_ORGANIZATION_ACTIVITY_CATEGORIES,
        {
          organizationId: this.activeOrganization.id,
        },
      );

      this.isComponentLoading = false;
    },
    async handleCreateNewActivityCategory() {
      this.createActivityCategoryForm.formErrors = ValidationService.runFormValidation({ ...this.createActivityCategoryForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.createActivityCategoryForm.formErrors)) return;
      this.isCreatingActivityCategory = true;

      try {
        const newCategoryId = await this.$store.dispatch(ActivityCategoryActions.CREATE_ACTIVITY_CATEGORY, {
          name: this.createActivityCategoryForm.fields.name.value,
        });

        this.$emit('addCategory', newCategoryId);
        this.createActivityCategoryForm = cloneDeep(createActivityCategoryForm);
      } catch (err) {
        this.apiError = err;
        console.error(err);
      }

      this.isCreatingActivityCategory = false;
    },
    getActivitiesByCategoryId(categoryId) {
      return this.getBudgetActivities(this.activeBudgetId)
        .filter(activity => activity.activityCategoryId === categoryId);
    },
    handleSelectActivityCategory(selectedCategory) {
      this.$emit('addCategory', selectedCategory.id);
    },
    handleActivityCategoryFieldBlur() {
      console.log('blurred');
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>
