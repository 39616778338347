<template>
  <div class="app-page__container">
    <div v-if="!isPageLoading">
      <section class="app-page__header">
        <div class="app-page__header__title">
          <h4 class="title is-4">{{ $t('common.ctas.createUser') }}</h4>
        </div>
      </section>

      <section class="app-page__section">
        <div>
          <form @submit.prevent="handleSubmitCreateUser">
            <div v-if="error" class="u-m-b">
              <BMessage type="is-danger">
                {{ error.message }}
              </BMessage>
            </div>
            <h5 class="title is-5 has-text-grey">
              {{ $t('createUserView.sectionTitles.details') }}
            </h5>
            <div class="columns is-multiline">
              <BField
                :key="createUserForm.fields.firstName.name"
                :class="['column', 'is-6', { 'required': createUserForm.fields.firstName.required }]"
                :label="$t(createUserForm.fields.firstName.label)"
                :type="createUserForm.formErrors[$t(createUserForm.fields.firstName.name)] ? 'is-danger' : ''"
                :message="createUserForm.formErrors[$t(createUserForm.fields.firstName.name)] || ''"
              >
                <BInput
                  v-model="createUserForm.fields.firstName.value"
                  :ref="createUserForm.fields.firstName.name"
                  :disabled="createUserForm.isFormSubmissionInProgress || createUserForm.fields.firstName.disabled"
                  @input="handleTouchForm"
                />
              </BField>
              <BField
                :key="createUserForm.fields.lastName.name"
                :class="['column', 'is-6', { 'required': createUserForm.fields.lastName.required }]"
                :label="$t(createUserForm.fields.lastName.label)"
                :type="createUserForm.formErrors[$t(createUserForm.fields.lastName.name)] ? 'is-danger' : ''"
                :message="createUserForm.formErrors[$t(createUserForm.fields.lastName.name)] || ''"
              >
                <BInput
                  v-model="createUserForm.fields.lastName.value"
                  :ref="createUserForm.fields.lastName.name"
                  :disabled="createUserForm.isFormSubmissionInProgress || createUserForm.fields.lastName.disabled"
                  @input="handleTouchForm"
                />
              </BField>
              <BField
                :key="createUserForm.fields.email.name"
                :class="['column', 'is-6', { 'required': createUserForm.fields.email.required }]"
                :label="$t(createUserForm.fields.email.label)"
                :type="createUserForm.formErrors[$t(createUserForm.fields.email.name)] ? 'is-danger' : ''"
                :message="createUserForm.formErrors[$t(createUserForm.fields.email.name)] || ''"
              >
                <BInput
                  v-model="createUserForm.fields.email.value"
                  :ref="createUserForm.fields.email.name"
                  :disabled="createUserForm.isFormSubmissionInProgress || createUserForm.fields.email.disabled"
                  @input="handleTouchForm"
                />
              </BField>
              <div class="column is-12 field required">
                <label class="label">{{ $t('common.terms.role') }}</label>
                <div class="card">
                  <div class="card-content">
                    <CardRow
                      v-for="role in getAvailableSystemRoles.sort(sortRolesByLevel)"
                      :key="role.id"
                      isClickable
                      @click="handleSelectRole(role.id)"
                    >
                      <template v-slot:content-left>
                        <div class="is-flex is-justify-content-space-between is-flex-grow-1">
                          <div>
                            <h5 class="title is-6">{{ $t(`common.roleNames.${role.name}`) }}</h5>
                            <p class="subtitle is-size-7">{{ $t(`common.roleDescriptions.${role.name}`) }}</p>
                          </div>
                          <div v-if="createUserForm.fields.role.value === role.id" class="is-flex is-align-items-center" style="width: 21px;">
                            <BIcon icon="check" type="is-success" customSize="mdi-36px" />
                          </div>
                          <div v-else class="is-flex is-align-items-center" style="width: 21px;" />
                        </div>
                      </template>
                      <template v-slot:content-right>
                      </template>
                    </CardRow>
                  </div>
                </div>
              </div>
            </div>
            <div class="has-text-right">
              <BButton
                type="is-primary"
                native-type="submit"
                :disabled="createUserForm.isFormSubmissionInProgress"
                :loading="createUserForm.isFormSubmissionInProgress"
              >
                {{ $t('common.ctas.createUser') }}
              </BButton>
            </div>
          </form>
        </div>
      </section>
    </div>
    <div v-else style="position: relative; min-height: 150px;">
      <BLoading :is-full-page="false" :active.sync="isPageLoading" :can-cancel="false"></BLoading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import * as ValidationService from '@/services/Validation.service';
import * as UserActions from '@/store/actions/User.actions';
import createUserForm from '@/forms/createUser.form';
import { isObjectEmpty } from '@/helpers/dataHelpers';
import { sortRolesByLevel } from '@/helpers/roleHelpers';
import { systemRoleNames } from '@/constants/Role.constants';
import CardRow from '@/components/CardRow.vue';

export default {
  name: 'CreateUserView',

  components: {
    CardRow,
  },

  data() {
    return {
      isPageLoading: false,
      createUserForm: cloneDeep(createUserForm),
      error: null,
    };
  },

  computed: {
    ...mapGetters([
      'loggedInUser',
      'getAvailableSystemRoles',
      'getSystemRoleByName',
    ]),
  },

  mounted() {
    this.setInitialFormState();
  },

  methods: {
    sortRolesByLevel,
    setInitialFormState() {
      this.$refs[this.createUserForm.fields.firstName.name].$el.children[0].focus();
      this.createUserForm.fields.role.value = this.getSystemRoleByName(systemRoleNames.member).id;
    },
    handleSelectRole(roleId) {
      this.createUserForm.fields.role.value = roleId;
    },
    async handleSubmitCreateUser() {
      this.error = null;
      this.createUserForm.formErrors = {};
      this.createUserForm.formErrors = ValidationService.runFormValidation({ ...this.createUserForm.fields }, { i18n: true });
      if (!isObjectEmpty(this.createUserForm.formErrors)) return;

      this.createUserForm.isFormSubmissionInProgress = true;

      try {
        await this.$store.dispatch(
          UserActions.CREATE_USER,
          {
            newUser: {
              firstName: this.createUserForm.fields.firstName.value,
              lastName: this.createUserForm.fields.lastName.value,
              email: this.createUserForm.fields.email.value,
              roleId: this.createUserForm.fields.role.value,
            },
          },
        );
        this.$router.push('/users');
      } catch (err) {
        this.error = err;
      }

      this.createUserForm.isFormSubmissionInProgress = false;
    },
    handleTouchForm() {
      console.log('form touched');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
